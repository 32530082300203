<div class="dialog-container">
  <mat-dialog-content>
    <h2>Manage Routes</h2>
    <form
      [formGroup]="routesFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div
        class="routes-container"
      >
        <div class="route-form-table-container" formArrayName="routes_list">
          <table class="routes-table-container">
            <div *ngFor="let route of getRoutesListFormArray().controls; let i=index" [formGroupName]="i">
              <tr>
                <td class="port-column">
                  <mat-form-field>
                    <mat-label>Port</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="bind_port"
                      required
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                    />
                    <mat-error>A valid port is required</mat-error>
                  </mat-form-field>
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="getBindPortTooltipText()"
                  >
                    help_outline
                  </mat-icon>
                </td>

                <td class="hostname-column">
                  <mat-form-field>
                    <mat-label>Hostname/IP</mat-label>
                    <input
                      matInput
                      type="text"
                      formControlName="bind_host"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                      (blur)="onBindHostFormBlur(i)"
                    />
                    <mat-error>A valid hostname/IP is required</mat-error>
                  </mat-form-field>
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="getBindHostTooltipText()"
                  >
                    help_outline
                  </mat-icon>
                </td>

                <td>
                  <mat-checkbox
                    formControlName="bindToEverything"
                    (change)="onCheckboxChange($event.checked, i)"
                    aria-label="Checkbox that toggles whether the bindToEverything option is enabled"
                  >
                    Bind To Everything
                  </mat-checkbox>
                  <mat-icon
                    class="help-icon"
                    [matTooltip]="getBindToEverythingTooltipText()"
                  >
                    help_outline
                  </mat-icon>
                </td>

                <td>
                  <div class="delete-route-button">
                    <button
                      mat-raised-button
                      color="warn"
                      (click)="removeRoute(i)"
                      (keydown.enter)="preventDeleteOnEnter($event)"
                    >
                      DELETE
                    </button>
                  </div>
                </td>
              </tr>
            </div>
          </table>
        </div>
        <div class="add-route-button-container">
          <span class="buttonAsText" (click)="addRoute()">+ Add route</span>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" [disabled]="routesFormGroup.invalid" tabindex="1">SAVE</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>
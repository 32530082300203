import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState, NotificationService } from '@app/core';

import { Organisation, OrganisationStateSelector } from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, forkJoin, combineLatest, of } from 'rxjs';
import { takeUntil, map, concatMap, take } from 'rxjs/operators';
import { addHTTPSToUrlIfNotSet, updateTableElements } from '@app/shared/components/utils';
import { TableElement } from '../table-layout/table-element';
import { CheckboxOption, FilterManager } from '../filter/filter-manager';
import { OrganisationsService } from '@agilicus/angular';
import { UsersService } from '@agilicus/angular';
import { User } from '@agilicus/angular';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  setColumnDefs,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import { AuthService } from '@app/core/services/auth-service.service';
import { ActionOrganisationsLoad } from '@app/core/organisations/organisations.actions';
import { ActionUserRefreshMemberOrgs } from '@app/core/user/user.actions';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { isValidHostname } from '../validation-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { markOrgAsDeleted } from '../org-utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { FilterType } from '../filter-type.enum';
import { updateOrg$, updateSubOrgUniqueIssuer$ } from '@app/core/api/organisations/organisations-api.utils';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { ButtonType } from '../button-type.enum';
import { getOrganisationProductGuideLink, getSubOrgPageDescriptiveText } from '@app/core/api/organisations/organisation-utils';

export interface SubOrgElement extends TableElement, Organisation {
  contact_email?: string;
}

export enum OrgCheckboxOptions {
  DELETED = 'Show deleted organisations',
  DISABLED = 'Show disabled organisations',
}

@Component({
  selector: 'portal-sub-org-admin',
  templateUrl: './sub-org-admin.component.html',
  styleUrls: ['./sub-org-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubOrgAdminComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<SubOrgElement>> = new Map();
  private currentUser: User;
  private orgId: string;
  private org: Organisation;
  public tableData: Array<SubOrgElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'SUB-ORG';
  public hasUsersPermissions: boolean;
  public pageDescriptiveText = getSubOrgPageDescriptiveText();
  public productGuideLink = getOrganisationProductGuideLink();
  public buttonsToShow: Array<string> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      'ADD SUB-ORG',
      ButtonColor.PRIMARY,
      'Add a new sub-organisation',
      'Button that adds a new sub-organisation',
      () => {
        this.router.navigate(['/sub-org-new'], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];

  public showDeletedOrgs = false;
  public showDisabledOrgs = false;
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'status',
      {
        name: 'status',
        displayName: 'Organisation Status',
        icon: 'assessment',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private organisationsService: OrganisationsService,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private authService: AuthService,
    private appErrorHandler: AppErrorHandler,
    private router: Router
  ) {
    this.filterManager.addCheckboxFilterOption({
      name: OrgCheckboxOptions.DELETED,
      displayName: OrgCheckboxOptions.DELETED,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleDeletedOrgs.bind(this),
    });
    this.filterManager.addCheckboxFilterOption({
      name: OrgCheckboxOptions.DISABLED,
      displayName: OrgCheckboxOptions.DISABLED,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleDisabledOrgs.bind(this),
    });
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllData$() {
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    return hasUsersPermissions$.pipe(
      concatMap((hasUsersPermissionsResp) => {
        const orgId = hasUsersPermissionsResp.orgId;
        if (!orgId) {
          return combineLatest([of(hasUsersPermissionsResp), of(undefined), of(undefined), of(undefined), of(undefined)]);
        }
        const currentUser$ = this.authService.auth().user$();
        const org$ = this.organisationsService.getOrg({ org_id: orgId });
        const subOrgs$ = this.organisationsService
          .listSubOrgs({
            org_id: orgId,
          })
          .pipe(
            map((subOrgsResp) => {
              return subOrgsResp.orgs;
            })
          );
        return forkJoin([of(hasUsersPermissionsResp), currentUser$, org$, subOrgs$]);
      })
    );
  }

  private getAndSetAllData(): void {
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([hasUsersPermissionsResp, currentUserResp, orgResp, subOrgsResp]) => {
          this.currentUser = currentUserResp;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.orgId = hasUsersPermissionsResp.orgId;
          this.org = orgResp;
          if (!!this.orgId && this.hasUsersPermissions) {
            this.updateTable(subOrgsResp);
          }
          this.changeDetector.detectChanges();
        },
        (error) => {
          this.resetEmptyTable();
        }
      );
  }

  private updateTable(subOrgs: Array<Organisation>): void {
    if (this.showDeletedOrgs && this.showDisabledOrgs) {
      this.buildData(subOrgs);
    } else if (this.showDeletedOrgs) {
      const filteredSubOrgsListWithDeletedOrgsOnly = subOrgs.filter((org) => org.admin_state !== OrganisationStateSelector.disabled);
      this.buildData(filteredSubOrgsListWithDeletedOrgsOnly);
    } else if (this.showDisabledOrgs) {
      const filteredSubOrgsListWithDisabledOrgsOnly = subOrgs.filter((org) => org.admin_state !== OrganisationStateSelector.deleted);
      this.buildData(filteredSubOrgsListWithDisabledOrgsOnly);
    } else {
      const filteredSubOrgsListWithoutDeletedOrDisabled = subOrgs.filter(
        (org) => org.admin_state !== OrganisationStateSelector.deleted && org.admin_state !== OrganisationStateSelector.disabled
      );
      this.buildData(filteredSubOrgsListWithoutDeletedOrDisabled);
    }
    this.replaceTableWithCopy();
  }

  private getSelectRowColumn(): Column<SubOrgElement> {
    const column = createSelectRowColumn();
    column.disableField = (element: SubOrgElement) => {
      return element.admin_state === OrganisationStateSelector.disabled || element.admin_state === OrganisationStateSelector.deleted;
    };
    return column;
  }

  private getOrganisationColumn(): Column<SubOrgElement> {
    const column = createInputColumn('organisation');
    column.displayName = 'Org Name';
    column.requiredField = () => true;
    column.isEditable = true;
    column.isUnique = true;
    return column;
  }

  private getContactEmailColumn(): Column<SubOrgElement> {
    const column = createInputColumn('contact_email');
    column.displayName = 'Org Contact';
    column.isReadOnly = () => true;
    return column;
  }

  private getSubdomainColumn(): Column<SubOrgElement> {
    const column = createInputColumn('subdomain');
    column.displayName = 'Org Base URL';
    column.isUnique = true;
    column.isReadOnly = () => true;
    column.getDisplayValue = (element: any): any => {
      if (element.subdomain === null || element.subdomain === '') {
        return element.subdomain;
      }
      return `${addHTTPSToUrlIfNotSet(element.subdomain)}`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<SubOrgElement> {
    const column = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<SubOrgElement>> = [
      {
        displayName: 'Disable Unique Issuer',
        icon: 'toggle_off',
        tooltip: 'Click to disable the unique issuer for this sub-organisation',
        onClick: (element: SubOrgElement) => {
          this.disableUniqueIssuer(element);
        },
        isHidden: (element: SubOrgElement) => {
          return element.issuer === this.org.issuer;
        },
      },
      {
        displayName: 'Enable Unique Issuer',
        icon: 'toggle_on',
        tooltip: 'Click to enable a unique issuer for this sub-organisation',
        onClick: (element: SubOrgElement) => {
          this.enableUniqueIssuer(element);
        },
        isHidden: (element: SubOrgElement) => {
          return element.issuer !== this.org.issuer;
        },
      },
    ];
    column.allowedValues = menuOptions;
    return column;
  }

  private enableUniqueIssuer(element: SubOrgElement): void {
    updateSubOrgUniqueIssuer$(this.organisationsService, this.orgId, element.id, true)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success(`"${element.organisation}" has been assigned its own unique issuer`);
          this.refreshDataSource();
        },
        (err) => {
          this.notificationService.error(`Failed to assign "${element.organisation}" its own unique issuer`);
        }
      );
  }

  private disableUniqueIssuer(element: SubOrgElement): void {
    updateSubOrgUniqueIssuer$(this.organisationsService, this.orgId, element.id, false)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success(
            `"${element.organisation}" has been assigned the same issuer as the parent organisation "${this.org.organisation}"`
          );
          this.refreshDataSource();
        },
        (err) => {
          this.notificationService.error(`Failed to assign the parent organisation's issuer to "${element.organisation}"`);
        }
      );
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        this.getSelectRowColumn(),
        this.getOrganisationColumn(),
        this.getContactEmailColumn(),
        this.getSubdomainColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private buildData(subOrgResp: Array<Organisation>): void {
    const data: Array<SubOrgElement> = [];
    for (let i = 0; i < subOrgResp.length; i++) {
      const subOrg = subOrgResp[i];
      data.push(this.createSubOrgElement(subOrg, i));
    }
    updateTableElements(this.tableData, data, 'organisation');
  }

  private createSubOrgElement(subOrg: Organisation, index: number): SubOrgElement {
    const data: SubOrgElement = {
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(subOrg)) {
      data[key] = subOrg[key];
    }
    return data;
  }

  private putSubOrg(updatedSubOrg: SubOrgElement): void {
    updateOrg$(updatedSubOrg, this.organisationsService)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(take(1))
      .subscribe(
        (subOrgResp) => {
          this.notificationService.success(`Sub-Organisation "${updatedSubOrg.organisation}" was successfully updated`);
        },
        (errorResp) => {
          const baseMessage = `Failed to update sub-organisation "${updatedSubOrg.organisation}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        },
        () => {
          this.store.dispatch(new ActionUserRefreshMemberOrgs());
          this.refreshDataSource();
        }
      );
  }

  /**
   * Receives a SubOrgElement and submits the data via the
   * appropriate api call.
   */
  public updateEvent(updatedSubOrg: SubOrgElement): void {
    this.putSubOrg(updatedSubOrg);
  }

  /**
   * Will mark the selected sub-orgs as deleted status
   */
  private deleteSubOrgs(subOrgsToDelete: Array<SubOrgElement>): void {
    if (this.tableData.length === 0) {
      return;
    }
    const observablesArray = [];
    for (const subOrg of subOrgsToDelete) {
      if (subOrg.isChecked && subOrg.id !== '') {
        markOrgAsDeleted(subOrg);
        observablesArray.push(updateOrg$(subOrg, this.organisationsService));
      }
    }
    if (observablesArray.length === 0) {
      this.notificationService.success('Unsaved sub-organisations were removed');
      this.refreshDataSource();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success('All selected sub-organisations were deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected sub-organisations');
        },
        () => {
          this.store.dispatch(new ActionUserRefreshMemberOrgs());
          this.refreshDataSource();
        }
      );
  }

  public removeSelected(subOrgsToDelete: Array<SubOrgElement>): void {
    this.deleteSubOrgs(subOrgsToDelete);
  }

  public refreshDataSource(): void {
    this.unsubscribe$.next();
    this.getAndSetAllData();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public toggleDeletedOrgs(checkboxOption: CheckboxOption): void {
    this.showDeletedOrgs = checkboxOption.isChecked;
    this.refreshDataSource();
  }

  public toggleDisabledOrgs(checkboxOption: CheckboxOption): void {
    this.showDisabledOrgs = checkboxOption.isChecked;
    this.refreshDataSource();
  }
}

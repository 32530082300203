import { DesktopResource } from '@agilicus/angular';
import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TableElement } from '../table-layout/table-element';
import { cloneDeep } from 'lodash-es';
import { UntypedFormControl } from '@angular/forms';
import { DesktopExtraConfigComponent } from '../desktop-extra-config/desktop-extra-config.component';

export interface DesktopExtraConfigDialogData {
  desktop: DesktopResource;
}

export interface DesktopExtraConfigElement extends TableElement {
  name: string;
  type: string;
  value: string;
  nameFormControl: UntypedFormControl;
}

@Component({
  selector: 'portal-desktop-extra-config-dialog',
  templateUrl: './desktop-extra-config-dialog.component.html',
  styleUrl: './desktop-extra-config-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopExtraConfigDialogComponent {
  public desktopCopy: DesktopResource;

  @ViewChild('desktopExtraConfigComponent') desktopExtraConfigComponent: DesktopExtraConfigComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: DesktopExtraConfigDialogData,
    private dialogRef: MatDialogRef<DesktopExtraConfigDialogComponent>
  ) {
    this.desktopCopy = cloneDeep(data.desktop);
  }

  public getExtraConfigFormatText(): string {
    return '"<name>:<type>:<value>"';
  }

  public onConfirmClick(): void {
    this.dialogRef.close(this.desktopCopy);
  }

  public disableSaveButton(): boolean {
    return !this.desktopExtraConfigComponent?.tableHasBeenEdited;
  }
}

<h2 mat-dialog-title>Path Validation</h2>
<mat-dialog-content>
    <p>The path you entered may have issues:</p>
    <pre>{{ data.originalInput }}</pre>
    <p>Paths should not contain unexpected quotes. Please confirm or correct the path:</p>
    <mat-form-field>
    <input matInput [(ngModel)]="correctedInput">
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button (click)="onCancel()">Cancel</button>
    <button mat-button color="primary" (click)="onConfirm()">Confirm</button>
</mat-dialog-actions>
import { AgentConnector, AgentConnectorProxy, AgentConnectorProxySpec, Connector, ConnectorsService } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from '@app/core';
import {
  createNewAgentConnectorProxy$,
  deleteExistingAgentConnectorProxy$,
  getDefaultConnectorProxyHostname,
  getDefaultConnectorProxyPort,
  updateExistingAgentConnectorProxy$,
} from '@app/core/api/connectors/connectors-api-utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { cloneDeep } from 'lodash-es';
import { forkJoin, Observable, Subject, takeUntil } from 'rxjs';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getDefaultReadonlyTableSettings, getDefaultTableProperties } from '../table-layout-utils';
import { Column, createReadonlyColumn, ReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';

export interface ConnectorProxyDialogData {
  agentConnector: AgentConnector;
  connectorIsInnerProxyList: Array<AgentConnectorProxy>;
  connectorIsOuterProxyList: Array<AgentConnectorProxy>;
  connectorList: Array<Connector>;
  orgId: string;
}

export interface InnerConnectorProxyElement extends TableElement {
  connector_id: string;
  bind_port: number;
  bind_host: string;
}

@Component({
  selector: 'portal-connector-proxy-dialog',
  templateUrl: './connector-proxy-dialog.component.html',
  styleUrls: ['./connector-proxy-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConnectorProxyDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public connectorProxiesFormGroup: FormGroup;
  public proxyFormGroup: FormGroup;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  private connectorList: Array<Connector> = [];
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();
  private connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public agentConnector: AgentConnector;
  public connectorIsInnerProxyList: Array<AgentConnectorProxy> = [];
  public connectorIsOuterProxyList: Array<AgentConnectorProxy> = [];
  private proxyIdToAgentConnectorProxyMap: Map<string, AgentConnectorProxy> = new Map();
  private orgId: string;
  public saving = false;
  public proxiesToDeleteIdList: Array<string> = [];
  public columnDefs: Map<string, Column<InnerConnectorProxyElement>> = new Map();
  public tableData: Array<InnerConnectorProxyElement> = [];

  public getDefaultReadonlyTableSettings = getDefaultReadonlyTableSettings;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConnectorProxyDialogData,
    private dialogRef: MatDialogRef<ConnectorProxyDialogComponent>,
    private formBuilder: FormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private connectorsService: ConnectorsService,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.connectorIsInnerProxyList = !!data?.connectorIsInnerProxyList ? data.connectorIsInnerProxyList : [];
    this.connectorIsOuterProxyList = !!data?.connectorIsOuterProxyList ? data.connectorIsOuterProxyList : [];
    this.connectorList = !!data?.connectorList ? data.connectorList : [];
    this.agentConnector = data.agentConnector;
    this.orgId = data.orgId;
    this.setConnectorMaps();
  }

  private setConnectorMaps(): void {
    this.connectorNameToConnectorMap.clear();
    this.connectorIdToConnectorMap.clear();
    this.proxyIdToAgentConnectorProxyMap.clear();
    for (const connector of this.connectorList) {
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
    }
    for (const proxy of this.connectorIsInnerProxyList) {
      this.proxyIdToAgentConnectorProxyMap.set(proxy.metadata.id, proxy);
    }
  }

  public ngOnInit(): void {
    this.initializeConnectorProxyFormGroup();
    this.initializeColumnDefs();
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeConnectorProxyFormGroup(): void {
    this.connectorProxiesFormGroup = this.formBuilder.group({
      proxy_list: this.formBuilder.array([]),
    });
    const proxyList: Array<AgentConnectorProxy> = this.data?.connectorIsInnerProxyList ? this.data.connectorIsInnerProxyList : [];
    for (const proxy of proxyList) {
      this.addProxy(proxy);
    }
  }

  public getProxyListFormArray(): FormArray {
    return this.connectorProxiesFormGroup.get('proxy_list') as FormArray;
  }

  public newRoute(proxy?: AgentConnectorProxy): UntypedFormGroup {
    const connectorName = proxy?.spec?.outer_connector_id
      ? this.connectorIdToConnectorMap.get(proxy.spec.outer_connector_id).spec.name
      : '';
    const proxyFormGroup = this.formBuilder.group({
      proxy_id: proxy?.metadata?.id,
      connector_name: [!!connectorName ? connectorName : '', [Validators.required]],
      bind_port: [
        proxy?.spec?.local_bind?.bind_port ? proxy.spec.local_bind.bind_port : getDefaultConnectorProxyPort(),
        [Validators.required, this.customValidatorsService.portValidator()],
      ],
      bind_host: [
        proxy?.spec?.local_bind?.bind_host !== undefined ? proxy.spec.local_bind.bind_host : getDefaultConnectorProxyHostname(),
        [Validators.required, this.customValidatorsService.hostnameOrIp4OrIpValidator()],
      ],
    });
    return proxyFormGroup;
  }

  public addProxy(proxy?: AgentConnectorProxy) {
    this.getProxyListFormArray().push(this.newRoute(proxy));
  }

  public removeProxy(index: number) {
    if (index >= 0) {
      const targetProxyForm = this.getProxyListFormArray().at(index);
      const targetProxyOuterConnectorId = targetProxyForm?.value.proxy_id;
      if (!!targetProxyOuterConnectorId) {
        this.proxiesToDeleteIdList.push(targetProxyOuterConnectorId);
      }
      this.getProxyListFormArray().removeAt(index);
    }
  }

  public preventDeleteOnEnter(event: any): void {
    event.preventDefault();
  }

  public onConfirmClick(): void {
    this.saving = true;
    const proxyList = this.getProxyList();
    const observablesList$: Array<Observable<AgentConnectorProxy>> = [];
    for (const id of this.proxiesToDeleteIdList) {
      observablesList$.push(deleteExistingAgentConnectorProxy$(this.connectorsService, id, this.orgId));
    }
    for (const proxy of proxyList) {
      if (!proxy.metadata) {
        // Create new proxy:
        observablesList$.push(createNewAgentConnectorProxy$(this.connectorsService, proxy));
      } else {
        // Update existing proxy:
        observablesList$.push(updateExistingAgentConnectorProxy$(this.connectorsService, proxy));
      }
    }
    if (observablesList$.length === 0) {
      this.dialogRef.close();
    }
    forkJoin(observablesList$)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Connector proxies successfully updated');
          this.dialogRef.close();
        },
        (err) => {
          this.notificationService.error(`Failed to update connector proxies. Please try again.`);
        },
        () => {
          this.saving = false;
          this.changeDetector.detectChanges();
        }
      );
  }

  private getProxyList(): Array<AgentConnectorProxy> {
    const proxyList: Array<AgentConnectorProxy> = [];
    const proxyListFormControl = this.getProxyListFormArray().controls;
    for (const proxyForm of proxyListFormControl) {
      const proxyId = proxyForm.get('proxy_id').value;
      const outerConnectorName = proxyForm.get('connector_name').value;
      const bindPortFormValue = parseInt(proxyForm.get('bind_port').value.toString(), 10);
      const bindHostFormValue = proxyForm.get('bind_host').value;
      const updatedAgentConnectorProxySpec: AgentConnectorProxySpec = {
        org_id: this.orgId,
        inner_connector_id: this.agentConnector.metadata.id,
        outer_connector_id: this.connectorNameToConnectorMap.get(outerConnectorName).metadata.id,
      };
      if (!bindPortFormValue && !bindHostFormValue) {
        updatedAgentConnectorProxySpec.local_bind = undefined;
      } else {
        updatedAgentConnectorProxySpec.local_bind = {
          bind_port: bindPortFormValue,
          bind_host: bindHostFormValue,
        };
      }
      if (!!proxyId) {
        // Existing proxy
        const targetProxy = this.proxyIdToAgentConnectorProxyMap.get(proxyId);
        const targetProxyCopy = cloneDeep(targetProxy);
        targetProxyCopy.spec = {
          ...targetProxyCopy.spec,
          ...updatedAgentConnectorProxySpec,
        };
        proxyList.push(targetProxyCopy);
      } else {
        // New proxy
        proxyList.push({
          spec: updatedAgentConnectorProxySpec,
        });
      }
    }
    return proxyList;
  }

  public getBindPortTooltipText(): string {
    return `The port to bind to.
    "0" binds to a random, free port chosen by the system.
    Be careful to choose a port that the connector has permission to bind.
    E.g. on some systems, low-numbered ports such as 443 require special permissions.`;
  }

  public getBindHostTooltipText(): string {
    return `The host or IP address to bind to.
    "0.0.0.0" will bind to all IPv4 addresses.
    "::" will bind to all IPv6 addresses.
    Setting it to a hostname will cause the connector to bind to the IP of that host.`;
  }

  public getFilteredConnectorList(): Array<Connector> {
    const filteredConnectorList = this.connectorList.filter((connector) => {
      if (connector.metadata.id === this.agentConnector.metadata.id) {
        // Do not allow making connector outer of self
        return false;
      }
      for (const innerConnector of this.connectorIsOuterProxyList) {
        if (connector.metadata.id === innerConnector.metadata.id) {
          // Do not allow making an inner connector also an outer connector
          return false;
        }
      }
      return true;
    });
    return filteredConnectorList;
  }

  /**
   * Parent Table Column
   */
  private getConnectorColumn(): ReadonlyColumn<InnerConnectorProxyElement> {
    const column = createReadonlyColumn('connector_id');
    column.displayName = 'Connector';
    column.getDisplayValue = (elem: InnerConnectorProxyElement) => {
      const connector = this.connectorIdToConnectorMap.get(elem.connector_id);
      return !!connector ? connector.spec.name : elem.connector_id;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getBindPortColumn(): ReadonlyColumn<InnerConnectorProxyElement> {
    const column = createReadonlyColumn('bind_port');
    column.displayName = 'Port';
    return column;
  }

  /**
   * Parent Table Column
   */
  private getBindHostColumn(): ReadonlyColumn<InnerConnectorProxyElement> {
    const column = createReadonlyColumn('bind_host');
    column.displayName = 'Inner Connector Listeners';
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([this.getConnectorColumn(), this.getBindPortColumn(), this.getBindHostColumn()], this.columnDefs);
  }

  private updateTable(): void {
    this.buildData(this.connectorIsOuterProxyList);
    this.replaceTableWithCopy();
  }

  private buildData(connectorIsOuterProxyList: Array<AgentConnectorProxy>): void {
    const data: Array<InnerConnectorProxyElement> = [];
    for (let i = 0; i < connectorIsOuterProxyList.length; i++) {
      data.push(this.createInnerProxyElement(connectorIsOuterProxyList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createInnerProxyElement(proxy: AgentConnectorProxy, index: number): InnerConnectorProxyElement {
    const data: InnerConnectorProxyElement = {
      connector_id: proxy.spec?.inner_connector_id,
      bind_port: proxy.spec?.local_bind?.bind_port,
      bind_host: proxy.spec?.local_bind?.bind_host,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public disableSaveButton(): boolean {
    return (
      this.connectorProxiesFormGroup.invalid ||
      this.saving ||
      (this.getProxyListFormArray().length === 0 && this.proxiesToDeleteIdList.length === 0)
    );
  }
}

import { Label, LabelAssociation, LabelledObject, LabelsService, Resource, ResourceConfig, ResourcesService } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from '@app/core';
import {
  createNewLabelledObjectWithLabels$,
  getUpdatedLabels$,
  prepareDeleteLabelledObjectsObservablesArray$,
} from '@app/core/api/labels/labels-api-utils';
import { updateExistingResource$ } from '@app/core/api/resources/resources-api-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { concatMap, forkJoin, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { ButtonType } from '../button-type.enum';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { resetAutocompleteDropdownFilteredList } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { CheckboxOption, FilterManager } from '../filter/filter-manager';
import { ResourceLabelsDialogComponent, ResourceLabelsDialogData } from '../resource-labels-dialog/resource-labels-dialog.component';
import { ResourceType } from '../resource-type.enum';
import { getResourceTypeIcon, getResourceTypeTooltip } from '../resource-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties, isAtLeastOneRowSelected, isTableDirty } from '../table-layout-utils';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createIconColumn,
  createInputColumn,
  createResourceIconColumn,
  createSelectRowColumn,
  IconColumn,
  InputColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { areAllValuesPresentInArray, getIconURIFromResource, updateTableElements } from '../utils';
import { isValidLabelName } from '../validation-utils';
import { MatDialog } from '@angular/material/dialog';
import { getDefaultDialogConfig, getDefaultLogoDialogConfig } from '../dialog-utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { FilterType } from '../filter-type.enum';
import { ResourceWithLabels } from '../resource-with-labels';
import { ResourceLabelLinkService } from '@app/core/resource-label-link-service/resource-label-link.service';
import { ResourceAndLabelDataWithPermissions } from '../resource-and-label-data-with-permissions';
import { InputData } from '../custom-chiplist-input/input-data';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';

export interface ResourceOverviewElement extends ResourceWithLabels, TableElement {
  published: ResourceConfig.PublishedEnum;
}

export interface ResourceAndLabelsResponse {
  resource: Resource;
  labelledObject: LabelledObject;
  labels: Array<LabelAssociation>;
}

export enum ResourceFilterEnum {
  PUBLISH = 'Published Status',
  LABEL_STATUS = 'Label Status',
  LABEL_SELECT = 'Label Select',
}

export enum ResourceCheckboxOptions {
  HIDE_PUBLISHED = 'Hide published resources',
  HIDE_UNPUBLISHED = 'Hide unpublished resources',
  HIDE_LABELS = 'Hide resources with labels',
  HIDE_NO_LABELS = 'Hide resources without labels',
  LABEL_SELECT = 'Only show resources with the selected labels',
}

@Component({
  selector: 'portal-resource-overview',
  templateUrl: './resource-overview.component.html',
  styleUrls: ['./resource-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasLabelPermissions: boolean;
  public hasResourcePermissions: boolean;
  public hasPermissions: boolean;
  private orgId: string;
  public tableData: Array<ResourceOverviewElement> = [];
  public columnDefs: Map<string, Column<ResourceOverviewElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'LABEL';
  public warnOnNOperations = 1;
  public pageDescriptiveText = `Publish your resources so that your users may request access to them. 
  You may also associate your resources with labels. 
  Labels group resources on different dimensions, allowing you to filter, or organise them in different ways, while also accomplishing more complicated use-cases such as authorization.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/labels/`;
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resources: Array<Resource> = [];
  private resourceIdToLabelledObjectMap: Map<string, LabelledObject> = new Map();
  private labelledObjects: Array<LabelledObject> = [];
  public buttonsToShow: Array<ButtonType> = [];
  public customButtons: Array<TableButton> = [this.getBulkReplaceLabelButton(), this.getBulkDeleteLabelButton()];
  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'publish',
      {
        name: 'publish',
        displayName: ResourceFilterEnum.PUBLISH,
        icon: 'public',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'label_status',
      {
        name: 'label_status',
        displayName: ResourceFilterEnum.LABEL_STATUS,
        icon: 'label',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'label_select',
      {
        name: 'label_select',
        displayName: ResourceFilterEnum.LABEL_SELECT,
        icon: 'label_important',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);
  private hidePublishedResources = false;
  private hideUnpublishedResources = false;
  private hideLabelledResources = false;
  private hideUnlabelledResources = false;
  private selectedLabelNamesForFilter: Array<string> = [];
  private editingTable = false;
  public updateDataCount = 0;
  public delayedUpdateDataCount = 0;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<ResourceOverviewElement>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private resourcesService: ResourcesService,
    private labelsService: LabelsService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private resourceLabelLinkService: ResourceLabelLinkService
  ) {
    this.filterManager.addCheckboxFilterOption({
      name: ResourceCheckboxOptions.HIDE_PUBLISHED,
      displayName: ResourceCheckboxOptions.HIDE_PUBLISHED,
      label: this.filterMenuOptions.get('publish').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleHidePublishedResources.bind(this),
    });
    this.filterManager.addCheckboxFilterOption({
      name: ResourceCheckboxOptions.HIDE_UNPUBLISHED,
      displayName: ResourceCheckboxOptions.HIDE_UNPUBLISHED,
      label: this.filterMenuOptions.get('publish').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleHideUnpublishedResources.bind(this),
    });
    this.filterManager.addCheckboxFilterOption({
      name: ResourceCheckboxOptions.HIDE_LABELS,
      displayName: ResourceCheckboxOptions.HIDE_LABELS,
      label: this.filterMenuOptions.get('label_status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleHideLabelledResources.bind(this),
    });
    this.filterManager.addCheckboxFilterOption({
      name: ResourceCheckboxOptions.HIDE_NO_LABELS,
      displayName: ResourceCheckboxOptions.HIDE_NO_LABELS,
      label: this.filterMenuOptions.get('label_status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: this.toggleHideUnlabelledResources.bind(this),
    });
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getBulkReplaceLabelButton(): TableScopedButton {
    const button = new TableScopedButton(
      'BULK REPLACE LABELS',
      ButtonColor.PRIMARY,
      'Replace labels for all selected resources at once',
      'Button that replaces the labels for all the selected resources at once',
      () => {
        this.openBulkReplaceLabelsDialog();
      }
    );
    button.isDisabled = (elements: Array<ResourceOverviewElement>) => {
      return !isAtLeastOneRowSelected(elements);
    };
    return button;
  }

  private getBulkDeleteLabelButton(): TableScopedButton {
    const button = new TableScopedButton(
      'BULK DELETE LABELS',
      ButtonColor.WARN,
      'Delete labels for all selected resources at once',
      'Button that deletes the labels for all the selected resources at once',
      () => {
        this.onBulkDeleteLabelClick();
      }
    );
    button.isDisabled = (elements: Array<ResourceOverviewElement>) => {
      return !isAtLeastOneRowSelected(elements);
    };
    return button;
  }

  private isResourcePublished(resource: Resource): boolean {
    return resource.spec?.config?.published === ResourceConfig.PublishedEnum.public;
  }

  private getFilteredResources(allResourcesResp: Array<Resource>): Array<Resource> {
    let filteredResources = allResourcesResp;
    if (this.hidePublishedResources) {
      filteredResources = filteredResources.filter((resource) => !this.isResourcePublished(resource));
    }
    if (this.hideUnpublishedResources) {
      filteredResources = filteredResources.filter((resource) => this.isResourcePublished(resource));
    }
    if (this.hideLabelledResources) {
      filteredResources = filteredResources.filter(
        (resource) =>
          !this.resourceIdToLabelledObjectMap.get(resource.metadata.id)?.labels ||
          this.resourceIdToLabelledObjectMap.get(resource.metadata.id).labels.length === 0
      );
    }
    if (this.hideUnlabelledResources) {
      filteredResources = filteredResources.filter(
        (resource) =>
          !!this.resourceIdToLabelledObjectMap.get(resource.metadata.id)?.labels &&
          this.resourceIdToLabelledObjectMap.get(resource.metadata.id).labels.length !== 0
      );
    }
    if (this.selectedLabelNamesForFilter.length !== 0) {
      filteredResources = filteredResources.filter((resource) => {
        const labelsList = this.resourceIdToLabelledObjectMap.get(resource.metadata.id)?.labels;
        if (!labelsList || labelsList.length === 0) {
          return false;
        }
        return areAllValuesPresentInArray(
          this.selectedLabelNamesForFilter,
          labelsList.map((label) => label.label_name)
        );
      });
    }
    return filteredResources;
  }

  private getAndSetAllData(): void {
    this.resourceLabelLinkService
      .getResourceAndLabelDataWithPermissions$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelDataWithPermissionsResp: ResourceAndLabelDataWithPermissions) => {
        this.orgId = resourceAndLabelDataWithPermissionsResp.hasLabelPermissions?.orgId;
        this.hasLabelPermissions = resourceAndLabelDataWithPermissionsResp.hasLabelPermissions.hasPermission;
        this.hasResourcePermissions = resourceAndLabelDataWithPermissionsResp.hasResourcePermissions.hasPermission;
        const hasPermissions =
          resourceAndLabelDataWithPermissionsResp.hasLabelPermissions.hasPermission &&
          resourceAndLabelDataWithPermissionsResp.hasResourcePermissions.hasPermission;
        if (
          !hasPermissions ||
          !resourceAndLabelDataWithPermissionsResp.labelledObjects ||
          !resourceAndLabelDataWithPermissionsResp.labelsList ||
          !resourceAndLabelDataWithPermissionsResp.resourcesList
        ) {
          this.resetEmptyTable();
          return;
        }
        this.resources = this.getFilteredResources(resourceAndLabelDataWithPermissionsResp.resourcesList);
        this.labelledObjects = resourceAndLabelDataWithPermissionsResp.labelledObjects;
        this.setAllMaps();
        const labelColumn = this.columnDefs.get('labels');
        labelColumn.allowedValues = resourceAndLabelDataWithPermissionsResp.labelsList;
        this.setLabelListFilterOptions(resourceAndLabelDataWithPermissionsResp.labelsList);
        this.updateTable(this.resources);
        // Reset the form control so it rebuilds the list of allowed members:
        resetAutocompleteDropdownFilteredList(labelColumn);
        this.changeDetector.detectChanges();
      });
  }

  private setLabelListFilterOptions(labelsListResp: Array<Label>): void {
    const existingFilters = this.filterManager.checkboxOptions;
    for (const label of labelsListResp) {
      if (existingFilters.map((filter) => filter.name).includes(label.spec.name)) {
        // Prevent adding the same label multiple times
        continue;
      }
      this.filterManager.addCheckboxFilterOption({
        name: label.spec.name,
        displayName: label.spec.name,
        label: this.filterMenuOptions.get('label_select').displayName,
        type: FilterType.CHECKBOX,
        isChecked: false,
        doFilter: this.toggleLabelSelectFilter.bind(this),
      });
    }
  }

  public hasAllPermissions(): boolean {
    return this.hasLabelPermissions && this.hasResourcePermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasLabelPermissions !== undefined && this.hasResourcePermissions !== undefined && !this.hasAllPermissions();
  }

  private setAllMaps(): void {
    this.resourceIdToResourceMap.clear();
    this.resourceIdToLabelledObjectMap.clear();
    for (const resource of this.resources) {
      this.resourceIdToResourceMap.set(resource.metadata.id, resource);
    }
    for (const labelledObject of this.labelledObjects) {
      this.resourceIdToLabelledObjectMap.set(labelledObject.object_id, labelledObject);
    }
  }

  private updateTable(data: Array<Resource>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: Array<Resource>): void {
    const dataForTable: Array<ResourceOverviewElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: Resource, index: number): ResourceOverviewElement {
    const backingLabelledObjectResult = this.resourceIdToLabelledObjectMap.get(item.metadata.id);
    const data: ResourceOverviewElement = {
      ...getDefaultTableProperties(index),
      resource_type: item.spec.resource_type,
      name: item.spec.name,
      labels: !!backingLabelledObjectResult ? backingLabelledObjectResult.labels : [],
      previousLabels: !!backingLabelledObjectResult ? [...backingLabelledObjectResult.labels] : [],
      published: item.spec.config?.published,
      backingObject: item,
      backingLabelledObject: backingLabelledObjectResult,
    };
    return data;
  }

  private makeEmptyTableElement(): ResourceOverviewElement {
    const element: ResourceOverviewElement = {
      ...getDefaultNewRowProperties(),
      resource_type: undefined,
      name: undefined,
      labels: [],
      previousLabels: [],
      published: undefined,
      backingObject: undefined,
      backingLabelledObject: undefined,
    };
    return element;
  }

  /**
   * Parent Table Column
   */
  private getTypeColumn(): Column<ResourceOverviewElement> {
    const column = createIconColumn('resource_type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    column.getDisplayValue = (element: ResourceOverviewElement) => {
      return getResourceTypeIcon(element.resource_type as ResourceType);
    };
    column.getTooltip = (element: ResourceOverviewElement) => {
      return getResourceTypeTooltip(element.resource_type as ResourceType);
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<ResourceOverviewElement> {
    return createResourceIconColumn<InputData, ResourceOverviewElement>(
      this.getIconURIFromElement.bind(this),
      this.openIconDialog.bind(this)
    );
  }

  private getIconURIFromElement(element: ResourceOverviewElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: ResourceOverviewElement): void {
    const dialogData: ResourceLogoDialogData<any> = {
      overviewData: {
        element: element,
        localUpdateFunc: this.getAndSetAllData.bind(this),
        isLabel: false,
      },
      resourceType: element.resource_type as ResourceType,
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent table column
   */
  private getResourceNameColumn(): InputColumn<ResourceOverviewElement> {
    const column = createInputColumn('name');
    column.isEditable = false;
    column.isRowIdentifier = true;
    return column;
  }

  /**
   * Parent table column
   */
  private getLabelsColumn(): ChiplistColumn<ResourceOverviewElement> {
    const column = createChipListColumn('labels');
    column.getDisplayValue = (item: Label | LabelAssociation) => {
      const itemAsLabel = item as Label;
      if (!!itemAsLabel.metadata) {
        return !!itemAsLabel?.spec?.name ? itemAsLabel.spec.name : '';
      }
      const itemAsLabelAssociation = item as LabelAssociation;
      return !!itemAsLabelAssociation?.label_name ? itemAsLabelAssociation.label_name : '';
    };
    column.getElementFromValue = (labelString: string, element: ResourceOverviewElement): LabelAssociation => {
      return {
        label_name: labelString,
        org_id: this.orgId,
      };
    };
    column.isValidEntry = (item: Label | LabelAssociation): boolean => {
      const itemAsLabel = item as Label;
      if (!!itemAsLabel.spec) {
        return isValidLabelName(itemAsLabel.spec.name);
      }
      const itemAsLabelAssociation = item as LabelAssociation;
      return isValidLabelName(itemAsLabelAssociation.label_name);
    };
    column.isFreeform = true;
    column.getHeaderTooltip = () => {
      return `Labels group resources on different dimensions, allowing you to filter, or organise them in different ways, while also accomplishing more complicated use-cases such as authorization`;
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getPublishedColumn(): Column<ResourceOverviewElement> {
    const column = createCheckBoxColumn('published');
    column.isEditable = true;
    column.isChecked = (element: ResourceOverviewElement) => {
      return element.published === ResourceConfig.PublishedEnum.public;
    };
    column.setElementFromCheckbox = (element: ResourceOverviewElement, isBoxChecked: boolean): any => {
      if (isBoxChecked) {
        element.published = ResourceConfig.PublishedEnum.public;
      } else {
        element.published = ResourceConfig.PublishedEnum.no;
      }
    };
    column.getHeaderTooltip = () => {
      return `Checking this option makes the resource available for users to request access to the resource from their profiles. 
    Not checking it will mean it remains hidden and not available for requests.`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<ResourceOverviewElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ResourceOverviewElement>> = [
      {
        displayName: 'Configure Labels',
        icon: 'label',
        tooltip: 'Click to access the "Labels" advanced configuration',
        columnName: 'labels',
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getTypeColumn(),
        this.getIconColumn(),
        this.getResourceNameColumn(),
        this.getLabelsColumn(),
        this.getPublishedColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private updateResourceOverviewElement(updatedElement: ResourceOverviewElement, resourceResp: Resource): void {
    updatedElement.previousLabels = updatedElement.labels;
    updatedElement.backingObject = resourceResp;
  }

  private getResourceFromResourceElement(element: ResourceOverviewElement): Resource {
    return {
      ...element.backingObject,
      spec: {
        ...element.backingObject.spec,
        config: {
          ...element.backingObject.spec.config,
          published: element.published,
        },
      },
    };
  }

  private getUpdatedResource$(updatedElement: ResourceOverviewElement): Observable<Resource> {
    const updatedResource = this.getResourceFromResourceElement(updatedElement);
    return updateExistingResource$(this.resourcesService, updatedResource);
  }

  private getUpdatedResourceAndLabels$(updatedElement: ResourceOverviewElement): Observable<ResourceAndLabelsResponse> {
    const updateResource$: Observable<Resource | undefined> = this.getUpdatedResource$(updatedElement);
    return updateResource$.pipe(
      concatMap((updateResourceResp) => {
        if (!updatedElement.backingLabelledObject) {
          return createNewLabelledObjectWithLabels$(this.labelsService, updatedElement, updateResourceResp, this.orgId, true);
        }
        return getUpdatedLabels$(
          this.labelsService,
          updatedElement,
          updateResourceResp,
          updatedElement.backingLabelledObject,
          this.orgId,
          true
        );
      })
    );
  }

  private updateDataIfNotEditing(): void {
    this.updateDataCount = this.updateDataCount + 1;
    setTimeout(() => {
      this.delayedUpdateDataCount = this.delayedUpdateDataCount + 1;
      if (this.editingTable || isTableDirty(this.tableData) || this.updateDataCount !== this.delayedUpdateDataCount) {
        return;
      }
      this.getAndSetAllData();
    }, 5000);
  }

  private handleResourceAndLabelsUpdate(updatedElement: ResourceOverviewElement): void {
    this.editingTable = true;
    this.getUpdatedResourceAndLabels$(updatedElement)
      .pipe(take(1))
      .subscribe(
        (resourceAndLabelsResp) => {
          if (!!resourceAndLabelsResp.resource) {
            this.notificationService.success(`Resource "${updatedElement.name}" was successfully updated`);
          } else {
            this.notificationService.success(`Labels were successfully updated for resource "${updatedElement.name}"`);
          }
          this.updateResourceOverviewElement(updatedElement, resourceAndLabelsResp.resource);
          this.editingTable = false;
          this.updateDataIfNotEditing();
        },
        (errorResp) => {
          this.notificationService.error(`Failed to update resource "${updatedElement.name}". Please refresh the page and try again.`);
        }
      );
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: ResourceOverviewElement): void {
    this.handleResourceAndLabelsUpdate(updatedElement);
  }

  public deleteSelected(itemsToDelete: Array<ResourceOverviewElement>): void {
    this.deleteAllLabels(itemsToDelete);
  }

  private deleteAllLabels<T extends ResourceOverviewElement>(itemsToDelete: Array<T>): void {
    const observablesArray = prepareDeleteLabelledObjectsObservablesArray$(this.labelsService, itemsToDelete, this.orgId);
    if (observablesArray.length === 0) {
      this.getAndSetAllData();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Labels were successfully removed from all selected resources');
          this.getAndSetAllData();
        },
        (errorResp) => {
          this.notificationService.error('Failed to remove labels from all selected resources');
        }
      );
  }

  public openBulkReplaceLabelsDialog(): void {
    const dialogData: ResourceLabelsDialogData = {
      resourceElementsList: this.tableData.filter((element) => element.isChecked),
      chiplistInput: this.columnDefs.get('labels'),
      placeholderElement: this.makeEmptyTableElement(),
      orgId: this.orgId,
    };
    const dialogRef = this.dialog.open(
      ResourceLabelsDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.getAndSetAllData();
      }
    });
  }

  public onBulkDeleteLabelClick(): void {
    const deleteList = this.tableData.filter((element) => element.isChecked);
    const filteredDeleteList = deleteList.filter((element) => !!element.backingLabelledObject);
    if (filteredDeleteList.length === 0) {
      this.notificationService.success('No labels to delete');
      return;
    }
    const observablesArray = prepareDeleteLabelledObjectsObservablesArray$(this.labelsService, filteredDeleteList, this.orgId);
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Labels were successfully removed from all selected resources');
          this.getAndSetAllData();
        },
        (errorResp) => {
          this.notificationService.error('Failed to remove labels from all selected resources');
        }
      );
  }

  private toggleHidePublishedResources(checkboxOption: CheckboxOption): void {
    this.hidePublishedResources = checkboxOption.isChecked;
    this.getAndSetAllData();
  }

  private toggleHideUnpublishedResources(checkboxOption: CheckboxOption): void {
    this.hideUnpublishedResources = checkboxOption.isChecked;
    this.getAndSetAllData();
  }

  private toggleHideLabelledResources(checkboxOption: CheckboxOption): void {
    this.hideLabelledResources = checkboxOption.isChecked;
    this.getAndSetAllData();
  }

  private toggleHideUnlabelledResources(checkboxOption: CheckboxOption): void {
    this.hideUnlabelledResources = checkboxOption.isChecked;
    this.getAndSetAllData();
  }

  private toggleLabelSelectFilter(checkboxOption: CheckboxOption): void {
    if (checkboxOption.isChecked) {
      this.selectedLabelNamesForFilter.push(checkboxOption.name);
    } else {
      const targetIndex = this.selectedLabelNamesForFilter.indexOf(checkboxOption.name);
      this.selectedLabelNamesForFilter.splice(targetIndex, 1);
    }
    this.getAndSetAllData();
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

import { Directive, ElementRef, Renderer2, NgZone, Input, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[portalColumnResize]',
})
export class ColumnResizeDirective implements OnInit, OnDestroy {
  @Input() public resizableTable: HTMLElement | null = null;

  private startX: number;
  private startWidth: number;
  private isResizing = false;
  private column: HTMLElement;
  private resizer: HTMLElement;
  private destroy$ = new Subject<void>();
  private cachedPointerEventsValue: string;

  constructor(private el: ElementRef, private renderer: Renderer2, private zone: NgZone) {
    this.column = this.el.nativeElement;
  }

  public ngOnInit() {
    this.createResizer();
    this.initializeResizeListener();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createResizer() {
    this.resizer = this.renderer.createElement('div');
    this.renderer.addClass(this.resizer, 'column-resizer');
    this.renderer.setStyle(this.resizer, 'position', 'absolute');
    this.renderer.setStyle(this.resizer, 'right', '0');
    this.renderer.setStyle(this.resizer, 'top', '0');
    this.renderer.setStyle(this.resizer, 'width', '7px');
    this.renderer.setStyle(this.resizer, 'cursor', 'col-resize');
    this.renderer.appendChild(this.column, this.resizer);
  }

  private initializeResizeListener() {
    this.zone.runOutsideAngular(() => {
      fromEvent(this.resizer, 'mousedown')
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: MouseEvent) => this.onMouseDown(event));

      fromEvent(document, 'mousemove')
        .pipe(takeUntil(this.destroy$))
        .subscribe((event: MouseEvent) => this.onMouseMove(event));

      fromEvent(document, 'mouseup')
        .pipe(takeUntil(this.destroy$))
        .subscribe(() => this.onMouseUp());
    });
  }

  private onMouseDown(event: MouseEvent) {
    event.preventDefault();
    this.preventColumnSortClickOnResize();
    this.isResizing = true;
    this.startX = event.pageX;
    this.startWidth = this.column.offsetWidth;
  }

  private onMouseMove(event: MouseEvent) {
    if (!this.isResizing) {
      return;
    }
    const width = this.startWidth + (event.pageX - this.startX);
    this.renderer.setStyle(this.column, 'width', `${width}px`);
  }

  private onMouseUp() {
    if (!this.isResizing) {
      return;
    }
    this.enableColumnSortClickWhenResizeFinished();
    this.isResizing = false;
  }

  private preventColumnSortClickOnResize(): void {
    this.cachedPointerEventsValue = this.getPointerEventValueFromStyle();
    this.renderer.setStyle(this.column, 'pointer-events', 'none');
  }

  private enableColumnSortClickWhenResizeFinished(): void {
    this.renderer.setStyle(this.column, 'pointer-events', !!this.cachedPointerEventsValue ? this.cachedPointerEventsValue : 'auto');
  }

  private getPointerEventValueFromStyle(): string | undefined {
    const stylesString = this.column.getAttribute('style');
    const stylesAsArray = stylesString.split(';');
    for (const style of stylesAsArray) {
      const trimmedStyle = style.trim();
      if (trimmedStyle.includes('pointer-events')) {
        const trimmedStyleAsArray = trimmedStyle.split(':');
        const value = trimmedStyleAsArray[1];
        return !!value ? value.trim() : undefined;
      }
    }
    return undefined;
  }
}

import { NgModule } from '@angular/core';
import { TablePaginatorComponent } from './components/table-paginator/table-paginator.component';
import { TableLayoutComponent } from './components/table-layout/table-layout.component';
import { SharedModule } from '.';
import { PageSelectionDirective } from './components/table-paginator/page-selection.directive';
import { DropdownSelectorComponent } from './components/dropdown-selector/dropdown-selector.component';
import { ButtonsModule } from './components/buttons/buttons.module';
import { KeyInputDelayDirective } from './components/key-input-delay/key-input-delay.directive';
import { TableFilterComponent } from '../shared/components/table-filter/table-filter.component';
import { CustomChiplistInputModule } from './custom-chiplist-input.module';
import { CommonComponentsModule } from './common-components.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotificationService } from '@app/core';
import { FormLayoutComponent } from './components/form-layout/form-layout.component';
import { DemoSetupComponent } from './components/demo-setup/demo-setup.component';
import { NgrxStepperModule } from './ngrx-stepper.module';
import { ColumnResizeDirective } from './components/column-resize/column-resize.directive';

@NgModule({
  imports: [SharedModule, ButtonsModule, CustomChiplistInputModule, CommonComponentsModule, BrowserAnimationsModule, NgrxStepperModule],
  declarations: [
    TableLayoutComponent,
    TablePaginatorComponent,
    PageSelectionDirective,
    DropdownSelectorComponent,
    KeyInputDelayDirective,
    TableFilterComponent,
    FormLayoutComponent,
    DemoSetupComponent,
    ColumnResizeDirective,
  ],
  exports: [
    TableLayoutComponent,
    TablePaginatorComponent,
    ButtonsModule,
    TableFilterComponent,
    FormLayoutComponent,
    DemoSetupComponent,
    ColumnResizeDirective,
  ],
  providers: [NotificationService],
})
export class TableModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StartStepComponent } from './components/start-step/start-step.component';
import { StepperFormFieldComponent } from './components/stepper-form-field/stepper-form-field/stepper-form-field.component';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, MatRadioModule, MatFormFieldModule, MatIconModule, MatInputModule, MatTooltipModule],
  declarations: [StartStepComponent, StepperFormFieldComponent],
  exports: [StartStepComponent, StepperFormFieldComponent],
})
export class StepperModule {}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UsageMetricsComponent } from './usage-metrics.component';
import { TableModule } from '@app/shared/table.module';

@NgModule({
  imports: [CommonModule, TableModule, MatTooltipModule],
  declarations: [UsageMetricsComponent],
  exports: [UsageMetricsComponent],
})
export class UsageMetricsModule {}

import { Component, OnInit, Input, ViewChild, Output, EventEmitter, OnDestroy, ViewContainerRef, Renderer2 } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { getPageSizeOptions } from '@app/shared/utilities/tables/pagination';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { updateTableElements } from '../utils';

export interface CachedResults<T> {
  previousKey: string;
  nextKey: string;
  previousWindow: Array<T>;
}

export interface DataFetchedObj<T> {
  data: Array<T>;
  previousKey: string;
  nextKey: string;
  searchDirection: 'forwards' | 'backwards';
  limit?: number;
  error?: any;
}

export interface PageSelectionConfig {
  nextWindow: boolean;
  pageIndex: number;
  pageCount: number;
}

export interface UpdateTableParams {
  key?: string;
  searchDirection?: 'forwards' | 'backwards';
  limit?: number;
}

export class PaginatorActions<T> {
  public reloadWindowSubject: Subject<void>; // signals that the window needs to be reloaded
  public reloadFirstPageSubject: Subject<void>; // signals that table needs to be reloaded from the first page
  public dataFetchedSubject: Subject<DataFetchedObj<T>>; // signals component has finished fetching new table data
  public updateTableSubject: Subject<UpdateTableParams>; // signals component to fetch more data

  constructor() {
    this.reloadWindowSubject = new Subject<void>();
    this.reloadFirstPageSubject = new Subject<void>();
    this.dataFetchedSubject = new Subject<DataFetchedObj<T>>();
    this.updateTableSubject = new Subject<UpdateTableParams>();
  }

  public reloadWindow(): void {
    this.reloadWindowSubject.next();
  }

  public reloadFirstPage(): void {
    this.reloadFirstPageSubject.next();
  }

  public dataFetched(dataFetched: DataFetchedObj<T>): void {
    this.dataFetchedSubject.next(dataFetched);
  }

  public updateTable(params: UpdateTableParams): void {
    this.updateTableSubject.next(params);
  }

  public errorHandler(err: Error): void {
    this.dataFetched({
      data: [],
      searchDirection: 'forwards',
      limit: 0,
      error: err,
      nextKey: '',
      previousKey: '',
    });
  }
}

export class PaginatorConfig<T> {
  constructor(
    public alwaysShowPaginator: boolean = false,
    public showPageSelection: boolean = false,
    public paginatorPageSize: number = 25,
    public pageWindowSize?: number, // number of pages options that are displayed
    public actions?: PaginatorActions<T>,
    public keyName?: string, // name of property that is being used as the key
    public cachedResults?: CachedResults<T>
  ) {}

  public getQueryLimit(): number {
    return this.paginatorPageSize * this.pageWindowSize;
  }
}

@Component({
  selector: 'portal-table-paginator',
  templateUrl: './table-paginator.component.html',
})
export class TablePaginatorComponent<T> implements OnInit, OnDestroy {
  @Input() public paginatorConfig = new PaginatorConfig<T>();
  @Output() public updateTableData = new EventEmitter<any>();
  @Output() public doOnPageEvent = new EventEmitter<any>();

  public rowAdded = false; // indicates if a row has been added and the window needs to be reloaded

  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;

  private unsubscribe$: Subject<void> = new Subject<void>();
  public updatePageSelection = new Subject<PageSelectionConfig>(); // emitted when component has finished fetching new table data
  public paginatorPages: number; // total # of pages; incremented by pageWindowSize, reflects actual page count when all data is fetched
  public dataLength: number; // length of total data fetched
  public cachedData: Array<any> = []; // cached window data (previous + current window)
  public currentPageIndex = 0; // page index of currently fetched data (doesn't include dropped data)
  public paginatorPageIndex = 0; // index of current page (including pages from dropped data)
  constructor(private readonly vr: ViewContainerRef, private readonly ren: Renderer2) {}

  public ngOnInit(): void {
    this.paginatorPages = 0;
    this.dataLength = 0;
    if (this.paginatorConfig.cachedResults) {
      // data is back end paginated, get partial data
      if (!this.paginatorConfig.keyName || !this.paginatorConfig.actions || !this.paginatorConfig.pageWindowSize) {
        return;
      }
      this.subscribeToActions();
    } else if (this.paginatorConfig.showPageSelection) {
      if (!this.paginatorConfig.actions || !this.paginatorConfig.pageWindowSize) {
        return;
      }
      // fetched data is all data
      this.paginatorConfig.actions.dataFetchedSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: DataFetchedObj<T>) => {
        if (resp.error) {
          this.resetEmptyTable();
        } else {
          this.updateDataSource(resp.data);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public subscribeToActions(): void {
    this.paginatorConfig.actions.dataFetchedSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((resp: DataFetchedObj<T>) => {
      if (resp.error) {
        this.resetEmptyTable();
      } else {
        this.paginatorConfig.cachedResults.previousKey = resp.previousKey;
        this.paginatorConfig.cachedResults.nextKey = resp.nextKey;
        this.updatePaginator(resp.data, resp.searchDirection, resp.limit);
      }
    });
    this.paginatorConfig.actions.reloadWindowSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.reloadWindow();
    });
    this.paginatorConfig.actions.reloadFirstPageSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.reloadFirstPage();
    });
  }

  public updateDataSource(data: Array<T>): void {
    this.cachedData = data;
    const dataLength = this.cachedData.length;
    const pageSize = this.paginatorConfig.paginatorPageSize;
    this.paginatorPages = Math.floor(dataLength / pageSize) + 1;
    if (dataLength % pageSize === 0) {
      this.paginatorPages = dataLength / pageSize;
    }
    this.setTableData();
    this.updateButtons();
  }

  public updatePaginator(data: Array<any>, searchDirection: 'forwards' | 'backwards', limit: number): void {
    // when searching backwards, the next key is the first key of the window that is dropped
    if (searchDirection === 'backwards') {
      this.paginatorConfig.cachedResults.nextKey =
        this.cachedData[this.paginatorConfig.cachedResults.previousWindow.length][this.paginatorConfig.keyName];
    }
    // use '' as the previousKey if the previous window is the first window
    if (!this.isPageInFirstWindow() && this.paginatorPageIndex - this.paginatorConfig.pageWindowSize < 2) {
      this.paginatorConfig.cachedResults.previousKey = '';
    }
    this.cachedData = [];

    // check if we are only refreshing the current window
    if (limit <= this.paginatorConfig.getQueryLimit()) {
      // don't add to dataLength if we have already fetched this data
      if (this.paginatorPageIndex >= this.paginatorPages - 2) {
        this.dataLength += data.length;
      }
      data =
        searchDirection === 'forwards'
          ? [...this.paginatorConfig.cachedResults.previousWindow, ...data]
          : [...data, ...this.paginatorConfig.cachedResults.previousWindow];
    }
    updateTableElements(this.cachedData, data, this.paginatorConfig.keyName);

    if (searchDirection === 'forwards') {
      this.getPages(this.paginatorConfig.cachedResults.nextKey, limit);
    }

    this.setTableData();
    this.updateButtons();
  }

  private updateButtons(): void {
    this.paginator.length = this.paginatorPages * this.paginatorConfig.paginatorPageSize;
    this.paginator.pageIndex = this.currentPageIndex;
    if (this.paginatorPageIndex === 0) {
      // we are on the first page
      this.paginator.pageIndex = 0;
    }
    if (this.paginatorPageIndex === this.paginatorPages - 1) {
      // we are on the last page, disable the next page button
      this.paginator.length = this.paginatorConfig.paginatorPageSize;
    }
    this.updatePageSelection.next({
      nextWindow: this.paginatorConfig.cachedResults?.nextKey?.length > 0,
      pageIndex: this.paginatorPageIndex,
      pageCount: this.paginatorPages,
    });
  }

  private getPages(nextPageKey: string, limit: number): void {
    const pageSize = this.paginatorConfig.paginatorPageSize;
    if (!nextPageKey) {
      // we have fetched all available data
      this.paginatorPages = Math.floor(this.dataLength / pageSize) + 1;
      if (this.dataLength % pageSize === 0) {
        this.paginatorPages = this.dataLength / pageSize;
      }
    } else {
      // don't add to total page count if we are refetching the cached data
      if (limit === pageSize * this.paginatorConfig.pageWindowSize && this.paginatorPageIndex >= this.paginatorPages - 2) {
        this.paginatorPages += this.paginatorConfig.pageWindowSize;
      }
    }
  }

  public setTableData(): void {
    // assign current page data to table
    const start = this.currentPageIndex * this.paginatorConfig.paginatorPageSize;
    const end =
      start + this.paginatorConfig.paginatorPageSize > this.cachedData.length
        ? this.cachedData.length
        : start + this.paginatorConfig.paginatorPageSize;
    this.updateTableData.emit(this.cachedData.slice(start, end));
  }

  public usePaginator(): boolean {
    if (this.paginatorConfig.alwaysShowPaginator) {
      return true;
    }
    return this.paginator.length > 25;
  }

  public useSharedPageSizeOptions(): Array<number> {
    if (this.paginatorConfig.showPageSelection) {
      // limit max page size or page window can get unwieldy
      return [25, 50, 100];
    }
    return getPageSizeOptions(this.paginator.length, this.paginatorConfig.paginatorPageSize);
  }

  private handleOnPageEvent(event: PageEvent): void {
    if (!this.paginatorConfig.showPageSelection) {
      return;
    }
    // disable pagination buttons
    const pagingContainer = this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
    this.ren.addClass(pagingContainer, 'disable-pagination-actions');

    // update index wrt total fetched data
    this.paginatorPageIndex += event.pageIndex - event.previousPageIndex;
    this.currentPageIndex = event.pageIndex;

    // clear selection checkbox for each row
    this.clearPageSelection();
    if (this.paginatorConfig.paginatorPageSize !== event.pageSize) {
      // page size changed, reload data from the first page
      this.paginatorConfig.paginatorPageSize = event.pageSize;
      this.reloadFirstPage();
    } else if (!this.paginatorConfig.cachedResults) {
      // don't fetch more data if data is not back-end paginated
      this.setTableData();
      this.updateButtons();
    } else if (this.isNearWindowEnd()) {
      // get next window of pages when we are near the end of the current window cache and more data exists
      this.paginatorConfig.cachedResults.previousWindow =
        this.paginatorConfig.cachedResults.previousWindow.length > 0
          ? this.cachedData.slice(this.paginatorConfig.cachedResults.previousWindow.length)
          : [...this.cachedData];
      if (!this.isPageInFirstWindow()) {
        this.currentPageIndex -= this.paginatorConfig.pageWindowSize;
      }
      this.paginatorConfig.actions.updateTable({
        key: this.paginatorConfig.cachedResults.nextKey,
        searchDirection: 'forwards',
        limit: this.paginatorConfig.getQueryLimit(),
      });
    } else if (this.currentPageIndex <= 1 && this.paginatorConfig.cachedResults.previousKey.length > 0) {
      // get previous window if we are near the start of the current window and previous data exists
      this.paginatorConfig.cachedResults.previousKey = this.cachedData[0][this.paginatorConfig.keyName];
      this.paginatorConfig.cachedResults.previousWindow = this.cachedData.slice(
        0,
        this.paginatorConfig.cachedResults.previousWindow.length
      );
      if (this.paginatorPageIndex >= this.paginatorConfig.pageWindowSize) {
        this.currentPageIndex += this.paginatorConfig.pageWindowSize;
        this.paginatorConfig.actions.updateTable({
          key: this.paginatorConfig.cachedResults.previousKey,
          searchDirection: 'backwards',
          limit: this.paginatorConfig.getQueryLimit(),
        });
      } else {
        // still having to go back on the first window means that new rows were added before the cached window
        this.paginatorConfig.cachedResults.previousKey = '';
        this.cachedData = [];
        this.reloadWindow();
      }
    } else if (this.rowAdded) {
      // reload current window if a new row was added
      if (this.isPageInFirstWindow()) {
        this.paginatorConfig.cachedResults.previousKey = '';
        this.cachedData = [];
      }
      this.reloadWindow();
      this.rowAdded = false;
    } else {
      // get already fetched page of data
      // if pageIndex === previousPageIndex, then page event was manually triggered
      this.setTableData();
      this.updateButtons();
    }
  }

  public onPageEvent(event: PageEvent): void {
    this.handleOnPageEvent(event);
    this.doOnPageEvent.emit();
  }

  public clearPageSelection(): void {
    for (const row of this.cachedData) {
      row.isChecked = false;
    }
  }

  private isPageInFirstWindow(): boolean {
    return this.paginatorPageIndex < this.paginatorConfig.pageWindowSize;
  }

  private isNearWindowEnd(): boolean {
    const cachedWindowSize =
      this.paginatorPages > this.paginatorConfig.pageWindowSize ? this.paginatorConfig.pageWindowSize * 2 : this.paginatorPages;
    return this.currentPageIndex >= cachedWindowSize - 2 && this.paginatorConfig.cachedResults.nextKey.length > 0;
  }

  private reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorPageIndex = 0;
    this.paginatorPages = 0;
    this.currentPageIndex = 0;
    this.dataLength = 0;
    if (this.paginatorConfig.cachedResults) {
      this.paginatorConfig.cachedResults.previousWindow = [];
    }
    this.paginatorConfig.actions.updateTable({
      key: '',
      searchDirection: 'forwards',
      limit: this.paginatorConfig.getQueryLimit(),
    });
  }

  private reloadWindow(): void {
    let limitParam = this.paginatorConfig.getQueryLimit() * 2;
    if (this.paginatorConfig.cachedResults.previousKey === '' && this.cachedData.length === 0) {
      // refresh first page
      limitParam = limitParam / 2;
      this.dataLength = 0;
      this.paginatorPages = 0;
      this.paginatorConfig.cachedResults.previousWindow = [];
    } else {
      this.paginatorConfig.cachedResults.previousKey = this.cachedData[0][this.paginatorConfig.keyName];
    }
    this.paginatorConfig.actions.updateTable({
      key: this.paginatorConfig.cachedResults.previousKey,
      searchDirection: 'forwards',
      limit: limitParam,
    });
  }

  private resetEmptyTable(): void {
    this.paginatorPageIndex = 0;
    this.paginatorPages = 0;
    this.currentPageIndex = 0;
    this.dataLength = 0;
    this.paginatorConfig.cachedResults.previousWindow = [];
    this.updateTableData.emit([]);
    this.updateButtons();
  }
}

import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, APP_INITIALIZER, Provider, ModuleWithProviders, Injectable, isDevMode } from '@angular/core';

import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { SharedModule } from '@app/shared';
import { TableModule } from '@app/shared/table.module';
import { CoreModule } from '@app/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { GravatarModule } from 'ngx-gravatar';
import { CookieService } from 'ngx-cookie-service';
import {
  ShowHidePasswordComponent,
  ShowHideInputDirective,
  ShowHideTriggerDirective,
  ShowHideStatusDirective,
} from 'ngx-show-hide-password';

import { MAT_DATE_LOCALE } from '@angular/material/core';

import { WelcomeComponent } from './static/welcome/welcome.component';
import { FooterComponent } from './static/footer/footer.component';
import { NewsComponent } from './shared/components/news/news.component';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { TextCopyDirective } from './shared/copy-text.directive';
import { PermissionsAdminComponent } from './shared/components/permissions-admin/permissions-admin.component';
import { OrgAdminComponent } from './shared/components/org-admin/org-admin.component';
import { ApplicationEnvironmentComponent } from './shared/components/application-environment/application-environment.component';
import { ApplicationDefineComponent } from './shared/components/application-define/application-define.component';
import { ApplicationDiagnoseComponent } from './shared/components/application-diagnose/application-diagnose.component';
import { ApplicationRolesComponent } from './shared/components/application-roles/application-roles.component';
import { ApplicationOverviewComponent } from './shared/components/application-main/application-main.component';
import { DynamicEnvironmentService } from './core/services/dynamic-environment.init';
import { MetricsTopUsersComponent } from './shared/components/metrics-top-users/metrics-top-users.component';
import { MetricsActiveUsersComponent } from './shared/components/metrics-active-users/metrics-active-users.component';
import { GroupAdminComponent } from './shared/components/group-admin/group-admin.component';
import { UserAdminComponent } from './shared/components/user-admin/user-admin.component';
import { SubOrgAdminComponent } from './shared/components/sub-org-admin/sub-org-admin.component';
import { EffectsModule } from '@ngrx/effects';
import { ApiApplicationsEffects } from './core/api-applications/api-applications.effects';
import { OrganisationsEffects } from './core/organisations/organisations.effects';
import { UserEffects } from './core/user/user.effects';
import { ApplicationInstancesComponent } from './shared/components/application-instances/application-instances.component';
import { NgrxRouterStoreModule } from './core/merged-route/ngrx-router.module';
import { ApplicationRuleConfigComponent } from './shared/components/application-rule-config/application-rule-config.component';
import { ApplicationQueryParametersComponent } from './shared/components/application-query-parameters/application-query-parameters.component';
import { ApplicationBodyComponent } from './shared/components/application-body/application-body.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';
import { NetworkOverviewComponent } from './shared/components/network-overview/network-overview.component';
import { UsersCsvComponent } from './shared/components/users-csv/users-csv.component';
import { ApiModule, apiConfigFactory, ApiConfiguration, Configuration } from '@agilicus/angular';
import { StepperProgressBarModule } from '@agilicus/stepper-progress-bar';
import { ApplicationEnvironmentVariablesComponent } from './shared/components/application-environment-variables/application-environment-variables.component';
import { ApplicationEnvironmentFilesComponent } from './shared/components/application-environment-files/application-environment-files.component';
import { ApplicationAuthClientsComponent } from './shared/components/application-auth-clients/application-auth-clients.component';
import { ApplicationAuthClientsDialogComponent } from './shared/components/application-auth-clients/application-auth-clients-dialog.component';
import { IssuerClientsEffects } from './core/issuer-clients/issuer-clients.effects';
import { PolicyEffects } from './core/issuer/issuer.effects';
import { DisableKeyTabDirective } from './shared/components/disable-key-tab-directive/disable-key-tab.directive';
import { DroppableDirective } from './shared/components/droppable-directive/droppable.directive';
import { ApplicationBundlesComponent } from './shared/components/application-bundles/application-bundles.component';
import { OrgDeleteConfirmationDialogComponent } from './shared/components/org-delete-confirmation-dialog/org-delete-confirmation-dialog.component';
import { ApplicationEnvironmentExternalMountsComponent } from './shared/components/application-environment-external-mounts/application-environment-external-mounts.component';
import { ApplicationRulesComponent } from './shared/components/application-rules/application-rules.component';
import { ApplicationEnvironmentStatusComponent } from './shared/components/application-environment-status/application-environment-status.component';
import { ApplicationContentSecurityPolicyComponent } from './shared/components/application-content-security-policy/application-content-security-policy.component';
import { AuthAuditComponent } from './shared/components/auth-audit/auth-audit.component';
import { UserDiagnoseComponent } from './shared/components/user-diagnose/user-diagnose.component';
import { UserAppAccessRequestsComponent } from './shared/components/user-app-access-requests/user-app-access-requests.component';
import { UploadAreaComponent } from './shared/components/upload-area/upload-area.component';
import { ApplicationEnvironmentTemporaryDirectoriesComponent } from './shared/components/application-environment-temporary-directories/application-environment-temporary-directories.component';
import { SignupComponent } from './shared/components/signup/signup.component';
import { ApplicationStepperComponent } from './shared/components/application-stepper/application-stepper.component';
import { MatVerticalStepperScrollerDirective } from './shared/components/mat-vertical-stepper-scroller/mat-vertical-stepper-scroller.directive';
import { ApplicationHostMethodStepperComponent } from './shared/components/application-host-method-stepper/application-host-method-stepper.component';
import { ApplicationAuthFlowStepperComponent } from './shared/components/application-auth-flow-stepper/application-auth-flow-stepper.component';
import { SignupEffects } from './core/signup/signup.effects';
import { UpstreamProviderSetupDialogComponent } from './shared/components/upstream-provider-setup-dialog/upstream-provider-setup-dialog.component';
import { ApplicationModelEffects } from './core/application-model/application-model.effects';
import { PreventStepperAdvanceDirective } from './shared/components/prevent-stepper-advance/prevent-stepper-advance.directive';
import { ConnectorAdminComponent } from './shared/components/connector-admin/connector-admin.component';
import { ConnectorDownloadDialogComponent } from './shared/components/connector-download-dialog/connector-download-dialog.component';
import { ConnectorStepperComponent } from './shared/components/connector-stepper/connector-stepper.component';
import { FileSharesStepperComponent } from './shared/components/file-shares-stepper/file-shares-stepper.component';
import { FileSharesOverviewComponent } from './shared/components/file-shares-overview/file-shares-overview.component';
import { ResourcePermissionsComponent } from './shared/components/resource-permissions/resource-permissions.component';
import { UserResourceAccessRequestsComponent } from './shared/components/user-resource-access-requests/user-resource-access-requests.component';
import { ConnectionStepperComponent } from './shared/components/connection-stepper/connection-stepper.component';
import { ProxyLocationSelectionComponent } from './shared/components/proxy-location-selection/proxy-location-selection.component';
import { ForwardingServiceComponent } from './shared/components/forwarding-service/forwarding-service.component';
import { WebApplicationSecurityComponent } from './shared/components/web-application-security/web-application-security.component';
import { MediaTypesComponent } from './shared/components/media-types/media-types.component';
import { OtherMappingsComponent } from './shared/components/other-mappings/other-mappings.component';
import { HttpRewritesComponent } from './shared/components/http-rewrites/http-rewrites.component';
import { CorsConfigComponent } from './shared/components/cors-config/cors-config.component';
import { CorsAllowOriginsComponent } from './shared/components/cors-allow-origins/cors-allow-origins.component';
import { CorsAllowMethodsComponent } from './shared/components/cors-allow-methods/cors-allow-methods.component';
import { CorsAllowHeadersComponent } from './shared/components/cors-allow-headers/cors-allow-headers.component';
import { CorsExposeHeadersComponent } from './shared/components/cors-expose-headers/cors-expose-headers.component';
import { UpstreamGroupMappingsComponent } from './shared/components/upstream-group-mappings/upstream-group-mappings.component';
import { UpstreamGroupMappingsDialogComponent } from './shared/components/upstream-group-mappings-dialog/upstream-group-mappings-dialog.component';
import { DesktopStepperComponent } from './shared/components/desktop-stepper/desktop-stepper.component';
import { DesktopOverviewComponent } from './shared/components/desktop-overview/desktop-overview.component';
import { ProgressSpinnerDialogComponent } from './shared/components/progress-spinner-dialog/progress-spinner-dialog.component';
import { TaskBoardComponent } from './static/task-board/task-board.component';
import { BillingComponent } from './shared/components/billing/billing.component';
import { HeaderOverridesComponent } from './shared/components/header-overrides/header-overrides.component';
import { SecurityHeadersComponent } from './shared/components/security-headers/security-headers.component';
import { SecurityHeaderGenericFormComponent } from './shared/components/security-header-generic-form/security-header-generic-form.component';
import { ResourceGroupAdminComponent } from './shared/components/resource-group-admin/resource-group-admin.component';
import { ServiceAccountAdminComponent } from './shared/components/service-account-admin/service-account-admin.component';
import { LauncherOverviewComponent } from './shared/components/launcher-overview/launcher-overview.component';
import { CustomChiplistInputModule } from './shared/custom-chiplist-input.module';
import { GettingStartedComponent } from './static/getting-started/getting-started.component';
import { HammerModule } from '@angular/platform-browser';
import * as Hammer from 'hammerjs';
import { IdentifyUsersGuideComponent } from './static/identify-users-guide/identify-users-guide.component';
import { ConnectToSitesGuideComponent } from './static/connect-to-sites-guide/connect-to-sites-guide.component';
import { ExposeResourceGuideComponent } from './static/expose-resource-guide/expose-resource-guide.component';
import { AssignPermissionsGuideComponent } from './static/assign-permissions-guide/assign-permissions-guide.component';
import { AuditUserGuideComponent } from './static/audit-user-guide/audit-user-guide.component';
import { SetupPaymentGuideComponent } from './static/setup-payment-guide/setup-payment-guide.component';
import { GettingStartedRecommendationsComponent } from './static/getting-started-recommendations/getting-started-recommendations.component';
import { ThemingComponent } from './shared/components/theming/theming.component';
import { SharedIdentityComponent } from './shared/components/shared-identity/shared-identity.component';
import { CustomIdentityComponent } from './shared/components/custom-identity/custom-identity.component';
import { OnsiteIdentityComponent } from './shared/components/onsite-identity/onsite-identity.component';
import { ApplicationIdentityComponent } from './shared/components/application-identity/application-identity.component';
import { ExpansionPanelControlButtonsComponent } from './shared/components/expansion-panel-control-buttons/expansion-panel-control-buttons.component';
import { AuditDestinationsComponent } from './shared/components/audit-destinations/audit-destinations.component';
import { AuthPresetDialogComponent } from './shared/components/auth-preset-dialog/auth-preset-dialog.component';
import { SideNavMenuComponent } from './shared/components/submenu/submenu.component';
import { ConnectorDownloadInstructionsComponent } from './shared/components/connector-download-instructions/connector-download-instructions.component';
import { AuditSubsystemComponent } from './shared/components/audit-subsystem/audit-subsystem.component';
import { StateDrivenCrudEffects } from './core/api/state-driven-crud/state-driven-crud.effects';
import { CommonModule } from '@angular/common';
import { StepperModule } from './shared/stepper.module';
import { CommonComponentsModule } from './shared/common-components.module';
import { AppExpansionPanelStateModule } from './shared/components/app-expansion-panel-state/app-expansion-panel-state.module';
import { AuthIssuerPolicyModule } from './shared/components/auth-issuer-policy/auth-issuer-policy.module';
import { UsageMetricsModule } from './shared/components/usage-metrics/usage-metrics.module';
import { ConnectorBasedStepperModule } from './shared/connector-based-stepper.module';
import { SshStepperComponent } from './shared/components/ssh-stepper/ssh-stepper.component';
import { SshOverviewComponent } from './shared/components/ssh-overview/ssh-overview.component';
import { ApplicationServicesEffects } from './core/application-service-state/application-service.effects';
import { IssuerEffects } from './core/issuer-state/issuer.effects';
import { AuthzErrorService } from './core/services/interceptors/authz-interceptor.service';
import { ConnectorEffects } from './core/connector-state/connector.effects';
import { AgentConnectorEffects } from './core/agent-connector-state/agent-connector.effects';
import { FileShareServiceEffects } from './core/file-share-state/file-share-service.effects';
import { DesktopResourceEffects } from './core/desktop-state/desktop.effects';
import { LauncherEffects } from './core/launcher-state/launcher.effects';
import { NetworkStepperComponent } from './shared/components/network-stepper/network-stepper.component';
import { ResourceLogoDialogComponent } from './shared/components/resource-logo-dialog/resource-logo-dialog.component';
import { SSHResourceEffects } from './core/ssh-state/ssh.effects';
import { LauncherStepperComponent } from './shared/components/launcher-stepper/launcher-stepper.component';
import { UserIdentityDialogComponent } from './shared/components/user-identity-dialog/user-identity-dialog.component';
import { BillingAccountFullEffects } from './core/billing-state/billing-account-full.effects';
import { ConnectorRoutesDialogComponent } from './shared/components/connector-routes-dialog/connector-routes-dialog.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InboxMessageDialogComponent } from './shared/components/inbox-message-dialog/inbox-message-dialog.component';
import { NotificationsComponent } from './shared/components/notifications/notifications.component';
import { InboxItemEffects } from './core/inbox-item-state/inbox-item.effects';
import { UserEndDateDialogComponent } from './shared/components/user-end-date-dialog/user-end-date-dialog.component';
import { PolicyConditionDialogComponent } from './shared/components/policy-condition-dialog/policy-condition-dialog.component';
import { UIStateEffects } from './core/ui/ui.effects';
import { ConnectorStatsDialogComponent } from './shared/components/connector-stats-dialog/connector-stats-dialog.component';
import { NgrxStepperModule } from './shared/ngrx-stepper.module';
import { ResourceOverviewComponent } from './shared/components/resource-overview/resource-overview.component';
import { SshCredentialsDialogComponent } from './shared/components/ssh-credentials-dialog/ssh-credentials-dialog.component';
import { AuthenticationOverviewComponent } from './shared/components/authentication-overview/authentication-overview.component';
import { ResourceLabelsDialogComponent } from './shared/components/resource-labels-dialog/resource-labels-dialog.component';
import { GroupsCsvComponent } from './shared/components/groups-csv/groups-csv.component';
import { SubOrgStepperComponent } from './shared/components/sub-org-stepper/sub-org-stepper.component';
import { ConnectorProxyDialogComponent } from './shared/components/connector-proxy-dialog/connector-proxy-dialog.component';
import { PaymentDialogComponent } from './shared/components/payment-dialog/payment-dialog.component';
import { PolicyTemplatesComponent } from './shared/components/policy-templates/policy-templates.component';
import { PolicyTemplateInstanceEffects } from './core/policy-template-instance-state/policy-template-instance.effects';
import { PolicyTemplateDialogComponent } from './shared/components/policy-template-dialog/policy-template-dialog.component';
import { StepperDoneConfigOptionsComponent } from './shared/components/stepper-done-config-options/stepper-done-config-options.component';
import { NetworkCsvDialogComponent } from './shared/components/network-csv-dialog/network-csv-dialog.component';
import { StripeCheckoutDialogComponent } from './shared/components/stripe-checkout-dialog/stripe-checkout-dialog.component';
import { ApplicationPolicyRulesComponent } from './shared/components/application-policy-rules/application-policy-rules.component';
import { ApplicationGeolocationRulesComponent } from './shared/components/application-geolocation-rules/application-geolocation-rules.component';
import { ChiplistExpandDialogComponent } from './shared/components/chiplist-expand-dialog/chiplist-expand-dialog.component';
import { FaqComponent } from './shared/components/faq/faq.component';
import { ChiplistExpandLoadingDialogComponent } from './shared/components/chiplist-expand-loading-dialog/chiplist-expand-loading-dialog.component';
import { LoadingDataComponent } from './shared/components/loading-data/loading-data.component';
import { AuditDestinationConnectorDialogComponent } from './shared/components/audit-destination-connector-dialog/audit-destination-connector-dialog.component';
import { ResourceGroupsCsvComponent } from './shared/components/resource-groups-csv/resource-groups-csv.component';
import { PathValidationDialogComponent } from './shared/components/path-validation-dialog/path-validation-dialog.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { LabelOverviewComponent } from './shared/components/label-overview/label-overview.component';
import { DesktopExtraConfigDialogComponent } from './shared/components/desktop-extra-config-dialog/desktop-extra-config-dialog.component';
import { DesktopExtraConfigComponent } from './shared/components/desktop-extra-config/desktop-extra-config.component';
import { SupportRequestsOverviewComponent } from './shared/components/support-requests-overview/support-requests-overview.component';
import { SupportRequestResetExpiryDialogComponent } from './shared/components/support-request-reset-expiry-dialog/support-request-reset-expiry-dialog.component';
import { ApiKeyOverviewComponent } from './shared/components/api-key-overview/api-key-overview.component';
import { ApiKeyNewComponent } from './shared/components/api-key-new/api-key-new.component';

export function init_app(env: DynamicEnvironmentService, cfg: ApiConfiguration) {
  return async () => {
    await env.init();
    // Here we update the singleton api configuration with the newly fetched
    // api domain. We need to do this here, as opposed to depending on the dynamic service
    // in config factory because the app init is the only place to do async initializers, and
    // it does not fire until after all the dependencies have been resolved.
    // This relies on the configuration being passed as a pointer to the API services.
    const apiDomain = env.environment.overrideApiDomain;
    if (apiDomain) {
      cfg.basePath = apiDomain;
    }
  };
}

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 750,
  hideDelay: 0,
  touchendHideDelay: 1500,
  disableTooltipInteractivity: true,
};

export function envOverrideConfigFactory(env: DynamicEnvironmentService): ApiConfiguration {
  const baseConfig = apiConfigFactory();
  return new ApiConfiguration(baseConfig);
}

const apiProvider: ModuleWithProviders<ApiModule> = {
  ngModule: ApiModule,
  providers: [
    {
      provide: Configuration,
      useFactory: envOverrideConfigFactory,
      deps: [DynamicEnvironmentService],
    },
  ],
};

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  buildHammer(element: HTMLElement): HammerManager {
    return new Hammer.Manager(element, {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [
        [
          Hammer.Swipe,
          {
            direction: Hammer.DIRECTION_HORIZONTAL,
          },
        ],
      ],
    });
  }
}

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    WelcomeComponent,
    ProfileComponent,
    OrgAdminComponent,
    PermissionsAdminComponent,
    NewsComponent,
    TextCopyDirective,
    ApplicationEnvironmentComponent,
    ApplicationDefineComponent,
    ApplicationDiagnoseComponent,
    ApplicationRolesComponent,
    GroupAdminComponent,
    UserAdminComponent,
    SubOrgAdminComponent,
    ApplicationOverviewComponent,
    SideNavMenuComponent,
    ApplicationInstancesComponent,
    ApplicationRuleConfigComponent,
    ApplicationQueryParametersComponent,
    ApplicationBodyComponent,
    NetworkOverviewComponent,
    UsersCsvComponent,
    ApplicationEnvironmentVariablesComponent,
    ApplicationEnvironmentFilesComponent,
    MetricsTopUsersComponent,
    MetricsActiveUsersComponent,
    ApplicationAuthClientsComponent,
    ApplicationAuthClientsDialogComponent,
    DisableKeyTabDirective,
    DroppableDirective,
    ApplicationBundlesComponent,
    OrgDeleteConfirmationDialogComponent,
    ApplicationEnvironmentExternalMountsComponent,
    ApplicationRulesComponent,
    ApplicationEnvironmentStatusComponent,
    ApplicationContentSecurityPolicyComponent,
    AuthAuditComponent,
    UserDiagnoseComponent,
    UserAppAccessRequestsComponent,
    UploadAreaComponent,
    ApplicationEnvironmentTemporaryDirectoriesComponent,
    SignupComponent,
    ApplicationStepperComponent,
    MatVerticalStepperScrollerDirective,
    ApplicationHostMethodStepperComponent,
    ApplicationAuthFlowStepperComponent,
    UpstreamProviderSetupDialogComponent,
    PreventStepperAdvanceDirective,
    ConnectorAdminComponent,
    ConnectorDownloadDialogComponent,
    ConnectorStepperComponent,
    FileSharesStepperComponent,
    FileSharesOverviewComponent,
    ResourcePermissionsComponent,
    UserResourceAccessRequestsComponent,
    ConnectionStepperComponent,
    ProxyLocationSelectionComponent,
    ForwardingServiceComponent,
    WebApplicationSecurityComponent,
    MediaTypesComponent,
    OtherMappingsComponent,
    HttpRewritesComponent,
    CorsConfigComponent,
    CorsAllowOriginsComponent,
    CorsAllowMethodsComponent,
    CorsAllowHeadersComponent,
    CorsExposeHeadersComponent,
    UpstreamGroupMappingsComponent,
    UpstreamGroupMappingsDialogComponent,
    DesktopStepperComponent,
    DesktopOverviewComponent,
    ProgressSpinnerDialogComponent,
    TaskBoardComponent,
    BillingComponent,
    HeaderOverridesComponent,
    SecurityHeadersComponent,
    SecurityHeaderGenericFormComponent,
    ResourceGroupAdminComponent,
    ServiceAccountAdminComponent,
    LauncherOverviewComponent,
    GettingStartedComponent,
    IdentifyUsersGuideComponent,
    ConnectToSitesGuideComponent,
    ExposeResourceGuideComponent,
    AssignPermissionsGuideComponent,
    AuditUserGuideComponent,
    SetupPaymentGuideComponent,
    GettingStartedRecommendationsComponent,
    ThemingComponent,
    SharedIdentityComponent,
    CustomIdentityComponent,
    OnsiteIdentityComponent,
    ApplicationIdentityComponent,
    ExpansionPanelControlButtonsComponent,
    AuditDestinationsComponent,
    AuthPresetDialogComponent,
    ConnectorDownloadInstructionsComponent,
    AuditSubsystemComponent,
    SshStepperComponent,
    SshOverviewComponent,
    NetworkStepperComponent,
    ResourceLogoDialogComponent,
    LauncherStepperComponent,
    UserIdentityDialogComponent,
    StepperDoneConfigOptionsComponent,
    ConnectorRoutesDialogComponent,
    InboxMessageDialogComponent,
    NotificationsComponent,
    UserEndDateDialogComponent,
    PolicyConditionDialogComponent,
    ConnectorStatsDialogComponent,
    ResourceOverviewComponent,
    SshCredentialsDialogComponent,
    AuthenticationOverviewComponent,
    ResourceLabelsDialogComponent,
    GroupsCsvComponent,
    SubOrgStepperComponent,
    ConnectorProxyDialogComponent,
    PaymentDialogComponent,
    PolicyTemplatesComponent,
    PolicyTemplateDialogComponent,
    NetworkCsvDialogComponent,
    StripeCheckoutDialogComponent,
    ApplicationPolicyRulesComponent,
    ApplicationGeolocationRulesComponent,
    ChiplistExpandDialogComponent,
    FaqComponent,
    ChiplistExpandLoadingDialogComponent,
    LoadingDataComponent,
    AuditDestinationConnectorDialogComponent,
    ResourceGroupsCsvComponent,
    PathValidationDialogComponent,
    LabelOverviewComponent,
    DesktopExtraConfigDialogComponent,
    DesktopExtraConfigComponent,
    SupportRequestsOverviewComponent,
    SupportRequestResetExpiryDialogComponent,
    ApiKeyOverviewComponent,
    ApiKeyNewComponent,
  ],
  exports: [FooterComponent, GravatarModule, TableModule, CustomChiplistInputModule],
  bootstrap: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    HammerModule,
    ShowHidePasswordComponent,
    ShowHideInputDirective,
    ShowHideTriggerDirective,
    ShowHideStatusDirective,
    // core & shared
    AppExpansionPanelStateModule,
    AuthIssuerPolicyModule,
    CoreModule,
    CommonComponentsModule,
    CommonModule,
    ConnectorBasedStepperModule,
    NgrxStepperModule,
    StepperModule,
    SharedModule,
    GravatarModule,
    TableModule,
    UsageMetricsModule,
    CustomChiplistInputModule,
    // app
    AppRoutingModule,
    NgrxRouterStoreModule,
    apiProvider,
    StepperProgressBarModule,
    EffectsModule.forRoot([
      ApiApplicationsEffects,
      OrganisationsEffects,
      ApplicationServicesEffects,
      UserEffects,
      IssuerClientsEffects,
      PolicyEffects,
      IssuerEffects,
      SignupEffects,
      ApplicationModelEffects,
      StateDrivenCrudEffects,
      ConnectorEffects,
      AgentConnectorEffects,
      FileShareServiceEffects,
      DesktopResourceEffects,
      LauncherEffects,
      SSHResourceEffects,
      BillingAccountFullEffects,
      InboxItemEffects,
      UIStateEffects,
      PolicyTemplateInstanceEffects,
    ]),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'en-CA' },
    DynamicEnvironmentService,
    {
      provide: APP_INITIALIZER,
      useFactory: init_app,
      deps: [DynamicEnvironmentService, Configuration],
      multi: true,
    },
    CookieService,
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
    {
      provide: HAMMER_GESTURE_CONFIG,
      deps: [],
      useClass: MyHammerConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthzErrorService,
      multi: true,
    },
    // So dialogs will not hide all content on main page when opened
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { scrollStrategy: new NoopScrollStrategy() },
    },
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}

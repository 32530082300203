<portal-page-info
  pageTitle="Application Identity Providers"
  [productGuideLink]="productGuideLink"
  [pageDescriptiveTextWithImageWrap]="pageDescriptiveTextWithImageWrap"
  [pageDescriptiveHelpImageWithTextWrap]="pageDescriptiveHelpImageWithTextWrap"
>
</portal-page-info>
<div class="authentication-group">
  <div *ngIf="hasPermissions !== undefined && !hasPermissions">
      <portal-no-permissions></portal-no-permissions>
  </div>

  <div *ngIf="hasPermissions">
    <div *ngIf="currentOrgIssuer === undefined && issuerCopy === undefined" class="no-info">
        <h2>No issuer information available.</h2>
    </div>

    <div *ngIf="currentOrgIssuer !== undefined" class="issuer-url title">
        <h5>Issuer: {{ currentOrgIssuer }}</h5>
    </div>

    <div *ngIf="issuerCopy !== undefined" class="table-outer-container">
      <div class="application-providers">
        <portal-table-layout
          class="table-template"
          [tableData]="applicationTableData"
          [columnDefs]="applicationColumnDefs"
          [filterManager]="filterManager"
          [rowObjectName]="rowObjectName"
          [buttonsToShow]="buttonsToShow"
          [customButtons]="customButtons"
          (updateEvent)="updateApplicationUpstreamEvent()"
          (removeSelected)="deleteSelectedApplicationUpstreams()"
        >
        </portal-table-layout>
      </div>
    </div>
  </div>
</div>
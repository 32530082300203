import {
  UsersService,
  ServiceAccount,
  CreateServiceAccountRequestParams,
  ListServiceAccountsRequestParams,
  UserMetadata,
  ReplaceUserMetadataRequestParams,
  ListUserMetadataRequestParams,
  CreateUserMetadataRequestParams,
  User,
  patch_via_put,
  SupportRequest,
  ListSupportRequestsRequestParams,
  CreateSupportRequestRequestParams,
  DeleteSupportRequestRequestParams,
} from '@agilicus/angular';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

export function updateExistingUser$(usersService: UsersService, userToUpdate: User): Observable<User> {
  const putter = (user: User) => {
    return usersService.replaceUser({ user_id: user.id, User: user });
  };
  const getter = (user: User) => {
    return usersService.getUser({
      user_id: user.id,
      org_id: user.org_id,
    });
  };
  return patch_via_put(userToUpdate, getter, putter);
}

export function createNewServiceAccount(
  usersService: UsersService,
  newServiceAccount: ServiceAccount
): Observable<ServiceAccount | undefined> {
  const args: CreateServiceAccountRequestParams = {
    ServiceAccount: newServiceAccount,
  };
  return usersService.createServiceAccount(args);
}

export function getServiceAccounts(usersService: UsersService, orgId: string | undefined): Observable<Array<ServiceAccount> | undefined> {
  let serviceAccounts$: Observable<Array<ServiceAccount>> = of([]);
  if (!!orgId) {
    const listServiceAccountsRequestParams: ListServiceAccountsRequestParams = {
      org_id: orgId,
    };
    serviceAccounts$ = usersService.listServiceAccounts(listServiceAccountsRequestParams).pipe(
      map((resp) => {
        return resp.service_accounts;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return serviceAccounts$;
}

export function createUserMetadata(usersService: UsersService, userMetadata: UserMetadata): Observable<UserMetadata> {
  const createUserMetadataRequestParams: CreateUserMetadataRequestParams = {
    UserMetadata: userMetadata,
  };
  return usersService.createUserMetadata(createUserMetadataRequestParams);
}

export function getUserMetadata(
  usersService: UsersService,
  userId: string | undefined,
  orgId: string | undefined,
  appId: string | undefined
): Observable<Array<UserMetadata> | undefined> {
  let userMetadata$: Observable<Array<UserMetadata>> = of(undefined);
  if (!!userId && !!orgId && !!appId) {
    const listUserMetadataRequestParams: ListUserMetadataRequestParams = {
      user_id: userId,
      org_id: orgId,
      app_id: appId,
    };
    userMetadata$ = usersService.listUserMetadata(listUserMetadataRequestParams).pipe(
      map((resp) => {
        return resp.user_metadata;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return userMetadata$;
}

export function updateUserMetadata(usersService: UsersService, updatedUserMetadata: UserMetadata): Observable<UserMetadata> {
  const replaceUserMetadataRequestParams: ReplaceUserMetadataRequestParams = {
    metadata_id: updatedUserMetadata.metadata.id,
    UserMetadata: updatedUserMetadata,
  };
  return usersService.replaceUserMetadata(replaceUserMetadataRequestParams);
}

export function getSupportRequests$(usersService: UsersService, orgId: string | undefined): Observable<Array<SupportRequest> | undefined> {
  let supportRequests$: Observable<Array<SupportRequest>> = of([]);
  if (!!orgId) {
    const listSupportRequestsRequestParams: ListSupportRequestsRequestParams = {
      org_id: orgId,
    };
    supportRequests$ = usersService.listSupportRequests(listSupportRequestsRequestParams).pipe(
      map((resp) => {
        return resp.support_requests;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return supportRequests$;
}

export function createNewSupportRequest$(usersService: UsersService, supportRequest: SupportRequest): Observable<SupportRequest> {
  const createSupportRequestRequestParams: CreateSupportRequestRequestParams = {
    SupportRequest: supportRequest,
  };
  return usersService.createSupportRequest(createSupportRequestRequestParams);
}

export function updateExistingSupportRequest$(
  usersService: UsersService,
  supportRequestToUpdate: SupportRequest
): Observable<SupportRequest> {
  const putter = (supportRequest: SupportRequest) => {
    return usersService.updateSupportRequest({
      support_request_id: supportRequest.metadata.id,
      SupportRequest: supportRequest,
    });
  };
  const getter = (supportRequest: SupportRequest) => {
    return usersService.getSupportRequest({
      support_request_id: supportRequest.metadata.id,
      org_id: supportRequest.spec.org_id,
    });
  };
  return patch_via_put(supportRequestToUpdate, getter, putter);
}

export function deleteExistingSupportRequest$(usersService: UsersService, supportRequest: SupportRequest): Observable<any> {
  const deleteSupportRequestRequestParams: DeleteSupportRequestRequestParams = {
    support_request_id: supportRequest.metadata.id,
    org_id: supportRequest.spec.org_id,
  };
  return usersService.deleteSupportRequest(deleteSupportRequestRequestParams);
}

export function getSupportRequestDescriptiveText(): string {
  return `Request support from Agilicus. While the support request is active, the support person will be able to assist you by reviewing and modifying your configuration. Coordinate with Agilicus support by using the help chat or emailing support@agilicus.com.`;
}

export function getSupportRequestsProductGuideLink(): string {
  return `https://www.agilicus.com/anyx-guide/support-requests/`;
}

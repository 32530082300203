<div *ngIf="!fixedData">
  <portal-page-info
    [pageDescriptiveText]="webAppSecurityDescriptiveText"
    [productGuideLink]="webAppSecurityProductGuideLink"
    pageInfoWidth="full"
  >
  </portal-page-info>
  <mat-accordion class="expansion-container" multi>
    <!-- Authentication Panel -->
    <mat-expansion-panel
      #appDefineAuthenticationPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineAuthenticationPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Authentication
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineAuthenticationPanel)">
          Perform authentication via proxy
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div
        *ngIf="!!oidcAuthenticationForm && isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineAuthenticationPanel)"
        class="form-container"
      >
        <portal-page-info
          [pageDescriptiveText]="authenticationDescriptiveText"
          [productGuideLink]="webAppSecurityProductGuideLink"
          pageInfoWidth="full"
        >
        </portal-page-info>
        <form
          [formGroup]="oidcAuthenticationForm"
          spellcheck="false"
          autocomplete="off"
        >
          <div class="checkbox-container">
            <mat-checkbox
              formControlName="auth_enabled"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether an application is authenticated by a proxy"
            >
              Enable Authentication
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="enableAuthTooltipText"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="applicationHandlesAuthentication"
              (change)="onProxyAuthenticationCheckboxChange($event.checked)"
            >
              Application Handles Authentication
            </mat-checkbox>
            <mat-icon
              class="help-icon"
              [matTooltip]="applicationHandlesAuthenticationTooltipText"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="ntlm_passthrough"
              (change)="onCheckboxChange()"
              aria-label="Checkbox that toggles whether an application enables NTLM passthrough"
            >
              This application uses NTLM
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="enableNtlmTooltipText"
            >
              help_outline
            </mat-icon>
          </div>
      
          <mat-form-field class="extended-field">
            <mat-label>Client id</mat-label>
            <mat-select
              #clientIdSelect
              formControlName="client_id"
              (selectionChange)="onClientIdChange()"
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, clientIdSelect)"
            >
              <mat-option value=""></mat-option>
              <mat-option 
                *ngFor="let client of issuerClients"
                value="{{ client.name }}"
              >
                {{ client.name }}
              </mat-option>
            </mat-select>
            <mat-error>Client id is required</mat-error>
          </mat-form-field>
          <mat-icon 
            class="help-icon custom-help-icon" 
            matTooltip="The client id controls how the issuer performs authentication for this application. Please select one of the previously configured Issuer Clients."
          >
            help_outline
          </mat-icon>
      
          <mat-form-field class="extended-field">
            <mat-label>Logout url</mat-label>
            <input
              matInput
              type="text"
              formControlName="logout_url"
              (blur)="onFormBlur(oidcAuthenticationForm, 'logout_url')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
          </mat-form-field>
          <mat-icon 
            class="help-icon custom-help-icon" 
            matTooltip="The proxy will clear the user's session when the user visits this URL. Typically you want this to be the URL your application would use to initiate a logout."
          >
            help_outline
          </mat-icon>

          <mat-form-field class="extended-field">
            <mat-label>Redirect after signin</mat-label>
            <input
              matInput
              type="text"
              placeholder="/redirect_path"
              formControlName="redirect_after_signin_path"
              (blur)="onFormBlur(oidcAuthenticationForm, 'redirect_after_signin_path')"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            />
          </mat-form-field>
          <mat-icon 
            class="help-icon custom-help-icon" 
            matTooltip="The path to which the user will be redirected on successful signin."
          >
            help_outline
          </mat-icon>

        </form>
      </div>
    </mat-expansion-panel>

    <!-- Security Headers Panel -->
    <mat-expansion-panel
      #appDefineSecurityHeadersPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineSecurityHeadersPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Security Headers
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineSecurityHeadersPanel)">
          Configure HTTP security headers overrides
        </mat-panel-description>
      </mat-expansion-panel-header>
      <portal-security-headers
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineSecurityHeadersPanel)"
        [currentSecurityHeadersCopy]="currentSecurityHeadersCopy"
        (updateSecurityHeaders)="updateSecurityHeaders($event)"
      >
      </portal-security-headers>
    </mat-expansion-panel>

    <!-- Content Security Policy Panel -->
    <mat-expansion-panel
      #appDefineContentSecurityPolicyPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineContentSecurityPolicyPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Content Security Policy
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineContentSecurityPolicyPanel)">
          {{ cspDescriptiveText }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <portal-application-content-security-policy
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineContentSecurityPolicyPanel)"
        [fixedData]="fixedTable"
        [cspDescriptiveText]="cspDescriptiveText"
      >
      </portal-application-content-security-policy>
    </mat-expansion-panel>

    <!-- CORS Panel -->
    <mat-expansion-panel
      #appDefineCorsPanel
      portalExpansionPanelState [appExpansionPanelState]="uiState.expansionPanelsState" [panelId]="appDefineExpansionPanel.appDefineCorsPanel"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Cross-Origin Resource Sharing (CORS)
        </mat-panel-title>
        <mat-panel-description *ngIf="!isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineCorsPanel)">
          Control which origins request content from your application
        </mat-panel-description>
      </mat-expansion-panel-header>
      <portal-cors-config
        *ngIf="isExpansionPanelOpen(uiState, appDefineExpansionPanel.appDefineCorsPanel)"
        [fixedData]="fixedTable"
      >
      </portal-cors-config>
    </mat-expansion-panel>
  </mat-accordion>
</div>
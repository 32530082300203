import { Component, OnInit, ChangeDetectionStrategy, OnChanges, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createSelectColumn,
  createChipListColumn,
  createActionsColumn,
  ActionMenuOptions,
  ChiplistColumn,
  InputColumn,
  SelectColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { Application, Rule, RuleScopeEnum, HttpRule, RuleV2, RoleToRuleEntry, RoleV2, RuleSpec } from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Router } from '@angular/router';
import {
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsSavingRule,
  ActionApiApplicationsDeletingRules,
} from '@app/core/api-applications/api-applications.actions';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import {
  selectApiApplicationsCurrentRolesList,
  selectApiApplicationsCurrentRoleToRuleEntriesList,
  selectApiApplicationsCurrentRulesList,
  selectApiApplicationsSavingRule,
  selectApiCurrentApplication,
} from '@app/core/api-applications/api-applications.selectors';
import { updateTableElements, createEnumChecker, findUniqueItems, useValueIfNotInMapRaw, replaceCharacterWithSpace } from '../utils';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { OptionalRoleToRuleEntry, OptionalRuleElement } from '../optional-types';
import { RuleType } from '../rule-type.enum';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { selectPolicyTemplateInstanceRefreshDataValue } from '@app/core/policy-template-instance-state/policy-template-instance.selectors';

export interface RuleElement extends TableElement {
  scope?: RuleScopeEnum;
  comments?: string;
  rule_type: string;
  methods?: Array<HttpRule.MethodsEnum>;
  path_regex: string;
  currentAssignedRoles?: Array<RoleToRuleEntry>;
  previousAssignedRoles?: Array<RoleToRuleEntry>;
  backingRule: RuleV2;
}

@Component({
  selector: 'portal-application-rules',
  templateUrl: './application-rules.component.html',
  styleUrls: ['./application-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationRulesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<RuleElement>> = new Map();
  private orgId: string;
  public currentApplicationCopy: Application;
  public currentRuleList: Array<RuleV2>;
  public currentRoleToRuleEntriesList: Array<RoleToRuleEntry>;
  public tableData: Array<RuleElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'RULE';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private roleIdToRoleMap: Map<string, RoleV2> = new Map();
  private roleNameToRoleMap: Map<string, RoleV2> = new Map();
  private roleToRuleEntryIdToEntryMap: Map<string, RoleToRuleEntry> = new Map();
  public rulesProductGuideLink = `https://www.agilicus.com/anyx-guide/authorisation-rules/`;
  public rulesDescriptiveText = `Apply a set of allow/deny rules by HTTP method, path, body, and user identity + role.`;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef, private router: Router) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(new ActionApiApplicationsInitApplications());
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const currentRulesListState$ = this.store.pipe(select(selectApiApplicationsCurrentRulesList));
    const currentRolesListState$ = this.store.pipe(select(selectApiApplicationsCurrentRolesList));
    const currentRoleToRuleEntriesListState$ = this.store.pipe(select(selectApiApplicationsCurrentRoleToRuleEntriesList));
    const savingRuleState$ = this.store.pipe(select(selectApiApplicationsSavingRule));
    const refreshPolicyTemplateInstanceDataState$ = this.store.pipe(select(selectPolicyTemplateInstanceRefreshDataValue));
    combineLatest([
      orgId$,
      currentAppState$,
      currentRulesListState$,
      currentRolesListState$,
      currentRoleToRuleEntriesListState$,
      savingRuleState$,
      refreshPolicyTemplateInstanceDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          orgIdResp,
          currentAppStateResp,
          currentRulesListStateResp,
          currentRolesListStateResp,
          currentRoleToRuleEntriesListStateResp,
          isSavingRule,
          refreshPolicyTemplateInstanceDataStateResp,
        ]) => {
          if (isSavingRule) {
            return;
          }
          this.orgId = orgIdResp;
          this.currentApplicationCopy = cloneDeep(currentAppStateResp);
          if (
            !currentAppStateResp ||
            !currentRulesListStateResp ||
            !currentRoleToRuleEntriesListStateResp ||
            // Do not load table until we have checked the policy rules:
            refreshPolicyTemplateInstanceDataStateResp === 0
          ) {
            this.resetEmptyTables();
            return;
          }
          this.currentRoleToRuleEntriesList = currentRoleToRuleEntriesListStateResp;
          this.setRoleToRuleEntriesMap();
          this.setRolesMaps(currentRolesListStateResp);
          this.columnDefs.get('currentAssignedRoles').allowedValues = currentRolesListStateResp.map((role) => {
            return {
              spec: {
                role_id: role.metadata.id,
                rule_id: '',
                app_id: this.currentApplicationCopy.id,
                org_id: this.orgId,
              },
            };
          });
          this.setEditableColumnDefs();
          // Need to make a copy since we cannot modify the readonly data from the store.
          this.updateTable(cloneDeep(currentRulesListStateResp));
        }
      );
  }

  public get RuleType(): typeof RuleType {
    return RuleType;
  }

  public ngOnChanges(): void {
    if (this.currentApplicationCopy === undefined) {
      return;
    }
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setRolesMaps(currentRoleList: Array<RoleV2>): void {
    this.roleIdToRoleMap.clear();
    this.roleNameToRoleMap.clear();
    for (const role of currentRoleList) {
      this.roleIdToRoleMap.set(role.metadata.id, role);
      this.roleNameToRoleMap.set(role.spec.name, role);
    }
  }

  private setRoleToRuleEntriesMap(): void {
    this.roleToRuleEntryIdToEntryMap.clear();
    for (const entry of this.currentRoleToRuleEntriesList) {
      this.roleToRuleEntryIdToEntryMap.set(entry.metadata.id, entry);
    }
  }

  private updateTable(ruleList: Array<RuleV2>): void {
    this.buildData(ruleList);
    this.replaceTableWithCopy();
  }

  private buildData(ruleList: Array<RuleV2>): void {
    const data: Array<RuleElement> = [];
    for (let i = 0; i < ruleList.length; i++) {
      data.push(this.createRuleElement(ruleList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createRuleElement(rule: RuleV2, index: number): RuleElement {
    const data: RuleElement = {
      scope: rule.spec.scope,
      comments: rule.spec.comments,
      rule_type: rule.spec.condition.rule_type,
      methods: rule.spec.condition.methods,
      path_regex: rule.spec.condition.path_regex,
      currentAssignedRoles: [],
      previousAssignedRoles: [],
      ...getDefaultTableProperties(index),
      backingRule: rule,
    };
    for (const roleToRuleEntry of this.currentRoleToRuleEntriesList) {
      if (roleToRuleEntry.spec.rule_id === rule.metadata.id) {
        data.currentAssignedRoles.push(roleToRuleEntry);
        data.previousAssignedRoles.push(roleToRuleEntry);
      }
    }
    return data;
  }

  /**
   * Parent Table Column
   */
  private getPathRegexColumn(): InputColumn<RuleElement> {
    const pathRegexColumn = createInputColumn<RuleElement>('path_regex');
    pathRegexColumn.displayName = 'Path';
    pathRegexColumn.requiredField = () => true;
    return pathRegexColumn;
  }

  /**
   * Parent Table Column
   */
  private getMethodsColumn(): ChiplistColumn<RuleElement> {
    const methodsColumn = createChipListColumn<RuleElement>('methods');
    methodsColumn.requiredField = () => true;
    methodsColumn.allowedValues = Object.keys(Rule.MethodEnum);
    methodsColumn.isValidEntry = (entry: Rule.MethodEnum) => {
      const isMethodEnum = createEnumChecker(Rule.MethodEnum);
      return isMethodEnum(entry);
    };
    return methodsColumn;
  }

  /**
   * Parent Table Column
   */
  private getScopeColumn(): SelectColumn<RuleElement> {
    const column = createSelectColumn<RuleElement>('scope');
    column.requiredField = () => true;
    column.allowedValues = Object.keys(RuleScopeEnum);
    column.getDisplayValue = (value: string | RuleSpec) => {
      const valueAsRuleSpec = value as RuleSpec;
      if (valueAsRuleSpec.scope !== undefined) {
        return valueAsRuleSpec.scope;
      }
      return replaceCharacterWithSpace(value as string, '_');
    };
    column.getOptionDisplayValue = (value: string | RuleSpec) => {
      const valueAsRuleSpec = value as RuleSpec;
      if (valueAsRuleSpec.scope !== undefined) {
        return valueAsRuleSpec.scope;
      }
      return replaceCharacterWithSpace(value as string, '_');
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getAssignedRolesColumn(): ChiplistColumn<RuleElement> {
    const assignedRolesColumn = createChipListColumn<RuleElement>('currentAssignedRoles');
    assignedRolesColumn.displayName = 'Application Roles';
    assignedRolesColumn.getDisplayValue = (entry: OptionalRoleToRuleEntry) => {
      return useValueIfNotInMapRaw(entry.spec.role_id, this.roleIdToRoleMap, (role: RoleV2) => role.spec.name);
    };
    assignedRolesColumn.getElementFromValue = (roleName: string): RoleToRuleEntry => {
      return {
        spec: {
          role_id: useValueIfNotInMapRaw(roleName, this.roleNameToRoleMap, (role: RoleV2) => role.metadata.id),
          rule_id: '',
          app_id: this.currentApplicationCopy.id,
          org_id: this.orgId,
        },
      };
    };
    assignedRolesColumn.isValidEntry = (entry: OptionalRoleToRuleEntry) => {
      const role = this.roleIdToRoleMap.get(entry.spec.role_id);
      return role !== undefined;
    };
    return assignedRolesColumn;
  }

  /**
   * Parent Table Column
   */
  private getCommentsColumn(): InputColumn<RuleElement> {
    const commentsColumn = createInputColumn<RuleElement>('comments');
    commentsColumn.isValidEntry = (comment: string): boolean => {
      return comment.length < 2047;
    };
    return commentsColumn;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<RuleElement> {
    const actionsColumn = createActionsColumn<RuleElement>('actions');
    const menuOptions: Array<ActionMenuOptions<RuleElement>> = [
      {
        displayName: 'Configure Firewall Rule',
        icon: 'open_in_browser',
        tooltip: 'Click to view/modify this rule',
        onClick: (element: OptionalRuleElement) => {
          this.router.navigate([window.location.pathname + '/rule', element.backingRule.metadata.id], {
            queryParams: { org_id: this.orgId },
          });
        },
      },
      {
        displayName: 'Configure Methods',
        icon: 'list_alt',
        tooltip: 'Click to access the "Methods" advanced configuration',
        columnName: 'methods',
      },
      {
        displayName: 'Configure Application Roles',
        icon: 'verified_user',
        tooltip: 'Click to access the "Application Roles" advanced configuration',
        columnName: 'currentAssignedRoles',
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getPathRegexColumn(),
        this.getMethodsColumn(),
        this.getScopeColumn(),
        this.getAssignedRolesColumn(),
        this.getCommentsColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const pathRegexColumn = this.columnDefs.get('path_regex');
    pathRegexColumn.isEditable = !this.fixedTable;

    const methodsColumn: ChiplistColumn<RuleElement> = this.columnDefs.get('methods');
    methodsColumn.isEditable = !this.fixedTable;
    methodsColumn.isRemovable = !this.fixedTable;

    const scopeColumn = this.columnDefs.get('scope');
    scopeColumn.isEditable = !this.fixedTable;

    const assignedRolesColumn: ChiplistColumn<RuleElement> = this.columnDefs.get('currentAssignedRoles');
    assignedRolesColumn.isEditable = !this.fixedTable;
    assignedRolesColumn.isRemovable = !this.fixedTable;

    const commentsColumn = this.columnDefs.get('comments');
    commentsColumn.isEditable = !this.fixedTable;

    const actionsColumn = this.columnDefs.get('actions');
    actionsColumn.showColumn = !!this.currentApplicationCopy.id;
  }

  public makeEmptyTableElement(): RuleElement {
    return {
      scope: RuleScopeEnum.assigned_to_user,
      comments: '',
      rule_type: RuleType.HttpRule,
      methods: [],
      path_regex: '',
      currentAssignedRoles: [],
      previousAssignedRoles: [],
      ...getDefaultNewRowProperties(),
      backingRule: {
        spec: {
          app_id: this.currentApplicationCopy.id,
          org_id: this.orgId,
          scope: RuleScopeEnum.assigned_to_user,
          comments: '',
          condition: {
            rule_type: RuleType.HttpRule,
            methods: [],
            path_regex: '',
          },
        },
      },
    };
  }

  private getRoleToRuleEntriesToDelete(updatedRule: RuleElement): Array<RoleToRuleEntry> {
    const previousRoleToRuleEntryIds = updatedRule.previousAssignedRoles.map((entry) => entry.metadata.id);
    const currentRoleToRuleEntryIds = updatedRule.currentAssignedRoles.filter((entry) => entry.metadata).map((entry) => entry.metadata.id);

    return findUniqueItems(previousRoleToRuleEntryIds, currentRoleToRuleEntryIds).map((entry) =>
      this.roleToRuleEntryIdToEntryMap.get(entry)
    );
  }

  /**
   * Receives a RuleElement from the table then updates and saves
   * the current rules list.
   */
  public updateEvent(updatedRule: RuleElement): void {
    this.saveRule(updatedRule);
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<RuleElement>; element: RuleElement }): void {
    if (params.column.name !== 'scope') {
      return;
    }
    const isRuleScopeEnumEnum = createEnumChecker(RuleScopeEnum);
    if (isRuleScopeEnumEnum(params.value)) {
      params.element.scope = params.value;
    }
  }

  private getRuleFromRuleElement(ruleElement: RuleElement): RuleV2 {
    const result: RuleV2 = ruleElement.backingRule ? cloneDeep(ruleElement.backingRule) : { spec: {} };
    result.spec.comments = ruleElement.comments;
    result.spec.scope = ruleElement.scope;
    result.spec.condition.rule_type = ruleElement.rule_type;
    result.spec.condition.methods = ruleElement.methods;
    result.spec.condition.path_regex = ruleElement.path_regex;
    return result;
  }

  /**
   * If a user, removes and then re-adds a role to rule entry,
   * reset the value of the object to the original since it has not changed
   * and should not be updated.
   */
  private resetUnchangedRoleToRuleEntries(updatedRuleElement: RuleElement): void {
    for (let i = 0; i < updatedRuleElement.currentAssignedRoles.length; i++) {
      const assignedRole = updatedRuleElement.currentAssignedRoles[i];
      const targetItem = updatedRuleElement.previousAssignedRoles.find(
        (item) =>
          item.spec.app_id === assignedRole.spec.app_id &&
          item.spec.role_id === assignedRole.spec.role_id &&
          item.spec.org_id === assignedRole.spec.org_id
      );
      if (targetItem) {
        updatedRuleElement.currentAssignedRoles[i] = targetItem;
      }
    }
  }

  private saveRule(updatedRuleElement: RuleElement): void {
    this.resetUnchangedRoleToRuleEntries(updatedRuleElement);
    const modifiedRule = this.getRuleFromRuleElement(updatedRuleElement);
    const roleToRuleEntriesToDelete = this.getRoleToRuleEntriesToDelete(updatedRuleElement);
    this.store.dispatch(
      new ActionApiApplicationsSavingRule(modifiedRule, updatedRuleElement.currentAssignedRoles, roleToRuleEntriesToDelete)
    );
  }

  public deleteSelected(rulesToDelete: Array<RuleElement>): void {
    this.deleteRules(rulesToDelete);
  }

  private deleteRules(rulesToDelete: Array<RuleElement>): void {
    this.store.dispatch(new ActionApiApplicationsDeletingRules(rulesToDelete.map((ruleElement) => cloneDeep(ruleElement.backingRule))));
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTables(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

import { Application, ApplicationConfig, CORSSettings, Environment } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { AppState } from '@app/core';
import { ActionApiApplicationsModifyCurrentApp } from '@app/core/api-applications/api-applications.actions';
import { selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import {
  getDefaultCorsMaxAgeSecondsValue,
  getDefaultCorsOriginMatchingValue,
  setCorsConfigFromTemplate,
} from '@app/core/models/application/application-model-api-utils';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { setCorsConfigIfUnset } from '../application-configs-utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CorsTemplateType } from '../cors-template-type';
import { createDialogData } from '../dialog-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { capitalizeFirstLetter, createEnumChecker, modifyDataOnFormBlur } from '../utils';
import { isANumber } from '../validation-utils';
import { CorsAllowMethodsComponent } from '../cors-allow-methods/cors-allow-methods.component';
import { CorsAllowOriginsComponent } from '../cors-allow-origins/cors-allow-origins.component';
import { CorsAllowHeadersComponent } from '../cors-allow-headers/cors-allow-headers.component';
import { CorsExposeHeadersComponent } from '../cors-expose-headers/cors-expose-headers.component';
import { getCorsTemplateTooltipText, getCorsTemplateTypes } from './cors-config-utils';

export enum CorsPanel {
  ALLOW_ORIGINS = 'ALLOW_ORIGINS',
  ALLOW_METHODS = 'ALLOW_METHODS',
  ALLOW_HEADERS = 'ALLOW_HEADERS',
  EXPOSE_HEADERS = 'EXPOSE_HEADERS',
}

@Component({
  selector: 'portal-cors-config',
  templateUrl: './cors-config.component.html',
  styleUrls: ['./cors-config.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CorsConfigComponent implements OnInit, OnDestroy {
  @Input() public fixedData = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public securityForm: UntypedFormGroup;
  private currentApplicationCopy: Application;
  public enableCorsTooltipText =
    'Whether or not to apply the CORS policy. If the policy is disabled, then any CORS headers applied by the application will be passed through unchanged.';
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public capitalizeFirstLetter = capitalizeFirstLetter;
  public webAppSecurityProductGuideLink = `https://www.agilicus.com/anyx-guide/web-application-security/`;
  public corsDescriptiveText = `Configure the Cross-Origin Resource Sharing (CORS) policy of this Application. 
  This allows an application to control which origins may request content from it.`;

  // Expansion panels
  @ViewChild(MatAccordion) public accordion: MatAccordion;

  /**
   * A mapping of CorsPanel to whether that panel is open.
   */
  private panelsStateMap: Map<CorsPanel, boolean> = new Map();
  // This is required in order to reference the enums in the html template.
  public corsPanel = CorsPanel;
  public corsTemplateType = CorsTemplateType;

  public getCorsTemplateTooltipText = getCorsTemplateTooltipText;
  public getCorsTemplateTypes = getCorsTemplateTypes;

  @ViewChild(CorsAllowMethodsComponent) public corsAllowMethods: CorsAllowMethodsComponent;
  @ViewChild(CorsAllowOriginsComponent) public corsAllowOrigins: CorsAllowOriginsComponent;
  @ViewChild(CorsAllowHeadersComponent) public corsAllowHeaders: CorsAllowHeadersComponent;
  @ViewChild(CorsExposeHeadersComponent) public corsExposeHeaders: CorsExposeHeadersComponent;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService,
    private dialog: MatDialog
  ) {
    this.setPanelsStateMapToClosed();
  }

  public ngOnInit(): void {
    const currentApplicationState$ = this.store.pipe(select(selectApiCurrentApplication));
    currentApplicationState$.pipe(takeUntil(this.unsubscribe$)).subscribe((currentApplicationStateResp) => {
      if (
        !currentApplicationStateResp ||
        !currentApplicationStateResp.environments ||
        currentApplicationStateResp.environments.length === 0
      ) {
        return;
      }
      this.currentApplicationCopy = cloneDeep(currentApplicationStateResp);
      this.initializeFormGroups();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setPanelsStateMapToClosed(): void {
    for (const value of Object.keys(CorsPanel)) {
      const isCorsPanelEnum = createEnumChecker(CorsPanel);
      if (isCorsPanelEnum(value)) {
        this.panelsStateMap.set(value, false);
      }
    }
  }

  private initializeFormGroups(): void {
    this.initializeApplicationSecurityFormGroup();
    this.changeDetector.detectChanges();
  }

  private getCorsEnabledValue(): boolean {
    return !!this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.enabled;
  }

  private getCorsOriginMatchingValue(): CORSSettings.OriginMatchingEnum {
    const originMatchingValue = this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.origin_matching;
    if (!!originMatchingValue) {
      return originMatchingValue;
    }
    // If it hasn't been set then default to this value:
    return getDefaultCorsOriginMatchingValue();
  }

  private getAllowCredentialsValue(): boolean {
    return !!this.currentApplicationCopy.environments[0]?.application_configs?.security?.http?.cors?.allow_credentials;
  }

  private getMaxAgeSecondsValue(): number {
    const maxAgeSecondsValue = this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http?.cors?.max_age_seconds;
    if (!!maxAgeSecondsValue) {
      return maxAgeSecondsValue;
    }
    // If it hasn't been set then default to this value:
    return getDefaultCorsMaxAgeSecondsValue();
  }

  private initializeApplicationSecurityFormGroup(): void {
    this.securityForm = this.formBuilder.group({
      enabled: this.getCorsEnabledValue(),
      origin_matching: this.getCorsOriginMatchingValue(),
      allow_credentials: this.getAllowCredentialsValue(),
      max_age_seconds: [
        this.getMaxAgeSecondsValue(),
        [Validators.min(-1), Validators.required, this.customValidatorsService.customValidator(isANumber)],
      ],
    });
  }

  public onCheckboxChange(): void {
    this.modifyApplicationOnValueChanges();
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    modifyDataOnFormBlur(form, formField, this.modifyApplicationOnValueChanges.bind(this));
  }

  private modifyApplicationOnValueChanges(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    // Need to update the application_configs of every environment for this application.
    for (const env of copyOfCurrentApplicationCopy.environments) {
      this.setAllFieldsFromForms(env);
    }
    this.currentApplicationCopy = copyOfCurrentApplicationCopy;
    this.modifyApplication();
  }

  private setAllFieldsFromForms(environment: Environment): void {
    this.setEnvCorsConfigFromSecurityForm(environment);
  }

  private setEnvCorsConfigFromSecurityForm(environment: Environment): void {
    setCorsConfigIfUnset(environment);
    environment.application_configs.security.http.cors.enabled = this.securityForm.get('enabled').value;
    environment.application_configs.security.http.cors.origin_matching = this.securityForm.get('origin_matching').value;
    environment.application_configs.security.http.cors.allow_credentials = this.securityForm.get('allow_credentials').value;
    environment.application_configs.security.http.cors.max_age_seconds = parseInt(this.securityForm.get('max_age_seconds').value, 10);
  }

  public modifyApplication(): void {
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  public onPanelOpen(panel: CorsPanel): void {
    this.panelsStateMap.set(panel, true);
  }

  public onPanelClose(panel: CorsPanel): void {
    this.panelsStateMap.set(panel, false);
  }

  public getPanelState(panel: CorsPanel): boolean {
    return this.panelsStateMap.get(panel);
  }

  public getAllPossibleOriginMatchingEnumValues(): Array<string> {
    return Object.values(CORSSettings.OriginMatchingEnum);
  }

  public getOriginMatchingTooltip(value: CORSSettings.OriginMatchingEnum): string {
    if (value === CORSSettings.OriginMatchingEnum.me) {
      return 'Will match the hosts on which this application can be reached.';
    }
    if (value === CORSSettings.OriginMatchingEnum.wildcard) {
      return 'Will match any host.';
    }
    if (value === CORSSettings.OriginMatchingEnum.list) {
      return `Will match only those hosts provided in the 'allow_origins' list.`;
    }
    return '';
  }

  public updateApplicationOnAppConfigsChange(appConfigs: ApplicationConfig): void {
    for (const env of this.currentApplicationCopy.environments) {
      env.application_configs = appConfigs;
    }
    this.modifyApplication();
  }

  public onCorsTemplateSelection(template: CorsTemplateType): void {
    this.openConfirmationDialog(template);
  }

  public applyCorsTemplate(template: CorsTemplateType): void {
    const existingEnvironmentCopy = cloneDeep(this.currentApplicationCopy?.environments[0]);
    setCorsConfigFromTemplate(template, existingEnvironmentCopy);
    this.updateApplicationOnAppConfigsChange(existingEnvironmentCopy.application_configs);
  }

  private openConfirmationDialog(template: CorsTemplateType): void {
    const messagePrefix = `You have selected the "${template}" template option. ${getCorsTemplateTooltipText(template)}`;
    const message = 'Warning: This action will overwrite your existing CORS settings. Do you want to continue?';
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.applyCorsTemplate(template);
        return;
      }
    });
  }

  public onOriginMatchingOptionChange(value: CORSSettings.OriginMatchingEnum): void {
    this.securityForm.get('origin_matching').setValue(value);
    this.modifyApplicationOnValueChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const corsAllowMethodsValidate = this?.corsAllowMethods ? this.corsAllowMethods.canDeactivate() : true;
    const corsAllowOriginsValidate = this?.corsAllowOrigins ? this.corsAllowOrigins.canDeactivate() : true;
    const corsAllowHeadersValidate = this?.corsAllowHeaders ? this.corsAllowHeaders.canDeactivate() : true;
    const corsExposeHeadersValidate = this?.corsExposeHeaders ? this.corsExposeHeaders.canDeactivate() : true;
    return corsAllowMethodsValidate && corsAllowOriginsValidate && corsAllowHeadersValidate && corsExposeHeadersValidate;
  }
}

<div class="dialog-container">
  <mat-dialog-content>
    <h2>Add Connector Destination</h2>
    <div *ngIf="!!allForms">
      <form
        [formGroup]="nameFormGroup"
        class="name-form"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field>
          <mat-label>Provide a connector destination name:</mat-label>
          <input
            matInput
            type="text"
            formControlName="name"
            placeholder="Enter name..."
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          />
        </mat-form-field>
      </form>

      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        required="true"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      [disabled]="disableSaveButton()"
      tabindex="1"
    >
      SAVE
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
</div>
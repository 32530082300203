<div 
  class="progress-bar-container" 
  [hidden]="progressBarController.isHidden"
  [ngClass]="{ 'progress-bar-failed': !!progressBarController.progressBarFailed }"
>
  <mat-progress-bar
    [mode]="progressBarController.progressBarMode"
    [value]="progressBarController.progressBarValue"
    [bufferValue]="progressBarController.progressBarBufferValue"
    matTooltip="File upload progress bar"
    aria-label="Progress Bar for file upload"
  >
  </mat-progress-bar>
  <small *ngIf="!!progressBarInfoText">{{ progressBarInfoText }}</small>
</div>
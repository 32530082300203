<portal-page-info
  pageTitle="New API Key"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasAllPermissions()" class="stepper-container">
  <mat-vertical-stepper linear #apiKeysStepper portalMatVerticalStepperScroller (selectionChange)="onStepperSelectionChange($event.selectedIndex)">
    
    <!-- Step 1: API Key Name -->
    <mat-step [stepControl]="apiKeyNameFormGroup">
      <ng-template matStepLabel>API key name</ng-template>
      <form [formGroup]="apiKeyNameFormGroup" class="stepper-form" spellcheck="false" autocomplete="off">
        <portal-stepper-form-field
          placeholder="Enter the API key name"
          label="My API key name is..."
          [formCtrl]="apiKeyNameFormGroup.get('name')"
          required="true"
          (formBlur)="onFormBlur(apiKeyNameFormGroup, 'name', getModel())">
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <!-- Step 2: Choose Self or Service Account -->
    <mat-step [stepControl]="accountTypeFormGroup">
      <ng-template matStepLabel>Choose API Key for:</ng-template>

      <form 
        [formGroup]="accountTypeFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-radio-group 
          formControlName="accountType"
          aria-labelledby="config radio buttons"
          class="optional-config-radio-group"
          (change)="onAccountTypeChange()">
          <mat-radio-button
            class="optional-config-radio-button"
            value="self"
          >
            Self
          </mat-radio-button>
          <mat-radio-button 
            class="optional-config-radio-button"
            value="serviceAccount"
          >
            Service Account
          </mat-radio-button>
        </mat-radio-group>

         <div>
          <mat-form-field *ngIf="isServiceAccountSelected">
            <mat-label>Select a service account:</mat-label>
            <mat-select 
              #select1
              formControlName="selectedServiceAccount"
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
              (selectionChange)="updateSelectedServiceAccount(accountTypeFormGroup, $event.value)"
            >
              <mat-option value=""></mat-option>
              <mat-option
                *ngFor="let account of serviceAccounts"
                value="{{ account.status.user.id }}"
                >{{ account.spec.name }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="required">Service account is required</mat-error>
          </mat-form-field>
         </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="scopesFormGroup">
      <ng-template matStepLabel>Scopes</ng-template>
    
      <form [formGroup]="scopesFormGroup">
        <label class="scopes-label">
          My API Key will provide access to the following:
        </label>

        <div class="optional-config-radio-group">
          <div>
            <mat-checkbox formControlName="apiAccess" (change)="onApiAccessChange($event.checked)">
              API Scope
            </mat-checkbox>
          </div>
          
          <div>
            <mat-checkbox formControlName="resourceAccess" (change)="onResourceAccessChange()">Resource Scope</mat-checkbox>
          </div>
        </div>

        <div *ngIf="scopesFormGroup.get('resourceAccess')?.value">
          <div>
            <mat-form-field>
              <mat-label>Select Resource Type</mat-label>
              <mat-select 
                #select2 
                formControlName="selectedResourceType"
                (selectionChange)="onResourceTypeChange()"
                [disabled]="!scopesFormGroup.get('resourceAccess')?.value"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select2)"
              >
                <mat-option *ngFor="let type of resourceTypesList" [value]="type">{{ type }}</mat-option>
              </mat-select>
            </mat-form-field>        
          </div>
  
          <div>
            <mat-form-field>
              <mat-label>Select a Resource</mat-label>
              <mat-select 
                #select3 
                formControlName="selectedResource"
                (selectionChange)="onResourceSelectionChange()"
                [disabled]="!scopesFormGroup.get('selectedResourceType')?.value"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select3)"
              >
                <mat-option value="*">all</mat-option>
                <mat-option *ngFor="let option of filteredResourcesByType" [value]="option.value">
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
  
          <div>
            <mat-form-field>
              <mat-label>Permission Scope</mat-label>
              <mat-select 
                formControlName="selectedPermissionScope"
                [disabled]="!scopesFormGroup.get('selectedResourceType')?.value"
              >
                <mat-option *ngFor="let perm of ['*', 'owner', 'viewer', 'editor', 'self']" [value]="perm">
                  {{ perm }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
    
        <div>
          <button class="add-button" mat-raised-button color="primary" (click)="addScope()" [disabled]="!isScopeValid()">Add Scope</button>
        </div>
      </form>

      <portal-table-layout
        #tableLayoutComp
        [tableData]="tableData"
        [columnDefs]="tableColumns"
        [columnDefs]="columnDefs"
        [fixedTable]="fixedTable"
        [rowObjectName]="rowObjectName"
        [buttonsToShow]="buttonsToShow"
        (updateEvent)="updateEvent($event)"
        [makeEmptyTableElement]="makeEmptyTableElement"
        (removeSelected)="deleteSelected($event)">
      </portal-table-layout>

      <div class="stepper-nav-buttons scopes-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext [disabled]="scopes.length === 0 || (scopes.length === 1 && scopes[0] === '')">Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="expiryFormGroup">
      <ng-template matStepLabel>Expiry Date</ng-template>

      <form [formGroup]="expiryFormGroup">
        <label class="scopes-label">
          Choose expiry date for the API key:
        </label>
        <div class="optional-config-radio-group">
          <mat-form-field>
            <input matInput [matDatepicker]="startDatepicker" [min]="minDate" placeholder="Choose a date" name="expiryDate"
              formControlName="expiryDate" required (click)="startDatepicker.open()">
            <mat-datepicker-toggle matSuffix [for]="startDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatepicker></mat-datepicker>
            <mat-error *ngIf="expiryFormGroup.get('expiryDate').invalid && (expiryFormGroup.get('expiryDate').dirty || expiryFormGroup.get('expiryDate').touched)">
              Please enter a valid date.
            </mat-error>
          </mat-form-field>        
        </div>
      </form>
    
      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext (click)="onExpiryDateNextClick()" [disabled]="expiryFormGroup.invalid || !expiryFormGroup.get('expiryDate').value">Next</button>
      </div>
    </mat-step>
    
    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedApiKeyText }}</p>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>API key name: {{ getModel().name }}</p>
        <p>API key account for: {{ getModel().serviceAccount ? getModel().serviceAccount : 'self' }}</p>
        <p>Scopes:</p>
          <ul>
            <li *ngFor="let scope of getModel().scopes">
              {{ processScopeString(scope) }}
            </li>
          </ul>
        <p>Expiry Date: {{ getReadableExpiryDate() }}</p>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentApiKeyModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="apiKeyModelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitApiKeyModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <div *ngIf="apiKeyModelStatus.apiKey">
        <p>Your API Key has been created. Please save this information carefully as it won't be shown again:</p>
        <div>
          <strong>Username:</strong> {{ currentUser.email }}
        </div>
        <div>
          <strong>Password:</strong> {{ apiKeyModelStatus.apiKey.status.api_key }}
        </div>
        <p class="scopes-label">Do not lose your API key, as it will not be retrievable later.</p>
      </div>
    </mat-step>

  </mat-vertical-stepper>
</div>

import {
  LabelledObject,
  Resource,
  ResourceMember,
  ResourcePermissionSpec,
  ResourceRole,
  ResourceRoleSpec,
  ResourceTypeEnum,
  UserRequestInfoSpec,
} from '@agilicus/angular';
import { ColorType } from './color-type.enum';
import { ResourceType } from './resource-type.enum';
import { ChiplistColumn, Column, createChipListColumn } from './table-layout/column-definitions';
import { TableElement } from './table-layout/table-element';
import { capitalizeFirstLetter, pluralizeString, replaceCharacterWithSpace } from './utils';
import { ApplicationService } from '@agilicus/angular';

export function getResourceColumnName(resource: Resource): string {
  return `${resource.spec.name}:${resource.spec.resource_type}`;
}

export function getResourcePermissionChipValue(resourceString: string, roleString: string): string {
  return `${resourceString}:${roleString}`;
}

/**
 * Will take in a Resource and output a string in the format of `resource_name(resource_type)`
 */
export function getResourceNameAndTypeString(resourceName: string | undefined, resourceType: ResourceTypeEnum | undefined): string {
  if (!resourceName) {
    return '';
  }
  if (!resourceType) {
    return resourceName;
  }
  return `${resourceName}(${convertResourceTypeToReadableString(resourceType)})`;
}

export function getResourceNameFromResourceNameAndTypeString(resourceNameAndTypeString: string): string {
  return resourceNameAndTypeString.substring(0, resourceNameAndTypeString.indexOf('('));
}

export function convertResourceTypeToResourceRoleType(resourceType: ResourceTypeEnum): ResourceRoleSpec.ResourceTypeEnum {
  if (resourceType === ResourceTypeEnum.application) {
    return ResourceRoleSpec.ResourceTypeEnum.application;
  }
  if (resourceType === ResourceTypeEnum.application_service) {
    return ResourceRoleSpec.ResourceTypeEnum.application_service;
  }
  if (resourceType === ResourceTypeEnum.desktop) {
    return ResourceRoleSpec.ResourceTypeEnum.desktop;
  }
  if (resourceType === ResourceTypeEnum.fileshare) {
    return ResourceRoleSpec.ResourceTypeEnum.fileshare;
  }
  if (resourceType === ResourceTypeEnum.group) {
    return ResourceRoleSpec.ResourceTypeEnum.group;
  }
  if (resourceType === ResourceTypeEnum.launcher) {
    return ResourceRoleSpec.ResourceTypeEnum.launcher;
  }
  if (resourceType === ResourceTypeEnum.ssh) {
    return ResourceRoleSpec.ResourceTypeEnum.ssh;
  }

  return null;
}

export function convertResourceTypeToResourcePermissionType(resourceType: ResourceType): ResourcePermissionSpec.ResourceTypeEnum {
  if (resourceType === ResourceType.application) {
    return ResourcePermissionSpec.ResourceTypeEnum.application;
  }
  if (resourceType === ResourceType.application_service) {
    return ResourcePermissionSpec.ResourceTypeEnum.application_service;
  }
  if (resourceType === ResourceType.desktop) {
    return ResourcePermissionSpec.ResourceTypeEnum.desktop;
  }
  if (resourceType === ResourceType.fileshare) {
    return ResourcePermissionSpec.ResourceTypeEnum.fileshare;
  }
  if (resourceType === ResourceType.group) {
    return ResourcePermissionSpec.ResourceTypeEnum.group;
  }
  if (resourceType === ResourceType.launcher) {
    return ResourcePermissionSpec.ResourceTypeEnum.launcher;
  }
  if (resourceType === ResourceType.ssh) {
    return ResourcePermissionSpec.ResourceTypeEnum.ssh;
  }
  return null;
}

/**
 * Creates the previous version of a Role without any rules.
 */
export function createBasicResourceRoleWithName(name: string): ResourceRole {
  return {
    spec: {
      resource_type: ResourceRoleSpec.ResourceTypeEnum.fileshare,
      role_name: name,
    },
  };
}

export function getResourceDefaultRole(request: UserRequestInfoSpec): string {
  switch (request.requested_resource_type) {
    case UserRequestInfoSpec.RequestedResourceTypeEnum.file_share_access:
      return 'viewer';
    case UserRequestInfoSpec.RequestedResourceTypeEnum.application_service:
      return 'owner';
    case UserRequestInfoSpec.RequestedResourceTypeEnum.desktop:
      return 'owner';
    default:
      return '';
  }
}

export function getResourceTypeIcon(resourceType: ResourceType): string {
  switch (resourceType) {
    case ResourceType.application:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.application_access:
      return 'apps';
    case ResourceType.application_service:
      return 'miscellaneous_services';
    case ResourceType.fileshare:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.file_share_access:
      return 'cloud_circle';
    case ResourceType.desktop:
      return 'desktop_windows';
    case ResourceType.launcher:
      return 'launch';
    case ResourceType.service_forwarder:
      return 'device_hub';
    case ResourceType.ssh:
      return 'https';
    case ResourceType.group:
      return 'group';
    default:
      return '';
  }
}

export function getServiceProtocolTypeIcon(serviceProtocolTypeType: ApplicationService.ServiceProtocolTypeEnum): string {
  switch (serviceProtocolTypeType) {
    case ResourceType.desktop:
      return 'desktop_windows';
    case ResourceType.ip:
      return 'vpn_key';
    case ResourceType.fileshare:
      return 'cloud_circle';
    case ResourceType.ssh:
      return 'https';
    default:
      return '';
  }
}

export function convertResourceTypeToReadableString(resourceType: ResourceTypeEnum | ResourceType): string {
  switch (resourceType) {
    case ResourceType.application:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.application_access:
      return 'application';
    case ResourceType.application_service:
      return 'network';
    case ResourceType.fileshare:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.file_share_access:
      return 'share';
    default:
      return !!resourceType ? replaceCharacterWithSpace(resourceType, '_') : '';
  }
}

export function getResourceTypeColor(resourceType: ResourceTypeEnum | ResourceType): string {
  switch (resourceType) {
    case ResourceType.application:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.application_access:
      // Red
      return '#74012B';
    case ResourceType.application_service:
      // Purple
      return '#6E0099';
    case ResourceType.fileshare:
    // The below option is deprecated. Here for backwards compatibility:
    case ResourceType.file_share_access:
      // Blue
      return '#4111D0';
    case ResourceType.desktop:
      // Dark Blue
      return '#044067';
    case ResourceType.service_forwarder:
      // Dark Green
      return '#003822';
    case ResourceType.group:
      // Green
      return '#0A5903';
    case ResourceType.launcher:
      // Orange
      return '#C44601';
    case ResourceType.ssh:
      // Yellow
      return '#754A00';
    default:
      // Primary color:
      return '#0057b8';
  }
}

export function getResourceTypeTooltip(resourceType: ResourceType): string {
  if (resourceType === ResourceType.ssh) {
    return 'SSH';
  }
  if (resourceType === ResourceType.ip) {
    return 'IP';
  }
  return capitalizeFirstLetter(convertResourceTypeToReadableString(resourceType));
}

export function getDefaultResourceMembersColumn<T extends TableElement>(
  resourceIdToResourceMap: Map<string, Resource>,
  resourceNameAndTypeToResourceMap: Map<string, Resource>
): Column<T> {
  const resourceMembersColumn: ChiplistColumn<T> = createChipListColumn('resource_members');
  resourceMembersColumn.getDisplayValue = (member: ResourceMember | Resource) => {
    if (!member) {
      return '';
    }
    const resourceMemberAsMember = member as ResourceMember;
    const targetResource = getChipTargetResourceMember(member, resourceIdToResourceMap);
    const resourceNameAndTypeString = getResourceNameAndTypeString(
      !!targetResource?.spec?.name ? targetResource.spec.name : '',
      targetResource?.spec?.resource_type
    );
    return !resourceNameAndTypeString ? resourceMemberAsMember.id : resourceNameAndTypeString;
  };
  resourceMembersColumn.getElementFromValue = (resourceNameAndType: string): any => {
    const resourceId = resourceNameAndTypeToResourceMap.get(resourceNameAndType)?.metadata.id;
    if (!resourceId) {
      return undefined;
    }
    return {
      id: resourceId,
    };
  };
  resourceMembersColumn.getChipColor = (member: ResourceMember | Resource) => {
    const targetResource = getChipTargetResourceMember(member, resourceIdToResourceMap);
    if (!targetResource) {
      return ColorType.warn;
    }
    return ColorType.primary;
  };
  return resourceMembersColumn;
}

export function getChipTargetResourceMember(
  member: ResourceMember | Resource,
  resourceIdToResourceMap: Map<string, Resource>
): Resource | undefined {
  if (!member) {
    return undefined;
  }
  const resourceMemberAsMember = member as ResourceMember;
  const resourceMemberAsResource = member as Resource;
  let targetResource = resourceIdToResourceMap.get(resourceMemberAsMember.id);
  if (!targetResource) {
    targetResource = resourceIdToResourceMap.get(resourceMemberAsResource.metadata?.id);
  }
  return targetResource;
}

export function getFormattedResourceType(resourceType: ResourceType): string {
  if (!resourceType) {
    return '';
  }
  if (resourceType === ResourceType.ssh) {
    return 'SSH';
  }
  return convertResourceTypeToReadableString(convertResourceTypeToResourcePermissionType(resourceType));
}

export function getFormattedPluralizedResourceType(resourceType: ResourceType, overrideSSHPluralization?: boolean): string {
  if (resourceType === ResourceType.ssh) {
    if (!!overrideSSHPluralization) {
      return 'SSH';
    }
    return 'SSH resources';
  }
  return pluralizeString(getFormattedResourceType(resourceType));
}

export function getMatchingLabelledObjectFromResource(
  resource: Resource,
  labelledObjects: Array<LabelledObject> | undefined
): LabelledObject | undefined {
  if (!labelledObjects) {
    return undefined;
  }
  return labelledObjects.find((labelledObject) => labelledObject.object_id === resource.metadata.id);
}

export function setResourceMapsAndColumnData<T extends TableElement>(
  resourceIdToResourceMap: Map<string, Resource>,
  resourceNameAndTypeToResourceMap: Map<string, Resource>,
  resourceList: Array<Resource>,
  columnDefs: Map<string, Column<T>>,
  overrideResourceMemebersAllowedValuesResourceId?: string
): void {
  resourceIdToResourceMap.clear();
  resourceNameAndTypeToResourceMap.clear();
  const resourceMembersColumn = columnDefs.get('resource_members');
  resourceMembersColumn.allowedValues.length = 0;
  for (const resource of resourceList) {
    resourceIdToResourceMap.set(resource.metadata.id, resource);
    resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
    if (!!overrideResourceMemebersAllowedValuesResourceId) {
      if (resource.metadata.id === overrideResourceMemebersAllowedValuesResourceId) {
        resourceMembersColumn.allowedValues.push(resource);
      }
    } else {
      resourceMembersColumn.allowedValues.push(resource);
    }
  }
}

export function getMaxResourceNameLength(): number {
  return 63;
}

export function getTrimmedResourceName(name: string): string {
  return name.trim();
}

export function getFormattedLowercaseResourceName(name: string): string {
  return getTrimmedResourceName(name.toLowerCase());
}

export function getRdpDesktopExtraConfigOptions(): Array<string> {
  return [
    'alternate full address',
    'alternate shell',
    'authentication level',
    'disableconnectionsharing',
    'domain',
    'enablecredsspsupport',
    'enablerdsaadauth',
    'full address',
    'gatewaycredentialssource',
    'gatewayhostname',
    'gatewayprofileusagemethod',
    'gatewayusagemethod',
    'kdcproxyname',
    'promptcredentialonce',
    'targetisaadjoined',
    'username',
    'autoreconnection enabled',
    'bandwidthautodetect',
    'compression',
    'networkautodetect',
    'videoplaybackmode',
    'audiocapturemode',
    'audiomode',
    'camerastoredirect',
    'devicestoredirect',
    'drivestoredirect',
    'encode redirected video capture',
    'keyboardhook',
    'redirectclipboard',
    'redirectcomports',
    'redirected video capture encoding quality',
    'redirectlocation',
    'redirectprinters',
    'redirectsmartcards',
    'redirectwebauthn',
    'usbdevicestoredirect',
    'desktop size id',
    'desktopheight',
    'desktopscalefactor',
    'desktopwidth',
    'dynamic resolution',
    'maximizetocurrentdisplays',
    'screen mode id',
    'selectedmonitors',
    'singlemoninwindowedmode',
    'smart sizing',
    'use multimon',
    'remoteapplicationcmdline',
    'remoteapplicationexpandcmdline',
    'remoteapplicationexpandworkingdir',
    'remoteapplicationfile',
    'remoteapplicationicon',
    'remoteapplicationmode',
    'remoteapplicationname',
    'remoteapplicationprogram',
  ];
}

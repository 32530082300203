<portal-page-info
  pageTitle="Active Users"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions && org_id" class="active-metrics-container">
  <form
    class="metrics-form"
    [formGroup]="activeMetricsForm"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="date-filters-container">
      <p class="metrics-filter-field metrics-filter-field-title">Start Time</p>

      <mat-form-field class="metrics-filter-field">
        <mat-label>Choose start time</mat-label>
        <input
          matInput
          [max]="maxDate"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          formControlName="dtFrom"
          [owlDateTimeFilter]="getStartDateMaxSetter(activeMetricsForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt1></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>

      <p class="metrics-filter-field metrics-filter-field-title">End Time</p>

      <mat-form-field class="metrics-filter-field">
        <mat-label>Choose end time</mat-label>
        <input
          matInput
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          formControlName="dtTo"
          [owlDateTimeFilter]="getEndDateMinSetter(activeMetricsForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt2></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>
    </div>

    <div class="resource-filters-container">
      <mat-form-field class='filter-field'>
        <mat-label>Resource name</mat-label>
        <input
          matInput
          formControlName="resource_name"
          [matAutocomplete]="autoResourceNames"
          #resourceNamesTrigger="matAutocompleteTrigger"
          (keyup.enter)="keyTabManager.keyTabAutoInput($event.target.id, resourceNamesTrigger)"
        />
        <mat-autocomplete #autoResourceNames="matAutocomplete">
          <mat-option
            *ngFor="let option of filteredResourceOptions | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field class="filter-field">
        <mat-label>Resource type</mat-label>
        <mat-select 
          #selectResourceType
          formControlName="resource_type"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectResourceType)"
        >
          <mat-option
            *ngFor="let type of resourceTypesList"
            value="{{ type }}"
            >{{ type }}</mat-option
          >
        </mat-select>
      </mat-form-field>
    </div>

    <div class="button-container">
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!activeMetricsForm.valid"
        (click)="listActiveUsers()"
      >
        <fa-icon [icon]="['fas', 'search']"></fa-icon> VIEW METRICS
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        [disabled]="!activeMetricsForm.valid"
        (click)="listActiveUsersAndDownload()"
      >
        <mat-icon class="download-icon">cloud_download</mat-icon> DOWNLOAD
      </button>
    </div>
  </form>

  <div class="graph-container">
    <b>Number of Unique Active Users per Time Interval</b>
    <ngx-charts-bar-vertical
      [results]="graphDataSource"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel"
      [noBarWhenZero]="false"
      [roundEdges]="false"
      [legend]="false"
    >
      <ng-template #tooltipTemplate let-model="model">
        <div class=tooltip>{{ model.extra.warning }}</div>
        <div class=tooltip>{{ model.name }}</div>
        <div class=tooltip>Active Users: {{ model.value }}</div>
      </ng-template>
    </ngx-charts-bar-vertical>
  </div>

  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>
</div>

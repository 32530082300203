// TODO: will want to implement this in place of the custom-chiplist-input.utils file

import { Injectable } from '@angular/core';
import { ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NotificationService } from '@app/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InputData } from './input-data';
import { InputSize } from './input-size.enum';
import { MultiAutocompleteInputValues } from './multi-autocomplete-input-values';
import { ChiplistInput } from './chiplist-input';
import { ColumnTypes } from '../column-types.enum';

@Injectable({
  providedIn: 'root',
})
export class CustomChiplistInputHelperService {
  constructor() {}

  public createChiplistInput<T extends InputData>(name: string): ChiplistInput<T> {
    return {
      name,
      displayName: '',
      allowedValues: [],
      filterOn: true,
      isEditable: true,
      isUnique: false,
      isCaseSensitive: false,
      isRemovable: true,
      hasAutocomplete: true,
      isFreeform: false,
      newChipAdded: false,
      autoDropdownOptionSelected: false,
      hasMultiAutocomplete: false,
      isFirstMultiAutocompleteOptionSelected: false,
      addOnInput: true,
      isFormInputDirty: false,
      inputSize: InputSize.STANDARD,
      formControl: new UntypedFormControl(),

      requiredField: () => {
        return false;
      },
      getDisplayValue: (item: object | string, input: ChiplistInput<T>): string => {
        if (!!input?.hasMultiAutocomplete) {
          return input.getMultiAutocompleteOptionDisplayValue(item as string, input);
        }
        return item as string;
      },
      getElementFromValue: (item: string): any => {
        return item;
      },
      isValidEntry: (): boolean => {
        return true;
      },
      disableField: () => {
        return false;
      },
      getSecondaryFilteredValues: (input: ChiplistInput<T>) => {
        return this.getSecondaryFilteredValues(input.formControl, input);
      },
      getSecondaryAllowedValues: () => {
        return [];
      },
      onInputValueChange: (inputValue: string, input: ChiplistInput<T>) => {
        this.onDefaultInputValueChange(inputValue, input);
      },
      getOptionValue: (option: any): string => {
        return option;
      },
      getOptionDisplayValue: (option: any, element: T): string => {
        return option;
      },
      getChiplistValues: (element: T, chiplistInput: ChiplistInput<T>) => {
        return this.getChipValuesList(element, chiplistInput);
      },
      getMultiAutocompleteChipValueSeparator: () => {
        return this.getDefaultMultiAutocompleteChipValueSeparator();
      },
      getMultiAutocompleteOptionDisplayValue: (value: string, input: ChiplistInput<T>): string => {
        const multiAutocompleteInputValues = this.getMultiAutocompleteInputValues(value, input);
        if (input.isFirstMultiAutocompleteOptionSelected) {
          return multiAutocompleteInputValues.secondInputValue;
        }
        return multiAutocompleteInputValues.firstInputValue;
      },
      showColumn: true,
      type: ColumnTypes.CHIPLIST,
    };
  }

  public getDefaultMultiAutocompleteChipValueSeparator(): string {
    return ':';
  }

  public filterAllowedValues<T extends InputData>(chiplistInput: ChiplistInput<T>, value: string): Array<T> {
    const filterValue = value.toLowerCase();
    return chiplistInput.allowedValues.filter((entry) => chiplistInput.getDisplayValue(entry).toLowerCase().includes(filterValue));
  }

  public getFilteredValues<T extends InputData>(
    formControl: UntypedFormControl,
    chiplistInput: ChiplistInput<T>
  ): Observable<Array<string>> {
    return formControl.valueChanges.pipe(
      startWith(formControl.value as string),
      map((value: string | null) => {
        chiplistInput.onInputValueChange(value, chiplistInput);
        return value ? this.filterAllowedValues(chiplistInput, value) : chiplistInput.allowedValues.slice();
      })
    );
  }

  public filterSecondaryAllowedValues<T extends InputData>(chiplistInput: ChiplistInput<T>, value: string): Array<T> {
    const multiAutocompleteInputValues = this.getMultiAutocompleteInputValues(value, chiplistInput);
    const filterValue = multiAutocompleteInputValues.secondInputValue.toLowerCase();
    return chiplistInput
      .getSecondaryAllowedValues(multiAutocompleteInputValues.firstInputValue)
      .filter((entry) => chiplistInput.getDisplayValue(entry).toLowerCase().includes(filterValue));
  }

  public getSecondaryFilteredValues<T extends InputData>(
    formControl: UntypedFormControl,
    chiplistInput: ChiplistInput<T>
  ): Observable<Array<string>> {
    return formControl.valueChanges.pipe(
      startWith(formControl.value as string),
      map((value: string | null) => {
        chiplistInput.onInputValueChange(value, chiplistInput);
        return value ? this.filterSecondaryAllowedValues(chiplistInput, value) : chiplistInput.getSecondaryAllowedValues('').slice();
      })
    );
  }

  public onDefaultInputValueChange<T extends InputData>(inputValue: string, chiplistInput: ChiplistInput<T>): void {
    if (!chiplistInput.hasMultiAutocomplete) {
      return;
    }
    if (inputValue?.includes(chiplistInput.getMultiAutocompleteChipValueSeparator())) {
      chiplistInput.isFirstMultiAutocompleteOptionSelected = true;
    } else {
      chiplistInput.isFirstMultiAutocompleteOptionSelected = false;
    }
  }

  public getMultiAutocompleteInputValues<T extends InputData>(
    value: string,
    chiplistInput: ChiplistInput<T>
  ): MultiAutocompleteInputValues {
    const splitValueArray = value.split(chiplistInput.getMultiAutocompleteChipValueSeparator());
    const firstInputValue = splitValueArray[0];
    let secondInputValue = splitValueArray[1];
    if (!secondInputValue) {
      secondInputValue = '';
    }
    return {
      firstInputValue,
      secondInputValue,
    };
  }

  public doValuesExistInOptionsList<T extends InputData>(valuesArray: Array<string>, chiplistInput: ChiplistInput<T>): boolean {
    if (chiplistInput.hasMultiAutocomplete) {
      if (chiplistInput.isFirstMultiAutocompleteOptionSelected) {
        const multiAutocompleteInputValues = this.getMultiAutocompleteInputValues(valuesArray[0], chiplistInput);
        return chiplistInput.getSecondaryAllowedValues(multiAutocompleteInputValues.firstInputValue).includes(valuesArray[0]);
      }
    }
    const allowedValuesDisplayValuesList = chiplistInput.allowedValues.map((item) => chiplistInput.getDisplayValue(item));
    for (const value of valuesArray) {
      if (allowedValuesDisplayValuesList.includes(value)) {
        return true;
      }
    }
    return false;
  }

  /**
   * Gets the input values entered by the user that do not exist
   */
  public getNonexistantValues<T extends InputData>(valuesArray: Array<string>, chiplistInput: ChiplistInput<T>, element: T): Array<string> {
    const nonexistantValues = [];
    for (const value of valuesArray) {
      const elementFromValue = chiplistInput.getElementFromValue(value, element);
      if (elementFromValue === undefined) {
        nonexistantValues.push(value);
      }
    }
    return nonexistantValues;
  }

  public handleNonexistantValues(nonexistantValues: Array<string>, notificationService: NotificationService): void {
    let message = nonexistantValues.join(', ');
    if (nonexistantValues.length === 1) {
      message += ' does not exist';
    } else {
      message += ' do not exist';
    }
    notificationService.error(message);
  }

  public handleInvalidValues(invalidValues: Array<string>, notificationService: NotificationService): void {
    let message = invalidValues.join(', ');
    if (invalidValues.length === 1) {
      message += ' is not valid';
    } else {
      message += ' are not valid';
    }
    notificationService.error(message);
  }

  public getExistingChipValues<T extends InputData>(element: T, chiplistInput: ChiplistInput<T>): Set<string> {
    const existingChipValues: Set<string> = new Set();
    for (const item of chiplistInput.getChiplistValues(element, chiplistInput)) {
      existingChipValues.add(chiplistInput.getDisplayValue(item));
    }
    return existingChipValues;
  }

  /**
   * Gets the input values entered by the user that are invalid
   */
  public async getInvalidValues<T extends InputData>(
    valuesArray: Array<string>,
    chiplistInput: ChiplistInput<T>,
    element: T
  ): Promise<Array<string>> {
    const invalidValues = [];
    if (chiplistInput.isValidEntry === undefined) {
      return invalidValues;
    }
    for (const value of valuesArray) {
      const isValidEntryResult = await Promise.resolve(
        chiplistInput.isValidEntry(chiplistInput.getElementFromValue(value, element), element)
      );
      if (!isValidEntryResult) {
        invalidValues.push(value);
      }
    }
    return invalidValues;
  }

  /**
   * Adds a new chip to the chips input when an autocomplete option is clicked
   */
  public addChipOnAutoSelectEvent<T extends InputData>(chipValue: string, element: T, chiplistInput: ChiplistInput<T>): void {
    this.onNewChipAdded(chiplistInput);
    chiplistInput.getChiplistValues(element, chiplistInput).push(chiplistInput.getElementFromValue(chipValue, element));
    element.dirty = true;
  }

  public onNewChipAdded<T extends InputData>(chiplistInput: ChiplistInput<T>): void {
    chiplistInput.newChipAdded = true;
  }

  public updateMultiAutocompleteInputOnFirstOptionSelection<T extends InputData>(value: string, chiplistInput: ChiplistInput<T>): string {
    return `${value}${chiplistInput.getMultiAutocompleteChipValueSeparator()}`;
  }

  /**
   * Converts valid/existing chip list values to their appropriate elements
   * and adds those elements to the target element
   */
  public updateElementFromChiplistValues<T extends InputData>(
    valuesArray: Array<string>,
    element: T,
    chiplistInput: ChiplistInput<T>
  ): void {
    for (const value of valuesArray) {
      const elementFromValue = chiplistInput.getElementFromValue(value, element);
      if (elementFromValue !== undefined) {
        chiplistInput.getChiplistValues(element, chiplistInput).push(elementFromValue);
      }
    }
  }

  /**
   * Converts valid/existing chip list values to their appropriate elements
   * and adds those elements to the target element. Then refreshes the UI view.
   */
  public updateElementFromChiplistValuesAndDetechChanges<T extends InputData>(
    valuesArray: Array<string>,
    element: T,
    chiplistInput: ChiplistInput<T>,
    changeDetector: ChangeDetectorRef
  ): void {
    this.updateElementFromChiplistValues(valuesArray, element, chiplistInput);
    changeDetector.detectChanges();
  }

  public getChipValuesList<T extends InputData>(element: T, chiplistInput: ChiplistInput<T>): Array<string> {
    if (!element) {
      return [];
    }
    const chipValuesList = element[chiplistInput?.name];
    if (!chipValuesList) {
      return [];
    }
    return chipValuesList;
  }

  /**
   * A valid multi-autocomplete chip value must be in the format of
   * <firstValue>chiplistInput.getMultiAutocompleteChipValueSeparator()<secondValue>.
   * For example, a:b is a valid value if the MultiAutocompleteChipValueSeparator is ":".
   */
  public isValidMultiAutocompleteChipValue<T extends InputData>(value: string, chiplistInput: ChiplistInput<T>): boolean {
    if (!value) {
      return false;
    }
    if (!value.includes(chiplistInput.getMultiAutocompleteChipValueSeparator())) {
      return false;
    }
    const multiAutocompleteInputValues = this.getMultiAutocompleteInputValues(value, chiplistInput);
    if (!multiAutocompleteInputValues.firstInputValue || !multiAutocompleteInputValues.secondInputValue) {
      return false;
    }
    return true;
  }
}

import { createReducer, on } from '@ngrx/store';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import * as APIKeyActions from './api-key.actions';
import { APIKey } from '@agilicus/angular';
import { BasicCollectionState } from '@app/core/basic-collection-state';
import { sortListByCreatedDate } from '../api/state-driven-crud/state-driven-crud';
import { getDefaultBasicCollectionState } from '../helpers/entity-state-utils';

export const apiKeysFeatureKey = 'apiKey';

export type APIKeyState = BasicCollectionState<APIKey>;

export const adapter: EntityAdapter<APIKey> = createEntityAdapter<APIKey>({
  sortComparer: sortListByCreatedDate,
  selectId: (share) => share.metadata.id,
});

export const initialState: APIKeyState = adapter.getInitialState(getDefaultBasicCollectionState<APIKey>());

export const apiKeyReducer = createReducer(
  initialState,
  on(APIKeyActions.addAPIKey, (state, action) => adapter.addOne(action.obj, state)),
  on(APIKeyActions.upsertAPIKey, (state, action) =>
    adapter.upsertOne(action.obj, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(APIKeyActions.addAPIKeys, (state, action) => adapter.addMany(action.objs, state)),
  on(APIKeyActions.upsertAPIKeys, (state, action) =>
    adapter.upsertMany(action.objs, {
      ...state,
      saving_state: false,
      refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data,
    })
  ),
  on(APIKeyActions.updateAPIKey, (state, action) => adapter.updateOne(action.obj, state)),
  on(APIKeyActions.updateAPIKeys, (state, action) => adapter.updateMany(action.objs, state)),
  on(APIKeyActions.deleteAPIKey, (state, action) =>
    adapter.removeOne(action.id, { ...state, refresh_data: action.refreshData ? state.refresh_data + 1 : state.refresh_data })
  ),
  on(APIKeyActions.deleteAPIKeys, (state, action) => adapter.removeMany(action.ids, { ...state, refresh_data: state.refresh_data + 1 })),
  on(APIKeyActions.loadAPIKeys, (state, action) =>
    adapter.setAll(action.objs, { ...state, saving_state: false, loaded_org_id: action.org_id, refresh_data: state.refresh_data + 1 })
  ),
  on(APIKeyActions.clearAPIKeys, (state) => adapter.removeAll({ ...state, refresh_data: state.refresh_data + 1 })),
  // custom reducers:
  on(APIKeyActions.savingAPIKey, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(APIKeyActions.savingAPIKeys, (state, action) => {
    return { ...state, saving_state: true, trigger_update_side_effects: action.trigger_update_side_effects };
  }),
  on(APIKeyActions.initAPIKeys, (state, action) => {
    return { ...state, should_populate: true };
  }),
  on(APIKeyActions.refreshAPIKeys, (state, action) => {
    return { ...state, refresh_data: state.refresh_data + 1 };
  })
);

export const { selectIds, selectEntities, selectAll, selectTotal } = adapter.getSelectors();

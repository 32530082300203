import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, OnChanges, Input } from '@angular/core';
import { Column, createSelectRowColumn, createInputColumn } from '../table-layout/column-definitions';
import { FilterManager } from '../filter/filter-manager';
import { TableElement } from '../table-layout/table-element';
import { Subject, Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { ActionApiApplicationsSavingEnvConfigVarList } from '@app/core/api-applications/api-applications.actions';
import { EnvironmentConfigVar, EnvironmentConfigVarList } from '@agilicus/angular';
import { updateTableElements } from '../utils';
import { selectApiApplicationsEnvironmentConfigVarList } from '@app/core/api-applications/api-applications.selectors';
import { PaginatorActions, PaginatorConfig } from '../table-paginator/table-paginator.component';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface EnvConfigVarElement extends EnvironmentConfigVar, TableElement {}

@Component({
  selector: 'portal-application-environment-variables',
  templateUrl: './application-environment-variables.component.html',
  styleUrls: ['./application-environment-variables.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentVariablesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<EnvConfigVarElement>> = new Map();
  public tableData: Array<EnvConfigVarElement> = [];
  public rowObjectName = 'VARIABLE';
  public filterManager: FilterManager = new FilterManager();
  public linkDataSource = false;
  public paginatorConfig = new PaginatorConfig<EnvConfigVarElement>(true, true, 25, 5, new PaginatorActions<EnvConfigVarElement>());
  private varListCopy: EnvironmentConfigVarList;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    const environmentConfigVarListState$ = this.store.pipe(select(selectApiApplicationsEnvironmentConfigVarList));
    environmentConfigVarListState$.pipe(takeUntil(this.unsubscribe$)).subscribe((environmentConfigVarListStateResp) => {
      if (!environmentConfigVarListStateResp) {
        this.resetEmptyTable();
        return;
      }
      this.setEditableColumnDefs();
      // Need to make a copy since we cannot modify the readonly data from the store.
      this.varListCopy = cloneDeep(environmentConfigVarListStateResp);
      this.updateTable(this.varListCopy);
      this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        this.updateTable(this.varListCopy);
      });
    });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const nameColumn = createInputColumn('name');
    nameColumn.requiredField = () => true;
    nameColumn.isUnique = true;
    nameColumn.isCaseSensitive = true;

    const valueColumn = createInputColumn('value');
    valueColumn.requiredField = () => true;
    valueColumn.isCaseSensitive = true;

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(nameColumn.name, nameColumn);
    this.columnDefs.set(valueColumn.name, valueColumn);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const nameColumn = this.columnDefs.get('name');
    nameColumn.isEditable = !this.fixedTable;

    const valueColumn = this.columnDefs.get('value');
    valueColumn.isEditable = !this.fixedTable;
  }

  private updateTable(envConfigVarList: EnvironmentConfigVarList): void {
    this.buildData(envConfigVarList);
    this.replaceTableWithCopy();
    this.paginatorConfig.actions.dataFetched({
      data: this.tableData,
      searchDirection: 'forwards',
      limit: this.paginatorConfig.getQueryLimit(),
      nextKey: '',
      previousKey: '',
    });
  }

  private buildData(envConfigVarList: EnvironmentConfigVarList): void {
    const data: Array<EnvConfigVarElement> = [];
    for (let i = 0; i < envConfigVarList.configs.length; i++) {
      data.push(this.createEnvConfigVarElement(envConfigVarList.configs[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createEnvConfigVarElement(envConfVar: EnvironmentConfigVar, index: number): EnvConfigVarElement {
    const data: EnvConfigVarElement = {
      name: envConfVar.name,
      value: envConfVar.value,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  public makeEmptyTableElement(): EnvConfigVarElement {
    return {
      name: '',
      value: '',
      ...getDefaultNewRowProperties(),
    };
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  /**
   * Receives an EnvConfigVarElement from the table then updates and saves
   * the current_environment_config_var_list.
   */
  public updateEvent(updatedElement: EnvConfigVarElement): void {
    const updatedVarsList = this.getEnvConfigVarsFromTable();
    if (updatedElement.index === -1) {
      // new element
      updatedVarsList.configs.unshift({
        name: updatedElement.name,
        value: updatedElement.value,
      });
    }
    this.savingEnvConfigVars(updatedVarsList);
  }

  private removeElements(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  public deleteSelected(): void {
    this.removeElements();
    const updatedVarsList = this.getEnvConfigVarsFromTable();
    this.savingEnvConfigVars(updatedVarsList);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private savingEnvConfigVars(envConfigVarList: EnvironmentConfigVarList): void {
    this.store.dispatch(new ActionApiApplicationsSavingEnvConfigVarList(envConfigVarList));
  }

  private getEnvConfigVarsFromTable(): EnvironmentConfigVarList {
    const vars: EnvironmentConfigVarList = {
      configs: this.tableData.map((entry: EnvConfigVarElement) => {
        const envConfigVar: EnvironmentConfigVar = {
          name: entry.name,
          value: entry.value,
        };
        return envConfigVar;
      }),
      _builtin_original: this.varListCopy._builtin_original,
    };
    return vars;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }
}

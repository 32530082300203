import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { FilterChipOptions } from '../filter-chip-options';
import { ResourceAndLabelsResponse, ResourceOverviewElement } from '../resource-overview/resource-overview.component';
import { ENTER, COMMA, TAB } from '@angular/cdk/keycodes';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { LabelAssociation, LabelsService } from '@agilicus/angular';
import { NotificationService } from '@app/core';
import { forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import { createNewLabelledObjectWithLabels$, getUpdatedLabels$ } from '@app/core/api/labels/labels-api-utils';

export interface ResourceLabelsDialogData {
  resourceElementsList: Array<ResourceOverviewElement>;
  chiplistInput: ChiplistInput<ResourceOverviewElement>;
  placeholderElement: ResourceOverviewElement;
  orgId: string;
}

@Component({
  selector: 'portal-resource-labels-dialog',
  templateUrl: './resource-labels-dialog.component.html',
  styleUrls: ['./resource-labels-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceLabelsDialogComponent implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA, TAB],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ResourceLabelsDialogData,
    private dialogRef: MatDialogRef<ResourceLabelsDialogComponent>,
    private changeDetector: ChangeDetectorRef,
    private labelsService: LabelsService,
    private notificationService: NotificationService
  ) {}

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private prepareUpdateLabelledObjectsObservableArray$(): Array<Observable<ResourceAndLabelsResponse>> {
    const observablesArray: Array<Observable<ResourceAndLabelsResponse>> = [];
    for (const resourceElement of this.data.resourceElementsList) {
      let observable: Observable<ResourceAndLabelsResponse> = of(undefined);
      if (!resourceElement.backingLabelledObject) {
        observable = createNewLabelledObjectWithLabels$(this.labelsService, resourceElement, undefined, this.data.orgId, true);
      } else {
        observable = getUpdatedLabels$(
          this.labelsService,
          resourceElement,
          undefined,
          resourceElement.backingLabelledObject,
          this.data.orgId,
          true
        );
      }
      observablesArray.push(observable);
    }
    return observablesArray;
  }

  public onConfirmClick(): void {
    for (const resourceElement of this.data.resourceElementsList) {
      resourceElement.labels = this.data.placeholderElement.labels;
    }
    const observablesArray = this.prepareUpdateLabelledObjectsObservableArray$();
    if (observablesArray.length === 0) {
      this.dialogRef.close(false);
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Labels were successfully updated for all selected resources');
          this.dialogRef.close(true);
        },
        (errorResp) => {
          this.notificationService.error('Failed to update labels for all selected resources');
        }
      );
  }

  public disableSaveButton(): boolean {
    if (this.data.placeholderElement.labels.length === 0) {
      return true;
    }
    return false;
  }

  public removeChip(label: LabelAssociation, labels: Array<LabelAssociation>): void {
    const index = labels.map((labelAssociation) => labelAssociation.label_name).indexOf(label.label_name);
    if (index >= 0) {
      labels.splice(index, 1);
    }
    this.changeDetector.detectChanges();
  }
}

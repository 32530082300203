<mat-slide-toggle class="stepper-content-padding" [(ngModel)]="showWebAppFirewallStep" (change)="changeShowWebAppFirewallStep()">My application uses custom web application firewall settings</mat-slide-toggle>
<mat-vertical-stepper 
  linear
  #hostMethodStepper
  portalMatVerticalStepperScroller
  class="sub-stepper"
>
  <mat-step *ngIf="showWebAppFirewallStep" [completed]="isWebAppFirewallStepValid()">
    <ng-template matStepLabel>Set up web application firewall</ng-template>
    <form
      [formGroup]="commonPathPrefixFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <portal-stepper-form-field
        label="My application hosts all or most of its pages under a common path prefix?"
        placeholder="/common_path_prefix"
        [formCtrl]="commonPathPrefixFormGroup.get('common_path_prefix')"
        (formBlur)="onCommonPathPrefixFormBlur()"
        [tooltip]="redirectSubpathTooltipText"
      >
      </portal-stepper-form-field>
    </form>
    
    <form
      [formGroup]="rewriteMediaTypesFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="rewrite_common_media_types"
          (change)="onCheckboxChange('rewrite_common_media_types', $event.checked)"
          aria-label="Checkbox that toggles whether the application will rewrite common media types"
        >
          My application expects to receive requests using its internal hostname
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getRewriteCommonMediaTypesCustomTooltip()"
        >
          help_outline
        </mat-icon>
      </div>
    </form>

    <form
      [formGroup]="corsTypeFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="hasCors"
          (change)="onCheckboxChange('hasCors', $event.checked)"
          aria-label="Checkbox that toggles whether the application uses CORS"
        >
          My application is an API
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getCorsCheckboxTooltip()"
        >
          help_outline
        </mat-icon>
      </div>

      <div *ngIf="showNestedCorsOptions()" class="cors-nested-options">
        <label
          id="cors-radio-group-label"
          class="radio-button-group-label"
        >
          My application:
        </label>
        <mat-radio-group
          formControlName="corsType"
          aria-labelledby="cors selection radio buttons"
          class="cors-radio-group"
        >
          <div
            *ngFor="let template of getCorsTemplateTypesForStepper()"
            class="cors-radio-button-container full-line-radio-button"
          >
            <mat-radio-button
              class="cors-radio-button"
              [value]="template"
              (change)="onCorsTypeChange(template)"
            >
              {{ getStepperCorsTemplateDescriptiveText(template) }}
            </mat-radio-button>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getCorsTemplateTooltipText(template)"
            >
              help_outline
            </mat-icon>
          </div>
        </mat-radio-group>
      </div>
    </form>

    <form
      [formGroup]="http2FormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="disable_http2"
          (change)="onCheckboxChange('disable_http2', $event.checked)"
          aria-label="Checkbox that toggles whether the application service supports http2"
        >
          My application does not support http2 (supports only http/1.1)
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getDisableHttp2CheckboxTooltip()"
        >
          help_outline
        </mat-icon>
      </div>
    </form>

    <form
      [formGroup]="webApplicationFirewallFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="proxy_location"
          (change)="onProxyLocationOptionChange($event.checked)"
          aria-label="Checkbox that toggles whether the application requires a full web application firewall"
        >
          My application requires full web application firewall
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getFullWebApplicationFirewallCheckboxTooltip()"
        >
          help_outline
        </mat-icon>
      </div>
    </form>

    <form
      [formGroup]="fqdnAliasOptionFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
      (keydown.enter)="$event.preventDefault()"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="selectedFqdnAlias"
          (change)="onFqdnAliasOptionChange($event.checked)"
          aria-label="Checkbox that toggles whether the application is access via an alternate hostname"
        >
          {{ getFqdnAliasManualOptionDisplayValue() }}
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="getHostnameAliasTooltipText()"
        >
          help_outline
        </mat-icon>
      </div>

      <div *ngIf="showFqdnAliasListStep()" class="hostname-alias-container">
        <mat-form-field>
          <mat-label>Fully-qualified domain names</mat-label>
          <mat-chip-grid #fqdnAliasChipList>
            <mat-chip-row
              *ngFor="let fqdnAlias of currentApplicationModel.hosting.fqdnAliases.fqdnAliasList"
              [selectable]="filterChipOptions.selectable"
              [removable]="filterChipOptions.removable"
              (removed)="removeFqdnAliasChip(fqdnAlias, currentApplicationModel.hosting.fqdnAliases.fqdnAliasList)"
            >
              {{ fqdnAlias }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip-row>
            <input
              #input
              matInput
              formControlName="fqdnAliases"
              [matChipInputFor]="fqdnAliasChipList"
              [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
              [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
              (matChipInputTokenEnd)="addFqdnAliasOnInputEvent($event)"
              matTooltip="Separate multiple entries by a semicolon"
              (keyup.enter)="keyTabManager.keyTabChipList($event.target.value, $event.target.id, undefined, false)"
            />
          </mat-chip-grid>
          <mat-error>Provide at least one Fully-qualified domain name</mat-error>
        </mat-form-field>
        <p>Each of these fully-qualified domain names must be a CNAME pointing to "ca-1.agilicus.ca". For more information, see <a href="https://www.agilicus.com/signup/" target="_blank">https://www.agilicus.com/signup/</a> (in the Domain Selection). Please note, you may need to navigate to <a href="{{ getAuthClientsLink() }}">{{ getAuthClientsLink() }}</a> and adjust the authentication client redirect URIs after this application is created.</p>
      </div>
    </form>

    <p class="descriptive-text web-app-firewall-text">After configuring this application, you can configure the web application firewall using the "Define" sub-menu under the "Applications" tab on the left.</p>
  </mat-step>

  <div *ngIf="isAccessedOnPrem(currentApplicationModel.hosting)">
    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="getFilteredConnectorsList()"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>
    </mat-step>

    <mat-step [stepControl]="upstreamServiceFormGroup">

      <portal-page-info
        [productGuideLink]="authRulesProductGuideLink"
        pageInfoWidth="full"
      >
      </portal-page-info>
      <form
        [formGroup]="upstreamServiceFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <ng-template matStepLabel>Enter the upstream service(s)</ng-template>
        <p>This would be the hostname<span *ngIf="isAccessedViaVpn(currentApplicationModel.hosting)">, IP address</span> and port in your private network of the service you are exposing.</p>
        <div formArrayName="upstreamService">
          <table class="upstreamService-container">
            <div *ngFor="let upstreamService of upstreamService().controls; let i=index" [formGroupName]="i">
              <tr>
                <td>
                  <mat-form-field>
                    <mat-label>local hostname/ip</mat-label>
                    <input
                      matInput
                      formControlName="hostname"
                      (blur)="onUpstreamServiceFormBlur('hostname', currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                    />
                  </mat-form-field>
                </td>

                <td>
                  <mat-form-field>
                    <mat-label>local port</mat-label>
                    <input
                      matInput
                      formControlName="port"
                      (blur)="onUpstreamServiceFormBlur('port', currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                    />
                  </mat-form-field>
                </td>

                <td>
                  <mat-form-field *ngIf="isAccessedViaVpn(currentApplicationModel.hosting)">
                    <mat-label>Enter the IP address</mat-label>
                    <input
                      matInput
                      formControlName="ip_address"
                      (blur)="onUpstreamServiceFormBlur('ip_address', currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                    />
                  </mat-form-field>
                </td>

                <td>
                  <mat-form-field>
                    <mat-label>Select TLS type:</mat-label>
                    <mat-select 
                      #select2
                      formControlName="tls"
                      (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select2)"
                      (selectionChange)="onTlsValueChange(currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                    >
                      <mat-option value="{{ tlsTypes.NoTls }}"> No TLS </mat-option>
                      <mat-option value="{{ tlsTypes.Tls }}"> Service is accessed via TLS </mat-option>
                      <mat-option value="{{ tlsTypes.TlsAndVerify }}"> Service is accessed via TLS and verify </mat-option>
                    </mat-select>
                  </mat-form-field>
                </td>

                <td>
                  <mat-form-field>
                    <mat-label>Select a service type:</mat-label>
                    <mat-select 
                      #select1
                      formControlName="expose_type"
                      (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
                      (selectionChange)="onUpstreamServiceFormBlur('expose_type', currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                    >
                      <mat-option *ngIf="i === 0" value="application"> application </mat-option>
                      <span *ngIf="i > 0">
                        <mat-option
                          *ngFor="let type of expose_types"
                          value="{{ type }}"
                          >{{ type }}
                        </mat-option>
                      </span>
                    </mat-select>
                  </mat-form-field>
                </td>

                <td>
                <mat-form-field *ngIf="isExposeTypeHostName('hostname', i)">
                  <mat-label>hostname alias</mat-label>
                  <input
                    matInput
                    formControlName="hostname_alias"
                    (blur)="onUpstreamServiceFormBlur('hostname_alias', currentApplicationModel.hosting.on_prem.upstream_services[i], i)"
                    (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                  />
                </mat-form-field>
                </td>

                <td>
                  <div *ngIf="i > 0" class="delete-upstream-button">
                    <button
                      mat-raised-button
                      color="warn"
                      class="delete-service-button"
                      (click)="removeUpstreamService(i)"
                      (keydown.enter)="preventDeleteUpstreamServiceOnEnter($event)"
                    >
                      DELETE
                    </button>
                  </div>
                </td>
              </tr>
            </div>
          </table>
        </div>
        <div>
          <span class="buttonAsText" (click)="addUpstreamService()">+ Add more services</span>
        </div>
  
      </form>
    </mat-step>
  </div>
  
  <mat-step *ngIf="!isAccessedOnPrem(currentApplicationModel.hosting)">
    <ng-template matStepLabel>Enter services</ng-template>
    <p class="descriptive-text">After configuring this application, you can configure services using the "Sevices" sub-menu under the "Organisation" tab on the left.</p>
  </mat-step>

  <mat-step *ngIf="canConfigureRemapRules(currentApplicationModel)">
    <ng-template matStepLabel>Enter remap rules</ng-template>
    <p class="descriptive-text">Will enter the remap rules here:</p>
  </mat-step>

  <div *ngIf="canConfigureRuntime(currentApplicationModel)">
    <form
      [formGroup]="runtimeFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <!-- FORK -->
      <mat-step [steControl]="runtimeFormGroup.get('selectedRuntime')">
        <ng-template matStepLabel>Select container</ng-template>
        <label
          id="container-radio-group-label"
          class="radio-button-group-label"
        >
          Will use:
        </label>
        <mat-radio-group
          aria-labelledby="container selection radio buttons"
          class="container-radio-group"
          formControlName="selectedRuntime"
        >
          <mat-radio-button
            class="container-radio-button"
            *ngFor="let container of runtimeOptionData"
            [value]="container.value"
            (change)="onRuntimeOptionChange(container.value)"
          >
            {{container.displayValue}}
          </mat-radio-button>
        </mat-radio-group>
      </mat-step>

      <mat-step [stepControl]="runtimeFormGroup.get('image')" *ngIf="!!getRuntimeOptionValue(currentApplicationModel.hosting)">
        <div *ngIf="isUsingOwnRuntime(currentApplicationModel.hosting)">
          <ng-template matStepLabel>Enter image runtime</ng-template>
          <portal-stepper-form-field
            placeholder="Enter the image runtime"
            [formCtrl]="runtimeFormGroup.get('image')"
            (formBlur)="onFormBlur(runtimeFormGroup, 'image', currentApplicationModel.hosting.in_cloud.runtime.own)"
          >
          </portal-stepper-form-field>
        </div>

        <div *ngIf="isUsingAgilicusRuntime(currentApplicationModel.hosting)">
          <ng-template matStepLabel>Select from list of runtimes</ng-template>
          <mat-form-field>
            <mat-label>Select the image runtime</mat-label>
            <mat-select 
              #selectImage
              formControlName="image"
              (selectionChange)="modifyAppStepperDataOnFormSelectionChange(runtimeFormGroup, 'image', currentApplicationModel.hosting.in_cloud.runtime.agilicus, $event.value)"
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectImage)"
            >
              <!-- TODO: add list of image runtimes -->
              <!--
              <mat-option 
                *ngFor="let appBundle of appBundles"
                value="{{ appBundle.label }}"
              >
                {{ appBundle.label }}
              </mat-option>
              -->
              <mat-option value="oidc-proxy">oidc-proxy</mat-option>
              <mat-option value="runtime 2">runtime 2</mat-option>
              <mat-option value="runtime 3">runtime 3</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </mat-step>

      <mat-step *ngIf="isUsingAgilicusRuntime(currentApplicationModel.hosting)">
        <ng-template matStepLabel>Upload bundle</ng-template>
        <portal-upload-button
          class="upload-button-container"
          [isUploading]="isUploadingBundle"
          buttonDescription="BUNDLE"
          tooltipText="Click to upload an application bundle"
          (readFile)="onReadBundleFile($event)"
        >
        </portal-upload-button>
      </mat-step>

      <mat-step
        *ngIf="isUsingOwnRuntime(currentApplicationModel.hosting)"
        [stepControl]="runtimeFormGroup.get('port')"
      >
        <ng-template matStepLabel>Enter port</ng-template>
        <portal-stepper-form-field
          placeholder="Enter the port"
          [formCtrl]="runtimeFormGroup.get('port')"
          (formBlur)="onFormBlur(runtimeFormGroup, 'port', currentApplicationModel.hosting.in_cloud.runtime.own)"
        >
        </portal-stepper-form-field>
      </mat-step>

      <mat-step
        *ngIf="isUsingOwnRuntime(currentApplicationModel.hosting)"
        [stepControl]="runtimeFormGroup.get('version_tag')"
      >
        <ng-template matStepLabel>Enter tag</ng-template>
        <portal-stepper-form-field
          placeholder="Enter the tag"
          [formCtrl]="runtimeFormGroup.get('version_tag')"
          (formBlur)="onFormBlur(runtimeFormGroup, 'version_tag', currentApplicationModel.hosting.in_cloud.runtime.own)"
        >
        </portal-stepper-form-field>
      </mat-step>
    </form>
  </div>
</mat-vertical-stepper>

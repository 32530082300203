<portal-page-info
  pageTitle="Authentication Clients"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="
  hasPermissions !== undefined &&
  !hasPermissions
">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasPermissions"
  class="issuer-clients-container"
>
  <div class="table-controls-container">
    <portal-table-control-buttons
      [rowObjectName]="rowObjectName"
      [buttonsToShow]="buttonsToShow"
      (addNewRowToTable)="openAddDialog()"
    >
    </portal-table-control-buttons>
    <a [hidden]=disableIdPMetadataDownload() [href]=IdPMetadataUri onclick="return false;" target="_blank" rel="noopener noreferrer">
    <portal-download-button
      buttonDescription="SAML METADATA"
      (downloadFile)="downloadIdPMetadata()"
    >
    </portal-download-button></a>
  </div>

  <portal-table-filter
    [filterMenuOptions]="filterMenuOptions"
    [filterManager]="filterManager"
    [columnDefs]="columnDefs"
    [dataSource]="dataSource"
    [keyTabManager]="keyTabManager"
    [isParentTable]="showTableColumnConfigButton"
    placeholder="Search Authentication Clients"
    tooltip="Enter keywords to search the contents of the Authentication Clients. Search is not case-sensitive."
  >
  </portal-table-filter>

  <div class="cards flex-row-wrap flex-layout-gap-2px">
    <mat-card
      appearance="outlined"
      class="flex-xs-80 flex-sm-60 flex-md-33"
      *ngFor="let data of dataSource.filteredData"
    >
      <mat-card-header>
        <mat-card-title>{{data.name}}</mat-card-title>
        <div class="more-actions">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="openEditDialog(data)">
              <span>Edit</span>
            </button>
            <button mat-menu-item (click)="deleteSelected(data)">
              <span>Delete</span>
            </button>
          </mat-menu>
        </div>
      </mat-card-header>
      <div class=client-details>
        <mat-card-content> Application: </mat-card-content>
        <mat-card-subtitle style="text-transform: capitalize;"> {{ data.application ? data.application : "All"}} </mat-card-subtitle>
        
        <mat-card-content> Secret: </mat-card-content>
        <mat-card-subtitle> {{data.secret}} </mat-card-subtitle>
        
        <mat-card-content> Allowed Organisations: </mat-card-content>
        <mat-card-subtitle style="text-transform: capitalize; margin-bottom: 0;" *ngFor="let orgScope of columnDefs.get('organisation_scope').getMultipleDisplayValues(data)">
          {{ replaceCharacterWithSpace(orgScope, '_') }}
        </mat-card-subtitle>
        
        <mat-card-content style="margin-top: 16px"> Multi-Factor: </mat-card-content>
        <mat-card-subtitle style="text-transform: capitalize;"> {{ replaceCharacterWithSpace(data.mfa_challenge, '_') }} </mat-card-subtitle>

        <mat-card-content style="margin-top: 16px"> Single Sign-On: </mat-card-content>
        <mat-card-subtitle style="text-transform: capitalize;"> {{ replaceCharacterWithSpace(data.single_sign_on, '_') }} </mat-card-subtitle>

        <mat-card-content> Redirects: </mat-card-content>
        <mat-card-subtitle> {{ getRedirectsLength(data) }}</mat-card-subtitle>

        <mat-card-content> Authentication Attributes: </mat-card-content>
        <mat-card-subtitle> {{ getAttributesLength(data) }} </mat-card-subtitle>

        <mat-card-content> Identity Provider Aliases: </mat-card-content>
        <mat-card-subtitle> {{ getAliasesLength(data) }} </mat-card-subtitle>
      </div>
    </mat-card>
  </div>
</div>

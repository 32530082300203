import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, Renderer2, Input, OnChanges } from '@angular/core';
import {
  createSelectRowColumn,
  createInputColumn,
  createFileColumn,
  Column,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { addNewEntryFocus, updateTableElements } from '../utils';
import { Subject, Observable, combineLatest, EMPTY } from 'rxjs';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from '../button-type.enum';
import {
  ApplicationFileConfigElement,
  MountType,
  ApplicationFileConfig,
  ApplicationsService,
  FilesService,
  makeApplicationFileConfig,
  FileConfigDownloadRequestArgs,
} from '@agilicus/angular';
import { cloneDeep } from 'lodash-es';
import {
  ActionApiApplicationsDeletingEnvFileConfig,
  ActionApiApplicationsCreatingEnvFileConfig,
  ActionApiApplicationsModifyingEnvFileConfig,
  ActionApiApplicationsRemoveNewFileAddedFlag,
} from '@app/core/api-applications/api-applications.actions';
import { OptionalFileConfigElement } from '../optional-types';
import { getFileNameFromPath, isLargeFile, blobToFile, isImageFile, getDirectoryFromPath, getFile } from '../file-utils';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { isValidPath } from '../validation-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { InputSize } from '../custom-chiplist-input/input-size.enum';

export interface FileConfigElement extends ApplicationFileConfigElement, TableElement {
  fileContent: string | ArrayBuffer;
  fileType: string;
  isLargeFile: boolean;
  downloaded: boolean;
}

@Component({
  selector: 'portal-application-environment-files',
  templateUrl: './application-environment-files.component.html',
  styleUrls: ['./application-environment-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentFilesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  public app_file_cfg: ApplicationFileConfig;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<FileConfigElement>> = new Map();
  private orgId$: Observable<string>;
  private orgId: string;
  private appState$: Observable<ApiApplicationsState>;
  public tableData: Array<FileConfigElement> = [];
  public rowObjectName = 'FILE';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<string> = [ButtonType.UPLOAD, ButtonType.DELETE];
  public uploadButtonTooltipText = 'Click to select a file to upload';
  public isUploading = false;
  public currentAppId: string;
  public currentEnvName: string;
  public pathToNewFileAddedMap: Map<string, FileConfigElement> = new Map();

  public dragAndDropRows = true;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private apps: ApplicationsService,
    private files: FilesService,
    private renderer: Renderer2,
    private notificationService: NotificationService
  ) {
    this.app_file_cfg = makeApplicationFileConfig(this.files, this.apps);
  }

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.orgId$ = this.store.pipe(select(selectApiOrgId));
    this.appState$ = this.store.pipe(select(selectApiApplications));
    combineLatest([this.orgId$, this.appState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, appStateResp]) => {
        if (
          orgIdResp === undefined ||
          appStateResp === undefined ||
          appStateResp.current_application === undefined ||
          appStateResp.current_environment === undefined ||
          appStateResp.current_environment_file_config_list === undefined ||
          appStateResp.loading_env_file_config
        ) {
          this.resetEmptyTable();
          return;
        }
        this.orgId = orgIdResp;
        this.currentAppId = appStateResp.current_application.id;
        this.currentEnvName = appStateResp.current_environment.name;
        if (appStateResp.new_file_added) {
          // We need to add focus to the first new entry in the table after
          // the new elements have been created in the DOM.
          addNewEntryFocus(this.rowObjectName);
          this.store.dispatch(new ActionApiApplicationsRemoveNewFileAddedFlag());
          return;
        }
        // Need to make a copy since we cannot modify the readonly data from the store.
        this.setEditableColumnDefs();
        this.updateTable(cloneDeep(appStateResp.current_environment_file_config_list));
      });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const pathColumn = createInputColumn('path');
    pathColumn.requiredField = () => true;
    pathColumn.isUnique = true;
    pathColumn.isEditable = true;
    pathColumn.isValidEntry = (path: string): boolean => {
      return isValidPath(path);
    };
    pathColumn.inputSize = InputSize.TEXT_INPUT_LARGE;

    const fileColumn = createFileColumn('fileContent');
    fileColumn.displayName = 'File';
    fileColumn.isEditable = true;
    fileColumn.downloadFile = async (element: any): Promise<void> => {
      await this.downloadFileToDevice(element);
    };

    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<FileConfigElement>> = [
      {
        displayName: 'Download File',
        icon: 'cloud_download',
        tooltip: 'Click to download the file',
        onClick: (element: any) => {
          this.downloadFileToDevice(element);
        },
      },
      {
        displayName: 'Replace File Contents',
        icon: 'cloud_upload',
        tooltip: 'Click to replace the file contents',
        fileReplace: true,
      },
    ];
    actionsColumn.allowedValues = menuOptions;

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(pathColumn.name, pathColumn);
    this.columnDefs.set(fileColumn.name, fileColumn);
    this.columnDefs.set(actionsColumn.name, actionsColumn);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const pathColumn = this.columnDefs.get('path');
    pathColumn.isEditable = !this.fixedTable;

    const fileColumn = this.columnDefs.get('fileContent');
    fileColumn.isEditable = !this.fixedTable;
  }

  private setDataIfDownloaded(targetFileConfigElement: FileConfigElement): void {
    for (const fileConfigElem of this.tableData) {
      if (!fileConfigElem.downloaded) {
        continue;
      }
      if (fileConfigElem.config_id === targetFileConfigElement.config_id) {
        targetFileConfigElement.downloaded = fileConfigElem.downloaded;
        targetFileConfigElement.fileContent = fileConfigElem.fileContent;
        targetFileConfigElement.fileType = fileConfigElem.fileType;
        break;
      }
      const newFileData = this.pathToNewFileAddedMap.get(targetFileConfigElement.path);
      if (newFileData !== undefined) {
        targetFileConfigElement.downloaded = newFileData.downloaded;
        targetFileConfigElement.fileContent = newFileData.fileContent;
        targetFileConfigElement.fileType = newFileData.fileType;
        // Now that we've added the file to the store, remove it from the map.
        this.pathToNewFileAddedMap.delete(targetFileConfigElement.path);
        break;
      }
    }
    if (!targetFileConfigElement.downloaded) {
      this.autoDownloadIfSmallFile(targetFileConfigElement);
    }
  }

  private async autoDownloadIfSmallFile(targetFileConfigElement: FileConfigElement): Promise<void> {
    if (!isLargeFile(targetFileConfigElement.size)) {
      await this.downloadFileToTable(targetFileConfigElement);
    }
  }

  private updateTable(envFileConfigList: Array<ApplicationFileConfigElement>): void {
    this.buildData(envFileConfigList);
    this.replaceTableWithCopy();
  }

  private buildData(envFileConfigList: Array<ApplicationFileConfigElement>): void {
    const data: Array<FileConfigElement> = [];
    for (let i = 0; i < envFileConfigList.length; i++) {
      const envFileConfig = envFileConfigList[i];
      data.push(this.createEnvFileConfigElement(envFileConfig, i));
    }
    updateTableElements(this.tableData, data);
  }

  private createEnvFileConfigElement(envFileConfig: ApplicationFileConfigElement, index: number): FileConfigElement {
    const data: FileConfigElement = {
      fileContent: null,
      fileType: null,
      isLargeFile: isLargeFile(envFileConfig.size),
      ...getDefaultTableProperties(index),
      downloaded: false,
      ...envFileConfig,
    };
    this.setDataIfDownloaded(data);
    return data;
  }

  private checkIfDuplicateFileNameOnNewFileAdd(file: File): boolean {
    for (const fileConfigElem of this.tableData) {
      const fileConfigElemName = getFileNameFromPath(fileConfigElem.path);
      const fileConfigElemDirectory = getDirectoryFromPath(fileConfigElem.path);
      // Currently, all files are automatically uploaded to the root directory.
      if (fileConfigElemName === file.name && fileConfigElemDirectory === '/') {
        this.notificationService.error('File name(s) already exist(s).');
        return true;
      }
    }
    return false;
  }

  private createAndAddNewFileConfigElement(fileConfigElement: FileConfigElement, file: File): void {
    // Need to make a copy since we cannot modify the readonly data from the store.
    const fileConfigElementCopy = cloneDeep(fileConfigElement);
    this.pathToNewFileAddedMap.set(fileConfigElement.path, fileConfigElementCopy);
    this.store.dispatch(new ActionApiApplicationsCreatingEnvFileConfig(fileConfigElementCopy, file));
  }

  private makeNewFileConfigElement(file: File, params?: OptionalFileConfigElement): FileConfigElement {
    return {
      path: '/' + file.name,
      type: MountType.CONFIGMAP,
      size: file.size,
      fileContent: '',
      fileType: file.type,
      isLargeFile: isLargeFile(file.size),
      downloaded: false,
      ...getDefaultNewRowProperties(),
      ...params,
    };
  }

  public async addFile(file: File): Promise<void> {
    if (this.checkIfDuplicateFileNameOnNewFileAdd(file)) {
      return;
    }
    if (isLargeFile(file.size)) {
      const fileConfigElement: FileConfigElement = this.makeNewFileConfigElement(file);
      this.createAndAddNewFileConfigElement(fileConfigElement, file);
      return;
    }
    await this.createFileReader(file);
  }

  private createFileReader(file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((targetFile) => {
        return (_) => {
          const content = reader.result;
          const fileConfigElement: FileConfigElement = this.makeNewFileConfigElement(targetFile, {
            fileContent: content,
            downloaded: true,
          });
          this.createAndAddNewFileConfigElement(fileConfigElement, file);
          resolve();
        };
      })(file);
      reader.onerror = (e: any) => {
        reject(e);
      };
      if (isImageFile(file.type)) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file);
      }
    });
  }

  private getNewFileContents(fileConfigElem: FileConfigElement, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((_) => {
        return (__) => {
          const content = reader.result;
          fileConfigElem.fileContent = content;
          fileConfigElem.downloaded = true;
          resolve();
        };
      })(file);
      reader.onerror = (e: any) => {
        reject(e);
      };
      if (isImageFile(file.type)) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file);
      }
    });
  }

  private readFileFromElement(fileConfigElem: FileConfigElement, file: File): Promise<void> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = ((targetFile) => {
        return (_) => {
          const content = reader.result;
          fileConfigElem.fileContent = content;
          fileConfigElem.fileType = targetFile.type;
          fileConfigElem.downloaded = true;
          resolve();
        };
      })(file);
      reader.onerror = (e: any) => {
        reject(e);
      };
      if (isImageFile(file.type)) {
        reader.readAsDataURL(file);
      } else {
        reader.readAsText(file);
      }
    });
  }

  public async addFiles(filesToAdd: Array<File>): Promise<void> {
    if (!filesToAdd) {
      return;
    }
    for (const file of filesToAdd) {
      await this.addFile(file);
    }
  }

  public async replaceFile(params: { updatedElement: FileConfigElement; file: File }): Promise<void> {
    if (params.file === undefined) {
      return;
    }
    if (!isLargeFile(params.file.size)) {
      await this.getNewFileContents(params.updatedElement, params.file);
    }
    // Need to make a copy since we cannot modify the readonly data from the store.
    const updatedFileConfigElementCopy = cloneDeep(params.updatedElement);
    this.store.dispatch(new ActionApiApplicationsModifyingEnvFileConfig(updatedFileConfigElementCopy, params.file));
  }

  public async readFile(event: any): Promise<void> {
    const file = getFile(event);
    await this.addFile(file);
  }

  private downloadFile(fileConfigElement: FileConfigElement): Promise<Blob> {
    if (!fileConfigElement.config_id) {
      return EMPTY.toPromise();
    }
    const args: FileConfigDownloadRequestArgs = {
      app_id: this.currentAppId,
      org_id: this.orgId,
      env_name: this.currentEnvName,
      config_id: fileConfigElement.config_id,
    };
    return this.app_file_cfg.download(args);
  }

  private async downloadFileToTable(fileConfigElement: FileConfigElement): Promise<void> {
    if (isLargeFile(fileConfigElement.size)) {
      this.notificationService.error('File size is larger than 200kB. Please download the file using the "Actions" menu options.');
      return;
    }
    const downloadedBlob = await this.downloadFile(fileConfigElement);
    fileConfigElement.fileType = downloadedBlob.type;
    const downloadedFile = blobToFile(downloadedBlob, getFileNameFromPath(fileConfigElement.path));
    await this.readFileFromElement(fileConfigElement, downloadedFile);
    this.replaceTableWithCopy();
    this.changeDetector.detectChanges();
  }

  private async downloadFileToDevice(fileConfigElement: FileConfigElement): Promise<void> {
    const link = this.renderer.createElement('a');
    const downloadedBlob = await this.downloadFile(fileConfigElement);
    link.href = window.URL.createObjectURL(downloadedBlob);
    link.download = getFileNameFromPath(fileConfigElement.path);
    link.click();
  }

  /**
   * Receives a FileConfigElement from the table then updates and saves
   * the file.
   */
  public async updateEvent(updatedFileConfigElement: FileConfigElement): Promise<void> {
    // Need to make a copy since we cannot modify the readonly data from the store.
    const updatedFileConfigElementCopy = cloneDeep(updatedFileConfigElement);
    if (updatedFileConfigElementCopy.downloaded) {
      const blobToUpload = new Blob([updatedFileConfigElementCopy.fileContent], {
        type: updatedFileConfigElementCopy.fileType,
      });
      this.store.dispatch(new ActionApiApplicationsModifyingEnvFileConfig(updatedFileConfigElementCopy, blobToUpload));
    } else {
      // If not downloaded, update metadata only.
      this.store.dispatch(new ActionApiApplicationsModifyingEnvFileConfig(updatedFileConfigElementCopy));
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public deleteSelected(envFileConfigsToDelete: Array<FileConfigElement>): void {
    for (const envFileConfig of envFileConfigsToDelete) {
      this.deleteFile(envFileConfig);
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private deleteFile(envFileConfigToDelete: FileConfigElement): void {
    // Need to make a copy of the envFileConfigToDelete or it will be converted to readonly.
    this.store.dispatch(new ActionApiApplicationsDeletingEnvFileConfig(cloneDeep(envFileConfigToDelete)));
  }
}

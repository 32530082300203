import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { APIKey, FileSummary } from '@agilicus/angular';

// GENERATED ACTIONS (with custom modifications):

/**
 * Will load the initial list of data objects into the ngrx entity state
 */
export const loadAPIKeys = createAction(
  '[APIKey/API] Load APIKeys',
  props<{ objs: APIKey[]; org_id: string; blankSlate: boolean | undefined }>()
);

/**
 * Will add a new data object to the ngrx entity state. Not currently implemented. Using upsertApplicationService instead.
 */
export const addAPIKey = createAction('[APIKey/API] Add APIKey', props<{ obj: APIKey }>());

/**
 * Will add new or update an existing data object in the ngrx entity state
 */
export const upsertAPIKey = createAction('[APIKey/API] Upsert APIKey', props<{ obj: APIKey; refreshData: boolean }>());

/**
 * Will add a list of data objects to the ngrx entity state. Not currently implemented.
 */
export const addAPIKeys = createAction('[APIKey/API] Add APIKeys', props<{ objs: APIKey[] }>());

/**
 * Will add new or update existing objects in the ngrx entity state.
 */
export const upsertAPIKeys = createAction('[APIKey/API] Upsert APIKeys', props<{ objs: APIKey[]; refreshData: boolean }>());

/**
 * Will update an existing object in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationService instead.
 */
export const updateAPIKey = createAction('[APIKey/API] Update APIKey', props<{ obj: Update<APIKey> }>());

/**
 * Will update a list of existing objects in the ngrx entity state.
 * Not currently implemented since we are using upsertApplicationServices instead.
 */
export const updateAPIKeys = createAction('[APIKey/API] Update APIKeys', props<{ objs: Update<APIKey>[] }>());

/**
 * Will delete a single data object from the ngrx entity state
 */
export const deleteAPIKey = createAction('[APIKey/API] Delete APIKey', props<{ id: string; obj: APIKey; refreshData?: boolean }>());

/**
 * Will delete a list of data objects from the ngrx entity state.
 */
export const deleteAPIKeys = createAction('[APIKey/API] Delete APIKeys', props<{ ids: string[]; refreshData?: boolean }>());

/**
 * Will delete all data from the ngrx entity state
 */
export const clearAPIKeys = createAction('[APIKey/API] Clear APIKeys');

// CUSTOM ACTIONS:

/**
 * Will begin initializing the state data for the relevant data type
 */
export const initAPIKeys = createAction('[APIKey/API] Init APIKeys', props<{ force: boolean; blankSlate: boolean }>());

/**
 * Will trigger the fetching of the state data for the relevant data type
 */
export const getAPIKeys = createAction('[APIKey/API] Get APIKeys', props<{ org_id: string; blankSlate: boolean }>());

/**
 * Will trigger creating a new or updating an exiting data object for the relevant data type
 */
export const savingAPIKey = createAction(
  '[APIKey/API] Saving APIKey',
  props<{ obj: APIKey; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger creating new or updating a list of data objects for the relevant data type
 */
export const savingAPIKeys = createAction(
  '[APIKey/API] Saving APIKeys',
  props<{ objs: Array<APIKey>; trigger_update_side_effects: boolean; notifyUser: boolean; refreshData?: boolean }>()
);

/**
 * Will trigger deleting a single data object for the relevant data type
 */
export const deletingAPIKey = createAction(
  '[APIKey/API] Deleting APIKey',
  props<{ obj: APIKey; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger deleting a list of data objects for the relevant data type
 */
export const deletingAPIKeys = createAction(
  '[APIKey/API] Deleting APIKeys',
  props<{ objs: Array<APIKey>; trigger_update_side_effects: boolean; notifyUser: boolean }>()
);

/**
 * Will trigger a refresh in the state for the relevant data type so that any watching observables are fired.
 * However, the actual state data is unchanged.
 */
export const refreshAPIKeys = createAction('[APIKey/API] Refresh APIKeys');

/**
 * Is used to return in an effect when no change to the state is needed.
 */
export const maintainAPIKeys = createAction('[APIKey/API] Maintain APIKeys');

<div class="dialog-container">

  <mat-dialog-content>
    <h2>Manage Configuration Overrides for Desktop "{{ desktopCopy.spec.name }}"</h2>

    <p>These fields will be added to the end of the generated RDP configuration files in the format {{ getExtraConfigFormatText() }}. These configurations will override other configurations.</p>

    <portal-desktop-extra-config
      #desktopExtraConfigComponent
      [desktopCopy]="desktopCopy"
    >
    </portal-desktop-extra-config>

  </mat-dialog-content>
  <mat-dialog-actions align="end" class="clear">
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      [disabled]="disableSaveButton()"
      tabindex="1"
    >
      SAVE
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
</div>
import { Resource, ResourceMember, ResourceTypeEnum, ResourcesService } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';
import { AppState, NotificationService } from '@app/core';
import { createNewResource$, getResources, updateExistingResource$ } from '@app/core/api/resources/resources-api-utils';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { selectCanAdminResources } from '@app/core/user/permissions/resources.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, take, takeUntil } from 'rxjs/operators';
import { FilterManager } from '../filter/filter-manager';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import {
  ActionMenuOptions,
  Column,
  createActionsColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { isValidResourceName } from '../validation-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { getDefaultResourceMembersColumn, getResourceNameAndTypeString, setResourceMapsAndColumnData } from '../resource-utils';
import { cloneDeep } from 'lodash-es';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';

export interface ResourceGroupElement extends TableElement {
  name: string;
  resource_members?: Array<ResourceMember>;
  backingResourceGroup: Resource;
}

export interface CombinedPermissionsAndResourceGroupData {
  permission: OrgQualifiedPermission;
  resources: Array<Resource>;
}

@Component({
  selector: 'portal-resource-group-admin',
  templateUrl: './resource-group-admin.component.html',
  styleUrls: ['./resource-group-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceGroupAdminComponent implements OnInit, OnDestroy {
  @Input() public showPageInfo = true;
  @Input() public overrideResourceMemebersAllowedValuesResourceId: string | undefined;
  @Input() public hideFilter = false;
  @Input() public hideUploadDownloadButtons = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private hasAppsPermissions$: Observable<OrgQualifiedPermission>;
  public hasAppsPermissions: boolean;
  private orgId: string;
  public tableData: Array<ResourceGroupElement> = [];
  public columnDefs: Map<string, Column<ResourceGroupElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'RESOURCE GROUP';
  public allResources: Array<Resource> = [];
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public pageDescriptiveText = `A resource group allows grouping of resources that may be logically associated with one another, for example, multiple services that may be associated with an Application. 
  The resource group can then be used for permission assignment so that any resources that are part of that group will automatically get the same permission.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/resource-groups/`;
  public isUploading = false;
  public progressBarController: ProgressBarController = new ProgressBarController();

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private resourcesService: ResourcesService,
    private appErrorHandler: AppErrorHandler
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.hasAppsPermissions$ = this.store.pipe(select(selectCanAdminResources));
    this.getPermissionsAndResourceGroups();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): Column<ResourceGroupElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.isValidEntry = (name: string): boolean => {
      return isValidResourceName(name);
    };
    nameColumn.requiredField = () => true;
    nameColumn.inputSize = InputSize.TEXT_INPUT_LARGE;
    return nameColumn;
  }

  /**
   * Parent Table Column
   */
  private getResourceMembersColumn(): Column<ResourceGroupElement> {
    const resourceMembersColumn = getDefaultResourceMembersColumn<ResourceGroupElement>(
      this.resourceIdToResourceMap,
      this.resourceNameAndTypeToResourceMap
    );
    resourceMembersColumn.isFreeform = false;
    return resourceMembersColumn;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<ResourceGroupElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ResourceGroupElement>> = [
      {
        displayName: 'Configure Resource Members',
        icon: 'apps',
        tooltip: 'Click to access the "Resource Members" advanced configuration',
        columnName: 'resource_members',
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [createSelectRowColumn(), this.getNameColumn(), this.getResourceMembersColumn(), this.getActionsColumn()],
      this.columnDefs
    );
  }

  private getCombinedAppsPermissionsAndResourceGroups$(): Observable<CombinedPermissionsAndResourceGroupData> {
    return this.hasAppsPermissions$.pipe(
      concatMap((hasAppsPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasAppsPermissionsResp?.orgId;
        let allResources$: Observable<Array<Resource> | undefined> = of(undefined);
        if (!!this.orgId && !!hasAppsPermissionsResp?.hasPermission) {
          allResources$ = getResources(this.resourcesService, this.orgId, undefined, [ResourceTypeEnum.service_forwarder]);
        }
        return combineLatest([of(hasAppsPermissionsResp), allResources$]);
      }),
      map(([hasAppsPermissionsResp, allResourcesResp]: [OrgQualifiedPermission, Array<Resource>]) => {
        const combinedAppsPermissionsAndResourceGroups: CombinedPermissionsAndResourceGroupData = {
          permission: hasAppsPermissionsResp,
          resources: allResourcesResp,
        };
        return combinedAppsPermissionsAndResourceGroups;
      })
    );
  }

  private getPermissionsAndResourceGroups(): void {
    const combinedAppsPermissionsAndResourceGroups$ = this.getCombinedAppsPermissionsAndResourceGroups$();
    combinedAppsPermissionsAndResourceGroups$.pipe(takeUntil(this.unsubscribe$)).subscribe({
      next: (combinedAppsPermissionsAndResourceGroupsResp) => {
        this.hasAppsPermissions = combinedAppsPermissionsAndResourceGroupsResp?.permission?.hasPermission;
        this.allResources = combinedAppsPermissionsAndResourceGroupsResp?.resources;
        if (!this.hasAppsPermissions || !this.allResources) {
          // Need this in order for the "No Permissions" text to be displayed when the page first loads.
          this.changeDetector.detectChanges();
          return;
        }
        setResourceMapsAndColumnData(
          this.resourceIdToResourceMap,
          this.resourceNameAndTypeToResourceMap,
          this.allResources,
          this.columnDefs,
          this.overrideResourceMemebersAllowedValuesResourceId
        );
        this.changeDetector.detectChanges();
        const resourceGroups = combinedAppsPermissionsAndResourceGroupsResp.resources.filter(
          (resource) => resource.spec.resource_type === ResourceTypeEnum.group
        );
        this.updateTable(resourceGroups);
      },
      error: (e) => this.notificationService.error('Failed to list resource groups'),
    });
  }

  private updateTable(resourceGroups: Array<Resource>): void {
    this.buildData(resourceGroups);
    this.replaceTableWithCopy();
  }

  private buildData(resourceGroups: Array<Resource>): void {
    const data: Array<ResourceGroupElement> = [];
    for (let i = 0; i < resourceGroups.length; i++) {
      const resourceGroup = resourceGroups[i];
      data.push(this.createResourceGroupElement(resourceGroup, i));
    }
    updateTableElements(this.tableData, data);
  }

  private createResourceGroupElement(resource: Resource, index: number): ResourceGroupElement {
    const data: ResourceGroupElement = {
      name: resource.spec.name,
      resource_members: resource.spec.resource_members,
      backingResourceGroup: resource,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  /**
   * Receives a ResourceGroupElement from the table then updates and saves
   * the resource group.
   */
  public updateEvent(updatedResourceGroupElement: ResourceGroupElement): void {
    this.saveResourceGroup(updatedResourceGroupElement);
  }

  private getResourceGroupFromResourceGroupElement(resourceGroupElement: ResourceGroupElement): Resource {
    const result: Resource = resourceGroupElement.backingResourceGroup;
    result.spec.name = resourceGroupElement.name;
    result.spec.resource_members = resourceGroupElement.resource_members;
    return result;
  }

  private updateResourceMaps(resource: Resource): void {
    this.resourceIdToResourceMap.set(resource.metadata.id, resource);
    this.resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
  }

  private updateResourceGroupElement(resourceGroupElement: ResourceGroupElement, resourceGroupResp: Resource): void {
    // need to clone resourceGroupResp for comparison with table changes
    this.updateResourceMaps(cloneDeep(resourceGroupResp));
    for (const key of Object.keys(resourceGroupResp)) {
      resourceGroupElement[key] = resourceGroupResp[key];
    }
    resourceGroupElement.backingResourceGroup = resourceGroupResp;
  }

  private postResource(updatedResourceGroup: Resource, resourceGroupElement: ResourceGroupElement): void {
    createNewResource$(this.resourcesService, updatedResourceGroup)
      .pipe(take(1))
      .subscribe(
        (postResourceResp) => {
          this.notificationService.success(`Resource group "${postResourceResp.spec.name}" was successfully created`);
          this.updateResourceGroupElement(resourceGroupElement, postResourceResp);
          resourceGroupElement.isNew = false;
        },
        (errorResp) => {
          const baseMessage = `Failed to create resource group "${resourceGroupElement.backingResourceGroup.spec.name}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        }
      );
  }

  private putResource(updatedResourceGroup: Resource, resourceGroupElement: ResourceGroupElement): void {
    updateExistingResource$(this.resourcesService, updatedResourceGroup)
      .pipe(take(1))
      .subscribe(
        (putResourceResp) => {
          this.notificationService.success(`Resource group "${putResourceResp.spec.name}" was successfully updated`);
          this.updateResourceGroupElement(resourceGroupElement, putResourceResp);
        },
        (errorResp) => {
          const baseMessage = `Failed to update resource group "${resourceGroupElement.backingResourceGroup.spec.name}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        }
      );
  }

  private saveResourceGroup(resourceGroupElement: ResourceGroupElement): void {
    const updatedResourceGroup = this.getResourceGroupFromResourceGroupElement(resourceGroupElement);
    if (!updatedResourceGroup.metadata?.id) {
      // create new resource group:
      this.postResource(updatedResourceGroup, resourceGroupElement);
    } else {
      this.putResource(updatedResourceGroup, resourceGroupElement);
    }
  }

  private populateDeleteObservablesArray(resourceGroupsToDelete: Array<ResourceGroupElement>): Array<Observable<object>> {
    const observablesArray: Array<Observable<object>> = [];
    for (const resourceGroup of resourceGroupsToDelete) {
      if (resourceGroup.isChecked && resourceGroup.backingResourceGroup?.metadata?.id) {
        observablesArray.push(
          this.resourcesService.deleteResource({
            resource_id: resourceGroup.backingResourceGroup.metadata.id,
            org_id: this.orgId,
          })
        );
      }
    }
    return observablesArray;
  }

  public deleteSelected(resourceGroupsToDelete: Array<ResourceGroupElement>): void {
    const observablesArray = this.populateDeleteObservablesArray(resourceGroupsToDelete);
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Resource groups were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected resource groups');
        },
        () => {
          this.getPermissionsAndResourceGroups();
        }
      );
  }

  public showNoPermissionsText(): boolean {
    return this.hasAppsPermissions !== undefined && !this.hasAppsPermissions;
  }

  public makeEmptyTableElement(): ResourceGroupElement {
    return {
      name: '',
      resource_members: [],
      backingResourceGroup: {
        spec: {
          name: '',
          resource_type: ResourceTypeEnum.group,
          org_id: this.orgId,
          resource_members: [],
        },
      },
      ...getDefaultNewRowProperties(),
    };
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public onUpdateTableData(newTableData: ResourceGroupElement[]): void {
    this.isUploading = true;
    const totalGroupsToUpload = newTableData.length;
    let uploadsComplete = -1;
    const createRequests: Array<Observable<Resource>> = [];
    const updateRequests: Array<Observable<Resource>> = [];

    newTableData.forEach((resourceGroupElement) => {
      const updatedResourceGroup = this.getResourceGroupFromResourceGroupElement(resourceGroupElement);
      uploadsComplete++;
      this.progressBarController = this.progressBarController.updateProgressBarValue(totalGroupsToUpload, uploadsComplete);
      this.changeDetector.detectChanges();

      if (!updatedResourceGroup.metadata?.id) {
        createRequests.push(createNewResource$(this.resourcesService, updatedResourceGroup));
      } else {
        updateRequests.push(updateExistingResource$(this.resourcesService, updatedResourceGroup));
      }
    });

    forkJoin([...createRequests, ...updateRequests])
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.progressBarController = this.progressBarController.updateProgressBarValue(totalGroupsToUpload, totalGroupsToUpload);
          this.delayHideProgressBar();
          this.notificationService.success('Resource groups uploaded successfully.');
          this.getPermissionsAndResourceGroups();
        },
        complete: () => {
          this.isUploading = false;
          this.progressBarController = this.progressBarController.resetProgressBar();
          this.changeDetector.detectChanges();
        },
      });
  }

  public delayHideProgressBar(): void {
    setTimeout(() => {
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }
}

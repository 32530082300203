<h1 mat-dialog-title class="dialog-title">{{ dialogTitle }}</h1>
<div mat-dialog-content>
  <form 
    class="auth-form" 
    [formGroup]="updateAuthForm" 
    spellcheck="false"
    autocomplete="off"
  >  
    <mat-form-field>
      <mat-label>Client ID</mat-label>
      <input
        matInput 
        type="text"
        [value]="data.data.name"
        formControlName="client_id" 
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Application</mat-label>
      <mat-select 
        #select1
        formControlName="application"
        [value]="data.data.application"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
      >
      <mat-option
          *ngFor="let option of getAllowedValues('application')"
          [value]="getOptionValue('application', option)"
        >
          {{ capitalizeFirstLetter(getOptionValue('application', option)) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Secret</mat-label>
      <input 
        matInput 
        type="text" 
        value="data.data.secret"
        formControlName="secret" 
      >
    </mat-form-field>

    <mat-form-field>
      <mat-label>Allowed Organisations</mat-label>
      <mat-select 
        multiple
        #select2
        matTooltip="{{ data.columnDefs.get('organisation_scope').getTooltip(data.data) }}"
        [value]="tableLayout.getSelectionDisplayValue(data.columnDefs.get('organisation_scope'), data.data)"
        formControlName="organisation_scope"
        (selectionChange)="
        updateMultipleSelection({
          value: $event.value,
          element: data.data
        })"
      >
      <mat-option
          *ngFor="let option of getAllowedValues('organisation_scope')"
          [value]="option"
        >
          {{ capitalizeFirstLetter(replaceCharacterWithSpace(option, '_')) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Multi-Factor</mat-label>
      <mat-select 
        #select3
        [value]="data.data.mfa_challenge"
        formControlName="mfa_challenge"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select3)"
      >
      <mat-option
          *ngFor="let option of getAllowedValues('mfa_challenge')"
          value="{{ option }}"
        >
          {{ capitalizeFirstLetter(replaceCharacterWithSpace(option, '_')) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Single Sign-On</mat-label>
      <mat-select
        #select4
        matTooltip="Indicates whether this client will allow users to cache their login credentials. This setting is can be used as a condition in the Authentication Policy"
        [value]="data.data.single_sign_on"
        formControlName="single_sign_on"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select4)"
      >
      <mat-option
          *ngFor="let option of getAllowedValues('single_sign_on')"
          value="{{ option }}"
        >
          {{ capitalizeFirstLetter(replaceCharacterWithSpace(option, '_')) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <portal-custom-chiplist-input
      [chiplistInput]="redirectsChiplistInput"
      [element]="data.data"
      placeholder = 'Redirects'
      [filterChipOptions]="filterChipOptions"
      [isChipRemovable]="filterChipOptions.removable"
      [keyTabManager]="keyTabManager"
      customInputClass="auth-client-dialog-redirects-chiplist-input"
      (removeChip)="removeChip($event, data.data.redirects)"
    >
    </portal-custom-chiplist-input>

    <div class="attribute-table">
      <mat-label>Authentication Attributes</mat-label>
      <portal-table-layout
        class="table-template"
        [tableData]="authAttributes"
        [columnDefs]="attributeColumns"
        [makeEmptyTableElement]="makeEmptyAuthAttributeElement"
        rowObjectName="Attribute"
        [deleteRowsFunc]="removeAttributesFunc"
        (updateEvent)="updateAttributes()"
        (updateAutoInput)="updateAutoInput($event)"
      >
      </portal-table-layout>
    </div>

    <div class="provider-aliases-table">
      <mat-label>Identity Provider Aliases</mat-label>
      <div *ngIf="!data.issuer"><p>Access to Issuer data not available</p></div>
      <portal-table-layout
        *ngIf="!!data.issuer"
        class="table-template"
        [tableData]="aliasTableData"
        [columnDefs]="aliasColumnDefs"
        [makeEmptyTableElement]="makeEmptyUpsteamAliasTableElementFunc"
        rowObjectName="ALIAS"
        (updateSelection)="updateAliasSelection($event)"
        (removeSelected)="deleteSelectedUpstreamAliases()"
        >
      </portal-table-layout>
    </div>

    <div class="saml-container">
      <mat-form-field style="width: 95%;" class="textarea-form-field">
        <mat-label>SAML SP Metadata</mat-label>
        <textarea 
          matInput
          style="height: 100px;"
          [value]="data.data.saml_metadata_file"
          formControlName="saml_metadata_file"
          (blur)="updateMetadata()"
        >
        </textarea>
        <button mat-button *ngIf="data.data.saml_metadata_file" matSuffix mat-icon-button aria-label="Clear" (click)="clearMetadata()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
      <div class="more-actions saml-more-actions" style="display:inline-block; width: 5%">
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="metadataUpload.click()">
            Upload File
          </button>
          <input
            hidden
            type="file"
            #metadataUpload
            name="upload-file"
            id="upload-file"
            (change)="uploadMetadata($event)"
            matTooltip="Upload SAML SP metadata file"
            title=" "
            aria-label="Input that uploads a file"
            portalDisableKeyTab
          >
          <button mat-menu-item (click)="downloadSPMetadata()" [disabled]="disableDownload()">
            <span>Download</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </form>

  <mat-dialog-actions align="right">
    <button mat-raised-button color="primary" [disabled]="saveDisabled" (click)="onSaveClick()">{{ saveButton }}</button>
    <button mat-raised-button (click)="onCancelClick()">CANCEL</button>
  </mat-dialog-actions>
</div>

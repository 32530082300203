import { OrganisationsService } from '@agilicus/angular';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { initiateBillingCheckoutSession$ } from '@app/core/api/organisations/organisations-api.utils';
import { BillingCheckoutSessionFull } from '@app/core/models/billing/billing-checkout-session-full';
import { PaymentService } from '@app/core/payment-service/payment.service';
import { concatMap, Subject, takeUntil } from 'rxjs';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import { NotificationService } from '@app/core';
// "/pure" will delay loading the Stripe.js script until loadStripe is first called
import { loadStripe } from '@stripe/stripe-js/pure';
import { StripeEmbeddedCheckout } from '@stripe/stripe-js';

export interface StripeCheckoutDialogData {
  orgId?: string;
}

@Component({
  selector: 'portal-stripe-checkout-dialog',
  templateUrl: './stripe-checkout-dialog.component.html',
  styleUrls: ['./stripe-checkout-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StripeCheckoutDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public orgId: string;
  private checkout: StripeEmbeddedCheckout;
  private stripePublishableKey =
    'pk_live_51IyHFVJsUDC5loR4ro8vwUulYzpz6j5nEF2b7eNQEytu6oo2rzHcB7pkEEZg2nIBWPwXXzKLwOjWFqbpS4R24zhs00mcRj9EMk';

  private getStripePublishableKey(): string {
    if (!!this.envService.environment?.overrideStripePublishableKey) {
      return this.envService.environment.overrideStripePublishableKey;
    }
    return this.stripePublishableKey;
  }

  public stripePromise = loadStripe(this.getStripePublishableKey());

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: StripeCheckoutDialogData,
    private dialogRef: MatDialogRef<StripeCheckoutDialogComponent>,
    private organisationsService: OrganisationsService,
    private paymentService: PaymentService,
    private envService: DynamicEnvironmentService,
    private notificationService: NotificationService
  ) {
    if (data) {
      this.orgId = data.orgId;
    }
  }

  public ngOnInit(): void {
    this.paymentService
      .getBillingPortalLink$(this.orgId, true)
      .pipe(
        concatMap((getBillingPortalLinkResp) => {
          return initiateBillingCheckoutSession$(this.organisationsService, this.orgId, getBillingPortalLinkResp.return_uri);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(async (billingCheckoutSessionResp: BillingCheckoutSessionFull) => {
        // Initialize Checkout
        const stripe = await this.stripePromise;
        this.checkout = await stripe
          .initEmbeddedCheckout({
            clientSecret: billingCheckoutSessionResp.client_secret,
          })
          .catch((err) => {
            this.notificationService.error('Failed to create checkout session. Please try again.');
            throw err;
          });

        // Mount Checkout
        this.checkout.mount('#stripe-payment-checkout');
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (!!this.checkout) {
      this.checkout.destroy();
    }
  }
}

<form
  [formGroup]="genericHeaderForm"
  spellcheck="false"
  autocomplete="off"
>
  <div class="checkbox-container">
    <mat-checkbox
      formControlName="enabled"
      (change)="onCheckboxChange()"
      aria-label="Checkbox that toggles whether the security header option is enabled"
    >
      Enable
    </mat-checkbox>
    <mat-icon 
      class="help-icon" 
      matTooltip="Whether to apply this setting. If true, the settings defined here will override any set by the application. If false, no settings will be applied. Settings applied by the application itself will take effect."
    >
      help_outline
    </mat-icon>
  </div>

  <div class="mode-container">
    <div class="mode-radio-buttons-container" *ngIf="!!showModeRadioButtons()">
      <div class="radio-button-label-container">
        <label
          id="mode-radio-group-label"
          class="radio-button-group-label"
        >
          Mode:
        </label>
        <mat-icon 
          class="help-icon" 
          matTooltip="Controls the value of the header"
        >
          help_outline
        </mat-icon>
      </div>
      <mat-radio-group
        formControlName="mode"
        aria-label="mode selection radio buttons"
        class="mode-radio-group"
      >
        <div
          *ngFor="let value of genericSecurityHeaderSettings?.modeOptions"
          class="shared-line-radio-button"
        >
          <mat-radio-button
            class="mode-radio-button"
            [value]="value.modeName"
            (change)="onModeOptionChange(value.modeName)"
          >
            {{ getFormatedMode(value.modeName) }}
          </mat-radio-button>
          <mat-icon 
            class="help-icon" 
            [matTooltip]="value.modeDescription"
          >
            help_outline
          </mat-icon>
        </div>
      </mat-radio-group>
    </div>

    <div class="mode-dropdown-container" *ngIf="!showModeRadioButtons()">
      <mat-form-field class="extended-field">
        <mat-label>Mode</mat-label>
        <mat-select
          #modeSelect
          formControlName="mode"
          (selectionChange)="onModeSelectionChange()"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, modeSelect)"
        >
          <mat-option 
            *ngFor="let value of genericSecurityHeaderSettings?.modeOptions"
            [value]="value.modeName"
            [matTooltip]="value.modeDescription"
          >
            {{ getFormatedMode(value.modeName) }}
          </mat-option>
        </mat-select>
        <mat-error>Mode is required</mat-error>
      </mat-form-field>
      <mat-icon 
        class="help-icon custom-help-icon" 
        matTooltip="Controls the value of the header"
      >
        help_outline
      </mat-icon>
    </div>
  </div>

  <mat-form-field class="extended-field">
    <mat-label>Override</mat-label>
    <input
      matInput
      type="text"
      formControlName="override"
      (blur)="onFormBlur('override')"
      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
    />
  </mat-form-field>
  <mat-icon 
    class="help-icon custom-help-icon" 
    [matTooltip]="getOverrideInputTooltipText()"
  >
    help_outline
  </mat-icon>
</form>
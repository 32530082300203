<div class="table-controls">
    <portal-upload-button
      [isUploading]="isUploading"
      buttonDescription="RESOURCE GROUPS"
      tooltipText="Upload a CSV file with columns: name, resource_members"
      (readFile)="onUploadResourceGroups($event)"
    ></portal-upload-button>
  
    <portal-download-button
      buttonDescription="RESOURCE GROUPS"
      tooltipText="Download current resource groups as CSV"
      (downloadFile)="downloadResourceGroups()"
    ></portal-download-button>

    <div class="flex-div"></div>
</div>

<portal-progress-bar 
  [progressBarController]="progressBarController"
>
</portal-progress-bar>

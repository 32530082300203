<portal-page-info
  pageTitle="New Desktop"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentDesktopModel"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #desktopStepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [completed]="isStartStepValid()">
      <ng-template matStepLabel>Start</ng-template>

      <portal-start-step
        [stepperType]="stepperType"
        [startOptionFormGroup]="startOptionFormGroup"
        [stepperState]="desktopStepperState"
        [hasTemplates]="hasTemplates"
        [stepper]="stepper"
        [isInProgress]="isInProgress()"
        (updateModel)="updateDesktopModel(true)"
        (resetModel)="resetDesktopModelAndStatus()"
      >
      </portal-start-step>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="desktopNameFormGroup">
      <ng-template matStepLabel>Desktop name</ng-template>
      <form
        [formGroup]="desktopNameFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the desktop name"
          label="My remote desktop will be called (a valid hostname)..."
          [formCtrl]="desktopNameFormGroup.get('name')"
          required="true"
          (formBlur)="onFormBlur(desktopNameFormGroup, 'name', getModel())"
          tooltip="This name uniquely identifies the desktop within this organisation."
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="addressFormGroup">
      <ng-template matStepLabel>Address</ng-template>
      <form
        [formGroup]="addressFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the address"
          label="The address of the desktop in my remote network is..."
          [formCtrl]="addressFormGroup.get('address')"
          required="true"
          (formBlur)="onFormBlur(addressFormGroup, 'address', getModel())"
          tooltip="The hostname or IP of the desktop. A Desktop Gateway will proxy requests from the client through to this address via the Connector associated with this gateway."
        >
        </portal-stepper-form-field>

        <portal-stepper-form-field
          placeholder="Enter the port"
          label="The port of the Desktop in my remote network is..."
          [formCtrl]="addressFormGroup.get('port')"
          required="true"
          (formBlur)="onFormBlur(addressFormGroup, 'port', getModel())"
          tooltip="The port of the Desktop. A Desktop Gateway will proxy requests from the client through to this port via the Connector associated with this gateway."
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step *ngIf="isRdpDesktop()" [stepControl]="remoteDesktopFormGroup">
      <ng-template matStepLabel>Remote desktop configuration</ng-template>
      <form
        [formGroup]="remoteDesktopFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <label
          id="allow-joint-users-radio-group-label"
          class="radio-button-group-label"
        >
          {{ allowNonDomainJoinedUsersText }}:
        </label>
        <mat-radio-group
          aria-labelledby="allow non domain joined desktop selection radio buttons"
          class="allow-joint-users-radio-group"
          formControlName="allow_non_domain_joined_users"
        >
          <mat-radio-button
            class="allow-joint-users-radio-button"
            *ngFor="let option of allowNonDomainJoinedUsersOptions"
            [value]="option.value"
            (change)="onAllowNonDomainJoinedUsersOptionChange(option.value)"
          >
            {{ option.displayValue }}
          </mat-radio-button>
        </mat-radio-group>

        <label
          id="remote-app-radio-group-label"
          class="radio-button-group-label"
        >
          My remote desktop is restricted to a single application:
        </label>
        <mat-radio-group
          aria-labelledby="remote application selection radio buttons"
          class="remote-app-radio-group"
          formControlName="isRemoteApp"
        >
          <mat-radio-button
            class="remote-app-radio-button"
            *ngFor="let option of isRemoteAppOptions"
            [value]="option.value"
            (change)="onRemoteAppOptionChange(option.value)"
          >
            {{ option.displayValue }}
          </mat-radio-button>
        </mat-radio-group>

        <div *ngIf="!!remoteDesktopFormGroup.get('isRemoteApp').value">
          <portal-stepper-form-field
            placeholder="Enter the command path"
            label="The command path to the remote application is..."
            [formCtrl]="remoteDesktopFormGroup.get('command_path')"
            (formBlur)="onFormBlur(remoteDesktopFormGroup, 'command_path', getModel())"
            [tooltip]="commandPathTooltipText"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            placeholder="Enter the command arguments"
            label="The command arguments are..."
            [formCtrl]="remoteDesktopFormGroup.get('command_arguments')"
            (formBlur)="onFormBlur(remoteDesktopFormGroup, 'command_arguments', getModel())"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            placeholder="Enter the working directory"
            label="The directory to start the application in is..."
            [formCtrl]="remoteDesktopFormGroup.get('working_directory')"
            (formBlur)="onFormBlur(remoteDesktopFormGroup, 'working_directory', getModel())"
          >
          </portal-stepper-form-field>
        </div>

        <label
          id="extra-config-radio-group-label"
          class="radio-button-group-label"
        >
          {{ getExtraConfigLabelText() }}
        </label>
        <mat-radio-group
          aria-labelledby="additional configuration overrides selection radio buttons"
          class="extra-config-radio-group"
          formControlName="hasExtraConfig"
        >
          <mat-radio-button
            class="extra-config-radio-button"
            *ngFor="let option of hasExtraConfigOptions"
            [value]="option.value"
          >
            {{ option.displayValue }}
          </mat-radio-button>
        </mat-radio-group>

        <div *ngIf="!!getHasExtraConfigFormValue()">
          <portal-desktop-extra-config
            #desktopExtraConfigComponent
            [desktopCopy]="emptyDesktopResource"
          >
          </portal-desktop-extra-config>
        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step *ngIf="isVncDesktop()" [stepControl]="serverConfigurationFormGroup">
      <ng-template matStepLabel>Server Configuration</ng-template>
      <form
        [formGroup]="serverConfigurationFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field>
          <mat-label>Enter the read write password</mat-label>
          <input matInput showHideInput
            id="readWritePassword" 
            type="password"
            placeholder="The password which enables a vnc connection that allows the user to edit data is..."
            [formControl]="serverConfigurationFormGroup.get('read_write_password')"
            (blur)="onFormBlur(serverConfigurationFormGroup, 'read_write_password', getModel())"
          >
          <i matSuffix
            class="material-icons"
            showHideTrigger="readWritePassword"
            [showHideStatus]="{ materialIcon: true, id: 'readWritePassword' }"
          ></i>
        </mat-form-field>

        <mat-form-field>
          <mat-label>Enter the read only password</mat-label>
          <input matInput showHideInput
            id="readOnlyPassword" 
            type="password"
            placeholder="The password which enables a vnc connection with no user control is..."
            [formControl]="serverConfigurationFormGroup.get('read_only_password')"
            (blur)="onFormBlur(serverConfigurationFormGroup, 'read_only_password', getModel())"
          >
          <i matSuffix
            class="material-icons"
            showHideTrigger="readOnlyPassword"
            [showHideStatus]="{ materialIcon: true, id: 'readOnlyPassword' }"
          ></i>
        </mat-form-field>

        <div class="vnc-unsername-container">
          <label
            id="vnc-username-radio-group-label"
            class="radio-button-group-label"
          >
            My VNC server expects a username:
          </label>
          <mat-radio-group
            aria-labelledby="vnc desktop username selection radio buttons"
            class="vnc-username-radio-group"
            formControlName="vnc_username"
          >
            <mat-radio-button
              class="vnc-username-radio-button"
              *ngFor="let option of vncUsernameOptions"
              [value]="option.value"
              (change)="onVncUsernameOptionChange(option.value)"
            >
              {{ option.displayValue }}
            </mat-radio-button>
          </mat-radio-group> 

          <div *ngIf="!!serverConfigurationFormGroup.get('vnc_username').value">
            <portal-stepper-form-field
              placeholder="The username which enables a vnc connection that allows the user to edit data is..."
              label="Enter the read write username"
              [formCtrl]="serverConfigurationFormGroup.get('read_write_username')"
              (formBlur)="onFormBlur(serverConfigurationFormGroup, 'read_write_username', getModel())"
            >
            </portal-stepper-form-field>

            <portal-stepper-form-field
              placeholder="The username which enables a vnc connection with no user control is..."
              label="Enter the read only username"
              [formCtrl]="serverConfigurationFormGroup.get('read_only_username')"
              (formBlur)="onFormBlur(serverConfigurationFormGroup, 'read_only_username', getModel())"
            >
            </portal-stepper-form-field>
          </div>
        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedDesktopText }}</p>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Connector: {{ getModel().connector_name }}</p>
        <p>Desktop name: {{ getModel().name }}</p>
        <p>Desktop type: {{ getModel().desktop_type }}</p>
        <p>Address: {{ getModel().address }}</p>
        <p>Port: {{ getModel().config.ports[0].port }}</p>
        <div *ngIf="isRdpDesktop()">
          <p *ngIf="!!getModel().allow_non_domain_joined_users">{{ allowNonDomainJoinedUsersText }}</p>
          <p *ngIf="!!getModel().remote_app?.command_path">Command path: {{ getModel().remote_app.command_path }}</p>
          <p *ngIf="!!getModel().remote_app?.command_arguments">Command arguments: {{ getModel().remote_app.command_arguments }}</p>
          <p *ngIf="!!getModel().remote_app?.working_directory">Working directory: {{ getModel().remote_app.working_directory }}</p>
          <span *ngIf="getHasExtraConfigFormValue()">
            <p>{{ getExtraConfigLabelText() }}</p>
              <ul>
                <li *ngFor="let config of emptyDesktopResource.spec.extra_configs">{{ config }}</li>
              </ul>
          </span>
        </div>
        <div *ngIf="isVncDesktop()">
          <p *ngIf="!!getModel().connection_info?.vnc_connection_info?.password_authentication_info?.read_write_username">Read Write Username: {{ getModel().connection_info.vnc_connection_info.password_authentication_info.read_write_username }}</p>
          <p *ngIf="!!getModel().connection_info?.vnc_connection_info?.password_authentication_info?.read_only_username">Read Only Username: {{ getModel().connection_info.vnc_connection_info.password_authentication_info.read_only_username }}</p>
        </div>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentDesktopModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="desktopModelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitDesktopModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <portal-stepper-done-config-options
        *ngIf="!!getModel().id"
        [resourceId]="getModel().id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
      >
      </portal-stepper-done-config-options>

      <div *ngIf="!!getModel()?.remote_app?.command_path" class="rdp-config-file-download-container">
        <p>Remote applications require setup on the server hosting them. Click the button below to download a pre-built registry file to run on the remote Windows server:</p>
        <portal-download-button
          class="download-button-container"
          buttonDescription="CONFIG FILE"
          tooltipText="Click to download a pre-built registry file to run on the remote Windows server"
          (downloadFile)="downloadFile()"
          [disableButton]= "!getModel()?.remote_app?.command_path"
        >
        </portal-download-button>
      </div>
    </mat-step>

  </mat-vertical-stepper>
</div>

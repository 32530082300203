import {
  AgentConnector,
  Application,
  ApplicationService,
  Connector,
  EnvironmentConfigVarList,
  FileShareService,
  Issuer,
  DesktopResource,
  Launcher,
  SSHResource,
  InboxItem,
  PolicyTemplateInstance,
  APIKey,
} from '@agilicus/angular';
import { BillingAccountFull } from '@app/core/models/billing/billing-account-full';
import { CrudState } from './state-driven-crud';

export enum CrudStateCollection {
  applications = 'application',
  issuer = 'issuer',
  environment_config_var = 'environment_variables_list',
  application_services = 'network',
  connector = 'connector',
  agent_connector = 'agent_connector',
  file_share_service = 'share',
  desktop = 'desktop',
  launcher = 'launcher',
  ssh = 'ssh',
  billing = 'billing',
  inbox_item = 'inbox_item',
  policy_template_instance = 'policy_template_instance',
  api_key = 'api_key',
}

export interface CrudManagementState {
  [CrudStateCollection.applications]: CrudState<Application>;
  [CrudStateCollection.issuer]: CrudState<Issuer>;
  [CrudStateCollection.environment_config_var]: CrudState<EnvironmentConfigVarList>;
  [CrudStateCollection.application_services]: CrudState<ApplicationService>;
  [CrudStateCollection.connector]: CrudState<Connector>;
  [CrudStateCollection.agent_connector]: CrudState<AgentConnector>;
  [CrudStateCollection.file_share_service]: CrudState<FileShareService>;
  [CrudStateCollection.desktop]: CrudState<DesktopResource>;
  [CrudStateCollection.launcher]: CrudState<Launcher>;
  [CrudStateCollection.ssh]: CrudState<SSHResource>;
  [CrudStateCollection.billing]: CrudState<BillingAccountFull>;
  [CrudStateCollection.inbox_item]: CrudState<InboxItem>;
  [CrudStateCollection.policy_template_instance]: CrudState<PolicyTemplateInstance>;
  [CrudStateCollection.api_key]: CrudState<APIKey>;
}

<div class="profile">
  <H1>User Profile</H1>

  Your profile is owned by the upstream identity provider, and as such is read-only. Our system augments it with an ID (in
  base57) which is used as the unique-key throughout.

  <hr>
  <form *ngIf="user$ | async as user" class="user-form">
    <mat-form-field class="user-form-full-width">
      <mat-label>First Name</mat-label>
      <input matInput [readonly]="true" value="{{ user.first_name }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>Last Name</mat-label>
      <input matInput [readonly]="true" value="{{ user.last_name }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>ID Provider</mat-label>
      <input matInput [readonly]="true" value="{{ user.provider }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>Email</mat-label>
      <input matInput [readonly]="true" value="{{ user.email }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>ID</mat-label>
      <input matInput [readonly]="true" value="{{ user.id }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>Date Created</mat-label>
      <input matInput [readonly]="true" value="{{ user.created }}">
    </mat-form-field>

    <mat-form-field class="user-form-full-width">
      <mat-label>Date Updated</mat-label>
      <input matInput [readonly]="true" value="{{ user.updated }}">
    </mat-form-field>

  </form>
</div>
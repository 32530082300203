import { UpstreamGroupMapping } from '@agilicus/angular';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface GroupMappingsDialogData {
  upstreamName: string;
  upstreamGroupMapping: UpstreamGroupMapping;
  orgId: string;
  onUpdate: (upstreamProvider: string, updatedUpstreamGroupMapping: UpstreamGroupMapping) => void;
}

@Component({
  selector: 'portal-upstream-group-mappings-dialog',
  templateUrl: './upstream-group-mappings-dialog.component.html',
  styleUrls: ['./upstream-group-mappings-dialog.component.scss'],
})
export class UpstreamGroupMappingsDialogComponent {
  public confirmButtonText = 'Close';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: GroupMappingsDialogData,
    private dialogRef: MatDialogRef<UpstreamGroupMappingsDialogComponent>
  ) {}

  public onConfirmClick(): void {
    this.dialogRef.close();
  }

  public updateIssuerGroupMapping(updatedUpstreamGroupMapping: UpstreamGroupMapping): void {
    this.data.upstreamGroupMapping = updatedUpstreamGroupMapping;
    this.data.onUpdate(this.data.upstreamName, this.data.upstreamGroupMapping);
  }
}

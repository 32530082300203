import { FeatureFlag, Organisation, OrganisationStateSelector } from '@agilicus/angular';

export function markOrgAsDeleted(organisation: Organisation): void {
  organisation.admin_state = OrganisationStateSelector.disabled;
  const orgLifecycleDeleted: FeatureFlag = {
    feature: 'org_lifecycle',
    enabled: true,
    setting: 'deleted',
  };
  setFeature(organisation, orgLifecycleDeleted);
}

export function setFeature(organisation: Organisation, featureFlag: FeatureFlag): void {
  if (!organisation.feature_flags) {
    organisation.feature_flags = [];
  }
  for (const flag of organisation.feature_flags) {
    if (flag.feature === featureFlag.feature) {
      flag.enabled = featureFlag.enabled;
      flag.setting = featureFlag.setting;
      return;
    }
  }
  organisation.feature_flags.push(featureFlag);
}

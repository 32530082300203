<portal-page-info
  pageTitle="New Launcher"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentModel"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #launcherStepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [stepControl]="nameFormGroup">
      <ng-template matStepLabel>Launcher name</ng-template>
      <form
        [formGroup]="nameFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the launcher name"
          label="My launcher will be called (a valid hostname)..."
          [formCtrl]="nameFormGroup.get('name')"
          required="true"
          [tooltip]="getLauncherNameTooltipText()"
          (formBlur)="onFormBlur(nameFormGroup, 'name', getModel())"
        >
        </portal-stepper-form-field>

        <portal-stepper-form-field
          placeholder="Enter the command path"
          [formCtrl]="nameFormGroup.get('command_path')"
          required="true"
          (formBlur)="onFormBlur(nameFormGroup, 'command_path', getModel())"
          [tooltip]="getLauncherCommandPathTooltipText()"
          validateAsPath="true"
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="resourceMembersFormGroup">
      <ng-template matStepLabel>Resource members (optional)</ng-template>
      <form
        [formGroup]="resourceMembersFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-custom-chiplist-input
          class="chiplist-form-field"
          [chiplistInput]="resourceMembersChiplistInput"
          [element]="currentModel"
          placeholder="Select resource members"
          [filterChipOptions]="filterChipOptions"
          [isChipRemovable]="true"
          [keyTabManager]="keyTabManager"
          customInputClass="launcher-stepper-resource-members-chiplist-input"
          (removeChip)="removeResourceChip($event)"
        >
        </portal-custom-chiplist-input>
        <mat-icon 
          class="help-icon custom-help-icon" 
          [matTooltip]="getLauncherResourceMembersTooltipText()"
        >
          help_outline
        </mat-icon>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="optionalConfigFormGroup">
      <ng-template matStepLabel>Advanced configuration (optional)</ng-template>
      <form
        [formGroup]="optionalConfigFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <label
          class="radio-button-group-label"
        >
          My launcher has additional options:
        </label>
        <mat-radio-group
          formControlName="optional_config"
          aria-labelledby="optional config radio buttons"
          class="optional-config-radio-group"
        >
          <mat-radio-button
            *ngFor="let option of optionalConfigOptions"
            class="optional-config-radio-button"
            [value]="option.value"
            (change)="onOptionalConfigChange(option.value)"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="!!optionalConfigFormGroup.get('optional_config').value"
          class="optional-config-container"
        >
          <portal-stepper-form-field
            placeholder="Enter the command arguments"
            [formCtrl]="optionalConfigFormGroup.get('command_arguments')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'command_arguments', getModel())"
            [tooltip]="getLauncherCommandArgumentsTooltipText()"
          >
          </portal-stepper-form-field>

          <portal-stepper-form-field
            placeholder="Enter the start directory"
            [formCtrl]="optionalConfigFormGroup.get('start_in')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'start_in', getModel())"
            [tooltip]="getLauncherStartInTooltipText()"
          >
          </portal-stepper-form-field>

          <portal-custom-chiplist-input
            class="chiplist-form-field"
            [chiplistInput]="applicationsChiplistInput"
            [element]="currentModel"
            placeholder="Select associated applications"
            [filterChipOptions]="filterChipOptions"
            [isChipRemovable]="true"
            [keyTabManager]="keyTabManager"
            customInputClass="launcher-stepper-associated-applications-chiplist-input"
            (removeChip)="removeApplicationChip($event)"
          >
          </portal-custom-chiplist-input>
          <mat-icon 
            class="help-icon extra-icon-margin custom-help-icon" 
            [matTooltip]="getLauncherApplicationsTooltipText()"
          >
            help_outline
          </mat-icon>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="do_intercept"
              (change)="onCheckboxChange('do_intercept', $event.checked)"
              aria-label="Checkbox that toggles whether the launcher command requires the use of an interceptor"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              {{ getLauncherDoInterceptDescriptiveText() }}
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="hide_console"
              (change)="onCheckboxChange('hide_console', $event.checked)"
              aria-label="Checkbox that toggles whether the initial command launcher will be hidden"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              {{ getLauncherHideConsoleDescriptiveText() }}
            </mat-checkbox>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="run_as_admin"
              (change)="onCheckboxChange('run_as_admin', $event.checked)"
              aria-label="Checkbox that toggles whether the launcher will run elevated as administrator"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              {{ getLauncherRunAsAdminDescriptiveText() }}
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getLauncherRunAsAdminTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="fork_then_attach"
              (change)="onCheckboxChange('fork_then_attach', $event.checked)"
              aria-label="Checkbox that toggles whether the launcher requires custom, delayed initialisation"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              {{ getLauncherForkThenAttachDescriptiveText() }}
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getForkThenAttachTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <div class="checkbox-container">
            <mat-checkbox
              formControlName="end_existing_if_running"
              (change)="onCheckboxChange('end_existing_if_running', $event.checked)"
              aria-label="Checkbox that toggles whether the launcher will end any existing running programs"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            >
              {{ getLauncherEndExistingIfRunningDescriptiveText() }}
            </mat-checkbox>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getLauncherEndExistingIfRunningTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>
        </div>

        <label
          class="radio-button-group-label"
        >
          My application requires multiple processes:
        </label>
        <mat-radio-group
          formControlName="extra_processes"
          aria-labelledby="extra processes radio buttons"
          class="optional-config-radio-group"
        >
          <mat-radio-button
            *ngFor="let option of optionalConfigOptions"
            class="optional-config-radio-button"
            [value]="option.value"
            (change)="onExtraProcessChange(option.value)"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="!!getExtraProcessesFormValue()"
          class="extra-processes-container"
        >
          <div class="processes-form-table-container" formArrayName="processes_list">
            <table class="processes-table-container">
              <div *ngFor="let process of getProcessesListFormArray().controls; let i=index" [formGroupName]="i">
                <tr>
                  <td>
                    <h4 class="process-number">{{ i + 1 }}</h4>
                  </td>
                  <td>
                    <mat-form-field>
                      <mat-label>Enter program name</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="program_name"
                        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                        portalPreventStepperAdvance
                      />
                    </mat-form-field>
                    <mat-icon 
                      class="help-icon custom-help-icon"
                      [matTooltip]="getLauncherExtraProcessProgramNameTooltipText()"
                    >
                      help_outline
                    </mat-icon>

                    <mat-form-field>
                      <mat-label>Enter the command arguments</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="command_arguments"
                        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                        portalPreventStepperAdvance
                      />
                    </mat-form-field>
                    <mat-icon 
                      class="help-icon custom-help-icon"
                      [matTooltip]="getLauncherExtraProcessCommandArgumentsTooltipText()"
                    >
                      help_outline
                    </mat-icon>

                    <mat-form-field>
                      <mat-label>Enter the start directory</mat-label>
                      <input
                        matInput
                        type="text"
                        formControlName="start_in"
                        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                        portalPreventStepperAdvance
                      />
                    </mat-form-field>
                    <mat-icon 
                      class="help-icon custom-help-icon"
                      [matTooltip]="getLauncherExtraProcessStartInTooltipText()"
                    >
                      help_outline
                    </mat-icon>
                  </td>

                  <td>
                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="name_regex_flag"
                        aria-label="Checkbox that toggles whether the name regex flag option is enabled"
                      >
                        Use program name as a regex for finding this process
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessNameRegexFlagTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="start_if_not_running"
                        aria-label="Checkbox that toggles whether the start if not running option is enabled"
                      >
                        Start this process if no process is running
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessStartIfNotRunningTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="exit_when_ending"
                        aria-label="Checkbox that toggles whether the exit when eending option is enabled"
                      >
                        End this process when launcher exits
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessExitWhenEndingTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="attach_if_already_running"
                        aria-label="Checkbox that toggles whether the attach if already running option is enabled"
                      >
                        Attach to this process if it is already running
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessAttachIfAlreadyRunningTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="fork_then_attach"
                        aria-label="Checkbox that toggles whether the fork then attach option is enabled"
                      >
                        This process requires custom, delayed initialisation
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessForkThenAttachTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>

                    <div class="checkbox-container">
                      <mat-checkbox
                        formControlName="wait_for_exit"
                        aria-label="Checkbox that toggles whether the wait for exit option is enabled"
                      >
                      Wait on this proccess as well as the main process when running the interceptor
                      </mat-checkbox>
                      <mat-icon 
                        class="help-icon"
                        [matTooltip]="getLauncherExtraProcessWaitForExitTooltipText()"
                      >
                        help_outline
                      </mat-icon>
                    </div>
                  </td>
    
                  <td>
                    <div class="delete-process-button">
                      <button
                        mat-raised-button
                        color="warn"
                        (click)="removeProcess(i)"
                        (keydown.enter)="preventDeleteOnEnter($event)"
                      >
                        DELETE
                      </button>
                    </div>
                  </td>
                </tr>
              </div>
            </table>
          </div>
          <div class="add-process-button-container">
            <span class="buttonAsText" (click)="addProcess()">+ Add process</span>
          </div>
        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedStepperText }}</p>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Launcher name: {{ getModel().name }}</p>
        <p>Command path: {{ getModel().config.command_path }}</p>
        <p *ngIf="!!getModel().resource_members && getModel().resource_members.length !== 0">Resource members: {{ getResourceMemberNamesListString() }}</p>
        <p *ngIf="!!getModel().config?.command_arguments">Command arguments: {{ getModel().config.command_arguments }}</p>
        <p *ngIf="!!getModel().config?.start_in">Start directory: {{ getModel().config.start_in }}</p>
        <p *ngIf="!!getModel().applications && getModel().applications.length !== 0">Associated applications: {{ getApplicationNamesListString() }}</p>
        <p *ngIf="!!getModel().config?.do_intercept">{{ getLauncherDoInterceptDescriptiveText() }}</p>
        <p *ngIf="!!getModel().config?.hide_console">{{ getLauncherHideConsoleDescriptiveText() }}</p>
        <p *ngIf="!!getModel().config?.run_as_admin">{{ getLauncherRunAsAdminDescriptiveText() }}</p>
        <p *ngIf="!!getModel().config?.interceptor_config?.fork_then_attach">{{ getLauncherForkThenAttachDescriptiveText() }}</p>
        <p *ngIf="!!getModel().config?.end_existing_if_running">{{ getLauncherEndExistingIfRunningDescriptiveText() }}</p>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="modelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <portal-stepper-done-config-options
        *ngIf="!!getModel().id"
        [resourceId]="getModel().id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
        [showPolicies]="showPolicies"
      >
      </portal-stepper-done-config-options>
    </mat-step>

  </mat-vertical-stepper>
</div>
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { catchError, combineLatest, concatMap, delay, map, Observable, of, Subject, take, takeUntil, throwError } from 'rxjs';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { createDialogData } from '../dialog-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MatDialog } from '@angular/material/dialog';
import { Step, StepperProgressBarController } from '@agilicus/stepper-progress-bar';
import { getDefaultInitialModelStatus } from '@app/core/api-applications/api-applications.reducer';
import { StepperType } from '../stepper-type.enum';
import { Organisation, OrganisationsService, User } from '@agilicus/angular';
import { select, Store } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { AuthService } from '@app/core/services/auth-service.service';
import { ActionOrganisationsLoad } from '@app/core/organisations/organisations.actions';
import { ActionUserRefreshMemberOrgs } from '@app/core/user/user.actions';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { MatStepper } from '@angular/material/stepper';
import { delayStepperAdvanceOnSuccessfulApply } from '../application-template-utils';
import { Router } from '@angular/router';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';
import {
  failFirstProgressBarStep,
  failSecondProgressBarStep,
  getCnameDestination,
  getProductGuideSignupDeepLink,
  getProgressBarEstimateText,
  getSplitHorizonMessage,
  getSplitHorizonMessagePrefix,
  getSplitHorizonProductGuideDeepLink,
  getSubdomainHintLabel,
  getSubdomainProductGuidePostText,
  passAllProgressBarSteps,
  passProgressBarStep,
  resetProgressBarSteps,
  signupLog,
} from '@app/core/signup/signup-utils';
import { pollForCompletion$ } from '@app/core/api/organisations/organisations-api.utils';
import { getOrganisationProductGuideLink, getSubOrgPageDescriptiveText } from '@app/core/api/organisations/organisation-utils';
import { getFormattedSubdomainValue } from '../validation-utils';
import { FeatureFlagService } from '@app/core/feature-flag/feature-flag.service';

export interface resolveCnameData {
  status: boolean;
  statusMessage: string;
  response: any;
}

export interface CreateOrgResponseData {
  status: CreateOrgStatusEnum;
  errorMessage?: string;
}

export enum CreateOrgStatusEnum {
  success = 'success',
  fail = 'fail',
}

export enum SubOrgType {
  new = 'new',
  admin = 'admin',
}

@Component({
  selector: 'portal-sub-org-stepper',
  templateUrl: './sub-org-stepper.component.html',
  styleUrls: ['./sub-org-stepper.component.scss', '../../shared.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubOrgStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private currentUser: User;
  private currentOrg: Organisation;
  private orgId: string;
  public hasUsersPermissions: boolean;
  public modelStatus = getDefaultInitialModelStatus();
  public stepperType = StepperType.organisation;
  private subOrgCreated = false;
  public isValidSubdomain = false;
  public subOrgTypeOptions: Array<{ value: SubOrgType; displayValue: string }> = [
    { value: SubOrgType.new, displayValue: 'is a new customer' },
    { value: SubOrgType.admin, displayValue: 'is an administrative grouping for my existing organisation' },
  ];
  public orgCanCreateNewCustomerSubOrg = false;
  public orgCanCreateNewIssuer = false;

  public allForms: UntypedFormGroup;
  public orgNameForm: UntypedFormGroup;
  public subOrgTypeFormGroup: UntypedFormGroup;
  public subdomainOwnForm: UntypedFormGroup;

  public pageDescriptiveText = getSubOrgPageDescriptiveText();
  public productGuideLink = getOrganisationProductGuideLink();
  public subdomainHintLabel = getSubdomainHintLabel();
  public cnameDestination = getCnameDestination();
  public productGuideSignupDeepLink = getProductGuideSignupDeepLink();
  public productGuidePostText = getSubdomainProductGuidePostText(this.cnameDestination);
  private splitHorizonMessagePrefix = getSplitHorizonMessagePrefix();
  private splitHorizonProductGuideDeepLink = getSplitHorizonProductGuideDeepLink();
  private splitHorizonMessage = getSplitHorizonMessage(this.splitHorizonProductGuideDeepLink);

  public progressStepper: StepperProgressBarController = new StepperProgressBarController();
  public isVertical = false;
  public steps: Array<Step> = new Array<Step>();
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  // This is required in order to reference the enums in the html template.
  public subOrgType = SubOrgType;

  public getProgressBarEstimateText = getProgressBarEstimateText;

  @ViewChild('subOrgStepper') public stepper: MatStepper;
  @ViewChild('domainNameInput') domainNameInput: ElementRef;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private customValidatorsService: CustomValidatorsService,
    public errorDialog: MatDialog,
    private changeDetector: ChangeDetectorRef,
    private organisationsService: OrganisationsService,
    private authService: AuthService,
    private notificationService: NotificationService,
    public router: Router,
    public http: HttpClient,
    private featureFlagService: FeatureFlagService
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroups();
    this.setStepperProgressBarSteps();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllData$() {
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    return hasUsersPermissions$.pipe(
      concatMap((hasUsersPermissionsResp) => {
        const orgId = hasUsersPermissionsResp.orgId;
        if (!orgId) {
          return combineLatest([of(hasUsersPermissionsResp), of(undefined), of(undefined), of(undefined), of(undefined)]);
        }
        const currentUser$ = this.authService.auth().user$();
        const currentOrg$ = this.organisationsService.getOrg({ org_id: orgId });
        const canOrgCreateNewCustomerSubOrg$ = this.featureFlagService.canOrgCreateNewCustomerSubOrg$();
        const canOrgCreateNewIssuer$ = this.featureFlagService.canOrgCreateNewIssuer$();
        return combineLatest([
          of(hasUsersPermissionsResp),
          currentUser$,
          currentOrg$,
          canOrgCreateNewCustomerSubOrg$,
          canOrgCreateNewIssuer$,
        ]);
      })
    );
  }

  private getAndSetAllData(): void {
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([hasUsersPermissionsResp, currentUserResp, currentOrgResp, canOrgCreateNewCustomerSubOrgResp, canOrgCreateNewIssuerResp]) => {
          this.currentUser = currentUserResp;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.orgId = hasUsersPermissionsResp.orgId;
          this.currentOrg = currentOrgResp;
          this.orgCanCreateNewCustomerSubOrg = canOrgCreateNewCustomerSubOrgResp;
          this.orgCanCreateNewIssuer = canOrgCreateNewIssuerResp;
          if (!!this.orgCanCreateNewCustomerSubOrg) {
            this.subOrgTypeFormGroup.get('orgType').setValue('');
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  private initializeFormGroups(): void {
    this.initializeOrgNameFormGroup();
    this.initializeSubOrgTypeFormGroup();
    this.initializeSubdomainOwnFormGroup();
    this.allForms = this.formBuilder.group({
      orgNameForm: this.orgNameForm,
      subOrgTypeFormGroup: this.subOrgTypeFormGroup,
      subdomainOwnForm: this.subdomainOwnForm,
    });
  }

  private getDefaultNewCustomerOrgNameValidators(): ((control: AbstractControl<any, any>) => ValidationErrors)[] {
    return [Validators.required, Validators.maxLength(100)];
  }

  private getDefaultAdminOrgNameValidators(): ((control: AbstractControl<any, any>) => ValidationErrors)[] {
    return [Validators.required, Validators.maxLength(63)];
  }

  private initializeOrgNameFormGroup(): void {
    this.orgNameForm = this.formBuilder.group({
      orgName: ['', [...this.getDefaultAdminOrgNameValidators()]],
    });
  }

  private initializeSubOrgTypeFormGroup(): void {
    this.subOrgTypeFormGroup = this.formBuilder.group({
      orgType: [SubOrgType.admin, [Validators.required]],
    });
  }

  private initializeSubdomainOwnFormGroup(): void {
    this.subdomainOwnForm = this.formBuilder.group({
      domain: ['', [...this.getDefaultAdminOrgNameValidators(), this.customValidatorsService.subdomainHostnameValidator()]],
    });
    const domainControl = this.subdomainOwnForm.get('domain');
    domainControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((value) => {
      // Force user to re-validate everytime they change the subdomain
      this.isValidSubdomain = false;
    });
  }

  private getOrgNameFromForm(): string {
    return this.orgNameForm.get('orgName').value;
  }

  public getFormattedOrgNameFromForm(): string {
    return this.getOrgNameFromForm().trim();
  }

  public getOrgTypeFromForm(): SubOrgType {
    return this.subOrgTypeFormGroup.get('orgType').value;
  }

  public getOwnSubdomainFormValue(): string {
    return this.subdomainOwnForm.get('domain').value;
  }

  private getAdminSubOrgFromForms(): Organisation {
    const orgName = this.getFormattedOrgNameFromForm();
    return {
      organisation: orgName,
      // Subdomains must be lower case since hostnames are case insensitive
      subdomain: `${orgName.toLowerCase()}.${this.currentOrg.subdomain}`,
      contact_id: this.currentUser.id,
      contact_email: this.currentUser.email,
    };
  }

  private getNewSubOrgFromForms(): Organisation {
    const formattedSubdomainValue = getFormattedSubdomainValue(this.getOwnSubdomainFormValue());
    const orgName = this.getFormattedOrgNameFromForm();
    return {
      organisation: orgName,
      // Subdomains must be lower case since hostnames are case insensitive
      subdomain: this.userSetsDnsDomain() ? formattedSubdomainValue : `${orgName.toLowerCase()}.${this.currentOrg.subdomain}`,
      contact_id: this.currentUser.id,
      contact_email: this.currentUser.email,
    };
  }

  /**
   * Checks if cname resolves if the user is using their own domain
   */
  public verifySubDomain(): void {
    const formattedSubdomainValue = getFormattedSubdomainValue(this.getOwnSubdomainFormValue());
    if (!formattedSubdomainValue) {
      return;
    }
    resetProgressBarSteps(this.progressStepper, this.steps);
    passProgressBarStep(this.progressStepper);
    this.customValidatorsService
      .cnameResolves$(formattedSubdomainValue)
      .pipe(
        concatMap((resolves: resolveCnameData) => {
          if (resolves.status) {
            const regex = /agilicus/i;
            for (const answer of resolves.response.Answer) {
              // check that appropriate CNAME has been assigned
              if (answer.name === `auth.${formattedSubdomainValue}.` && answer.data.match(regex) !== null) {
                // throw 'passAllTests' error, if cname passes all the validation steps. Writing this logic here instead of subscribe method to avoid scope limitations
                return throwError(() => new HttpErrorResponse({ error: { error: 'passAllTests' } }));
              }
            }
            return throwError(() => new HttpErrorResponse({ error: { error: 'cnameNotCorrect' } }));
          }
          return throwError(() => new HttpErrorResponse({ error: { error: resolves.statusMessage } }));
        }),
        catchError((err: HttpErrorResponse) => {
          const errorMessage: string = err?.error?.error;
          if (errorMessage === 'passAllTests') {
            passAllProgressBarSteps(this.progressStepper);
            this.domainNameInput.nativeElement.focus();
            this.isValidSubdomain = true;
          } else {
            this.openCnameErrorMessageDialog(errorMessage, formattedSubdomainValue);
          }
          return of(undefined);
        })
      )
      .subscribe((res: Object | undefined) => {
        this.changeDetector.detectChanges();
      });
  }

  private setStepperProgressBarSteps() {
    this.steps.push(new Step('CNAME valid'));
    this.steps.push(new Step('CNAME correct'));
    resetProgressBarSteps(this.progressStepper, this.steps);
  }

  private openCnameErrorMessageDialog(errorMessage: string, subdomainValue: string): void {
    let messagePrefix = '';
    let message = errorMessage;
    if (errorMessage === 'cnameNotResolved') {
      failFirstProgressBarStep(this.progressStepper);
      messagePrefix = `Unable to resolve CNAME "${subdomainValue}"`;
      message = `ERROR: Please follow the instructions/wait longer for the dns to propagate. Click here to follow the <a href="${this.productGuideSignupDeepLink}" target="_blank">product guide</a>`;
    } else if (errorMessage === 'cnameNotCorrect') {
      failSecondProgressBarStep(this.progressStepper);
      messagePrefix = 'CNAME appears valid but does not point to Agilicus';
      message = `ERROR: If your dns name is run by your dns provider (e.g. godaddy, google domains, aws route 53, ...) and must be configured there. <a href="${this.productGuideSignupDeepLink}" target="_blank"> Please follow the instructions here</a>`;
    }
    if (errorMessage !== 'passAllTests') {
      this.displayErrorDialog(messagePrefix, message);
    }
  }

  public displayErrorDialog(messagePrefix: string, message: string) {
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.informationDialog = true;
    dialogData.buttonText = { confirm: '', cancel: 'Close' };
    this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
  }

  private onSuccessfulSubOrgCreation(subOrg: Organisation): void {
    // We need to tell the org state to update since we now have access to
    // a new one.
    this.store.dispatch(new ActionOrganisationsLoad(this.orgId));
    this.store.dispatch(new ActionUserRefreshMemberOrgs());
    this.notificationService.success(`New sub-organisation "${subOrg.organisation}" was successfully created`);
    // Trigger the update in the progress bar:
    this.modelStatus = {
      saving: false,
      save_success: true,
      save_fail: false,
      complete: true,
    };
    this.subOrgCreated = true;
    delayStepperAdvanceOnSuccessfulApply(this.stepper, this.modelStatus);
    this.delayAndRouteToSubOrgOverviewScreen();
    this.changeDetector.detectChanges();
  }

  private onFailedSubOrgCreation(subOrg: Organisation, errorMessage?: string): void {
    if (!errorMessage) {
      errorMessage = `Failed to create sub-organisation "${subOrg.organisation}"`;
    }
    this.notificationService.error(errorMessage);
    // Trigger the update in the progress bar:
    this.modelStatus = {
      saving: false,
      save_success: false,
      save_fail: true,
      complete: false,
    };
    this.changeDetector.detectChanges();
  }

  public createAdminSubOrg(): void {
    const subOrgToCreate = this.getAdminSubOrgFromForms();
    this.organisationsService
      .createSubOrg({ org_id: this.orgId, Organisation: subOrgToCreate })
      .pipe(takeUntil(this.unsubscribe$))
      .pipe(take(1))
      .subscribe(
        (orgResp) => {
          this.onSuccessfulSubOrgCreation(orgResp);
        },
        (err) => {
          this.onFailedSubOrgCreation(subOrgToCreate);
        }
      );
  }

  private getCreateOrgErrorMessage(err: any): string {
    let errorMessage = '';
    if (err.status === 409) {
      const nameFormControl = this.orgNameForm.get('orgName');
      signupLog(`ERROR: duplicate org name picked ${nameFormControl.value}`);
      errorMessage = 'An organisation with this name already exists. Please choose a different organisation name and try again.';
      nameFormControl.setErrors({ notUnique: true });
    }
    return errorMessage;
  }

  private getCreateNewSubOrgObservable$(newSubOrgToCreate: Organisation): Observable<Organisation> {
    return this.organisationsService.createOrg({
      OrganisationAdmin: {
        parent_id: this.orgId,
        billing_account_id: this.currentOrg.billing_account_id,
        organisation: newSubOrgToCreate.organisation,
        subdomain: newSubOrgToCreate.subdomain,
      },
    });
  }

  public createNewSubOrgWithoutIssuer(): void {
    const newSubOrgToCreate = this.getNewSubOrgFromForms();
    this.getCreateNewSubOrgObservable$(newSubOrgToCreate)
      .pipe(
        concatMap((org: Organisation) => {
          if (!org.id) {
            return throwError(() => 'Organisation missing id');
          }
          return of({ status: CreateOrgStatusEnum.success });
        }),
        catchError((err) => {
          const errorMessage = this.getCreateOrgErrorMessage(err);
          return of({ status: CreateOrgStatusEnum.fail, errorMessage });
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: CreateOrgResponseData) => {
        this.onCreateOrgResponse(resp, newSubOrgToCreate);
      });
  }

  public createNewSubOrgWithIssuer(): void {
    const formattedSubdomainValue = getFormattedSubdomainValue(this.getOwnSubdomainFormValue());
    const newSubOrgToCreate = this.getNewSubOrgFromForms();
    this.getCreateNewSubOrgObservable$(newSubOrgToCreate)
      .pipe(
        delay(10000), // wait 10 seconds before polling since it normally takes >10 seconds to be ready
        concatMap((org: Organisation) => {
          if (!org.id) {
            return throwError(() => 'Organisation missing id');
          }
          const url = `https://auth.${formattedSubdomainValue}/.well-known/openid-configuration`;
          return pollForCompletion$(this.organisationsService, org.id).pipe(
            concatMap((_) => {
              // check split-horizon issue if own domain is selected
              return this.http.get(url, { headers: getIgnoreErrorsHeader(), responseType: 'json' }).pipe(
                map((__) => {
                  return { status: CreateOrgStatusEnum.success };
                }),
                catchError((err: HttpErrorResponse) => {
                  this.displayErrorDialog(this.splitHorizonMessagePrefix, this.splitHorizonMessage);
                  return throwError(() => err);
                })
              );
            })
          );
        }),
        catchError((err) => {
          const errorMessage = this.getCreateOrgErrorMessage(err);
          return of({ status: CreateOrgStatusEnum.fail, errorMessage });
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: CreateOrgResponseData) => {
        this.onCreateOrgResponse(resp, newSubOrgToCreate);
      });
  }

  private onCreateOrgResponse(resp: CreateOrgResponseData, newSubOrgToCreate: Organisation): void {
    if (resp.status === CreateOrgStatusEnum.success) {
      this.onSuccessfulSubOrgCreation(newSubOrgToCreate);
    } else if (resp.status === CreateOrgStatusEnum.fail) {
      this.onFailedSubOrgCreation(newSubOrgToCreate, resp.errorMessage);
    }
    this.changeDetector.detectChanges();
  }

  private routeToSubOrgOverviewScreen(): void {
    this.router.navigate(['/sub-org-admin']);
  }

  private delayAndRouteToSubOrgOverviewScreen(): void {
    setTimeout(() => {
      this.routeToSubOrgOverviewScreen();
    }, 1500);
  }

  public getSubdomainNextButtonTooltip(): string {
    if (this.isValidSubdomain) {
      return '';
    }
    return 'Please validate the (sub)domain above';
  }

  public onSubOrgTypeOptionChange(option: SubOrgType): void {
    this.subOrgTypeFormGroup.get('orgType').setValue(option);
    const orgNameFormControl = this.orgNameForm.get('orgName');
    if ((option === SubOrgType.new && !this.orgCanCreateNewIssuer) || option === SubOrgType.admin) {
      orgNameFormControl.setValidators([...this.getDefaultAdminOrgNameValidators(), this.customValidatorsService.hostnameValidator()]);
    } else {
      orgNameFormControl.setValidators(this.getDefaultNewCustomerOrgNameValidators());
    }
    orgNameFormControl.updateValueAndValidity();
  }

  public onApply(): void {
    const selectedSubOrgType = this.getOrgTypeFromForm();
    if (selectedSubOrgType === SubOrgType.new) {
      if (this.orgCanCreateNewIssuer) {
        this.createNewSubOrgWithIssuer();
      } else {
        this.createNewSubOrgWithoutIssuer();
      }
    } else if (selectedSubOrgType === SubOrgType.admin) {
      this.createAdminSubOrg();
    }
  }

  public isStepperComplete(): boolean {
    return this.subOrgCreated;
  }

  public getCustomProgressBarEstimateText(): string {
    if (this.userSetsDnsDomain()) {
      return getProgressBarEstimateText();
    }
    return '';
  }

  public getOrgNameHintLabel(): string {
    if (this.userSetsDnsDomain()) {
      return 'Max length of 100 characters.';
    }
    return 'Must be a valid hostname. Max length of 63 characters.';
  }

  public userSetsDnsDomain(): boolean {
    return this.getOrgTypeFromForm() === SubOrgType.new && this.orgCanCreateNewIssuer;
  }
}

<portal-page-info
  pageTitle="New Share"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentFileShareModel"
  class="stepper-container"
>
  <mat-vertical-stepper 
    linear
    #fileSharesStepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>
  
    <mat-step [stepControl]="shareNameFormGroup">
      <ng-template matStepLabel>Share name</ng-template>
      <form
        [formGroup]="shareNameFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the share name"
          label="My share name is..."
          [formCtrl]="shareNameFormGroup.get('name')"
          required="true"
          (formBlur)="onFormBlur(shareNameFormGroup, 'name', getModel())"
          tooltip="This name will show up in audit records in this system and elsewhere. It can be the same as PATH."
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="localPathFormGroup">
      <ng-template matStepLabel>Local directory</ng-template>
      <form
        [formGroup]="localPathFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the local directory"
          label="My local directory I am sharing is..."
          [formCtrl]="localPathFormGroup.get('local_path')"
          required="true"
          (formBlur)="onFormBlur(localPathFormGroup, 'local_path', getModel())"
          tooltip="This is the absolute directory on the machine running the agent. It must be locally accessible and the agent must have group-access to it. This is a directory like '/home/files/X' for Linux or 'C:\users\my-user\to_share' for Windows."
          validateAsPath="true"
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [completed]="isAppStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedFileShareText }}</p>
      
      <portal-product-guide-link
        productGuidePreText="Note: you will need to assign permissions in order to use this. Although Shares have 3 basic permissions (Viewer, Editor, Owner), some applications do not work well with Editor since they expect to be able to create a temporary file or lock file in the same directory. For more information see the "
        productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-shares/"
      >
      </portal-product-guide-link>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Connector: {{ getModel().connector_name }}</p>
        <p>Share name: {{ getModel().name }}</p>
        <p>Local directory: {{ getModel().local_path }}</p>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentFileShareModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="fileShareModelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitFileShareModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <portal-stepper-done-config-options
        *ngIf="!!getModel().id"
        [resourceId]="getModel().id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
      >
      </portal-stepper-done-config-options>
    </mat-step>
  </mat-vertical-stepper>
</div>

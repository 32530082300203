import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WelcomeComponent } from './static/welcome/welcome.component';
import { NewsComponent } from './shared/components/news/news.component';
import { ProfileComponent } from './shared/components/profile/profile.component';
import { AuthGuardService } from '@app/core/auth/auth-guard.service';
import { PermissionsAdminComponent } from './shared/components/permissions-admin/permissions-admin.component';
import { OrgAdminComponent } from './shared/components/org-admin/org-admin.component';
import { GroupAdminComponent } from './shared/components/group-admin/group-admin.component';
import { UserAdminComponent } from './shared/components/user-admin/user-admin.component';
import { SubOrgAdminComponent } from './shared/components/sub-org-admin/sub-org-admin.component';
import { ApplicationOverviewComponent } from './shared/components/application-main/application-main.component';
import { ApplicationDefineComponent } from './shared/components/application-define/application-define.component';
import { ApplicationDiagnoseComponent } from './shared/components/application-diagnose/application-diagnose.component';
import { ApplicationEnvironmentComponent } from './shared/components/application-environment/application-environment.component';
import { ApplicationRuleConfigComponent } from './shared/components/application-rule-config/application-rule-config.component';
import { NetworkOverviewComponent } from './shared/components/network-overview/network-overview.component';
import { MetricsTopUsersComponent } from './shared/components/metrics-top-users/metrics-top-users.component';
import { MetricsActiveUsersComponent } from './shared/components/metrics-active-users/metrics-active-users.component';
import { ApplicationAuthClientsComponent } from './shared/components/application-auth-clients/application-auth-clients.component';
import { AuthAuditComponent } from './shared/components/auth-audit/auth-audit.component';
import { UserDiagnoseComponent } from './shared/components/user-diagnose/user-diagnose.component';
import { SignupComponent } from './shared/components/signup/signup.component';
import { ApplicationStepperComponent } from './shared/components/application-stepper/application-stepper.component';
import { ConnectorAdminComponent } from './shared/components/connector-admin/connector-admin.component';
import { ConnectorStepperComponent } from './shared/components/connector-stepper/connector-stepper.component';
import { FileSharesStepperComponent } from './shared/components/file-shares-stepper/file-shares-stepper.component';
import { FileSharesOverviewComponent } from './shared/components/file-shares-overview/file-shares-overview.component';
import { ResourcePermissionsComponent } from './shared/components/resource-permissions/resource-permissions.component';
import { UserResourceAccessRequestsComponent } from './shared/components/user-resource-access-requests/user-resource-access-requests.component';
import { ForwardingServiceComponent } from './shared/components/forwarding-service/forwarding-service.component';
import { DesktopStepperComponent } from './shared/components/desktop-stepper/desktop-stepper.component';
import { DesktopOverviewComponent } from './shared/components/desktop-overview/desktop-overview.component';
import { BillingComponent } from './shared/components/billing/billing.component';
import { ResourceGroupAdminComponent } from './shared/components/resource-group-admin/resource-group-admin.component';
import { ServiceAccountAdminComponent } from './shared/components/service-account-admin/service-account-admin.component';
import { LauncherOverviewComponent } from './shared/components/launcher-overview/launcher-overview.component';
import { GettingStartedComponent } from './static/getting-started/getting-started.component';
import { IdentifyUsersGuideComponent } from './static/identify-users-guide/identify-users-guide.component';
import { AssignPermissionsGuideComponent } from './static/assign-permissions-guide/assign-permissions-guide.component';
import { AuditUserGuideComponent } from './static/audit-user-guide/audit-user-guide.component';
import { ConnectToSitesGuideComponent } from './static/connect-to-sites-guide/connect-to-sites-guide.component';
import { ExposeResourceGuideComponent } from './static/expose-resource-guide/expose-resource-guide.component';
import { SetupPaymentGuideComponent } from './static/setup-payment-guide/setup-payment-guide.component';
import { ThemingComponent } from './shared/components/theming/theming.component';
import { SharedIdentityComponent } from './shared/components/shared-identity/shared-identity.component';
import { CustomIdentityComponent } from './shared/components/custom-identity/custom-identity.component';
import { OnsiteIdentityComponent } from './shared/components/onsite-identity/onsite-identity.component';
import { ApplicationIdentityComponent } from './shared/components/application-identity/application-identity.component';
import { AuthIssuerPolicyComponent } from './shared/components/auth-issuer-policy/auth-issuer-policy.component';
import { AuditDestinationsComponent } from './shared/components/audit-destinations/audit-destinations.component';
import { AuditSubsystemComponent } from './shared/components/audit-subsystem/audit-subsystem.component';
import { SshStepperComponent } from './shared/components/ssh-stepper/ssh-stepper.component';
import { SshOverviewComponent } from './shared/components/ssh-overview/ssh-overview.component';
import { NetworkStepperComponent } from './shared/components/network-stepper/network-stepper.component';
import { LauncherStepperComponent } from './shared/components/launcher-stepper/launcher-stepper.component';
import { ResourceOverviewComponent } from './shared/components/resource-overview/resource-overview.component';
import { AuthenticationOverviewComponent } from './shared/components/authentication-overview/authentication-overview.component';
import { SubOrgStepperComponent } from './shared/components/sub-org-stepper/sub-org-stepper.component';
import { PolicyTemplatesComponent } from './shared/components/policy-templates/policy-templates.component';
import { LabelOverviewComponent } from './shared/components/label-overview/label-overview.component';
import { SupportRequestsOverviewComponent } from './shared/components/support-requests-overview/support-requests-overview.component';
import { ApiKeyOverviewComponent } from './shared/components/api-key-overview/api-key-overview.component';
import { ApiKeyNewComponent } from './shared/components/api-key-new/api-key-new.component';

const routes: Routes = [
  {
    path: '',
    component: WelcomeComponent,
  },
  { path: 'getting-started', component: GettingStartedComponent },
  { path: 'identify-users-guide', component: IdentifyUsersGuideComponent },
  { path: 'connect-to-sites-guide', component: ConnectToSitesGuideComponent },
  { path: 'expose-resource-guide', component: ExposeResourceGuideComponent },
  { path: 'assign-permissions-guide', component: AssignPermissionsGuideComponent },
  { path: 'audit-user-guide', component: AuditUserGuideComponent },
  { path: 'setup-payment-guide', component: SetupPaymentGuideComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'news', component: NewsComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuardService],
  },
  /*
  {
    path: 'http-endpoints',
    component: HttpEndpointsComponent,
    canActivate: [ApiAuthGuardService]
  },
  {
    path: 'smtp-endpoints',
    component: SmtpEndpointsComponent,
    canActivate: [ApiAuthGuardService]
  },
  */
  {
    path: 'sub-org-new',
    component: SubOrgStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'org-admin',
    component: OrgAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'sub-org-admin',
    component: SubOrgAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'authentication-audit',
    component: AuthAuditComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'audit-subsystem',
    component: AuditSubsystemComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'audit-destinations',
    component: AuditDestinationsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'labels',
    component: LabelOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'network-new',
    component: NetworkStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'network-overview',
    component: NetworkOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'user-admin',
    component: UserAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'user-audits',
    component: UserDiagnoseComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'group-admin',
    component: GroupAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'resource-group-admin',
    component: ResourceGroupAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'service-account-admin',
    component: ServiceAccountAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-permissions-admin',
    component: PermissionsAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'resource-permissions-admin',
    component: ResourcePermissionsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'resource-access-requests',
    component: UserResourceAccessRequestsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-access-requests',
    component: UserResourceAccessRequestsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'support-requests',
    component: SupportRequestsOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-overview',
    component: ApplicationOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-define/:appId',
    component: ApplicationDefineComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-define',
    component: ApplicationDefineComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-define/:appId/instance/:envName',
    component: ApplicationEnvironmentComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-define/:appId/rule/:ruleId',
    component: ApplicationRuleConfigComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-diagnose',
    component: ApplicationDiagnoseComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-authentication-clients',
    component: ApplicationAuthClientsComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-new',
    component: ApplicationStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'connector-new',
    component: ConnectorStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'connector-overview',
    component: ConnectorAdminComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'resource-overview',
    component: ResourceOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'metrics-top-users',
    component: MetricsTopUsersComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'metrics-active-users',
    component: MetricsActiveUsersComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: { isSigningUp: true },
  },
  {
    path: 'shares-new',
    component: FileSharesStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'shares-overview',
    component: FileSharesOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'api-key-new',
    component: ApiKeyNewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'api-key-overview',
    component: ApiKeyOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'forwarding-services',
    component: ForwardingServiceComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'desktop-new',
    component: DesktopStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'desktop-overview',
    component: DesktopOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'ssh-new',
    component: SshStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'ssh-overview',
    component: SshOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'launcher-new',
    component: LauncherStepperComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'launcher-overview',
    component: LauncherOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'authentication-overview',
    component: AuthenticationOverviewComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'theming',
    component: ThemingComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'shared-identity',
    component: SharedIdentityComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'custom-identity',
    component: CustomIdentityComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'onsite-identity',
    component: OnsiteIdentityComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'application-identity',
    component: ApplicationIdentityComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'auth-issuer-policy',
    component: AuthIssuerPolicyComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  {
    path: 'policies',
    component: PolicyTemplatesComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [AuthGuardService],
  },
  { path: 'logout-bounce', redirectTo: '/?logout=true', pathMatch: 'full' },
  {
    path: '**',
    redirectTo: '/',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule],
})
export class AppRoutingModule {}

import { Application, ApplicationConfig, Environment, HTTPSecuritySettings, IssuerClient } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Input, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatAccordion } from '@angular/material/expansion';
import { AppState } from '@app/core';
import { ActionApiApplicationsModifyCurrentApp } from '@app/core/api-applications/api-applications.actions';
import { selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import { selectIssuerClientsList } from '@app/core/issuer-clients/issuer-clients.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  setAllSecurityConfigPropertiesIfUnset,
  setOidcAuthConfigIfUnset,
  setAuthenticationUpstreamIfUnset,
} from '../application-configs-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { getEmptyStringIfUnset, modifyDataOnFormBlur } from '../utils';
import { CorsConfigComponent } from '../cors-config/cors-config.component';
import { isExpansionPanelOpen } from '@app/core/models/ui/ui-model.utils';
import { selectUI } from '@app/core/ui/ui.selectors';
import { AppDefineExpansionPanel, UIState } from '@app/core/ui/ui.models';
import { createDialogData } from '../dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'portal-web-application-security',
  templateUrl: './web-application-security.component.html',
  styleUrls: ['./web-application-security.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebApplicationSecurityComponent implements OnInit, OnDestroy {
  @Input() public fixedData = false;
  @Input() public currentOrgIssuer: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public oidcAuthenticationForm: UntypedFormGroup;
  public enableAuthTooltipText =
    'If enabled, users will be forced to log in before accessing any of its assets. If disabled, no authentication will be performed.';
  public enableNtlmTooltipText = 'Enable NTLM passthrough, which allows your users to authenticate using NTLM.';
  private currentApplicationCopy: Application;
  public issuerClients: Array<IssuerClient> = [];
  public currentSecurityHeadersCopy: HTTPSecuritySettings;
  public webAppSecurityDescriptiveText = `Configure your web application's security settings.`;
  public webAppSecurityProductGuideLink = `https://www.agilicus.com/anyx-guide/web-application-security/`;
  public authenticationDescriptiveText = `Enable the proxy to perform authentication on behalf of your application.`;
  public cspDescriptiveText = `Configure the HTTP Content Security Policy.`;
  public uiState: UIState;
  public isExpansionPanelOpen = isExpansionPanelOpen;
  public applicationHandlesAuthenticationTooltipText = `The application processes requests which fail authentication. The firewall will mark, rather than reject, requests which are unauthenticated.`;

  // This is required in order to reference the enums in the html template.
  public appDefineExpansionPanel = AppDefineExpansionPanel;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  // Expansion panels
  @ViewChild(MatAccordion) public accordion: MatAccordion;

  @ViewChild(CorsConfigComponent) public corsConfig: CorsConfigComponent;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    public handlesAuthDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    const currentApplicationState$ = this.store.pipe(select(selectApiCurrentApplication));
    const issuerClients$ = this.store.pipe(select(selectIssuerClientsList));
    const uiState$ = this.store.pipe(select(selectUI));
    combineLatest([currentApplicationState$, issuerClients$, uiState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([currentApplicationStateResp, issuerClientsResp, uiStateResp]) => {
        this.uiState = uiStateResp;
        this.issuerClients = issuerClientsResp;
        if (!currentApplicationStateResp) {
          return;
        }
        this.currentApplicationCopy = cloneDeep(currentApplicationStateResp);
        this.currentSecurityHeadersCopy = cloneDeep(this.currentApplicationCopy?.environments[0]?.application_configs?.security?.http);
        this.initializeFormGroups();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAuthEnabledValue(): boolean {
    return !!this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.auth?.auth_enabled;
  }

  private getNtlmEnabledValue(): boolean {
    return !!this.currentApplicationCopy?.environments[0]?.application_configs?.authentication_config?.upstream?.ntlm?.ntlm_passthrough;
  }

  private initializeOidcAuthenticationFormGroup(): void {
    let applicationHandlesAuthentication = false;
    if (this.currentApplicationCopy?.environments[0]?.application_configs?.authentication_config?.application_handles_authentication) {
      applicationHandlesAuthentication = true;
    }
    const clientIdValidators = [Validators.maxLength(100)];
    if (!!this.getAuthEnabledValue()) {
      clientIdValidators.push(Validators.required);
    }
    this.oidcAuthenticationForm = this.formBuilder.group({
      auth_enabled: this.getAuthEnabledValue(),
      applicationHandlesAuthentication: [applicationHandlesAuthentication],
      ntlm_passthrough: this.getNtlmEnabledValue(),
      client_id: [
        getEmptyStringIfUnset(this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.auth?.client_id),
        clientIdValidators,
      ],
      logout_url: getEmptyStringIfUnset(this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.auth?.logout_url),
      redirect_after_signin_path: getEmptyStringIfUnset(
        this.currentApplicationCopy?.environments[0]?.application_configs?.oidc_config?.auth?.redirect_after_signin_path
      ),
    });
  }

  public onFormBlur(form: UntypedFormGroup, formField: string): void {
    modifyDataOnFormBlur(form, formField, this.modifyApplicationOnValueChanges.bind(this));
  }

  private modifyApplicationOnValueChanges(): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    // Need to update the application_configs of every environment for this application.
    for (const env of copyOfCurrentApplicationCopy.environments) {
      this.setAllFieldsFromForms(env);
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  public modifyApplication(updatedApplication: Application): void {
    this.currentApplicationCopy = updatedApplication;
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(updatedApplication));
  }

  public updateApplicationOnAppConfigsChange(appConfigs: ApplicationConfig): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const env of copyOfCurrentApplicationCopy.environments) {
      env.application_configs = appConfigs;
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  private setEnvOidcProxyFromAuthForm(environment: Environment): void {
    setOidcAuthConfigIfUnset(environment, this.currentOrgIssuer);
    setAuthenticationUpstreamIfUnset(environment);
    environment.application_configs.oidc_config.auth.auth_enabled = this.oidcAuthenticationForm.get('auth_enabled').value;
    environment.application_configs.oidc_config.auth.client_id = this.oidcAuthenticationForm.get('client_id').value;
    environment.application_configs.oidc_config.auth.logout_url = this.oidcAuthenticationForm.get('logout_url').value;
    environment.application_configs.oidc_config.auth.redirect_after_signin_path =
      this.oidcAuthenticationForm.get('redirect_after_signin_path').value;
    environment.application_configs.authentication_config.upstream.ntlm.ntlm_passthrough =
      this.oidcAuthenticationForm.get('ntlm_passthrough').value;
    environment.application_configs.authentication_config.application_handles_authentication =
      this.oidcAuthenticationForm.get('applicationHandlesAuthentication').value;
  }

  public onCheckboxChange(): void {
    this.modifyApplicationOnValueChanges();
  }

  private initializeFormGroups(): void {
    this.initializeOidcAuthenticationFormGroup();
    this.changeDetector.detectChanges();
  }

  private setAllFieldsFromForms(environment: Environment): void {
    this.setEnvOidcProxyFromAuthForm(environment);
  }

  public onClientIdChange(): void {
    const isClientIdFormControlValid = this.oidcAuthenticationForm.get('client_id').valid;
    if (!isClientIdFormControlValid) {
      return;
    }
    this.modifyApplicationOnValueChanges();
  }

  public updateSecurityHeaders(updatedSecurityHeaders: HTTPSecuritySettings): void {
    const copyOfCurrentApplicationCopy = cloneDeep(this.currentApplicationCopy);
    for (const environment of copyOfCurrentApplicationCopy.environments) {
      setAllSecurityConfigPropertiesIfUnset(environment);
      environment.application_configs.security.http = updatedSecurityHeaders;
    }
    this.modifyApplication(copyOfCurrentApplicationCopy);
  }

  public onProxyAuthenticationCheckboxChange(isChecked: boolean): void {
    if (!isChecked) {
      this.modifyApplicationOnValueChanges();
      return;
    }
    this.openHandlesAuthenticationDialog();
  }

  public openHandlesAuthenticationDialog(): void {
    const messagePrefix = `Application Handles Authentication`;
    const message = `Warning: Only enable this setting if you have integrated your application into the Agilicus authentication API, or your application is running an Agilicus proxy with authentication enabled. Would you like to continue?`;
    const dialogData = createDialogData(messagePrefix, message);
    const dialogRef = this.handlesAuthDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.modifyApplicationOnValueChanges();
        return;
      }
      this.oidcAuthenticationForm.get('applicationHandlesAuthentication').setValue(false);
    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    const corsConfigValidate = this?.corsConfig ? this.corsConfig.canDeactivate() : true;
    return corsConfigValidate;
  }
}

import { AfterViewInit, Directive, EventEmitter, Host, Input, OnDestroy, Output, Renderer2, ViewContainerRef } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PageSelectionConfig } from './table-paginator.component';

@Directive({
  selector: '[portalPageSelection]',
})
export class PageSelectionDirective implements AfterViewInit, OnDestroy {
  @Input() public showPageSelection = false;
  @Input() public pageWindowSize = 5; // number of pages to show
  @Input() public updatePageSelection = new Observable<PageSelectionConfig>(); // emitted when component has finished fetching table data
  @Input() public dataLength = 0; // total number of entries fetched
  @Output() public page = new EventEmitter<PageEvent>();
  private unsubscribe$: Subject<void> = new Subject<void>();
  private pageSize: number;
  private pageIndex = 0;
  public pageCount = 0;
  private nextWindow = false; // indicates if a window of pages exists after the current window

  constructor(@Host() private readonly paginator: MatPaginator, private readonly vr: ViewContainerRef, private readonly ren: Renderer2) {}

  public ngAfterViewInit(): void {
    if (this.showPageSelection) {
      this.initPageSelection();
      this.updatePageSelection.pipe(takeUntil(this.unsubscribe$)).subscribe((pageSelectionConfig: PageSelectionConfig) => {
        // negative page index means to dataFetched was triggered by page selection button
        this.pageIndex = pageSelectionConfig.pageIndex >= 0 ? pageSelectionConfig.pageIndex : this.pageIndex;
        this.pageCount = pageSelectionConfig.pageCount;
        this.nextWindow = pageSelectionConfig.nextWindow;
        if (this.paginator.pageSize !== this.pageSize) {
          // page event was triggered by page size change
          this.pageSize = this.paginator.pageSize;
          this.paginator.pageIndex = 0;
        }
        this.createPageRange();
      });
    }
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initPageSelection(): void {
    this.pageSize = this.paginator.pageSize;
    const pagingContainer = this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');

    // remove unused label and buttons
    this.ren.removeChild(
      pagingContainer,
      this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions div.mat-mdc-paginator-range-label')
    );
    this.ren.removeChild(
      pagingContainer,
      this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions button.mat-mdc-paginator-navigation-first')
    );
    this.ren.removeChild(
      pagingContainer,
      this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions button.mat-mdc-paginator-navigation-last')
    );

    // create buttons
    this.createPageRange();
  }

  private createPageRange(): void {
    const pagingContainer = this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions');
    const nextPageButton = this.vr.element.nativeElement.querySelector('button.mat-mdc-paginator-navigation-next');

    // remove existing buttons
    if (this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions div.btn_custom-paging-container')) {
      this.ren.removeChild(
        pagingContainer,
        this.vr.element.nativeElement.querySelector('div.mat-mdc-paginator-range-actions div.btn_custom-paging-container')
      );
    }

    // create container for page selection buttons
    const pagingContainerBtns = this.ren.createElement('div');
    this.ren.addClass(pagingContainerBtns, 'btn_custom-paging-container');

    // show the page numbers between the previous and next arrows
    this.ren.insertBefore(pagingContainer, pagingContainerBtns, nextPageButton);
    this.buildPageNumbers(this.pageCount, pagingContainerBtns);

    // re-enable pagination buttons
    this.ren.removeClass(pagingContainer, 'disable-pagination-actions');
  }

  private buildPageNumbers(pageCount, pagingContainerBtns): void {
    const currentPage = this.pageIndex;
    let morePagesDots = true;

    // number of pages to get to the halfway point of this window
    const halfwayPages = Math.floor(this.pageWindowSize / 2);

    // page number of the first page in this window
    let startPage = currentPage < halfwayPages ? 0 : currentPage - halfwayPages;

    // page number of the last page in this window
    let endPage = startPage + this.pageWindowSize;

    // last window of pages
    if (endPage >= pageCount && !this.nextWindow) {
      startPage = pageCount < this.pageWindowSize ? 0 : pageCount - this.pageWindowSize;
      endPage = pageCount;
      morePagesDots = false;
    }

    // add page buttons
    for (let i = startPage; i < endPage; i = i + 1) {
      this.ren.insertBefore(pagingContainerBtns, this.createPage(i, currentPage, startPage), null);
    }
    if (morePagesDots) {
      this.ren.insertBefore(pagingContainerBtns, this.createPage('...', currentPage), null);
    }
  }

  private createPage(i: any, pageIndex: number, start = 0): any {
    const linkBtn = this.ren.createElement('button');
    this.ren.addClass(linkBtn, 'mat-icon-button');
    const pagingTxt = isNaN(i) ? i : i + 1;
    const text = this.ren.createText(pagingTxt);
    if (i === pageIndex) {
      this.ren.addClass(linkBtn, 'mat-button-disabled');
    } else {
      this.ren.listen(linkBtn, 'click', () => {
        // change to the page number or increase page index if button is the ellipses
        const originalIndex = this.paginator.pageIndex;
        this.paginator.pageIndex = isNaN(i) ? this.paginator.pageIndex + 1 : this.paginator.pageIndex + (i - pageIndex);
        this.pageIndex = this.paginator.pageIndex;
        // trigger page update
        this.page.emit({
          length: this.paginator.length,
          previousPageIndex: originalIndex,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize,
        });
      });
    }

    this.ren.appendChild(linkBtn, text);
    return linkBtn;
  }
}

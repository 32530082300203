<mat-dialog-content>
  <h2 class="title">Detailed Statistics for: {{ connector.name }}</h2>
  <h4 class="overall-stats-title">Overall Connection Statistics</h4>
  <portal-form-layout
    #connectionOverviewFormLayout
    [columnDefs]="connectionOverviewFormColumnDefs"
    [element]="connector"
  >
  </portal-form-layout>

  <h4 class="overall-stats-title">Overall TLS Statistics</h4>
  <portal-form-layout
    #tlsOverviewFormLayout
    [columnDefs]="tlsOverviewFormColumnDefs"
    [element]="connector"
  >
  </portal-form-layout>

  <h4 class="overall-stats-title">Overall Share Statistics</h4>
  <portal-form-layout
    #shareOverviewFormLayout
    [columnDefs]="shareOverviewFormColumnDefs"
    [element]="connector"
  >
  </portal-form-layout>

  <h4 class="overall-stats-title">Overall HTTP Statistics</h4>
  <portal-form-layout
    #httpOverviewFormLayout
    [columnDefs]="httpOverviewFormColumnDefs"
    [element]="connector"
  >
  </portal-form-layout>

  <h4 class="resource-stats-title">Per Resource Connection Statistics</h4>
  <portal-table-layout
    [tableData]="nonShareStatsTableData"
    [columnDefs]="connectionStatsTableColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>

  <h4 class="resource-stats-title">Per Resource TLS Statistics</h4>
  <portal-table-layout
    [tableData]="nonShareStatsTableData"
    [columnDefs]="tlsStatsTableColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>

  <h4 class="resource-stats-title">Per Resource Share Statistics</h4>
  <portal-table-layout
    [tableData]="shareStatsTableData"
    [columnDefs]="shareStatsTableColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>

  <h4 class="resource-stats-title">Per Resource HTTP Statistics</h4>
  <portal-table-layout
    [tableData]="httpStatsTableData"
    [columnDefs]="httpStatsTableColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>

  <h4 class="resource-stats-title">Per Tunnel Statistics</h4>
  <portal-table-layout
    [tableData]="perTunnelStatsTableData"
    [columnDefs]="perTunnelStatsColumnDefs"
    [filterManager]="filterManager"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>
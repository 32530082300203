<div *ngIf="!!credentialsFormGroup" class="dialog-container">
  <mat-dialog-content>
    <h2>Manage Credentials for SSH "{{ data.ssh.spec.name }}"</h2>
    <form
      [formGroup]="credentialsFormGroup"
      class="stepper-form"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="main-container">
        <mat-form-field class="simple-input">
          <mat-label>
            Username
          </mat-label>
          <input
            matInput
            type="text"
            formControlName="username"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            spellcheck="true"
          />
        </mat-form-field>
        <mat-icon
          class="help-icon"
          [matTooltip]="getCredentialUsernameTooltipText()"
        >
          help_outline
        </mat-icon>

        <div class="private-key-container">
          <mat-form-field class="textarea-field textarea-form-field">
            <mat-label>
              Private key
              <mat-icon
                *ngIf="!!credential?.status?.private_key"
                class="success-color"
              >
                check_circle
              </mat-icon>
            </mat-label>
            <textarea 
              matInput
              [value]="getPrivateKeyValueFromCredential()"
              formControlName="private_key"
            >
            </textarea>
            <button
              *ngIf="!!getPrivateKeyValueFromForm()"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clearPrivateKey()"
            >
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div class="more-actions" style="display:inline-block; width: 5%">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="privateKeyUpload.click()">
                Upload File
              </button>
              <input
                hidden
                type="file"
                #privateKeyUpload
                name="upload-file"
                id="upload-file"
                (change)="uploadPrivateKeyData($event)"
                matTooltip="Upload private key pem file"
                title=" "
                aria-label="Input that uploads a file"
                portalDisableKeyTab
              >
              <button
                mat-menu-item
                (click)="downloadPrivateKey()"
                [disabled]="disableDownload()"
              >
                <span>Download</span>
              </button>
            </mat-menu>
          </div>
          <mat-icon
            class="help-icon private-key-help-icon"
            [matTooltip]="getCredentialPrivateKeyTooltipText()"
          >
            help_outline
          </mat-icon>
        </div>
  
        <mat-form-field class="simple-input">
          <mat-label>
            Private key passphrase
            <mat-icon
              *ngIf="!!credential?.status?.private_key_passphrase"
              class="success-color"
            >
              check_circle
            </mat-icon>
          </mat-label>
          <input
            matInput
            showHideInput
            id="privateKeyPassphraseId"
            type="password"
            formControlName="private_key_passphrase"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            spellcheck="true"
          />
          <i
            matSuffix
            class="material-icons"
            showHideTrigger="privateKeyPassphraseId"
            [showHideStatus]="{ materialIcon: true, id: 'privateKeyPassphraseId' }"
          ></i>
          <mat-error>Max length of {{ getSshCredentialPrivateKeyPassphraseMaxLength() }} exceeded</mat-error>
        </mat-form-field>
        <mat-icon
          class="help-icon"
          [matTooltip]="getCredentialPrivateKeyPassphraseTooltipText()"
        >
          help_outline
        </mat-icon>

        <mat-form-field class="simple-input">
          <mat-label>
            Password
            <mat-icon
              *ngIf="!!credential?.status?.password"
              class="success-color"
            >
              check_circle
            </mat-icon>
          </mat-label>
          <input
            matInput
            showHideInput
            id="passwordId"
            type="password"
            formControlName="password"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            spellcheck="true"
          />
          <i
            matSuffix
            class="material-icons"
            showHideTrigger="passwordId"
            [showHideStatus]="{ materialIcon: true, id: 'passwordId' }"
          ></i>
          <mat-error>Max length of {{ getSshCredentialPasswordMaxLength() }} exceeded</mat-error>
        </mat-form-field>
        <mat-icon
          class="help-icon"
          [matTooltip]="getCredentialPasswordTooltipText()"
        >
          help_outline
        </mat-icon>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" (click)="onConfirmClick()" [disabled]="disableSaveButton()" tabindex="2">SAVE</button>
    <button mat-raised-button color="warn" (click)="onDeleteClick()" [disabled]="!credential" tabindex="1">DELETE</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">CANCEL</button>
  </mat-dialog-actions>
</div>
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Subject, takeUntil } from 'rxjs';
import { ChiplistExpandDialogData } from '../chiplist-expand-dialog/chiplist-expand-dialog.component';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { InputData } from '../custom-chiplist-input/input-data';
import { TableElement } from '../table-layout/table-element';

export interface LoadingChiplistInput<T extends InputData> extends ChiplistInput<T> {
  // This is required here:
  getCustomAllowedValuesFromApi: () => Observable<Array<any>>;
}

export interface ChiplistExpandLoadingDialogData<T extends InputData> extends ChiplistExpandDialogData<T> {
  chiplistInput: LoadingChiplistInput<T>;
}

@Component({
  selector: 'portal-chiplist-expand-loading-dialog',
  templateUrl: './chiplist-expand-loading-dialog.component.html',
  styleUrls: ['./chiplist-expand-loading-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChiplistExpandLoadingDialogComponent<T extends TableElement> implements OnInit {
  private unsubscribe$: Subject<void> = new Subject<void>();
  constructor(
    public dialogRef: MatDialogRef<ChiplistExpandLoadingDialogComponent<T>>,
    @Inject(MAT_DIALOG_DATA) public data: ChiplistExpandLoadingDialogData<T>
  ) {}

  public ngOnInit(): void {
    this.data.chiplistInput
      .getCustomAllowedValuesFromApi()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.data.chiplistInput.allowedValues = resp;
        this.dialogRef.close(this.data);
      });
  }
}

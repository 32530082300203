import { InboxItem } from '@agilicus/angular';

export function getSurveyMessageLastCheckedStorageKey(): string {
  return 'survey_message_last_checked';
}

export function getUnreadSurveyMessage(inboxItems: Array<InboxItem>): InboxItem | undefined {
  for (const inboxItem of inboxItems) {
    if (isUnreadSurveyMessage(inboxItem)) {
      return inboxItem;
    }
  }
  return undefined;
}

export function isSurveyMessage(inboxItem: InboxItem): boolean {
  return inboxItem.status?.message?.message_type === 'survey';
}

export function isUnreadSurveyMessage(inboxItem: InboxItem): boolean {
  if (isSurveyMessage(inboxItem)) {
    if (!inboxItem.spec.has_been_read) {
      return true;
    }
  }
  return false;
}

export function shouldOpenUnreadSurveyMessage(inboxItem: InboxItem): boolean {
  if (isUnreadSurveyMessage(inboxItem)) {
    // Number between 0 and 1:
    const probability = inboxItem.status?.message?.push_probability !== undefined ? inboxItem.status.message.push_probability : 0;
    if (!probability) {
      return false;
    }
    // Number between 0 and 1:
    const randomNumber = Math.random();
    if (probability >= randomNumber) {
      return true;
    }
  }
  return false;
}

export function needToSetSurveyMessageLastTimeChecked(): boolean {
  const surveyMessageLastTimeChecked = localStorage.getItem(getSurveyMessageLastCheckedStorageKey());
  if (!surveyMessageLastTimeChecked) {
    // Has not been set, so need to set
    return true;
  }
  const surveyMessageLastTimeCheckedAsNumber = parseInt(surveyMessageLastTimeChecked, 10);
  const oneDayInMilliseconds = 86400000;
  return Date.now() > oneDayInMilliseconds + surveyMessageLastTimeCheckedAsNumber;
}

export function setSurveyMessageLastTimeChecked(): void {
  const currentDateInMilliseconds = Date.now();
  localStorage.setItem(getSurveyMessageLastCheckedStorageKey(), currentDateInMilliseconds.toString());
}

export function shouldAddDeepLink(url: string): boolean {
  return url.includes('agilicus.com');
}

<div class="cards flex-row-wrap flex-layout-gap-2px">

  <mat-card
    appearance="outlined"
    class="flex-xs-80 flex-sm-60 flex-md-33 flex-lg-25"
    style="margin:2em;margin-bottom:2em;"
    *ngFor="let rss of rssItems | async"
  >
    <mat-card-header>
      <mat-card-title>
        <a href="{{rss.url}}?utm_source=portal" target="_blank"> {{rss.title}} </a>
      </mat-card-title>
      <mat-card-subtitle>
        {{rss.date_published}}
      </mat-card-subtitle>
    </mat-card-header>
    <a class="mat-card-image" href="{{rss.url}}?utm_source=portal" target="_blank">
      <img class="rss" src="{{rss.image}}" alt="img">
    </a>
    <mat-card-content>
      <p>
        {{rss.summary}}
      </p>
    </mat-card-content>

    <mat-card-actions>

      <button mat-button>
        <a target=_blank href="https://twitter.com/share?url={{rss.url}}?utm_source=portal&text={{rss.title}}">
          <fa-icon [icon]="['fab','twitter']"></fa-icon>
        </a>
      </button>

      <button mat-button>
        <a target=_blank href="http://www.reddit.com/submit?url={{rss.url}}?utm_source=portal&title={{rss.title}}">
          <fa-icon [icon]="['fab','reddit']"></fa-icon>
        </a>
      </button>

      <button mat-button>
        <a target=_blank href="http://www.linkedin.com/shareArticle?mini=true&url={{rss.url}}?utm_source=portal&title={{rss.title}}">
          <fa-icon [icon]="['fab','linkedin']"></fa-icon>
        </a>
      </button>

    </mat-card-actions>

  </mat-card>

</div>
<div
  class="table-layout-container-all"
  [ngClass]="{
    'full-width-table': fullWidthTable
  }"
>
  <div *ngIf="showPageInfoFunc()" class="page-info-container">
    <portal-page-info
      [pageTitle]="pageTitle"
      [pageDescriptiveText]="pageDescriptiveText"
      [productGuideLink]="productGuideLink"
      [pageInfoWidth]="pageInfoWidth"
      [titleSize]="titleSize"
    >
    </portal-page-info>
  </div>

  <div *ngIf="showTableControls" class="table-controls-container">
    <portal-table-control-buttons
      *ngIf="!fixedTable"
      [rowObjectName]="rowObjectName"
      [doesNewRowExist]="checkIfNewRowExists()"
      [disableAddNewRow]="disableAddNewRow"
      [isAtLeastOneSelected]="isAtLeastOneRowSelected(dataSource.data)"
      [buttonsToShow]="buttonsToShow"
      [doNotPluralizeRowObjectName]="doNotPluralizeRowObjectName"
      [resetButtonTooltipText]="resetButtonTooltipText"
      (addNewRowToTable)="addNewRowToTable()"
      (addNewEntryFocus)="addNewEntryFocus(rowObjectName)"
      (deleteRows)="deleteRowsFunc()"
      (enableRows)="enableRows()"
      (disableRows)="disableRows()"
      (resetRows)="resetRows()"
      (uploadFile)="uploadFileEvent($event)"
      (approveRows)="approveRows()"
      (rejectRows)="rejectRows()"
    >
    </portal-table-control-buttons>
    <div class="custom-buttons" *ngFor="let customButton of customButtons">
      <portal-table-button
        buttonColor="{{ customButton.buttonColor }}"
        buttonName="{{ customButton.buttonName }}"
        tooltipText="{{ customButton.tooltipText }}"
        ariaLabelText="{{ customButton.ariaLabelText }}"
        disabledButtonTooltipText="{{ customButton.disabledButtonTooltipText(dataSource.data) }}"
        disabledButtonAriaLabelText="{{ customButton.disabledButtonAriaLabelText }}"
        [disableButton]="customButton.isDisabled(dataSource.data)"
        [disableTooltip]="customButton.isDisabled(dataSource.data)"
        [hideButton]="customButton.isHidden(dataSource.data)"
        (buttonClick)="customControlFunc(customButton)"
      >
      </portal-table-button>
    </div>
    <portal-dropdown-selector
      *ngIf="displayDropdownSelector()"
      [dropdownSelector]="dropdownSelector"
      [hasBlankOption]="hasBlankOption"
    >
    </portal-dropdown-selector>
  </div>

  <portal-demo-setup
    *ngIf="showDemoButtons"
    [tourAnchorId]="tourAnchorId"
    [overrideShowDemoConnectorCheck]="overrideShowDemoConnectorCheck"
    [overrideApiResourceCheckValue]="overrideApiResourceCheckValue"
    [delayShowDemo]="delayShowDemo"
    showButtonsOnly="true"
    (startTourInParent)="startDemoTour()"
  >
  </portal-demo-setup>

  <portal-table-filter
    [hideFilter]="hideFilter"
    [filterMenuOptions]="filterMenuOptions"
    [filterManager]="filterManager"
    [paramSpecificFilterManager]="paramSpecificFilterManager"
    [columnDefs]="columnDefs"
    [dataSource]="dataSource"
    [useBackendFilter]="useBackendFilter"
    [keyTabManager]="keyTabManager"
    [isParentTable]="isParentTable"
    [fixedTable]="fixedTable"
    [preventLegacyTableView]="preventLegacyTableView"
    (filterSearchDelay)="filterSearchDelayEventFunc($event)"
    (filterBySearchParam)="filterBySearchParamEventFunc()"
    (updateDisplayType)="updateDisplayTypeEventFunc($event)"
    (updateFreezeHeader)="updateFreezeHeaderEventFunc($event)"
    (updateAlternateRowColor)="updateAlternateRowColorEventFunc($event)"
  >
  </portal-table-filter>

  <button
    #rightClickMenuTrigger="matMenuTrigger"
    mat-icon-button
    [matMenuTriggerFor]="rightClickMenu"
    [id]="rightClickMenuId"
    style="height: 0; width: 0; padding: 0; position: fixed;"
  >
  </button>

  <mat-menu
    #rightClickMenu="matMenu"
    xPosition="after"
    yPosition="below"
  >
    <div class="button-container">
      <button
        mat-menu-item
        (click)="onCollapseColumn()"
      >
        Hide column
      </button>
    </div>
  </mat-menu>

  <div
    class="table-container mat-elevation-z8 resizable-table"
    [ngClass]="{
      'medium-row-height': displayType === tableDisplayEnum.medium,
      'relaxed-row-height': displayType === tableDisplayEnum.relaxed,
      'legacy-table-display': displayType === tableDisplayEnum.legacy,
      'freeze-header': freezeHeaderRow,
      'alternate-row-color-container': alternateRowColor
    }"
  >
    <table
      mat-table
      #tableSort="matSort"
      [dataSource]="dataSource"
      matSort
      spellcheck="false"
      multiTemplateDataRows
      [ngClass]="{
        'freeze-left-columns': freezeLeftColumns
      }"
    >
      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.SELECT_ROW)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'add-extra-header-padding': addExtraHeaderPadding,
            'large-header': largeHeaders,
            'small-header': column.inputSize === inputSize.XSMALL
          }"
          portalColumnResize
        >
          <mat-checkbox
            (change)="$event ? masterToggle() : null"
            [checked]="areAllSelected()"
            [indeterminate]="isAtLeastOneRowSelected(dataSource.data) && !areAllSelected()"
            [matTooltip]="selectAllRowsTooltipText"
            aria-label="Checkbox that selects all rows for removal from the table"
            (keydown.enter)="preventDefaultKeyDownEvent($event)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          >
          </mat-checkbox>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="checkbox checkbox-column-td"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'select-row-cell': column.inputSize === inputSize.SELECT_ROW,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="$event ? toggleIsChecked(element) : null"
            [checked]="element.isChecked"
            [matTooltip]="getRowDeletionTooltipText(element, column)"
            aria-label="Checkbox that selects rows for removal from the table"
            [disabled]="column.disableField(element)"
            (keydown.enter)="preventDefaultKeyDownEvent($event)"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.ICON)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders,
            'small-header': column.inputSize === inputSize.XSMALL
          }"
          portalColumnResize
        >
          {{ column.displayName }}
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="icon-row"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
          matTooltip="{{ column.getTooltip(element) }}"
          [matTooltipDisabled]="column.getTooltip(element) === ''"
        >
          <span
            *ngIf="!!column.getLink && !!column.getLink(element) && (!column.isImageLink || !column.isImageLink(element))"
            class="table-icon-container"
          >
            <a
              [href]="column.getLink(element)"
              target="_blank"
              class="unstyled-link"
              [ngClass]="{ 'clickable': !!column.getLink }"
            >
              <mat-icon>{{ column.getDisplayValue(element) }}</mat-icon>
            </a>
          </span>
          <span
            *ngIf="!!column.getLink && !!column.getLink(element) && !!column.isImageLink && !!column.isImageLink(element)"
            class="table-icon-container"
          >
            <img
              alt="icon"
              [src]="column.getLink(element)"
              width="24"
              height="24"
              class="clickable"
              (click)="column.onImageClick(element)"
            />
          </span>
          <span
            *ngIf="!column.getLink || !column.getLink(element)"
            class="table-icon-container"
          >
            <mat-icon
              *ngIf="!!column.onImageClick"
              class="clickable"
              (click)="column.onImageClick(element)"
            >
              {{ column.getDisplayValue(element) }}
            </mat-icon>
            <mat-icon *ngIf="!column.onImageClick">{{ column.getDisplayValue(element) }}</mat-icon>
          </span>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.INPUT)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'medium-cell': column.inputSize === inputSize.MEDIUM,
            'small-cell': column.inputSize === inputSize.SMALL,
            'x-small-cell': column.inputSize === inputSize.XSMALL,
            'date-cell': column.inputSize === inputSize.DATE,
            'readonly-input-field': inputIsReadonly(element, column),
            'grey-out-input-field': greyOutInput(element, column),
            'text-input-cell': column.inputSize === inputSize.TEXT_INPUT,
            'text-input-large-cell': column.inputSize === inputSize.TEXT_INPUT_LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
          matTooltip="This is a required field"
          [matTooltipDisabled]="!(element.isNew && column.requiredField(element, column))"
        >
          <div
            [matTooltip]="column.getTooltip(element, column)"
          >
            <mat-form-field [appearance]="getTableInputAppearance()">
              <input
                matInput
                type="{{ column.inputType }}"
                value="{{ column.getDisplayValue(element) }}"
                (blur)="onFormFieldEdit($event.target, element, column)"
                class="{{ getFocusClass(rowObjectName) }}"
                [readonly]="inputIsReadonly(element, column)"
                (keydown.enter)="preventDefaultKeyDownEvent($event)"
                (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                autocomplete="off"
                #inputTarget
              />
              <span>
                <mat-icon
                  *ngIf="column.inputType && column.inputType === 'password'"
                  class="visibility-icon"
                  [matTooltip]="tooltip"
                  (click) = "changePasswordVisibility(column)"
                >
                visibility
                </mat-icon>

                <mat-icon
                  *ngIf="column.inputType && column.inputType === 'text'"
                  class="visibility-icon"
                  [matTooltip]="tooltip"
                  (click) = "changePasswordVisibility(column)"
                >
                visibility_off
                </mat-icon>
              </span>
              <span *ngIf="column.hasIconPrefix" matPrefix>
                <mat-icon 
                  [ngClass]="{ 
                    'success-color': column.getIconColor(element) === iconColor.success,
                    'intermediate-color': column.getIconColor(element) === iconColor.intermediate,
                    'warn-color': column.getIconColor(element) === iconColor.warn,
                    'disabled-color': column.getIconColor(element) === iconColor.disabled
                  }"
                >
                  {{ column.getIconPrefix(element) }}
                </mat-icon>
              </span>
              <button
                *ngIf="column.copyToClipboard && column.getDisplayValue(element)"
                matSuffix
                mat-raised-button
                color="primary"
                class="copy-button"
                matTooltip="Copy '{{ inputTarget.value }}'"
                [ngxClipboard]="inputTarget"
              >
                <mat-icon>content_copy</mat-icon>
              </button>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.INPUT_LINK)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'readonly-input-field': inputIsReadonly(element, column),
            'grey-out-input-field': greyOutInput(element, column),
            'text-input-cell': column.inputSize === inputSize.TEXT_INPUT,
            'text-input-large-cell': column.inputSize === inputSize.TEXT_INPUT_LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
        >
          <mat-form-field
            appearance="outline"
            (click)="column.onClick(element)"
            class="clickable"
            matTooltip="Click to view/modify {{ column.getDisplayValue(element) }}"
            aria-label="Clickable link to view/modify the selected application"
          >
            <input
              matInput
              value="{{ column.getDisplayValue(element) }}"
              class="{{ getFocusClass(rowObjectName) }} clickable"
              [readonly]="!column.isEditable || column.disableField(element)"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
              autocomplete="off"
            />
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.AUTOINPUT)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'text-input-cell': column.inputSize === inputSize.TEXT_INPUT,
            'text-input-large-cell': column.inputSize === inputSize.TEXT_INPUT_LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn(),
            'readonly-input-field': inputIsReadonly(element, column),
          }"
          matTooltip="This is a required field"
          [matTooltipDisabled]="!(element.isNew && column.requiredField(element, column))"
        >
          <div
            matTooltip="{{ column.getDisplayValue(element) }}"
          >
            <mat-form-field
              *ngIf="column.isReadOnly(element)"
              appearance="outline"
            >
              <input
                matInput
                [value]="column.getDisplayValue(element)"
                readonly="true"
                (keydown.enter)="preventDefaultKeyDownEvent($event)"
                (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                autocomplete="off"
              />
            </mat-form-field>
          </div>

          <mat-form-field
            *ngIf="!column.isReadOnly(element)"
            appearance="outline"
          >
            <input
              #autoinput
              matInput
              [formControl]="element[column.name]"
              [matAutocomplete]="auto"
              class="{{ getFocusClass(rowObjectName) }}"
              autocomplete="off"
              #triggerAutoInput="matAutocompleteTrigger"
              (blur)="onAutoInputFormFieldEdit($event.target, element, column, autoinput.id)"
              (keydown.enter)="preventDefaultKeyDownEvent($event)"
              (keyup.enter)="keyTabManager.keyTabTableAutoInput($event.target.id, triggerAutoInput)"
            />
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="updateInputOnAutoInputSelection($event.option.value, autoinput)"
            >
              <span *ngFor="let option of getSortedFiltredAutocompleteInputValues(column, column.getFilteredValues(element, column) | async)">
                <mat-option
                  [value]="column.getDisplayValue(option)"
                  *ngIf="!isOptionAlreadySelected(element, column, column.getDisplayValue(option))"
                >
                  {{ column.getDisplayValue(option) }}
                </mat-option>
              </span>
            </mat-autocomplete>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.SELECT)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          class="table-dropdown-select-td"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'medium-cell': column.inputSize === inputSize.MEDIUM,
            'small-cell': column.inputSize === inputSize.SMALL,
            'x-small-cell': column.inputSize === inputSize.XSMALL,
            'select-input-cell': column.inputSize === inputSize.SELECT_INPUT,
            'table-has-chiplist-column': tableHasChiplistColumn(),
            'readonly-input-field': inputIsReadonly(element, column)
          }"
          matTooltip="This is a required field"
          [matTooltipDisabled]="!(element.isNew && column.requiredField(element, column))"
        >
          <mat-form-field
            appearance="outline"
            *ngIf="!column.hideOptions(element, column)"
          >
            <mat-select
              #select
              (selectionChange)="
                onSelection({
                  value: $event.value,
                  column: column,
                  element: element
                })
              "
              (openedChange)="onSelectionToggle($event, column, element)"
              [value]="getSelectionDisplayValue(column, element)"
              [disabled]="!column.isEditable || column.disableField(element)"
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
              matTooltip="{{ column.getTooltip(element) }}"
              [matTooltipDisabled]="column.getTooltip(element) === ''"
              [multiple] = "column.multiple"
              class="{{ getFocusClass(rowObjectName) }}"
            >
              <mat-option
                *ngFor="let value of getTableSelectColumnAllowedValues(column, element)"
                [value]="column.getOptionValue(value, element)"
                [disabled]="column.disableOption(element, column, value)"
              >
                {{ capitalizeFirstLetter(column.getOptionDisplayValue(value, element)) }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.CHIPLIST)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          class="chiplist-header"
          portalColumnResize
        >
            {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
            <mat-icon
              *ngIf="!!getHeaderTooltipText(column)"
              [matTooltip]="getHeaderTooltipText(column)"
              class="column-tooltip-icon"
            >
              help_outline
            </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE && column.isEditable,
            'medium-cell': column.inputSize === inputSize.MEDIUM && column.isEditable,
            'small-cell': column.inputSize === inputSize.SMALL && column.isEditable,
            'x-small-cell': column.inputSize === inputSize.XSMALL,
            'in-error': element.dirty && column.inError,
            'table-has-chiplist-column': tableHasChiplistColumn(),
            'readonly-input-field': inputIsReadonly(element, column)
          }"
          matTooltip="This is a required field"
          [matTooltipDisabled]="!(element.isNew && column.requiredField(element, column))"
        >
          <portal-custom-chiplist-input
            #chiplistInput
            [chiplistInput]="column"
            [element]="element"
            appearance="outline"
            [isChipRemovable]="isChipRemovable(column, element)"
            [keyTabManager]="keyTabManager"
            [removeFromAllowedValues]="removeFromAllowedValues"
            [isTableColumn]="chiplistIsTableColumn"
            [customInputClass]="getChiplistCustomInputClass()"
            (removeChip)="removeChip($event, element, column)"
            (triggerChangeDetectionInParentComponent)="triggerChangeDetectionFromParentComponent()"
            (triggerRowDirtyEvent)="triggerRowDirtyEventFunc($event)"
            (forceRowUpdateEvent)="updateRow($event)"
          >
          </portal-custom-chiplist-input>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.ACTIONS)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name
          }"
          portalColumnResize
        >
          {{ column.displayName }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'medium-cell': column.inputSize === inputSize.MEDIUM,
            'small-cell': column.inputSize === inputSize.SMALL,
            'actions-cell': column.inputSize === inputSize.ACTIONS,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
        >
          <button
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Icon-button with a menu to choose an action for the selected row"
            [matTooltip]="column.getTooltip(element)"
            [disabled]="column.disableField(element)"
            class="actions-button"
            (click)="$event.stopPropagation()"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div *ngFor="let value of column.allowedValues">
              <div *ngIf="!value.fileReplace">
                <button
                  mat-menu-item
                  (click)="onActionClick(element, value)"
                  [matTooltip]="value.tooltip"
                  [disabled]="value.isDisabled && value.isDisabled(element)"
                  [hidden]="hideActionMenuOption(value, element)"
                >
                  <mat-icon>{{ value.icon }}</mat-icon>
                  <span>{{ value.displayName }}</span>
                </button>
              </div>
              <div
                *ngIf="value.fileReplace"
                class="upload-button-wrapper"
              >
                <button
                  mat-menu-item
                  class="upload-button"
                >
                  <mat-icon>{{ value.icon }}</mat-icon>
                  <span>{{ value.displayName }}</span>
                </button>
                <input
                  type="file"
                  name="upload-file"
                  id="input-file"
                  title=" "
                  (change)="replaceFileAction($event, element)"
                  matTooltip="{{ value.tooltip }}"
                  aria-label="Input that uploads a file"
                  autocomplete="off"
                  portalDisableKeyTab
                />
              </div>
            </div>
          </mat-menu>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.CHECK)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'medium-cell': column.inputSize === inputSize.MEDIUM,
            'small-cell': column.inputSize === inputSize.SMALL,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
          [matTooltip]="column.getTooltip(element, column)"
          [matTooltipDisabled]="!column.getTooltip(element, column)"
          class="checkbox-column-td"
        >
          <mat-checkbox
            (click)="$event.stopPropagation()"
            (change)="onCheckboxUpdate(column, element, $event.checked)"
            [checked]="column.isChecked(element)"
            [indeterminate]="column.isIndeterminate(element)"
            class="check-column-checkbox"
            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
            [disabled]="!column.isEditable || column.disableField(element)"
          >
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.FILE)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn(),
            'readonly-input-field': inputIsReadonly(element, column)
          }"
          matTooltip="This is a required field"
          [matTooltipDisabled]="!(element.isNew && column.requiredField(element, column))"
        >
          <span
            *ngIf="isTextFile(element.fileType) && element.downloaded"
          >
            <mat-form-field appearance="outline">
              <textarea
                matInput
                value="{{ column.getDisplayValue(element) }}"
                (blur)="onFormFieldEdit($event.target, element, column)"
                [disabled]="!column.isEditable || column.disableField(element)"
                (keyup.control.enter)="keyTabManager.keyTab($event.target.id)"
              >
              </textarea>
            </mat-form-field>
          </span>
          <span
            *ngIf="isImageFile(element.fileType) && element.downloaded"
          >
            <div class="table-image-container">
              <img
                src={{element.fileContent}}
                class="table-image"
              />
            </div>
          </span>
          <span
            *ngIf="isNotTextOrImageFile(element.fileType) || !element.downloaded"
            (click)="column.downloadFile(element)"
            class="file-download"
            matTooltip="Click to download the file"
          >
            <mat-icon class="file-attachment">attach_file</mat-icon>
            <span class="file-type">{{ element.fileType }}</span>
          </span>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.DATE_PICKER)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          mat-sort-header
          (contextmenu)="onHeaderRightClick($event, column)"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-header': largeHeaders
          }"
          portalColumnResize
        >
          {{ column.displayName }} <span *ngIf="column.requiredField(element, column)">*</span>
          <mat-icon
            *ngIf="!!getHeaderTooltipText(column)"
            [matTooltip]="getHeaderTooltipText(column)"
            class="column-tooltip-icon"
          >
            help_outline
          </mat-icon>
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'new-row-required-field': element.isNew && column.requiredField(element, column),
            'large-cell': column.inputSize === inputSize.LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn(),
            'readonly-input-field': inputIsReadonly(element, column)
          }"
          [matTooltip]="column.getTooltip(element, column)"
          [matTooltipDisabled]="column.getTooltip(element, column) === ''"
          [ngClass]="{
            'warn-of-value': column.warnValue(element),
          }"
        >
          <div
            [matTooltip]="column.getTooltip(element, column)"
          >
            <mat-form-field [appearance]="getTableInputAppearance()">
              <input
                matInput
                [min]="column.minDate"
                [owlDateTime]="dt1"
                [owlDateTimeTrigger]="dt1"
                [formControl]="element[column.name]"
                (focus)="trackDateField(element, column)"
                (blur)="onDatePickerInputFormFieldEdit($event.target, element, column)"
                class="{{ getFocusClass(rowObjectName) }}"
                [hidden]="!column.isEditable || column.disableField(element)"
                (keydown.enter)="preventDefaultKeyDownEvent($event)"
                (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                autocomplete="off"
              />
              <owl-date-time
                #dt1
                [disabled]="!column.isEditable || column.disableField(element)"
              >
              </owl-date-time>
            </mat-form-field>
          </div>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.READONLY)"
      >
        <ng-container *ngIf="column.showColumn">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            (contextmenu)="onHeaderRightClick($event, column)"
            [ngClass]="{
              'first-column': getFirstColumnName() === column.name,
              'last-column': getLastColumnName() === column.name,
              'large-header': largeHeaders
            }"
            portalColumnResize
          >
            {{ column.displayName }}
            <mat-icon
              *ngIf="column.getHeaderTooltip"
              [matTooltip]="column.getHeaderTooltip()"
              class="column-tooltip-icon"
            >
              help_outline
            </mat-icon>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            [matTooltip]="column.getTooltip(element, column)"
            [matTooltipDisabled]="column.getTooltip(element, column) === ''"
            [ngClass]="{
              'first-column': getFirstColumnName() === column.name,
              'last-column': getLastColumnName() === column.name,
              'large-cell': column.inputSize === inputSize.LARGE,
              'medium-cell': column.inputSize === inputSize.MEDIUM,
              'small-cell': column.inputSize === inputSize.SMALL,
              'date-cell': column.inputSize === inputSize.DATE,
              'warn-of-value': column.warnValue(element),
              'table-has-chiplist-column': tableHasChiplistColumn()
            }"
          >
            <mat-icon 
              *ngIf="column.hasIconPrefix"
              [ngClass]="{ 
                'success-color': column.getIconColor(element) === iconColor.success,
                'intermediate-color': column.getIconColor(element) === iconColor.intermediate,
                'warn-color': column.getIconColor(element) === iconColor.warn,
                'disabled-color': column.getIconColor(element) === iconColor.disabled
              }"
            >
              {{ column.getIconPrefix(element) }}
            </mat-icon>
            {{ column.getDisplayValue(element, column) }}
          </td>
        </ng-container>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.EXPAND)"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name
          }"
          portalColumnResize
        >
          {{ column.displayName }}
        </th>
        <td
          mat-cell
          *matCellDef="let element"
          [ngClass]="{
            'first-column': getFirstColumnName() === column.name,
            'last-column': getLastColumnName() === column.name,
            'large-cell': column.inputSize === inputSize.LARGE,
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
        >
          <a
            class="expand-icon"
            (click)="$event.stopPropagation(); toggleRowExpansionIcon(element, column)"
            [matTooltip]="column.getTooltip(element, column)"
            [matTooltipDisabled]="column.disableField(element)"
            [ngClass]="{ 'disable-expander': column.disableField(element) }"
          >
            <mat-icon *ngIf="!element.isRowExpanded">chevron_right</mat-icon>
            <mat-icon *ngIf="element.isRowExpanded">expand_more</mat-icon>
          </a>
        </td>
      </ng-container>

      <ng-container
        [matColumnDef]="column.name"
        [sticky]="!!column.sticky"
        *ngFor="let column of getColumnDefs(columnDefs, columnTypes.COLLAPSED)"
      >
        <ng-container *ngIf="column.showColumn">
          <th
            mat-header-cell
            *matHeaderCellDef
            class="collapsed-column-header"
            [ngClass]="{
              'first-column': getFirstColumnName() === column.name,
              'last-column': getLastColumnName() === column.name
            }"
            portalColumnResize
          >
            <span class="expand-column-icon" (click)="onExpandColumn(column)" matTooltip="Click to expand hidden columns">
              <mat-icon style="transform: rotate(90deg)">unfold_more</mat-icon>
            </span>
          </th>
          <td
            mat-cell
            *matCellDef="let element"
            class="collapsed-column-data"
            [ngClass]="{
              'first-column': getFirstColumnName() === column.name,
              'last-column': getLastColumnName() === column.name,
              'table-has-chiplist-column': tableHasChiplistColumn()
            }"
          >
          </td>
        </ng-container>
      </ng-container>

      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail" *ngIf="!!hasNestedTable">
        <td
          mat-cell
          *matCellDef="let element"
          [attr.colspan]="getDisplayedColumnNames(columnDefs).length"
          class="expanded-cell"
          [ngClass]="{
            'table-has-chiplist-column': tableHasChiplistColumn()
          }"
        >
          <div
            class="expanded-element-detail"
            [ngClass]="{ 'expanded-element-detail-flex': !element.nestedFormColumnDefs }"
            [@detailExpand]="element?.index === expandedElementId ? 'expanded' : 'collapsed'"
            (focusout)="handleNestedRowBlur($event, element)"
          >
            <div class="inner-form" *ngIf="!!element.nestedFormColumnDefs">
              <portal-form-layout
                #nestedFormLayout
                [columnDefs]="element.nestedFormColumnDefs"
                [element]="element"
                [hidden]="element.hideNestedForm !== undefined ? element.hideNestedForm(element) : false"
                (updateSelection)="updateSelectionEventFunc($event)"
                (updateMultipleSelection)="updateMultipleSelectionEventFunc($event)"
              >
              </portal-form-layout>
            </div>
            <div class="inner-table mat-elevation-z8" *ngIf="showNestedTable(element)">
              <portal-table-layout
                #nestedTable
                [tableData]="element.expandedData.nestedTableData"
                [columnDefs]="element.expandedData.nestedColumnDefs"
                [filterManager]="element.expandedData.nestedFilterManager"
                [keyTabManager]="keyTabManager"
                [fixedTable]="element.expandedData.fixedTable"
                [hideFilter]="element.expandedData.hideNestedFilter"
                [fullWidthTable]="element.expandedData.fullWidthTable"
                [showTableControls]="element.expandedData.showTableControls"
                [showPageInfo]="element.expandedData.showPageInfo"
                [buttonsToShow]="element.expandedData.nestedButtonsToShow"
                [customButtons]="element.expandedData.customButtons"
                [addExtraHeaderPadding]="true"
                [makeEmptyTableElement]="element.expandedData.makeEmptyNestedTableElement"
                [rowObjectName]="element.expandedData.nestedRowObjectName"
                [isParentTable]="nestedTableIsParentTable"
                [hasNestedTable]="nestedTableHasNestedTable"
                [alternateRowColor]="alternateRowColor"
                (updateEvent)="updateEventFunc($event)"
                (removeSelected)="removeSelectedEventFunc($event)"
                (enableSelected)="enableSelectedEventFunc($event)"
                (disableSelected)="disableSelectedEventFunc($event)"
                (resetSelected)="resetSelectedEventFunc($event)"
                (refreshDataSource)="refreshDataSourceEventFunc()"
                (updateSelection)="updateSelectionEventFunc($event)"
                (updateMultipleSelection)="updateMultipleSelectionEventFunc($event)"
                (updateAutoInput)="updateAutoInputEventFunc($event)"
                (updateDatePickerInput)="updateDatePickerInputEventFunc($event)"
                (selectionClose)="selectionCloseEventFunc($event)"
                (uploadFile)="uploadFileEventEvent($event)"
                (addFiles)="addFilesEventFunc($event)"
                (replaceFile)="replaceFileEventFunc($event)"
                (approveSelected)="approveSelectedEventFunc($event)"
                (rejectSelected)="rejectSelectedEventFunc($event)"
                (filterSearchDelay)="filterSearchDelayEventFunc($event)"
                (filterBySearchParam)="filterBySearchParamEventFunc($event)"
                (triggerRowDirtyEvent)="triggerRowDirtyEventFunc($event)"
                (triggerRowCheckedEvent)="triggerRowCheckedEventFunc()"
                >
              </portal-table-layout>
            </div>
          </div>
        </td>
      </ng-container>

      <div
        *ngIf="!dragAndDropRows && !dragAndDropHeaderOnly"
        class="tr-container"
      >
        <tr
          mat-header-row
          *matHeaderRowDef="getDisplayedColumnNames(columnDefs); sticky: true"
          [ngClass]="{
            'header-border': largeHeaders
          }"
        >
        </tr>
        
        <div *ngIf="!hasNestedTable">
          <tr
            tabindex="0"
            mat-row
            *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs)"
            (focusout)="delayAndUpdateRow($event, row)"
            [hidden]="!row.showRow"
            class="alternate-row-color"
            [ngClass]="{
              'disabled-row': row.enabled === undefined ? false : !row.enabled,
              'highlighted-row': row.isHighlightedRow,
              'clickable': selectOnRowClick
            }"
            (click)="selectOnRowClick ? toggleIsChecked(row) : null"
          >
          </tr>
        </div>
        
        <div *ngIf="!!hasNestedTable">
          <tr
            tabindex="0"
            mat-row
            *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs); let dataIndex = dataIndex;"
            (focusout)="delayAndUpdateRow($event, row)"
            [hidden]="!row.showRow"
            [ngClass]="{
              'expandable-row': isExpandableRow(row),
              'disabled-row': row.enabled === undefined ? false : !row.enabled,
              'alternate-row-color-parent-table': isEvenRow(dataIndex),
              'highlighted-row': row.isHighlightedRow,
            }"
            [class.expanded-row]="expandedElementId === row?.index"
            (click)="toggleRowOnRowClick(row)"
            class="nested-table-expanded-row"
          >
          </tr>

          <tr
            mat-row
            *matRowDef="let row; columns: ['expandedDetail']"
            class="expanded-detail-row"
          >
          </tr>
        </div>
      </div>

      <div
        *ngIf="dragAndDropRows"
        class="tr-container"
      >
        <tr
          mat-header-row
          *matHeaderRowDef="getDisplayedColumnNames(columnDefs); sticky: true"
          portalDroppable (handleOnDrop)="handleAddDrop($event)"
          [ngClass]="{
            'header-border': largeHeaders
          }"
        >
        </tr>
        <tr
          mat-row
          *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs); let i = index"
          (focusout)="delayAndUpdateRow($event, row)"
          [hidden]="!row.showRow"
          class="alternate-row-color"
          [ngClass]="{
            'disabled-row': row.enabled === undefined ? false : !row.enabled,
            'highlighted-row': row.isHighlightedRow,
            'clickable': selectOnRowClick
          }"
          portalDroppable (handleOnDrop)="handleReplaceDrop($event, i)"
          (click)="selectOnRowClick ? toggleIsChecked(row) : null"
        >
        </tr>
      </div>

      <div
        *ngIf="dragAndDropHeaderOnly"
        class="tr-container"
      >
        <tr
          mat-header-row
          *matHeaderRowDef="getDisplayedColumnNames(columnDefs); sticky: true"
          portalDroppable (handleOnDrop)="handleAddDrop($event)"
          [ngClass]="{
            'header-border': largeHeaders
          }"
        >
        </tr>
        <tr
          mat-row
          *matRowDef="let row; columns: getDisplayedColumnNames(columnDefs); let i = index"
          (focusout)="delayAndUpdateRow($event, row)"
          [hidden]="!row.showRow"
          class="alternate-row-color"
          [ngClass]="{
            'disabled-row': row.enabled === undefined ? false : !row.enabled,
            'highlighted-row': row.isHighlightedRow,
            'clickable': selectOnRowClick
          }"
          (click)="selectOnRowClick ? toggleIsChecked(row) : null"
        >
        </tr>
      </div>
    </table>
    <portal-table-paginator
      [paginatorConfig]="paginatorConfig"
      (updateTableData)="updateDataSource($event)"
      (doOnPageEvent)="doOnPageEventFunc()"
    >
    </portal-table-paginator>
  </div>
</div>

<h2>Configure Policy Condition</h2>
<div mat-dialog-content>
  <mat-vertical-stepper linear="true" #stepper>
    <mat-step [stepControl]="conditionTypeForm">
      <ng-template matStepLabel>Choose condition type</ng-template>
      <body>
        <form
          [formGroup]="conditionTypeForm"
          class="stepper-form"
          spellcheck="false"
          autocomplete="off"
        >
          <mat-form-field>
            <mat-label>Select condition type:</mat-label>
            <mat-select 
              #selectType
              formControlName="condition_type"
              (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectType)"
              (selectionChange)="onConditionTypeChange($event.value)"
            >
              <mat-option
                *ngFor="let conditionType of conditionTypeOptionsList"
                [value]="conditionType"
                >{{ formatConditionTypeForDisplay(conditionType) }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <div *ngIf="getConditionTypeValueFromForm() === policyConditionTypeEnum.object_attribute" class="field-step-container">
            <mat-vertical-stepper 
              linear
              #hostMethodStepper
              portalMatVerticalStepperScroller
              class="sub-stepper"
            >
              <mat-step [stepControl]="conditionTypeForm.get('field')">
                <ng-template matStepLabel>Choose field</ng-template>
                <mat-form-field>
                  <mat-label>Select field:</mat-label>
                  <mat-select 
                    #selectField
                    formControlName="field"
                    (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectField)"
                    (selectionChange)="onFieldChange($event.value)"
                  >
                    <mat-option
                      *ngFor="let field of fieldOptionsList"
                      [value]="field"
                      >{{ formatFieldForDisplay(field) }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </mat-step>
            </mat-vertical-stepper>
          </div>
        </form>
        <div class="nav-buttons">
          <button mat-button matStepperNext>Next</button>
        </div>
      </body>
    </mat-step>

    <mat-step [completed]="areDetailsCompleted()">
      <ng-template matStepLabel>Configure condition details</ng-template>
      <body>
        <!-- Disabled form from previous step for user reference only -->
        <form
          [formGroup]="conditionTypeFormReadonly"
          class="stepper-form"
          spellcheck="false"
          autocomplete="off"
        >
          <mat-form-field>
            <mat-label>Condition type:</mat-label>
            <input 
              matInput 
              type="text" 
              [value]="formatConditionTypeForDisplay(getConditionTypeValueFromForm())"
              disabled="true"
            >
          </mat-form-field>

          <div *ngIf="getConditionTypeValueFromForm() === policyConditionTypeEnum.object_attribute" class="field-step-container">
            <mat-form-field>
              <mat-label>Field:</mat-label>
              <input 
                matInput 
                type="text" 
                [value]="formatFieldForDisplay(getFieldValueFromForm())"
                disabled="true"
              >
            </mat-form-field>
          </div>
        </form>

        <form
          [formGroup]="conditionDetailsForm"
          class="stepper-form"
          spellcheck="false"
          autocomplete="off"
        >
            <mat-form-field>
              <mat-label>Select operator:</mat-label>
              <mat-select 
                #selectOperator
                formControlName="operator"
                (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectOperator)"
              >
                <mat-option
                  *ngFor="let operatorOption of getOperatorOptionValues()"
                  [value]="operatorOption"
                  >{{ formatOperatorOptionForDisplay(operatorOption) }}</mat-option
                >
              </mat-select>
            </mat-form-field>

            <portal-stepper-form-field
              *ngIf="showValueTextInput()"
              placeholder="Enter the value"
              [formCtrl]="conditionDetailsForm.get('value')"
            >
            </portal-stepper-form-field>

            <portal-custom-chiplist-input
              *ngIf="!showValueTextInput()"
              [chiplistInput]="getValueListChiplistInput()"
              [element]="data.condition"
              placeholder="Values"
              [filterChipOptions]="filterChipOptions"
              [isChipRemovable]="isFormChipRemovable(data.condition.value_list, conditionDetailsForm.get('value_list'), filterChipOptions.removable)"
              [keyTabManager]="keyTabManager"
              isTemplateGeneratedChiplistInput="true"
              customInputClass="policy-condition-dialog-values-chiplist-input"
              (removeChip)="removeValueChip($event)"
            >
            </portal-custom-chiplist-input>

            <div class="nav-buttons">
              <button mat-button matStepperPrevious>Back</button>
              <button mat-button matStepperNext (click)="checkDetailsStepAndNotifyUser()">Next</button>
            </div>
        </form>
      </body>
    </mat-step>

    <mat-step [completed]="!!advanceToDoneStep">
      <ng-template matStepLabel><div (click)="checkDetailsStepAndNotifyUser()">Apply</div></ng-template>
      <body>
        Click "Add" to save your policy condition. You will be able to view the condition's properties in the table at any time.
        <div class="nav-buttons">
          <button mat-button matStepperPrevious>Back</button>
          <button
            mat-raised-button
            color="primary"
            matTooltip="Click to save the policy condition"
            aria-label="A button to save the policy condition"
            [disabled]="addDisabled"
            (click)="savePolicyCondition()"
          >
            Add
          </button>
        </div>
      </body>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <body>
        <p>Your policy condition has been saved. You can view it in the table on the main Authentication Policy screen. 
          You may edit this condition at any time by selecting the action button in the right most column of the table and choosing "Configure Condition".
        </p>
      </body>
    </mat-step>
  </mat-vertical-stepper>

  <mat-dialog-actions align="end">
    <button mat-button (click)="onCloseClick()">CLOSE</button>
  </mat-dialog-actions>
</div>
<portal-page-info
  pageTitle="New SSH"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div
  *ngIf="hasAllPermissions() && !!currentSSHModel"
  class="stepper-container"
>
  <mat-vertical-stepper
    linear
    #sshStepper
    portalMatVerticalStepperScroller
    (selectionChange)="onStepperSelectionChange($event.selectedIndex)"
  >
    <mat-step [stepControl]="connectorFormGroup">
      <ng-template matStepLabel>Select connector</ng-template>
      <portal-connector-selection
        [connectorFormGroup]="connectorFormGroup"
        [connectors]="connectors"
        (updateModel)="updateConnector($event)"
      >
      </portal-connector-selection>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="sshNameFormGroup">
      <ng-template matStepLabel>SSH name</ng-template>
      <form
        [formGroup]="sshNameFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the SSH name"
          label="My remote SSH will be called (a valid hostname)..."
          [formCtrl]="sshNameFormGroup.get('name')"
          required="true"
          (formBlur)="onFormBlur(sshNameFormGroup, 'name', getModel())"
          tooltip="This name uniquely identifies the SSH within this organisation."
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="addressFormGroup">
      <ng-template matStepLabel>Address</ng-template>
      <form
        [formGroup]="addressFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <portal-stepper-form-field
          placeholder="Enter the address"
          label="The address of the SSH in my remote network is..."
          [formCtrl]="addressFormGroup.get('address')"
          required="true"
          (formBlur)="onFormBlur(addressFormGroup, 'address', getModel())"
          tooltip="The hostname or IP of the SSH. A SSH Gateway will proxy requests from the client through to this address via the Connector associated with this gateway."
        >
        </portal-stepper-form-field>

        <portal-stepper-form-field
          placeholder="Enter the port"
          label="The port of the SSH in my remote network is..."
          [formCtrl]="addressFormGroup.get('port')"
          required="true"
          (formBlur)="setModelFromForms()"
          tooltip="The port of the SSH. A SSH Gateway will proxy requests from the client through to this port via the Connector associated with this gateway."
        >
        </portal-stepper-form-field>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [stepControl]="optionalConfigFormGroup">
      <ng-template matStepLabel>Advanced configuration (optional)</ng-template>
      <form
        [formGroup]="optionalConfigFormGroup"
        class="stepper-form"
        spellcheck="false"
        autocomplete="off"
      >
        <label
          class="radio-button-group-label"
        >
          I want my users to log in automatically when using the browser:
        </label>
        <mat-radio-group
          formControlName="optional_config"
          aria-labelledby="optional config radio buttons"
          class="optional-config-radio-group"
        >
          <mat-radio-button
            *ngFor="let option of optionalConfigOptions"
            class="optional-config-radio-button"
            [value]="option.value"
            (change)="onOptionalConfigChange(option.value)"
          >
            {{ option.name }}
          </mat-radio-button>
        </mat-radio-group>
        <div
          *ngIf="!!optionalConfigFormGroup.get('optional_config').value"
          class="optional-config-container"
        >
          <portal-stepper-form-field
            placeholder="Enter the username"
            label="My SSH server's default user is..."
            [formCtrl]="optionalConfigFormGroup.get('username')"
            (formBlur)="onFormBlur(optionalConfigFormGroup, 'username', getModel())"
            [tooltip]="getCredentialUsernameTooltipText()"
          >
          </portal-stepper-form-field>

          <div class="private-key-container">
            <mat-form-field class="textarea-field textarea-form-field">
              <mat-label>Private key</mat-label>
              <textarea 
                matInput
                [value]="getPrivateKeyValueFromForm()"
                formControlName="private_key"
                (blur)="setModelFromForms()"
              >
              </textarea>
              <button
                *ngIf="!!getPrivateKeyValueFromForm()"
                mat-button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearPrivateKey()"
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <div class="more-actions" style="display:inline-block; width: 5%">
              <button mat-icon-button [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="privateKeyUpload.click()">
                  Upload File
                </button>
                <input
                  hidden
                  type="file"
                  #privateKeyUpload
                  name="upload-file"
                  id="upload-file"
                  (change)="uploadPrivateKeyData($event)"
                  matTooltip="Upload private key pem file"
                  title=" "
                  aria-label="Input that uploads a file"
                  portalDisableKeyTab
                >
              </mat-menu>
            </div>
            <mat-icon
              class="help-icon private-key-help-icon"
              [matTooltip]="getCredentialPrivateKeyTooltipText()"
            >
              help_outline
            </mat-icon>
          </div>

          <mat-form-field>
            <mat-label>Enter the private key passphrase</mat-label>
            <input
              matInput
              showHideInput
              id="privateKeyPassphraseId" 
              type="password"
              formControlName="private_key_passphrase"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
              (blur)="setModelFromForms()"
              spellcheck="true"
            />
            <i 
              matSuffix
              class="material-icons"
              showHideTrigger="privateKeyPassphraseId"
              [showHideStatus]="{ materialIcon: true, id: 'privateKeyPassphraseId' }"
            ></i>
            <mat-error>Max length of {{ getSshCredentialPrivateKeyPassphraseMaxLength() }} exceeded</mat-error>
          </mat-form-field>
          <mat-icon
            class="help-icon"
            [matTooltip]="getCredentialPrivateKeyPassphraseTooltipText()"
          >
            help_outline
          </mat-icon>

          <mat-form-field>
            <mat-label>Enter the password</mat-label>
            <input
              matInput
              showHideInput
              id="passwordId" 
              type="password"
              formControlName="password"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
              (blur)="setModelFromForms()"
              spellcheck="true"
            />
            <i 
              matSuffix
              class="material-icons"
              showHideTrigger="passwordId"
              [showHideStatus]="{ materialIcon: true, id: 'passwordId' }"
            ></i>
            <mat-error>Max length of {{ getSshCredentialPasswordMaxLength() }} exceeded</mat-error>
          </mat-form-field>
          <mat-icon
            class="help-icon"
            [matTooltip]="getCredentialPasswordTooltipText()"
          >
            help_outline
          </mat-icon>

        </div>
      </form>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
        <button mat-button matStepperNext>Next</button>
      </div>
    </mat-step>

    <mat-step [completed]="isStepperComplete()">
      <ng-template matStepLabel>Apply</ng-template>
      <p class="descriptive-text">{{ completedSSHText }}</p>

      <div
        *ngIf="!!getModel()"
        class="summary-container"
      >
        <h4>Summary:</h4>
        <p>Connector: {{ getModel().connector_name }}</p>
        <p>SSH name: {{ getModel().name }}</p>
        <p>Address: {{ getModel().address }}</p>
        <p>Port: {{ getModel().config.ports[0].port }}</p>
        <p *ngIf="!!getModel().username">Username: {{ getModel().username }}</p>
      </div>

      <portal-apply-app-model
        [currentApplicationModel]="currentSSHModel"
        [appModelSubmissionProgressBarController]="appModelSubmissionProgressBarController"
        [appModelStatus]="sshModelStatus"
        [stepperType]="stepperType"
        (submitModel)="submitSSHModel($event)"
      >
      </portal-apply-app-model>

      <div class="stepper-nav-buttons">
        <button mat-button matStepperPrevious>Back</button>
      </div>
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <portal-stepper-done-config-options
        *ngIf="!!getModel().id"
        [resourceId]="getModel().id"
        [resourceName]="getModel().name"
        [resourceType]="resourceType"
        [orgId]="orgId"
      >
      </portal-stepper-done-config-options>
    </mat-step>

  </mat-vertical-stepper>
</div>

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChildren, QueryList } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import {
  Catalogue,
  CatalogueEntry,
  CataloguesService,
  Group,
  GroupsService,
  MFAChallengeAnswerResult,
  Policy,
  PolicyGroup,
  PolicyRule,
  PolicySpec,
} from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { AppState, selectPolicyState } from '@app/core';
import { Observable, Subject, combineLatest, of } from 'rxjs';
import { ActionPolicyDeletingPolicyGroup, ActionPolicySavingPolicy, ActionPolicySavingPolicyRule } from '@app/core/issuer/issuer.actions';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { catchError, concatMap, map, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { capitalizeFirstLetter, getDefaultMfaActionDisplayValue, getMFAChallengeAnswerResultOptions } from '../utils';
import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ChallengeType } from '../challenge-type.enum';
import { createDialogData } from '../dialog-utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { AuthPresetDialogComponent } from '../auth-preset-dialog/auth-preset-dialog.component';
import { applyPatch } from 'fast-json-patch';
import { initIssuer } from '@app/core/issuer-state/issuer.actions';
import { selectCurrentIssuer } from '@app/core/issuer-state/issuer.selectors';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';

export interface PresetConfigValues {
  multiFactorRequiredTime: string;
  sessionsValidTime: string;
  sharedSessionsBetweenApps: boolean;
  noMfaFromProviders: { checked: boolean; values: string[] };
  requireMfaFromApps: { checked: boolean; values: string[] };
  requireMfaFromGroups: { checked: boolean; values: string[] };
}

export interface MapPatchNames {
  multiFactorRequiredTime: string;
  sessionsValidTime: string;
  sharedSessionsBetweenApps: string;
  noMfaFromProviders: string;
  requireMfaFromApps: string;
  requireMfaFromGroups: string;
}

@Component({
  selector: 'portal-auth-issuer-policy',
  templateUrl: './auth-issuer-policy.component.html',
  styleUrls: ['./auth-issuer-policy.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthIssuerPolicyComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private issuersPermissions$: Observable<OrgQualifiedPermission>;
  private orgId: string;
  private issuerId: string;
  public policyCopy: Policy;
  public policyRulesList: Array<PolicyRule> = [];
  public mfaPolicyForm: UntypedFormGroup;
  public mfaPolicyCatalogueForm: UntypedFormGroup;
  public hasIssuersPermissions: boolean;
  private policyRuleIdToPolicyRuleMap: Map<string, PolicyRule> = new Map();
  public mFAChallengeAnswerResultOptions = getMFAChallengeAnswerResultOptions();
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public policyCatalogueEntries: Array<CatalogueEntry>;
  private policyCatalogueEntryIdToCatalogueEntryMap: Map<string, CatalogueEntry> = new Map();
  public authRulesDescriptiveText = '';
  public authRulesProductGuideLink = `https://www.agilicus.com/anyx-guide/authentication-rules/`;
  public mfaProductGuideLink = `https://www.agilicus.com/anyx-guide/multi-factor-authentication-cfg/`;
  public pageDescriptiveHelpImageWithTextWrap = 'assets/img/sign-in-screen.png';
  public currentOrgIssuer: string;
  public patches;
  public defaultSettings;
  public defaultCatalogueEntry = 'default';
  public groupIdToGroupMap: Map<string, Group> = new Map();
  public groupDisplayNameToGroupMap: Map<string, Group> = new Map();

  // This is required in order to reference the enums in the html template.
  public challengeType = ChallengeType;

  /**
   * A mapping of Group and Rule Panel Ids to whether that panel is open.
   */
  public panelsStateMap: Map<string, boolean> = new Map();

  public getDefaultMfaActionDisplayValue = getDefaultMfaActionDisplayValue;
  public capitalizeFirstLetter = capitalizeFirstLetter;

  public cdkDropTrackLists: Array<CdkDropList>;
  @ViewChildren('policyRuleList')
  set cdkDropLists(value: QueryList<CdkDropList>) {
    this.cdkDropTrackLists = value.toArray();
  }

  // This is required so that the html is not redrawn when the data is refreshed.
  public trackByGroupId = (index: number, policyGroup: PolicyGroup) => policyGroup?.metadata?.id;

  constructor(
    private store: Store<AppState>,
    private formBuilder: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private cataloguesService: CataloguesService,
    private dialog: MatDialog,
    private groupsService: GroupsService
  ) {}

  public ngOnInit(): void {
    this.store.dispatch(initIssuer({ force: true, blankSlate: false }));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    const policyState$ = this.store.pipe(select(selectPolicyState));
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    this.issuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const policyCatalogue$ = this.getPolicyCatalogue();
    combineLatest([policyState$, currentIssuer$, policyCatalogue$, this.getPermissionsAndGroups$()])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([policyStateResp, currentIssuerResp, policyCatalogueResp, permissionsAndGroupsResp]) => {
        const issuersPermissionsResp = permissionsAndGroupsResp[0];
        const groupsResp = permissionsAndGroupsResp[1];
        if (policyStateResp.saving_policy || policyStateResp.saving_policy_rule) {
          return;
        }
        this.setGroupMaps(groupsResp);
        this.policyCatalogueEntries = this.getPolicyCatalogueEntries(policyCatalogueResp);
        this.setPolicyCatalogueEntryIdToCatalogueEntryMap();
        this.policyCopy = cloneDeep(policyStateResp?.current_issuer_policy);
        this.policyRulesList = policyStateResp?.current_policy_rules_list;
        this.issuerId = currentIssuerResp?.id;
        this.hasIssuersPermissions = issuersPermissionsResp.hasPermission;
        this.orgId = issuersPermissionsResp.orgId;
        this.currentOrgIssuer = currentIssuerResp?.issuer;
        this.authRulesDescriptiveText = this.getPageDescriptiveTextWithImageWrap();
        if (!this.hasIssuersPermissions || !policyStateResp) {
          // Need this in order for the "No Permissions" text to be displayed when the page first loads.
          this.changeDetector.detectChanges();
          return;
        }
        this.setPolicyRuleIdToPolicyRuleMap();
        this.initializePanelsStateMap();
        this.initializeFormGroups();
        this.policyCatalogueEntries.forEach((catalogue) => {
          if (catalogue.name === 'patches') {
            this.patches = JSON.parse(catalogue.content);
          } else if (catalogue.name === 'default_settings') {
            this.defaultSettings = JSON.parse(catalogue.content);
          }
        });
        this.enableFormsIfPolicyExists();
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private enableFormsIfPolicyExists(): void {
    if (!!this.policyCopy) {
      this.mfaPolicyForm.enable();
      this.mfaPolicyCatalogueForm.enable();
    }
  }

  private setGroupMaps(groupList: Array<Group>): void {
    this.groupIdToGroupMap.clear();
    this.groupDisplayNameToGroupMap.clear();
    if (!groupList) {
      return;
    }
    for (const group of groupList) {
      this.groupIdToGroupMap.set(group.id, group);
      this.groupDisplayNameToGroupMap.set(group.display_name, group);
    }
  }

  private getPermissionsAndGroups$(): Observable<[OrgQualifiedPermission, Array<Group>]> {
    return this.issuersPermissions$.pipe(
      concatMap((issuersPermissionsResp) => {
        let groups$: Observable<Array<Group>> = of([]);
        if (issuersPermissionsResp.hasPermission && issuersPermissionsResp.orgId) {
          groups$ = this.groupsService.listGroups({ org_id: issuersPermissionsResp.orgId }).pipe(
            map((resp) => {
              return resp.groups;
            })
          );
        }
        return combineLatest([of(issuersPermissionsResp), groups$]);
      })
    );
  }

  private getPageDescriptiveTextWithImageWrap(): string {
    return `Authentication rules are policies that are run during the Authentication flow. Authorization occurs per application, but Authentication is global.\n\nThese include cached-credentials, multi-factor authentication, allow/deny IP blocks, etc. These will apply as your users sign in via ${this.currentOrgIssuer}.`;
  }

  private setPolicyRuleIdToPolicyRuleMap(): void {
    this.policyRuleIdToPolicyRuleMap.clear();
    for (const policyRule of this.policyRulesList) {
      this.policyRuleIdToPolicyRuleMap.set(policyRule.metadata.id, policyRule);
    }
  }

  private initializePanelsStateMap(): void {
    for (const policyRule of this.policyRulesList) {
      const rulePanel = this.panelsStateMap.get(policyRule.metadata.id);
      if (!rulePanel) {
        if (policyRule.spec.name === '') {
          // If the new rule has not been given a name we set the panel to open
          // so that the user does not see a blank panel.
          this.panelsStateMap.set(policyRule.metadata.id, true);
        } else {
          this.panelsStateMap.set(policyRule.metadata.id, false);
        }
      }
    }
    if (!this.policyCopy || !this.policyCopy.spec.policy_groups) {
      return;
    }
    for (const policyGroup of this.policyCopy.spec.policy_groups) {
      const groupPanel = this.panelsStateMap.get(policyGroup.metadata?.id);
      if (!groupPanel) {
        if (policyGroup.spec.name === '') {
          // If the new group has not been given a name we set the panel to open
          // so that the user does not see a blank panel.
          this.panelsStateMap.set(policyGroup.metadata.id, true);
        } else {
          this.panelsStateMap.set(policyGroup.metadata.id, false);
        }
      }
    }
  }

  private initializeMfaPolicyFormGroup(): void {
    this.mfaPolicyForm = this.formBuilder.group({
      web_push: this.isMfaMethodSelected(ChallengeType.web_push),
      totp: this.isMfaMethodSelected(ChallengeType.totp),
      webauthn: this.isMfaMethodSelected(ChallengeType.webauthn),
    });
    if (!this.policyCopy) {
      this.mfaPolicyForm.disable();
    }
  }

  private isMfaMethodSelected(mfaMethod: ChallengeType): boolean {
    if (!this.policyCopy) {
      return false;
    }
    return this.policyCopy.spec.supported_mfa_methods.includes(mfaMethod);
  }

  public mfaMethodChange(): void {
    this.setAndSavePolicy();
  }

  public modifyPolicyOnFormSelectionChange(formField: string): void {
    if (this.mfaPolicyCatalogueForm.controls[formField].invalid) {
      return;
    }
    this.setAndSavePolicy();
  }

  private setPolicyFromForm(): void {
    const defaultActionFormValue = this.mfaPolicyCatalogueForm.get('default_action').value;
    const copyOfPolicyCopy = cloneDeep(this.policyCopy);
    copyOfPolicyCopy.spec.default_action = defaultActionFormValue;
    const supportedMfaMethods = this.getSupportedMfaMethodsFromForm();
    copyOfPolicyCopy.spec.supported_mfa_methods = supportedMfaMethods;
    this.policyCopy = copyOfPolicyCopy;
  }

  private savePolicy(): void {
    this.store.dispatch(new ActionPolicySavingPolicy(this.policyCopy, false));
  }

  private overwritePolicy(): void {
    this.store.dispatch(new ActionPolicySavingPolicy(this.policyCopy, true));
  }

  private setAndSavePolicy(): void {
    this.setPolicyFromForm();
    this.savePolicy();
  }

  private getSupportedMfaMethodsFromForm(): Array<ChallengeType> {
    const webPushFormValue = this.mfaPolicyForm.get('web_push').value;
    const totpFormValue = this.mfaPolicyForm.get('totp').value;
    const webauthnFormValue = this.mfaPolicyForm.get('webauthn').value;
    const supportedMfaMethods = [];
    if (webPushFormValue) {
      supportedMfaMethods.push(ChallengeType.web_push);
    }
    if (totpFormValue) {
      supportedMfaMethods.push(ChallengeType.totp);
    }
    if (webauthnFormValue) {
      supportedMfaMethods.push(ChallengeType.webauthn);
    }
    return supportedMfaMethods;
  }

  public onPanelOpen(id: string): void {
    this.panelsStateMap.set(id, true);
  }

  public onPanelClose(id: string): void {
    this.panelsStateMap.set(id, false);
  }

  public getPanelState(id: string): boolean {
    return this.panelsStateMap.get(id);
  }

  private createNewPolicyGroup(): PolicyGroup {
    const newPolicyGroup: PolicyGroup = {
      spec: {
        name: '',
        rule_ids: [],
      },
    };
    return newPolicyGroup;
  }

  public addPolicyGroup(): void {
    if (!this.policyCopy.spec.policy_groups) {
      this.policyCopy.spec.policy_groups = [];
    }
    this.policyCopy.spec.policy_groups.push(this.createNewPolicyGroup());
    this.store.dispatch(new ActionPolicySavingPolicy(this.policyCopy, false));
  }

  public getPolicyRuleFromId(policyRuleId: string): PolicyRule | undefined {
    return this.policyRuleIdToPolicyRuleMap.get(policyRuleId);
  }

  private createNewPolicyRule(): PolicyRule {
    const newPolicyRule: PolicyRule = {
      spec: {
        action: MFAChallengeAnswerResult.ActionEnum.authenticate,
        org_id: this.orgId,
        name: '',
        conditions: [],
      },
    };
    return newPolicyRule;
  }

  public addPolicyRuleToGroup(policyGroupId: string): void {
    this.store.dispatch(new ActionPolicySavingPolicyRule(this.createNewPolicyRule(), policyGroupId));
  }

  public updateGroupName(updatedGroupName: string, policyGroupToUpdate: PolicyGroup): void {
    if (!updatedGroupName) {
      return;
    }
    for (const group of this.policyCopy.spec.policy_groups) {
      if (group.metadata.id === policyGroupToUpdate.metadata.id) {
        if (group.spec.name === updatedGroupName) {
          return;
        }
        group.spec.name = updatedGroupName;
        this.savePolicy();
        return;
      }
    }
  }

  public deletePolicyGroup(policyGroupToDelete: PolicyGroup): void {
    this.store.dispatch(new ActionPolicyDeletingPolicyGroup(policyGroupToDelete));
  }

  public dropGroup(event: CdkDragDrop<Array<PolicyGroup>>): void {
    if (event.previousIndex === event.currentIndex) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.savePolicy();
  }

  public dropRule(event: CdkDragDrop<Array<PolicyRule>>, policyGroup: PolicyGroup): void {
    if (event.previousContainer.id === event.container.id) {
      return;
    }
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
    this.savePolicy();
  }

  private initializeMfaPolicyCatalogueFormGroup(): void {
    this.mfaPolicyCatalogueForm = this.formBuilder.group({
      default_action: this.policyCopy?.spec?.default_action,
    });
    if (!this.policyCopy) {
      this.mfaPolicyCatalogueForm.disable();
    }
  }

  private initializeFormGroups(): void {
    this.initializeMfaPolicyFormGroup();
    this.initializeMfaPolicyCatalogueFormGroup();
  }

  public applyCatalogueEntryToPolicy(id: string, presetValuesGroup: PresetConfigValues): void {
    const selectedCatalogueEntry = this.policyCatalogueEntryIdToCatalogueEntryMap.get(this.defaultCatalogueEntry);
    const selectedPolicy: PolicySpec = JSON.parse(selectedCatalogueEntry.content);
    if (!this.policyCopy) {
      this.policyCopy = {
        spec: selectedPolicy,
      };
    } else {
      this.policyCopy.spec = selectedPolicy;
    }
    this.policyCopy.spec.org_id = this.orgId;
    this.policyCopy.spec.issuer_id = this.issuerId;
    if (this.patches) {
      this.applyJsonPatches(presetValuesGroup);
    }
    this.overwritePolicy();
  }

  // checks if is object, null val returns false
  private isObject(val): boolean {
    if (val === null) {
      return false;
    }
    return typeof val === 'function' || typeof val === 'object';
  }

  private applyJsonPatches(presetValuesGroup: PresetConfigValues): void {
    const mapPatchNames = this.getMapPatchNames();

    for (const k in presetValuesGroup) {
      if (k === 'sharedSessionsBetweenApps') {
        if (!presetValuesGroup[k]) {
          // In this unique case, we need to apply this preset if the checkbox is NOT selected
          applyPatch(this.policyCopy.spec, this.patches[mapPatchNames[k]]);
        }
        continue;
      }
      // if key does not have nested object and is not null
      if (!this.isObject(presetValuesGroup[k]) && presetValuesGroup[k] != null) {
        // if typeof presetValuesGroup[k] is boolean
        if (typeof presetValuesGroup[k] === 'boolean') {
          if (presetValuesGroup[k]) {
            applyPatch(this.policyCopy.spec, this.patches[mapPatchNames[k]]);
          }
          continue;
        }
        const patch = JSON.parse(JSON.stringify(this.patches[mapPatchNames[k]]).replace(/<VALUE1>/g, presetValuesGroup[k]));
        applyPatch(this.policyCopy.spec, patch);
      }
      // if key have nested object and key.checked is true
      else if (this.isObject(presetValuesGroup[k]) && presetValuesGroup[k].checked) {
        const patch = this.findAndReplaceString(this.patches[mapPatchNames[k]], '<VALUE1>', JSON.stringify(presetValuesGroup[k].values));
        applyPatch(this.policyCopy.spec, patch);
      }
    }
  }

  private getMapPatchNames(): MapPatchNames {
    return {
      multiFactorRequiredTime: 'multi_factor_window',
      sessionsValidTime: 'sign_on_window',
      sharedSessionsBetweenApps: 'disallow_shared_sessions',
      noMfaFromProviders: 'skip_mfa_from_trusted_provider',
      requireMfaFromApps: 'always_require_mfa_from_app',
      requireMfaFromGroups: 'always_require_mfa_from_groups',
    };
  }

  private findAndReplaceString(object: any, value: string, replacevalue: string): any {
    for (const x of Object.keys(object)) {
      if (typeof object[x] === typeof {}) {
        this.findAndReplaceString(object[x], value, replacevalue);
      }
      if (object[x] === value) {
        const key = 'value';
        object[key] = replacevalue;
      }
    }
    return object;
  }

  private getPolicyCatalogue(): Observable<Array<Catalogue>> {
    return this.cataloguesService
      .listCatalogues({
        catalogue_category: 'default-policies',
      })
      .pipe(
        map((resp) => {
          return resp.catalogues;
        }),
        catchError((_) => {
          return [];
        })
      );
  }

  private getPolicyCatalogueEntries(policyCatalogues: Array<Catalogue>): Array<CatalogueEntry> {
    if (policyCatalogues.length === 0) {
      return [];
    }
    return policyCatalogues[0].catalogue_entries;
  }

  private setPolicyCatalogueEntryIdToCatalogueEntryMap(): void {
    this.policyCatalogueEntryIdToCatalogueEntryMap.clear();
    for (const catalogueEntry of this.policyCatalogueEntries) {
      this.policyCatalogueEntryIdToCatalogueEntryMap.set(catalogueEntry.name, catalogueEntry);
    }
  }

  // gives first sentense in a paragraph
  public getSummaryPoint(description: string): string {
    if (description) {
      return description.substring(0, description.indexOf('.') + 1);
    }
    return '';
  }

  public openPolicyCatalogueDialog(policyName: string): void {
    const selectedCatalogueEntry = this.policyCatalogueEntryIdToCatalogueEntryMap.get(this.defaultCatalogueEntry);
    const messagePrefix = `You have selected the "${policyName}" policy preset option.`;
    const dialogData = createDialogData(messagePrefix, '');
    dialogData.orgId = this.orgId;
    if (this.defaultSettings) {
      dialogData.defaultValues = this.defaultSettings[policyName];
    }
    const dialogRef = this.dialog.open(AuthPresetDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe((presetValuesGroup: PresetConfigValues) => {
      if (presetValuesGroup) {
        this.applyCatalogueEntryToPolicy(selectedCatalogueEntry.id, presetValuesGroup);
        return;
      }
    });
  }

  public openDetailsDialog(policyName: string, long_description: string): void {
    const messagePrefix = capitalizeFirstLetter(policyName);
    const message = long_description.replace(/\n/g, '<br />');
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.informationDialog = true;
    dialogData.buttonText = { confirm: 'Config & Apply Preset', cancel: 'Cancel' };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.openPolicyCatalogueDialog(policyName);
        return;
      }
    });
  }

  public getInheritedPolicyText(): string {
    return !!this.policyCopy ? 'No' : 'Yes';
  }

  public getInheritedPolicyTooltipText(): string {
    return `An inherited policy shares the same issuer and policy as the parent organisation as opposed to having its own unique issuer and policy`;
  }

  private getDisabledInheritedOptionTooltipText(): string {
    return `This option is not available for inherited policies`;
  }

  public getTotpTooltipText(): string {
    return !!this.policyCopy
      ? `Enable Time-Based One Time Code (e.g. Google Authenticator, Authy, ...)`
      : this.getDisabledInheritedOptionTooltipText();
  }

  public getWebauthnTooltipText(): string {
    return !!this.policyCopy
      ? `Enable WebAuthN standard (USB security key, push to Phone, biometric, TPM, ...)`
      : this.getDisabledInheritedOptionTooltipText();
  }

  public getDefaultActionTooltipText(): string {
    return !!this.policyCopy ? `` : this.getDisabledInheritedOptionTooltipText();
  }

  public getGroupButtonTooltipText(): string {
    return !!this.policyCopy ? `Add a new group to the policy` : this.getDisabledInheritedOptionTooltipText();
  }
}

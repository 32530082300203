import {
  Application,
  ApplicationsService,
  ListApplicationsRequestParams,
  ListResourcesRequestParams,
  Resource,
  ResourcesService,
} from '@agilicus/angular';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

export function getApplicationsForMetrics(orgId: string, applicationsService: ApplicationsService): Observable<Array<Application>> {
  const listApplicationsRequestParams: ListApplicationsRequestParams = {
    org_id: orgId,
    maintained: true,
    owned: true,
    show_status: true,
    application_type: ['user_defined'],
  };
  return applicationsService.listApplications(listApplicationsRequestParams).pipe(
    map((resp) => {
      return resp.applications;
    })
  );
}

export function getResourcesForMetrics(orgId: string, resourcesService: ResourcesService): Observable<Array<Resource>> {
  const listResourcesRequestParams: ListResourcesRequestParams = {
    org_id: orgId,
  };
  return resourcesService.listResources(listResourcesRequestParams).pipe(
    map((resp) => {
      return resp.resources;
    })
  );
}

export function searchFilter(value: string, options: Array<string>): Array<string> {
  const filterValue = value.toLowerCase();
  return options.filter((option) => option.toLowerCase().includes(filterValue));
}

export function getFilteredOptions(options: Array<string>, param: string, form: UntypedFormGroup): Observable<Array<string>> | undefined {
  if (options === undefined) {
    return undefined;
  }
  const control = form.get(param);
  return control.valueChanges.pipe(
    startWith(''),
    map((value) => searchFilter(value, options))
  );
}

export function getResourceFilterList(): Array<string> {
  return ['resource_name', 'resource_type'];
}

import { Label, LabelledObject, LabelsService, Resource, ResourcesService, ResourceTypeEnum } from '@agilicus/angular';
import { Injectable } from '@angular/core';
import { ResourceAndLabelDataWithPermissions } from '@app/shared/components/resource-and-label-data-with-permissions';
import { select, Store } from '@ngrx/store';
import { catchError, combineLatest, concatMap, Observable, of } from 'rxjs';
import { getLabelledObjectsList$, getObjectLabelsList$ } from '../api/labels/labels-api-utils';
import { getResources } from '../api/resources/resources-api-utils';
import { AppState } from '../core.state';
import { NotificationService } from '../notifications/notification.service';
import { selectCanAdminLabels } from '../user/permissions/labelled-objects.selectors';
import { selectCanAdminResources } from '../user/permissions/resources.selectors';
import { selectCanAdminRules } from '../user/permissions/rules.selectors';

@Injectable({
  providedIn: 'root',
})
export class ResourceLabelLinkService {
  constructor(
    private store: Store<AppState>,
    private labelsService: LabelsService,
    private resourcesService: ResourcesService,
    private notificationService: NotificationService
  ) {}

  public getResourceAndLabelDataWithPermissions$(): Observable<ResourceAndLabelDataWithPermissions> {
    const hasLabelPermissions$ = this.store.pipe(select(selectCanAdminLabels));
    const hasResourcePermissions$ = this.store.pipe(select(selectCanAdminResources));
    const hasRulesPermissions$ = this.store.pipe(select(selectCanAdminRules));
    const result$ = combineLatest([hasLabelPermissions$, hasResourcePermissions$, hasRulesPermissions$]).pipe(
      concatMap(([hasLabelPermissionsResp, hasResourcePermissionsResp, hasRulesPermissionsResp]) => {
        const orgId = hasLabelPermissionsResp?.orgId;
        const hasAllPermissions =
          hasLabelPermissionsResp.hasPermission && hasResourcePermissionsResp.hasPermission && hasRulesPermissionsResp.hasPermission;
        let labelledObjects$: Observable<Array<LabelledObject> | undefined> = of(undefined);
        let allResources$: Observable<Array<Resource> | undefined> = of(undefined);
        let labelsList$: Observable<Array<Label> | undefined> = of(undefined);
        if (!!orgId && hasAllPermissions) {
          const resourceTypesList = Object.values(ResourceTypeEnum);
          labelledObjects$ = getLabelledObjectsList$(this.labelsService, orgId, resourceTypesList).pipe(
            catchError((_) => {
              this.notificationService.error('Failed to list labelled objects');
              return of(undefined);
            })
          );
          labelsList$ = getObjectLabelsList$(this.labelsService, orgId).pipe(
            catchError((_) => {
              this.notificationService.error('Failed to list labels');
              return of(undefined);
            })
          );
          allResources$ = getResources(this.resourcesService, orgId, undefined, [ResourceTypeEnum.service_forwarder]).pipe(
            catchError((_) => {
              this.notificationService.error('Failed to list resources');
              return of(undefined);
            })
          );
        }
        return combineLatest([
          of(hasLabelPermissionsResp),
          of(hasResourcePermissionsResp),
          of(hasRulesPermissionsResp),
          labelledObjects$,
          labelsList$,
          allResources$,
        ]);
      })
    );
    return result$.pipe(
      concatMap(
        ([
          hasLabelPermissionsResp,
          hasResourcePermissionsResp,
          hasRulesPermissionsResp,
          labelledObjectsResp,
          labelsListResp,
          allResourcesResp,
        ]) => {
          const permissionsAndResourceAndLabelData: ResourceAndLabelDataWithPermissions = {
            hasLabelPermissions: hasLabelPermissionsResp,
            hasResourcePermissions: hasResourcePermissionsResp,
            hasRulesPermissions: hasRulesPermissionsResp,
            labelledObjects: labelledObjectsResp,
            labelsList: labelsListResp,
            resourcesList: allResourcesResp,
          };
          return of(permissionsAndResourceAndLabelData);
        }
      )
    );
  }
}

import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, Input } from '@angular/core';

import { AppState } from '@app/core';

import {
  User,
  UsersService,
  Application,
  ApplicationsService,
  UserStatusEnum,
  ListUsersRequestParams,
  ListUsersResponse,
  ListApplicationsRequestParams,
} from '@agilicus/angular';
import { Store, select } from '@ngrx/store';
import { Subject, Observable, Subscription, of } from 'rxjs';
import { takeUntil, map, filter, debounceTime, distinctUntilChanged, take } from 'rxjs/operators';
import { MatSort } from '@angular/material/sort';
import { forkJoin } from 'rxjs';
import { NotificationService } from '@app/core/notifications/notification.service';
import { FilterManager, CheckboxOption } from '@app/shared/components/filter/filter-manager';
import {
  Column,
  createSelectRowColumn,
  createIconColumn,
  createAutoInputColumn,
  createSelectColumn,
  AutoInputColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { UntypedFormControl } from '@angular/forms';
import { resetIndices, capitalizeFirstLetter, createEnumChecker, getUserTypeIcon, getUserTypeTooltip } from '../utils';
import { selectCanReadApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { OrgQualifiedPermission, createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { OptionalPermissionElement, Optional_Role } from '../optional-types';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { PropertyNamesOfType } from '@app/shared/utilities/generics/type-scrubbers';
import { PermissionsFilterOptions } from '../permissions-filter-options';
import { PermissionElement } from '../permission-element';
import { PermissionsCheckboxOptions } from '../permissions-checkbox-options.enum';
import { FilterType } from '../filter-type.enum';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { getDefaultPermissionFilterOptions } from '../filter-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { FilterMenuOption, FilterMenuOptionType } from '../table-filter/table-filter.component';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { ButtonType } from '../button-type.enum';
import { InputSize } from '../custom-chiplist-input/input-size.enum';

export enum PermissionsFilterLabel {
  NOLABEL = '',
  APPCATEGORY = 'Application Category',
  USER = 'User Status',
  TYPE = 'User Type',
  RESOURCE_TYPE = 'Resource Type',
  RESOURCE_NAME = 'Resource Name',
}

@Component({
  selector: 'portal-permissions-admin',
  templateUrl: './permissions-admin.component.html',
  styleUrls: ['./permissions-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionsAdminComponent implements OnInit, OnDestroy {
  @Input() public appId: string;
  @Input() public showPageInfo = true;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private permissionCreated$: Subject<void> = new Subject<void>(); // signals that the new permission has been created
  private permissions$: Observable<OrgQualifiedPermission>;
  public columnDefs: Map<string, Column<PermissionElement>> = new Map();
  private orgId: string;
  private applications$: Observable<Array<Application>>;
  private applications: Array<Application> = [];
  private users$: Observable<ListUsersResponse>;
  private appCategoryNames: Set<string> = new Set([]);
  public buttonsToShow: Array<ButtonType> = [ButtonType.ADD];
  public filterOptions = getDefaultPermissionFilterOptions();
  public filterManager: FilterManager = new FilterManager();
  public tableData: Array<PermissionElement> = [];
  private emailToPermissionElementMap: Map<string, PermissionElement> = new Map();
  private appNameToAppMap: Map<string, Application> = new Map();
  public rowObjectName = 'PERMISSION';
  public hasPermission: boolean;
  public linkDataSource = false;
  public paginatorConfig = new PaginatorConfig<PermissionElement>(true, true, 25, 5, new PaginatorActions<PermissionElement>(), 'email', {
    previousKey: '',
    nextKey: '',
    previousWindow: [],
  });
  public pageDescriptiveText = `Assign role-based permissions to users or groups per web application`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/permissions/`;
  public freezeLeftColumns = true;

  @ViewChild(MatSort, { static: true }) public sort: MatSort;
  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<PermissionElement>;

  public filterMenuOptions: Map<string, FilterMenuOption> = new Map([
    [
      'category',
      {
        name: 'category',
        displayName: PermissionsFilterLabel.APPCATEGORY,
        icon: 'label',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'type',
      {
        name: 'type',
        displayName: PermissionsFilterLabel.TYPE,
        icon: 'group',
        type: FilterMenuOptionType.checkbox,
      },
    ],
    [
      'status',
      {
        name: 'status',
        displayName: PermissionsFilterLabel.USER,
        icon: 'person_add_disabled',
        type: FilterMenuOptionType.checkbox,
      },
    ],
  ]);

  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private usersSubscription: Subscription = new Subscription();

  constructor(
    private applicationsService: ApplicationsService,
    private usersService: UsersService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService
  ) {
    this.setCheckboxFilterOptions();
  }

  private setCheckboxFilterOptions(): void {
    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.DISABLED,
      displayName: PermissionsCheckboxOptions.DISABLED,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'showDisabledUsers'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.PENDING,
      displayName: PermissionsCheckboxOptions.PENDING,
      label: this.filterMenuOptions.get('status').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'showPendingUsers'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.USERS,
      displayName: 'User',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeUser'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.GROUPS,
      displayName: 'Group',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeGroup'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.BIGROUPS,
      displayName: 'Built-in Group',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeBigroup'),
    });

    this.filterManager.addCheckboxFilterOption({
      name: PermissionsCheckboxOptions.SERVICE_ACCOUNT,
      displayName: 'Service Account',
      label: this.filterMenuOptions.get('type').displayName,
      type: FilterType.CHECKBOX,
      isChecked: false,
      doFilter: (checkbox: CheckboxOption) => this.toggleCheckbox(checkbox, 'onlyShowTypeServiceAccount'),
    });
  }

  public ngOnInit(): void {
    if (!this.appId) {
      this.buttonsToShow.push(ButtonType.DELETE);
    }
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanAdminUsers, selectCanReadApps)));
    this.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((permissions) => {
      this.orgId = permissions.orgId;
      if (!this.orgId) {
        return;
      }
      this.hasPermission = permissions.hasPermission;
      if (!this.hasPermission) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.resetLocalData();
      if (this.applications$ === undefined) {
        // we are fetching data for the first time
        this.updateTable();
        this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
          this.updateTable(params.key, params.searchDirection, params.limit);
        });
      } else {
        // this case could happen when the org is changed - we don't want to subscribe to updateTableSubject again
        this.reloadFirstPage();
      }
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private resetLocalData(): void {
    this.emailToPermissionElementMap.clear();
    this.appNameToAppMap.clear();
    // Reset the list of applications so only those that apply to that org
    // are shown when switching between orgs.
    for (const app of this.applications) {
      this.columnDefs.delete(app.name);
    }
    this.applications.length = 0;
  }

  public updateTable(
    emailKey = '',
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    const params = this.getParams(emailKey, searchDirectionParam, limitParam);
    this.users$ = this.usersService.listUsers(params);
    const listApplicationsRequestParams: ListApplicationsRequestParams = {
      org_id: this.orgId,
      assigned: true,
      owned: false,
      show_status: true,
      application_type: ['user_defined'],
    };
    if (!!this.appId) {
      listApplicationsRequestParams.resource_id = this.appId;
    }
    this.applications$ = this.applicationsService.listApplications(listApplicationsRequestParams).pipe(
      map((appsResp) => {
        return appsResp.applications;
      })
    );
    // Combine data from above api calls into single dataSource for use
    // in the MatTable.
    forkJoin([this.users$, this.applications$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([usersResp, appsResp]) => {
          this.applications = appsResp;
          // Create the application map
          for (const app of appsResp) {
            this.appNameToAppMap.set(app.name, app);
          }
          this.createTableDataSource(usersResp.users, appsResp);
          this.populateColumns(appsResp);
          this.getAppCategoriesForFiltering(appsResp);
          this.setShowColumns();
          this.replaceTableWithCopy();
          this.paginatorConfig.actions.dataFetched({
            data: this.tableData,
            searchDirection: searchDirectionParam,
            limit: limitParam,
            nextKey: usersResp.next_page_email,
            previousKey: usersResp.previous_page_email,
          });
        },
        (err) => {
          this.paginatorConfig.actions.errorHandler(err);
        }
      );
  }

  private getParams(email_key: string, search_direction_param: 'forwards' | 'backwards', limit_param: number): ListUsersRequestParams {
    const params: ListUsersRequestParams = {
      org_id: this.orgId,
      status: [UserStatusEnum.active],
      limit: limit_param,
      previous_email: email_key,
      search_direction: search_direction_param,
      type: [],
      has_application_permissions: true,
    };
    if (this.filterOptions.showDisabledUsers) {
      params.status.push(UserStatusEnum.disabled);
    }
    if (this.filterOptions.showPendingUsers) {
      params.status.push(UserStatusEnum.pending);
    }
    if (this.filterOptions.onlyShowTypeUser) {
      params.type.push(User.TypeEnum.user);
    }
    if (this.filterOptions.onlyShowTypeGroup) {
      params.type.push(User.TypeEnum.group);
    }
    if (this.filterOptions.onlyShowTypeBigroup) {
      params.type.push(User.TypeEnum.bigroup);
    }
    if (this.filterOptions.onlyShowTypeServiceAccount) {
      params.type.push(User.TypeEnum.service_account);
    }
    return params;
  }

  private reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }

  private reloadWindow(): void {
    this.paginatorConfig.actions.reloadWindow();
  }

  private getSelectRowColumn(): Column<PermissionElement> {
    const column = createSelectRowColumn();
    column.sticky = true;
    return column;
  }

  private getTypeColumn(): Column<PermissionElement> {
    const column = createIconColumn('type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    column.getDisplayValue = (element: OptionalPermissionElement) => {
      if (element.status === UserStatusEnum.disabled) {
        return 'person_add_disabled';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'pending_actions';
      }
      const isUserTypeEnum = createEnumChecker(User.TypeEnum);
      if (isUserTypeEnum(element.type)) {
        return getUserTypeIcon(element.type);
      }
      // This will lead to no icon being displayed.
      return '';
    };
    column.getTooltip = (element: OptionalPermissionElement) => {
      if (element.status === UserStatusEnum.disabled) {
        return 'Disabled User';
      }
      if (element.status === UserStatusEnum.pending) {
        return 'Pending User';
      }
      const isUserTypeEnum = createEnumChecker(User.TypeEnum);
      if (isUserTypeEnum(element.type)) {
        return getUserTypeTooltip(element.type);
      }
      return '';
    };
    column.sticky = true;
    return column;
  }

  private getEmailColumn(): Column<PermissionElement> {
    const column = createAutoInputColumn('emailFormControl');
    column.displayName = 'Identity';
    column.requiredField = () => true;
    column.isEditable = true;
    column.isUnique = true;
    column.allowAnyValue = false;
    column.freezeWhenSet = true;
    column.getDisplayValue = (element: OptionalPermissionElement): string => {
      return element.display_name;
    };
    column.isReadOnly = (element: OptionalPermissionElement): boolean => {
      return element.email !== '';
    };
    column.getFilteredValues = (
      element: OptionalPermissionElement,
      column: AutoInputColumn<PermissionElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.emailFormControl, column);
    };
    column.sticky = true;
    column.inputSize = InputSize.TEXT_INPUT_LARGE;
    return column;
  }

  private initializeColumnDefs(): void {
    const columns: Array<Column<PermissionElement>> = [this.getTypeColumn(), this.getEmailColumn()];
    if (!this.appId) {
      columns.unshift(this.getSelectRowColumn());
    }
    setColumnDefs(columns, this.columnDefs);
  }

  private checkShowAllOptions(currentCheckboxOptions: Array<CheckboxOption>): boolean {
    // If all options are unchecked, then we show all options.
    for (const option of currentCheckboxOptions) {
      if (option.isChecked) {
        return false;
      }
    }
    return true;
  }

  private setShowColumns(): void {
    const currentCheckboxOptions = this.filterManager.getCheckboxOptions(PermissionsFilterLabel.APPCATEGORY);
    if (this.checkShowAllOptions(currentCheckboxOptions)) {
      return;
    }
    for (const app of this.applications) {
      for (const option of currentCheckboxOptions) {
        if (option.name === app.category) {
          this.columnDefs.get(app.name).showColumn = option.isChecked;
          break;
        }
      }
    }
  }

  /**
   * Maps the applications/roles to the user for display in the table.
   */
  private createTableDataSource(users: Array<User>, apps: Array<Application>): void {
    this.tableData = [];
    for (let i = 0; i < users.length; i++) {
      const user = users[i];
      for (const app of apps) {
        if (user.roles[app.name] !== undefined) {
          user[app.name] = user.roles[app.name];
        } else {
          user[app.name] = [];
        }
      }
      this.setUserData(user, this.tableData, true, i);
    }
  }

  private checkForMatchingApps(userApps: Array<string>): boolean {
    for (const app of userApps) {
      if (this.appNameToAppMap.get(app) !== undefined) {
        return true;
      }
    }
    return false;
  }

  private setUserData(user: User, displayedUsers: Array<PermissionElement>, showRow: boolean, index: number): void {
    const userElement: PermissionElement = {
      userSelected: false,
      emailFormControl: new UntypedFormControl(),
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(user)) {
      userElement[key] = user[key];
    }
    // Set the users 'showRow' property so the row will remain accurate when the
    // data is refreshed.
    userElement.showRow = showRow;
    userElement.userSelected = true;
    this.emailToPermissionElementMap.set(userElement.display_name, userElement);
    this.setFormControlValues(userElement);
    displayedUsers.push(userElement);
  }

  private populateColumns(apps: Array<Application>): void {
    apps.forEach((app) => {
      this.columnDefs.set(app.name, this.createApplicationColumnDef(app));
    });
  }

  /**
   * Gets the list of app categories and adds them to the list of checkbox options
   * so a user can filter columns by app category.
   * @param apps The response from the listApplications api call.
   */
  private getAppCategoriesForFiltering(apps: Array<Application>): void {
    // Use Set to remove duplicates.
    const newAppCategoryNames: Set<string> = new Set(apps.map((app) => app.category));
    this.updateAppCategoryOptions(newAppCategoryNames);
  }

  private updateAppCategoryOptions(newAppCategoryNames: Set<string>): void {
    // Remove app category names that no longer exist from appCategoryNames
    // and remove the appCategory from checkboxOptions.
    this.appCategoryNames.forEach((appCategory) => {
      if (!newAppCategoryNames.has(appCategory)) {
        this.appCategoryNames.delete(appCategory);
        this.filterManager.removeCheckboxFilterOption(appCategory, this.filterMenuOptions.get('category').displayName);
      }
    });
    // Add new app category names to appCategoryNames
    // and add the appCategory to checkboxOptions.
    newAppCategoryNames.forEach((name) => {
      if (!this.appCategoryNames.has(name)) {
        this.appCategoryNames.add(name);
        this.filterManager.addCheckboxFilterOption({
          name,
          displayName: capitalizeFirstLetter(name),
          label: this.filterMenuOptions.get('category').displayName,
          type: FilterType.CHECKBOX,
          isChecked: false,
          doFilter: this.updateDisplayedColumns.bind(this),
        });
      }
    });
  }

  private putUserRoles(element: PermissionElement): void {
    if (element.isNew) {
      // unsubscribe from emailFormControl.valueChanges
      this.permissionCreated$.next();
    }
    const user = sanitizeUser(element);
    this.usersService
      .replaceUser({ user_id: element.id, User: user })
      .pipe(take(1))
      .subscribe(
        (resp) => {
          this.notificationService.success(`User "${element.display_name}" was successfully assigned permissions`);
        },
        (errorResp) => {
          this.notificationService.error(`User "${element.display_name}" failed to be assigned permissions`);
        },
        () => {
          element.isNew = false;
          this.columnDefs.get('emailFormControl').allowedValues.length = 0;
          this.changeDetector.detectChanges();
        }
      );
  }

  public updateRoles(params: { value: string; column: Column<PermissionElement>; element: PermissionElement }): void {
    if (params.value === '') {
      params.element.roles[params.column.name] = [];
    } else {
      params.element.roles[params.column.name] = [params.value];
    }
  }

  public updateEvent(element: PermissionElement): void {
    this.putUserRoles(element);
  }

  public toggleCheckbox(checkboxOption: CheckboxOption, option: PropertyNamesOfType<PermissionsFilterOptions, boolean>): void {
    this.filterOptions[option] = checkboxOption.isChecked;
    this.reloadFirstPage();
  }

  /**
   * Updates the displayed table columns when an application category option is checked or unchecked.
   * If no selections are checked, then no filtering is taking place and, therefore, all columns are displayed.
   * If selections are checked then only those columns that match the selections will be displayed.
   */
  public updateDisplayedColumns(): void {
    const appCategoryOptions = this.filterManager.checkboxOptions.filter(
      (option) => option.label === this.filterMenuOptions.get('category').displayName
    );
    this.applications.forEach((app) => {
      let match = false;
      let showAllColumns = true;
      for (const category of appCategoryOptions) {
        if (category.isChecked) {
          showAllColumns = false;
          if (category.name === app.category) {
            match = true;
          }
        }
      }
      if (showAllColumns) {
        this.columnDefs.get(app.name).showColumn = true;
      } else {
        this.columnDefs.get(app.name).showColumn = match;
      }
    });
  }

  private createApplicationColumnDef(application: Application): Column<PermissionElement> {
    const appColumnDef = createSelectColumn(application.name);
    appColumnDef.allowedValues = [{ name: '', rules: [] }, ...application.roles];
    appColumnDef.formControl = new UntypedFormControl();
    appColumnDef.getDisplayValue = (element: any): string => {
      if (element.roles[application.name] !== undefined && element.roles[application.name].length !== 0) {
        return element.roles[application.name][0];
      }
      return '';
    };
    appColumnDef.getOptionValue = this.getAppRoleName;
    appColumnDef.getOptionDisplayValue = this.getAppRoleName;
    appColumnDef.hideOptions = (element: any): boolean => {
      return element.isNew && !element.userSelected;
    };
    return appColumnDef;
  }

  private getAppRoleName(role: Optional_Role): string {
    return role.name;
  }

  /**
   * Adds a user without a role to the table when selected from the
   * autocomplete dropdown.
   */
  public updateAutoInput(params: { optionValue: string; column: Column<PermissionElement>; element: PermissionElement }): void {
    if (!params.optionValue) {
      return;
    }
    const selectedUser = this.columnDefs.get('emailFormControl').allowedValues.find((user) => user.display_name === params.optionValue);
    for (const key of Object.keys(selectedUser)) {
      params.element[key] = selectedUser[key];
    }
    params.element.isNew = true;
    params.element.userSelected = true;
    resetIndices(this.tableData);
  }

  public makeEmptyTableElement(): PermissionElement {
    const element = {
      created: null,
      email: '',
      id: '',
      org_id: this.orgId,
      roles: {},
      userSelected: false,
      emailFormControl: new UntypedFormControl(),
      ...getDefaultNewRowProperties(),
    };
    for (const app of this.applications) {
      element[app.name] = [];
    }
    this.setFormControlValues(element);
    return element;
  }

  private prepareDeleteRoles(elementsToDelete: Array<PermissionElement>): Array<Observable<User>> {
    const observablesArray = [];
    for (const element of elementsToDelete) {
      if (element.isChecked && element.id !== '') {
        for (const key of Object.keys(element.roles)) {
          element.roles[key] = [];
        }
        const user = sanitizeUser(element);
        observablesArray.push(this.usersService.replaceUser({ user_id: element.id, User: user }));
      }
    }
    return observablesArray;
  }

  private deleteAllRoles<T extends PermissionElement>(elementsToDelete: Array<T>): void {
    if (this.tableData.length === 0) {
      return;
    }
    const observablesArray = this.prepareDeleteRoles(elementsToDelete);
    if (observablesArray.length === 0) {
      this.reloadWindow();
      this.changeDetector.detectChanges();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {},
        (errorResp) => {
          this.notificationService.error('Failed to remove permissions from all selected users');
          this.reloadWindow();
        },
        () => {
          this.reloadWindow();
          this.changeDetector.detectChanges();
        }
      );
  }

  public removeSelected<T extends PermissionElement>(elementsToDelete: Array<T>): void {
    this.deleteAllRoles(elementsToDelete);
  }

  /**
   * Updates the displayed table rows when a checkbox filter option is checked or unchecked.
   * If no selections are checked, then no filtering is taking place and, therefore, all rows are displayed.
   * If selections are checked then only those rows that match the selections will be displayed.
   */
  public updateShownRows(): void {
    const userTypeOptions = this.filterManager.checkboxOptions.filter(
      (option) => option.label === this.filterMenuOptions.get('type').displayName
    );
  }

  public permissionsDefined(): boolean {
    return this.hasPermission !== undefined;
  }

  public hasPermissions(): boolean {
    return this.hasPermission;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private setFormControlValues(data: PermissionElement): void {
    data.emailFormControl.setValue(data.display_name);

    if (!data.isNew) {
      // don't need to get drop down for elements that are not new since they are read only
      return;
    }

    data.emailFormControl.valueChanges
      .pipe(
        takeUntil(this.permissionCreated$),
        takeUntil(this.unsubscribe$),
        filter((input) => input !== null),
        debounceTime(350), // allow for some delay so we don't make api calls on every keyup, only the last value is returned after 350ms
        distinctUntilChanged() // only make api calls if the latest value is different from the previous value
      )
      .subscribe((input: string) => {
        // cancel old subscription
        if (!this.usersSubscription.closed) {
          this.usersSubscription.unsubscribe();
        }
        // get users whose email prefix matches the input
        this.users$ = this.usersService.listUsers({
          org_id: this.orgId,
          has_application_permissions: false,
          prefix_email_search: input,
        });
        this.usersSubscription = this.users$.pipe(takeUntil(this.unsubscribe$)).subscribe((usersResp) => {
          // Get allowed values from list of suggested users
          this.columnDefs.get('emailFormControl').allowedValues = usersResp.users;
          // reset the form control so it rebuilds the list of allowed members
          this.columnDefs.get('emailFormControl').formControl.reset();
          // re-evaluate dropdown
          data.emailFormControl.enable();
        });
      });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

function sanitizeUser(element: PermissionElement): User {
  const user: PermissionElement = {
    ...element,
  };

  delete user.emailFormControl;
  return user;
}

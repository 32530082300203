<div class="dialog-container">
  <mat-dialog-content>
    <h2 class="message-prefix">Deleting organisation {{ orgName }}</h2>
    <div class="icon-container">
      <mat-icon class="warning-icon">warning</mat-icon>
    </div>
    <div class="message-container">
      <p>You are permanently deleting this organisation and all of the associated applications. Please enter your organisation name below to confirm.</p>
    </div>
    <form 
      [formGroup]="orgForm" 
      spellcheck="false"
      autocomplete="off"
    >
      <mat-form-field class="org-name-form">
        <mat-label>Confirm Organisation Name</mat-label>
        <input
          matInput
          type="text" 
          formControlName="orgName" 
        >
      </mat-form-field>

      <mat-form-field>
        <mat-label>Reason for Deletion</mat-label>
        <mat-select 
          #select1
          formControlName="feedback"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
        >
          <mat-option
            *ngFor="let option of orgCancelFeedbackDataList"
            [value]="option.value"
          >
            {{ option.detail }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="org-name-form">
        <mat-label>Additional Comments</mat-label>
        <textarea
          matInput
          style="height: 100px;"
          formControlName="comment"
          (keyup.control.enter)="keyTabManager.keyTab($event.target.id)"
        >
        </textarea>
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearComment()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button color="primary" [disabled]="!orgForm.valid" (click)="onConfirmClick()" tabindex="1">Delete</button>
    <button mat-raised-button mat-dialog-close tabindex="-1">Cancel</button>
  </mat-dialog-actions>
</div>
import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnChanges, Renderer2 } from '@angular/core';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { Observable, Subject, combineLatest } from 'rxjs';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import { ButtonType } from '../button-type.enum';
import { Store, select } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import {
  ActionApiApplicationsInitApplications,
  ActionApiApplicationsRemoveNewAppBundleAddedFlag,
  ActionApiApplicationsCreatingAppBundle,
  ActionApiApplicationsModifyingAppBundle,
  ActionApiApplicationsDeletingAppBundle,
} from '@app/core/api-applications/api-applications.actions';
import { takeUntil } from 'rxjs/operators';
import { updateTableElements, addNewEntryFocus } from '../utils';
import { cloneDeep } from 'lodash-es';
import { OptionalAppBundleElement } from '../optional-types';
import { getFile, formatBytes } from '../file-utils';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { AppBundle } from '@app/core/api/applications/app-bundle';
import { FileHelperService } from '@app/core/services/file-helper/file-helper.service';
import { Application } from '@agilicus/angular';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog.component';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface AppBundleElement extends AppBundle, TableElement {}

@Component({
  selector: 'portal-application-bundles',
  templateUrl: './application-bundles.component.html',
  styleUrls: ['./application-bundles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationBundlesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<AppBundleElement>> = new Map();
  private orgId$: Observable<string>;
  private orgId: string;
  private appState$: Observable<ApiApplicationsState>;
  private currentApp: Application;
  public tableData: Array<AppBundleElement> = [];
  public rowObjectName = 'BUNDLE';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<string> = [ButtonType.UPLOAD, ButtonType.DELETE];
  public uploadButtonTooltipText = 'Click to select an application bundle to upload';
  public dragAndDropHeaderOnly = true;
  public bundlesProductGuideLink = `https://www.agilicus.com/anyx-guide/product-guide-applications/#h-bundles`;
  public bundlesDescriptiveText = `A bundle is a zip-file containing support files of your application. 
  These will be interpreted by the runtime container, mounted within it.`;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private fileHelperService: FileHelperService,
    private renderer: Renderer2,
    private dialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.store.dispatch(new ActionApiApplicationsInitApplications());
    this.orgId$ = this.store.pipe(select(selectApiOrgId));
    this.appState$ = this.store.pipe(select(selectApiApplications));
    combineLatest([this.orgId$, this.appState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, appStateResp]) => {
        if (
          appStateResp === undefined ||
          appStateResp.current_application === undefined ||
          appStateResp.current_application_bundles_list === undefined
        ) {
          this.resetEmptyTable();
          return;
        }
        this.orgId = orgIdResp;
        if (appStateResp.new_app_bundle_added) {
          // We need to add focus to the first new entry in the table after
          // the new elements have been created in the DOM.
          addNewEntryFocus(this.rowObjectName);
          this.store.dispatch(new ActionApiApplicationsRemoveNewAppBundleAddedFlag());
          return;
        }
        this.setEditableColumnDefs();
        this.currentApp = appStateResp.current_application;
        this.updateTable(cloneDeep(appStateResp.current_application_bundles_list));
      });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const labelColumn = createInputColumn('label');
    labelColumn.displayName = 'Name';
    labelColumn.requiredField = () => true;
    labelColumn.isUnique = true;
    labelColumn.isEditable = true;

    // Created is readonly.
    const createdColumn = createInputColumn('created');
    createdColumn.isReadOnly = () => true;
    createdColumn.displayName = 'Date Added';

    // Size is readonly.
    const sizeColumn = createInputColumn('size');
    sizeColumn.isReadOnly = () => true;
    sizeColumn.getDisplayValue = (element: any) => {
      return formatBytes(element.size);
    };

    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<AppBundleElement>> = [
      {
        displayName: 'Download Bundle',
        icon: 'cloud_download',
        tooltip: 'Click to download the bundle',
        onClick: (element: any) => {
          this.fileHelperService.downloadToBrowser(element, this.orgId, this.renderer);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(labelColumn.name, labelColumn);
    this.columnDefs.set(createdColumn.name, createdColumn);
    this.columnDefs.set(sizeColumn.name, sizeColumn);
    this.columnDefs.set(actionsColumn.name, actionsColumn);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const labelColumn = this.columnDefs.get('label');
    labelColumn.isEditable = !this.fixedTable;
  }

  private updateTable(appBundleList: Array<AppBundle>): void {
    this.buildData(appBundleList);
    this.replaceTableWithCopy();
  }

  private buildData(appBundleList: Array<AppBundle>): void {
    const data: Array<AppBundleElement> = [];
    for (let i = 0; i < appBundleList.length; i++) {
      data.push(this.createAppBundleElement(appBundleList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createAppBundleElement(appBundle: AppBundle, index: number): AppBundleElement {
    const data: AppBundleElement = {
      ...getDefaultTableProperties(index),
      ...appBundle,
    };
    return data;
  }

  private checkIfDuplicateBundleLabelOnNewFileAdd(file: File): boolean {
    for (const appBundleElement of this.tableData) {
      if (appBundleElement.label === file.name) {
        this.notificationService.error('Application bundle with name "' + appBundleElement.label + '" already exists.');
        return true;
      }
    }
    return false;
  }

  private createAndAddNewAppBundleElement(appBundleElement: AppBundleElement, file: File): void {
    // Need to make a copy since we cannot modify the readonly data from the store.
    const appBundleElementCopy = cloneDeep(appBundleElement);
    this.store.dispatch(new ActionApiApplicationsCreatingAppBundle(appBundleElementCopy, file));
  }

  private makeNewAppBundleElement(file: File, params?: OptionalAppBundleElement): AppBundleElement {
    return {
      label: file.name,
      name: file.name,
      size: file.size,
      ...getDefaultNewRowProperties(),
      ...params,
    };
  }

  private addFile(file: File): void {
    if (this.checkIfDuplicateBundleLabelOnNewFileAdd(file)) {
      return;
    }
    const appBundleElement: AppBundleElement = this.makeNewAppBundleElement(file);
    this.createAndAddNewAppBundleElement(appBundleElement, file);
    return;
  }

  public addFiles(filesToAdd: Array<File>): void {
    if (!filesToAdd) {
      return;
    }
    for (const file of filesToAdd) {
      this.addFile(file);
    }
  }

  /**
   * Receives a AppBundleElement from the table then updates and saves
   * the file.
   */
  public updateEvent(updatedAppBundleElement: AppBundleElement): void {
    // Need to make a copy since we cannot modify the readonly data from the store.
    const updatedAppBundleElementCopy = cloneDeep(updatedAppBundleElement);
    this.store.dispatch(new ActionApiApplicationsModifyingAppBundle(updatedAppBundleElementCopy));
  }

  public deleteSelected(appBundlesToDelete: Array<AppBundleElement>): void {
    for (const appBundle of appBundlesToDelete) {
      this.deleteAppBundle(appBundle);
    }
  }

  private deleteAppBundle(appBundleToDelete: AppBundleElement): void {
    if (this.isAppBundleAssignedToEnvironment(appBundleToDelete)) {
      this.openDialog(appBundleToDelete);
      return;
    }
    // Need to make a copy of the appBundleToDelete or it will be converted to readonly.
    this.store.dispatch(new ActionApiApplicationsDeletingAppBundle(cloneDeep(appBundleToDelete)));
  }

  private isAppBundleAssignedToEnvironment(appBundleToDelete: AppBundleElement): boolean {
    for (const env of this.currentApp.environments) {
      if (env.serverless_image === appBundleToDelete.id) {
        return true;
      }
    }
    return false;
  }

  public readFile(event: any): void {
    const file = getFile(event);
    this.addFile(file);
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public openDialog(appBundleToDelete: AppBundleElement): void {
    const dialogData: ConfirmationDialogData = {
      messagePrefix: 'Deleting bundle "' + appBundleToDelete.label + '"!',
      message:
        'Warning: this bundle is in use by some instances of this application. If you delete it, their bundle will be cleared. Do you want to continue?',
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.store.dispatch(new ActionApiApplicationsDeletingAppBundle(appBundleToDelete));
        return;
      }
      appBundleToDelete.isChecked = false;
      this.replaceTableWithCopy();
    });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

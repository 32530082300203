import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState, NotificationService } from '@app/core';
import { ResourceObject } from '@app/core/api/state-driven-crud/state-driven-crud';
import { IconStatus } from '@app/core/basic-collection-state';
import { ActionCreator, DefaultProjectorFn, MemoizedSelector, select, Store } from '@ngrx/store';
import { Action as TypedAction } from '@ngrx/store';
import { combineLatest, concatMap, Observable, of, Subject, takeUntil } from 'rxjs';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { getFormattedResourceType } from '../resource-utils';
import {
  capitalizeFirstLetter,
  getDefaultIconPurpose,
  getIconFromResource,
  getIconURIFromResource,
  getIconURIWithoutQueryParams,
  replaceCharacterWithSpace,
} from '../utils';
import {
  AddFileRequestParams,
  CreateFileAssociationRequestParams,
  FileAssociation,
  FilesService,
  FileSummary,
  Label,
  LabelsService,
  ListFilesRequestParams,
  Resource,
  ResourcesService,
  ReuploadFileRequestParams,
} from '@agilicus/angular';
import { ResourceOverviewElement } from '../resource-overview/resource-overview.component';
import { updateExistingResource$ } from '@app/core/api/resources/resources-api-utils';
import { LabelOverviewElement } from '../label-overview/label-overview.component';
import { updateExistingObjectLabel$ } from '@app/core/api/labels/labels-api-utils';

export interface SaveFileResponse {
  iconStatus: IconStatus;
  fileSummary: FileSummary | undefined;
  resource: Resource | undefined;
  label: Label | undefined;
}

export interface OverviewData {
  element: ResourceOverviewElement | LabelOverviewElement;
  localUpdateFunc: (element?: ResourceOverviewElement) => void;
  isLabel: boolean;
}

export interface ResourceLogoDialogData<DataType extends ResourceObject<string>> {
  resourceType?: ResourceType;
  overviewData?: OverviewData;
  saveAction?: ActionCreator<
    string,
    (props: { file: File; obj: DataType }) => {
      file: File;
      obj: DataType;
    } & TypedAction<string>
  >;
  resetStatusAction?: ActionCreator<string, () => TypedAction<string>>;
  getResourceStateSelector?: MemoizedSelector<object, DataType, DefaultProjectorFn<DataType>>;
  getIconStatusStateSelector?: MemoizedSelector<object, IconStatus, DefaultProjectorFn<IconStatus>>;
}

@Component({
  selector: 'portal-resource-logo-dialog',
  templateUrl: './resource-logo-dialog.component.html',
  styleUrls: ['./resource-logo-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceLogoDialogComponent<DataType extends ResourceObject<string>> implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public resource: DataType | Resource;
  public label: Label;
  public isUploading = false;
  public progressBarController: ProgressBarController = new ProgressBarController();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public replaceCharacterWithSpace = replaceCharacterWithSpace;
  public getFormattedResourceType = getFormattedResourceType;

  constructor(
    public dialogRef: MatDialogRef<ResourceLogoDialogComponent<DataType>>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceLogoDialogData<DataType>,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private filesService: FilesService,
    private resourcesService: ResourcesService,
    private labelsService: LabelsService
  ) {}

  public ngOnInit(): void {
    if (!!this.data.overviewData) {
      const backingObject = this.data.overviewData.element.backingObject;
      if (this.data.overviewData.isLabel) {
        this.label = backingObject as Label;
      } else {
        this.resource = backingObject as Resource;
      }
      this.changeDetector.detectChanges();
      return;
    }
    const resourceState$ = this.store.pipe(select(this.data.getResourceStateSelector));
    const iconStatusState$ = this.store.pipe(select(this.data.getIconStatusStateSelector));
    combineLatest([resourceState$, iconStatusState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([resourceStateResp, iconStatusStateResp]) => {
        this.resource = resourceStateResp;
        this.handleProgressBarState(iconStatusStateResp);
        this.changeDetector.detectChanges();
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getResourceIconUrl(): string | undefined {
    if (!!this.label) {
      return !!getIconURIFromResource(this.label) ? getIconURIFromResource(this.label) : '';
    } else {
      return !!getIconURIFromResource(this.resource) ? getIconURIFromResource(this.resource) : '';
    }
  }

  public getIconActionText(): string {
    const icon = this.getResourceIconUrl();
    return !!icon ? 'modify the' : 'add an';
  }

  public uploadFile(file: File): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.initializeProgressBar();
    this.isUploading = true;
    if (!!this.data.overviewData?.element) {
      const iconStatus: IconStatus = {
        saving_icon_file: true,
        icon_file_save_success: false,
        icon_file_save_fail: false,
      };
      this.handleProgressBarState(iconStatus);
      if (!!this.resource) {
        this.saveFileAndUpdateResource$(file)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((saveFileAndUpdateResourceResp) => {
            if (!!saveFileAndUpdateResourceResp.resource) {
              this.resource = saveFileAndUpdateResourceResp.resource;
              this.data.overviewData.localUpdateFunc();
            }
            this.handleProgressBarState(saveFileAndUpdateResourceResp.iconStatus);
          });
      } else if (this.label) {
        this.saveFileAndUpdateLabel$(file)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe((saveFileAndUpdateResourceResp) => {
            if (!!saveFileAndUpdateResourceResp.label) {
              this.label = saveFileAndUpdateResourceResp.label;
              this.data.overviewData.localUpdateFunc();
            }
            this.handleProgressBarState(saveFileAndUpdateResourceResp.iconStatus);
          });
      }
    } else {
      this.store.dispatch(this.data.saveAction({ file: file, obj: this.resource as DataType }));
    }
  }

  public handleFileDropError(): void {
    // Need to reassign the progressBarController in order to
    // trigger the update in the template.
    this.progressBarController = this.progressBarController.resetProgressBar();
    this.notificationService.error('Cannot upload multiple files. Please select a single file for upload.');
  }

  /**
   * Delay hiding the progress bar by 2 seconds to match the successful
   * upload notification
   */
  public delayHideProgressBar(): void {
    setTimeout(() => {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.resetProgressBar();
      this.changeDetector.detectChanges();
    }, this.progressBarController.hideProgressBarDelay);
  }

  private handleProgressBarState(iconStatus: IconStatus): void {
    if (iconStatus.saving_icon_file) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.initializeProgressBar();
      this.isUploading = true;
    }
    if (!iconStatus.icon_file_save_success && !iconStatus.icon_file_save_fail) {
      return;
    }
    if (iconStatus.icon_file_save_success) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.updateProgressBarValue(1, 1);
      this.delayHideProgressBar();
    }
    if (iconStatus.icon_file_save_fail) {
      // Need to reassign the progressBarController in order to
      // trigger the update in the template.
      this.progressBarController = this.progressBarController.onFailedUpload();
    }
    if (!this.data.overviewData?.element) {
      this.store.dispatch(this.data.resetStatusAction());
    }
    this.isUploading = false;
    this.changeDetector.detectChanges();
  }

  private getSaveFileResponse$(file: File): Observable<SaveFileResponse> {
    const resourceAsResource = this.resource as Resource;
    const orgId = !!this.label ? this.label.spec.org_id : resourceAsResource.spec.org_id;
    const objectId = !!this.label ? this.label.metadata.id : resourceAsResource.metadata.id;
    const listFilesRequestParams: ListFilesRequestParams = {
      org_id: orgId,
      tag: objectId,
    };
    const saveFile$ = this.filesService.listFiles(listFilesRequestParams).pipe(
      concatMap((filesListResp) => {
        const icon = getIconFromResource(!!this.label ? this.label : resourceAsResource);
        const targetFile = !!icon
          ? filesListResp.files.find((file) => getIconURIWithoutQueryParams(file.public_url) === getIconURIWithoutQueryParams(icon.uri))
          : undefined;
        if (!targetFile) {
          const addFileParams: AddFileRequestParams = {
            name: file.name,
            file_zip: file,
            org_id: orgId,
            tag: objectId,
            label: 'resource_icon',
            visibility: 'public',
          };
          return this.filesService.addFile(addFileParams);
        }
        const reuploadFileRequestParams: ReuploadFileRequestParams = {
          file_id: targetFile.id,
          org_id: orgId,
          file_zip: file,
        };
        return this.filesService.reuploadFile(reuploadFileRequestParams).pipe();
      })
    );
    return saveFile$.pipe(
      concatMap((updateResp) => {
        const result: SaveFileResponse = {
          iconStatus: undefined,
          fileSummary: updateResp,
          resource: undefined,
          label: undefined,
        };
        if (!updateResp) {
          this.notificationService.error(`Failed to save new icon "${file.name}". Please try again.`);
          const iconStatus: IconStatus = {
            saving_icon_file: false,
            icon_file_save_success: false,
            icon_file_save_fail: true,
          };
          result.iconStatus = iconStatus;
          return of(result);
        }
        if (!!updateResp.has_been_associated) {
          const iconStatus: IconStatus = {
            saving_icon_file: false,
            icon_file_save_success: true,
            icon_file_save_fail: false,
          };
          result.iconStatus = iconStatus;

          return of(result);
        }
        const fileAssociation: FileAssociation = {
          spec: {
            file_id: updateResp.id,
            object_id: objectId,
            org_id: orgId,
          },
        };
        const createFileAssociationRequestParams: CreateFileAssociationRequestParams = {
          FileAssociation: fileAssociation,
        };
        return this.filesService.createFileAssociation(createFileAssociationRequestParams).pipe(
          concatMap((_) => {
            const iconStatus: IconStatus = {
              saving_icon_file: false,
              icon_file_save_success: true,
              icon_file_save_fail: false,
            };
            result.iconStatus = iconStatus;
            return of(result);
          })
        );
      })
    );
  }

  private updateResourceWithFileIcon$(fileSummary: FileSummary): Observable<Resource> {
    const resourceAsResource = this.resource as Resource;
    if (!resourceAsResource.spec.config) {
      resourceAsResource.spec.config = {
        display_info: {
          icons: [
            {
              purposes: [getDefaultIconPurpose()],
              uri: fileSummary.public_url,
            },
          ],
        },
      };
    } else if (!resourceAsResource.spec.config.display_info) {
      resourceAsResource.spec.config.display_info = {
        icons: [
          {
            purposes: [getDefaultIconPurpose()],
            uri: fileSummary.public_url,
          },
        ],
      };
    } else {
      const icon = getIconFromResource(resourceAsResource);
      if (!!icon) {
        icon.uri = fileSummary.public_url;
      } else {
        resourceAsResource.spec.config.display_info.icons.push({
          purposes: [getDefaultIconPurpose()],
          uri: fileSummary.public_url,
        });
      }
    }
    return updateExistingResource$(this.resourcesService, resourceAsResource);
  }

  private updateLabelWithFileIcon$(fileSummary: FileSummary): Observable<Label> {
    if (!this.label.spec.display_info) {
      this.label.spec.display_info = {
        icons: [
          {
            purposes: [getDefaultIconPurpose()],
            uri: fileSummary.public_url,
          },
        ],
      };
    } else {
      const icon = getIconFromResource(this.label);
      if (!!icon) {
        icon.uri = fileSummary.public_url;
      } else {
        this.label.spec.display_info.icons.push({
          purposes: [getDefaultIconPurpose()],
          uri: fileSummary.public_url,
        });
      }
    }
    return updateExistingObjectLabel$(this.labelsService, this.label);
  }

  private saveFileAndUpdateResource$(file: File): Observable<SaveFileResponse> {
    return this.getSaveFileResponse$(file).pipe(
      concatMap((saveFileResp) => {
        return this.updateResourceWithFileIcon$(saveFileResp.fileSummary).pipe(
          concatMap((updateResourceResp) => {
            saveFileResp.resource = updateResourceResp;
            return of(saveFileResp);
          })
        );
      })
    );
  }

  private saveFileAndUpdateLabel$(file: File): Observable<SaveFileResponse> {
    return this.getSaveFileResponse$(file).pipe(
      concatMap((saveFileResp) => {
        return this.updateLabelWithFileIcon$(saveFileResp.fileSummary).pipe(
          concatMap((updateLabelResp) => {
            saveFileResp.label = updateLabelResp;
            return of(saveFileResp);
          })
        );
      })
    );
  }
}

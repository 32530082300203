<portal-page-info
  pageTitle="Diagnose Application"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions" class="application-diagnose-container">
  <h2 class="diagnose-filter">Filter by:</h2>
  <form
    class="diagnose-form"
    [formGroup]="diagnoseAppForm"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="set-log-time">
      <mat-form-field class="diagnose-filter-field">
        <mat-label>Select a time frame:</mat-label>
        <mat-select 
          #select1
          formControlName="quick_select_time"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
        >
          <mat-option value="custom">Custom</mat-option>
          <mat-option
            *ngFor="let time of timeIntervalOptions"
            value="{{ time.interval }}"
            >{{ time.displayedInterval }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="diagnose-filter-field"
        *ngIf="diagnoseAppForm.value.quick_select_time === 'custom'"
      >
        <mat-label>Choose start date</mat-label> 
        <input
          matInput
          [max]="maxDate"
          [owlDateTime]="dt1"
          [owlDateTimeTrigger]="dt1"
          formControlName="dtFrom"
          [owlDateTimeFilter]="getStartDateMaxSetter(diagnoseAppForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt1></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>

      <p
        class="diagnose-filter-field diagnose-filter-field-p"
        *ngIf="diagnoseAppForm.value.quick_select_time === 'custom'"
      >
        to
      </p>

      <mat-form-field
        class="diagnose-filter-field"
        *ngIf="diagnoseAppForm.value.quick_select_time === 'custom'"
      >
        <mat-label>Choose end date</mat-label> 
        <input
          matInput
          [owlDateTime]="dt2"
          [owlDateTimeTrigger]="dt2"
          formControlName="dtTo"
          [owlDateTimeFilter]="getEndDateMinSetter(diagnoseAppForm)"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <owl-date-time #dt2></owl-date-time>
        <mat-error>Enter valid date and time</mat-error>
      </mat-form-field>
    </div>

    <mat-form-field class="diagnose-filter-field">
      <mat-select 
        #select2
        formControlName="dtSort"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select2)"
      >
        <mat-option value="desc">Newest first</mat-option>
        <mat-option value="asc">Oldest first</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="diagnose-filter-field">
      <mat-label>Max number of results</mat-label> 
      <input
        matInput
        type="text"
        formControlName="limit"
        (keyup.enter)="keyTabManager.keyTab($event.target.id)"
      />
      <mat-error>Please enter a valid number</mat-error>
    </mat-form-field>

    <mat-form-field class="diagnose-filter-field">
      <mat-label>Application name</mat-label> 
      <input
        matInput
        type="text"
        formControlName="app"
        [matAutocomplete]="auto"
        #trigger="matAutocompleteTrigger"
        (keyup.enter)="keyTabManager.keyTabAutoInput($event.target.id, trigger)"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option
          *ngFor="let option of filteredAppOptions | async"
          [value]="option"
        >
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-form-field class="diagnose-filter-field diagnose-environment-form-field">
      <mat-label>Environment</mat-label>
      <mat-select 
        #select3
        formControlName="env"
        (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select3)"
      >
        <mat-option value=""></mat-option>
        <mat-option value="prod">Production</mat-option>
        <mat-option value="dev">Development</mat-option>
      </mat-select>
    </mat-form-field>

    <div class="button-container">
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="show-logs-button"
        [disabled]="!diagnoseAppForm.valid"
        (click)="getLogs()"
      >
        <fa-icon [icon]="['fas', 'search']"></fa-icon> VIEW LOGS
      </button>
      <button
        type="button"
        mat-raised-button
        color="primary"
        class="download-button"
        (click)="getLogsAndDownload()"
      >
        <mat-icon class="download-icon">cloud_download</mat-icon> DOWNLOAD LOGS
      </button>
    </div>
  </form>

  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [filterMenuOptions]="filterMenuOptions"
    [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
    [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
    [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
    [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
  >
  </portal-table-layout>
</div>

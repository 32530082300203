import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { KeyTabManager } from '../../key-tab-manager/key-tab-manager';
import { PathValidationDialogComponent } from '../../path-validation-dialog/path-validation-dialog.component';

@Component({
  selector: 'portal-stepper-form-field',
  templateUrl: './stepper-form-field.component.html',
  styleUrls: ['./stepper-form-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StepperFormFieldComponent {
  @Input() public placeholder = '';
  @Input() public label = '';
  @Input() public formControlName: string;
  @Input() public required = false;
  // Workaround for angular stepper bug:
  // See: https://github.com/angular/components/issues/20923
  @Input() public accessOptionChanged = false;
  @Input() public formCtrl: UntypedFormControl;
  @Input() public tooltip = '';
  @Input() public validateAsPath = false;
  @Output() public formBlur = new EventEmitter<any>();

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor(private dialog: MatDialog) {}

  @HostListener('paste', ['$event'])
  public onPaste(event: ClipboardEvent): void {
    if (!this.validateAsPath) return;

    const pastedText = event.clipboardData?.getData('text') || '';

    if (this.containsQuoteIssues(pastedText)) {
      event.preventDefault();
      this.showValidationDialog(pastedText);
    }
  }

  // Check if Path Contains Quoting Issues
  private containsQuoteIssues(path: string): boolean {
    return /["']/.test(path) || this.isInvalidWindowsPath(path);
  }

  // Windows does not allow "" at all
  private isInvalidWindowsPath(path: string): boolean {
    return navigator.platform.includes('Win') && path.includes('""');
  }

  // Show Confirmation Dialog
  private showValidationDialog(originalInput: string): void {
    const dialogRef = this.dialog.open(PathValidationDialogComponent, {
      width: '400px',
      data: { originalInput },
    });

    dialogRef.afterClosed().subscribe((correctedInput) => {
      if (correctedInput !== undefined) {
        this.formCtrl.setValue(correctedInput);
      }
    });
  }

  public onFormBlurEvent(): void {
    this.formBlur.emit();
  }

  public getLabel(): string {
    return !!this.label ? this.label : this.placeholder;
  }
}

<div *ngIf="!fixedData" [@.disabled]="true">

  <div class="csp-text-title-container">
    <portal-page-info
      [pageDescriptiveText]="cspDescriptiveText"
      [productGuideLink]="cspProductGuideLink"
      pageInfoWidth="full"
    >
    </portal-page-info>

    <button mat-raised-button class="defaults-button" color="primary" matTooltip="Clear Content-Security-Policy"
      (click)="applyDefaults(defaultTypes.noCSP)">
      Clear
    </button>

    <button mat-raised-button class="defaults-button" color="primary"
      matTooltip="Apply defaults for strict Angular application" (click)="applyDefaults(defaultTypes.strictAngular)">
      Apply Strict Angular Defaults
    </button>

    <button mat-raised-button class="defaults-button" color="primary"
      matTooltip="Apply defaults for lax AngularJS application" (click)="applyDefaults(defaultTypes.laxAngularJS)">
      Apply Lax AngularJS Defaults
    </button><br><br>

    <div class="csp-text-title-container">
      <h4 class="csp-text-title">Generated Content Security Policy</h4>
      <mat-icon class="help-icon"
        matTooltip="Below is the content-security policy. You may copy this and use it externally or paste in a raw value and it will be parsed.">
        help_outline
      </mat-icon>
    </div>
    <mat-form-field appearance="outline">
      <textarea matInput value="{{ currentContentSecurityPolicyString }}" (blur)="onFormFieldEdit($event.target.value)"
        (keyup.control.enter)="keyTabManager.keyTab($event.target.id)" spellcheck="false" autocomplete="off"
        #inputTarget>
      </textarea>
      <button mat-raised-button color="primary" class="copy-button"
        matTooltip="Copy '{{ currentContentSecurityPolicyString }}'" [ngxClipboard]="inputTarget">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>

    <form
      [formGroup]="cspForm"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="enabled-checkbox-container">
        <mat-checkbox
          formControlName="enabled"
          (change)="onCheckboxChange()"
          aria-label="Checkbox that toggles whether the content security policy is enabled"
        >
          Enabled
        </mat-checkbox>
        <mat-icon 
          class="help-icon"
          [matTooltip]="getCspEnabledTooltipText()"
        >
          help_outline
        </mat-icon>
      </div>

      <div class="mode-select-container">
        <mat-form-field>
          <mat-label>Mode</mat-label>
          <mat-select 
            #selectMode
            formControlName="mode"
            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectMode)"
            (selectionChange)="updateSelectedMode()"
          >
            <mat-option
              *ngFor="let option of getModeValues()"
              [value]="option"
            >
              {{ capitalizeFirstLetter(getModeDisplayValue(option)) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon 
          class="help-icon"
          [matTooltip]="getCspModeTooltipText()"
        >
          help_outline
        </mat-icon>
      </div>
    </form>

    <mat-accordion class="expansion-container" multi>
      <mat-expansion-panel
        *ngFor="let directive of cspDirectives"
        (opened)="onPanelOpen(directive.name)"
        (closed)="onPanelClose(directive.name)"
        [expanded]="getPanelState(directive.name)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{ capitalizeFirstLetter(directive.name) }}
          </mat-panel-title>
          <mat-panel-description *ngIf="directive.isEnabled">
            <mat-icon class="enabled-icon" matTooltip="Enabled">
              check_circle
            </mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <div *ngIf="getPanelState(directive.name)" class="panel-content-container">
          <h4 class="sources-title">Policy Entry</h4>
          <section class="csp-source-options">
            <mat-checkbox
              *ngFor="let option of getKeysFromMap(directive.sources)"
              (click)="$event.stopPropagation()"
              (change)="onCheckboxUpdate(directive, option, $event.checked)"
              [checked]="isOptionChecked(directive.name, option)"
              class="csp-source-checkbox"
              (keyup.enter)="keyTabManager.keyTab($event.target.id)"
              [disabled]="savingCsp"
            >
              {{ getSourceDisplayValue(option) }}
            </mat-checkbox>
          </section>

          <h4 class="hosts-title">Hosts</h4>
          <mat-form-field class="chiplist-form-field" spellcheck="false" autocomplete="off">
            <mat-chip-grid #chipList>
              <mat-chip-row
                *ngFor="let host of directive.hosts"
                [selectable]="filterChipOptions.selectable"
                [removable]="filterChipOptions.removable && !savingCsp"
                (removed)="removeChip(host, directive)"
              >
                {{ host }}
                <mat-icon matChipRemove *ngIf="!fixedData">cancel</mat-icon>
              </mat-chip-row>
              <input 
                #input 
                [formControl]="directive.hostsFormControl" 
                [matChipInputFor]="chipList"
                [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
                (matChipInputTokenEnd)="addChipOnInputEvent($event, directive)"
                matTooltip="Separate multiple entries by a semicolon"
                (keyup.enter)="keyTabManager.keyTabChipList($event.target.value, $event.target.id, undefined, newChipAdded)"
                (blur)="saveOnInputBlur(directive)"
                [disabled]="savingCsp"
              />
            </mat-chip-grid>
          </mat-form-field>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <portal-progress-spinner-dialog *ngIf="savingCsp"></portal-progress-spinner-dialog>
import { NgModule, Optional, SkipSelf, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule, RouterStateSerializer, FullRouterStateSerializer } from '@ngrx/router-store';

import { environment } from '@env/environment';

import { httpInterceptorProviders } from './http-interceptors';
import { AuthGuardService } from './auth/auth-guard.service';
import { TitleService } from './title/title.service';
import { reducers, metaReducers } from './core.state';
import { AppErrorHandler } from './error-handler/app-error-handler.service';
import { CustomSerializer } from './router/custom-serializer';
import { NotificationService } from './notifications/notification.service';
import * as fromApplicationService from '@app/core/application-service-state/application-service.reducer';
import * as fromIssuer from '@app/core/issuer-state/issuer.reducer';
import * as fromConnector from '@app/core/connector-state/connector.reducer';
import * as fromAgentConnector from '@app/core/agent-connector-state/agent-connector.reducer';
import * as fromFileShareService from '@app/core/file-share-state/file-share-service.reducer';
import * as fromDesktop from '@app/core/desktop-state/desktop.reducer';
import * as fromLauncher from '@app/core/launcher-state/launcher.reducer';
import * as fromSsh from '@app/core/ssh-state/ssh.reducer';
import * as fromAPIKey from '@app/core/api-key-state/api-key.reducer';
import * as fromBillingAccountFull from '@app/core/billing-state/billing-account-full.reducer';
import * as fromInboxItem from '@app/core/inbox-item-state/inbox-item.reducer';
import * as fromPolicyTemplateInstance from '@app/core/policy-template-instance-state/policy-template-instance.reducer';

@NgModule({
  imports: [
    // angular
    CommonModule,
    // ngrx
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    StoreModule.forFeature(fromApplicationService.applicationServicesFeatureKey, fromApplicationService.applicationServicesReducer),
    StoreModule.forFeature(fromIssuer.issuersFeatureKey, fromIssuer.issuerReducer),
    StoreModule.forFeature(fromConnector.connectorsFeatureKey, fromConnector.connectorReducer),
    StoreModule.forFeature(fromAgentConnector.agentConnectorsFeatureKey, fromAgentConnector.agentConnectorReducer),
    StoreModule.forFeature(fromFileShareService.fileShareServicesFeatureKey, fromFileShareService.fileShareServiceReducer),
    StoreModule.forFeature(fromDesktop.desktopsFeatureKey, fromDesktop.desktopReducer),
    StoreModule.forFeature(fromLauncher.launcersFeatureKey, fromLauncher.launcherReducer),
    StoreModule.forFeature(fromSsh.sshsFeatureKey, fromSsh.sshReducer),
    StoreModule.forFeature(fromAPIKey.apiKeysFeatureKey, fromAPIKey.apiKeyReducer),
    StoreModule.forFeature(fromBillingAccountFull.billingAccountFullsFeatureKey, fromBillingAccountFull.billingAccountFullReducer),
    StoreModule.forFeature(fromInboxItem.inboxItemFeatureKey, fromInboxItem.inboxItemReducer),
    StoreModule.forFeature(
      fromPolicyTemplateInstance.policyTemplateInstancesFeatureKey,
      fromPolicyTemplateInstance.policyTemplateInstanceReducer
    ),
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Agilicus Portal',
          maxAge: 30,
          // logOnly: environment.production
        }),
  ],
  declarations: [],
  providers: [
    NotificationService,
    AuthGuardService,
    httpInterceptorProviders,
    TitleService,
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ],
  exports: [],
})
export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}

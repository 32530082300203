<portal-page-info
  pageTitle="Policies"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="shouldGateAccess()" class="gate-access-container">
  <h2>
    This feature is not currently available.
  </h2>
</div>

<div 
  *ngIf="hasRulesPermissions && !shouldGateAccess()" 
  class="policy-template-container"
>
  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [preventLegacyTableView]="preventLegacyTableView"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    [warnOnNOperations]="warnOnNOperations"
    [alternateRowColor]="true"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>

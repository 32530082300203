import { ChangeDetectorRef } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NotificationService } from '@app/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { InputData } from './input-data';
import { ChiplistInput } from './chiplist-input';
import { InputSize } from './input-size.enum';
import { MultiAutocompleteInputValues } from './multi-autocomplete-input-values';
import { ColorType } from '../color-type.enum';
import {
  capitalizeFirstLetter,
  getDuplicateChipValues,
  getDuplicateInputValues,
  handleDuplicateChipValues,
  handleDuplicateInputValues,
  replaceCharacterWithSpace,
} from '../utils';
import { ColumnTypes } from '../column-types.enum';

export function createChiplistInput<T extends InputData>(name: string): ChiplistInput<T> {
  return {
    name,
    displayName: capitalizeFirstLetter(replaceCharacterWithSpace(name, '_')),
    allowedValues: [],
    filterOn: true,
    isEditable: true,
    isUnique: false,
    isCaseSensitive: false,
    isRemovable: true,
    allowRemoveAllChips: () => false,
    hasAutocomplete: true,
    isFreeform: false,
    newChipAdded: false,
    autoDropdownOptionSelected: false,
    hasMultiAutocomplete: false,
    isFirstMultiAutocompleteOptionSelected: false,
    addOnInput: true,
    isFormInputDirty: false,
    inError: false,
    isSelectingAutocompleteOption: false,
    inputSize: InputSize.STANDARD,
    formControl: new UntypedFormControl(),
    requiredField: () => {
      return false;
    },
    getChipColor: () => {
      return ColorType.primary;
    },
    getDisplayValue: (item: object | string, input: ChiplistInput<T>): string => {
      if (!!input?.hasMultiAutocomplete) {
        return input.getMultiAutocompleteOptionDisplayValue(item as string, input);
      }
      return item as string;
    },
    getTooltip: () => {
      return '';
    },
    getElementFromValue: (item: string): any => {
      return item;
    },
    isValidEntry: (): boolean => {
      return true;
    },
    disableField: () => {
      return false;
    },
    getSecondaryFilteredValues: (input: ChiplistInput<T>) => {
      return getSecondaryFilteredValues(input.formControl, input);
    },
    getSecondaryAllowedValues: () => {
      return [];
    },
    onInputValueChange: (inputValue: string, input: ChiplistInput<T>) => {
      onDefaultInputValueChange(inputValue, input);
    },
    getOptionValue: (option: any): string => {
      return option;
    },
    getOptionDisplayValue: (option: any, element: T): string => {
      return option;
    },
    getOptionTooltip: (option: any, element: T): string => {
      return '';
    },
    getChiplistValues: (element: T, chiplistInput: ChiplistInput<T>) => {
      return getChipValuesList(element, chiplistInput);
    },
    getMultiAutocompleteChipValueSeparator: () => {
      return getDefaultMultiAutocompleteChipValueSeparator();
    },
    getMultiAutocompleteOptionDisplayValue: (value: string, input: ChiplistInput<T>): string => {
      const multiAutocompleteInputValues = getMultiAutocompleteInputValues(value, input);
      if (input.isFirstMultiAutocompleteOptionSelected) {
        return multiAutocompleteInputValues.secondInputValue;
      }
      return multiAutocompleteInputValues.firstInputValue;
    },
    showColumn: true,
    type: ColumnTypes.CHIPLIST,
  };
}

export function getDefaultMultiAutocompleteChipValueSeparator(): string {
  return ':';
}

export function filterAllowedValues<T extends InputData>(chiplistInput: ChiplistInput<T>, value: string): Array<T> {
  const filterValue = value.toLowerCase();
  return chiplistInput.allowedValues.filter((entry) => chiplistInput.getDisplayValue(entry).toLowerCase().includes(filterValue));
}

export function getFilteredValues<T extends InputData>(
  formControl: UntypedFormControl,
  chiplistInput: ChiplistInput<T>
): Observable<Array<string>> {
  return formControl.valueChanges.pipe(
    startWith(formControl.value as string),
    map((value: string | null) => {
      chiplistInput.onInputValueChange(value, chiplistInput);
      return value ? filterAllowedValues(chiplistInput, value) : chiplistInput.allowedValues.slice();
    })
  );
}

export function filterSecondaryAllowedValues<T extends InputData>(chiplistInput: ChiplistInput<T>, value: string): Array<T> {
  const multiAutocompleteInputValues = getMultiAutocompleteInputValues(value, chiplistInput);
  const filterValue = multiAutocompleteInputValues.secondInputValue.toLowerCase();
  return chiplistInput
    .getSecondaryAllowedValues(multiAutocompleteInputValues.firstInputValue)
    .filter((entry) => chiplistInput.getDisplayValue(entry).toLowerCase().includes(filterValue));
}

export function getSecondaryFilteredValues<T extends InputData>(
  formControl: UntypedFormControl,
  chiplistInput: ChiplistInput<T>
): Observable<Array<string>> {
  return formControl.valueChanges.pipe(
    startWith(formControl.value as string),
    map((value: string | null) => {
      chiplistInput.onInputValueChange(value, chiplistInput);
      return value ? filterSecondaryAllowedValues(chiplistInput, value) : chiplistInput.getSecondaryAllowedValues('').slice();
    })
  );
}

export function onDefaultInputValueChange<T extends InputData>(inputValue: string, chiplistInput: ChiplistInput<T>): void {
  if (!chiplistInput.hasMultiAutocomplete) {
    return;
  }
  if (inputValue?.includes(chiplistInput.getMultiAutocompleteChipValueSeparator())) {
    chiplistInput.isFirstMultiAutocompleteOptionSelected = true;
  } else {
    chiplistInput.isFirstMultiAutocompleteOptionSelected = false;
  }
}

export function getMultiAutocompleteInputValues<T extends InputData>(
  value: string,
  chiplistInput: ChiplistInput<T>
): MultiAutocompleteInputValues {
  const splitValueArray = value.split(chiplistInput.getMultiAutocompleteChipValueSeparator());
  const firstInputValue = splitValueArray[0];
  let secondInputValue = splitValueArray[1];
  if (!secondInputValue) {
    secondInputValue = '';
  }
  return {
    firstInputValue,
    secondInputValue,
  };
}

export function doValuesExistInOptionsList<T extends InputData>(valuesArray: Array<string>, chiplistInput: ChiplistInput<T>): boolean {
  if (chiplistInput.hasMultiAutocomplete) {
    if (chiplistInput.isFirstMultiAutocompleteOptionSelected) {
      const multiAutocompleteInputValues = getMultiAutocompleteInputValues(valuesArray[0], chiplistInput);
      return chiplistInput.getSecondaryAllowedValues(multiAutocompleteInputValues.firstInputValue).includes(valuesArray[0]);
    }
  }
  const allowedValuesDisplayValuesList = chiplistInput.allowedValues.map((item) => chiplistInput.getDisplayValue(item));
  for (const value of valuesArray) {
    if (allowedValuesDisplayValuesList.includes(value)) {
      return true;
    }
  }
  return false;
}

/**
 * Gets the input values entered by the user that do not exist
 */
export function getNonexistantValues<T extends InputData>(
  valuesArray: Array<string>,
  chiplistInput: ChiplistInput<T>,
  element: T
): Array<string> {
  const nonexistantValues = [];
  for (const value of valuesArray) {
    const elementFromValue = chiplistInput.getElementFromValue(value, element);
    if (elementFromValue === undefined) {
      nonexistantValues.push(value);
    }
  }
  return nonexistantValues;
}

export function handleNonexistantValues(nonexistantValues: Array<string>, notificationService: NotificationService): void {
  let message = nonexistantValues.join(', ');
  if (nonexistantValues.length === 1) {
    message += ' does not exist';
  } else {
    message += ' do not exist';
  }
  notificationService.error(message);
}

export function handleInvalidValues(invalidValues: Array<string>, notificationService: NotificationService): void {
  let message = invalidValues.join(', ');
  if (invalidValues.length === 1) {
    message += ' is not valid';
  } else {
    message += ' are not valid';
  }
  notificationService.error(message);
}

export function getExistingChipValues<T extends InputData>(element: T, chiplistInput: ChiplistInput<T>): Set<string> {
  const existingChipValues: Set<string> = new Set();
  for (const item of chiplistInput.getChiplistValues(element, chiplistInput)) {
    existingChipValues.add(chiplistInput.getDisplayValue(item));
  }
  return existingChipValues;
}

/**
 * Gets the input values entered by the user that are invalid
 */
export async function getInvalidValues<T extends InputData>(
  valuesArray: Array<string>,
  chiplistInput: ChiplistInput<T>,
  element: T
): Promise<Array<string>> {
  const invalidValues = [];
  if (chiplistInput.isValidEntry === undefined) {
    return invalidValues;
  }
  for (const value of valuesArray) {
    const isValidEntryResult = await Promise.resolve(
      chiplistInput.isValidEntry(chiplistInput.getElementFromValue(value, element), element)
    );
    if (!isValidEntryResult) {
      invalidValues.push(value);
    }
  }
  return invalidValues;
}

/**
 * Adds a new chip to the chips input when an autocomplete option is clicked
 */
export function addChipOnAutoSelectEvent<T extends InputData>(chipValue: string, element: T, chiplistInput: ChiplistInput<T>): void {
  onNewChipAdded(chiplistInput);
  const newChipElementValue = chiplistInput.getElementFromValue(chipValue, element);
  chiplistInput.getChiplistValues(element, chiplistInput).push(newChipElementValue);
  element.dirty = true;
}

export function onNewChipAdded<T extends InputData>(chiplistInput: ChiplistInput<T>): void {
  chiplistInput.newChipAdded = true;
}

export function updateMultiAutocompleteInputOnFirstOptionSelection<T extends InputData>(
  value: string,
  chiplistInput: ChiplistInput<T>
): string {
  return `${value}${chiplistInput.getMultiAutocompleteChipValueSeparator()}`;
}

/**
 * Converts valid/existing chip list values to their appropriate elements
 * and adds those elements to the target element
 */
export function updateElementFromChiplistValues<T extends InputData>(
  valuesArray: Array<string>,
  element: T,
  chiplistInput: ChiplistInput<T>
): void {
  for (const value of valuesArray) {
    const elementFromValue = chiplistInput.getElementFromValue(value, element);
    if (elementFromValue !== undefined) {
      chiplistInput.getChiplistValues(element, chiplistInput).push(elementFromValue);
    }
  }
}

/**
 * Converts valid/existing chip list values to their appropriate elements
 * and adds those elements to the target element. Then refreshes the UI view.
 */
export function updateElementFromChiplistValuesAndDetechChanges<T extends InputData>(
  valuesArray: Array<string>,
  element: T,
  chiplistInput: ChiplistInput<T>,
  changeDetector: ChangeDetectorRef
): void {
  updateElementFromChiplistValues(valuesArray, element, chiplistInput);
  changeDetector.detectChanges();
}

export function getChipValuesList<T extends InputData>(element: T, chiplistInput: ChiplistInput<T>): Array<string> {
  if (!element) {
    return [];
  }
  const chipValuesList = element[chiplistInput?.name];
  if (!chipValuesList) {
    return [];
  }
  return chipValuesList;
}

/**
 * A valid multi-autocomplete chip value must be in the format of
 * <firstValue>chiplistInput.getMultiAutocompleteChipValueSeparator()<secondValue>.
 * For example, a:b is a valid value if the MultiAutocompleteChipValueSeparator is ":".
 */
export function isValidMultiAutocompleteChipValue<T extends InputData>(value: string, chiplistInput: ChiplistInput<T>): boolean {
  if (!value) {
    return false;
  }
  if (!value.includes(chiplistInput.getMultiAutocompleteChipValueSeparator())) {
    return false;
  }
  const multiAutocompleteInputValues = getMultiAutocompleteInputValues(value, chiplistInput);
  if (!multiAutocompleteInputValues.firstInputValue || !multiAutocompleteInputValues.secondInputValue) {
    return false;
  }
  return true;
}

/**
 * Will return true if the user enters values that already exist in the chiplist and notify the user.
 */
export function areExistingChipValuesEntered<T extends InputData>(
  element: T,
  chiplistInput: ChiplistInput<T>,
  valuesArray: Array<string>,
  notificationService: NotificationService
): boolean {
  const existingChipValues = getExistingChipValues(element, chiplistInput);
  const duplicateChipValues = getDuplicateChipValues(valuesArray, existingChipValues);
  if (duplicateChipValues.length > 0) {
    handleDuplicateChipValues(duplicateChipValues, notificationService);
    return true;
  }
  return false;
}

export function getIncompleteChipValueErrorMessage<T extends InputData>(chiplistInput: ChiplistInput<T>): string {
  const message = 'chip was not entered due to incomplete input data! Please enter a valid chip value.';
  return !!chiplistInput.displayName ? `${chiplistInput.displayName} ${message}` : capitalizeFirstLetter(message);
}

export function getAlreadyExistsChipValueErrorMessage(): string {
  return `This value already exists in the list. Please enter a new value.`;
}

/**
 * Reset the form control so it rebuilds the list of allowed members
 */
export function resetAutocompleteDropdownFilteredList<T extends InputData>(chiplistInput: ChiplistInput<T>) {
  chiplistInput.formControl.reset();
}

export function getMaxChiplistLength(): number {
  return 10;
}

/**
 * Will return true if values exist in the list that do not pass the validation requirements for the input.
 */
export async function areInvalidValuesEntered<T extends InputData>(
  element: T,
  chiplistInput: ChiplistInput<T>,
  valuesArray: Array<string>,
  notificationService: NotificationService
): Promise<boolean> {
  const invalidValues = await getInvalidValues(valuesArray, chiplistInput, element);
  if (invalidValues.length > 0) {
    handleInvalidValues(invalidValues, notificationService);
    return true;
  }
  return false;
}

/**
 * Will return true if the user enters multiple values separated by semicolons
 * and the same value is included more than once
 */
export function areDuplicateInputValuesEntered(valuesArray: Array<string>, notificationService: NotificationService): boolean {
  const duplicateInputValues = getDuplicateInputValues(valuesArray);
  if (duplicateInputValues.length > 0) {
    handleDuplicateInputValues(duplicateInputValues, notificationService);
    return true;
  }
  return false;
}

/**
 * Will return true if the user enters values that do not exist in the dropdown list
 */
export function areNonexistantOptionValuesEntered<T extends InputData>(
  element: T,
  chiplistInput: ChiplistInput<T>,
  valuesArray: Array<string>,
  notificationService: NotificationService
): boolean {
  const nonexistantValues = getNonexistantValues(valuesArray, chiplistInput, element);
  if (nonexistantValues.length > 0) {
    handleNonexistantValues(nonexistantValues, notificationService);
    return true;
  }
  return false;
}

export function getChiplistDialogMultiAutocompleteColumnName(): string {
  return 'secondary_option';
}

import { User } from '@agilicus/angular';
import { catchError, map, Observable, of } from 'rxjs';
import * as sha256 from 'fast-sha256';
import { environment as env } from '@env/environment';
import { AuthService } from '@app/core/services/auth-service.service';
import { getSubdomainFromIssuer } from '@app/core/issuer-state/issuer.utils';

export function getProductLinkFromUser$(url: string, authService: AuthService): Observable<string> {
  return authService
    .auth()
    .user$()
    .pipe(
      map((userResp: User) => {
        const email = userResp?.email;
        if (!email) {
          return url;
        }
        const te = new TextEncoder();
        const hash = sha256.hmac(te.encode(env.tawk_key), te.encode(email));
        const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');
        const session = sessionStorage.getItem('e-session');
        const mydomain = getSubdomainFromIssuer(window.location.href);
        const parsedURL = new URL(url);
        parsedURL.searchParams.set('email', email);
        parsedURL.searchParams.set('domain', mydomain);
        parsedURL.searchParams.set('tawk_hash', hashHex);
        if (!!session) {
          parsedURL.searchParams.set('esess', session);
        }
        const parsedURLString = parsedURL.toString();
        return parsedURLString;
      }),
      catchError((_) => of(url))
    );
}

export function getProductLinkViaLocalStorage(url: string): string {
  const email = localStorage.getItem('email');
  if (!email) {
    return url;
  }
  const te = new TextEncoder();
  const hash = sha256.hmac(te.encode(env.tawk_key), te.encode(email));
  const hashHex = Array.prototype.map.call(hash, (x) => x.toString(16).padStart(2, '0')).join('');
  return url + `?email=${encodeURIComponent(email)}&tawk_hash=${encodeURIComponent(hashHex)}`;
}

import { Label, LabelAssociation, LabelledObject, LabelsService, Resource } from '@agilicus/angular';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TableElement } from '../table-layout/table-element';
import { combineLatest, concatMap, forkJoin, Observable, of, Subject, takeUntil } from 'rxjs';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createChipListColumn,
  createInputColumn,
  createResourceIconColumn,
  createSelectRowColumn,
  IconColumn,
  InputColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { FilterManager } from '../filter/filter-manager';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { NotificationService } from '@app/core';
import { ResourceLabelLinkService } from '@app/core/resource-label-link-service/resource-label-link.service';
import { MatDialog } from '@angular/material/dialog';
import { ResourceAndLabelDataWithPermissions } from '../resource-and-label-data-with-permissions';
import { resetAutocompleteDropdownFilteredList } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { getIconURIFromResource, updateTableElements } from '../utils';
import { getDefaultNewRowProperties, getDefaultTableProperties, isTableDirty } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { getResourceNameAndTypeString } from '../resource-utils';
import {
  createNewLabelledObject$,
  createNewObjectLabel$,
  deleteExistingLabelsList$,
  updateExistingLabelledObject$,
  updateExistingObjectLabel$,
} from '@app/core/api/labels/labels-api-utils';
import { InputData } from '../custom-chiplist-input/input-data';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';
import { getDefaultLogoDialogConfig } from '../dialog-utils';

export interface LabelWithResources {
  name: string;
  resources: Array<Resource>;
  previousResources: Array<Resource>;
  labelledObjects: Array<LabelledObject>;
  navigationEnabled: boolean;
  created: Date;
  backingObject: Label;
}

export interface LabelOverviewElement extends LabelWithResources, TableElement {}

export interface LabelAndResourcesResponse {
  label: Label;
  resources: Array<LabelAssociation>;
  labelledObjects: Array<LabelledObject>;
}

export interface CombinedResourceAndLabelledObjectData {
  resources: Array<Resource>;
  labelledObjects: Array<LabelledObject>;
}

export interface LabelsResponse {
  labelledObjects: Array<LabelledObject>;
  label: Label;
}

export interface LabelledObjectUpdateData {
  labelledObjectsToCreate: Array<LabelledObject>;
  labelledObjectsToUpdate: Array<LabelledObject>;
}

@Component({
  selector: 'portal-label-overview',
  templateUrl: './label-overview.component.html',
  styleUrl: './label-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasLabelPermissions: boolean;
  public hasResourcePermissions: boolean;
  public hasPermissions: boolean;
  private orgId: string;
  public tableData: Array<LabelOverviewElement> = [];
  public columnDefs: Map<string, Column<LabelOverviewElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'LABEL';
  public warnOnNOperations = 1;
  // TODO: add this:
  public pageDescriptiveText = ``;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/labels/`;
  private resourceIdToResourceMap: Map<string, Resource> = new Map();
  private resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  private resources: Array<Resource> = [];
  private resourceIdToLabelledObjectMap: Map<string, LabelledObject> = new Map();
  private labelledObjects: Array<LabelledObject> = [];
  private labels: Array<Label> = [];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private editingTable = false;
  public updateDataCount = 0;
  public delayedUpdateDataCount = 0;
  public sortDataBy = 'created';

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<LabelOverviewElement>;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private labelsService: LabelsService,
    private notificationService: NotificationService,
    public dialog: MatDialog,
    private resourceLabelLinkService: ResourceLabelLinkService
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAndSetAllData(): void {
    this.resourceLabelLinkService
      .getResourceAndLabelDataWithPermissions$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelDataWithPermissionsResp: ResourceAndLabelDataWithPermissions) => {
        this.orgId = resourceAndLabelDataWithPermissionsResp.hasLabelPermissions?.orgId;
        this.hasLabelPermissions = resourceAndLabelDataWithPermissionsResp.hasLabelPermissions.hasPermission;
        this.hasResourcePermissions = resourceAndLabelDataWithPermissionsResp.hasResourcePermissions.hasPermission;
        const hasPermissions =
          resourceAndLabelDataWithPermissionsResp.hasLabelPermissions.hasPermission &&
          resourceAndLabelDataWithPermissionsResp.hasResourcePermissions.hasPermission;
        if (
          !hasPermissions ||
          !resourceAndLabelDataWithPermissionsResp.labelledObjects ||
          !resourceAndLabelDataWithPermissionsResp.labelsList ||
          !resourceAndLabelDataWithPermissionsResp.resourcesList
        ) {
          this.resetEmptyTable();
          return;
        }
        this.labels = resourceAndLabelDataWithPermissionsResp.labelsList;
        this.resources = resourceAndLabelDataWithPermissionsResp.resourcesList;
        this.labelledObjects = resourceAndLabelDataWithPermissionsResp.labelledObjects;
        this.setAllMaps();
        const resourcesColumn = this.columnDefs.get('resources');
        resourcesColumn.allowedValues = resourceAndLabelDataWithPermissionsResp.resourcesList;
        this.updateTable(this.labels);
        // Reset the form control so it rebuilds the list of allowed members:
        resetAutocompleteDropdownFilteredList(resourcesColumn);
        this.changeDetector.detectChanges();
      });
  }

  public hasAllPermissions(): boolean {
    return this.hasLabelPermissions && this.hasResourcePermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasLabelPermissions !== undefined && this.hasResourcePermissions !== undefined && !this.hasAllPermissions();
  }

  private setAllMaps(): void {
    this.resourceIdToResourceMap.clear();
    this.resourceIdToLabelledObjectMap.clear();
    this.resourceNameAndTypeToResourceMap.clear();
    for (const resource of this.resources) {
      this.resourceIdToResourceMap.set(resource.metadata.id, resource);
      this.resourceNameAndTypeToResourceMap.set(getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type), resource);
    }
    for (const labelledObject of this.labelledObjects) {
      this.resourceIdToLabelledObjectMap.set(labelledObject.object_id, labelledObject);
    }
  }

  private updateTable(data: Array<Label>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: Array<Label>): void {
    const dataForTable: Array<LabelOverviewElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: Label, index: number): LabelOverviewElement {
    const combinedResourceLabelledObjectData = this.getCombinedResourceLabelledObjectData(item);
    const data: LabelOverviewElement = {
      ...getDefaultTableProperties(index),
      name: item.spec.name,
      resources: [...combinedResourceLabelledObjectData.resources],
      previousResources: [...combinedResourceLabelledObjectData.resources],
      labelledObjects: [...combinedResourceLabelledObjectData.labelledObjects],
      navigationEnabled: !!item.spec.navigation?.enabled,
      created: item.metadata.created,
      backingObject: item,
    };
    return data;
  }

  private makeEmptyTableElement(): LabelOverviewElement {
    const element: LabelOverviewElement = {
      ...getDefaultNewRowProperties(),
      name: '',
      resources: [],
      previousResources: [],
      labelledObjects: [],
      navigationEnabled: false,
      created: new Date(),
      backingObject: {
        spec: {
          name: '',
          org_id: this.orgId,
          navigation: {
            enabled: false,
          },
        },
      },
    };
    return element;
  }

  private getCombinedResourceLabelledObjectData(label: Label): CombinedResourceAndLabelledObjectData {
    const resourceList = [];
    const labelledObjectList = [];
    for (const labelledObject of this.labelledObjects) {
      const labelAssociations = labelledObject.labels;
      for (const labelAssociation of labelAssociations) {
        if (labelAssociation.label_name === label.spec.name) {
          const targetResource = this.resourceIdToResourceMap.get(labelledObject.object_id);
          if (!!targetResource) {
            resourceList.push(targetResource);
          }
          labelledObjectList.push(labelledObject);
        }
      }
    }
    return {
      resources: resourceList,
      labelledObjects: labelledObjectList,
    };
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<LabelOverviewElement> {
    return createResourceIconColumn<InputData, LabelOverviewElement>(this.getIconURIFromElement.bind(this), this.openIconDialog.bind(this));
  }

  private getIconURIFromElement(element: LabelOverviewElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: LabelOverviewElement): void {
    const dialogData: ResourceLogoDialogData<any> = {
      overviewData: {
        element: element,
        localUpdateFunc: this.getAndSetAllData.bind(this),
        isLabel: true,
      },
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent table column
   */
  private getLabelNameColumn(): InputColumn<LabelOverviewElement> {
    const column = createInputColumn('name');
    column.requiredField = () => true;
    column.isEditable = true;
    column.isRowIdentifier = true;
    return column;
  }

  /**
   * Parent table column
   */
  private getResourcesColumn(): ChiplistColumn<LabelOverviewElement> {
    const column = createChipListColumn('resources');
    column.getDisplayValue = (resource: Resource) => {
      if (!resource) {
        return '';
      }
      return getResourceNameAndTypeString(resource.spec.name, resource?.spec?.resource_type);
    };
    column.getElementFromValue = (resourceNameAndTypeString: string, element: LabelOverviewElement): Resource => {
      return this.resourceNameAndTypeToResourceMap.get(resourceNameAndTypeString);
    };

    return column;
  }

  /**
   * Parent table column
   */
  private getNavigationColumn(): Column<LabelOverviewElement> {
    const column = createCheckBoxColumn('navigationEnabled');
    column.displayName = 'Navigation Enabled';
    column.isEditable = true;
    column.isChecked = (element: LabelOverviewElement) => {
      return !!element.navigationEnabled;
    };
    column.setElementFromCheckbox = (element: LabelOverviewElement, isBoxChecked: boolean): any => {
      element.navigationEnabled = isBoxChecked;
      element.backingObject.spec.navigation.enabled = isBoxChecked;
    };
    column.getHeaderTooltip = () => {
      return `Select this option if the label is used for folder navigation in profile`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<LabelOverviewElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<LabelOverviewElement>> = [
      {
        displayName: 'Configure Resources',
        icon: 'apps',
        tooltip: 'Click to access the "Resources" advanced configuration',
        columnName: 'resources',
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getIconColumn(),
        this.getLabelNameColumn(),
        this.getResourcesColumn(),
        this.getNavigationColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private updateLabelOverviewElement(updatedElement: LabelOverviewElement, labelResp: Label): void {
    updatedElement.previousResources = [...updatedElement.resources];
    updatedElement.backingObject = labelResp;
  }

  private getLabelFromLabelElement(element: LabelOverviewElement): Label {
    return {
      ...element.backingObject,
      spec: {
        ...element.backingObject.spec,
        name: element.name,
        navigation: {
          ...element.backingObject.spec.navigation,
          enabled: element.navigationEnabled,
        },
      },
    };
  }

  private getLabelledObjectsToCreateAndUpdate(updatedElement: LabelWithResources): LabelledObjectUpdateData {
    const labelledObjectsToCreate: Array<LabelledObject> = [];
    const labelledObjectsToUpdate: Array<LabelledObject> = [];
    for (const resource of updatedElement.resources) {
      const labelAssociation: LabelAssociation = {
        label_name: updatedElement.name,
        org_id: resource.spec.org_id,
        status: {
          navigation: {
            enabled: updatedElement.navigationEnabled,
          },
        },
      };
      const targetPreviousResource = updatedElement.previousResources.find(
        (previousResource) => previousResource.metadata.id === resource.metadata.id
      );
      if (!targetPreviousResource) {
        const targetLabelledObject = this.resourceIdToLabelledObjectMap.get(resource.metadata.id);
        if (!!targetLabelledObject) {
          targetLabelledObject.labels.push(labelAssociation);
          labelledObjectsToUpdate.push(targetLabelledObject);
        } else {
          const newLabelledObject: LabelledObject = {
            object_id: resource.metadata.id,
            object_type: resource.spec.resource_type,
            labels: [labelAssociation],
            org_id: resource.spec.org_id,
          };
          labelledObjectsToCreate.push(newLabelledObject);
        }
      }
    }
    return { labelledObjectsToCreate, labelledObjectsToUpdate };
  }

  private getLabelledObjectsToRemove(updatedElement: LabelWithResources): Array<LabelledObject> {
    const labelledObjectsToRemove: Array<LabelledObject> = [];
    for (const previousResource of updatedElement.previousResources) {
      const currentResource = updatedElement.resources.find((resource) => resource.metadata.id === previousResource.metadata.id);
      if (!currentResource) {
        const targetLabelledObject = this.resourceIdToLabelledObjectMap.get(currentResource.metadata.id);
        targetLabelledObject.labels = targetLabelledObject.labels.filter((label) => label.label_name !== updatedElement.name);
        labelledObjectsToRemove.push(targetLabelledObject);
      }
    }
    return labelledObjectsToRemove;
  }

  private getMergedLabelledObjectUpdateData(updatedElement: LabelWithResources): LabelledObjectUpdateData {
    const labelledObjectsToCreateAndUpdate = this.getLabelledObjectsToCreateAndUpdate(updatedElement);
    const labelledObjectsToRemove = this.getLabelledObjectsToRemove(updatedElement);
    labelledObjectsToCreateAndUpdate.labelledObjectsToUpdate = [
      ...labelledObjectsToCreateAndUpdate.labelledObjectsToUpdate,
      ...labelledObjectsToRemove,
    ];
    return labelledObjectsToCreateAndUpdate;
  }

  private prepareCreateLabelledObjectsObservablesArray$(labelledObjectsToCreate: Array<LabelledObject>): Array<Observable<LabelledObject>> {
    const observablesArray: Array<Observable<LabelledObject>> = [];
    for (const labelledObject of labelledObjectsToCreate) {
      observablesArray.push(createNewLabelledObject$(this.labelsService, labelledObject));
    }
    return observablesArray;
  }

  private prepareUpdateLabelledObjectsObservablesArray$(labelledObjectsToUpdate: Array<LabelledObject>): Array<Observable<LabelledObject>> {
    const observablesArray: Array<Observable<LabelledObject>> = [];
    for (const labelledObject of labelledObjectsToUpdate) {
      observablesArray.push(updateExistingLabelledObject$(this.labelsService, labelledObject));
    }
    return observablesArray;
  }

  private getUpdatedLabel$(updatedElement: LabelOverviewElement): Observable<Label> {
    const updatedLabel = this.getLabelFromLabelElement(updatedElement);
    if (!updatedElement.backingObject.metadata) {
      return createNewObjectLabel$(this.labelsService, updatedLabel);
    }
    return updateExistingObjectLabel$(this.labelsService, updatedLabel);
  }

  private getUpdatedLabelledObjectData$(updatedElement: LabelOverviewElement): Observable<Array<LabelledObject> | undefined> {
    const mergedLabelledObjectUpdateData = this.getMergedLabelledObjectUpdateData(updatedElement);
    const createdLabelledObjects$ = this.prepareCreateLabelledObjectsObservablesArray$(
      mergedLabelledObjectUpdateData.labelledObjectsToCreate
    );
    const updatedLabelledObjects$ = this.prepareUpdateLabelledObjectsObservablesArray$(
      mergedLabelledObjectUpdateData.labelledObjectsToUpdate
    );
    if (createdLabelledObjects$.length === 0 && updatedLabelledObjects$.length === 0) {
      return of(undefined);
    }
    return forkJoin([...createdLabelledObjects$, ...updatedLabelledObjects$]);
  }

  private updateDataIfNotEditing(): void {
    this.updateDataCount = this.updateDataCount + 1;
    setTimeout(() => {
      this.delayedUpdateDataCount = this.delayedUpdateDataCount + 1;
      if (this.editingTable || isTableDirty(this.tableData) || this.updateDataCount !== this.delayedUpdateDataCount) {
        return;
      }
      this.getAndSetAllData();
    }, 5000);
  }

  private handleResourceAndLabelsUpdate(updatedElement: LabelOverviewElement): void {
    this.editingTable = true;
    this.getUpdatedLabel$(updatedElement)
      .pipe(
        concatMap((labelResp) => {
          return combineLatest([of(labelResp), this.getUpdatedLabelledObjectData$(updatedElement)]);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([labelsResp, LabelledObjectsResp]) => {
          this.notificationService.success(`Label "${updatedElement.name}" was successfully updated`);
          this.updateLabelOverviewElement(updatedElement, labelsResp);
          this.editingTable = false;
          this.updateDataIfNotEditing();
        },
        (errorResp) => {
          this.notificationService.error(`Failed to update resource "${updatedElement.name}". Please refresh the page and try again.`);
        }
      );
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: LabelOverviewElement): void {
    this.handleResourceAndLabelsUpdate(updatedElement);
  }

  public deleteSelected(itemsToDelete: Array<LabelOverviewElement>): void {
    this.deleteLabels(itemsToDelete);
  }

  private deleteLabels(itemsToDelete: Array<LabelOverviewElement>): void {
    const observablesArray = deleteExistingLabelsList$(
      this.labelsService,
      itemsToDelete.map((item) => item.backingObject)
    );
    if (observablesArray.length === 0) {
      this.getAndSetAllData();
      return;
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Labels were successfully deleted');
          this.getAndSetAllData();
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all labels');
        }
      );
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

import { DiagnosticDateRange } from './diagnostic-types';
import { UntypedFormGroup } from '@angular/forms';
import { MetadataWithId, Organisation, User } from '@agilicus/angular';

export interface TimeIntervalOption {
  interval: string;
  displayedInterval: string;
}

export function convertTimeToMilliseconds(date: Date): number {
  const dateAsDate = new Date(date);
  return dateAsDate.getTime();
}

export function convertHoursToMilliseconds(hours: number | string): number {
  const hoursAsNumber = parseInt(hours.toString(), 10);
  return hoursAsNumber * 3600000;
}

export function addHoursToCurrentDate(hours: string | number): Date | undefined {
  if (!hours) {
    return undefined;
  }
  const expiryInMilliseconds = convertHoursToMilliseconds(hours);
  const currentDateInMilliseconds = convertTimeToMilliseconds(new Date());
  return new Date(currentDateInMilliseconds + expiryInMilliseconds);
}

export function compareTimeFunc<T extends object>(a: T, b: T, key: string): number {
  if (!a[key] && !b[key]) {
    return 0;
  }
  if (!a[key]) {
    return -1;
  }
  if (!b[key]) {
    return 1;
  }
  return convertTimeToMilliseconds(new Date(a[key])) - convertTimeToMilliseconds(new Date(b[key]));
}

/**
 * Sorts an array of objects chronologically in ascending order by a specific key
 */
export function sortDateChronologicallyByKey<T extends object>(data: Array<T>, key: string): Array<T> {
  return data.sort((a, b) => {
    return compareTimeFunc(a, b, key);
  });
}

/**
 * Sorts an array of objects chronologically in descending order by a specific key
 */
export function sortDateReverseChronologicallyByKey<T extends object>(data: Array<T>, key: string): Array<T> {
  return sortDateChronologicallyByKey(data, key).reverse();
}

/**
 * Sorts an array of chronologically in descending order by the metadata created date
 */
export function sortArrayByMetadataCreatedDate<T extends { metadata?: MetadataWithId }>(data: Array<T>): Array<T> {
  return data.sort((a, b) => {
    let lhs = a.metadata?.created;
    let rhs = b.metadata?.created;
    return getDateSortNumber(lhs, rhs);
  });
}

/**
 * Sorts an array of chronologically in descending order by the metadata created date
 */
export function sortArrayByCreatedDate<T extends { created?: Date }>(data: Array<T>): Array<T> {
  return data.sort((a, b) => {
    let lhs = a?.created;
    let rhs = b?.created;
    return getDateSortNumber(lhs, rhs);
  });
}

/**
 * Sorts an array of chronologically in descending order by the metadata created date
 */
export function getDateSortNumber(lhs: Date, rhs: Date): number {
  if (lhs === null) {
    lhs = undefined;
  }
  if (rhs === null) {
    rhs = undefined;
  }
  if (lhs === rhs) {
    return 0;
  }
  if (lhs === undefined && rhs !== undefined) {
    return -1;
  }
  if (lhs !== undefined && rhs === undefined) {
    return 1;
  }
  return convertTimeToMilliseconds(new Date(rhs)) - convertTimeToMilliseconds(new Date(lhs));
}

export function getDefaultTimeIntervalOptions(): Array<TimeIntervalOption> {
  return [
    {
      interval: '5',
      displayedInterval: 'Last 5 mins',
    },
    {
      interval: '15',
      displayedInterval: 'Last 15 mins',
    },
    {
      interval: '30',
      displayedInterval: 'Last 30 mins',
    },
    {
      interval: '60',
      displayedInterval: 'Last hour',
    },
    {
      interval: '360',
      displayedInterval: 'Last 6 hours',
    },
    {
      interval: '720',
      displayedInterval: 'Last 12 hours',
    },
    {
      interval: '2160',
      displayedInterval: 'Last 36 hours',
    },
  ];
}

export function setStartDateFromInterval(currentTime: Date, interval: number): Date {
  const updatedTime: Date = new Date(currentTime);
  updatedTime.setMinutes(updatedTime.getMinutes() - interval);
  return updatedTime;
}

/**
 * Updates the relative time based on the updated 'now' variable.
 * Assumes the formGroup has fields 'quick_select_time', 'dtFrom' and 'dtTo'.
 */
export function setStartAndEndDatesForFilter(formGroup: UntypedFormGroup): DiagnosticDateRange {
  const targetDates: DiagnosticDateRange = {
    startDate: undefined,
    endDate: undefined,
  };
  if (formGroup.value.quick_select_time !== 'custom') {
    const now: Date = new Date();
    targetDates.startDate = setStartDateFromInterval(now, formGroup.value.quick_select_time);
    targetDates.endDate = now;
  } else {
    targetDates.startDate = formGroup.value.dtFrom;
    targetDates.endDate = formGroup.value.dtTo;
  }
  return targetDates;
}

export function getStartDateMaxSetter(formGroup: UntypedFormGroup): (date: Date) => boolean {
  return (date: Date): boolean => {
    const dateToControl = formGroup.get('dtTo');
    if (dateToControl.value !== null) {
      return convertTimeToMilliseconds(date) < convertTimeToMilliseconds(dateToControl.value);
    }
    return true;
  };
}

export function getEndDateMinSetter(formGroup: UntypedFormGroup): (date: Date) => boolean {
  return (date: Date): boolean => {
    const dateFromControl = formGroup.get('dtFrom');
    if (dateFromControl.value !== null) {
      return convertTimeToMilliseconds(date) > convertTimeToMilliseconds(dateFromControl.value);
    }
    return true;
  };
}

export function convertDateToReadableFormat(targetDate: Date): string {
  const convertedDate = new Date(targetDate);
  return convertedDate.toLocaleString('sv', { timeZoneName: 'short' });
}

/**
 * Converts to date in format yyyy/mm/dd
 */
export function convertDateToShortReadableFormat(targetDate: Date | undefined): string {
  if (!targetDate) {
    return ``;
  }
  const convertedDate = new Date(targetDate);
  return convertedDate.toLocaleDateString('sv');
}

export function addSecondsToDate(seconds: number, startDate: Date): Date {
  startDate.setSeconds(startDate.getSeconds() + seconds);
  return startDate;
}

export function convertDaysToSeconds(days: number): number {
  return days * 86400;
}

export function convertMillisecondsToDays(milliseconds: number): number {
  return convertSecondsToDays(milliseconds / 1000);
}

export function convertSecondsToDays(seconds: number): number {
  return seconds / 86400;
}

export function getCurrentDate(): Date {
  return new Date();
}

export function getFormatedDateStringFromMilliseconds(value: number): string {
  return convertDateToReadableFormat(new Date(value));
}

export function getFormatedDateStringFromSeconds(value: number): string {
  return convertDateToReadableFormat(new Date(value * 1000));
}

/**
 * Takes 2 dates, earlierDate and laterDate, and returns the number of days between them.
 * If laterDate is before earlierDate it will return undefined.
 */
export function getDateDifferenceInDays(earlierDate: Date | undefined, laterDate: Date | undefined): number | undefined {
  if (!earlierDate || !laterDate) {
    return undefined;
  }
  const earlierDateInMilliseconds = convertTimeToMilliseconds(earlierDate);
  const laterDateInMilliseconds = convertTimeToMilliseconds(laterDate);
  if (earlierDateInMilliseconds > laterDateInMilliseconds) {
    return undefined;
  }
  const diffTime = laterDateInMilliseconds - earlierDateInMilliseconds;
  return convertMillisecondsToDays(diffTime);
}

/**
 * Returns true if the given date is less than 10 minutes ago.
 */
export function dateIsTenMinutesAgo(date: Date): boolean {
  return dateIsWithinDurationAgo(date, 10 * 60);
}

/**
 * Returns true if the given date is less than or equal to N seconds ago.
 */
export function dateIsWithinDurationAgo(date: Date, durationSeconds: number): boolean {
  const deltaMilliseconds = new Date().getTime() - date.getTime();
  return deltaMilliseconds <= durationSeconds * 1000;
}

export function getCurrentDateInSeconds(): number {
  return new Date().getTime() / 1000;
}

export function getDateThirtyDaysFromNow(): Date {
  const now = new Date();
  return new Date(now.setDate(now.getDate() + 30));
}

export function getStartDateFromOrgAndUser(currentOrg: Organisation | undefined, currentUser: User | undefined): Date | undefined {
  const now = new Date();
  const orgDateDiff = getDateDifferenceInDays(currentOrg?.created, now);
  const userDateDiff = getDateDifferenceInDays(currentUser?.created, now);
  if (!orgDateDiff && !userDateDiff) {
    return undefined;
  }
  if (!orgDateDiff) {
    return currentUser?.created;
  }
  if (!userDateDiff) {
    return currentOrg?.created;
  }
  if (orgDateDiff < userDateDiff) {
    return currentOrg?.created;
  }
  return currentUser?.created;
}

export function getDaysActive(currentOrgResp: Organisation, currentUserResp: User): number {
  const startDate = getStartDateFromOrgAndUser(currentOrgResp, currentUserResp);
  return getDateDifferenceInDays(startDate, new Date());
}

export function convertCalendarPickerDateStringToDate(dateInput: any): Date | undefined {
  if (!dateInput) {
    return undefined;
  }

  // If the input is already a Date object, return it as is
  if (dateInput instanceof Date) {
    return dateInput;
  }

  // Convert a valid date string
  if (typeof dateInput === 'string') {
    const parsedDate = new Date(dateInput);
    return isNaN(parsedDate.getTime()) ? undefined : parsedDate;
  }

  console.error('Invalid date input:', dateInput);
  return undefined;
}

export function isValidDateString(dateInput: string | Date): boolean {
  let dateObj: Date;

  if (dateInput instanceof Date) {
    dateObj = dateInput;
  } else if (typeof dateInput === 'string') {
    dateObj = new Date(dateInput);
    if (isNaN(dateObj.getTime())) {
      return false;
    }
  } else {
    return false;
  }

  const datePart = dateObj.toISOString().split('T')[0];
  const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;

  if (!dateRegex.test(datePart)) {
    return false;
  }

  const [yearString, monthString, dayString] = datePart.split('-');
  const year = parseInt(yearString, 10);
  const month = parseInt(monthString, 10);
  const day = parseInt(dayString, 10);

  if ([4, 6, 9, 11].includes(month) && day > 30) return false;
  if (month === 2) {
    const isLeap = isLeapYear(year);
    if (day > (isLeap ? 29 : 28)) return false;
  }

  return true;
}

function isLeapYear(year: number): boolean {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

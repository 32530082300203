import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { _BillingSubscriptionCancelDetail } from '@agilicus/angular';

export interface OrgDeleteDialogData {
  orgName: string;
}

export enum OrgCancelFeedbackEnum {
  customer_service = 'customer_service',
  low_quality = 'low_quality',
  missing_features = 'missing_features',
  other = 'other',
  switched_service = 'switched_service',
  too_complex = 'too_complex',
  too_expensive = 'too_expensive',
  unused = 'unused',
}

export interface OrgCancelFeedbackData {
  value: OrgCancelFeedbackEnum;
  detail: string;
}

@Component({
  selector: 'portal-org-delete-confirmation-dialog',
  templateUrl: './org-delete-confirmation-dialog.component.html',
  styleUrls: ['./org-delete-confirmation-dialog.component.scss'],
})
export class OrgDeleteConfirmationDialogComponent implements OnInit {
  public orgName: string;
  public orgForm: UntypedFormGroup;
  public orgCancelFeedbackDataList: Array<OrgCancelFeedbackData> = [
    {
      value: OrgCancelFeedbackEnum.customer_service,
      detail: `Customer service was less than expected`,
    },
    {
      value: OrgCancelFeedbackEnum.too_complex,
      detail: `Ease of use was less than expected`,
    },
    {
      value: OrgCancelFeedbackEnum.unused,
      detail: `I don't use the service enough`,
    },
    {
      value: OrgCancelFeedbackEnum.switched_service,
      detail: `I'm switching to a different service`,
    },
    {
      value: OrgCancelFeedbackEnum.too_expensive,
      detail: `It's too expensive`,
    },
    {
      value: OrgCancelFeedbackEnum.low_quality,
      detail: `Quality was less than expected`,
    },
    {
      value: OrgCancelFeedbackEnum.missing_features,
      detail: `Some features are missing`,
    },
    {
      value: OrgCancelFeedbackEnum.other,
      detail: `Other reason`,
    },
  ];
  public keyTabManager: KeyTabManager = new KeyTabManager();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: OrgDeleteDialogData,
    private dialogRef: MatDialogRef<OrgDeleteConfirmationDialogComponent>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.orgName = data.orgName;
  }

  public ngOnInit(): void {
    this.orgForm = this.formBuilder.group({
      orgName: ['', [Validators.required, this.checkOrgName.bind(this)]],
      feedback: ['', [Validators.required]],
      comment: '',
    });
  }

  public checkOrgName(control: AbstractControl): ValidationErrors | null {
    const invalid = { invalidOrgName: { value: control.value } };
    if (control.value !== this.orgName) {
      return invalid;
    }
    return null;
  }

  public onConfirmClick(): void {
    const billingSubscriptionCancelDetail: _BillingSubscriptionCancelDetail = {
      feedback: this.orgForm.get('feedback').value,
      comment: this.orgForm.get('comment').value,
    };
    this.dialogRef.close(billingSubscriptionCancelDetail);
  }

  public clearComment(): void {
    this.orgForm.get('comment').setValue('');
  }
}

import { Application, Group, PolicyCondition, PolicyRule } from '@agilicus/angular';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { AppState, NotificationService, selectPolicyState } from '@app/core';
import { selectApiApplicationsList } from '@app/core/api-applications/api-applications.selectors';
import { ActionPolicySavingPolicyRule } from '@app/core/issuer/issuer.actions';
import { getAllPaths } from '@app/shared/utilities/model-helpers/issuers';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { filter, map, Subject, take, takeUntil } from 'rxjs';
import { ChiplistInput } from '../custom-chiplist-input/chiplist-input';
import { createChiplistInput } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { isJson } from '../file-utils';
import { FilterChipOptions } from '../filter-chip-options';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import {
  formatConditionValues,
  isAttributeAList,
  PolicyConditionElement,
  policyConditionValueIsAList,
  setConditionTypeToDisplayValueMap,
} from '../policy-utils';
import { createEnumChecker, isFormChipRemovable, removeFromBeginningOfString, replaceCharacterWithSpace } from '../utils';

export interface PolicyConditionDialogData {
  condition: PolicyConditionElement;
  store: Store<AppState>;
  groupIdToGroupMap: Map<string, Group>;
  groupDisplayNameToGroupMap: Map<string, Group>;
  policyRule: PolicyRule;
}

enum PolicyRuleSaveState {
  SAVING = 'saving',
  SUCCESS = 'success',
  FAILED = 'failed',
}

@Component({
  selector: 'portal-policy-condition-dialog',
  templateUrl: './policy-condition-dialog.component.html',
  styleUrls: ['./policy-condition-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true }, // display errors in the steps
    },
  ],
})
export class PolicyConditionDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  public allForms: UntypedFormGroup;
  public conditionTypeForm: UntypedFormGroup;
  public conditionTypeFormReadonly: UntypedFormGroup;
  public conditionDetailsForm: UntypedFormGroup;
  public conditionTypeOptionsList = Object.values(PolicyCondition.ConditionTypeEnum);
  public conditionTypeToDisplayValueMap: Map<PolicyCondition.ConditionTypeEnum, string> = new Map();
  public fieldOptionsList = getAllPaths();
  public applications: Array<Application> = [];
  public addDisabled = false;
  private localCachedCondition: PolicyConditionElement;

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };
  public newChipAdded = false;
  public advanceToDoneStep = false;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public isFormChipRemovable = isFormChipRemovable;

  // This is required in order to reference the enums in the html template.
  public policyConditionTypeEnum = PolicyCondition.ConditionTypeEnum;

  @ViewChild('stepper') public stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<PolicyConditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PolicyConditionDialogData,
    private formBuilder: UntypedFormBuilder,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {
    setConditionTypeToDisplayValueMap(this.conditionTypeToDisplayValueMap);
    this.data.condition.value_list = this.getValuesListFromValue();
    this.localCachedCondition = cloneDeep(this.data.condition);
    this.data.condition.value = isJson(this.data.condition.value) ? JSON.parse(this.data.condition.value) : this.data.condition.value;
  }

  public ngOnInit(): void {
    this.initializeForms();
    this.allForms = this.formBuilder.group({
      conditionTypeForm: this.conditionTypeForm,
      conditionDetailsForm: this.conditionDetailsForm,
    });
    const appListState$ = this.data.store.pipe(select(selectApiApplicationsList));
    appListState$.pipe(takeUntil(this.unsubscribe$)).subscribe((appListResp) => {
      this.applications = appListResp;
    });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.changeDetector.detach();
  }

  private initializeForms(): void {
    this.initializeConditionTypeForm();
    this.initializeConditionTypeFormReadonly();
    this.initializeConditionDetailsForm();
  }

  private initializeConditionTypeForm(): void {
    const fieldValidators = [];
    this.conditionTypeForm = this.formBuilder.group({
      condition_type: [this.data.condition.condition_type, [Validators.required]],
      field: [this.data.condition.field, fieldValidators],
    });
  }

  /**
   * This form is for a readonly view of the conditionTypeForm when on the second step of the stepper
   */
  private initializeConditionTypeFormReadonly(): void {
    this.conditionTypeFormReadonly = this.formBuilder.group({
      condition_type: this.getConditionTypeValueFromForm(),
      field: this.getFieldValueFromForm(),
    });
    this.conditionTypeFormReadonly.disable();
  }

  private initializeConditionDetailsForm(): void {
    const valueValidators = [];
    if (!policyConditionValueIsAList(this.data.condition.field)) {
      valueValidators.push(Validators.required);
    }
    this.conditionDetailsForm = this.formBuilder.group({
      operator: [this.data.condition.operator, [Validators.required]],
      value: [this.data.condition.value, valueValidators],
      value_list: [this.data.condition.value_list],
      // This is the input used to enter chip values, not the chip values themselves:
      value_input_value: '',
    });
  }

  private getValuesListFromValue(): Array<string> {
    if (!policyConditionValueIsAList(this.data.condition.field)) {
      return [];
    }
    const valueAsArray = isJson(this.data.condition.value) ? JSON.parse(this.data.condition.value) : [this.data.condition.value];
    if (this.data.condition.field === 'user_member_of.id') {
      // Convert the group ids to display names:
      const targetGroupIdsList = valueAsArray;
      let groupDisplayNamesList: Array<string> = [];
      for (const groupId of targetGroupIdsList) {
        const targetGroup = this.data.groupIdToGroupMap.get(groupId);
        groupDisplayNamesList.push(targetGroup?.display_name ? targetGroup.display_name : groupId);
      }
      return groupDisplayNamesList;
    }
    return valueAsArray;
  }

  public onCloseClick(): void {
    this.dialogRef.close(false);
  }

  public formatConditionTypeForDisplay(conditionType: PolicyCondition.ConditionTypeEnum): string {
    if (!conditionType) {
      return '';
    }
    const displayValue = this.conditionTypeToDisplayValueMap.get(conditionType);
    if (!displayValue) {
      return removeFromBeginningOfString(replaceCharacterWithSpace(conditionType, '_'), 'type ');
    }
    return displayValue;
  }

  public formatFieldForDisplay(field: string): string {
    if (!field) {
      return '';
    }
    if (field.startsWith('user_mfa_preferences.spec')) {
      field = field.replace('user_mfa_preferences.spec', 'User multi-factor');
    }
    return replaceCharacterWithSpace(replaceCharacterWithSpace(field, '_'), '.');
  }

  public onConditionTypeChange(conditionType: PolicyCondition.ConditionTypeEnum) {
    const valueFormControl = this.conditionDetailsForm.get('value');
    const fieldFormControl = this.conditionTypeForm.get('field');
    if (conditionType !== PolicyCondition.ConditionTypeEnum.object_attribute) {
      // In this case we need to unset the field value:
      fieldFormControl.setValue('');
      fieldFormControl.setValidators([]);
    } else {
      fieldFormControl.setValidators([Validators.required]);
    }
    valueFormControl.setValue('');
    valueFormControl.setValidators([Validators.required]);
    valueFormControl.updateValueAndValidity();
    fieldFormControl.updateValueAndValidity();
  }

  public onFieldChange(field: string) {
    const valueFormControl = this.conditionDetailsForm.get('value');
    if (!policyConditionValueIsAList(field)) {
      valueFormControl.setValidators([Validators.required]);
    } else {
      valueFormControl.setValidators([]);
    }
    valueFormControl.setValue('');
    valueFormControl.updateValueAndValidity();
    this.data.condition.value_list = [];
  }

  public getConditionTypeValueFromForm(): PolicyCondition.ConditionTypeEnum {
    return this.conditionTypeForm.get('condition_type')?.value;
  }

  public getFieldValueFromForm(): string {
    return this.conditionTypeForm.get('field')?.value;
  }

  public getOperatorOptionValues() {
    if (this.getConditionTypeValueFromForm() === PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method) {
      return [PolicyCondition.OperatorEnum.in];
    }
    return this.getOperatorAllowedValues();
  }

  /**
   * We need to hide certain operator options that are not applicable.
   */
  private getOperatorAllowedValues(): Array<string> {
    const operatorValues = [...Object.values(PolicyCondition.OperatorEnum)];
    return operatorValues;
  }

  public formatOperatorOptionForDisplay(option: string): string {
    const isOperatorEnum = createEnumChecker(PolicyCondition.OperatorEnum);
    if (isOperatorEnum(option)) {
      return replaceCharacterWithSpace(option, '_');
    }
    return '';
  }

  public showValueTextInput(): boolean {
    const fieldFormValue = this.getFieldValueFromForm();
    return !policyConditionValueIsAList(fieldFormValue);
  }

  public removeValueChip(chipValue: string): void {
    this.data.condition.value_list = this.data.condition.value_list.filter((value) => value !== chipValue);
  }

  public getValueListChiplistInput(): ChiplistInput<object> {
    const valueListChiplistInput = createChiplistInput('value_list');
    valueListChiplistInput.allowedValues = this.getValuesChiplistAllowedValues();
    valueListChiplistInput.hasAutocomplete = true;
    valueListChiplistInput.formControl = this.conditionDetailsForm.get('value_input_value') as UntypedFormControl;
    return valueListChiplistInput;
  }

  private getValuesChiplistAllowedValues(): Array<string> {
    const fieldFormValue = this.getFieldValueFromForm();
    if (fieldFormValue === 'user_member_of.id') {
      return [...this.data.groupDisplayNameToGroupMap.keys()];
    }
    if (fieldFormValue === 'clients.application') {
      return this.applications.map((app) => app.name);
    }
    return [];
  }

  private isMatchingCondition(condition: PolicyCondition): boolean {
    if (
      condition?.condition_type !== this.localCachedCondition?.condition_type ||
      condition?.value !== this.localCachedCondition?.value ||
      condition?.operator !== this.localCachedCondition?.operator ||
      condition?.created !== this.localCachedCondition?.created
    ) {
      return false;
    }
    if (!!condition?.field && condition?.field !== this.localCachedCondition?.field) {
      return false;
    }
    return true;
  }

  private getConditionElementFromForm(): PolicyConditionElement {
    const conditionTypeFormValue = this.getConditionTypeValueFromForm();
    const fieldFormValue = this.getFieldValueFromForm();
    const operatorFormValue = this.conditionDetailsForm.get('operator').value;
    const valueFormValue = this.conditionDetailsForm.get('value').value;
    const policyConditionElementCopy = cloneDeep(this.data.condition);
    policyConditionElementCopy.condition_type = conditionTypeFormValue;
    policyConditionElementCopy.field = fieldFormValue;
    policyConditionElementCopy.operator = operatorFormValue;
    policyConditionElementCopy.value = valueFormValue;
    formatConditionValues(
      policyConditionElementCopy,
      this.data.groupIdToGroupMap,
      this.data.groupDisplayNameToGroupMap,
      policyConditionElementCopy.value_list
    );
    return policyConditionElementCopy;
  }

  private getConditionFromConditionElement(policyConditionElement: PolicyConditionElement): PolicyCondition {
    const policyCondition: PolicyCondition = {
      condition_type: policyConditionElement.condition_type,
      operator: policyConditionElement.operator,
      value: policyConditionElement.value,
      inverted: policyConditionElement.inverted,
      input_is_list: policyConditionElement.input_is_list,
      created: policyConditionElement.created,
      updated: policyConditionElement.updated,
    };
    if (policyConditionElement.condition_type === PolicyCondition.ConditionTypeEnum.object_attribute) {
      if (!!policyConditionElement.field) {
        policyCondition.field = policyConditionElement.field;
      }
      if (isAttributeAList(policyConditionElement.field)) {
        policyCondition.input_is_list = true;
      }
    }
    return policyCondition;
  }

  public savePolicyCondition(): void {
    const conditionElementToSave = this.getConditionElementFromForm();
    const conditionToSave = this.getConditionFromConditionElement(conditionElementToSave);
    const errMsg = this.validatePolicyConditions(conditionToSave);
    if (errMsg !== undefined) {
      this.notificationService.error(errMsg);
      return;
    }
    if (this.preventSaveIfValueNotSet(conditionToSave)) {
      this.notificationService.error('A "value" is required. Please enter a "value".');
      return;
    }
    const policyConditionsListCopy = cloneDeep(this.data.policyRule.spec.conditions);
    const targetConditionIndex = policyConditionsListCopy.findIndex((condition) => this.isMatchingCondition(condition));
    if (targetConditionIndex !== -1) {
      this.data.policyRule.spec.conditions.splice(targetConditionIndex, 1, conditionToSave);
    } else {
      this.data.policyRule.spec.conditions.unshift(conditionToSave);
    }
    // Update the localCachedCondition here so that if the save fails or the user wants to go back
    // and make changes to save again, it will update the existing condition rather than add a new condition.
    this.localCachedCondition = conditionElementToSave;
    this.savePolicyRule();
  }

  private savePolicyRule(): void {
    this.data.store.dispatch(new ActionPolicySavingPolicyRule(this.data.policyRule));
    this.disableForms();
    this.checkSaveStatus();
  }

  /**
   * Do not submit to the api if the value is not set.
   * This is a temporary solution to a larger issuer which requires a larger refactoring
   * of the table-layout.
   */
  private preventSaveIfValueNotSet(policyCondition: PolicyCondition): boolean {
    if (policyCondition.condition_type === PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method) {
      // no value required
      return false;
    }
    return !policyCondition.value;
  }

  /**
   * Checks if the value property of all PolicyConditionElements is set.
   */
  private validatePolicyConditions(policyCondition: PolicyCondition): string | undefined {
    if (policyCondition.condition_type === PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method) {
      // no value is required.
      // force set to In operator (only valid value here)
      policyCondition.operator = PolicyCondition.OperatorEnum.in;
      return undefined;
    }

    // Note: cannot simply use "!policyConditionElement.value" below since value could be set to false.
    if (policyCondition.value === '' || policyCondition.value === undefined || policyCondition.value === null) {
      // Do not submit to the api if the value properties are not set.
      // This is a temporary solution to a larger issuer which requires a larger refactoring of
      // the table-layout.
      return 'The value property is required.';
    }
    if (policyCondition.condition_type === PolicyCondition.ConditionTypeEnum.last_successful_mfa) {
      const intervalVal = parseInt(policyCondition.value, 10);
      if (isNaN(intervalVal) || intervalVal < 0) {
        return 'The Successful Multi-Factor Authentication condition requires an integer duration in seconds. The duration cannot be a negative number.';
      }
    }
    return undefined;
  }

  public areDetailsCompleted(): boolean {
    const fieldFormValue = this.getFieldValueFromForm();
    const valueFormValue = this.conditionDetailsForm.get('value').value;
    if (
      this.conditionDetailsForm.invalid ||
      (!policyConditionValueIsAList(fieldFormValue) && !valueFormValue && valueFormValue !== false) ||
      (policyConditionValueIsAList(fieldFormValue) && this.data.condition.value_list.length === 0)
    ) {
      return false;
    }
    return true;
  }

  public checkDetailsStepAndNotifyUser(): void {
    if (!this.areDetailsCompleted()) {
      this.notificationService.error('The value property is required. Please provide a value.');
    }
  }

  public onStepChange(event) {
    if (event.selectedIndex === 2) {
      this.checkDetailsStepAndNotifyUser();
    }
  }

  private checkSaveStatus(): void {
    this.data.store
      .pipe(select(selectPolicyState))
      .pipe(
        takeUntil(this.unsubscribe$),
        map((state) => {
          if (state.saving_policy_rule) {
            return PolicyRuleSaveState.SAVING;
          }
          if (!state.policy_rule_save_success) {
            // save was unsuccessful
            return PolicyRuleSaveState.FAILED;
          }
          // save was successful
          return PolicyRuleSaveState.SUCCESS;
        }),
        filter((action) => action !== PolicyRuleSaveState.SAVING),
        take(1)
      )
      .subscribe((action) => {
        if (action === PolicyRuleSaveState.FAILED) {
          // re-enable form to retry saving
          this.enableForms();
        } else {
          this.advanceToDoneStep = true;
          this.changeDetector.detectChanges();
          setTimeout(() => {
            this.enableForms();
            this.stepper.next();
          }, 1000);
        }
      });
  }

  public disableForms(): void {
    this.addDisabled = true;
    this.allForms.disable();
  }

  public enableForms(): void {
    this.addDisabled = false;
    this.allForms.enable();
  }
}

import { APIKey, GetApiKeyRequestParams, ListApiKeysRequestParams, patch_via_put, TokensService } from '@agilicus/angular';
import { APIKeyModel } from '../models/api-key/api-key-model';
import { map, Observable } from 'rxjs';

export function getApiKeyFromModel(model: APIKeyModel, orgId: string): APIKey | undefined {
  const newApiKey: APIKey = {
    spec: {
      name: model.name,
      scopes: model.scopes,
      user_id: model.id,
      org_id: orgId,
      expiry: model.expiryDate,
    },
  };
  return newApiKey;
}

export function createNewApiKey(tokensService: TokensService, newApiKey: APIKey): Observable<APIKey | undefined> {
  return tokensService.createApiKey({
    APIKey: newApiKey,
  });
}

export function getApiKeyById(tokensService: TokensService, id: string, orgId: string | undefined): Observable<APIKey | undefined> {
  const getApiKeyRequestParams: GetApiKeyRequestParams = {
    api_key_id: id,
    org_id: orgId,
  };
  return tokensService.getApiKey(getApiKeyRequestParams);
}

export function getApiKeysList(tokensService: TokensService, orgId: string | undefined): Observable<Array<APIKey>> {
  const listApiKeyRequestParams: ListApiKeysRequestParams = {
    org_id: orgId,
  };
  return tokensService.listApiKeys(listApiKeyRequestParams).pipe(
    map((resp) => {
      return resp.api_keys;
    })
  );
}

export function deleteExistingApiKey(tokensService: TokensService, id: string, orgId: string): Observable<void> {
  return tokensService.deleteApiKey({
    api_key_id: id,
    org_id: orgId,
  });
}

export function updateExistingApiKey(tokensService: TokensService, apiKeyToUpdate: APIKey): Observable<APIKey | undefined> {
  const putter = (apiKey: APIKey) => {
    return tokensService.replaceApiKey({
      api_key_id: apiKey.metadata.id,
      APIKey: apiKey,
    });
  };
  const getter = (apiKey: APIKey) => {
    return tokensService.getApiKey({
      api_key_id: apiKey.metadata.id,
      org_id: apiKey.spec.org_id,
    });
  };
  return patch_via_put(apiKeyToUpdate, getter, putter);
}

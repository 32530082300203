import { ApplicationService, Connector, ConnectorSpec, RuntimeStatus } from '@agilicus/angular';
import { ConfirmationDialogComponent, ConfirmationDialogData } from './confirmation-dialog/confirmation-dialog.component';
import { InputData } from './custom-chiplist-input/input-data';
import { createDialogData } from './dialog-utils';
import { Column } from './table-layout/column-definitions';
import { MatDialog } from '@angular/material/dialog';

export function setConnectorColumnAllowedValues<T extends InputData>(
  column: Column<T>,
  connectorsList: Array<Connector>,
  orgId: string,
  emptyConnectorType: ConnectorSpec.ConnectorTypeEnum,
  filteredConnectorTypesList?: Array<ConnectorSpec.ConnectorTypeEnum>
): void {
  column.allowedValues = getFilteredConnectorNamesList(connectorsList, orgId, emptyConnectorType, filteredConnectorTypesList);
}

export function getFilteredConnectorNamesList(
  connectorsList: Array<Connector>,
  orgId: string,
  emptyConnectorType: ConnectorSpec.ConnectorTypeEnum,
  filteredConnectorTypesList?: Array<ConnectorSpec.ConnectorTypeEnum>
): Array<string> {
  const emptyConnector = getEmptyConnector(orgId, emptyConnectorType);
  if (!!filteredConnectorTypesList) {
    return [emptyConnector, ...connectorsList]
      .filter((connector) => filteredConnectorTypesList.every((connectorType) => connector.spec.connector_type === connectorType))
      .map((connector) => connector.spec.name);
  }
  return [emptyConnector, ...connectorsList].map((connector) => connector.spec.name);
}

export function getEmptyConnector(orgId: string, connectorType: ConnectorSpec.ConnectorTypeEnum): Connector {
  return {
    spec: {
      name: '',
      org_id: orgId,
      connector_type: connectorType,
    },
  };
}

export function getNumberOfConnectorInstances(connector: Connector): number {
  return connector.status?.instances ? connector.status.instances.length : 0;
}

export function getNumberOfDownConnectorInstances(connector: Connector): number {
  return connector.status?.instances
    ? connector.status.instances.filter((instance) => instance.status?.operational_status?.status !== RuntimeStatus.OverallStatusEnum.good)
        .length
    : 0;
}

export function doesConnectorHaveShares(connector: Connector): boolean {
  const appServiceList = connector.status?.application_services ? connector.status?.application_services : [];
  return appServiceList.some((network) => network.service_protocol_type === ApplicationService.ServiceProtocolTypeEnum.fileshare);
}

export function openRemoveConnectorFromResourceWarningDialog<
  T extends {
    name: string;
    connector_name: string;
    prevous_connector_name: string;
    dirty: boolean;
    ignore_resource_disconnect_check?: boolean;
  }
>(element: T, dialog: MatDialog, saveFunc: (element: T) => void): void {
  const messagePrefix = `Disconnecting Resource from Connector`;
  const message = `Warning: You are about to disconnect "${element.name}" from connector "${element.prevous_connector_name}". 
  It will not be accessible until you assign it to a connector.\n<br><br>
  Would you like to continue?`;
  const dialogData: ConfirmationDialogData = {
    ...createDialogData(messagePrefix, message),
    icon: 'warning',
    buttonText: { confirm: 'Yes', cancel: 'No' },
  };
  const dialogRef = dialog.open(ConfirmationDialogComponent, {
    data: dialogData,
  });

  dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    if (confirmed) {
      element.prevous_connector_name = element.connector_name;
      if (element.ignore_resource_disconnect_check !== undefined) {
        element.ignore_resource_disconnect_check = true;
      }
      saveFunc(element);
    } else {
      // Mark the row as dirty so that the user will be warned of the issue again
      // if they do not update the value on subsequent save attempts.
      element.dirty = true;
      if (element.ignore_resource_disconnect_check !== undefined) {
        element.ignore_resource_disconnect_check = false;
      }
    }
  });
}

export function isConnectorBeingRemovedFromResource<
  T extends { connector_name: string; prevous_connector_name: string; ignore_resource_disconnect_check?: boolean }
>(element: T): boolean {
  if (element.ignore_resource_disconnect_check !== undefined) {
    if (element.ignore_resource_disconnect_check) {
      return false;
    }
  }
  return element.connector_name === '' && element.prevous_connector_name !== '';
}

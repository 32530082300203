import { Connector, Organisation, DesktopResourceSpec, ApplicationServicesService, DesktopResource } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { AppState } from '@app/core';
import {
  ActionApiApplicationsResetDesktopModel,
  ActionApiApplicationsResetDesktopModelSubmissionStatus,
  ActionApiApplicationsSubmittingDesktopModel,
} from '@app/core/api-applications/api-applications.actions';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import {
  selectApiApplicationsDesktopModel,
  selectApiApplicationsDesktopModelStatus,
} from '@app/core/api-applications/api-applications.selectors';
import {
  getDesktopServerConfiguration,
  getRemoteDesktopApplicationCommandPathTooltipText,
} from '@app/core/application-service-state/application-services-utils';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList } from '@app/core/connector-state/connector.selectors';
import { DesktopModel } from '@app/core/models/desktop/desktop-model';
import { selectCurrentOrganisation } from '@app/core/organisations/organisations.selectors';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { FileHelperService } from '@app/core/services/file-helper/file-helper.service';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminIssuers } from '@app/core/user/permissions/issuers.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { delayStepperAdvanceOnSuccessfulApply, getStartOptionData, handleStateOnFirstStepSelection } from '../application-template-utils';
import { DefaultStepperState } from '../default-stepper-state';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceType } from '../resource-type.enum';
import { getTrimmedResourceName } from '../resource-utils';
import { StepperType } from '../stepper-type.enum';
import { capitalizeFirstLetter, getEmptyStringIfUnset, modifyDataOnFormBlur, pluralizeString } from '../utils';

@Component({
  selector: 'portal-desktop-stepper',
  templateUrl: './desktop-stepper.component.html',
  styleUrls: ['./desktop-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopStepperComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public currentDesktopModel: DesktopModel;
  private desktopModelStatus: ApplicationModelStatus;
  public hasAppsPermissions: boolean;
  public hasUsersPermissions: boolean;
  public hasIssuersPermissions: boolean;
  public connectors: Array<Connector>;
  public startOptionFormGroup: UntypedFormGroup;
  public connectorFormGroup: UntypedFormGroup;
  public desktopNameFormGroup: UntypedFormGroup;
  public addressFormGroup: UntypedFormGroup;
  public remoteDesktopFormGroup: UntypedFormGroup;
  public serverConfigurationFormGroup: UntypedFormGroup;
  public currentOrg: Organisation;
  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();
  public desktopStepperState: DefaultStepperState = {
    selectedStartOption: undefined,
  };
  public stepperType = StepperType.desktop;
  public startOptionData = getStartOptionData(this.stepperType, false);
  public hasTemplates = false;
  public completedDesktopText = `Your desktop setup is complete (you may need to assign user permissions). Please review it below. Make any corrections needed above.`;
  public resourceType = ResourceType.desktop;

  public keyTabManager: KeyTabManager = new KeyTabManager();

  public capitalizeFirstLetter = capitalizeFirstLetter;
  public pluralizeString = pluralizeString;
  public pageDescriptiveText = `A Desktop is a Microsoft Remote Desktop (RDP) resource accessed via the identity-aware proxy`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/zero-trust-desktop-access/`;
  public orgId: string;
  public isRemoteAppOptions: Array<{ value: boolean; displayValue: string }> = [
    { value: true, displayValue: 'Yes' },
    { value: false, displayValue: 'No' },
  ];
  public allowNonDomainJoinedUsersOptions: Array<{ value: boolean; displayValue: string }> = [
    { value: true, displayValue: 'Yes' },
    { value: false, displayValue: 'No' },
  ];
  public hasExtraConfigOptions: Array<{ value: boolean; displayValue: string }> = [
    { value: true, displayValue: 'Yes' },
    { value: false, displayValue: 'No' },
  ];
  public allowNonDomainJoinedUsersText = `I have machines who are not on my domain (Network Level Association) that will use this desktop`;
  public commandPathTooltipText = getRemoteDesktopApplicationCommandPathTooltipText();
  public vncUsernameOptions: Array<{ value: boolean; displayValue: string }> = [
    { value: true, displayValue: 'Yes' },
    { value: false, displayValue: 'No' },
  ];
  public emptyDesktopResource: DesktopResource = {
    spec: {
      extra_configs: [],
      name: '',
      address: '',
      org_id: '',
      desktop_type: DesktopResourceSpec.DesktopTypeEnum.rdp,
    },
  };

  // This is required in order to reference the enum in the html template.
  public desktopResourceSpec = DesktopResourceSpec;

  @ViewChild('desktopStepper') public stepper: MatStepper;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private customValidatorsService: CustomValidatorsService,
    private applicationServicesService: ApplicationServicesService,
    private fileHelperService: FileHelperService,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.resetModel();
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const hasIssuersPermissions$ = this.store.pipe(select(selectCanAdminIssuers));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrganisation));
    const desktopModelState$ = this.store.pipe(select(selectApiApplicationsDesktopModel));
    const desktopModelStatusState$ = this.store.pipe(select(selectApiApplicationsDesktopModelStatus));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    combineLatest([
      hasUsersPermissions$,
      hasAppsPermissions$,
      hasIssuersPermissions$,
      currentOrg$,
      desktopModelState$,
      desktopModelStatusState$,
      connectorListState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasUsersPermissionsResp,
          hasAppsPermissionsResp,
          hasIssuersPermissionsResp,
          currentOrgResp,
          desktopModelStateResp,
          desktopModelStatusStateResp,
          connectorListStateResp,
        ]: [
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          OrgQualifiedPermission,
          Organisation,
          DesktopModel,
          ApplicationModelStatus,
          Array<Connector>
        ]) => {
          this.orgId = hasUsersPermissionsResp?.orgId;
          this.desktopModelStatus = desktopModelStatusStateResp;
          if (!this.currentDesktopModel || this.desktopModelStatus.complete) {
            this.currentDesktopModel = cloneDeep(desktopModelStateResp);
          }
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasIssuersPermissions = hasIssuersPermissionsResp.hasPermission;
          this.connectors = connectorListStateResp;
          this.currentOrg = currentOrgResp;
          if (!this.desktopModelStatus.saving) {
            this.initializeFormGroups();
          }
          delayStepperAdvanceOnSuccessfulApply(this.stepper, this.desktopModelStatus);
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroups(): void {
    this.initializeStartOptionFormGroup();
    this.initializeConnectorFormGroup();
    this.initializeDesktopNameFormGroup();
    this.initializeAddressFormGroup();
    this.initializeRemoteDesktopFormGroup();
    this.initializeServerConfigurationFormGroup();
    this.changeDetector.detectChanges();
  }

  private initializeStartOptionFormGroup(): void {
    this.startOptionFormGroup = this.formBuilder.group({
      selectedStartOption: [this.desktopStepperState.selectedStartOption, Validators.required],
    });
  }

  private initializeConnectorFormGroup(): void {
    this.connectorFormGroup = this.formBuilder.group({
      connector_name: [getEmptyStringIfUnset(this.currentDesktopModel?.connector_name), [Validators.required]],
    });
  }

  public getModel(): DesktopModel | undefined {
    return this.currentDesktopModel;
  }

  private initializeDesktopNameFormGroup(): void {
    this.desktopNameFormGroup = this.formBuilder.group({
      name: [
        getEmptyStringIfUnset(this.currentDesktopModel?.name),
        [Validators.required, this.customValidatorsService.resourceNameValidator()],
        [this.customValidatorsService.isDuplicateResourceName(this.orgId, this.desktopModelStatus.complete)],
      ],
    });
  }

  private initializeAddressFormGroup(): void {
    this.addressFormGroup = this.formBuilder.group({
      address: [
        getEmptyStringIfUnset(this.currentDesktopModel?.address),
        [Validators.required, this.customValidatorsService.hostnameOrIP4Validator()],
      ],
      port: [
        getEmptyStringIfUnset(
          !!this.currentDesktopModel?.config?.ports && !!this.currentDesktopModel.config.ports[0]?.port
            ? this.currentDesktopModel.config.ports[0].port
            : ''
        ),
        [Validators.required, this.customValidatorsService.portValidator()],
      ],
    });
  }

  private initializeRemoteDesktopFormGroup(): void {
    this.remoteDesktopFormGroup = this.formBuilder.group({
      allow_non_domain_joined_users: [this.currentDesktopModel?.allow_non_domain_joined_users, [Validators.required]],
      isRemoteApp: [this.remoteDesktopFormGroup?.get('isRemoteApp')?.value, [Validators.required]],
      command_path: getEmptyStringIfUnset(this.currentDesktopModel?.remote_app?.command_path),
      command_arguments: getEmptyStringIfUnset(this.currentDesktopModel?.remote_app?.command_arguments),
      working_directory: getEmptyStringIfUnset(this.currentDesktopModel?.remote_app?.working_directory),
      hasExtraConfig: [this.remoteDesktopFormGroup?.get('hasExtraConfig')?.value, [Validators.required]],
    });
  }

  public getExtraConfigLabelText() {
    return `My remote desktop has configuration overrides:`;
  }

  public getHasExtraConfigFormValue(): boolean {
    return this.remoteDesktopFormGroup.get('hasExtraConfig').value;
  }

  private initializeServerConfigurationFormGroup(): void {
    this.serverConfigurationFormGroup = this.formBuilder.group({
      vnc_username: this.currentDesktopModel?.vnc_username,
      read_write_username: [
        getEmptyStringIfUnset(
          this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_write_username
        ),
      ],
      read_write_password: [
        getEmptyStringIfUnset(
          this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_write_password
        ),
        [this.customValidatorsService.vncPasswordValidator()],
      ],
      read_only_username: [
        getEmptyStringIfUnset(this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_only_username),
      ],
      read_only_password: [
        getEmptyStringIfUnset(this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_only_password),
        [this.customValidatorsService.vncPasswordValidator()],
      ],
    });
  }

  public hasAllPermissions(): boolean {
    return !!this.hasAppsPermissions && !!this.hasUsersPermissions && !!this.hasIssuersPermissions;
  }

  public showNoPermissionsText(): boolean {
    if (this.hasAppsPermissions === undefined || this.hasUsersPermissions === undefined || this.hasIssuersPermissions === undefined) {
      return false;
    }
    if (this.hasAllPermissions()) {
      return false;
    }
    return true;
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    let formValue = form.get(formField).value;
    if (formField === 'name') {
      formValue = getTrimmedResourceName(formValue);
    }
    obj[formField] = formValue;
    this.updateDesktopModel();
  }

  public updateConnector(connectorName: string): void {
    this.currentDesktopModel.connector_name = connectorName;
    this.updateDesktopModel();
  }

  private updateDesktopModel(startStepChanged = false): void {
    if (this.isRdpDesktop()) {
      this.currentDesktopModel.desktop_type = DesktopResourceSpec.DesktopTypeEnum.rdp;
      if (startStepChanged) {
        this.addressFormGroup.get('port').setValue('3389');
      }
      if (!this.currentDesktopModel.remote_app) {
        this.currentDesktopModel.remote_app = {
          command_path: '',
          command_arguments: '',
          working_directory: '',
        };
      }
      this.currentDesktopModel.remote_app.command_path = this.remoteDesktopFormGroup.get('command_path').value;
      this.currentDesktopModel.remote_app.command_arguments = this.remoteDesktopFormGroup.get('command_arguments').value;
      this.currentDesktopModel.remote_app.working_directory = this.remoteDesktopFormGroup.get('working_directory').value;
    }
    if (this.isVncDesktop()) {
      this.currentDesktopModel.desktop_type = DesktopResourceSpec.DesktopTypeEnum.vnc;
      if (startStepChanged) {
        this.addressFormGroup.get('port').setValue('5900');
      }
      this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_write_username =
        this.serverConfigurationFormGroup.get('read_write_username').value;
      this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_write_password =
        this.serverConfigurationFormGroup.get('read_write_password').value;
      this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_only_username =
        this.serverConfigurationFormGroup.get('read_only_username').value;
      this.currentDesktopModel.connection_info.vnc_connection_info.password_authentication_info.read_only_password =
        this.serverConfigurationFormGroup.get('read_only_password').value;
    }
    this.currentDesktopModel.config.ports[0].port = this.addressFormGroup.get('port').value;
  }

  public isVncDesktop(): boolean {
    return this.desktopStepperState.selectedStartOption === DesktopResourceSpec.DesktopTypeEnum.vnc;
  }

  public isRdpDesktop(): boolean {
    return this.desktopStepperState.selectedStartOption === DesktopResourceSpec.DesktopTypeEnum.rdp;
  }

  public isStepperComplete(): boolean {
    return this.desktopModelStatus.complete;
  }

  public submitDesktopModel(model: DesktopModel): void {
    if (!!this.getHasExtraConfigFormValue()) {
      model.extra_configs = this.emptyDesktopResource.spec.extra_configs;
    }
    this.store.dispatch(new ActionApiApplicationsSubmittingDesktopModel(model));
  }

  public resetDesktopModelStatus(): void {
    this.store.dispatch(new ActionApiApplicationsResetDesktopModelSubmissionStatus());
  }

  public isStartStepValid(): boolean {
    if (this.startOptionFormGroup.invalid) {
      return false;
    }
    return true;
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(
      selectedIndex,
      this.desktopModelStatus,
      this.desktopStepperState,
      this.resetModel.bind(this),
      this.initializeStartOptionFormGroup.bind(this)
    );
  }

  public onRemoteAppOptionChange(isUsingRemoteApp: boolean): void {
    this.remoteDesktopFormGroup.get('isRemoteApp').setValue(isUsingRemoteApp);
    if (isUsingRemoteApp) {
      this.remoteDesktopFormGroup.get('command_path').addValidators([Validators.required]);
    } else {
      this.remoteDesktopFormGroup.get('command_path').clearValidators();
      this.remoteDesktopFormGroup.get('command_path').setValue('');
      this.remoteDesktopFormGroup.get('command_arguments').setValue('');
      this.remoteDesktopFormGroup.get('working_directory').setValue('');
      this.updateDesktopModel();
    }
  }

  public onAllowNonDomainJoinedUsersOptionChange(option: boolean): void {
    this.remoteDesktopFormGroup.get('allow_non_domain_joined_users').setValue(option);
    this.currentDesktopModel.allow_non_domain_joined_users = option;
    this.updateDesktopModel();
  }

  public onVncUsernameOptionChange(option: boolean): void {
    this.serverConfigurationFormGroup.get('vnc_username').setValue(option);
    this.currentDesktopModel.vnc_username = option;
    this.updateDesktopModel();
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetDesktopModel());
  }

  public resetDesktopModelAndStatus(): void {
    this.resetModel();
    this.resetDesktopModelStatus();
  }

  public isInProgress(): boolean {
    return !!this.currentDesktopModel.connector_name && !this.desktopModelStatus.complete;
  }

  public downloadFile(): void {
    getDesktopServerConfiguration(this.applicationServicesService, this.orgId, this.currentDesktopModel.id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        this.fileHelperService.downloadDesktopConfigToBrowser(resp, this.currentDesktopModel.name, this.renderer);
      });
  }

  public canDeactivate(): Observable<boolean> | boolean {
    this.resetModel();
    return true;
  }
}

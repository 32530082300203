<portal-page-info
  *ngIf="showPageInfo"
  pageTitle="Resource Groups"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasAppsPermissions" 
  class="resource-group-container"
>
  <portal-resource-groups-csv
    *ngIf="!hideUploadDownloadButtons"
    [resourceGroups]="tableData"
    [resourceIdToResourceMap]="resourceIdToResourceMap"
    [resourceNameAndTypeToResourceMap]="resourceNameAndTypeToResourceMap"
    [orgId]="orgId"
    (updateTableData)="onUpdateTableData($event)"
    [isUploading]="isUploading"
    [progressBarController]="progressBarController"
  >
  </portal-resource-groups-csv>

  <portal-table-layout
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    [hideFilter]="hideFilter"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>

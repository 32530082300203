import { Action, createAction, props } from '@ngrx/store';
import {
  Application,
  Environment,
  EnvironmentConfigVarList,
  ApplicationFileConfigElement,
  EnvironmentConfig,
  RuleV2,
  RoleToRuleEntry,
  RoleV2,
  FileSummary,
  FileShareService,
  DesktopResource,
  SSHResource,
  PatchErrorImpl,
  ApplicationService,
  Launcher,
  AuthenticationDocument,
  IssuerClient,
  Group,
  AgentConnector,
  RuleConfig,
  APIKey,
} from '@agilicus/angular';
import { FileConfigElement } from '@app/shared/components/application-environment-files/application-environment-files.component';
import { AppBundle } from '../api/applications/app-bundle';
import { ApplicationModel } from '@app/core/models/application/application-model';
import { FileShareModel } from '../models/file-share/file-share-model';
import { DesktopModel } from '../models/desktop/desktop-model';
import { SSHModel } from '../models/ssh/ssh-model';
import { NetworkModel } from '../models/network/network-model';
import { LauncherModel } from '../models/launcher/launcher-model';
import { PolicyTemplateInstanceResource } from '../api/policy-template-instance/policy-template-instance-utils';
import { APIKeyModel } from '../models/api-key/api-key-model';

export interface DemoDeleteResources {
  application: Application;
  issuerClient: IssuerClient[];
  group: Group;
  desktop: DesktopResource;
  share: FileShareService;
  network: ApplicationService;
  agentConnector: AgentConnector;
}

export enum ApiApplicationsActionTypes {
  SET_APP_STATE = '[Api Applications] SetAppState',
  SET_CURRENT_APP_STATE = '[Api Applications] SetCurrentAppState',
  UPDATE_APPLICATIONS = '[Api Applications] UpdateApplications',
  MODIFY_CURRENT_APP = '[Api Applications] ModifyCurrentApp',
  APP_SAVE_FINISHED = '[Api Applications] AppSaveFinished',
  UNSET_DEFAULT_ROLE_FLAG = '[Api Applications] UnsetDefaultRoleFlag',
  CREATING_NEW_APP = '[Api Applications] CreatingNewApp',
  CREATING_NEW_APP_CANCELED = '[Api Applications] CreatingNewAppCanceled',
  UPDATE_APP_ID = '[Api Applications] UpdateAppId',
  REFRESH_APP_STATE = '[Api Applications] RefreshAppState',
  UPDATE_CURRENT_APP = '[Api Applications] UpdateCurrentApp',
  APP_DELETE_FINISHED = '[Api Applications] AppDeleteFinished',
  APP_DELETES_FINISHED = '[Api Applications] AppDeletesFinished',
  CREATING_NEW_APP_CANCELED_ON_APP_CHANGES = '[Api Applications] CreatingNewAppCanceledOnAppChanges',
  UPDATE_CURRENT_ENV = '[Api Applications] UpdateCurrentEnv',
  MODIFY_ENV = '[Api Applications] ModifyEnv',
  UPDATE_ENV_NAME = '[Api Applications] UpdateEnvName',
  REFRESH_ENV_STATE = '[Api Applications] RefreshEnvState',
  UPDATE_CURRENT_RULE = '[Api Applications] UpdateCurrentRule',
  REFRESH_RULE_STATE = '[Api Applications] UpdateRuleState',
  RELOAD_APPLICATION = '[Api Applications] ReloadApplication',
  LOAD_APPLICATIONS = '[Api Applications] LoadApplications',
  INIT_APPLICATIONS = '[Api Applications] InitApplications',
  SET_ENV_CONFIG_VAR_LIST = '[Api Applications] SetEnvConfigVarList',
  SAVING_ENV_CONFIG_VAR_LIST = '[Api Applications] SavingEnvConfigVarList',
  SET_ENV_FILE_CONFIG_LIST = '[Api Applications] SetEnvFileConfigList',
  ENV_CONFIG_VAR_SAVE_FINISHED = '[Api Applications] EnvConfigVarSaveFinished',
  CREATING_ENV_FILE_CONFIG = '[Api Applications] CreatingEnvFileConfig',
  ADD_TO_ENV_FILE_CONFIG_LIST = '[Api Applications] AddToEnvFileConfigList',
  MODIFYING_ENV_FILE_CONFIG = '[Api Applications] ModifyingEnvFileConfig',
  UPDATE_ENV_FILE_CONFIG_LIST = '[Api Applications] UpdateEnvFileConfigList',
  DELETE_ENV_FILE_CONFIG = '[Api Applications] DeleteEnvFileConfig',
  REMOVE_FROM_ENV_FILE_CONFIG_LIST = '[Api Applications] RemoveFromEnvFileConfigList',
  REMOVE_NEW_FILE_ADDED_FLAG = '[Api Applications] RemoveNewFileAddedFlag',
  FAILED_ENV_FILE_CONFIG_UPDATE = '[Api Applications] FailedEnvFileConfigUpdate',
  LOADING_ENV_FILE_CONFIGS = '[Api Applications] LoadingEnvFileConfigs',
  SET_APP_BUNDLE_LIST = '[Api Applications] SetAppBundleList',
  CREATING_APP_BUNDLE = '[Api Applications] CreatingAppBundle',
  FAILED_APP_BUNDLE_UPDATE = '[Api Applications] FailedAppBundleUpdate',
  ADD_TO_APP_BUNDLE_LIST = '[Api Applications] AddToAppBundleList',
  MODIFYING_APP_BUNDLE = '[Api Applications] ModifyingAppBundle',
  UPDATE_APP_BUNDLE_LIST = '[Api Applications] UpdateAppBundleList',
  DELETING_APP_BUNDLE = '[Api Applications] DeletingAppBundle',
  REMOVE_FROM_APP_BUNDLE_LIST = '[Api Applications] RemoveFromAppBundleList',
  REMOVE_NEW_APP_BUNDLE_ADDED_FLAG = '[Api Applications] RemoveNewAppBundleAddedFlag',
  SET_ENV_CONFIG_LIST = '[Api Applications] SetEnvConfigList',
  FAILED_ENV_CONFIG_LOAD = '[Api Applications] FailedEnvConfigLoad',
  SAVING_ENV_CONFIG = '[Api Applications] SavingEnvConfig',
  ADD_TO_ENV_CONFIG_LIST = '[Api Applications] AddToEnvConfigList',
  UPDATE_ENV_CONFIG_LIST = '[Api Applications] UpdateEnvConfigList',
  DELETING_ENV_CONFIG = '[Api Applications] DeletingEnvConfig',
  REMOVE_FROM_ENV_CONFIG_LIST = '[Api Applications] RemoveFromEnvConfigList',
  SET_ROLE_LIST = '[Api Applications] SetRoleList',
  FAILED_ROLE_LOAD = '[Api Applications] FailedRoleLoad',
  SAVING_ROLE = '[Api Applications] SavingRole',
  ADD_TO_ROLE_LIST = '[Api Applications] AddToRoleList',
  UPDATE_ROLE_LIST = '[Api Applications] UpdateRoleList',
  DELETING_ROLE = '[Api Applications] DeletingRole',
  REMOVE_FROM_ROLE_LIST = '[Api Applications] RemoveFromRoleList',
  REFRESH_ROLE_STATE = '[Api Applications] RefreshRoleState',
  SET_RULE_LIST = '[Api Applications] SetRuleList',
  SET_CURRENT_RULE_FROM_POLICY = '[Api Applications] SetCurrentRuleFromPolicy',
  FAILED_RULE_LOAD = '[Api Applications] FailedRuleLoad',
  SAVING_RULE = '[Api Applications] SavingRule',
  ADD_TO_RULE_LIST = '[Api Applications] AddToRuleList',
  UPDATE_RULE_LIST = '[Api Applications] UpdateRuleList',
  DELETING_RULES = '[Api Applications] DeletingRules',
  REMOVE_FROM_RULE_LIST = '[Api Applications] RemoveFromRuleList',
  SET_ROLE_TO_RULE_ENTRIES_LIST = '[Api Applications] SetRoleToRuleEntriesList',
  FAILED_ROLE_TO_RULE_ENTRIES_LOAD = '[Api Applications] FailedRoleToRuleEntriesLoad',
  SAVING_ROLE_TO_RULE_ENTRY = '[Api Applications] SavingRoleToRuleEntry',
  SAVING_ROLE_TO_RULE_ENTRIES = '[Api Applications] SavingRoleToRuleEntries',
  ADD_TO_ROLE_TO_RULE_ENTRIES_LIST = '[Api Applications] AddToRoleToRuleEntriesList',
  UPDATE_ROLE_TO_RULE_ENTRIES_LIST = '[Api Applications] UpdateRoleToRuleEntriesList',
  DELETING_ROLE_TO_RULE_ENTRIES = '[Api Applications] DeletingRoleToRuleEntries',
  REMOVE_FROM_ROLE_TO_RULE_ENTRIES_LIST = '[Api Applications] RemoveFromRoleToRuleEntriesList',
  RULE_SAVE_FINISHED = '[Api Applications] RuleSaveFinished',
  UPDATE_RULE_ID = '[Api Applications] UpdateRuleId',
  ALL_ENV_CSPS_UPDATED = '[Api Applications] AllEnvCspsUpdated',
  // FAILED_ENV_CONFIG_VAR_UPDATE = '[Api Applications] FailedEnvConfigVarUpdate',
  SAVING_APP_ICON_FILE = '[Api Applications] SavingAppIconFile',
  SUCCESSFUL_APP_ICON_FILE_SAVE = '[Api Applications] SuccessfulAppIconFileSave',
  FAILED_APP_ICON_FILE_SAVE = '[Api Applications] FailedAppIconFileSave',
  UPDATING_APP_ICON_URL = '[Api Applications] UpdatingAppIconUrl',
  RESET_APP_ICON_FILE = '[Api Applications] ResetAppIconFile',
  RESET_APP_ICON_FILE_UPLOAD_STATUS = '[Api Applications] ResetAppIconFileUploadStatus',
  DELETING_APP_ICON_URL = '[Api Applications] DeletingAppIconUrl',
  DELETING_PREVIOUS_APP_ICON_FILES = '[Api Applications] DeletingPreviousAppIconFiles',
  SUCCESSFUL_APP_ICON_FILE_DELETION = '[Api Applications] SuccessfulAppIconFileDeletion',
  FAILED_APP_ICON_FILE_DELETION = '[Api Applications] FailedAppIconDeletion',
  SET_APP_FILES_LIST = '[Api Applications] SetAppFilesList',
  FAILED_APP_FILES_UPDATE = '[Api Applications] FailedAppFilesUpdate',
  REFRESH_APP_FILES_STATE = '[Api Applications] RefreshAppFilesState',
  UPDATE_APPLICATION_MODEL = '[Api Applications] UpdateApplicationModel',
  RESET_APPLICATION_MODEL = '[Api Applications] ResetApplicationModel',
  SUBMITTING_APPLICATION_MODEL = '[Api Applications] SubmittingApplicationModel',
  SUCCESSFUL_APPLICATION_MODEL_SUBMISSION = '[Api Applications] SuccessfulApplicationModelSubmission',
  FAILED_APPLICATION_MODEL_SUBMISSION = '[Api Applications] FailedApplicationModelSubmission',
  RESET_APP_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetAppModelSubmissionStatus',
  UPDATE_FILE_SHARE_SERVICE_MODEL = '[Api Applications] UpdateFileShareServiceModel',
  RESET_FILE_SHARE_SERVICE_MODEL = '[Api Applications] ResetFileShareServiceModel',
  SUBMITTING_FILE_SHARE_SERVICE_MODEL = '[Api Applications] SubmittingFileShareServiceModel',
  SUCCESSFUL_FILE_SHARE_SERVICE_MODEL_SUBMISSION = '[Api Applications] SuccessfulFileShareServiceModelSubmission',
  FAILED_FILE_SHARE_SERVICE_MODEL_SUBMISSION = '[Api Applications] FailedFileShareServiceModelSubmission',
  RESET_FILE_SHARE_SERVICE_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetFileShareServiceModelSubmissionStatus',
  UPDATE_DESKTOP_MODEL = '[Api Applications] UpdateDesktopModel',
  RESET_DESKTOP_MODEL = '[Api Applications] ResetDesktopModel',
  SUBMITTING_DESKTOP_MODEL = '[Api Applications] SubmittingDesktopModel',
  SUCCESSFUL_DESKTOP_MODEL_SUBMISSION = '[Api Applications] SuccessfulDesktopModelSubmission',
  FAILED_DESKTOP_MODEL_SUBMISSION = '[Api Applications] FailedDesktopModelSubmission',
  RESET_DESKTOP_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetDesktopModelSubmissionStatus',
  UPDATE_SSH_MODEL = '[Api Applications] UpdateSSHModel',
  RESET_SSH_MODEL = '[Api Applications] ResetSSHModel',
  SUBMITTING_SSH_MODEL = '[Api Applications] SubmittingSSHModel',
  SUCCESSFUL_SSH_MODEL_SUBMISSION = '[Api Applications] SuccessfulSSHModelSubmission',
  FAILED_SSH_MODEL_SUBMISSION = '[Api Applications] FailedSSHModelSubmission',
  RESET_SSH_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetSSHModelSubmissionStatus',
  UPDATE_APIKEY_MODEL = '[Api Applications] UpdateApiKeyModel',
  RESET_APIKEY_MODEL = '[Api Applications] ResetApiKeyModel',
  SUBMITTING_APIKEY_MODEL = '[Api Applications] SubmittingApiKeyModel',
  SUCCESSFUL_APIKEY_MODEL_SUBMISSION = '[Api Applications] SuccessfulApiKeyModelSubmission',
  FAILED_APIKEY_MODEL_SUBMISSION = '[Api Applications] FailedApiKeyModelSubmission',
  RESET_APIKEY_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetApiKeyModelSubmissionStatus',
  UPDATE_NETWORK_MODEL = '[Api Applications] UpdateNetworkModel',
  RESET_NETWORK_MODEL = '[Api Applications] ResetNetworkModel',
  SUBMITTING_NETWORK_MODEL = '[Api Applications] SubmittingNetworkModel',
  SUCCESSFUL_NETWORK_MODEL_SUBMISSION = '[Api Applications] SuccessfulNetworkModelSubmission',
  FAILED_NETWORK_MODEL_SUBMISSION = '[Api Applications] FailedNetworkModelSubmission',
  RESET_NETWORK_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetNetworkModelSubmissionStatus',
  UPDATE_LAUNCHER_MODEL = '[Api Applications] UpdateLauncherModel',
  RESET_LAUNCHER_MODEL = '[Api Applications] ResetLauncherModel',
  SUBMITTING_LAUNCHER_MODEL = '[Api Applications] SubmittingLauncherModel',
  SUCCESSFUL_LAUNCHER_MODEL_SUBMISSION = '[Api Applications] SuccessfulLauncherModelSubmission',
  FAILED_LAUNCHER_MODEL_SUBMISSION = '[Api Applications] FailedLauncherModelSubmission',
  RESET_LAUNCHER_MODEL_SUBMISSION_STATUS = '[Api Applications] ResetLauncherModelSubmissionStatus',
  DELETE_CSP_ENV_VARS_AND_UPDATE_APP_CONFIG = '[Api Applications] DeleteCspEnvVarsAndUpdateAppConfig',
  SUCCESSFUL_CSP_ENV_VAR_DELETION = '[Api Applications] SuccessfulCspEnvVarDeletion',
  FAILED_CSP_ENV_VAR_DELETION = '[Api Applications] FailedCspEnvVarDeletion',
  MAINTAIN_STATE = '[Api Applications] AppMaintainState',
  RESET_REFRESH_DATA_FLAG = '[Api Applications] ResetRefreshDataFlag',
  CLEAR_APPLICATION_STATE = '[Api Applications] ClearApplicationState',
  CREATE_DEMO = '[Api Applications] CreateDemo',
  SUCCESSFUL_DEMO_CONNECTOR_AND_AUTH_DOC_CREATION = '[Api Applications] SuccessfulDemoConnectorAndAuthDocCreation',
  SUCCESSFUL_DEMO_CREATION = '[Api Applications] SuccessfulDemoCreation',
  FAILED_DEMO_CREATION = '[Api Applications] FailedDemoCreation',
  RESET_DEMO_STATUS = '[Api Applications] ResetDemoStatus',
  DELETE_DEMO = '[Api Applications] DeleteDemo',
  SUCCESSFUL_DEMO_DELETE = '[Api Applications] SuccessfulDemoDelete',
  FAILED_DEMO_DELETE = '[Api Applications] FailedDemoDelete',
}

export class ActionApiApplicationsInitApplications implements Action {
  public readonly type = ApiApplicationsActionTypes.INIT_APPLICATIONS;
  constructor(
    public readonly force: boolean = false,
    public readonly blankSlate: boolean = false,
    public readonly getAllCurrentAppConfig = true
  ) {}
}

export class ActionApiApplicationsLoadApplications implements Action {
  public readonly type = ApiApplicationsActionTypes.LOAD_APPLICATIONS;
  constructor(public readonly org_id: string, public readonly blankSlate: boolean = false) {}
}

export class ActionApiApplicationsSetState implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_APP_STATE;
  constructor(
    public readonly applications: Array<Application>,
    public readonly org_id: string,
    public readonly blankSlate: boolean = false
  ) {}
}

// Ngrx entity action
export const loadApplications = createAction(
  '[Applications/API] Load Applications',
  props<{ objs: Application[]; org_id: string; blankSlate: boolean | undefined }>()
);

// Ngrx entity action
export const clearApplications = createAction('[Applications/API] Clear Applications');

// Ngrx entity action
export const maintainApplications = createAction('[Applications/API] Maintain Applications');

export class ActionApiApplicationsClearApplicationState implements Action {
  public readonly type = ApiApplicationsActionTypes.CLEAR_APPLICATION_STATE;
  constructor() {}
}

export class ActionApiApplicationsSetCurrentApplicationState implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_CURRENT_APP_STATE;
  constructor(public readonly current_application: Application, public readonly org_id: string) {}
}

export class ActionApiApplicationsUpdateApplications implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_APPLICATIONS;
  public constructor(public readonly applications: Array<Application>) {}
}

export class ActionApiApplicationsModifyCurrentApp implements Action {
  public readonly type = ApiApplicationsActionTypes.MODIFY_CURRENT_APP;
  constructor(
    public readonly current_application: Application,
    public readonly setDefaultRoleAndPublishApplication?: boolean,
    public readonly refreshData = false,
    public readonly usePolicyRules = false
  ) {}
}

export class ActionApiApplicationsAppSaveFinished implements Action {
  public readonly type = ApiApplicationsActionTypes.APP_SAVE_FINISHED;
  constructor(public readonly current_application: Application, public readonly refreshData: boolean) {}
}

// Ngrx entity action
export const upsertApplication = createAction('[Application/API] Upsert Application', props<{ obj: Application; refreshData?: boolean }>());

// Ngrx entity action
export const upsertApplications = createAction(
  '[Application/API] Upsert Applications',
  props<{ objs: Array<Application>; refreshData?: boolean }>()
);

// Ngrx entity action
export const refreshApplications = createAction('[Application/API] Refresh Applications');

export class ActionApiApplicationsAppDeleteFinished implements Action {
  public readonly type = ApiApplicationsActionTypes.APP_DELETE_FINISHED;
  constructor(public readonly deleted_application: Application, public readonly refreshData?: boolean) {}
}

// Ngrx entity action
export const deleteApplication = createAction(
  '[Application/API] Delete Application',
  props<{ id: string; obj: Application; refreshData?: boolean }>()
);

export class ActionApiApplicationsAppDeletesFinished implements Action {
  public readonly type = ApiApplicationsActionTypes.APP_DELETES_FINISHED;
  constructor(public readonly deleted_application_ids: Array<string>, public readonly refreshData?: boolean) {}
}

// Ngrx entity action
export const deleteApplications = createAction('[Application/API] Delete Applications', props<{ ids: string[]; refreshData?: boolean }>());

export class ActionApiApplicationsUnsetDefaultRoleFlag implements Action {
  public readonly type = ApiApplicationsActionTypes.UNSET_DEFAULT_ROLE_FLAG;
  constructor() {}
}

export class ActionApiApplicationsCreatingNewApp implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATING_NEW_APP;
  constructor() {}
}

export class ActionApiApplicationsCreatingNewAppCanceled implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATING_NEW_APP_CANCELED;
  constructor() {}
}

export class ActionApiApplicationsCreatingNewAppCanceledOnAppChanges implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATING_NEW_APP_CANCELED_ON_APP_CHANGES;
  constructor() {}
}

export class ActionApiApplicationsUpdateAppId implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_APP_ID;
  constructor(public readonly application_id: string) {}
}

export class ActionApiApplicationsRefreshAppState implements Action {
  public readonly type = ApiApplicationsActionTypes.REFRESH_APP_STATE;
  constructor() {}
}

export class ActionApiApplicationsUpdateCurrentApp implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_CURRENT_APP;
  constructor(public readonly current_application: Application, public readonly org_id: string) {}
}

export class ActionApiApplicationsUpdateCurrentEnv implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_CURRENT_ENV;
  constructor(public readonly current_environment: Environment) {}
}

export class ActionApiApplicationsUpdateEnvName implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_ENV_NAME;
  constructor(public readonly environment_name: string) {}
}

export class ActionApiApplicationsRefreshEnvState implements Action {
  public readonly type = ApiApplicationsActionTypes.REFRESH_ENV_STATE;
  constructor() {}
}

export class ActionApiApplicationsUpdateCurrentRule implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_CURRENT_RULE;
  constructor(public readonly current_rule: RuleV2 | RuleConfig) {}
}

export class ActionApiApplicationsRefreshRuleState implements Action {
  public readonly type = ApiApplicationsActionTypes.REFRESH_RULE_STATE;
  constructor() {}
}

export class ActionApiApplicationsReloadApp implements Action {
  public readonly type = ApiApplicationsActionTypes.RELOAD_APPLICATION;
  constructor(public readonly updated_application: Application, public readonly org_id: string) {}
}

export class ActionApiApplicationsSetEnvConfigVarList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_ENV_CONFIG_VAR_LIST;
  constructor(public readonly current_environment_config_var_list: EnvironmentConfigVarList) {}
}

// Ngrx entity action
export const loadEnvConfigVars = createAction(
  '[EnvConfigVar/API] Load EnvConfigVars',
  props<{ objs: EnvironmentConfigVarList[]; org_id: string; blankSlate: boolean | undefined }>()
);

// Ngrx entity action
export const maintainEnvConfigVars = createAction('[EnvConfigVar/API] Maintain EnvConfigVars');

// Ngrx entity action
export const refreshEnvConfigVars = createAction('[EnvConfigVar/API] Refresh EnvConfigVars');

export class ActionApiApplicationsSavingEnvConfigVarList implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_ENV_CONFIG_VAR_LIST;
  constructor(public readonly current_environment_config_var_list: EnvironmentConfigVarList) {}
}
export class ActionApiApplicationsEnvConfigVarSaveFinished implements Action {
  public readonly type = ApiApplicationsActionTypes.ENV_CONFIG_VAR_SAVE_FINISHED;
  constructor(public readonly current_environment_config_var_list: EnvironmentConfigVarList) {}
}

// Ngrx entity action
export const upsertEnvConfigVar = createAction('[EnvConfigVar/API] Upsert EnvConfigVar', props<{ obj: EnvironmentConfigVarList }>());

export class ActionApiApplicationsSetEnvFileConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_ENV_FILE_CONFIG_LIST;
  constructor(public readonly current_environment_file_config_list: Array<ApplicationFileConfigElement>) {}
}

export class ActionApiApplicationsLoadingEnvFileConfigs implements Action {
  public readonly type = ApiApplicationsActionTypes.LOADING_ENV_FILE_CONFIGS;
  constructor() {}
}

export class ActionApiApplicationsFailedEnvFileConfigUpdate implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_ENV_FILE_CONFIG_UPDATE;
  constructor() {}
}

export class ActionApiApplicationsCreatingEnvFileConfig implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATING_ENV_FILE_CONFIG;
  constructor(public readonly env_file_config_to_create: FileConfigElement, public readonly blob_to_upload: Blob) {}
}

export class ActionApiApplicationsAddToEnvFileConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_ENV_FILE_CONFIG_LIST;
  constructor(public readonly env_file_config_to_add: ApplicationFileConfigElement) {}
}

export class ActionApiApplicationsModifyingEnvFileConfig implements Action {
  public readonly type = ApiApplicationsActionTypes.MODIFYING_ENV_FILE_CONFIG;
  constructor(public readonly env_file_config_to_modify: FileConfigElement, public readonly blob_to_upload?: Blob) {}
}

export class ActionApiApplicationsUpdateEnvFileConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_ENV_FILE_CONFIG_LIST;
  constructor(public readonly env_file_config_to_update: ApplicationFileConfigElement) {}
}

export class ActionApiApplicationsDeletingEnvFileConfig implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETE_ENV_FILE_CONFIG;
  constructor(public readonly env_file_config_to_delete: FileConfigElement) {}
}

export class ActionApiApplicationsRemoveFromEnvFileConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_ENV_FILE_CONFIG_LIST;
  constructor(public readonly deleted_env_file_config: FileConfigElement) {}
}

export class ActionApiApplicationsRemoveNewFileAddedFlag implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_NEW_FILE_ADDED_FLAG;
  constructor() {}
}

export class ActionApiApplicationsCreatingAppBundle implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATING_APP_BUNDLE;
  constructor(public readonly app_bundle_to_create: AppBundle, public readonly new_file: File) {}
}

export class ActionApiApplicationsAddToAppBundleList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_APP_BUNDLE_LIST;
  constructor(public readonly app_bundle_to_add: AppBundle) {}
}

export class ActionApiApplicationsModifyingAppBundle implements Action {
  public readonly type = ApiApplicationsActionTypes.MODIFYING_APP_BUNDLE;
  constructor(public readonly app_bundle_to_modify: AppBundle) {}
}

export class ActionApiApplicationsUpdateAppBundleList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_APP_BUNDLE_LIST;
  constructor(public readonly app_bundle_to_update: AppBundle) {}
}

export class ActionApiApplicationsDeletingAppBundle implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_APP_BUNDLE;
  constructor(public readonly app_bundle_to_delete: AppBundle) {}
}

export class ActionApiApplicationsRemoveFromAppBundleList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_APP_BUNDLE_LIST;
  constructor(public readonly deleted_app_bundle: AppBundle) {}
}

export class ActionApiApplicationsRemoveNewAppBundleAddedFlag implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_NEW_APP_BUNDLE_ADDED_FLAG;
  constructor() {}
}

export class ActionApiApplicationsSetEnvConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_ENV_CONFIG_LIST;
  constructor(public readonly current_external_mounts_list: Array<EnvironmentConfig>) {}
}

export class ActionApiApplicationsFailedEnvConfigLoad implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_ENV_CONFIG_LOAD;
  constructor() {}
}

export class ActionApiApplicationsSavingEnvConfig implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_ENV_CONFIG;
  constructor(public readonly external_mount_to_create: EnvironmentConfig) {}
}

export class ActionApiApplicationsAddToEnvConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_ENV_CONFIG_LIST;
  constructor(public readonly external_mount_to_add: EnvironmentConfig) {}
}

export class ActionApiApplicationsUpdateEnvConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_ENV_CONFIG_LIST;
  constructor(public readonly external_mount_to_update: EnvironmentConfig) {}
}

export class ActionApiApplicationsDeletingEnvConfig implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_ENV_CONFIG;
  constructor(public readonly external_mount_to_delete: EnvironmentConfig) {}
}

export class ActionApiApplicationsRemoveFromEnvConfigList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_ENV_CONFIG_LIST;
  constructor(public readonly deleted_external_mount: EnvironmentConfig) {}
}

export class ActionApiApplicationsSetRoleList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_ROLE_LIST;
  constructor(public readonly current_roles_list: Array<RoleV2>) {}
}

export class ActionApiApplicationsFailedRoleLoad implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_ROLE_LOAD;
  constructor() {}
}

export class ActionApiApplicationsSavingRole implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_ROLE;
  constructor(public readonly api_obj: RoleV2, public readonly setDefaultRoleAndPublishApplication?: boolean) {}
}

export class ActionApiApplicationsAddToRoleList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_ROLE_LIST;
  constructor(public readonly api_obj: RoleV2) {}
}

export class ActionApiApplicationsUpdateRoleList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_ROLE_LIST;
  constructor(public readonly api_obj: RoleV2, public readonly previousRoleId: string) {}
}

export class ActionApiApplicationsDeletingRole implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_ROLE;
  constructor(public readonly api_obj: RoleV2) {}
}

export class ActionApiApplicationsRemoveFromRoleList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_ROLE_LIST;
  constructor(public readonly api_obj: RoleV2, public readonly org_id: string) {}
}

export class ActionApiApplicationsRefreshRoleState implements Action {
  public readonly type = ApiApplicationsActionTypes.REFRESH_ROLE_STATE;
  constructor() {}
}

export class ActionApiApplicationsSetRuleList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_RULE_LIST;
  constructor(
    public readonly current_rules_list: Array<RuleV2>,
    public readonly current_policy_resource_list: Array<PolicyTemplateInstanceResource>
  ) {}
}

export class ActionApiApplicationsSetCurrentRuleFromPolicy implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_CURRENT_RULE_FROM_POLICY;
  constructor(public readonly current_policy_resource_list: Array<PolicyTemplateInstanceResource>) {}
}

export class ActionApiApplicationsFailedRuleLoad implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_RULE_LOAD;
  constructor() {}
}

export class ActionApiApplicationsSavingRule implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_RULE;
  constructor(
    public readonly api_obj: RuleV2,
    public readonly assigned_roles?: Array<RoleToRuleEntry>,
    public readonly roles_to_delete?: Array<RoleToRuleEntry>
  ) {}
}

export class ActionApiApplicationsAddToRuleList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_RULE_LIST;
  constructor(
    public readonly api_obj: RuleV2,
    public readonly assigned_roles?: Array<RoleToRuleEntry>,
    public readonly roles_to_delete?: Array<RoleToRuleEntry>
  ) {}
}

export class ActionApiApplicationsUpdateRuleList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_RULE_LIST;
  constructor(
    public readonly api_obj: RuleV2,
    public readonly assigned_roles?: Array<RoleToRuleEntry>,
    public readonly roles_to_delete?: Array<RoleToRuleEntry>
  ) {}
}

export class ActionApiApplicationsDeletingRules implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_RULES;
  constructor(public readonly api_objs: Array<RuleV2>) {}
}

export class ActionApiApplicationsRemoveFromRuleList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_RULE_LIST;
  constructor(public readonly api_objs: Array<RuleV2>, public readonly org_id: string) {}
}

export class ActionApiApplicationsSetRoleToRuleEntriesList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_ROLE_TO_RULE_ENTRIES_LIST;
  constructor(public readonly current_role_to_rule_entries_list: Array<RoleToRuleEntry>) {}
}

export class ActionApiApplicationsFailedRoleToRuleEntriesLoad implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_ROLE_TO_RULE_ENTRIES_LOAD;
  constructor() {}
}

export class ActionApiApplicationsSavingRoleToRuleEntry implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_ROLE_TO_RULE_ENTRY;
  constructor(public readonly api_obj: RoleToRuleEntry) {}
}

export class ActionApiApplicationsSavingRoleToRuleEntries implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_ROLE_TO_RULE_ENTRIES;
  constructor(public readonly api_objs: Array<RoleToRuleEntry>, public readonly roles_to_delete?: Array<RoleToRuleEntry>) {}
}

export class ActionApiApplicationsAddToRoleToRuleEntriesList implements Action {
  public readonly type = ApiApplicationsActionTypes.ADD_TO_ROLE_TO_RULE_ENTRIES_LIST;
  constructor(public readonly api_obj: RoleToRuleEntry) {}
}

export class ActionApiApplicationsUpdateRoleToRuleEntriesList implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_ROLE_TO_RULE_ENTRIES_LIST;
  constructor(public readonly api_objs: Array<RoleToRuleEntry>, public readonly roles_to_delete?: Array<RoleToRuleEntry>) {}
}

export class ActionApiApplicationsDeletingRoleToRuleEntries implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_ROLE_TO_RULE_ENTRIES;
  constructor(public readonly api_objs: Array<RoleToRuleEntry>) {}
}

export class ActionApiApplicationsRemoveFromRoleToRuleEntriesList implements Action {
  public readonly type = ApiApplicationsActionTypes.REMOVE_FROM_ROLE_TO_RULE_ENTRIES_LIST;
  constructor(public readonly api_objs: Array<RoleToRuleEntry>) {}
}

export class ActionApiApplicationsUpdateRuleId implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_RULE_ID;
  constructor(public readonly rule_id: string) {}
}

export class ActionApiApplicationsAllEnvCspsUpdated implements Action {
  public readonly type = ApiApplicationsActionTypes.ALL_ENV_CSPS_UPDATED;
  constructor() {}
}

export class ActionApiApplicationsSavingAppIconFile implements Action {
  public readonly type = ApiApplicationsActionTypes.SAVING_APP_ICON_FILE;
  constructor(public readonly new_file: File) {}
}

export class ActionApiApplicationsSuccessfulAppIconFileSave implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_APP_ICON_FILE_SAVE;
  constructor(public readonly new_app_icon_file: FileSummary) {}
}

export class ActionApiApplicationsFailedAppIconFileSave implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_APP_ICON_FILE_SAVE;
  constructor() {}
}

export class ActionApiApplicationsUpdatingAppIconUrl implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATING_APP_ICON_URL;
  constructor(public readonly new_app_icon_file: FileSummary) {}
}

export class ActionApiApplicationsDeletingAppIconUrl implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_APP_ICON_URL;
  constructor() {}
}

export class ActionApiApplicationsDeletingPreviousAppIconFiles implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETING_PREVIOUS_APP_ICON_FILES;
  constructor() {}
}

export class ActionApiApplicationsSuccessfulAppIconFileDeletion implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_APP_ICON_FILE_DELETION;
  constructor() {}
}

export class ActionApiApplicationsFailedAppIconFileDeletion implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_APP_ICON_FILE_DELETION;
  constructor() {}
}

export class ActionApiApplicationsResetAppIconFileUploadStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_APP_ICON_FILE_UPLOAD_STATUS;
  constructor() {}
}

export class ActionApiApplicationsSetAppFilesList implements Action {
  public readonly type = ApiApplicationsActionTypes.SET_APP_FILES_LIST;
  constructor(public readonly application_files_list: Array<FileSummary>) {}
}

export class ActionApiApplicationsFailedAppFilesUpdate implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_APP_FILES_UPDATE;
  constructor() {}
}

export class ActionApiApplicationsRefreshAppFilesState implements Action {
  public readonly type = ApiApplicationsActionTypes.REFRESH_APP_FILES_STATE;
  constructor(public readonly org_id: string) {}
}

export class ActionApiApplicationsUpdateApplicationModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_APPLICATION_MODEL;
  constructor(public readonly application_model: ApplicationModel) {}
}

export class ActionApiApplicationsResetApplicationModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_APPLICATION_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingApplicationModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_APPLICATION_MODEL;
  constructor(
    public readonly appModel: ApplicationModel,
    public readonly authDoc?: AuthenticationDocument,
    public readonly usePolicyRules = false
  ) {}
}

export class ActionApiApplicationsSuccessfulApplicationModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_APPLICATION_MODEL_SUBMISSION;
  constructor(public readonly model: ApplicationModel) {}
}

export class ActionApiApplicationsFailedApplicationModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_APPLICATION_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetAppModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_APP_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateFileShareServiceModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_FILE_SHARE_SERVICE_MODEL;
  constructor(public readonly model: FileShareModel) {}
}

export class ActionApiApplicationsResetFileShareServiceModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_FILE_SHARE_SERVICE_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingFileShareServiceModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_FILE_SHARE_SERVICE_MODEL;
  constructor(public readonly model: FileShareModel) {}
}

export class ActionApiApplicationsSuccessfulFileShareServiceModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_FILE_SHARE_SERVICE_MODEL_SUBMISSION;
  constructor(public readonly share: FileShareService, public readonly model: FileShareModel) {}
}

export class ActionApiApplicationsFailedFileShareServiceModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_FILE_SHARE_SERVICE_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetFileShareServiceSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_FILE_SHARE_SERVICE_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateDesktopModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_DESKTOP_MODEL;
  constructor(public readonly desktop_model: DesktopModel) {}
}

export class ActionApiApplicationsResetDesktopModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_DESKTOP_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingDesktopModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_DESKTOP_MODEL;
  constructor(public readonly desktop_model: DesktopModel) {}
}

export class ActionApiApplicationsSuccessfulDesktopModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_DESKTOP_MODEL_SUBMISSION;
  constructor(public readonly desktopResource: DesktopResource, public readonly model: DesktopModel) {}
}

export class ActionApiApplicationsFailedDesktopModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_DESKTOP_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetDesktopModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_DESKTOP_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateSSHModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_SSH_MODEL;
  constructor(public readonly ssh_model: SSHModel) {}
}

export class ActionApiApplicationsResetSSHModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_SSH_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingSSHModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_SSH_MODEL;
  constructor(public readonly ssh_model: SSHModel) {}
}

export class ActionApiApplicationsSuccessfulSSHModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_SSH_MODEL_SUBMISSION;
  constructor(public readonly SSHResource: SSHResource) {}
}

export class ActionApiApplicationsFailedSSHModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_SSH_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetSSHModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_SSH_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateApiKeyModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_APIKEY_MODEL;
  constructor(public readonly api_key_model: APIKeyModel) {}
}

export class ActionApiApplicationsResetApiKeyModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_APIKEY_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingApiKeyModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_APIKEY_MODEL;
  constructor(public readonly api_key_model: APIKeyModel) {}
}

export class ActionApiApplicationsSuccessfulApiKeyModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_APIKEY_MODEL_SUBMISSION;
  constructor(public readonly APIKey: APIKey) {}
}

export class ActionApiApplicationsFailedApiKeyModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_APIKEY_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetApiKeyModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_APIKEY_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateNetworkModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_NETWORK_MODEL;
  constructor(public readonly model: NetworkModel) {}
}

export class ActionApiApplicationsResetNetworkModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_NETWORK_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingNetworkModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_NETWORK_MODEL;
  constructor(public readonly model: NetworkModel) {}
}

export class ActionApiApplicationsSuccessfulNetworkModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_NETWORK_MODEL_SUBMISSION;
  constructor(public readonly object: ApplicationService) {}
}

export class ActionApiApplicationsFailedNetworkModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_NETWORK_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetNetworkModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_NETWORK_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsUpdateLauncherModel implements Action {
  public readonly type = ApiApplicationsActionTypes.UPDATE_LAUNCHER_MODEL;
  constructor(public readonly model: LauncherModel) {}
}

export class ActionApiApplicationsResetLauncherModel implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_LAUNCHER_MODEL;
  constructor() {}
}

export class ActionApiApplicationsSubmittingLauncherModel implements Action {
  public readonly type = ApiApplicationsActionTypes.SUBMITTING_LAUNCHER_MODEL;
  constructor(public readonly model: LauncherModel) {}
}

export class ActionApiApplicationsSuccessfulLauncherModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_LAUNCHER_MODEL_SUBMISSION;
  constructor(public readonly object: Launcher) {}
}

export class ActionApiApplicationsFailedLauncherModelSubmission implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_LAUNCHER_MODEL_SUBMISSION;
  constructor() {}
}

export class ActionApiApplicationsResetLauncherModelSubmissionStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_LAUNCHER_MODEL_SUBMISSION_STATUS;
  constructor() {}
}

export class ActionApiApplicationsRuleSaveFinished implements Action {
  public readonly type = ApiApplicationsActionTypes.RULE_SAVE_FINISHED;
  constructor() {}
}

export class ActionApiApplicationsCreateDemo implements Action {
  public readonly type = ApiApplicationsActionTypes.CREATE_DEMO;
  constructor() {}
}

export class ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CONNECTOR_AND_AUTH_DOC_CREATION;
  constructor(public agentConnector: AgentConnector, public authDoc: AuthenticationDocument) {}
}

export class ActionApiApplicationsSuccessfulDemoCreation implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CREATION;
  constructor() {}
}

export class ActionApiApplicationsFailedDemoCreation implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_DEMO_CREATION;
  constructor() {}
}

export class ActionApiApplicationsResetDemoStatus implements Action {
  public readonly type = ApiApplicationsActionTypes.RESET_DEMO_STATUS;
  constructor() {}
}

export class ActionApiApplicationsDeleteDemo implements Action {
  public readonly type = ApiApplicationsActionTypes.DELETE_DEMO;
  constructor() {}
}

export class ActionApiApplicationsSuccessfulDemoDelete implements Action {
  public readonly type = ApiApplicationsActionTypes.SUCCESSFUL_DEMO_DELETE;
  constructor(public deletedResources: DemoDeleteResources) {}
}

export class ActionApiApplicationsFailedDemoDelete implements Action {
  public readonly type = ApiApplicationsActionTypes.FAILED_DEMO_DELETE;
  constructor() {}
}

/**
 * The state is not changed
 */
export class ActionApiApplicationsMaintainState implements Action {
  public readonly type = ApiApplicationsActionTypes.MAINTAIN_STATE;
  constructor() {}
}

export type ApiApplicationsActions =
  | ActionApiApplicationsSetState
  | ActionApiApplicationsSetCurrentApplicationState
  | ActionApiApplicationsInitApplications
  | ActionApiApplicationsUpdateApplications
  | ActionApiApplicationsAppSaveFinished
  | ActionApiApplicationsCreatingNewApp
  | ActionApiApplicationsCreatingNewAppCanceled
  | ActionApiApplicationsCreatingNewAppCanceledOnAppChanges
  | ActionApiApplicationsModifyCurrentApp
  | ActionApiApplicationsUpdateAppId
  | ActionApiApplicationsRefreshAppState
  | ActionApiApplicationsUpdateCurrentApp
  | ActionApiApplicationsAppDeleteFinished
  | ActionApiApplicationsAppDeletesFinished
  | ActionApiApplicationsUpdateCurrentEnv
  | ActionApiApplicationsUpdateEnvName
  | ActionApiApplicationsRefreshEnvState
  | ActionApiApplicationsUpdateCurrentRule
  | ActionApiApplicationsRefreshRuleState
  | ActionApiApplicationsReloadApp
  | ActionApiApplicationsLoadApplications
  | ActionApiApplicationsSetEnvConfigVarList
  | ActionApiApplicationsSavingEnvConfigVarList
  | ActionApiApplicationsEnvConfigVarSaveFinished
  | ActionApiApplicationsSetEnvFileConfigList
  | ActionApiApplicationsLoadingEnvFileConfigs
  | ActionApiApplicationsFailedEnvFileConfigUpdate
  | ActionApiApplicationsCreatingEnvFileConfig
  | ActionApiApplicationsAddToEnvFileConfigList
  | ActionApiApplicationsModifyingEnvFileConfig
  | ActionApiApplicationsUpdateEnvFileConfigList
  | ActionApiApplicationsDeletingEnvFileConfig
  | ActionApiApplicationsRemoveFromEnvFileConfigList
  | ActionApiApplicationsRemoveNewFileAddedFlag
  | ActionApiApplicationsCreatingAppBundle
  | ActionApiApplicationsAddToAppBundleList
  | ActionApiApplicationsModifyingAppBundle
  | ActionApiApplicationsUpdateAppBundleList
  | ActionApiApplicationsDeletingAppBundle
  | ActionApiApplicationsRemoveFromAppBundleList
  | ActionApiApplicationsRemoveNewAppBundleAddedFlag
  | ActionApiApplicationsSetEnvConfigList
  | ActionApiApplicationsFailedEnvConfigLoad
  | ActionApiApplicationsSavingEnvConfig
  | ActionApiApplicationsAddToEnvConfigList
  | ActionApiApplicationsUpdateEnvConfigList
  | ActionApiApplicationsDeletingEnvConfig
  | ActionApiApplicationsRemoveFromEnvConfigList
  | ActionApiApplicationsSetRoleList
  | ActionApiApplicationsFailedRoleLoad
  | ActionApiApplicationsSavingRole
  | ActionApiApplicationsAddToRoleList
  | ActionApiApplicationsUpdateRoleList
  | ActionApiApplicationsDeletingRole
  | ActionApiApplicationsRemoveFromRoleList
  | ActionApiApplicationsRefreshRoleState
  | ActionApiApplicationsSetRuleList
  | ActionApiApplicationsSetCurrentRuleFromPolicy
  | ActionApiApplicationsFailedRuleLoad
  | ActionApiApplicationsSavingRule
  | ActionApiApplicationsAddToRuleList
  | ActionApiApplicationsUpdateRuleList
  | ActionApiApplicationsDeletingRules
  | ActionApiApplicationsRemoveFromRuleList
  | ActionApiApplicationsSetRoleToRuleEntriesList
  | ActionApiApplicationsFailedRoleToRuleEntriesLoad
  | ActionApiApplicationsSavingRoleToRuleEntry
  | ActionApiApplicationsSavingRoleToRuleEntries
  | ActionApiApplicationsAddToRoleToRuleEntriesList
  | ActionApiApplicationsUpdateRoleToRuleEntriesList
  | ActionApiApplicationsDeletingRoleToRuleEntries
  | ActionApiApplicationsRemoveFromRoleToRuleEntriesList
  | ActionApiApplicationsRuleSaveFinished
  | ActionApiApplicationsUpdateRuleId
  | ActionApiApplicationsAllEnvCspsUpdated
  | ActionApiApplicationsSavingAppIconFile
  | ActionApiApplicationsSuccessfulAppIconFileSave
  | ActionApiApplicationsFailedAppIconFileSave
  | ActionApiApplicationsUpdatingAppIconUrl
  | ActionApiApplicationsDeletingAppIconUrl
  | ActionApiApplicationsDeletingPreviousAppIconFiles
  | ActionApiApplicationsSuccessfulAppIconFileDeletion
  | ActionApiApplicationsFailedAppIconFileDeletion
  | ActionApiApplicationsResetAppIconFileUploadStatus
  | ActionApiApplicationsSetAppFilesList
  | ActionApiApplicationsFailedAppFilesUpdate
  | ActionApiApplicationsRefreshAppFilesState
  | ActionApiApplicationsUpdateApplicationModel
  | ActionApiApplicationsResetApplicationModel
  | ActionApiApplicationsSubmittingApplicationModel
  | ActionApiApplicationsSuccessfulApplicationModelSubmission
  | ActionApiApplicationsFailedApplicationModelSubmission
  | ActionApiApplicationsResetAppModelSubmissionStatus
  | ActionApiApplicationsUpdateFileShareServiceModel
  | ActionApiApplicationsResetFileShareServiceModel
  | ActionApiApplicationsSubmittingFileShareServiceModel
  | ActionApiApplicationsSuccessfulFileShareServiceModelSubmission
  | ActionApiApplicationsFailedFileShareServiceModelSubmission
  | ActionApiApplicationsResetFileShareServiceSubmissionStatus
  | ActionApiApplicationsUpdateDesktopModel
  | ActionApiApplicationsResetDesktopModel
  | ActionApiApplicationsSubmittingDesktopModel
  | ActionApiApplicationsSuccessfulDesktopModelSubmission
  | ActionApiApplicationsFailedDesktopModelSubmission
  | ActionApiApplicationsResetDesktopModelSubmissionStatus
  | ActionApiApplicationsUpdateSSHModel
  | ActionApiApplicationsResetSSHModel
  | ActionApiApplicationsSubmittingSSHModel
  | ActionApiApplicationsSuccessfulSSHModelSubmission
  | ActionApiApplicationsFailedSSHModelSubmission
  | ActionApiApplicationsResetSSHModelSubmissionStatus
  | ActionApiApplicationsUpdateApiKeyModel
  | ActionApiApplicationsResetApiKeyModel
  | ActionApiApplicationsSubmittingApiKeyModel
  | ActionApiApplicationsSuccessfulApiKeyModelSubmission
  | ActionApiApplicationsFailedApiKeyModelSubmission
  | ActionApiApplicationsResetApiKeyModelSubmissionStatus
  | ActionApiApplicationsUpdateNetworkModel
  | ActionApiApplicationsResetNetworkModel
  | ActionApiApplicationsSubmittingNetworkModel
  | ActionApiApplicationsSuccessfulNetworkModelSubmission
  | ActionApiApplicationsFailedNetworkModelSubmission
  | ActionApiApplicationsResetNetworkModelSubmissionStatus
  | ActionApiApplicationsUpdateLauncherModel
  | ActionApiApplicationsResetLauncherModel
  | ActionApiApplicationsSubmittingLauncherModel
  | ActionApiApplicationsSuccessfulLauncherModelSubmission
  | ActionApiApplicationsFailedLauncherModelSubmission
  | ActionApiApplicationsResetLauncherModelSubmissionStatus
  | ActionApiApplicationsUnsetDefaultRoleFlag
  | ActionApiApplicationsCreateDemo
  | ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation
  | ActionApiApplicationsSuccessfulDemoCreation
  | ActionApiApplicationsFailedDemoCreation
  | ActionApiApplicationsResetDemoStatus
  | ActionApiApplicationsDeleteDemo
  | ActionApiApplicationsSuccessfulDemoDelete
  | ActionApiApplicationsFailedDemoDelete
  | ActionApiApplicationsMaintainState
  | ActionApiApplicationsClearApplicationState;

export type ApiApplicationsEnvConfigWatchActions =
  | ActionApiApplicationsSetState
  | ActionApiApplicationsUpdateCurrentApp
  | ActionApiApplicationsUpdateCurrentEnv
  | ActionApiApplicationsReloadApp;

export type ApiApplicationsAppChangeActions =
  | ActionApiApplicationsSetState
  | ActionApiApplicationsSetCurrentApplicationState
  | ActionApiApplicationsUpdateCurrentApp
  | ActionApiApplicationsReloadApp
  | ActionApiApplicationsRefreshAppFilesState;

export type ApiApplicationsLoadRoleToRuleEntriesActions =
  | ActionApiApplicationsSetState
  | ActionApiApplicationsUpdateCurrentApp
  | ActionApiApplicationsReloadApp
  | ActionApiApplicationsRemoveFromRoleList
  | ActionApiApplicationsRemoveFromRuleList;

export type ApiApplicationsChangeRulesList = ActionApiApplicationsAddToRuleList | ActionApiApplicationsUpdateRuleList;

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, of, Subject, takeUntil } from 'rxjs';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { UserElement } from '../user-admin/user-admin.component';
import { getEndDateMinSetter } from '../date-utils';
import { User } from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { NotificationService } from '@app/core';
import { UsersToGroupsService } from '@app/core/api/users-to-groups/users-to-groups.service';

export interface UserEndDateDialogData {
  userElement: UserElement;
}

@Component({
  selector: 'portal-user-end-date-dialog',
  templateUrl: './user-end-date-dialog.component.html',
  styleUrls: ['./user-end-date-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserEndDateDialogComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public userEndDateFormGroup: UntypedFormGroup;
  public errorMessage = '';
  public saveDisabled = false;
  public minDate: Date = new Date();
  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public getEndDateMinSetter = getEndDateMinSetter;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: UserEndDateDialogData,
    private dialogRef: MatDialogRef<UserEndDateDialogComponent>,
    private formBuilder: FormBuilder,
    private usersToGroupService: UsersToGroupsService,
    private appErrorHandler: AppErrorHandler,
    private notificationService: NotificationService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    const today = new Date();
    this.userEndDateFormGroup.value.dtFrom = new Date(new Date(today).setDate(today.getDate()));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeFormGroup(): void {
    this.userEndDateFormGroup = this.formBuilder.group({
      dtFrom: null,
      dtTo: [this.data.userElement?.disabled_at_time ? this.data.userElement.disabled_at_time : null, [Validators.required]],
    });
  }

  private updateUserEndDate(): void {
    this.data.userElement.disabled_at_time = this.userEndDateFormGroup.get('dtTo').value;
    this.usersToGroupService
      .updateUser$(this.data.userElement)
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.errorMessage = this.appErrorHandler.getErrorMessageForUserDisplay(err, `Failed to update the user's end date.`);
          return of(undefined);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp: User | undefined) => {
        this.saveDisabled = false;
        if (!resp) {
          this.changeDetector.detectChanges();
          return;
        }
        this.errorMessage = '';
        this.dialogRef.close(true);
        this.notificationService.success(`User was successfully updated`);
      });
  }

  public onSaveClick(): void {
    this.saveDisabled = true;
    this.updateUserEndDate();
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }
}

import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InboxItem, MessageAction } from '@agilicus/angular';
import { NotificationService } from '@app/core';
import { DynamicEnvironmentService } from '@app/core/services/dynamic-environment.init';
import { isSurveyMessage, shouldAddDeepLink } from '@app/core/api/inbox-items/inbox-items-utils';

@Component({
  selector: 'portal-inbox-message-dialog',
  templateUrl: './inbox-message-dialog.component.html',
  styleUrls: ['./inbox-message-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxMessageDialogComponent {
  public inboxItem: InboxItem;

  public shouldAddDeepLink = shouldAddDeepLink;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: InboxItem,
    private notificationService: NotificationService,
    private dialogRef: MatDialogRef<InboxMessageDialogComponent>,
    private env: DynamicEnvironmentService
  ) {
    this.inboxItem = data;
  }

  public fetchActionUri(action: MessageAction | undefined): void {
    if (action.uri) {
      const url = action.uri;
      const currentHost = window.location.host;
      const targetHost = new URL(url).host;
      const apiDomain = this.env.environment.overrideApiDomain;

      if (currentHost === targetHost) {
        window.location.href = url;
      } else if (url.includes(apiDomain) || url.includes('api.agilicus.com')) {
        this.fetchAction(action.uri)
          .then((res) => {
            this.notificationService.success('Request has been processed');
          })
          .catch((err) => this.notificationService.error('Failed to fetch the requested uri'));
      } else {
        window.open(url, '_blank');
      }
    }
    this.dialogRef.close();
  }

  public openMessageUri(event: MouseEvent): void {
    event.stopPropagation();
    const url = (<HTMLAnchorElement>event.target).href;
    const currentHost = window.location.host;
    const targetHost = new URL(url).host;
    if (currentHost === targetHost) {
      window.location.href = url;
    } else {
      window.open(url, '_blank');
    }
    event.preventDefault(); // prevent the default anchor click behavior
    this.dialogRef.close();
  }

  private fetchAction(url: string): Promise<any> {
    return fetch(url).then((response) => response.json());
  }

  public onCloseClick(): void {
    this.dialogRef.close();
  }

  public showCustomActionButtons(inboxItem: InboxItem): boolean {
    return inboxItem?.status?.message?.actions && inboxItem.status.message.actions.length > 0;
  }

  public getMessageUriText(inboxItem: InboxItem): string {
    if (isSurveyMessage(inboxItem)) {
      return 'Go to survey';
    }
    return 'Go to event';
  }

  public getIconUrlStyle(): string {
    if (!this.inboxItem?.status?.message?.icon) {
      return '';
    }
    return `background-image: url(${this.inboxItem.status.message.icon});`;
  }

  public getFormattedButtonText(buttonText: string): string {
    return buttonText.toUpperCase();
  }

  public getCloseButtonColor(): string {
    if (this.showCustomActionButtons(this.inboxItem)) {
      return 'none';
    }
    // If only 1 button, make it primary color
    return 'primary';
  }
}

import {
  CreateLabelledObjectLabelRequestParams,
  DeleteLabelledObjectLabelRequestParams,
  GetLabelledObjectRequestParams,
  Label,
  LabelAssociation,
  LabelledObject,
  LabelsService,
  ListLabelledObjectsRequestParams,
  ListObjectLabelsRequestParams,
  patch_via_put,
  Resource,
} from '@agilicus/angular';
import { ResourceAndLabelsResponse, ResourceOverviewElement } from '@app/shared/components/resource-overview/resource-overview.component';
import { ResourceWithLabels } from '@app/shared/components/resource-with-labels';
import { catchError, concatMap, forkJoin, map, Observable, of, throwError } from 'rxjs';

export function createNewLabelledObject$(
  labelsService: LabelsService,
  newLabelledObject: LabelledObject
): Observable<LabelledObject | undefined> {
  return labelsService
    .createLabelledObject({
      LabelledObject: newLabelledObject,
    })
    .pipe(
      catchError((err) => {
        if (err.status === 409) {
          return of(err.error);
        }
        return throwError(() => err);
      })
    );
}

export function updateExistingLabelledObject$(
  labelsService: LabelsService,
  labelledObjectToUpdate: LabelledObject
): Observable<LabelledObject | undefined> {
  const putter = (labelledObject: LabelledObject) => {
    return labelsService.replaceLabelledObject({
      labelled_object_id: labelledObject.object_id,
      LabelledObject: labelledObject,
    });
  };
  const getter = (labelledObject: LabelledObject) => {
    return labelsService.getLabelledObject({
      labelled_object_id: labelledObject.object_id,
      org_id: labelledObject.org_id,
    });
  };
  return patch_via_put(labelledObjectToUpdate, getter, putter);
}

export function getLabelledObjectById$(
  labelsService: LabelsService,
  id: string,
  orgId: string | undefined
): Observable<LabelledObject | undefined> {
  const getLabelledObjectRequestParams: GetLabelledObjectRequestParams = {
    labelled_object_id: id,
    org_id: orgId,
  };
  return labelsService.getLabelledObject(getLabelledObjectRequestParams);
}

export function getLabelledObjectsList$(
  labelsService: LabelsService,
  orgId: string | undefined,
  includedTypes: Array<string> = []
): Observable<Array<LabelledObject>> {
  const listLabelledObjectsRequestParams: ListLabelledObjectsRequestParams = {
    org_id: orgId,
  };
  if (includedTypes.length !== 0) {
    listLabelledObjectsRequestParams.object_types = includedTypes;
  }
  return labelsService.listLabelledObjects(listLabelledObjectsRequestParams).pipe(
    map((resp) => {
      return resp.labelled_objects;
    })
  );
}

export function deleteExistingLabelledObject$(labelsService: LabelsService, id: string, orgId: string): Observable<any> {
  return labelsService.deleteLabelledObject({
    labelled_object_id: id,
    org_id: orgId,
  });
}

export function getObjectLabelsList$(labelsService: LabelsService, orgId: string | undefined): Observable<Array<Label>> {
  const listObjectLabelsRequestParams: ListObjectLabelsRequestParams = {
    org_id: orgId,
  };
  return labelsService.listObjectLabels(listObjectLabelsRequestParams).pipe(
    map((resp) => {
      return resp.labels;
    })
  );
}

export function addLabelAssociationToObject$(
  labelsService: LabelsService,
  objectId: string,
  orgId: string,
  labelName: string
): Observable<LabelAssociation> {
  const createLabelledObjectLabelRequestParams: CreateLabelledObjectLabelRequestParams = {
    labelled_object_id: objectId,
    LabelAssociation: {
      label_name: labelName,
      org_id: orgId,
    },
  };
  return labelsService.createLabelledObjectLabel(createLabelledObjectLabelRequestParams);
}

export function removeLabelAssociationFromObject$(
  labelsService: LabelsService,
  objectId: string,
  orgId: string,
  labelName: string
): Observable<any> {
  const deleteLabelledObjectLabelRequestParams: DeleteLabelledObjectLabelRequestParams = {
    labelled_object_id: objectId,
    label_name: labelName,
    org_id: orgId,
  };
  return labelsService.deleteLabelledObjectLabel(deleteLabelledObjectLabelRequestParams);
}

export function getLabelledObjectFromResourceOverviewElement(resource: Resource): LabelledObject {
  return {
    object_id: resource.metadata.id,
    object_type: resource.spec.resource_type,
    // Labels must be empty when creating a new LabelledObject
    labels: [],
    org_id: resource.spec.org_id,
  };
}

export function getLabelsToCreate(updatedElement: ResourceWithLabels): Array<LabelAssociation> {
  const labelsToCreate: Array<LabelAssociation> = [];
  for (const label of updatedElement.labels) {
    const previousLabel = updatedElement.previousLabels.find((previousLabel) => previousLabel.label_name === label.label_name);
    if (!previousLabel) {
      labelsToCreate.push(label);
    }
  }
  return labelsToCreate;
}

export function getLabelsToDelete(updatedElement: ResourceWithLabels): Array<LabelAssociation> {
  const labelsToDelete: Array<LabelAssociation> = [];
  for (const previousLabel of updatedElement.previousLabels) {
    const currentLabel = updatedElement.labels.find((label) => label.label_name === previousLabel.label_name);
    if (!currentLabel) {
      labelsToDelete.push(previousLabel);
    }
  }
  return labelsToDelete;
}

export function createLabelsList$(
  labelsService: LabelsService,
  itemsToCreate: Array<LabelAssociation>,
  updatedElement: ResourceWithLabels,
  orgId: string
): Array<Observable<LabelAssociation>> {
  const observablesArray: Array<Observable<LabelAssociation>> = [];
  for (const item of itemsToCreate) {
    observablesArray.push(addLabelAssociationToObject$(labelsService, updatedElement.backingObject.metadata.id, orgId, item.label_name));
  }
  return observablesArray;
}

export function deleteLabelsList$(
  labelsService: LabelsService,
  itemsToDelete: Array<LabelAssociation>,
  updatedElement: ResourceWithLabels,
  orgId: string
): Array<Observable<null>> {
  const observablesArray: Array<Observable<null>> = [];
  for (const item of itemsToDelete) {
    observablesArray.push(
      removeLabelAssociationFromObject$(labelsService, updatedElement.backingObject.metadata.id, orgId, item.label_name)
    );
  }
  return observablesArray;
}

export function updateObjectLabelsNavigationIfNotAllowed$(
  createdLabelAssociations$: Array<Observable<LabelAssociation>>,
  labelsService: LabelsService,
  orgId: string,
  allowNavigation: boolean
): Array<Observable<LabelAssociation>> {
  let createdAndUpdatedLabels$: Observable<LabelAssociation>[] = [];
  if (allowNavigation) {
    createdAndUpdatedLabels$ = [...createdLabelAssociations$];
  } else {
    for (const createdLabelAssociation$ of createdLabelAssociations$) {
      createdAndUpdatedLabels$.push(
        createdLabelAssociation$.pipe(
          concatMap((labelAssociationResp) => {
            if (!labelAssociationResp.status?.navigation?.enabled) {
              return of(labelAssociationResp);
            }

            return labelsService
              .listObjectLabels({
                label_name: labelAssociationResp.label_name,
                org_id: orgId,
              })
              .pipe(
                map((labelsListResp) => {
                  return labelsListResp.labels[0];
                }),
                concatMap((objectLabelResp) => {
                  const updatedObjectLabel = {
                    ...objectLabelResp,
                    spec: {
                      ...objectLabelResp.spec,
                      navigation: {
                        enabled: false,
                      },
                    },
                  };
                  return updateExistingObjectLabel$(labelsService, updatedObjectLabel);
                }),
                map((updateObjectLabelResp) => {
                  return labelAssociationResp;
                })
              );
          })
        )
      );
    }
  }
  return createdAndUpdatedLabels$;
}

export function getUpdatedLabels$(
  labelsService: LabelsService,
  updatedElement: ResourceWithLabels,
  updatedResource: Resource | undefined,
  updatedLabelledObject: LabelledObject | undefined,
  orgId: string,
  allowNavigation: boolean
): Observable<ResourceAndLabelsResponse> {
  const labelsToCreate = getLabelsToCreate(updatedElement);
  const labelsToDelete = getLabelsToDelete(updatedElement);
  if (labelsToCreate.length === 0 && labelsToDelete.length === 0) {
    return of({
      resource: updatedResource,
      labelledObject: updatedLabelledObject,
      labels: [],
    });
  }
  const createdLabelAssociations$ = createLabelsList$(labelsService, labelsToCreate, updatedElement, orgId);
  const createdAndUpdatedLabels$ = updateObjectLabelsNavigationIfNotAllowed$(
    createdLabelAssociations$,
    labelsService,
    orgId,
    allowNavigation
  );
  const deletedLabels$ = deleteLabelsList$(labelsService, labelsToDelete, updatedElement, orgId);
  return forkJoin([...createdAndUpdatedLabels$, ...deletedLabels$]).pipe(
    map((resp) => {
      return {
        resource: updatedResource,
        labelledObject: updatedLabelledObject,
        labels: resp,
      };
    })
  );
}

export function createNewLabelledObjectWithLabels$(
  labelsService: LabelsService,
  updatedElement: ResourceWithLabels,
  updatedResource: Resource | undefined,
  orgId: string,
  allowNavigation: boolean
): Observable<ResourceAndLabelsResponse> {
  return createNewLabelledObject$(labelsService, getLabelledObjectFromResourceOverviewElement(updatedElement.backingObject)).pipe(
    concatMap((newLabelledObjectResp) => {
      return getUpdatedLabels$(labelsService, updatedElement, updatedResource, newLabelledObjectResp, orgId, allowNavigation).pipe(
        map((labelsResp) => {
          newLabelledObjectResp.labels = updatedElement.labels.filter((label) => !!label);
          return {
            resource: updatedResource,
            labelledObject: newLabelledObjectResp,
            labels: labelsResp.labels.filter((label) => !!label),
          };
        })
      );
    })
  );
}

export function prepareDeleteLabelledObjectsObservablesArray$(
  labelsService: LabelsService,
  itemsToDelete: Array<ResourceOverviewElement>,
  orgId: string
): Array<Observable<any>> {
  const observablesArray: Array<Observable<any>> = [];
  for (const element of itemsToDelete) {
    if (element.isChecked && !!element.backingLabelledObject) {
      observablesArray.push(deleteExistingLabelledObject$(labelsService, element.backingObject.metadata.id, orgId));
    }
  }
  return observablesArray;
}

export function createNewObjectLabel$(labelsService: LabelsService, objectLabelToCreate: Label): Observable<Label> {
  return labelsService.createObjectLabel({
    Label: objectLabelToCreate,
  });
}

export function updateExistingObjectLabel$(labelsService: LabelsService, objectLabelToUpdate: Label): Observable<Label | undefined> {
  const putter = (objectLabel: Label) => {
    return labelsService.replaceObjectLabel({
      label_id: objectLabel.metadata.id,
      Label: objectLabel,
    });
  };
  const getter = (objectLabel: Label) => {
    return labelsService.getObjectLabel({
      label_id: objectLabel.metadata.id,
      org_id: objectLabel.spec.org_id,
    });
  };
  return patch_via_put(objectLabelToUpdate, getter, putter);
}

export function deleteExistingLabel$(labelsService: LabelsService, label: Label): Observable<any> {
  return labelsService.deleteObjectLabel({
    label_id: label.metadata.id,
    org_id: label.spec.org_id,
  });
}

export function deleteExistingLabelsList$(labelsService: LabelsService, labels: Array<Label>): Array<Observable<any>> {
  const observablesArray: Array<Observable<any>> = [];
  for (const label of labels) {
    observablesArray.push(deleteExistingLabel$(labelsService, label));
  }
  return observablesArray;
}

<portal-page-info
  pageTitle="API Keys"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasAllPermissions" 
  class="desktop-overview-container"
>
  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [rowObjectName]="rowObjectName"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
    (updateDatePickerInput)=updateDatePickerInput($event)
  >
  </portal-table-layout>
</div>

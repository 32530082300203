import { ResourceModel } from '../resource/resource-model';

export interface APIKeyModel extends ResourceModel {
  name: string;
  serviceAccount?: string;
  apiAccess: boolean;
  scopes: Array<string>;
  expiryDate: Date;
}

export function defaultAPIKeyModel(): APIKeyModel {
  return {
    id: undefined,
    name: '',
    serviceAccount: '',
    apiAccess: false,
    scopes: [],
    expiryDate: undefined,
  };
}

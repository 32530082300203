<mat-dialog-content>
  <div
    class="tab-component-outer-container selectable"
    portalSwipeable [appTabState]="uiState?.tabsState" [tabGroupId]="tabGroupId" [tabsLength]="tabsLength"
  >
    <div class="connector-download-tab-group-container row content" *ngIf="(downloadInfo$ | async) as downloadInfo else loading">
      <mat-tab-group
        #tabGroup
        mat-stretch-tabs
        animationDuration="0ms"
        class="tab-group-overrides fixed-tabs-top"
        [selectedIndex]="localTabIndex"
        portalTabState [appTabState]="uiState?.tabsState" [tabGroupId]="tabGroupId"
        (selectedTabChange)="onTabClick($event)"
      >
        <!-- Linux Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            Linux
          </ng-template>
          <ng-template matTabContent>
            <portal-connector-download-instructions
              [operatingSystem]="operatingSystemEnum.linux"
              [installCmd]="getInstallCommandWithNestedConnectorInfo(downloadInfo.singleLinuxInstallCmd, commandTypeEnum.linux)"
              [rootCertCmd]="getLinuxInstallRootCertString()"
              [hasShares]="hasShares"
              [failed]="downloadInfo.failed"
              [joinClusterFormGroup]="joinClusterFormGroup"
              [timedOut]="!!downloadInfo.timedOut"
            >
            </portal-connector-download-instructions>
          </ng-template>
        </mat-tab>

        <!-- Windows Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            Windows(CMD)
          </ng-template>
          <ng-template matTabContent>
            <portal-connector-download-instructions
              [operatingSystem]="operatingSystemEnum.windows"
              [installCmd]="getInstallCommandWithNestedConnectorInfo(downloadInfo.singleWindowsInstallCmd, commandTypeEnum.cmd)"
              [rootCertCmd]="getWindowsCmdInstallRootCertString()"
              [hasShares]="hasShares"
              [failed]="downloadInfo.failed"
              [joinClusterFormGroup]="joinClusterFormGroup"
              [timedOut]="!!downloadInfo.timedOut"
            >
            </portal-connector-download-instructions>
          </ng-template>
        </mat-tab>

        <!-- PowerShell Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            Windows(PowerShell)
          </ng-template>
          <ng-template matTabContent>
            <portal-connector-download-instructions
              [operatingSystem]="operatingSystemEnum.windows"
              [installCmd]="getInstallCommandWithNestedConnectorInfo(downloadInfo.singleWindowsPSInstallCmd, commandTypeEnum.power_shell)"
              [rootCertCmd]="getWindowsPowerShellInstallRootCertString()"
              [hasShares]="hasShares"
              [failed]="downloadInfo.failed"
              [joinClusterFormGroup]="joinClusterFormGroup"
              [timedOut]="!!downloadInfo.timedOut"
            >
            </portal-connector-download-instructions>
          </ng-template>
        </mat-tab>

        <!-- Container Tab -->
        <mat-tab>
          <ng-template mat-tab-label>
            Docker
          </ng-template>
          <ng-template matTabContent>
            <portal-connector-download-instructions
              [operatingSystem]="operatingSystemEnum.container"
              [installCmd]="downloadInfo.singleContainerInstallCmd"
              [hasShares]="hasShares"
              [failed]="downloadInfo.failed"
              [joinClusterFormGroup]="joinClusterFormGroup"
              [timedOut]="!!downloadInfo.timedOut"
            >
            </portal-connector-download-instructions>
          </ng-template>
        </mat-tab>

        <!-- Manual -->
        <mat-tab>
          <ng-template mat-tab-label>
            Manual
          </ng-template>
          <ng-template matTabContent>
            <portal-connector-download-instructions
              [operatingSystem]="operatingSystemEnum.manual"
              [installCmd]="getInstallCommandWithNestedConnectorInfo(downloadInfo.singleManualInstallCmd, commandTypeEnum.manual)"
              [hasShares]="hasShares"
              [failed]="downloadInfo.failed"
              [joinClusterFormGroup]="joinClusterFormGroup"
              [timedOut]="!!downloadInfo.timedOut"
            >
            </portal-connector-download-instructions>
          </ng-template>
        </mat-tab>

      </mat-tab-group>
    </div>
    <ng-template #loading>
      <div>
        <mat-tab-group
          #tabGroup
          mat-stretch-tabs
          animationDuration="0ms"
          class="tab-group-overrides fixed-tabs-top"
          [selectedIndex]="localTabIndex"
          portalTabState [appTabState]="uiState?.tabsState" [tabGroupId]="tabGroupId"
        >
          <!-- Linux Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Linux
            </ng-template>
            <ng-template matTabContent>
              <portal-connector-download-instructions
                [operatingSystem]="operatingSystemEnum.linux"
                [installCmd]="getInstallCommandWithNestedConnectorInfo(defaultDownloadInfo.singleLinuxInstallCmd, commandTypeEnum.linux)"
                [rootCertCmd]="getLinuxInstallRootCertString()"
                [hasShares]="hasShares"
                [joinClusterFormGroup]="joinClusterFormGroup"
              >
              </portal-connector-download-instructions>
            </ng-template>
          </mat-tab>
          
          <!-- Windows Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Windows(CMD)
            </ng-template>
            <ng-template matTabContent>
              <portal-connector-download-instructions
                [operatingSystem]="operatingSystemEnum.windows"
                [installCmd]="getInstallCommandWithNestedConnectorInfo(defaultDownloadInfo.singleWindowsInstallCmd, commandTypeEnum.cmd)"
                [rootCertCmd]="getWindowsCmdInstallRootCertString()"
                [hasShares]="hasShares"
                [joinClusterFormGroup]="joinClusterFormGroup"
              >
              </portal-connector-download-instructions>
            </ng-template>
          </mat-tab>

          <!-- PowerShell Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Windows(PowerShell)
            </ng-template>
            <ng-template matTabContent>
              <portal-connector-download-instructions
                [operatingSystem]="operatingSystemEnum.windows"
                [installCmd]="getInstallCommandWithNestedConnectorInfo(defaultDownloadInfo.singleWindowsPSInstallCmd, commandTypeEnum.power_shell)"
                [rootCertCmd]="getWindowsPowerShellInstallRootCertString()"
                [hasShares]="hasShares"
                [joinClusterFormGroup]="joinClusterFormGroup"
              >
              </portal-connector-download-instructions>
            </ng-template>
          </mat-tab>

          <!-- Container Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              Docker
            </ng-template>
            <ng-template matTabContent>
              <portal-connector-download-instructions
                [operatingSystem]="operatingSystemEnum.container"
                [installCmd]="defaultDownloadInfo.singleContainerInstallCmd"
                [hasShares]="hasShares"
                [joinClusterFormGroup]="joinClusterFormGroup"
              >
              </portal-connector-download-instructions>
            </ng-template>
          </mat-tab>

          <!-- Manual -->
          <mat-tab>
            <ng-template mat-tab-label>
              Manual
            </ng-template>
            <ng-template matTabContent>
              <portal-connector-download-instructions
                [operatingSystem]="operatingSystemEnum.manual"
                [installCmd]="getInstallCommandWithNestedConnectorInfo(defaultDownloadInfo.singleManualInstallCmd, commandTypeEnum.manual)"
                [hasShares]="hasShares"
                [joinClusterFormGroup]="joinClusterFormGroup"
              >
              </portal-connector-download-instructions>
            </ng-template>
          </mat-tab>

        </mat-tab-group>
      </div>
    </ng-template>

    <div class="code-timer-container">
      <p *ngIf="timeRemaining$ === undefined" class="code-timer"><b>Command expires in: </b> <span class="code-timer-clock">--:--</span></p>
      <p *ngIf="timeRemaining$ !== undefined && challengeTimeout !== 0" class="code-timer"><b>Command expires in: <span class="code-timer-clock">{{ timeRemaining$ | async | date:'mm:ss' }}</span></b></p>
      <p *ngIf="timeRemaining$ !== undefined && challengeTimeout === 0" class="code-timer"><b>Command expires in: <span class="code-timer-clock">00:00</span></b></p>
      <mat-icon
        class="help-icon"
        matTooltip="The installation command will expire when the timer reaches 00:00">
        help_outline
      </mat-icon>
      <button
        class="regenerate-code-button"
        mat-raised-button
        color="primary"
        matTooltip="The installation command has expired. Click here to generate a new installation command."
        (click)="onRegenerateCodeClick()"
        [disabled]="disableRegenerateButton"
      >
        REGENERATE
      </button>
    </div>

    <portal-demo-setup
      overrideShowDemoConnectorCheck="true"
      [hideCreateButton] = "!getShowDemoCreateButton()"
      hideDeleteButton="true"
      [tourAnchorId]="tourAnchorId"
      (startTourInParent)="startDemoTour()"
    >
    </portal-demo-setup>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <div class="download-button-container">
    <button
      class="download-button"
      matSuffix
      mat-raised-button
      color="primary"
      matTooltip="Download the Agilicus Connector for manual install"
      (click)="onManualDownloadClick()"
    >
      Alternative: Manual Download/Install
    </button>
  </div>

  <button mat-raised-button mat-dialog-close tabindex="-1">Close</button>
</mat-dialog-actions>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { ConnectorSelectionComponent } from './components/connector-selection/connector-selection.component';

@NgModule({
  imports: [CommonModule, MatOptionModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule],
  declarations: [ConnectorSelectionComponent],
  exports: [ConnectorSelectionComponent],
})
export class ConnectorBasedStepperModule {}

<portal-page-info
  pageTitle="Common Tasks"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="task-cards-container">
  <div class="cards flex-row-wrap flex-layout-align-center">
    <mat-card
      appearance="outlined"
      class="flex-xs-80 flex-sm-60 flex-md-33"
      *ngFor="let data of dataSource"
    >
      <mat-card-header class="card-header-container">
        <mat-card-title class="title-container" (click)="onCardHeaderClick(data)">
          <div class="icon-container">
            <mat-icon>{{ data.icon }}</mat-icon>
          </div>
          <div class="title-text-container">
            {{data.title}}
            <mat-icon class="profile-open-icon">link</mat-icon>
          </div>
        </mat-card-title>
        <span class="fill-space hide-on-large-screens"></span>
        <div class="help-container hide-on-large-screens">
          <portal-product-guide-link
            [productGuideLink]="data.productLink"
            productGuideLinkText=""
            icon="help-icon"
          >
          </portal-product-guide-link>
        </div>
      </mat-card-header>
      <mat-card-content class="hide-on-small-screens">
        <ul class="objectives-list">
          <li *ngFor="let item of data.objectives">{{ item }}</li>
        </ul>
      </mat-card-content>
      <mat-card-subtitle class="hide-on-small-screens">
        <portal-product-guide-link
          [productGuideLink]="data.productLink"
          productGuideLinkText="(product-guide)"
        >
        </portal-product-guide-link>
      </mat-card-subtitle>
    </mat-card>
  </div>
</div>

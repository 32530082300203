// TODO: move file and move functions

import { forkJoin, Observable, of, throwError } from 'rxjs';
import {
  AgentConnector,
  AgentConnectorInstance,
  AgentConnectorProxy,
  Connector,
  ConnectorInstance,
  ConnectorService,
  ConnectorSpec,
  ConnectorsService,
  DeleteConnectorRequestParams,
  GetAgentConnectorRequestParams,
  GetConnectorRequestParams,
  ListAgentConnectorRequestParams,
  ListConnectorRequestParams,
  ListInstancesRequestParams,
  ListProxiesRequestParams,
  Organisation,
  PatchErrorImpl,
  patch_via_put,
} from '@agilicus/angular';
import { catchError, concatMap, map } from 'rxjs/operators';
import { NotificationService } from '@app/core';
import { getMoreThanOneResultError } from '../api-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { getIgnoreErrorsHeader } from '@app/core/http-interceptors/http-interceptor-utils';

export interface ConnectorWithOuterProxyResp {
  connector: Connector | undefined;
  connectorIsInnerProxy: AgentConnectorProxy | undefined;
}

export interface ConnectorWithInnerAndOuterProxyResp extends ConnectorWithOuterProxyResp {
  connectorIsOuterProxyList: Array<AgentConnectorProxy>;
}

export interface InnerAndOuterConnectorProxiesResp {
  connectorIsInnerProxy: AgentConnectorProxy | undefined;
  connectorIsOuterProxyList: Array<AgentConnectorProxy>;
}

export function getAllConnectorsWithStatsList(
  connectorsService: ConnectorsService,
  orgId: string | undefined
): Observable<Array<Connector>> {
  const listConnectorRequestParams: ListConnectorRequestParams = {
    org_id: orgId,
    show_stats: true,
  };
  return connectorsService.listConnector(listConnectorRequestParams).pipe(
    map((resp) => {
      return resp.connectors;
    })
  );
}

export function getConnectorById(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string | undefined
): Observable<Connector | undefined> {
  const getConnectorRequestParams: GetConnectorRequestParams = {
    connector_id: connectorId,
    org_id: orgId,
  };
  return connectorsService.getConnector(getConnectorRequestParams);
}

export function deleteExistingConnector(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string | undefined
): Observable<any> {
  const deleteConnectorRequestParams: DeleteConnectorRequestParams = {
    connector_id: connectorId,
    org_id: orgId,
  };
  return connectorsService.deleteConnector(deleteConnectorRequestParams);
}

export function getConnectors(
  connectorsService: ConnectorsService,
  orgId: string | undefined,
  type?: ConnectorSpec.ConnectorTypeEnum
): Observable<Array<Connector>> {
  let connectors$: Observable<Array<Connector>> = of([]);
  if (!!orgId) {
    const listConnectorRequestParams: ListConnectorRequestParams = {
      org_id: orgId,
    };
    if (!!type) {
      listConnectorRequestParams.type = type;
    }
    connectors$ = connectorsService.listConnector(listConnectorRequestParams).pipe(
      map((resp) => {
        return resp.connectors;
      }),
      catchError((_) => {
        return of([]);
      })
    );
  }
  return connectors$;
}

export function getConnectorByName$(
  connectorsService: ConnectorsService,
  name: string,
  orgId: string | undefined,
  type?: ConnectorSpec.ConnectorTypeEnum
): Observable<Connector> {
  const listConnectorRequestParams: ListConnectorRequestParams = {
    org_id: orgId,
    name,
  };
  if (!!type) {
    listConnectorRequestParams.type = type;
  }
  return connectorsService.listConnector(listConnectorRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.connectors);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.connectors.length === 0 ? undefined : resp.connectors[0]);
    })
  );
}

export function getAgentConnectorConnectionUri(organisation: Organisation): string {
  return `wss://${organisation.subdomain}/.agilicus-agent/control`;
}

export function getAgentConnectorToCreate(connectorName: string, organisation: Organisation): AgentConnector {
  return {
    spec: {
      name: connectorName,
      org_id: organisation.id,
      connection_uri: getAgentConnectorConnectionUri(organisation),
      service_account_required: true,
    },
  };
}

export function getAgentConnectorById(
  connectorsService: ConnectorsService,
  id: string,
  orgId: string | undefined
): Observable<AgentConnector> {
  const getAgentConnectorRequestParams: GetAgentConnectorRequestParams = {
    connector_id: id,
    org_id: orgId,
  };
  return connectorsService.getAgentConnector(getAgentConnectorRequestParams);
}

export function getAgentConnectorByName$(
  connectorsService: ConnectorsService,
  name: string,
  orgId: string
): Observable<AgentConnector | undefined> {
  const listAgentConnectorRequestParams: ListAgentConnectorRequestParams = {
    org_id: orgId,
    name: name,
  };
  return connectorsService.listAgentConnector(listAgentConnectorRequestParams).pipe(
    concatMap((resp) => {
      const moreThanOneResultError = getMoreThanOneResultError(resp.agent_connectors);
      if (!!moreThanOneResultError) {
        return throwError(() => moreThanOneResultError);
      }
      return of(resp.agent_connectors.length === 0 ? undefined : resp.agent_connectors[0]);
    })
  );
}

export function getAgentConnectorsList(connectorsService: ConnectorsService, orgId: string | undefined): Observable<Array<AgentConnector>> {
  const listAgentConnectorRequestParams: ListAgentConnectorRequestParams = {
    org_id: orgId,
  };
  return connectorsService.listAgentConnector(listAgentConnectorRequestParams).pipe(
    map((resp) => {
      return resp.agent_connectors;
    })
  );
}

export function createAgentConnector(connectorsService: ConnectorsService, agentConnector: AgentConnector): Observable<AgentConnector> {
  return connectorsService.createAgentConnector({
    AgentConnector: agentConnector,
  });
}

export function updateExistingAgentConnector(
  connectorsService: ConnectorsService,
  agentToUpdate: AgentConnector
): Observable<AgentConnector> {
  const putter = (agent: AgentConnector) => {
    const toPut: AgentConnector = {
      metadata: agent.metadata,
      spec: agent.spec,
    };

    return connectorsService.replaceAgentConnector({
      connector_id: toPut.metadata.id,
      AgentConnector: toPut,
    });
  };
  const getter = (agent: AgentConnector) => {
    return connectorsService.getAgentConnector({
      connector_id: agent.metadata.id,
      org_id: agent.spec.org_id,
    });
  };
  // strip off the status which we cannot allow to interfere
  // with the diff
  return patch_via_put(agentToUpdate, getter, putter);
}

export function deleteExistingAgentConnector(connectorsService: ConnectorsService, id: string, orgId: string): Observable<void> {
  return connectorsService.deleteAgentConnector({
    connector_id: id,
    org_id: orgId,
  });
}

export function getAgentConnector(connectorsService: ConnectorsService, connector: Connector): Observable<AgentConnector> {
  const getAgentConnectorRequestParams: GetAgentConnectorRequestParams = {
    connector_id: connector.metadata.id,
    org_id: connector.spec.org_id,
  };
  return connectorsService.getAgentConnector(getAgentConnectorRequestParams);
}

export function getAgentConnectorInstances(
  connectorsService: ConnectorsService,
  agentConnector: AgentConnector
): Observable<Array<AgentConnectorInstance>> {
  const listInstancesRequestParams: ListInstancesRequestParams = {
    connector_id: agentConnector.metadata.id,
    org_id: agentConnector.spec.org_id,
  };
  return connectorsService.listInstances(listInstancesRequestParams).pipe(map((resp) => resp.agent_connector_instances));
}

export function getAndEnableAgentConnectorLocalAuthIfNotEnabled(
  connectorsService: ConnectorsService,
  connector: Connector
): Observable<AgentConnector | undefined> {
  return getAgentConnector(connectorsService, connector).pipe(
    concatMap((agentConnectorResp) => {
      if (!!agentConnectorResp.spec.local_authentication_enabled) {
        return of(undefined);
      }
      agentConnectorResp.spec.local_authentication_enabled = true;
      return updateExistingAgentConnector(connectorsService, agentConnectorResp);
    })
  );
}

export function enableConnectorLocalAuthIfNotEnabled(
  connectorsService: ConnectorsService,
  notificationService: NotificationService,
  connector: Connector
): void {
  const getAndUpdateAgentConnectorLocalAuth$ = getAndEnableAgentConnectorLocalAuthIfNotEnabled(connectorsService, connector);
  getAndUpdateAgentConnectorLocalAuth$.subscribe(
    (updatedAgentConnector) => {
      if (!updatedAgentConnector) {
        return;
      }
      notificationService.success(`Connector ${updatedAgentConnector.spec.name} was successfully updated.`);
    },
    (err) => {
      notificationService.error(`Connector ${connector.spec.name} failed to update.`);
    }
  );
}

export function deleteExistingAgentConnectorByName$(
  connectorsService: ConnectorsService,
  name: string,
  orgId: string
): Observable<AgentConnector> {
  return getAgentConnectorByName$(connectorsService, name, orgId).pipe(
    concatMap((getResp) => {
      if (!getResp) {
        return of(undefined);
      }
      return deleteExistingAgentConnector(connectorsService, getResp.metadata.id, orgId).pipe(map((deleteResp) => getResp));
    })
  );
}

export function deleteAgentConnectorInstance$(
  connectorsService: ConnectorsService,
  connectorId: string,
  instanceId: string,
  orgId: string
): Observable<any> {
  return connectorsService.deleteInstance({
    connector_id: connectorId,
    connector_instance_id: instanceId,
    org_id: orgId,
  });
}

export function deleteAllInstances$(
  connectorsService: ConnectorsService,
  instancesToDelete: Array<ConnectorInstance>,
  parentConnectorId: string,
  orgId: string
): Observable<Array<any> | undefined> {
  const observablesArray$: Array<Observable<any>> = [];
  for (const instance of instancesToDelete) {
    observablesArray$.push(deleteAgentConnectorInstance$(connectorsService, parentConnectorId, instance.metadata.id, orgId));
  }
  if (observablesArray$.length === 0) {
    return of(undefined);
  }
  return forkJoin(observablesArray$);
}

/**
 * Gets all proxies where the target connector is the inner connector
 */
export function getOuterProxiesForInnerConnector$(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string
): Observable<AgentConnectorProxy | undefined> {
  const listProxiesRequestParams: ListProxiesRequestParams = {
    org_id: orgId,
    inner_connector_id: connectorId,
  };
  return connectorsService.listProxies(listProxiesRequestParams, 'body', getIgnoreErrorsHeader()).pipe(
    map((connectorProxyResp) => {
      return !!connectorProxyResp.connector_proxies && connectorProxyResp.connector_proxies.length !== 0
        ? connectorProxyResp.connector_proxies[0]
        : undefined;
    }),
    catchError((err: HttpErrorResponse | Error | PatchErrorImpl) => {
      if (err instanceof HttpErrorResponse && err.status === 404 && err.error.error_code === 'NOT_FOUND') {
        return of(undefined);
      }
      return throwError(err);
    })
  );
}

/**
 * Gets all proxies where the target connector is the outer connector
 */
export function getInnerProxiesForOuterConnector$(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string
): Observable<Array<AgentConnectorProxy>> {
  const listProxiesRequestParams: ListProxiesRequestParams = {
    org_id: orgId,
    outer_connector_id: connectorId,
  };
  return connectorsService.listProxies(listProxiesRequestParams, 'body', getIgnoreErrorsHeader()).pipe(
    map((connectorProxyResp) => {
      return connectorProxyResp.connector_proxies;
    }),
    catchError((err: HttpErrorResponse | Error | PatchErrorImpl) => {
      if (err instanceof HttpErrorResponse && err.status === 404 && err.error.error_code === 'NOT_FOUND') {
        return of([]);
      }
      return throwError(err);
    })
  );
}

/**
 * Gets all proxies where the target connector is either the inner or outer connector
 */
export function getProxiesWhereConnectorIsEitherInnerOrOuterConnector$(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string
): Observable<InnerAndOuterConnectorProxiesResp> {
  return getOuterProxiesForInnerConnector$(connectorsService, connectorId, orgId).pipe(
    concatMap((connectorIsInnerProxyResp) => {
      return getInnerProxiesForOuterConnector$(connectorsService, connectorId, orgId).pipe(
        map((connectorIsOuterProxyResp) => {
          const resp: InnerAndOuterConnectorProxiesResp = {
            connectorIsInnerProxy: connectorIsInnerProxyResp,
            connectorIsOuterProxyList: connectorIsOuterProxyResp,
          };
          return resp;
        })
      );
    })
  );
}

export function createNewAgentConnectorProxy$(
  connectorsService: ConnectorsService,
  agentConnectorProxy: AgentConnectorProxy
): Observable<AgentConnectorProxy> {
  return connectorsService.createConnectorProxy({
    AgentConnectorProxy: agentConnectorProxy,
  });
}

export function updateExistingAgentConnectorProxy$(
  connectorsService: ConnectorsService,
  agentConnectorProxy: AgentConnectorProxy
): Observable<AgentConnectorProxy> {
  return connectorsService.replaceProxy({
    connector_proxy_id: agentConnectorProxy.metadata.id,
    AgentConnectorProxy: agentConnectorProxy,
  });
}

export function deleteExistingAgentConnectorProxy$(
  connectorsService: ConnectorsService,
  agentConnectorProxyId: string,
  orgId: string
): Observable<any> {
  return connectorsService.deleteProxy({
    connector_proxy_id: agentConnectorProxyId,
    org_id: orgId,
  });
}

export function getDefaultConnectorProxyPort(): number {
  return 18443;
}

export function getDefaultConnectorProxyHostname(): string {
  return '0.0.0.0';
}

export function getAuditConnectorService$(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string
): Observable<ConnectorService> {
  return connectorsService.getService({
    org_id: orgId,
    connector_id: connectorId,
    connector_service: 'audits',
  });
}

export function createAuditConnectorService$(
  connectorsService: ConnectorsService,
  connectorId: string,
  orgId: string
): Observable<ConnectorService> {
  return connectorsService
    .createService(
      {
        connector_id: connectorId,
        ConnectorService: {
          spec: {
            org_id: orgId,
            connector_id: connectorId,
            service: 'audits',
          },
        },
      },
      'body',
      getIgnoreErrorsHeader()
    )
    .pipe(
      catchError((err) => {
        if (err.status === 409) {
          return of(err.error);
        }
        return throwError(() => err);
      })
    );
}

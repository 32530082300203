<div class="main-container">
  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [rowObjectName]="rowObjectName"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    (updateEvent)="updateEvent()"
    (updateAutoInput)="updateAutoInput($event)"
    (removeSelected)="deleteSelected()"
  >
  </portal-table-layout>
</div>

import { UserState } from './user.models';
import { UserActions, UserActionTypes } from './user.actions';
import { Organisation } from '@agilicus/angular';
import { getGettingStartedDataFromAdminPortalUserMetadata } from './preferences/user-preference-utils';

export const userStateInitial: UserState = {
  current_org: undefined,
  base_org_id: undefined,
  current_org_id: undefined,
  member_orgs: [],
  user: undefined,
  desired_org_set: false,
  logging_in: false,
  permissions: undefined,
  admin_portal_user_metadata: undefined,
  getting_started_data: undefined,
};

export function userReducer(state: UserState = userStateInitial, action: UserActions): UserState {
  switch (action.type) {
    case UserActionTypes.CANNOT_SEE_CURRENT_ORG:
      return {
        ...state,
        current_org: undefined,
      };
    case UserActionTypes.INIT:
      return {
        ...state,
        logging_in: true,
      };
    case UserActionTypes.LOGGED_IN:
      return {
        ...state,
        user: action.user,
        base_org_id: action.user.org_id,
        logging_in: false,
      };
    case UserActionTypes.MEMBER_ORGS_UPDATED:
      return {
        ...state,
        // We won't always have the current org retrieved from the API. In that case,
        // set up the current org here by finding it in the orgs we're members of.
        // If we can't find it, default to what we currently have.
        member_orgs: action.orgs,
        current_org: findOrgOrDefault(action.orgs, state.current_org_id, state.current_org),
      };
    case UserActionTypes.NOT_LOGGED_IN:
      return {
        ...state,
        logging_in: false,
      };
    case UserActionTypes.ORG_SWITCHED:
      return {
        ...state,
        desired_org: action.org_id,
        desired_org_set: true,
      };
    case UserActionTypes.ORGANISATION_SELECTED:
      return {
        ...state,
        current_org: action.organisation,
      };
    case UserActionTypes.SET_CURRENT_ORG:
      // Note: since permissions are tied to the org, you cannot set the org without setting
      // the permissions. Otherwise permission checks can fall out of sync with the org id
      return {
        ...state,
        current_org_id: action.orgId,
        permissions: {
          usersRoles: getRoles(action.permissions, 'urn:api:agilicus:users'),
          appsRoles: getRoles(action.permissions, 'urn:api:agilicus:applications'),
          sysgroupsRoles: getRoles(action.permissions, 'urn:api:agilicus:sysgroups'),
          diagRoles: getRoles(action.permissions, 'urn:api:agilicus:diagnostics'),
          filesRoles: getRoles(action.permissions, 'urn:api:agilicus:files'),
          metricsRoles: getRoles(action.permissions, 'urn:api:agilicus:metrics'),
          issuersRoles: getRoles(action.permissions, 'urn:api:agilicus:issuers'),
          auditsRoles: getRoles(action.permissions, 'urn:api:agilicus:audits'),
          resourceRoles: getRoles(action.permissions, 'urn:api:agilicus:resources'),
          auditDestinationsRoles: getRoles(action.permissions, 'urn:api:agilicus:audit-config'),
          messagesRoles: getRoles(action.permissions, 'urn:api:agilicus:messages'),
          labelsRoles: getRoles(action.permissions, 'urn:api:agilicus:labels'),
          rulesRoles: getRoles(action.permissions, 'urn:api:agilicus:rules'),
          tokensRoles: getRoles(action.permissions, 'urn:api:agilicus:traffic-tokens'),
        },
      };
    case UserActionTypes.UPDATE_ADMIN_PORTAL_METADATA:
      return {
        ...state,
        admin_portal_user_metadata: action.userMetadata,
        getting_started_data: getGettingStartedDataFromAdminPortalUserMetadata(action.userMetadata),
      };
    default:
      return state;
  }
}

function getRoles(permissions: Map<string, string[]>, endpoint: string): string[] {
  if (permissions.has(endpoint)) {
    return permissions.get(endpoint);
  }

  return [];
}

function findOrgOrDefault(orgs: Organisation[], orgId: string | undefined, currentOrg: Organisation | undefined): Organisation | undefined {
  if (!orgId) {
    return currentOrg;
  }

  const org = orgs.find((value: Organisation) => value.id === orgId);
  if (!org) {
    return currentOrg;
  }

  return org;
}

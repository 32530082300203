<p class="rules-secondary-header top-margin">Geolocation Rules:</p>
<portal-table-layout 
  [tableData]="tableData" 
  [columnDefs]="columnDefs" 
  [filterManager]="filterManager"
  [fixedTable]="fixedTable"
  [makeEmptyTableElement]="makeEmptyTableElementFunc"
  [rowObjectName]="rowObjectName"
  [pageDescriptiveText]="geolocationRulesDescriptiveText"
  pageInfoWidth="full"
  [hasNestedTable]="usePolicyRulesVersion()"
  (updateEvent)="updateEvent($event)"
  (removeSelected)="deleteSelected($event)"
>
</portal-table-layout>
<div class="main-content-container">
  <div class="left">
    <h1>Welcome {{ currentUser?.email }} </h1>
  </div>

  <div class="right">
    <h4>
      Zero-Trust <a target="_blank" [href]="zeroTrustLink">Concepts</a>
    </h4>
    <portal-product-guide-link
      [productGuideLink]="productGuideLink"
      [largeText]="productGuideLargeText"
    >
    </portal-product-guide-link>
  </div>

  <p class="tag-line">Get Started with Agilicus Any-X</p>

  <div class="main-description">
    <p class="main-text">This getting-started guide helps walk through key concepts and setup.</p>
    <p class="main-text">For assistance, please use the Chat icon in the lower-right, or email info&#64;agilicus.com</p>
  </div>

  <hr>
  <hr>

  <div class="tasks-list-container">
    <ol class="tasks-list">
      <li
        *ngFor="let item of listItemData"
        [ngClass]="{
          'show-number': !getTaskCompletedStatus(item.task, gettingStartedDataCopy),
          'show-pulsing-number': isFirstIncompleteTask(item),
          'show-complete': getTaskCompletedStatus(item.task, gettingStartedDataCopy)
        }"
        class="clickable main-text highlight-hover"
        (click)="routeToGuideScreen(item.link)"
      >
        {{ item.text }}
      </li>
    </ol>
  </div>

  <div class="getting-started-button-container">
    <button
      mat-raised-button
      color="primary"
      class="getting-started-button"
      matTooltip="Click to dismiss the getting started menu. Clicking this will hide the getting started menu on subsequent logins."
      aria-label="A button to dismiss the getting started page"
      (click)="dismissGettingStarted()"
    >
      DISMISS GETTING STARTED
    </button>
  </div>

  <portal-demo-setup
    [overrideAllShowDemoChecks]="overrideAllShowDemoChecks"
  >
  </portal-demo-setup>
</div>
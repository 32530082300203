import { Component, OnInit, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnChanges } from '@angular/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createSelectColumn,
  createAutoInputColumn,
  AutoInputColumn,
  InputColumn,
  SelectColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { Environment, EnvironmentConfig, ApplicationService } from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { ButtonType } from '../button-type.enum';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { updateTableElements, useValueIfNotInMap, createEnumChecker, setDropdownEnableStateForTableAutoInputs } from '../utils';
import { OptionalExternalMountElement, OptionalApplicationService } from '../optional-types';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import {
  ActionApiApplicationsDeletingEnvConfig,
  ActionApiApplicationsSavingEnvConfig,
} from '@app/core/api-applications/api-applications.actions';
import { UntypedFormControl } from '@angular/forms';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { selectApplicationServicesList } from '@app/core/application-service-state/application-service.selectors';
import { initApplicationServices } from '@app/core/application-service-state/application-service.actions';

export interface ExternalMountElement extends EnvironmentConfig, TableElement {
  hostnameFormControl: UntypedFormControl;
}

@Component({
  selector: 'portal-application-environment-external-mounts',
  templateUrl: './application-environment-external-mounts.component.html',
  styleUrls: ['./application-environment-external-mounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentExternalMountsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<ExternalMountElement>> = new Map();
  private appState$: Observable<ApiApplicationsState>;
  private currentEnv: Environment;
  public tableData: Array<ExternalMountElement> = [];
  public rowObjectName = 'EXTERNAL MOUNT';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private serviceNameToHostMap: Map<string, string> = new Map();
  private serviceHostToNameMap: Map<string, string> = new Map();
  private serviceNameToServiceMap: Map<string, ApplicationService> = new Map();

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.store.dispatch(initApplicationServices({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.appState$ = this.store.pipe(select(selectApiApplications));
    const appServiceListState$ = this.store.pipe(select(selectApplicationServicesList));
    combineLatest([this.appState$, appServiceListState$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([appStateResp, appServicesResp]) => {
        if (!appStateResp || !appStateResp.current_external_mounts_list || !appStateResp.current_environment || !appServicesResp) {
          this.resetEmptyTable();
          return;
        }
        this.currentEnv = appStateResp.current_environment;
        this.setMaps(appServicesResp);
        const mountHostnameColumn = this.columnDefs.get('hostnameFormControl');
        mountHostnameColumn.allowedValues = appServicesResp;
        this.setEditableColumnDefs();
        const filteredExternalMountsList = appStateResp.current_external_mounts_list.filter(
          (externalMount) => externalMount.config_type !== EnvironmentConfig.ConfigTypeEnum.mount_tmpdir
        );
        // Need to make a copy since we cannot modify the readonly data from the store.
        this.updateTable(cloneDeep(filteredExternalMountsList));
        setDropdownEnableStateForTableAutoInputs('hostnameFormControl', mountHostnameColumn, this.tableData);
      });
  }

  private setMaps(appServicesResp: Array<ApplicationService>): void {
    this.serviceNameToHostMap.clear();
    this.serviceHostToNameMap.clear();
    this.serviceNameToServiceMap.clear();
    for (const service of appServicesResp) {
      this.serviceNameToHostMap.set(service.name, service.hostname);
      this.serviceHostToNameMap.set(service.hostname, service.name);
      this.serviceNameToServiceMap.set(service.name, service);
    }
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getMountPathColumn(): InputColumn<ExternalMountElement> {
    const mountPathColumn = createInputColumn('mount_path');
    mountPathColumn.displayName = 'Destination';
    mountPathColumn.requiredField = () => true;
    mountPathColumn.isUnique = true;
    mountPathColumn.isCaseSensitive = true;
    return mountPathColumn;
  }

  private getConfigTypeColumn(): SelectColumn<ExternalMountElement> {
    const configTypeColumn = createSelectColumn('config_type');
    configTypeColumn.displayName = 'Type';
    // If adding a new config_type to the allowed values list below, we need to also update the
    // `filteredEnvConfigs` in the `loadEnvConfigs$` effect in `api-applications.effects.ts`.
    configTypeColumn.allowedValues = [EnvironmentConfig.ConfigTypeEnum.mount_smb];
    return configTypeColumn;
  }

  private getMountHostnameColumn(): AutoInputColumn<ExternalMountElement> {
    const mountHostnameColumn = createAutoInputColumn('hostnameFormControl');
    mountHostnameColumn.displayName = 'Hostname';
    mountHostnameColumn.isCaseSensitive = true;
    mountHostnameColumn.getDisplayValue = (service: OptionalApplicationService) => {
      return service.name;
    };
    mountHostnameColumn.getPropertyValueFromTableValue = (serviceName: string): string => {
      const targetAppService = this.serviceNameToServiceMap.get(serviceName);
      if (targetAppService) {
        return targetAppService.hostname;
      } else {
        return serviceName;
      }
    };
    mountHostnameColumn.getFilteredValues = (
      element: OptionalExternalMountElement,
      column: AutoInputColumn<ExternalMountElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.hostnameFormControl, column);
    };
    return mountHostnameColumn;
  }

  private getMountDomainColumn(): InputColumn<ExternalMountElement> {
    const mountDomainColumn = createInputColumn('mount_domain');
    mountDomainColumn.displayName = 'Domain';
    mountDomainColumn.isCaseSensitive = true;
    return mountDomainColumn;
  }

  private getMountShareColumn(): InputColumn<ExternalMountElement> {
    const mountShareColumn = createInputColumn('mount_share');
    mountShareColumn.displayName = 'Share';
    mountShareColumn.isCaseSensitive = true;
    return mountShareColumn;
  }

  private getMountSrcPathColumn(): InputColumn<ExternalMountElement> {
    const mountSrcPathColumn = createInputColumn('mount_src_path');
    mountSrcPathColumn.displayName = 'Source Mount';
    mountSrcPathColumn.isCaseSensitive = true;
    return mountSrcPathColumn;
  }

  private getMountUsernameColumn(): InputColumn<ExternalMountElement> {
    const mountUsernameColumn = createInputColumn('mount_username');
    mountUsernameColumn.displayName = 'Username';
    mountUsernameColumn.isCaseSensitive = true;
    return mountUsernameColumn;
  }

  private getMountPasswordColumn(): InputColumn<ExternalMountElement> {
    const mountPasswordColumn = createInputColumn('mount_password');
    mountPasswordColumn.displayName = 'Password';
    mountPasswordColumn.isCaseSensitive = true;
    return mountPasswordColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getMountPathColumn(),
        this.getConfigTypeColumn(),
        this.getMountHostnameColumn(),
        this.getMountDomainColumn(),
        this.getMountShareColumn(),
        this.getMountSrcPathColumn(),
        this.getMountUsernameColumn(),
        this.getMountPasswordColumn(),
      ],
      this.columnDefs
    );
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const mountPathColumn = this.columnDefs.get('mount_path');
    mountPathColumn.isEditable = !this.fixedTable;

    const configTypeColumn = this.columnDefs.get('config_type');
    configTypeColumn.isEditable = !this.fixedTable;

    const mountDomainColumn = this.columnDefs.get('mount_domain');
    mountDomainColumn.isEditable = !this.fixedTable;

    const mountUsernameColumn = this.columnDefs.get('mount_username');
    mountUsernameColumn.isEditable = !this.fixedTable;

    const mountPasswordColumn = this.columnDefs.get('mount_password');
    mountPasswordColumn.isEditable = !this.fixedTable;

    const mountHostnameColumn = this.columnDefs.get('hostnameFormControl');
    mountHostnameColumn.isEditable = !this.fixedTable;

    const mountShareColumn = this.columnDefs.get('mount_share');
    mountShareColumn.isEditable = !this.fixedTable;

    const mountSrcPathColumn = this.columnDefs.get('mount_src_path');
    mountSrcPathColumn.isEditable = !this.fixedTable;
  }

  private updateTable(envConfigList: Array<EnvironmentConfig>): void {
    this.buildData(envConfigList);
    this.replaceTableWithCopy();
  }

  private buildData(envConfigList: Array<EnvironmentConfig>): void {
    const data: Array<ExternalMountElement> = [];
    for (let i = 0; i < envConfigList.length; i++) {
      data.push(this.createExternalMountElement(envConfigList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createExternalMountElement(envConfig: EnvironmentConfig, index: number): ExternalMountElement {
    const data: ExternalMountElement = {
      ...getDefaultTableProperties(index),
      hostnameFormControl: new UntypedFormControl(),
      ...envConfig,
    };
    this.setFormControlValues(data);
    return data;
  }

  private setFormControlValues(data: ExternalMountElement): void {
    const hostnameValue = useValueIfNotInMap(data.mount_hostname, this.serviceHostToNameMap);
    data.hostnameFormControl.setValue(hostnameValue);
  }

  private makeEmptyTableElement(params?: OptionalExternalMountElement): ExternalMountElement {
    const element = {
      maintenance_org_id: this.currentEnv.maintenance_org_id,
      config_type: EnvironmentConfig.ConfigTypeEnum.mount_smb,
      mount_domain: '',
      mount_username: '',
      mount_password: '',
      mount_hostname: '',
      mount_share: '',
      mount_src_path: '',
      mount_path: '',
      ...getDefaultNewRowProperties(),
      hostnameFormControl: new UntypedFormControl(),
      ...params,
    };
    this.setFormControlValues(element);
    return element;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Receives an ExternalMountElement from the table then saves the current_external_mounts_list.
   */
  public updateEvent(updatedExternalMountElement: ExternalMountElement): void {
    this.saveExternalMount(updatedExternalMountElement);
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<ExternalMountElement>;
    element: ExternalMountElement;
  }): void {
    if (params.column.name !== 'hostnameFormControl') {
      return;
    }
    const value = useValueIfNotInMap(params.optionValue, this.serviceNameToHostMap);
    if (value === params.element.mount_hostname) {
      // The value has not been changed.
      return;
    }
    if (params.optionValue === '') {
      params.element.mount_hostname = '';
    } else {
      const updatedHostName = params.column.getPropertyValueFromTableValue(params.optionValue);
      params.element.mount_hostname = updatedHostName;
    }
    params.element.dirty = true;
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<ExternalMountElement>; element: ExternalMountElement }): void {
    if (params.column.name !== 'config_type') {
      return;
    }
    const isConfigTypeEnum = createEnumChecker(EnvironmentConfig.ConfigTypeEnum);
    if (isConfigTypeEnum(params.value)) {
      params.element.config_type = params.value;
    }
  }

  private saveExternalMount(updatedExternalMountElement: ExternalMountElement): void {
    // Need to make a copy since we cannot later modify the readonly data from the store.
    const updatedExternalMountElementCopy: ExternalMountElement = cloneDeep(updatedExternalMountElement);
    delete updatedExternalMountElementCopy.hostnameFormControl;
    this.store.dispatch(new ActionApiApplicationsSavingEnvConfig(updatedExternalMountElementCopy));
  }

  public deleteSelected(externalMountToDelete: Array<ExternalMountElement>): void {
    if (externalMountToDelete.length === 1 && externalMountToDelete[0].isNew) {
      this.tableData = this.tableData.filter((element) => !element.isNew);
      return;
    }
    for (const externalMount of externalMountToDelete) {
      if (externalMount.isNew) {
        continue;
      }
      this.deleteExternalMount(externalMount);
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private deleteExternalMount(externalMountToDelete: ExternalMountElement): void {
    // Need to make a copy of the externalMountToDelete or it will be converted to readonly.
    this.store.dispatch(new ActionApiApplicationsDeletingEnvConfig(cloneDeep(externalMountToDelete)));
  }
}

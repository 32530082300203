import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppTabStateDirective } from './components/app-tab-state/app-tab-state.directive';
import { ButtonsModule } from './components/buttons/buttons.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { NoPermissionsComponent } from './components/no-permissions/no-permissions.component';
import { PageInfoComponent } from './components/page-info/page-info.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { SwipeableDirective } from './components/swipeable/swipeable.directive';
import { ProductGuideLinkComponent } from './components/product-guide-link/product-guide-link.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatProgressBarModule, MatTooltipModule, ButtonsModule, MatDialogModule, MatButtonModule],
  declarations: [
    AppTabStateDirective,
    ConfirmationDialogComponent,
    SwipeableDirective,
    NoPermissionsComponent,
    PageInfoComponent,
    ProductGuideLinkComponent,
    ProgressBarComponent,
  ],
  exports: [
    AppTabStateDirective,
    ConfirmationDialogComponent,
    NoPermissionsComponent,
    PageInfoComponent,
    ProductGuideLinkComponent,
    ProgressBarComponent,
    SwipeableDirective,
  ],
})
export class CommonComponentsModule {}

<div class="dialog-container">
  <mat-dialog-content>
    <h2>Reset the expiry for support user "{{ supportRequestElement.supporting_user_email }}"</h2>

    <form 
      class="reset-expiry-form" 
      [formGroup]="resetExpiryForm" 
      spellcheck="false"
      autocomplete="off"
    >  
      <mat-form-field>
        <mat-label>My support user's access will expire in (hours)...</mat-label>
        <mat-select
          #selectExpiry
          formControlName="reset_expiry"
          (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectExpiry)"
          (selectionChange)="onResetExpiryChange($event.value)"
        >
          <mat-option value=""></mat-option>
          <mat-option
            *ngFor="let hour of getTwentyFourHourArray()"
            [value]="hour"
            matTooltip="Select the number of hours that this user will have access. Defaults to 24 hours if none provided."
          >
            {{ hour }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-icon 
        class="help-icon custom-help-icon"
        matTooltip="Will default to 24 hours if none selected"
      >
        help_outline
      </mat-icon>
    </form>
  </mat-dialog-content>

  <mat-dialog-actions align="end" class="clear">
    <button
      mat-raised-button
      color="primary"
      (click)="onConfirmClick()"
      tabindex="1"
    >
      SAVE
    </button>
    <button
      mat-raised-button
      mat-dialog-close
      tabindex="-1"
    >
      CANCEL
    </button>
  </mat-dialog-actions>
</div>
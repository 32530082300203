<div *ngIf="currentPolicyRuleCopy">
  <mat-form-field class="main-input-field rule-name-field">
    <mat-label>Rule Name</mat-label>
    <input
      matInput
      type="text"
      value="{{ currentPolicyRuleCopy.spec.name }}"
      (blur)="updateRuleName($event.target.value)"
      (keyup.enter)="keyTabManager.keyTab($event.target.id)"
      spellcheck="true"
    />
  </mat-form-field>

  <portal-auth-issuer-policy-rule-conditions class="policy-rule-conditions"
    [currentPolicyRule]="currentPolicyRuleCopy"
    [dropdownSelector]="policyRuleActionDropdownSelector"
    [groupIdToGroupMap]="groupIdToGroupMap"
    [groupDisplayNameToGroupMap]="groupDisplayNameToGroupMap"
  >
  </portal-auth-issuer-policy-rule-conditions>

  <div class="delete-button-container">
    <button
      type="button"
      mat-raised-button
      color="warn"
      class="control-button"
      (click)="deletePolicyRule()"
      matTooltip="Delete this policy rule"
      attr.aria-label="A button that deletes the policy rule"
    >
      DELETE RULE
    </button>
  </div>
</div>
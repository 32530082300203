<tour-step-template></tour-step-template>
<ng-container *ngIf="(context$ | async) as ctx">


  <div class="main-container flex-xl-100">
    <div class="font_preload" style="opacity: 0">
      <span style="font-family: 'Canada 1500'; font-style: 'bold';"></span>
    </div>

    <div class="main-toolbar">

      <mat-toolbar color="primary">
        <button 
          *ngIf="showSideMenu(ctx)"
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="snav.toggle(); updateEvent(router.url)"
        >
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <a routerLink="">
          <img class="branding" alt="logo" [src]="getBrandLogo()">
        </a>
        <mat-form-field 
          class="org-select" 
          *ngIf="ctx.isApiAuthenticated && ctx.orgs.length > 1"
          matTooltip="Click to switch organisation"
          aria-label="Dropdown menu to select a different organisation"
        >
          <mat-select 
            (selectionChange)="updateOrg($event.value)" 
            value="{{ ctx.currentOrgId }}"
          >
            <mat-option 
              *ngFor="let org of ctx.orgs"
              value="{{ org.id }}"
            >
              {{ org.organisation }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span class="fill-space"></span>

        <portal-notifications
          *ngIf="ctx.isApiAuthenticated"
          [user]="ctx.user"
        >
        </portal-notifications>
      
        <button 
          mat-icon-button 
          [matMenuTriggerFor]="menu" 
          *ngIf="!(ctx.isApiAuthenticated || isNavigatingToSignup())"
          class="account-button" 
          aria-label="Account icon-button with a menu"
          matTooltip="Click to login" 
        >
          <mat-icon class="account-icon">account_circle</mat-icon>
        </button>

        <button 
          mat-icon-button 
          [matMenuTriggerFor]="menu" 
          *ngIf="ctx.isApiAuthenticated"
          class="account-button" 
          aria-label="Account icon-button with a menu"
          matTooltip="{{ ctx.user.email }}" 
        >
        <img ngxGravatar email="{{ ctx.user.email }}" alt="user-gravatar">
        </button>

        <mat-menu #menu="matMenu" class="account-menu profile-account-menu">
          <div 
            class="row account-container" 
            *ngIf="ctx.isApiAuthenticated" 
            (click)="$event.stopPropagation()"
          >
            <div class="col icon-container">
              <img ngxGravatar email="{{ ctx.user.email }}" alt="user-gravatar" size=100>
            </div>
            <div class="col profile-container">
              <span *ngIf="!!ctx.user.first_name">{{ ctx.user.first_name }}</span>
              <br>
              <span>{{ ctx.user.email }}</span>
            </div>
          </div>
          
          <button 
            mat-menu-item 
            class="account-menu-button"  
            *ngIf="ctx.isApiAuthenticated" 
            [routerLink]="['']" 
            (click)="handleLogoutClick()"
          >
            <span>Sign Out</span>
          </button>

          <button 
            mat-menu-item
            class="login-button" 
            *ngIf="!ctx.isApiAuthenticated" 
            [routerLink]="[]" 
            (click)="handleLoginClick()"
          >
            <span>Sign In</span>
          </button>
          <hr>

          <button 
            mat-menu-item 
            class="account-menu-button" 
            *ngIf="ctx.isApiAuthenticated" 
            routerLink="profile" 
            routerLinkActive="active"
          >
            <span>View Profile</span>
          </button>

          <button
            mat-menu-item
            class="account-menu-button"
            *ngIf="currentPortalVerion !== portalVersion.beta"
            routerLink="['']"
            routerLinkActive="active"
            (click)="onVerClickBeta()"
          >
            <span>Use Beta Version</span>
          </button>

          <button
            mat-menu-item
            class="account-menu-button"
            *ngIf="currentPortalVerion !== portalVersion.alpha"
            routerLink="['']"
            routerLinkActive="active"
            (click)="onVerClickAlpha()"
          >
            <span>Use Alpha Version</span>
          </button>

          <button
            mat-menu-item
            class="account-menu-button"
            *ngIf="currentPortalVerion !== portalVersion.stable"
            routerLink="['']"
            routerLinkActive="active"
            (click)="onVerClickStable()"
          >
            <span>Use Stable Version</span>
          </button>

          <span class="version-text" mat-menu-item>Version: {{ version }} ({{ currentPortalVerion }}) </span>
        </mat-menu>
      </mat-toolbar>
    </div>

    <mat-drawer-container>
      <mat-drawer-content>
        <div class="space-between drawer-content-container">
          <mat-sidenav-container 
            class="sidenav"
            fixedInViewport="true"
            autosize
          >
            <mat-sidenav
              #snav
              fixedInViewport="true"
              [mode]="(isHandset$ | async) ? 'over' : 'side'"
              fixedTopGap="56"
              [opened]="showSideMenu(ctx)"
            >
              <mat-nav-list *ngIf="showSideMenu(ctx)">
                <portal-side-nav-menu
                  #sideNavMenuComp
                  [navMenuItems]="sideNavMenuItemsList"
                  [currentRouterLink]="currentRouterLink"
                  (updateEvent)="updateEvent($event)"
                  (toggleFaqFromSideMenu)="toggleFaqFromSideMenu()"
                >
                </portal-side-nav-menu>
 
                <!--
                <a mat-list-item routerLink="http-endpoints" routerLinkActive="active">HTTP Endpoints</a>
                <a mat-list-item routerLink="smtp-endpoints" routerLinkActive="active">SMTP Endpoints</a>
                -->
              </mat-nav-list>
            </mat-sidenav>

            <mat-sidenav-content class="page-wrap">
              <main class="content">
                <router-outlet></router-outlet>
              </main>
              <portal-footer></portal-footer>
            </mat-sidenav-content>
          </mat-sidenav-container>

          <div class="flex-h">
            <div
              class="mat-elevation-z8 expander-container clickable mat-table"
              (click)="isFaqOpen = !isFaqOpen"
            >
              <mat-icon
                matTooltip="View FAQ"
                class="expander-icon"
              >
                help
              </mat-icon>
            </div>
          </div>
        </div>

      </mat-drawer-content>

      <mat-drawer
        #drawer
        [(opened)]="isFaqOpen"
        class="history-drawer"
        mode="side"
        position="end"
        mode="over"
       >
        <portal-faq *ngIf="isFaqOpen"></portal-faq>
      </mat-drawer>
    </mat-drawer-container>

  </div>

</ng-container>

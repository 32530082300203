import { Component, OnInit, ChangeDetectionStrategy, OnChanges, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable, combineLatest, of } from 'rxjs';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createChipListColumn,
  ChiplistColumn,
  InputColumn,
  setColumnDefs,
  createCheckBoxColumn,
  CheckBoxColumn,
  createExpandColumn,
  createActionsColumn,
  ActionMenuOptions,
} from '../table-layout/column-definitions';
import {
  Application,
  RuleScopeEnum,
  RuleConfig,
  SourceISOCountryCodeCondition,
  instanceOfSourceISOCountryCodeCondition,
  RuleAction,
  SimpleResourcePolicyTemplate,
  SimpleResourcePolicyTemplateStructure,
} from '@agilicus/angular';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { ActionApiApplicationsModifyCurrentApp } from '@app/core/api-applications/api-applications.actions';
import { selectApiApplicationsRefreshData, selectApiCurrentApplication } from '@app/core/api-applications/api-applications.selectors';
import { updateTableElements } from '../utils';
import { concatMap, takeUntil } from 'rxjs/operators';
import { cloneDeep } from 'lodash-es';
import { RuleType } from '../rule-type.enum';
import { getDefaultNestedDataProperties, getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import {
  initPolicyTemplateInstances,
  savingPolicyTemplateInstance,
} from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import { selectCanAdminRules } from '@app/core/user/permissions/rules.selectors';
import {
  getPolicyDataBeforeApplicationInit$,
  getPolicyStructureFromTableData,
  getTargetPolicyTemplateInstanceResource,
  PolicyRuleCommonTableElement,
  PolicyTemplateInstanceResource,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import {
  selectPolicyTemplateInstanceRefreshDataValue,
  selectPolicyTemplateInstanceResourcesList,
} from '@app/core/policy-template-instance-state/policy-template-instance.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { ButtonType } from '../button-type.enum';
import { ExpandedTableData } from '../table-layout/expanded-table-data';
import {
  getPolicyRulePriorityErrorMessage,
  getPriorityNotWithinRangeErrorMessage,
  isValidPolicyRulePriority,
  isWithinValidPriorityRange,
} from '../validation-utils';
import { RuleConfigConditionType } from '../rule-config-condition-type.enum';

export interface GeolocationRuleElement extends PolicyRuleCommonTableElement {
  objectIndex?: number;
  operator: SourceISOCountryCodeCondition.OperatorEnum;
  value: Array<string>;
  extended_condition?: {
    negated: boolean;
    condition: SourceISOCountryCodeCondition;
  };
  rule_actions: Array<RuleAction>;
}

export interface NestedPolicyGeolocationRuleElement extends GeolocationRuleElement {
  parentId: string | number;
}

export interface GeolocationRuleConfig extends RuleConfig {
  objectIndex?: number;
}

@Component({
  selector: 'portal-application-geolocation-rules',
  templateUrl: './application-geolocation-rules.component.html',
  styleUrls: ['./application-geolocation-rules.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationGeolocationRulesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<GeolocationRuleElement>> = new Map();
  public tableData: Array<GeolocationRuleElement> = [];
  public hasRulesPermissions: boolean;
  private orgId: string;
  public currentApplicationCopy: Application;
  private localApplicationId: string;
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'Geolocation Rule';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public geolocationRulesDescriptiveText = `Control access to your application based on the location of a user. Allow or deny requests based on the code of the country from which requests originate.`;
  private allPolicyRuleConfigsList: Array<RuleConfig> = [];
  private geolocationRuleConfigsList: Array<GeolocationRuleConfig> = [];
  private nonGeolocationPolicyRuleConfigsList: Array<GeolocationRuleConfig> = [];
  private geolocationPolicyStructuresList: Array<SimpleResourcePolicyTemplateStructure> = [];
  private nonGeolocationPolicyStructuresList: Array<SimpleResourcePolicyTemplateStructure> = [];
  private policyTemplateInstanceResourceCopy: PolicyTemplateInstanceResource;
  private policyRuleNameToRuleConfigMap: Map<string, RuleConfig> = new Map();
  private parentPolicyRuleNameToChildRulesMap: Map<string, Array<RuleConfig>> = new Map();
  public policyTemplate: SimpleResourcePolicyTemplate;
  private nonGeolocationPolicyRules: Array<RuleConfig> = [];
  private localPolicyTemplateInstanceRefreshDataValue = 0;
  private localApplicationsRefreshDataValue = 0;

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    i18nIsoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'));
    this.initializeColumnDefs(this.columnDefs, false);
    this.getAndSetAllData();
  }

  public ngOnChanges(): void {
    if (this.currentApplicationCopy === undefined) {
      return;
    }
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private getAllData$(): Observable<[OrgQualifiedPermission, [PolicyTemplateInstanceResource[], number], Application, number]> {
    const hasRulesPermissions$ = this.store.pipe(select(selectCanAdminRules));
    const policyDataBeforeApplicationInit$ = getPolicyDataBeforeApplicationInit$(
      this.store,
      selectPolicyTemplateInstanceResourcesList,
      selectPolicyTemplateInstanceRefreshDataValue
    );
    const currentAppState$ = this.store.pipe(select(selectApiCurrentApplication));
    const refreshApplicationsDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    return combineLatest([hasRulesPermissions$, policyDataBeforeApplicationInit$, currentAppState$, refreshApplicationsDataState$]).pipe(
      concatMap(([hasRulesPermissionsResp, policyDataBeforeApplicationInitResp, currentAppStateResp, refreshApplicationsDataStateResp]) => {
        return combineLatest([
          of(hasRulesPermissionsResp),
          of(policyDataBeforeApplicationInitResp),
          of(currentAppStateResp),
          of(refreshApplicationsDataStateResp),
        ]);
      })
    );
  }

  private getAndSetAllData(): void {
    this.getAllData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([hasRulesPermissionsResp, policyDataBeforeApplicationInitResp, currentAppStateResp, refreshApplicationsDataStateResp]) => {
          const policyTemplateInstanceResourceListStateResp = policyDataBeforeApplicationInitResp[0];
          const refreshPolicyTemplateInstanceDataStateResp = policyDataBeforeApplicationInitResp[1];
          this.orgId = hasRulesPermissionsResp?.orgId;
          this.hasRulesPermissions = hasRulesPermissionsResp?.hasPermission;
          this.currentApplicationCopy = cloneDeep(currentAppStateResp);
          if (!this.hasRulesPermissions || !currentAppStateResp) {
            this.resetEmptyTables();
            return;
          }
          const targetPolicyTemplateInstanceResource = !!policyTemplateInstanceResourceListStateResp
            ? getTargetPolicyTemplateInstanceResource(policyTemplateInstanceResourceListStateResp, this.currentApplicationCopy?.id)
            : undefined;
          this.policyTemplateInstanceResourceCopy = cloneDeep(targetPolicyTemplateInstanceResource);
          this.policyTemplate = this.policyTemplateInstanceResourceCopy?.spec?.template;
          this.allPolicyRuleConfigsList = this.policyTemplate?.rules ? this.policyTemplate.rules : [];
          this.setRuleConfigArrays();
          this.setRulesMapsAndPolicyStructureArray();
          if (
            this.tableData.length === 0 ||
            this.localApplicationsRefreshDataValue !== refreshApplicationsDataStateResp ||
            this.localPolicyTemplateInstanceRefreshDataValue !== refreshPolicyTemplateInstanceDataStateResp ||
            this.currentApplicationCopy.id !== this.localApplicationId
          ) {
            this.localApplicationsRefreshDataValue = refreshApplicationsDataStateResp;
            this.localPolicyTemplateInstanceRefreshDataValue = refreshPolicyTemplateInstanceDataStateResp;
            if (
              (this.localApplicationsRefreshDataValue !== 0 && this.localPolicyTemplateInstanceRefreshDataValue !== 0) ||
              this.currentApplicationCopy.id !== this.localApplicationId
            ) {
              this.localApplicationId = this.currentApplicationCopy.id;
              this.initializeColumnDefs(this.columnDefs, true);
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.initGeolocationRulesTable();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  private setRuleConfigArrays(): void {
    this.nonGeolocationPolicyRuleConfigsList.length = 0;
    for (const policyRuleConfig of this.allPolicyRuleConfigsList) {
      if (policyRuleConfig.extended_condition.condition.condition_type === RuleConfigConditionType.source_iso_country_code_condition) {
      } else {
        this.nonGeolocationPolicyRuleConfigsList.push(policyRuleConfig);
      }
    }
  }

  private setRulesMapsAndPolicyStructureArray(): void {
    this.policyRuleNameToRuleConfigMap.clear();
    this.parentPolicyRuleNameToChildRulesMap.clear();
    this.geolocationPolicyStructuresList.length = 0;
    this.nonGeolocationPolicyStructuresList.length = 0;
    for (const rule of this.allPolicyRuleConfigsList) {
      this.policyRuleNameToRuleConfigMap.set(rule.name, rule);
    }
    if (!this.policyTemplate?.policy_structure) {
      return;
    }
    for (const node of this.policyTemplate.policy_structure) {
      const targetRule = this.policyRuleNameToRuleConfigMap.get(node.name);
      if (targetRule.extended_condition.condition.condition_type === RuleConfigConditionType.source_iso_country_code_condition) {
        this.geolocationPolicyStructuresList.push(node);
      } else {
        this.nonGeolocationPolicyStructuresList.push(node);
      }
      const childRules: Array<RuleConfig> = [];
      for (const child of node.root_node.children) {
        childRules.push(this.policyRuleNameToRuleConfigMap.get(child.rule_name));
      }
      this.parentPolicyRuleNameToChildRulesMap.set(node.name, childRules);
    }
  }

  private getFilteredGeolocationRulesFromList(ruleConfigList: Array<RuleConfig> | undefined): Array<GeolocationRuleConfig> {
    if (!ruleConfigList) {
      return [];
    }
    const allRules: Array<GeolocationRuleConfig> = ruleConfigList.map((rule, index) => ({
      ...rule,
      objectIndex: index,
    }));
    const filteredRules = allRules.filter(
      (rule) =>
        rule.extended_condition &&
        instanceOfSourceISOCountryCodeCondition(rule.extended_condition.condition) &&
        rule.scope === RuleScopeEnum.anyone
    );
    return filteredRules;
  }

  private initGeolocationRulesTable(): void {
    this.removeRuleTypeAttribute();
    // Map rules objectIndex to geolocationRules list
    if (this.usePolicyRulesVersion()) {
      // Use policy rules
      this.geolocationRuleConfigsList = this.getFilteredGeolocationRulesFromList(this.getGeolocationParentRulesListFromPolicyStructure());
    } else {
      // Use application rules
      this.geolocationRuleConfigsList = this.getFilteredGeolocationRulesFromList(this.currentApplicationCopy?.rules_config?.rules);
    }
    this.setEditableColumnDefs();
    this.updateTable();
  }

  /**
   * Remove rule_type attribute from rule condition
   */
  public removeRuleTypeAttribute(): void {
    if (!this.currentApplicationCopy?.rules_config?.rules) {
      return;
    }
    this.currentApplicationCopy.rules_config.rules.forEach((rule, index) => {
      if (
        !rule.extended_condition ||
        !rule.extended_condition.condition ||
        !instanceOfSourceISOCountryCodeCondition(rule.extended_condition.condition)
      ) {
        return;
      }
      const cond = this.currentApplicationCopy.rules_config.rules[index].extended_condition.condition as SourceISOCountryCodeCondition;
      this.currentApplicationCopy.rules_config.rules[index].extended_condition.condition = {
        condition_type: cond.condition_type,
        operator: cond.operator,
        value: cond.value,
      };
    });
  }

  public get RuleType(): typeof RuleType {
    return RuleType;
  }

  private getGeolocationParentRulesListFromPolicyStructure(): Array<RuleConfig> {
    const parentRulesList: Array<RuleConfig> = [];
    for (const policyStructure of this.geolocationPolicyStructuresList) {
      const targetRule = this.policyRuleNameToRuleConfigMap.get(policyStructure.name);
      parentRulesList.push(targetRule);
    }
    return parentRulesList;
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const dataForTable: Array<GeolocationRuleElement> = [];
    for (let i = 0; i < this.geolocationRuleConfigsList.length; i++) {
      dataForTable.push(this.createGeolocationRuleElement(this.geolocationRuleConfigsList[i], i));
    }
    if (this.usePolicyRulesVersion()) {
      this.setAllNestedTableData(dataForTable);
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createGeolocationRuleElement(ruleConfig: GeolocationRuleConfig, index: number): GeolocationRuleElement {
    const condition = ruleConfig?.extended_condition?.condition as SourceISOCountryCodeCondition;
    const data: GeolocationRuleElement = {
      objectIndex: ruleConfig.objectIndex,
      name: ruleConfig.name,
      priority: ruleConfig.priority,
      scope: ruleConfig.scope,
      comments: ruleConfig.comments,
      ...getDefaultTableProperties(index),
      extended_condition: {
        condition: condition,
        negated: ruleConfig?.extended_condition?.negated,
      },
      operator: condition?.operator,
      value: condition?.value,
      rule_actions: ruleConfig.actions ? ruleConfig.actions : [],
      backingRule: ruleConfig,
    };
    return data;
  }

  private createNestedGeolocationRuleElement(
    ruleConfig: GeolocationRuleConfig,
    index: number,
    parentElement: GeolocationRuleElement | undefined
  ): NestedPolicyGeolocationRuleElement {
    const condition = ruleConfig.extended_condition.condition as SourceISOCountryCodeCondition;
    const data: NestedPolicyGeolocationRuleElement = {
      objectIndex: ruleConfig.objectIndex,
      name: ruleConfig.name,
      priority: ruleConfig.priority,
      scope: ruleConfig.scope,
      comments: ruleConfig.comments,
      ...getDefaultTableProperties(index),
      extended_condition: {
        condition: condition,
        negated: ruleConfig.extended_condition.negated,
      },
      operator: condition.operator,
      value: condition.value,
      rule_actions: ruleConfig.actions ? ruleConfig.actions : [],
      backingRule: ruleConfig,
      parentId: parentElement.index,
    };
    return data;
  }

  private setAllNestedTableData(data: Array<GeolocationRuleElement>): void {
    for (const element of data) {
      this.setNestedTableData(element);
    }
  }

  private setNestedTableData(element: GeolocationRuleElement): void {
    element.expandedData = {
      ...getDefaultNestedDataProperties(element),
      nestedRowObjectName: 'NESTED RULE',
      nestedButtonsToShow: [ButtonType.ADD, ButtonType.DELETE],
      makeEmptyNestedTableElement: (): NestedPolicyGeolocationRuleElement => {
        return this.makeEmptyNestedTableElement(element);
      },
    };
    this.initializeColumnDefs(element.expandedData.nestedColumnDefs, false);
    const childRulesList = this.parentPolicyRuleNameToChildRulesMap.get(element.backingRule.name);
    if (!childRulesList || childRulesList.length === 0) {
      return;
    }
    for (let i = 0; i < childRulesList?.length; i++) {
      const childRule = childRulesList[i];
      const nestedElement = this.createNestedGeolocationRuleElement(childRule, i, element);
      element.expandedData.nestedTableData.push(nestedElement);
    }
  }

  public getBaseEmptyPolicyRuleElement(): GeolocationRuleElement {
    const backingConditon: SourceISOCountryCodeCondition = {
      condition_type: RuleConfigConditionType.source_iso_country_code_condition,
      operator: SourceISOCountryCodeCondition.OperatorEnum.in,
      value: [],
    };
    return {
      objectIndex: -1,
      name: '',
      scope: RuleScopeEnum.anyone,
      comments: '',
      priority: 0,
      operator: SourceISOCountryCodeCondition.OperatorEnum.in,
      value: [],
      extended_condition: {
        negated: false,
        condition: {
          condition_type: RuleConfigConditionType.source_iso_country_code_condition,
          operator: SourceISOCountryCodeCondition.OperatorEnum.in,
          value: [],
        },
      },
      rule_actions: [{ action: RuleAction.ActionEnum.allow }],
      ...getDefaultNewRowProperties(),
      backingRule: {
        name: '',
        scope: RuleScopeEnum.anyone,
        comments: '',
        priority: 0,
        extended_condition: {
          condition: backingConditon,
          negated: false,
        },
        excluded_roles: [],
      },
    };
  }

  private makeEmptyNestedTableElement(element: GeolocationRuleElement): NestedPolicyGeolocationRuleElement {
    const baseEmptyTableElement = this.getBaseEmptyPolicyRuleElement();
    const baseEmptyTableElementAsNestedPolicyRuleElement = baseEmptyTableElement as NestedPolicyGeolocationRuleElement;
    baseEmptyTableElementAsNestedPolicyRuleElement.parentId = this.getParentIdFromExpandedTableData(element.expandedData);
    return baseEmptyTableElementAsNestedPolicyRuleElement;
  }

  private makeEmptyParentTableElement(): GeolocationRuleElement {
    const baseEmptyTableElement = this.getBaseEmptyPolicyRuleElement();
    this.setNestedTableData(baseEmptyTableElement);
    return baseEmptyTableElement;
  }

  public makeEmptyTableElement(): GeolocationRuleElement {
    if (this.usePolicyRulesVersion()) {
      return this.makeEmptyParentTableElement();
    } else {
      return this.getBaseEmptyPolicyRuleElement();
    }
  }

  private getParentIdFromExpandedTableData(expandedTableData: ExpandedTableData<TableElement>): string | number {
    return expandedTableData.parentId;
  }

  private newUnsavedRuleHasUniquePriority(
    priorityValue: string,
    element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement
  ): boolean {
    const targetElementAsNestedPolicyGeolocationRuleElement = element as NestedPolicyGeolocationRuleElement;
    if (
      targetElementAsNestedPolicyGeolocationRuleElement.parentId === undefined ||
      targetElementAsNestedPolicyGeolocationRuleElement.parentId === null
    ) {
      // Is a parent element and, therefore, does not need a unique priority
      return true;
    }
    const parentElement = this.getParentElementFromParentId(targetElementAsNestedPolicyGeolocationRuleElement);
    const nestedTableElements: Array<NestedPolicyGeolocationRuleElement> = parentElement.expandedData.nestedTableData;
    for (const nestedElement of nestedTableElements) {
      const nestedElementRuleName = !!nestedElement.backingRule.name ? nestedElement.backingRule.name : nestedElement.name;
      const targetElementAsNestedPolicyGeolocationRuleElementName = targetElementAsNestedPolicyGeolocationRuleElement.backingRule.name
        ? targetElementAsNestedPolicyGeolocationRuleElement.backingRule.name
        : targetElementAsNestedPolicyGeolocationRuleElement.name;
      if (
        nestedElementRuleName !== targetElementAsNestedPolicyGeolocationRuleElementName &&
        nestedElement.priority.toString() === priorityValue.toString()
      ) {
        return false;
      }
    }
    return true;
  }

  private isUniqueRuleName(value: string, targetElement: GeolocationRuleElement | NestedPolicyGeolocationRuleElement) {
    for (const element of this.tableData) {
      if (element.name === value && element.index !== targetElement.index) {
        return false;
      }
      if (!element.expandedData?.nestedTableData) {
        continue;
      }
      for (const nestedElement of element.expandedData.nestedTableData) {
        const targetElementAsNestedPolicyGeolocationRuleElement = targetElement as NestedPolicyGeolocationRuleElement;
        if (
          nestedElement.name === value &&
          !(
            nestedElement.parentId === targetElementAsNestedPolicyGeolocationRuleElement.parentId &&
            nestedElement.index === targetElementAsNestedPolicyGeolocationRuleElement.index
          )
        ) {
          return false;
        }
      }
    }
    for (const nonGeolocatonRule of this.nonGeolocationPolicyRules) {
      if (nonGeolocatonRule.name === value) {
        return false;
      }
    }
    return true;
  }

  private isValidRuleNameFormat(value: string): boolean {
    const namePattern = /^[a-zA-Z0-9-_:]+$/;
    return value.match(namePattern) !== null;
  }

  private getRuleNameErrorMessage(value: string, targetElement: GeolocationRuleElement | NestedPolicyGeolocationRuleElement): string {
    if (!this.isValidRuleNameFormat(value)) {
      return 'Please enter a valid rule name';
    }
    if (!this.isUniqueRuleName(value, targetElement)) {
      return `Rule names must be unique`;
    }
    return ``;
  }

  /**
   * Parent Table Column
   */
  private getPriorityColumn(): InputColumn<GeolocationRuleElement> {
    const column = createInputColumn<GeolocationRuleElement>('priority');
    column.isEditable = true;
    column.requiredField = () => true;
    column.inputSize = InputSize.XSMALL;
    column.isValidEntry = (priorityValue: string, element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement) => {
      if (!this.usePolicyRulesVersion()) {
        const priorityValueAsNumber = parseInt(priorityValue, 10);
        return isWithinValidPriorityRange(priorityValueAsNumber);
      }
      if (element.index === -1) {
        // Is a newly added unsaved policy rule
        return this.newUnsavedRuleHasUniquePriority(priorityValue, element);
      }
      return isValidPolicyRulePriority(priorityValue, element.backingRule, this.policyTemplateInstanceResourceCopy);
    };
    column.getCustomValidationErrorMessage = (
      priorityValue: string,
      element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement
    ) => {
      if (element.index === -1) {
        const priorityValueAsNumber = parseInt(priorityValue, 10);
        if (!isWithinValidPriorityRange(priorityValueAsNumber)) {
          return getPriorityNotWithinRangeErrorMessage();
        }
        if (!this.newUnsavedRuleHasUniquePriority(priorityValue, element)) {
          return `Priority of nested rules must be unique`;
        }
      }
      return getPolicyRulePriorityErrorMessage(priorityValue, element.backingRule, this.policyTemplateInstanceResourceCopy);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): InputColumn<GeolocationRuleElement> {
    const column = createInputColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>('name');
    column.requiredField = () => true;
    column.isUnique = true;
    column.isEditable = true;
    column.isValidEntry = (value: string, element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement): boolean => {
      return this.getRuleNameErrorMessage(value, element) === '';
    };
    column.getCustomValidationErrorMessage = (value: string, element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement) => {
      return this.getRuleNameErrorMessage(value, element);
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getCountryCodesColumn(): ChiplistColumn<GeolocationRuleElement> {
    const column = createChipListColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>('value');
    column.displayName = 'Country Codes';
    column.requiredField = () => true;
    column.isEditable = true;
    column.allowedValues = Object.keys(i18nIsoCountries.getAlpha2Codes());
    return column;
  }

  /**
   * Parent Table Column
   */
  private getRuleActionsColumn(): ChiplistColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement> {
    const column = createChipListColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>('rule_actions');
    column.displayName = 'Actions';
    column.requiredField = () => true;
    column.isEditable = true;
    column.allowedValues = Object.keys(RuleAction.ActionEnum).map((value) => {
      return { action: value };
    });
    column.getDisplayValue = (value: RuleAction) => {
      return value?.action;
    };
    column.getElementFromValue = (value) => {
      return { action: value };
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getOperatorColumn(): Column<GeolocationRuleElement> {
    const column = createCheckBoxColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>('operator');
    column.displayName = 'In/Not_In';
    column.isEditable = true;
    column.isChecked = (element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement) => {
      return element.extended_condition?.condition?.operator === SourceISOCountryCodeCondition.OperatorEnum.in;
    };
    column.setElementFromCheckbox = (element: GeolocationRuleElement | NestedPolicyGeolocationRuleElement, isBoxChecked: boolean): any => {
      if (isBoxChecked) {
        element.extended_condition.condition.operator = SourceISOCountryCodeCondition.OperatorEnum.in;
      } else {
        element.extended_condition.condition.operator = SourceISOCountryCodeCondition.OperatorEnum.not_in;
      }
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getCommentsColumn(): InputColumn<GeolocationRuleElement> {
    const column = createInputColumn<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>('comments');
    column.isEditable = true;
    column.isValidEntry = (comment: string): boolean => {
      return comment.length < 2047;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<GeolocationRuleElement> {
    const column = createActionsColumn<GeolocationRuleElement>('actions');
    column.displayName = '';
    const menuOptions: Array<ActionMenuOptions<GeolocationRuleElement>> = [
      {
        displayName: 'Configure Country Codes',
        icon: 'edit',
        tooltip: 'Click to access the "Country Codes" advanced configuration',
        columnName: 'value',
      },
      {
        displayName: 'Configure Actions',
        icon: 'edit',
        tooltip: 'Click to access the "Actions" advanced configuration',
        columnName: 'rule_actions',
      },
    ];
    column.allowedValues = menuOptions;
    return column;
  }

  private initializeColumnDefs(
    colDefs: Map<string, Column<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>>,
    isParentTable: boolean
  ): void {
    const columns = [
      createSelectRowColumn(),
      this.getPriorityColumn(),
      this.getNameColumn(),
      this.getCountryCodesColumn(),
      this.getOperatorColumn(),
      this.getRuleActionsColumn(),
      this.getCommentsColumn(),
      this.getActionsColumn(),
    ];
    if (isParentTable && this.usePolicyRulesVersion()) {
      columns.push(createExpandColumn<GeolocationRuleElement>());
    }
    setColumnDefs(columns, colDefs);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const nameColumn = this.columnDefs.get('name');
    nameColumn.isEditable = !this.fixedTable;

    const priorityColumn = this.columnDefs.get('priority');
    priorityColumn.isEditable = !this.fixedTable;

    const countryCodesColumn: ChiplistColumn<GeolocationRuleElement> = this.columnDefs.get('value');
    countryCodesColumn.isEditable = !this.fixedTable;
    countryCodesColumn.isRemovable = !this.fixedTable;

    const ruleActionsColumn: ChiplistColumn<GeolocationRuleElement> = this.columnDefs.get('rule_actions');
    ruleActionsColumn.isEditable = !this.fixedTable;
    ruleActionsColumn.isRemovable = !this.fixedTable;

    const operatorColumn: CheckBoxColumn<GeolocationRuleElement> = this.columnDefs.get('operator');
    operatorColumn.isEditable = !this.fixedTable;

    const commentsColumn = this.columnDefs.get('comments');
    commentsColumn.isEditable = !this.fixedTable;
  }

  /**
   * Receives a GeolocationRuleElement or NestedPolicyGeolocationRuleElement from the table
   * then updates and saves the current rules list.
   */
  public updateEvent(updatedRule: GeolocationRuleElement | NestedPolicyGeolocationRuleElement): void {
    if (this.usePolicyRulesVersion()) {
      // Is a Policy rule
      const updatedRuleAsNestedPolicyGeolocationRuleElement = updatedRule as NestedPolicyGeolocationRuleElement;
      // In order to trigger enabling the expansion of a newly added row,
      // we need to reset the indicies of the table to remove the -1.
      // We only need to do this for parent rows, not nested rows,
      // since nested rows are not expandable.
      const resetTableIndicesOnSave =
        updatedRuleAsNestedPolicyGeolocationRuleElement.index === -1 &&
        updatedRuleAsNestedPolicyGeolocationRuleElement.parentId === undefined;
      this.savePolicyRules(resetTableIndicesOnSave);
    } else {
      this.saveApplicationConfigRules(updatedRule);
    }
  }

  private savePolicyRules(resetTableIndicesOnSave: boolean): void {
    const geolocationRuleConfigList = this.getRuleConfigListFromTableData();
    const allPolicyRuleConfigsList = [...geolocationRuleConfigList, ...this.nonGeolocationPolicyRuleConfigsList];
    this.policyTemplate.rules = allPolicyRuleConfigsList;
    const geolocationPolicyStructureList = getPolicyStructureFromTableData(this.tableData);
    const allRulesPolicyStructureList = [...geolocationPolicyStructureList, ...this.nonGeolocationPolicyStructuresList];
    this.policyTemplate.policy_structure = allRulesPolicyStructureList;
    this.policyTemplateInstanceResourceCopy.spec.template = this.policyTemplate;
    this.store.dispatch(
      savingPolicyTemplateInstance({
        obj: this.policyTemplateInstanceResourceCopy,
        trigger_update_side_effects: false,
        notifyUser: true,
      })
    );
    if (resetTableIndicesOnSave) {
      // We do not want to refresh the entire table/api data since that would
      // reorder the table rows. Therefore, we just need to reset the indicies
      // to enable the expansion of a newly added row without reordering the rows.
      this.resetTableIndicesAfterNewRowAdded();
    }
  }

  private saveApplicationConfigRules(updatedRule: GeolocationRuleElement): void {
    updatedRule.extended_condition.condition.value = updatedRule.value;
    const rule: RuleConfig = {
      name: updatedRule.name,
      priority: Number(updatedRule.priority),
      extended_condition: updatedRule.extended_condition,
      comments: updatedRule.comments,
      scope: updatedRule.scope,
      actions: updatedRule.rule_actions,
    };
    if (updatedRule.index === -1) {
      if (!this.currentApplicationCopy.rules_config) {
        this.currentApplicationCopy.rules_config = { rules: [rule] };
      } else if (!this.currentApplicationCopy.rules_config.rules) {
        this.currentApplicationCopy.rules_config.rules = [rule];
      } else {
        this.currentApplicationCopy.rules_config.rules.unshift(rule);
      }
      this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
      return;
    }
    this.currentApplicationCopy.rules_config.rules[updatedRule.objectIndex] = rule;
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy));
  }

  private getRuleConfigListFromTableData(): Array<RuleConfig> {
    const geolocationRuleConfigList: Array<RuleConfig> = [];
    for (const element of this.tableData) {
      const ruleCopy = this.getRuleConfigFromTableElement(element);
      geolocationRuleConfigList.push(ruleCopy);
      const nestedTableData: Array<NestedPolicyGeolocationRuleElement> = element.expandedData.nestedTableData;
      for (const nestedRuleElement of nestedTableData) {
        const nestedRuleCopy = this.getRuleConfigFromTableElement(nestedRuleElement);
        geolocationRuleConfigList.push(nestedRuleCopy);
      }
    }
    return [...geolocationRuleConfigList, ...this.nonGeolocationPolicyRules];
  }

  private getRuleConfigFromTableElement(element: GeolocationRuleElement): RuleConfig {
    const ruleCopy = cloneDeep(element.backingRule);
    const condition = ruleCopy.extended_condition.condition as SourceISOCountryCodeCondition;
    condition.operator = element.extended_condition.condition.operator;
    condition.value = element.value;
    ruleCopy.name = element.name;
    ruleCopy.scope = element.scope;
    ruleCopy.actions = element.rule_actions;
    ruleCopy.comments = element.comments;
    ruleCopy.priority = parseInt(element.priority as string, 10);
    return ruleCopy;
  }

  private getParentElementFromParentId(nestedElement: NestedPolicyGeolocationRuleElement): GeolocationRuleElement | undefined {
    return this.tableData[nestedElement.parentId];
  }

  private deleteFromPolicyRulesParentTable(): void {
    this.tableData = this.tableData.filter((element) => !element.isChecked);
  }

  private deleteFromPolicyRulesNestedTable(nestedElement: NestedPolicyGeolocationRuleElement): void {
    const parentElement = this.getParentElementFromParentId(nestedElement);
    parentElement.expandedData.nestedTableData = parentElement.expandedData.nestedTableData.filter((element) => !element.isChecked);
  }

  private deleteSelectedPolicyRules(itemsToDelete: Array<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>): void {
    const elementAsNestedPolicyRuleElement = itemsToDelete[0] as NestedPolicyGeolocationRuleElement;
    if (elementAsNestedPolicyRuleElement.parentId !== undefined && elementAsNestedPolicyRuleElement.parentId !== null) {
      this.deleteFromPolicyRulesNestedTable(elementAsNestedPolicyRuleElement);
    } else {
      this.deleteFromPolicyRulesParentTable();
    }
    this.savePolicyRules(false);
  }

  private deleteApplicationConfigRules(itemsToDelete: Array<GeolocationRuleElement>): void {
    itemsToDelete.sort((a, b) => b.objectIndex - a.objectIndex);
    for (const ruleToDelete of itemsToDelete) {
      if (ruleToDelete.index === -1) {
        continue;
      }
      this.currentApplicationCopy.rules_config.rules.splice(ruleToDelete.objectIndex, 1);
    }
    this.store.dispatch(new ActionApiApplicationsModifyCurrentApp(this.currentApplicationCopy, false, true));
  }

  public deleteSelected(rulesToDelete: Array<GeolocationRuleElement | NestedPolicyGeolocationRuleElement>): void {
    if (this.usePolicyRulesVersion()) {
      // Is a Policy rule
      this.deleteSelectedPolicyRules(rulesToDelete);
    } else {
      this.deleteApplicationConfigRules(rulesToDelete);
    }
  }

  private resetTableIndicesAfterNewRowAdded(): void {
    for (const element of this.tableData) {
      const newIndex = element.index + 1;
      element.index = newIndex;
      element.expandedData.parentId = newIndex;
      for (const nestedElement of element.expandedData.nestedTableData) {
        const nestedElementAsNestedPolicyGeolocationRuleElement = nestedElement as NestedPolicyGeolocationRuleElement;
        nestedElementAsNestedPolicyGeolocationRuleElement.parentId = newIndex;
      }
    }
  }

  public usePolicyRulesVersion(): boolean {
    return !!this.policyTemplate;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTables(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

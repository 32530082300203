<h3 class="usage-metrics-heading">Usage Metrics</h3>

  <div *ngIf="hasPermissions !== undefined && !hasPermissions">
    <portal-no-permissions restrictionType="section"></portal-no-permissions>
  </div>

  <div *ngIf="hasPermissions">
    <portal-table-layout
      #tableLayoutComp
      [tableData]="tableData"
      [columnDefs]="columnDefs"
      [filterManager]="filterManager"
      [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
      [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
      [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
      [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
      [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
    >
    </portal-table-layout>
  </div>
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { SupportRequestElement } from '../support-requests-overview/support-requests-overview.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { getTwentyFourHourArray } from '../utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

export interface SupportRequestResetExpiryDialogData {
  supportRequestElement: SupportRequestElement;
}

@Component({
  selector: 'portal-support-request-reset-expiry-dialog',
  templateUrl: './support-request-reset-expiry-dialog.component.html',
  styleUrl: './support-request-reset-expiry-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportRequestResetExpiryDialogComponent implements OnInit {
  public supportRequestElement: SupportRequestElement;
  public keyTabManager: KeyTabManager = new KeyTabManager();
  public resetExpiryForm: UntypedFormGroup;

  public getTwentyFourHourArray = getTwentyFourHourArray;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: SupportRequestResetExpiryDialogData,
    private dialogRef: MatDialogRef<SupportRequestResetExpiryDialogData>,
    private formBuilder: UntypedFormBuilder
  ) {
    this.supportRequestElement = data.supportRequestElement;
    this.supportRequestElement.supporting_user_email;
  }

  public ngOnInit(): void {
    this.initializeFormGroup();
  }

  private initializeFormGroup(): void {
    this.resetExpiryForm = this.formBuilder.group({
      reset_expiry: undefined,
    });
  }

  public onResetExpiryChange(expiryHours: number): void {
    this.supportRequestElement.reset_expiry = expiryHours;
  }

  public onConfirmClick(): void {
    if (!this.supportRequestElement.reset_expiry) {
      this.supportRequestElement.reset_expiry = 24;
    }
    this.dialogRef.close(this.supportRequestElement);
  }
}

import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'portal-path-validation-dialog',
  templateUrl: './path-validation-dialog.component.html',
  styleUrls: ['./path-validation-dialog.component.scss'],
})
export class PathValidationDialogComponent {
  public correctedInput: string;

  constructor(
    public dialogRef: MatDialogRef<PathValidationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { originalInput: string }
  ) {
    this.correctedInput = this.data.originalInput.replace(/["']/g, '');
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onConfirm(): void {
    this.dialogRef.close(this.correctedInput);
  }
}

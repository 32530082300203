<div *ngIf="!fixedData">
  <portal-page-info
    [pageDescriptiveText]="corsDescriptiveText"
    [productGuideLink]="webAppSecurityProductGuideLink"
    pageInfoWidth="full"
  >
  </portal-page-info>
  <div class="cors-template-buttons-container">
    <button
      *ngFor="let template of getCorsTemplateTypes()"
      mat-raised-button
      color="primary"
      class="cors-template-button"
      (click)="onCorsTemplateSelection(template)"
      [matTooltip]="getCorsTemplateTooltipText(template)"
      attr.aria-label="A button to apply a CORS template"
    >
      {{ template }}
    </button>
  </div>

  <div
    *ngIf="!!securityForm"
    class="form-container"
  >
    <form
      [formGroup]="securityForm"
      spellcheck="false"
      autocomplete="off"
    >
      <div class="checkbox-container">
        <mat-checkbox
          formControlName="enabled"
          (change)="onCheckboxChange()"
          aria-label="Checkbox that toggles whether CORS is enabled for the application"
        >
          Enable CORS
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          [matTooltip]="enableCorsTooltipText"
        >
          help_outline
        </mat-icon>
      </div>

      <div class="checkbox-container">
        <mat-checkbox
          formControlName="allow_credentials"
          (change)="onCheckboxChange()"
          aria-label="Checkbox that toggles whether credentials may be sent in requests"
        >
          Allow credentials
        </mat-checkbox>
        <mat-icon 
          class="help-icon" 
          matTooltip="Whether credentials may be sent in requests. This corresponds to the 'Access-Control-Allow-Credentials' header."
        >
          help_outline
        </mat-icon>
      </div>

      <div class="origin-matching-container">
        <div class="radio-button-label-container">
          <label
            id="origin-matching-radio-group-label"
            class="radio-button-group-label"
          >
            Origin matching:
          </label>
          <mat-icon 
            class="help-icon" 
            matTooltip="How to match the origin. Note that in all cases the \'allow_origins\' list will be consulted."
          >
            help_outline
          </mat-icon>
        </div>
        <mat-radio-group
          formControlName="origin_matching"
          aria-label="origin matching selection radio buttons"
          class="origin-matching-radio-group"
        >
          <div
            *ngFor="let value of getAllPossibleOriginMatchingEnumValues()"
            class="shared-line-radio-button"
          >
            <mat-radio-button
              class="origin-matching-radio-button"
              [value]="value"
              (change)="onOriginMatchingOptionChange(value)"
            >
              {{ capitalizeFirstLetter(value) }}
            </mat-radio-button>
            <mat-icon 
              class="help-icon" 
              [matTooltip]="getOriginMatchingTooltip(value)"
            >
              help_outline
            </mat-icon>
          </div>
        </mat-radio-group>
      </div>

      <mat-form-field class="extended-field">
        <mat-label>Max age (in seconds)</mat-label>
        <input
          matInput
          type="text"
          formControlName="max_age_seconds"
          (blur)="onFormBlur(securityForm, 'max_age_seconds')"
          (keyup.enter)="keyTabManager.keyTab($event.target.id)"
        />
        <mat-error>Max age is required</mat-error>
      </mat-form-field>
      <mat-icon 
        class="help-icon custom-help-icon" 
        matTooltip="This sets the 'Access-Control-Max-Age' which controls the maximum number of seconds for which the results of the CORS preflight check may be cached. -1 disables caching."
      >
        help_outline
      </mat-icon>
    </form>
  </div>

  <mat-accordion class="expansion-container" multi>
    <!-- Allow Origins Panel -->
    <mat-expansion-panel
      (opened)="onPanelOpen(corsPanel.ALLOW_ORIGINS)"
      (closed)="onPanelClose(corsPanel.ALLOW_ORIGINS)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Allow Origins
        </mat-panel-title>
        <mat-panel-description *ngIf="!getPanelState(corsPanel.ALLOW_ORIGINS)">
          Configure the origins allowed to access the resources of this application
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="getPanelState(corsPanel.ALLOW_ORIGINS)">
        <portal-cors-allow-origins
          [currentApplicationCopy]="currentApplicationCopy"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-cors-allow-origins>
      </div>
    </mat-expansion-panel>

    <!-- Allow Methods Panel -->
    <mat-expansion-panel
      (opened)="onPanelOpen(corsPanel.ALLOW_METHODS)"
      (closed)="onPanelClose(corsPanel.ALLOW_METHODS)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Allow Methods
        </mat-panel-title>
        <mat-panel-description *ngIf="!getPanelState(corsPanel.ALLOW_METHODS)">
          Configure the methods for which to allow access to resources
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="getPanelState(corsPanel.ALLOW_METHODS)">
        <portal-cors-allow-methods
          [currentApplicationCopy]="currentApplicationCopy"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-cors-allow-methods>
      </div>
    </mat-expansion-panel>

    <!-- Allow Headers Panel -->
    <mat-expansion-panel
      (opened)="onPanelOpen(corsPanel.ALLOW_HEADERS)"
      (closed)="onPanelClose(corsPanel.ALLOW_HEADERS)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Allow Headers
        </mat-panel-title>
        <mat-panel-description *ngIf="!getPanelState(corsPanel.ALLOW_HEADERS)">
          Configure the headers which may be sent in a request to resources from this application
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="getPanelState(corsPanel.ALLOW_HEADERS)">
        <portal-cors-allow-headers
          [currentApplicationCopy]="currentApplicationCopy"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-cors-allow-headers>
      </div>
    </mat-expansion-panel>

    <!-- Expose Headers Panel -->
    <mat-expansion-panel
      (opened)="onPanelOpen(corsPanel.EXPOSE_HEADERS)"
      (closed)="onPanelClose(corsPanel.EXPOSE_HEADERS)"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          Expose Headers
        </mat-panel-title>
        <mat-panel-description *ngIf="!getPanelState(corsPanel.EXPOSE_HEADERS)">
          Configure the response headers which the javascript running in the browser may access for resources from this application
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div *ngIf="getPanelState(corsPanel.EXPOSE_HEADERS)">
        <portal-cors-expose-headers
          [currentApplicationCopy]="currentApplicationCopy"
          (updateApplication)="updateApplicationOnAppConfigsChange($event)"
        >
        </portal-cors-expose-headers>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
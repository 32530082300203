import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { combineLatest, concatMap, forkJoin, map, Observable, of, Subject, take, takeUntil } from 'rxjs';
import { TableElement } from '../table-layout/table-element';
import {
  ActionMenuOptions,
  Column,
  createActionsColumn,
  createCheckBoxColumn,
  createInputColumn,
  createReadonlyColumn,
  createSelectRowColumn,
  ReadonlyColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { PatchErrorImpl, SupportRequest, UsersService } from '@agilicus/angular';
import { InputSize } from '../custom-chiplist-input/input-size.enum';
import { addHoursToCurrentDate, convertDateToReadableFormat, sortArrayByMetadataCreatedDate } from '../date-utils';
import { FilterManager } from '../filter/filter-manager';
import { AppState, NotificationService } from '@app/core';
import { select, Store } from '@ngrx/store';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';
import { resetIndices, updateTableElements } from '../utils';
import { getDefaultNewRowProperties, getDefaultTableProperties, isTableDirty } from '../table-layout-utils';
import {
  createNewSupportRequest$,
  deleteExistingSupportRequest$,
  getSupportRequestDescriptiveText,
  getSupportRequests$,
  getSupportRequestsProductGuideLink,
  updateExistingSupportRequest$,
} from '@app/core/user/user.utils';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { checkIfUserEmailFieldValid } from '../validation-utils';
import { HttpErrorResponse } from '@angular/common/http';
import { createDialogData, getDefaultDialogConfig } from '../dialog-utils';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {
  SupportRequestResetExpiryDialogComponent,
  SupportRequestResetExpiryDialogData,
} from '../support-request-reset-expiry-dialog/support-request-reset-expiry-dialog.component';

export interface SupportRequestElement extends TableElement {
  supporting_user_email?: string;
  expiry?: Date;
  reset_expiry: number;
  viewer_only_permissions: boolean;
  backingObject: SupportRequest;
}

export interface CombinedPermissionsAndSupportRequestData {
  permission: OrgQualifiedPermission;
  supportRequests: Array<SupportRequest>;
}

@Component({
  selector: 'portal-support-requests-overview',
  templateUrl: './support-requests-overview.component.html',
  styleUrl: './support-requests-overview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportRequestsOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public hasUsersPermissions: boolean;
  public tableData: Array<SupportRequestElement> = [];
  public columnDefs: Map<string, Column<SupportRequestElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'SUPPORT REQUEST';
  public warnOnNOperations = 1;
  public pageDescriptiveText = getSupportRequestDescriptiveText();
  public productGuideLink = getSupportRequestsProductGuideLink();
  private supportRequests: Array<SupportRequest> = [];
  private editingTable = false;
  public updateDataCount = 0;
  public delayedUpdateDataCount = 0;
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  private dialogOpened = false;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<SupportRequestElement>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private usersService: UsersService,
    private notificationService: NotificationService,
    private appErrorHandler: AppErrorHandler,
    public errorDialog: MatDialog,
    public resetExpiryDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public hasAllPermissions(): boolean {
    return this.hasUsersPermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasUsersPermissions !== undefined && !this.hasAllPermissions();
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndSupportRequestData> {
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminUsers));
    return hasUsersPermissions$.pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        const orgId = hasPermissionsResp?.orgId;
        let supportRequests$: Observable<Array<SupportRequest> | undefined> = of(undefined);
        if (!!orgId && !!hasPermissionsResp?.hasPermission) {
          supportRequests$ = getSupportRequests$(this.usersService, orgId);
        }
        return combineLatest([of(hasPermissionsResp), supportRequests$]);
      }),
      map(([hasPermissionsResp, supportRequestsResp]: [OrgQualifiedPermission, Array<SupportRequest>]) => {
        const combinedPermissionsAndSupportRequests: CombinedPermissionsAndSupportRequestData = {
          permission: hasPermissionsResp,
          supportRequests: !!supportRequestsResp ? sortArrayByMetadataCreatedDate(supportRequestsResp) : supportRequestsResp,
        };
        return combinedPermissionsAndSupportRequests;
      })
    );
  }

  private getAndSetAllData(): void {
    this.getCombinedPermissionsAndData$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((combinedPermissionsAndSupportRequests) => {
        const hasUsersPermissionsResp = combinedPermissionsAndSupportRequests.permission;
        const supportRequestsResp = combinedPermissionsAndSupportRequests.supportRequests;
        this.orgId = hasUsersPermissionsResp?.orgId;
        this.hasUsersPermissions = hasUsersPermissionsResp?.hasPermission;
        if (!this.hasAllPermissions() || !supportRequestsResp) {
          this.resetEmptyTable();
          return;
        }
        this.supportRequests = supportRequestsResp;
        this.updateTable(this.supportRequests);
        this.changeDetector.detectChanges();
      });
  }

  private updateTable(data: Array<any>): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: Array<any>): void {
    const dataForTable: Array<SupportRequestElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: SupportRequest, index: number): SupportRequestElement {
    const data: SupportRequestElement = {
      ...getDefaultTableProperties(index),
      supporting_user_email: item.spec.supporting_user_email,
      expiry: item.spec.expiry,
      reset_expiry: undefined,
      viewer_only_permissions: !!item.spec.viewer_only_permissions,
      backingObject: item,
    };
    return data;
  }

  /**
   * Parent Table Column
   */
  private getEmailColumn(): ReadonlyColumn<SupportRequestElement> {
    const column = createInputColumn('supporting_user_email');
    column.displayName = 'User';
    column.requiredField = () => true;
    column.isEditable = true;
    column.isUnique = true;
    column.isValidEntry = (item: string) => {
      return checkIfUserEmailFieldValid(item);
    };
    column.disableField = (element: SupportRequestElement): boolean => {
      return !!element.backingObject.metadata;
    };
    column.inputSize = InputSize.TEXT_INPUT_LARGE;
    column.isRowIdentifier = true;
    return column;
  }

  /**
   * Parent Table Column
   */
  private getExpiryColumn(): ReadonlyColumn<SupportRequestElement> {
    const column = createReadonlyColumn('expiry');
    column.getDisplayValue = (elem: SupportRequestElement) => {
      const formattedDate = convertDateToReadableFormat(new Date(elem.expiry));
      return formattedDate === 'Invalid Date' ? undefined : formattedDate;
    };
    column.inputSize = InputSize.DATE;
    column.warnValue = (elem: SupportRequestElement) => {
      const currentDate = new Date();
      const expiryDate = new Date(elem.expiry);
      return expiryDate.getTime() - currentDate.getTime() <= 0;
    };
    column.getHeaderTooltip = () => {
      return `The support user will be granted access until this time`;
    };
    return column;
  }

  /**
   * Parent table column
   */
  // Disabled for now
  private getPermissionsColumn(): Column<SupportRequestElement> {
    const column = createCheckBoxColumn('viewer_only_permissions');
    column.displayName = `View Only Permissions`;
    column.isEditable = true;
    column.isChecked = (element: SupportRequestElement) => {
      return !!element.viewer_only_permissions;
    };
    column.setElementFromCheckbox = (element: SupportRequestElement, isBoxChecked: boolean): any => {
      element.viewer_only_permissions = isBoxChecked;
      element.backingObject.spec.viewer_only_permissions = isBoxChecked;
    };
    column.getHeaderTooltip = () => {
      return `Whether or not this support group is allowed to make modifications in this organisation. If selected, the support user is not allowed to make modifications.`;
    };
    return column;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<SupportRequestElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<SupportRequestElement>> = [
      {
        displayName: 'Reset Expiry',
        icon: 'more_time',
        tooltip: 'Click to update the expiry for this support request',
        onClick: (element: SupportRequestElement) => {
          const dialogData: SupportRequestResetExpiryDialogData = {
            supportRequestElement: element,
          };
          const dialogRef = this.resetExpiryDialog.open(
            SupportRequestResetExpiryDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: '750px',
            })
          );
          dialogRef.afterClosed().subscribe((element: SupportRequestElement | undefined) => {
            if (!!element) {
              this.putSupportRequest(element, true);
            }
          });
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getEmailColumn(),
        this.getExpiryColumn(),
        // Disabled for now
        // this.getPermissionsColumn()
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  public makeEmptyTableElement(): SupportRequestElement {
    return {
      ...getDefaultNewRowProperties(),
      supporting_user_email: '',
      expiry: undefined,
      reset_expiry: undefined,
      viewer_only_permissions: false,
      backingObject: {
        spec: {
          supporting_user_email: '',
          expiry: undefined,
          viewer_only_permissions: false,
          org_id: this.orgId,
        },
      },
    };
  }

  private getExpiryAsDate(updatedElement: SupportRequestElement): Date | undefined {
    return addHoursToCurrentDate(updatedElement.reset_expiry);
  }

  private getSupportRequestFromTableElement(updatedElement: SupportRequestElement): SupportRequest {
    let updatedSupportRequest: SupportRequest;
    if (!updatedElement.backingObject.metadata) {
      // Creating new:
      updatedSupportRequest = {
        spec: {
          org_id: this.orgId,
          supporting_user_email: updatedElement.supporting_user_email,
          viewer_only_permissions: updatedElement.viewer_only_permissions,
        },
      };
    } else {
      updatedSupportRequest = {
        ...updatedElement.backingObject,
        spec: {
          ...updatedElement.backingObject.spec,
          viewer_only_permissions: updatedElement.viewer_only_permissions,
        },
      };
    }
    const expiryAsDate = this.getExpiryAsDate(updatedElement);
    if (!!expiryAsDate) {
      updatedSupportRequest.spec.expiry = expiryAsDate;
    }
    return updatedSupportRequest;
  }

  private updateSupportRequestElement(supportRequestElement: SupportRequestElement, supportRequestResp: SupportRequest): void {
    supportRequestElement.backingObject = supportRequestResp;
    supportRequestElement.supporting_user_email = supportRequestResp.spec.supporting_user_email;
    supportRequestElement.expiry = supportRequestResp.spec.expiry;
    supportRequestElement.viewer_only_permissions = supportRequestResp.spec.viewer_only_permissions;
    supportRequestElement.isNew = false;
  }

  private updateDataIfNotEditing(skipDelay = false): void {
    if (!!skipDelay) {
      this.getAndSetAllData();
      return;
    }
    this.updateDataCount = this.updateDataCount + 1;
    setTimeout(() => {
      this.delayedUpdateDataCount = this.delayedUpdateDataCount + 1;
      if (this.editingTable || isTableDirty(this.tableData) || this.updateDataCount !== this.delayedUpdateDataCount) {
        return;
      }
      this.getAndSetAllData();
    }, 5000);
  }

  private openSupportUserErrorDialog(errorMessageText: string): void {
    let messagePrefix = 'Failed to Save';
    this.displayErrorDialog(messagePrefix, errorMessageText);
  }

  public displayErrorDialog(messagePrefix: string, message: string) {
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.buttonText = { confirm: '', cancel: 'Close' };
    const dialogRef = this.errorDialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });
    dialogRef.afterClosed().subscribe(() => {
      this.resetDialogOpenedToFalse();
    });
  }

  private resetDialogOpenedToFalse(): void {
    this.dialogOpened = false;
  }

  public postSupportRequest(updatedElement: SupportRequestElement): void {
    this.editingTable = true;
    const newSupportRequest = this.getSupportRequestFromTableElement(updatedElement);
    createNewSupportRequest$(this.usersService, newSupportRequest)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (supportRequestResp) => {
          this.notificationService.success(
            `Support request for "${supportRequestResp.spec.supporting_user_email}" was successfully created`
          );
          this.updateSupportRequestElement(updatedElement, supportRequestResp);
          resetIndices(this.tableData);
          this.editingTable = false;
          this.updateDataIfNotEditing();
          this.changeDetector.detectChanges();
        },
        (err: HttpErrorResponse | Error | PatchErrorImpl) => {
          if (err instanceof HttpErrorResponse) {
            if (!this.dialogOpened) {
              if (err.status === 404 && err.error.error_code === 'NOT_FOUND') {
                const errorMessage = `"${updatedElement.supporting_user_email}" is not an allowed support user`;
                this.openSupportUserErrorDialog(errorMessage);
                this.dialogOpened = true;
              } else if (err.status === 400 && err.error.error_code === 'UNIQUE_CONFLICT') {
                const errorMessage = `"${updatedElement.supporting_user_email}" already exists as a support user. Please visit the overview page (Organisation > Support Requests > Overview) to modify or delete this support user.`;
                this.openSupportUserErrorDialog(errorMessage);
                this.dialogOpened = true;
              }
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  private putSupportRequest(updatedElement: SupportRequestElement, skipDelay = false): void {
    this.editingTable = true;
    updateExistingSupportRequest$(this.usersService, this.getSupportRequestFromTableElement(updatedElement))
      .pipe(take(1))
      .subscribe(
        (supportRequestResp) => {
          this.notificationService.success(
            `Support request for "${supportRequestResp.spec.supporting_user_email}" was successfully updated`
          );
          this.updateSupportRequestElement(updatedElement, supportRequestResp);
          this.editingTable = false;
          this.updateDataIfNotEditing(skipDelay);
          this.changeDetector.detectChanges();
        },
        (errorResp) => {
          const baseMessage = `Failed to update support request for "${updatedElement.supporting_user_email}"`;
          this.appErrorHandler.handlePotentialConflict(errorResp, baseMessage, 'reload');
        }
      );
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: SupportRequestElement): void {
    if (!updatedElement.backingObject?.metadata?.id) {
      this.postSupportRequest(updatedElement);
    } else {
      this.putSupportRequest(updatedElement);
    }
  }

  private populateDeleteObservablesArray(supportRequestsToDelete: Array<SupportRequestElement>): Array<Observable<object>> {
    const observablesArray: Array<Observable<object>> = [];
    for (const supportRequest of supportRequestsToDelete) {
      if (supportRequest.isChecked && supportRequest.backingObject?.metadata?.id) {
        observablesArray.push(deleteExistingSupportRequest$(this.usersService, supportRequest.backingObject));
      }
    }
    return observablesArray;
  }

  public deleteSelected(itemsToDelete: Array<SupportRequestElement>): void {
    this.deleteSupportRequests(itemsToDelete);
  }

  public deleteSupportRequests(itemsToDelete: Array<SupportRequestElement>): void {
    const observablesArray = this.populateDeleteObservablesArray(itemsToDelete);
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('Support requests were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected support requests');
        },
        () => {
          this.getAndSetAllData();
        }
      );
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: number; column: Column<SupportRequestElement>; element: SupportRequestElement }): void {
    if (params.column.name === 'reset_expiry') {
      params.element.reset_expiry = params.value;
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    // We need to get the dataSource from the table rather than using the local tableData
    // since the tableData is not passed into the table layout when using the
    // paginatorConfig. The paginatorConfig subscribes to data updates from the api directly.
    const tableData = !!this.tableLayoutComp ? this.tableLayoutComp.getDataSourceData() : [];
    return canNavigateFromTable(tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

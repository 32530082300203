import {
  APIKey,
  APIKeySpec,
  CreateApiKeyRequestParams,
  GetApiKeyRequestParams,
  ListApiKeysRequestParams,
  ListAPIKeysResponse,
  ListUsersResponse,
  patch_via_put,
  ReplaceApiKeyRequestParams,
  TokensService,
  User,
  UsersService,
} from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { AppState, NotificationService } from '@app/core';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminOrReadUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, takeUntil } from 'rxjs/operators';
import { convertCalendarPickerDateStringToDate, isValidDateString } from '../date-utils';
import { FilterManager } from '../filter/filter-manager';
import { OptionalAPIKeyElement } from '../optional-types';
import { getDefaultTableProperties } from '../table-layout-utils';
import {
  Column,
  createChipListColumn,
  createDatePickerColumn,
  createInputColumn,
  createSelectRowColumn,
  DatePickerColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { PaginatorActions, PaginatorConfig, UpdateTableParams } from '../table-paginator/table-paginator.component';
import { getEmptyStringIfUnset, updateTableElements } from '../utils';
import { ButtonType } from '../button-type.enum';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { selectCanAdminOrReadTokens } from '@app/core/user/permissions/tokens.selectors';
import { InputSize } from '../custom-chiplist-input/input-size.enum';

export interface APIKeyElement extends TableElement, APIKeySpec {
  id: string;
  expiryFormControl: FormControl;
  backingObject: APIKey;
}

export interface CombinedPermissionsAndData {
  permission: OrgQualifiedPermission;
  apiKeys: ListAPIKeysResponse;
  users: ListUsersResponse;
}

@Component({
  selector: 'portal-api-key-overview',
  templateUrl: './api-key-overview.component.html',
  styleUrls: ['./api-key-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiKeyOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasUsersPermissions: boolean;
  public hasTokensPermissions: boolean;
  private orgId: string;
  public tableData: Array<APIKeyElement> = [];
  public columnDefs: Map<string, Column<APIKeyElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public rowObjectName = 'API KEY';
  public usersList: Array<User> = [];
  private userIdToUserMap: Map<string, User> = new Map();
  private userEmailToUserMap: Map<string, User> = new Map();
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public fixedTable = false;
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate(['/api-key-new'], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];
  public pageDescriptiveText = `API keys are credentials which may be used to access resources or the Agilicus API without need for an explicit login. Use them for cases where the client supports basic (username/password) authentication, but not an OpenID Connect login, or where you need to run a process unattended, but cannot use a Service Account. Treat your API key like a password. When using an API key, the username is the identity (i.e. email address) of the user to which the API key belongs, and the password is the secret value returned when the API key was created.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/api-key-management/`;
  public paginatorConfig = new PaginatorConfig<APIKeyElement>(true, true, 25, 5, new PaginatorActions<APIKeyElement>(), 'id', {
    previousKey: '',
    nextKey: '',
    previousWindow: [],
  });
  public combinedPermissionsAndData$: Observable<CombinedPermissionsAndData>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    private usersService: UsersService,
    private tokensService: TokensService,
    private appErrorHandler: AppErrorHandler,
    private router: Router
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    if (this.combinedPermissionsAndData$ === undefined) {
      // we are fetching data for the first time
      this.getPermissionsAndData();
      this.paginatorConfig.actions.updateTableSubject.pipe(takeUntil(this.unsubscribe$)).subscribe((params: UpdateTableParams) => {
        this.getPermissionsAndData(params.searchDirection, params.limit);
      });
    } else {
      // this case could happen when the org is changed - we don't want to subscribe to updateTableSubject again
      this.reloadFirstPage();
    }
  }

  private getCombinedPermissions$(): Observable<OrgQualifiedPermission> {
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminOrReadUsers));
    const hasTokensPermissions$ = this.store.pipe(select(selectCanAdminOrReadTokens));
    return combineLatest([hasUsersPermissions$, hasTokensPermissions$]).pipe(
      map(([hasUsersPermissionsResp, hasTokensPermissionsResp]) => {
        this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
        this.hasTokensPermissions = hasTokensPermissionsResp.hasPermission;
        const combinedPermissions: OrgQualifiedPermission = {
          orgId: hasUsersPermissionsResp.orgId,
          hasPermission: hasUsersPermissionsResp.hasPermission && hasTokensPermissionsResp.hasPermission,
        };
        return combinedPermissions;
      })
    );
  }

  private getCombinedPermissionsAndData$(
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): Observable<CombinedPermissionsAndData> {
    return this.getCombinedPermissions$().pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasPermissionsResp?.orgId;
        let apiKeys$: Observable<ListAPIKeysResponse>;
        let users$: Observable<ListUsersResponse>;
        const today = new Date();
        const tenYearsLater = new Date(today);
        tenYearsLater.setFullYear(today.getFullYear() + 10);
        if (!!this.orgId) {
          const listApiKeysRequestParams: ListApiKeysRequestParams = {
            org_id: this.orgId,
            limit: limitParam,
            search_direction: searchDirectionParam,
            page_at_created_date: tenYearsLater,
            sort_order: 'descending',
          };
          apiKeys$ = this.tokensService.listApiKeys(listApiKeysRequestParams);
          users$ = this.usersService.listUsers({
            org_id: this.orgId,
            type: ['user', 'service_account'],
          });
        }
        return combineLatest([of(hasPermissionsResp), apiKeys$, users$]);
      }),
      map(([hasPermissionsResp, apiKeysResp, usersResp]: [OrgQualifiedPermission, ListAPIKeysResponse, ListUsersResponse]) => {
        const combinedPermissionsAndData: CombinedPermissionsAndData = {
          permission: hasPermissionsResp,
          apiKeys: apiKeysResp,
          users: usersResp,
        };
        return combinedPermissionsAndData;
      })
    );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setUserMaps(): void {
    this.userIdToUserMap.clear();
    this.userEmailToUserMap.clear();
    for (const user of this.usersList) {
      this.userIdToUserMap.set(user.id, user);
      this.userEmailToUserMap.set(user.email, user);
    }
  }

  private getNameColumn(): Column<APIKeyElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.requiredField = () => true;
    nameColumn.getHeaderTooltip = () => {
      return `A meaningful name for the API key. 
        Use this to identify its purpose.`;
    };
    return nameColumn;
  }

  private getUserIdColumn(): Column<APIKeyElement> {
    const userIdColumn = createInputColumn('User');
    userIdColumn.inputSize = InputSize.TEXT_INPUT_LARGE;
    userIdColumn.isRowIdentifier = true;
    userIdColumn.isEditable = false;
    userIdColumn.getDisplayValue = (element: OptionalAPIKeyElement) => {
      if (!!element?.user_id) {
        return this.userIdToUserMap.get(element.user_id)?.email;
      }
      return this.userIdToUserMap.get(element as string)?.email;
    };
    return userIdColumn;
  }

  private getExpiryColumn(): DatePickerColumn<APIKeyElement> {
    const expiryColumn = createDatePickerColumn('expiryFormControl');
    expiryColumn.displayName = 'Expiry date';
    expiryColumn.inputSize = InputSize.TEXT_INPUT_LARGE;
    expiryColumn.getDisplayValue = (element: OptionalAPIKeyElement): string => {
      if (!element?.expiry) {
        return '';
      }
      return element.expiry.toString();
    };
    expiryColumn.isValidEntry = (entry: string) => {
      return isValidDateString(entry);
    };
    expiryColumn.warnValue = (elem: APIKeyElement) => {
      const currentDate = new Date();
      const expiryDate = new Date(elem.expiry);
      return expiryDate.getTime() - currentDate.getTime() <= 0;
    };
    expiryColumn.getHeaderTooltip = () => {
      return `Please select a date from the calendar or enter text in the format of "YYYY-MM-DD". If left blank, the key does not expire.`;
    };
    return expiryColumn;
  }

  private getScopesColumn(): Column<APIKeyElement> {
    const scopesColumn = createChipListColumn('scopes');
    scopesColumn.isReadOnly = () => true;
    scopesColumn.isEditable = false;
    scopesColumn.isRemovable = false;
    scopesColumn.getHeaderTooltip = () => {
      return `The list of scopes requested for the API Key, for example, "urn:agilicus:users". 
        An optional scope is specified with an "?" at the end. 
        Optional scopes are used when the permission is requested but not required, for example, "urn:agilicus:users?". 
        A non-optional scope will cause creation of this API Key to fail if the user does not have that permission in this organisation.`;
    };
    return scopesColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [createSelectRowColumn(), this.getUserIdColumn(), this.getNameColumn(), this.getScopesColumn(), this.getExpiryColumn()],
      this.columnDefs
    );
  }

  public getPermissionsAndData(
    searchDirectionParam: 'forwards' | 'backwards' = 'forwards',
    limitParam = this.paginatorConfig.getQueryLimit()
  ): void {
    this.combinedPermissionsAndData$ = this.getCombinedPermissionsAndData$(searchDirectionParam, limitParam);
    this.combinedPermissionsAndData$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (combinedPermissionsAndDataResp) => {
        if (!combinedPermissionsAndDataResp?.apiKeys || !combinedPermissionsAndDataResp?.users) {
          return;
        }
        this.usersList = combinedPermissionsAndDataResp.users.users;
        this.setUserMaps();
        this.updateTable(combinedPermissionsAndDataResp.apiKeys, searchDirectionParam, limitParam);
      },
      (err) => {
        this.paginatorConfig.actions.errorHandler(err);
      }
    );
  }

  private updateTable(data: ListAPIKeysResponse, searchDirectionParam: 'forwards' | 'backwards', limitParam: number): void {
    this.buildData(data, searchDirectionParam, limitParam);
    this.replaceTableWithCopy();
  }

  private buildData(data: ListAPIKeysResponse, searchDirectionParam: 'forwards' | 'backwards', limitParam: number): void {
    const dataForTable: Array<APIKeyElement> = [];
    for (let i = 0; i < data.api_keys.length; i++) {
      const item = data.api_keys[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
    this.paginatorConfig.actions.dataFetched({
      data: this.tableData,
      searchDirection: searchDirectionParam,
      limit: limitParam,
      nextKey: data.next_api_key_id,
      previousKey: data.previous_api_key_id,
    });
  }

  private createTableElement(item: APIKey, index: number): APIKeyElement {
    const data: APIKeyElement = {
      id: item.metadata.id,
      name: getEmptyStringIfUnset(item.spec.name),
      label: getEmptyStringIfUnset(item.spec.label),
      org_id: item.spec.org_id,
      user_id: item.spec.user_id,
      expiry: !!item.spec.expiry ? item.spec.expiry : undefined,
      session: getEmptyStringIfUnset(item.spec.session),
      scopes: item.spec.scopes,
      backingObject: item,
      expiryFormControl: new FormControl(),
      ...getDefaultTableProperties(index),
    };
    data.expiryFormControl.setValue(item.spec.expiry);
    return data;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(updatedElement: APIKeyElement): void {
    this.saveItem(updatedElement);
  }

  public updateDatePickerInput(params: { optionValue: string; column: DatePickerColumn<APIKeyElement>; element: APIKeyElement }): void {
    if (params.column.name !== 'expiryFormControl') {
      return;
    }
    const optionValueAsDate = convertCalendarPickerDateStringToDate(params.optionValue);
    if (!!optionValueAsDate && optionValueAsDate.toString() === params.element.expiry?.toString()) {
      // The value has not been changed.
      return;
    }
    if (params.optionValue === '') {
      if (!params.element.expiry) {
        return;
      }
      params.element.expiry = undefined;
    } else {
      params.element.expiry = optionValueAsDate;
    }
    params.element.dirty = true;
  }

  private getItemFromTableElement(tableElement: APIKeyElement): APIKey {
    const result: APIKey = tableElement.backingObject;
    result.spec.name = tableElement.name;
    result.spec.label = tableElement.label;
    result.spec.user_id = tableElement.user_id;
    result.spec.session = tableElement.session;
    result.spec.scopes = tableElement.scopes;
    result.spec.expiry = tableElement.expiry;
    return result;
  }

  private postItem(itemToCreate: APIKey): Observable<APIKey> {
    const createRequestParams: CreateApiKeyRequestParams = {
      APIKey: itemToCreate,
    };
    return this.tokensService.createApiKey(createRequestParams);
  }

  private getSuccessMessageText(apiKey: APIKey, action: 'created' | 'updated'): string {
    let successMessage = 'API key ';
    if (!!apiKey.spec.name) {
      successMessage += `"${apiKey.spec.name}" `;
    }
    successMessage += `was successfully ${action}`;
    return successMessage;
  }

  private getErrorMessageText(apiKeyElement: APIKeyElement, action: 'create' | 'update'): string {
    let errorMessage = `Failed to ${action} API key`;
    if (!!apiKeyElement.backingObject.spec.name) {
      errorMessage += ` "${apiKeyElement.backingObject.spec.name}"`;
    }
    return errorMessage;
  }

  private createNewItem(newTableElement: APIKeyElement): void {
    const newItem = this.getItemFromTableElement(newTableElement);
    this.postItem(newItem).subscribe(
      (postResp) => {
        this.notificationService.success(this.getSuccessMessageText(postResp, 'created'));
      },
      (errorResp) => {
        const baseErrorMessage = this.getErrorMessageText(newTableElement, 'create');
        this.appErrorHandler.handlePotentialConflict(errorResp, baseErrorMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private putItem(itemToUpdate: APIKey): Observable<APIKey> {
    const getter = (item: APIKey) => {
      const getRequestParams: GetApiKeyRequestParams = {
        api_key_id: item.metadata.id,
        org_id: this.orgId,
      };
      return this.tokensService.getApiKey(getRequestParams);
    };
    const putter = (item: APIKey) => {
      const replaceRequestParams: ReplaceApiKeyRequestParams = {
        api_key_id: item.metadata.id,
        APIKey: item,
      };
      return this.tokensService.replaceApiKey(replaceRequestParams);
    };
    return patch_via_put(itemToUpdate, getter, putter);
  }

  private updateExistingItem(updatedTableElement: APIKeyElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    this.putItem(updatedItem).subscribe(
      (putResp) => {
        this.notificationService.success(this.getSuccessMessageText(putResp, 'updated'));
      },
      (errorResp) => {
        const baseErrorMessage = this.getErrorMessageText(updatedTableElement, 'update');
        this.appErrorHandler.handlePotentialConflict(errorResp, baseErrorMessage, 'reload');
      },
      () => {
        this.getPermissionsAndData();
      }
    );
  }

  private saveItem(tableElement: APIKeyElement): void {
    if (tableElement.index === -1) {
      this.createNewItem(tableElement);
    } else {
      this.updateExistingItem(tableElement);
    }
  }

  public deleteSelected(itemsToDelete: Array<APIKeyElement>): void {
    const observablesArray: Array<Observable<object>> = [];
    for (const item of itemsToDelete) {
      if (item.index === -1) {
        continue;
      }
      observablesArray.push(
        this.tokensService.deleteApiKey({
          api_key_id: item.backingObject.metadata.id,
          org_id: this.orgId,
        })
      );
    }
    forkJoin(observablesArray)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          this.notificationService.success('API keys were successfully deleted');
        },
        (errorResp) => {
          this.notificationService.error('Failed to delete all selected API keys');
        },
        () => {
          this.getPermissionsAndData();
        }
      );
  }

  public hasAllPermissions(): boolean {
    return this.hasUsersPermissions && this.hasTokensPermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasUsersPermissions !== undefined && this.hasTokensPermissions !== undefined && !this.hasAllPermissions();
  }

  public reloadFirstPage(): void {
    // reload table from the first page
    this.paginatorConfig.actions.reloadFirstPage();
  }
}

<portal-page-info
  pageTitle="Labels"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="showNoPermissionsText()">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasAllPermissions()" 
  class="main-container"
>
  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [fixedTable]="fixedTable"
    [rowObjectName]="rowObjectName"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [sortDataBy]="sortDataBy"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="deleteSelected($event)"
  >
  </portal-table-layout>
</div>

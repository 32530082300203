import { Component, OnInit, ChangeDetectionStrategy, OnChanges, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import { AppState } from '@app/core';
import {
  ActionApiApplicationsDeletingEnvConfig,
  ActionApiApplicationsSavingEnvConfig,
} from '@app/core/api-applications/api-applications.actions';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { select, Store } from '@ngrx/store';
import { Environment, EnvironmentConfig } from '@agilicus/angular';
import { Observable, Subject } from 'rxjs';
import { ButtonType } from '../button-type.enum';
import { FilterManager } from '../filter/filter-manager';
import { OptionalTemporaryDirectoryElement } from '../optional-types';
import { Column, createInputColumn, createSelectRowColumn } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { updateTableElements } from '../utils';
import { cloneDeep } from 'lodash-es';
import { takeUntil } from 'rxjs/operators';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '@app/core/auth/auth-guard-utils';

export interface TemporaryDirectoryElement extends EnvironmentConfig, TableElement {}

@Component({
  selector: 'portal-application-environment-temporary-directories',
  templateUrl: './application-environment-temporary-directories.component.html',
  styleUrls: ['./application-environment-temporary-directories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApplicationEnvironmentTemporaryDirectoriesComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public fixedTable = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public columnDefs: Map<string, Column<TemporaryDirectoryElement>> = new Map();
  private appState$: Observable<ApiApplicationsState>;
  private currentEnv: Environment;
  public tableData: Array<TemporaryDirectoryElement> = [];
  public rowObjectName = 'TEMPORARY DIRECTORY';
  public filterManager: FilterManager = new FilterManager();
  public buttonsToShow: Array<string> = [ButtonType.ADD, ButtonType.DELETE];
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  constructor(private store: Store<AppState>, private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.appState$ = this.store.pipe(select(selectApiApplications));
    this.appState$.pipe(takeUntil(this.unsubscribe$)).subscribe((appStateResp) => {
      if (!appStateResp || !appStateResp.current_external_mounts_list || !appStateResp.current_environment) {
        this.resetEmptyTable();
        return;
      }
      this.currentEnv = appStateResp.current_environment;
      this.setEditableColumnDefs();
      const temporaryDirectoriesList = appStateResp.current_external_mounts_list.filter(
        (externalMount) => externalMount.config_type === EnvironmentConfig.ConfigTypeEnum.mount_tmpdir
      );
      // Need to make a copy since we cannot modify the readonly data from the store.
      this.updateTable(cloneDeep(temporaryDirectoriesList));
    });
  }

  public ngOnChanges(): void {
    this.setEditableColumnDefs();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initializeColumnDefs(): void {
    const selectRowColumn = createSelectRowColumn();

    const mountPathColumn = createInputColumn('mount_path');
    mountPathColumn.displayName = 'Destination';
    mountPathColumn.requiredField = () => true;
    mountPathColumn.isUnique = true;
    mountPathColumn.isCaseSensitive = true;

    // Set the key/values for the column definitions map
    this.columnDefs.set(selectRowColumn.name, selectRowColumn);
    this.columnDefs.set(mountPathColumn.name, mountPathColumn);
  }

  private setEditableColumnDefs(): void {
    if (this.columnDefs.size === 0) {
      return;
    }
    const selectRowColumn = this.columnDefs.get('selectRow');
    selectRowColumn.showColumn = !this.fixedTable;

    const mountPathColumn = this.columnDefs.get('mount_path');
    mountPathColumn.isEditable = !this.fixedTable;
  }

  private updateTable(envConfigList: Array<EnvironmentConfig>): void {
    this.buildData(envConfigList);
    this.replaceTableWithCopy();
  }

  private buildData(envConfigList: Array<EnvironmentConfig>): void {
    const data: Array<TemporaryDirectoryElement> = [];
    for (let i = 0; i < envConfigList.length; i++) {
      data.push(this.createTemporaryDirectoryElement(envConfigList[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createTemporaryDirectoryElement(envConfig: EnvironmentConfig, index: number): TemporaryDirectoryElement {
    const data: TemporaryDirectoryElement = {
      ...getDefaultTableProperties(index),
      ...envConfig,
    };
    return data;
  }

  private makeEmptyTableElement(params?: OptionalTemporaryDirectoryElement): TemporaryDirectoryElement {
    const element = {
      maintenance_org_id: this.currentEnv.maintenance_org_id,
      config_type: EnvironmentConfig.ConfigTypeEnum.mount_tmpdir,
      ...getDefaultNewRowProperties(),
      ...params,
    };
    return element;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  /**
   * Receives an TemporaryDirectoryElement from the table then saves the current_external_mounts_list.
   */
  public updateEvent(updatedTemporaryDirectoryElement: TemporaryDirectoryElement): void {
    this.saveTemporaryDirectory(updatedTemporaryDirectoryElement);
  }

  private saveTemporaryDirectory(updatedTemporaryDirectoryElement: TemporaryDirectoryElement): void {
    // Need to make a copy since we cannot later modify the readonly data from the store.
    const updatedTemporaryDirectoryElementCopy: TemporaryDirectoryElement = cloneDeep(updatedTemporaryDirectoryElement);
    this.store.dispatch(new ActionApiApplicationsSavingEnvConfig(updatedTemporaryDirectoryElementCopy));
  }

  public deleteSelected(temporaryDirectoryToDelete: Array<TemporaryDirectoryElement>): void {
    if (temporaryDirectoryToDelete.length === 1 && temporaryDirectoryToDelete[0].isNew) {
      this.tableData = this.tableData.filter((element) => !element.isNew);
      return;
    }
    for (const temporaryDirectory of temporaryDirectoryToDelete) {
      if (temporaryDirectory.isNew) {
        continue;
      }
      this.deleteTemporaryDirectory(temporaryDirectory);
    }
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }

  private deleteTemporaryDirectory(temporaryDirectoryToDelete: TemporaryDirectoryElement): void {
    // Need to make a copy of the temporaryDirectoryToDelete or it will be converted to readonly.
    this.store.dispatch(new ActionApiApplicationsDeletingEnvConfig(cloneDeep(temporaryDirectoryToDelete)));
  }
}

import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnChanges, OnDestroy } from '@angular/core';
import { Group, PolicyCondition, PolicyRule } from '@agilicus/angular';
import { FilterManager } from '../filter/filter-manager';
import { OptionalPolicyConditionElement } from '../optional-types';
import {
  ActionMenuOptions,
  Column,
  createActionsColumn,
  createInputColumn,
  createSelectRowColumn,
  InputColumn,
  SelectColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import {
  createEnumChecker,
  getEmptyStringIfUnset,
  removeFromBeginningOfString,
  replaceCharacterWithSpace,
  updateTableElements,
} from '../utils';
import { cloneDeep } from 'lodash-es';
import { Store } from '@ngrx/store';
import { AppState, NotificationService } from '@app/core';
import { ActionPolicySavingPolicyRule } from '@app/core/issuer/issuer.actions';
import { DropdownSelector } from '../dropdown-selector/dropdown-selector-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { Observable } from 'rxjs';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { isJson } from '../file-utils';
import { MatDialog } from '@angular/material/dialog';
import { PolicyConditionDialogComponent, PolicyConditionDialogData } from '../policy-condition-dialog/policy-condition-dialog.component';
import { getDefaultDialogConfig } from '../dialog-utils';
import { ButtonType } from '../button-type.enum';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { convert_value_type, isAttributeAList, PolicyConditionElement, setConditionTypeToDisplayValueMap } from '../policy-utils';

@Component({
  selector: 'portal-auth-issuer-policy-rule-conditions',
  templateUrl: './auth-issuer-policy-rule-conditions.component.html',
  styleUrls: ['./auth-issuer-policy-rule-conditions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthIssuerPolicyRuleConditionsComponent implements OnChanges, OnDestroy {
  @Input() public currentPolicyRule: PolicyRule;
  @Input() public dropdownSelector: DropdownSelector;
  @Input() public groupIdToGroupMap: Map<string, Group> = new Map();
  @Input() public groupDisplayNameToGroupMap: Map<string, Group> = new Map();
  public currentPolicyRuleCopy: PolicyRule;
  public columnDefs: Map<string, Column<PolicyConditionElement>> = new Map();
  public tableData: Array<PolicyConditionElement> = [];
  public rowObjectName = 'CONDITION';
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  public conditionTypeToDisplayValueMap: Map<PolicyCondition.ConditionTypeEnum, string> = new Map();
  public hideFilter = true;
  public buttonsToShow: Array<string> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      'ADD CONDITION',
      ButtonColor.PRIMARY,
      'Add a new policy condition',
      'Button that adds a new policy condition',
      () => {
        this.openAddDialog();
      }
    ),
  ];

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private notificationService: NotificationService,
    public dialog: MatDialog
  ) {
    setConditionTypeToDisplayValueMap(this.conditionTypeToDisplayValueMap);
  }

  public ngOnChanges(): void {
    if (!this.currentPolicyRule) {
      this.resetEmptyTable();
      return;
    }
    this.currentPolicyRuleCopy = cloneDeep(this.currentPolicyRule);
    if (!!this.tableData && this.tableData.length !== 0 && this.tableData[0].index === -1 && this.tableData[0].value === '') {
      // We need to prevent the table from refreshing when the policy is changed while a new and
      // unsubmitted condition is being created. Otherwise, the new row will be removed on refresh of the state.
      return;
    }
    this.initializeColumnDefs();
    this.updateTable();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private getConditionTypeColumn(): SelectColumn<PolicyConditionElement> {
    const conditionTypeColumn = createInputColumn('condition_type');
    conditionTypeColumn.displayName = 'Condition Type';
    conditionTypeColumn.isEditable = false;
    conditionTypeColumn.getDisplayValue = (policyConditionElem: OptionalPolicyConditionElement) => {
      const isConditionTypeEnum = createEnumChecker(PolicyCondition.ConditionTypeEnum);
      if (isConditionTypeEnum(policyConditionElem.condition_type)) {
        return this.formatConditionTypeForDisplay(policyConditionElem.condition_type);
      }
      return '';
    };
    return conditionTypeColumn;
  }

  private getOperatorColumn(): SelectColumn<PolicyConditionElement> {
    const operatorColumn = createInputColumn('operator');
    operatorColumn.isEditable = false;
    operatorColumn.getDisplayValue = (policyConditionElem: OptionalPolicyConditionElement) => {
      const isOperatorEnum = createEnumChecker(PolicyCondition.OperatorEnum);
      if (isOperatorEnum(policyConditionElem.operator)) {
        return replaceCharacterWithSpace(policyConditionElem.operator, '_');
      }
      return '';
    };
    return operatorColumn;
  }

  private getValueColumn(): InputColumn<PolicyConditionElement> {
    const valueColumn = createInputColumn('value');
    valueColumn.requiredField = () => false;
    valueColumn.isEditable = false;
    valueColumn.isCaseSensitive = true;
    valueColumn.getDisplayValue = (policyConditionElem: OptionalPolicyConditionElement) => {
      const valueAsArray = isJson(policyConditionElem.value) ? JSON.parse(policyConditionElem.value) : [policyConditionElem.value];
      if (policyConditionElem.field === 'user_member_of.id') {
        // Convert the group ids to display names:
        const targetGroupIdsList = valueAsArray;
        let groupDisplayNamesList = [];
        for (const groupId of targetGroupIdsList) {
          const targetGroup = this.groupIdToGroupMap.get(groupId);
          groupDisplayNamesList.push(targetGroup?.display_name ? targetGroup.display_name : groupId);
        }
        return groupDisplayNamesList.join(',');
      }
      return isJson(policyConditionElem.value) ? JSON.parse(policyConditionElem.value) : policyConditionElem.value;
    };
    return valueColumn;
  }

  private getFieldColumn(): SelectColumn<PolicyConditionElement> {
    const fieldColumn = createInputColumn('field');
    fieldColumn.isEditable = false;
    fieldColumn.getDisplayValue = (policyConditionElem: OptionalPolicyConditionElement) => {
      return this.formatFieldForDisplay(policyConditionElem.field);
    };
    fieldColumn.getHeaderTooltip = () => {
      return 'Field is only applicable when the condition type is set to "Object attribute"';
    };
    return fieldColumn;
  }

  private getActionsColumn(): Column<PolicyConditionElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<PolicyConditionElement>> = [
      {
        displayName: 'Configure Condition',
        icon: 'open_in_browser',
        tooltip: 'Click to view/modify this condition',
        onClick: (element: any) => {
          this.openAddDialog(element);
        },
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getConditionTypeColumn(),
        this.getOperatorColumn(),
        this.getValueColumn(),
        this.getFieldColumn(),
        this.getActionsColumn(),
      ],
      this.columnDefs
    );
  }

  private formatConditionTypeForDisplay(conditionType: PolicyCondition.ConditionTypeEnum): string {
    const displayValue = this.conditionTypeToDisplayValueMap.get(conditionType);
    if (!displayValue) {
      return removeFromBeginningOfString(replaceCharacterWithSpace(conditionType, '_'), 'type ');
    }
    return displayValue;
  }

  private formatFieldForDisplay(field: string): string {
    if (field.startsWith('user_mfa_preferences.spec')) {
      field = field.replace('user_mfa_preferences.spec', 'User multi-factor');
    }
    return replaceCharacterWithSpace(replaceCharacterWithSpace(field, '_'), '.');
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<PolicyConditionElement> = [];
    for (let i = 0; i < this.currentPolicyRuleCopy.spec.conditions.length; i++) {
      data.push(this.createPolicyConditionElement(this.currentPolicyRuleCopy.spec.conditions[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createPolicyConditionElement(policyCondition: PolicyCondition, index: number): PolicyConditionElement {
    const data: PolicyConditionElement = {
      condition_type: this.getFirstConditionTypeFromEnum(),
      operator: null,
      field: '',
      value: '',
      overrideRequiredFlag: true,
      value_list: [],
      value_input_value: '',
      ...getDefaultTableProperties(index),
    };
    for (const key of Object.keys(policyCondition)) {
      const value = getEmptyStringIfUnset(policyCondition[key]);
      data[key] = value;
    }
    return data;
  }

  public makeEmptyTableElement(): PolicyConditionElement {
    return {
      condition_type: null,
      operator: null,
      field: null,
      value: '',
      value_list: [],
      value_input_value: '',
      overrideRequiredFlag: true,
      ...getDefaultNewRowProperties(),
    };
  }

  private getFirstConditionTypeFromEnum(): PolicyCondition.ConditionTypeEnum | undefined {
    const firstConditionTypeOption = Object.values(PolicyCondition.ConditionTypeEnum)[0];
    const isConditionTypeEnum = createEnumChecker(PolicyCondition.ConditionTypeEnum);
    if (isConditionTypeEnum(firstConditionTypeOption)) {
      return firstConditionTypeOption;
    }
    return undefined;
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  private getPolicyConditionsFromTable(): Array<PolicyCondition> {
    return this.tableData.map((entry: PolicyConditionElement) => {
      const policyCondition: PolicyCondition = {
        condition_type: entry.condition_type,
        value: convert_value_type(entry.value, entry.field, entry.condition_type, entry.operator),
        operator: entry.operator,
      };
      if (entry.condition_type === PolicyCondition.ConditionTypeEnum.object_attribute) {
        if (!!entry.field) {
          policyCondition.field = entry.field;
        }
        if (isAttributeAList(entry.field)) {
          policyCondition.input_is_list = true;
        }
      }
      this.formatGroupNamesValues(policyCondition);
      return policyCondition;
    });
  }

  private formatGroupNamesValues(policyCondition: PolicyCondition): void {
    if (policyCondition.field === 'user_member_of.id') {
      const targetGroupNamesList = JSON.parse(policyCondition.value).map((item: string) => item.trim());
      let groupIdsList = [];
      for (const groupName of targetGroupNamesList) {
        let targetGroup = this.groupDisplayNameToGroupMap.get(groupName);
        targetGroup = !!targetGroup ? targetGroup : this.groupIdToGroupMap.get(groupName);
        if (targetGroup?.id) {
          groupIdsList.push(targetGroup.id);
        }
      }
      policyCondition.value = groupIdsList.length !== 0 ? JSON.stringify(groupIdsList) : JSON.stringify([]);
    }
  }

  /**
   * Checks if the value property of all PolicyConditionElements is set.
   */
  private validatePolicyConditions(): string | undefined {
    for (const policyConditionElement of this.tableData) {
      if (policyConditionElement.condition_type === PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method) {
        // no value is required.
        // force set to In operator (only valid value here)
        policyConditionElement.operator = PolicyCondition.OperatorEnum.in;
        continue;
      }

      // Note: cannot simply use "!policyConditionElement.value" below since value could be set to false.
      if (policyConditionElement.value === '' || policyConditionElement.value === undefined || policyConditionElement.value === null) {
        // Do not submit to the api if the value properties are not set.
        // This is a temporary solution to a larger issuer which requires a larger refactoring of
        // the table-layout.
        return 'The value property is required.';
      }
      if (policyConditionElement.condition_type === PolicyCondition.ConditionTypeEnum.last_successful_mfa) {
        const intervalVal = parseInt(policyConditionElement.value, 10);
        if (isNaN(intervalVal) || intervalVal < 0) {
          return 'The Successful Multi-Factor Authentication condition requires an integer duration in seconds. The duration cannot be a negative number.';
        }
      }
    }
    return undefined;
  }

  /**
   * Receives an PolicyConditionElement from the table then updates and saves
   * the current policy.
   */
  public updateEvent(updatedPolicyConditionElement: PolicyConditionElement): void {
    const errMsg = this.validatePolicyConditions();
    if (errMsg !== undefined) {
      this.notificationService.error(errMsg);
      return;
    }
    this.currentPolicyRuleCopy.spec.conditions = this.getPolicyConditionsFromTable();
    if (this.preventSaveIfValueNotSet(updatedPolicyConditionElement)) {
      return;
    }
    this.savePolicyRule();
  }

  private removePolicyConditions(): void {
    this.tableData = this.tableData.filter((policyCondition) => !policyCondition.isChecked);
  }

  public deleteSelected(): void {
    this.removePolicyConditions();
    this.currentPolicyRuleCopy.spec.conditions = this.getPolicyConditionsFromTable();
    this.savePolicyRule();
  }

  /**
   * Do not submit to the api if the value is not set.
   * This is a temporary solution to a larger issuer which requires a larger refactoring
   * of the table-layout.
   */
  private preventSaveIfValueNotSet(element: PolicyConditionElement): boolean {
    if (element.condition_type === PolicyCondition.ConditionTypeEnum.user_has_enrolled_multifactor_method) {
      // no value required
      return false;
    }
    return !element.value;
  }

  private savePolicyRule(): void {
    this.store.dispatch(new ActionPolicySavingPolicyRule(this.currentPolicyRuleCopy));
  }

  public openAddDialog(condition?: PolicyConditionElement): void {
    const dialogData: PolicyConditionDialogData = {
      condition: !!condition ? cloneDeep(condition) : this.makeEmptyTableElement(),
      store: this.store,
      groupIdToGroupMap: this.groupIdToGroupMap,
      groupDisplayNameToGroupMap: this.groupDisplayNameToGroupMap,
      policyRule: this.currentPolicyRuleCopy,
    };
    const dialogRef = this.dialog.open(
      PolicyConditionDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

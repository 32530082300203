import { PatchErrorImpl } from '@agilicus/angular';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState, NotificationService, selectCrudManagementState } from '@app/core';
import { AppErrorHandler } from '@app/core/error-handler/app-error-handler.service';
import { selectApiOrgId } from '@app/core/user/user.selectors';
import { capitalizeFirstLetter, pluralizeString, replaceCharacterWithSpace } from '@app/shared/components/utils';
import { Actions, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, filter, forkJoin, map, mergeMap, Observable, of, withLatestFrom } from 'rxjs';
import { CrudManagementState } from './crud-management-state-definitions';
import { CrudRegistry, CrudRegistryService } from './crud-registry-service.service';
import {
  getCrudActionTypeName,
  getGuidFromObject,
  getNewCrudStateObjGuid,
  getSanitisedObject,
  GuidToSavedStateMap,
  ObjectState,
} from './state-driven-crud';
import {
  CrudActions,
  DeleteFinishedAction,
  FailedSaveAction,
  isCrudFailedSaveAction,
  isCrudResetStateAction,
  isCrudRetrySaveAction,
  isCrudSaveFinishedAction,
  isCrudTryNextSaveAction,
  isCrudUpdateAction,
  isCrudDeleteFinishedAction,
  MaintainStateAction,
  ResetStateAction,
  RetrySaveAction,
  SaveFinishedAction,
  TryNextSaveAction,
  UpdateAction,
  isCrudCreateAction,
  isCrudDeleteAction,
  DeleteAction,
  isCrudFailedDeleteAction,
  FailedDeleteAction,
  isCrudRetryDeleteAction,
  RetryDeleteAction,
  isCrudListAction,
  ListAction,
  isCrudGetAction,
  GetAction,
  isCrudUpdateListAction,
  UpdateListAction,
  SaveListFinishedAction,
  SaveListHandlerAction,
  isCrudSaveListHandlerAction,
  TryNextSaveListAction,
  isCrudTryNextSaveListAction,
  TryNextSaveListHandlerAction,
  isCrudTryNextSaveListHandlerAction,
  FailedSaveListAction,
  isCrudFailedSaveListAction,
  RetrySaveListAction,
  isCrudRetrySaveListAction,
  isCrudResetListStateAction,
  ResetListStateAction,
  isCrudSaveListFinishedAction,
  isCrudDeleteListAction,
  isCrudRetryDeleteListAction,
  DeleteListAction,
  RetryDeleteListAction,
  DeleteListHandlerAction,
  isCrudDeleteListHandlerAction,
  DeleteListFinishedAction,
  FailedDeleteListAction,
  isCrudFailedDeleteListAction,
  isCrudDeleteListFinishedAction,
} from './state-driven-crud.actions';

export const getFormattedCrudRegistyName = (crudRegistyName: string): string => {
  return capitalizeFirstLetter(replaceCharacterWithSpace(crudRegistyName, '_'));
};

@Injectable()
export class StateDrivenCrudEffects<DataType, IDType> {
  constructor(
    private store: Store<AppState>,
    private actions$: Actions,
    private crudRegistryService: CrudRegistryService<DataType, IDType>,
    private appErrorHandler: AppErrorHandler,
    private notificationService: NotificationService
  ) {}

  public getDataHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudGetAction(action)),
      mergeMap((action: GetAction<IDType>) => {
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        return crudRegistry.crudInterface.get(action.guid, action.orgId).pipe(
          mergeMap((getResp) => {
            return of(crudRegistry.setListAction({ objs: [getResp], org_id: action.orgId, blankSlate: action.blankSlate }));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            this.notificationService.error(
              `Failed to retrieve the ${getFormattedCrudRegistyName(action.crudRegistyName)} data. Please reload the page to try again.`
            );
            return of(crudRegistry.setListAction({ objs: [undefined], org_id: action.orgId, blankSlate: false }));
          })
        );
      })
    );
  });

  public listDataHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudListAction(action)),
      mergeMap((action: ListAction) => {
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        return crudRegistry.crudInterface.list(action.orgId).pipe(
          mergeMap((listResp) => {
            return of(crudRegistry.setListAction({ objs: listResp, org_id: action.orgId, blankSlate: action.blankSlate }));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            this.notificationService.error(
              `Failed to retrieve the ${getFormattedCrudRegistyName(action.crudRegistyName)} data. Please reload the page to try again.`
            );
            return of(crudRegistry.setListAction({ objs: [], org_id: action.orgId, blankSlate: false }));
          })
        );
      })
    );
  });

  public updateHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter(
        (action) =>
          isCrudUpdateAction<DataType, IDType>(action) ||
          isCrudCreateAction<DataType, IDType>(action) ||
          isCrudRetrySaveAction<IDType>(action)
      ),
      mergeMap((action: UpdateAction<DataType, IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [UpdateAction<DataType, IDType>, CrudManagementState, string]) => {
        let refreshData = !!action.refreshData;
        const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[action.guid.toString()];
        const desiredVersion = desiredObjectState.version;
        let desiredObj = desiredObjectState.obj;
        if (!!action.obj) {
          desiredObj = action.obj;
        }
        const currentVersion: number = crudManagementState[action.crudRegistyName].lastAppliedState[action.guid.toString()].version;
        // The idea here is that if we increment desiredVersion by one each update, the first
        // update since the last apply will be one more.
        // If we are already deleting the object, the version will be -1 so do not do the update
        if (desiredVersion - 1 !== currentVersion || desiredVersion === -1) {
          if (!action.forceNextSave) {
            return of(undefined).pipe(withLatestFrom(of(action), of(undefined), of(refreshData)));
          }
        }
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        if (action.guid.toString() === getNewCrudStateObjGuid()) {
          // If creating a new object we want to refresh the data:
          refreshData = true;
          return crudRegistry.crudInterface.create(action.guid, desiredObj, orgId).pipe(
            mergeMap((createResp) => {
              return of(createResp).pipe(withLatestFrom(of(action), of(desiredVersion), of(refreshData)));
            }),
            catchError((err: Error | PatchErrorImpl) => {
              return of(err).pipe(withLatestFrom(of(action), of(desiredVersion), of(refreshData)));
            })
          );
        }
        return crudRegistry.crudInterface.update(action.guid, desiredObj, orgId).pipe(
          mergeMap((updateResp) => {
            return of(updateResp).pipe(withLatestFrom(of(action), of(desiredVersion), of(refreshData)));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            return of(err).pipe(withLatestFrom(of(action), of(desiredVersion), of(refreshData)));
          })
        );
      }),
      mergeMap(
        ([updateResult, action, desiredVersion, refreshData]: [
          DataType | Error | PatchErrorImpl | undefined,
          UpdateAction<DataType, IDType>,
          number | undefined,
          boolean
        ]) => {
          if (updateResult === undefined || desiredVersion === undefined) {
            return of(
              new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
            );
          }
          if (updateResult instanceof HttpErrorResponse || updateResult instanceof Error || updateResult instanceof PatchErrorImpl) {
            const errorMessage = `Failed to update ${getFormattedCrudRegistyName(action.crudRegistyName)}.`;
            return of(
              new TryNextSaveAction<IDType>(
                getCrudActionTypeName(CrudActions.TRY_NEXT_SAVE, action.crudRegistyName),
                action.crudRegistyName,
                desiredVersion,
                action.guid,
                updateResult,
                errorMessage,
                action.notifyUser,
                action.refreshData
              )
            );
          }
          let guidVal = action.guid;
          if (guidVal.toString() === getNewCrudStateObjGuid()) {
            guidVal = getGuidFromObject(updateResult);
          }
          return of(
            new SaveFinishedAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_FINISHED, action.crudRegistyName),
              action.crudRegistyName,
              desiredVersion,
              updateResult as DataType,
              guidVal,
              true,
              action.refreshData
            )
          );
        }
      )
    );
  });

  public updateListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudUpdateListAction<DataType>(action) || isCrudRetrySaveListAction<DataType>(action)),
      mergeMap((action: UpdateListAction<DataType> | RetrySaveListAction<DataType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [UpdateListAction<DataType>, CrudManagementState, string]) => {
        let updateObjsObservablesList: Array<Observable<DataType | Error | PatchErrorImpl | undefined>> = [];
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        const guidToSavedStateMap: GuidToSavedStateMap<IDType> = new Map();
        let refreshData = !!action.refreshData;
        for (const obj of action.objs) {
          const guidVal: IDType = getGuidFromObject(obj);
          const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[guidVal.toString()];
          const desiredVersion = desiredObjectState.version;
          const currentVersion: number = crudManagementState[action.crudRegistyName].lastAppliedState[guidVal.toString()].version;
          // The idea here is that if we increment desiredVersion by one each update, the first
          // update since the last apply will be one more.
          // If we are already deleting the object, the version will be -1 so do not do the update
          if (desiredVersion - 1 !== currentVersion || desiredVersion === -1) {
            if (!action.forceNextSave) {
              continue;
            }
          }
          if (guidVal.toString() === getNewCrudStateObjGuid()) {
            // If creating a new object we want to refresh the data:
            refreshData = true;
            const createObjObservable$ = crudRegistry.crudInterface.create(guidVal, obj, orgId).pipe(
              catchError((err: Error | PatchErrorImpl) => {
                return of(err);
              })
            );
            updateObjsObservablesList.push(createObjObservable$);
          } else {
            guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: false });
            const updateObjObservable$ = crudRegistry.crudInterface.update(guidVal, obj, orgId).pipe(
              catchError((err: Error | PatchErrorImpl) => {
                return of(err);
              })
            );
            updateObjsObservablesList.push(updateObjObservable$);
          }
        }
        if (updateObjsObservablesList.length === 0) {
          updateObjsObservablesList = [of(undefined)];
        }
        return forkJoin(updateObjsObservablesList).pipe(withLatestFrom(of(action), of(guidToSavedStateMap), of(refreshData)));
      }),
      mergeMap(
        ([updateResult, action, guidToSavedStateMap, refreshData]: [
          Array<DataType | Error | PatchErrorImpl | undefined>,
          UpdateListAction<DataType>,
          GuidToSavedStateMap<IDType>,
          boolean
        ]) => {
          if (updateResult.length === 1 && updateResult[0] === undefined) {
            return of(
              new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
            );
          }
          const successfulUpdatesList: Array<DataType> = [];
          const errorsList: Array<Error | PatchErrorImpl> = [];
          for (const updatedObj of updateResult) {
            if (updatedObj instanceof HttpErrorResponse || updatedObj instanceof Error || updatedObj instanceof PatchErrorImpl) {
              errorsList.push(updatedObj);
            } else {
              successfulUpdatesList.push(updatedObj);
              const guidVal: IDType = getGuidFromObject(updatedObj);
              let desiredVersion = guidToSavedStateMap.get(guidVal)?.version;
              if (!desiredVersion) {
                // newly created object:
                desiredVersion = 1;
              }
              guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: true });
            }
          }
          return of(
            new SaveListHandlerAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_LIST_HANDLER, action.crudRegistyName),
              action.crudRegistyName,
              guidToSavedStateMap,
              successfulUpdatesList,
              errorsList,
              action.notifyUser,
              refreshData
            )
          );
        }
      )
    );
  });

  public savedListHandlerSaveSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudSaveListHandlerAction<DataType, IDType>(action)),
      map((action: SaveListHandlerAction<DataType, IDType>) => {
        if (action.savedObjs.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        return new SaveListFinishedAction<DataType, IDType>(
          getCrudActionTypeName(CrudActions.SAVE_LIST_FINISHED, action.crudRegistyName),
          action.crudRegistyName,
          action.guidToSavedStateMap,
          action.savedObjs,
          action.notifyUser,
          action.refreshData
        );
      })
    );
  });

  public savedListHandlerHandleErrors$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudSaveListHandlerAction<DataType, IDType>(action)),
      map((action: SaveListHandlerAction<DataType, IDType>) => {
        if (action.errors.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        const errorMessage = `Failed to update ${getFormattedCrudRegistyName(pluralizeString(action.crudRegistyName))}.`;
        return new TryNextSaveListAction<IDType>(
          getCrudActionTypeName(CrudActions.TRY_NEXT_SAVE_LIST, action.crudRegistyName),
          action.crudRegistyName,
          action.guidToSavedStateMap,
          action.errors,
          errorMessage,
          action.notifyUser,
          action.refreshData
        );
      })
    );
  });

  public tryNextSaveOnFailedSave$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudTryNextSaveAction<IDType>(action)),
      mergeMap((action: TryNextSaveAction<IDType>) => of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState))))),
      mergeMap(([action, crudManagementState]: [TryNextSaveAction<IDType>, CrudManagementState]) => {
        const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[action.guid.toString()];
        const desiredVersion = desiredObjectState.version;
        if (desiredVersion !== action.version) {
          return of(
            new UpdateAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.UPDATE, action.crudRegistyName),
              action.crudRegistyName,
              action.guid,
              desiredObjectState.obj,
              action.notifyUser,
              action.refreshData,
              true
            )
          );
        }
        return of(
          new FailedSaveAction<IDType>(
            getCrudActionTypeName(CrudActions.FAILED_SAVE, action.crudRegistyName),
            action.crudRegistyName,
            action.guid,
            action.error,
            action.errorMessage,
            action.notifyUser,
            action.refreshData
          )
        );
      })
    );
  });

  public tryNextSaveOnFailedListSave$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudTryNextSaveListAction<IDType>(action)),
      mergeMap((action: TryNextSaveListAction<IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState))))
      ),
      mergeMap(([action, crudManagementState]: [TryNextSaveListAction<IDType>, CrudManagementState]) => {
        // The list of objects to try saving again before alerting the user:
        const updateObjsList: Array<DataType> = [];
        // The list of objects with no further updates to save, so the user will be alerted to the failure:
        const failedSaveObjsList: Array<DataType> = [];
        const failedSaveIds: Array<IDType> = [];
        for (let [key, value] of action.guidToSavedStateMap) {
          if (!value.saved) {
            failedSaveIds.push(key);
          }
        }
        for (const id of failedSaveIds) {
          const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[id.toString()];
          const desiredVersion = desiredObjectState.version;
          if (desiredVersion !== action.guidToSavedStateMap.get(id).version) {
            updateObjsList.push(desiredObjectState.obj);
          } else {
            failedSaveObjsList.push(desiredObjectState.obj);
          }
        }

        return of(
          new TryNextSaveListHandlerAction<DataType, IDType>(
            getCrudActionTypeName(CrudActions.TRY_NEXT_SAVE_LIST_HANDLER, action.crudRegistyName),
            action.crudRegistyName,
            action.guidToSavedStateMap,
            updateObjsList,
            failedSaveObjsList,
            action.errorMessage,
            action.notifyUser,
            action.refreshData
          )
        );
      })
    );
  });

  public tryNextSaveListHandlerSaveObjs$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudTryNextSaveListHandlerAction<DataType, IDType>(action)),
      map((action: TryNextSaveListHandlerAction<DataType, IDType>) => {
        if (action.objsToSave.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        return new UpdateListAction<DataType>(
          getCrudActionTypeName(CrudActions.UPDATE_LIST, action.crudRegistyName),
          action.crudRegistyName,
          action.objsToSave,
          action.notifyUser,
          action.refreshData,
          true
        );
      })
    );
  });

  public tryNextSaveListHandlerFailedSaves$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudTryNextSaveListHandlerAction<DataType, IDType>(action)),
      map((action: TryNextSaveListHandlerAction<DataType, IDType>) => {
        if (action.failedSaveObjs.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        return new FailedSaveListAction<DataType>(
          getCrudActionTypeName(CrudActions.FAILED_SAVE_LIST, action.crudRegistyName),
          action.crudRegistyName,
          action.failedSaveObjs,
          action.errorMessage,
          action.notifyUser,
          action.refreshData
        );
      })
    );
  });

  public failedSaveHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudFailedSaveAction<IDType>(action)),
      map((action: FailedSaveAction<IDType>) => {
        this.appErrorHandler.openCrudStateErrorMessageDialog<IDType>(
          action.error,
          action.errorMessage,
          action.crudRegistyName,
          action.guid,
          RetrySaveAction,
          CrudActions.RETRY_SAVE,
          action.notifyUser,
          action.refreshData
        );
        return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
      })
    );
  });

  public failedSaveListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudFailedSaveListAction<DataType>(action)),
      map((action: FailedSaveListAction<DataType>) => {
        this.appErrorHandler.openCrudStateMultipleErrorMessageDialog<DataType, IDType>(
          action.errorMessage,
          action.crudRegistyName,
          action.failedSaveObjs,
          RetrySaveListAction,
          CrudActions.RETRY_SAVE_LIST,
          action.notifyUser,
          action.refreshData
        );
        return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
      })
    );
  });

  public resetStateHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudResetStateAction<IDType>(action)),
      mergeMap((action: ResetStateAction<IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [ResetStateAction<IDType>, CrudManagementState, string]) => {
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        if (action.guid.toString() === getNewCrudStateObjGuid()) {
          // The user has canceled saving a new object due a failed save.
          return of(undefined).pipe(withLatestFrom(of(action), of(crudManagementState), of(crudRegistry)));
        }
        return crudRegistry.crudInterface.get(action.guid, orgId).pipe(
          mergeMap((getResp) => {
            return of(getResp).pipe(withLatestFrom(of(action), of(crudManagementState), of(crudRegistry)));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            return of(err).pipe(withLatestFrom(of(action), of(crudManagementState), of(crudRegistry)));
          })
        );
      }),
      mergeMap(
        ([getResult, action, crudManagementState, crudRegistry]: [
          DataType | Error | PatchErrorImpl | undefined,
          ResetStateAction<IDType>,
          CrudManagementState,
          CrudRegistry<DataType, IDType>
        ]) => {
          if (getResult === undefined) {
            // The user has canceled saving a new object due a failed save so we need to refresh the data to clear the unsaved object
            return of(crudRegistry.refreshStateAction());
          }
          if (getResult instanceof HttpErrorResponse || getResult instanceof Error || getResult instanceof PatchErrorImpl) {
            const errorMessage = `Failed to reset ${action.crudRegistyName}.`;
            return of(
              new FailedSaveAction<IDType>(
                getCrudActionTypeName(CrudActions.FAILED_SAVE, action.crudRegistyName),
                action.crudRegistyName,
                action.guid,
                getResult,
                errorMessage,
                false, // notifyUser
                true // refreshData
              )
            );
          }
          const desiredObjectState: ObjectState<DataType> =
            crudManagementState[action.crudRegistyName].desiredState[action.guid.toString()];
          const desiredVersion = desiredObjectState.version;
          return of(
            new SaveFinishedAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_FINISHED, action.crudRegistyName),
              action.crudRegistyName,
              desiredVersion,
              getResult,
              action.guid,
              false,
              true
            )
          );
        }
      )
    );
  });

  public resetListStateHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudResetListStateAction<DataType>(action)),
      mergeMap((action: ResetListStateAction<IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [ResetListStateAction<IDType>, CrudManagementState, string]) => {
        let getObjsObservablesList: Array<Observable<DataType | Error | PatchErrorImpl | undefined>> = [];
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        const guidToSavedStateMap: GuidToSavedStateMap<IDType> = new Map();
        for (const id of action.ids) {
          if (id.toString() === getNewCrudStateObjGuid()) {
            // The user has canceled saving a new object due a failed save.
            continue;
          }
          const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[id.toString()];
          const desiredVersion = desiredObjectState.version;
          guidToSavedStateMap.set(id, { version: desiredVersion, saved: false });
          const getObjObservable$ = crudRegistry.crudInterface.get(id, orgId).pipe(
            catchError((err: Error | PatchErrorImpl) => {
              // TODO: handle case where this returns a 404 due to the obj having been deleted
              return of(err);
            })
          );
          getObjsObservablesList.push(getObjObservable$);
        }
        if (getObjsObservablesList.length === 0) {
          getObjsObservablesList = [of(undefined)];
        }
        return forkJoin(getObjsObservablesList).pipe(withLatestFrom(of(action), of(guidToSavedStateMap)));
      }),
      mergeMap(
        ([getResults, action, guidToSavedStateMap]: [
          Array<DataType | Error | PatchErrorImpl>,
          ResetListStateAction<IDType>,
          GuidToSavedStateMap<IDType>
        ]) => {
          if (getResults.length === 1 && getResults[0] === undefined) {
            return of(
              new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
            );
          }
          const successfullGetsList: Array<DataType> = [];
          const errorsList: Array<Error | PatchErrorImpl> = [];
          for (const obj of getResults) {
            if (obj instanceof HttpErrorResponse || obj instanceof Error || obj instanceof PatchErrorImpl) {
              errorsList.push(obj);
            } else {
              const guidVal: IDType = getGuidFromObject(obj);
              successfullGetsList.push(obj);
              const desiredVersion = guidToSavedStateMap.get(guidVal).version;
              guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: true });
            }
          }
          return of(
            new SaveListHandlerAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_LIST_HANDLER, action.crudRegistyName),
              action.crudRegistyName,
              guidToSavedStateMap,
              successfullGetsList,
              errorsList,
              false,
              true
            )
          );
        }
      )
    );
  });

  public stateSaveHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudSaveFinishedAction<DataType, IDType>(action)),
      mergeMap((action: SaveFinishedAction<DataType, IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [SaveFinishedAction<DataType, IDType>, CrudManagementState, string]) => {
        const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[action.guid.toString()];
        const lastAppliedState = crudManagementState[action.crudRegistyName].lastAppliedState[action.guid.toString()];
        const desiredVersion = desiredObjectState.version;
        const lastAppliedVersion = lastAppliedState.version;
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        if (desiredVersion === lastAppliedVersion || desiredVersion === -1) {
          return of(lastAppliedState.obj).pipe(withLatestFrom(of(action), of(undefined), of(crudRegistry)));
        }
        const sanitisedObject = getSanitisedObject(crudRegistry, desiredObjectState.obj, lastAppliedState.obj);
        return crudRegistry.crudInterface.update(action.guid, sanitisedObject, orgId).pipe(
          mergeMap((updateResp) => {
            return of(updateResp).pipe(withLatestFrom(of(action), of(desiredVersion), of(crudRegistry)));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            return of(err).pipe(withLatestFrom(of(action), of(desiredVersion), of(crudRegistry)));
          })
        );
      }),
      mergeMap(
        ([updateResult, action, desiredVersion, crudRegistry]: [
          DataType | Error | PatchErrorImpl | undefined,
          SaveFinishedAction<DataType, IDType> | undefined,
          number | undefined,
          CrudRegistry<DataType, IDType>
        ]) => {
          if (updateResult instanceof HttpErrorResponse || updateResult instanceof Error || updateResult instanceof PatchErrorImpl) {
            const errorMessage = `Failed to update ${getFormattedCrudRegistyName(action.crudRegistyName)}.`;
            return of(
              new TryNextSaveAction<IDType>(
                getCrudActionTypeName(CrudActions.TRY_NEXT_SAVE, action.crudRegistyName),
                action.crudRegistyName,
                desiredVersion,
                action.guid,
                updateResult,
                errorMessage,
                action.notifyUser,
                action.refreshData
              )
            );
          }
          const notifyUser = !!updateResult ? action.notifyUser : false;
          if (desiredVersion === undefined) {
            if (action.notifyUser) {
              let messsage = `${getFormattedCrudRegistyName(action.crudRegistyName)}`;
              if (!!crudRegistry.getNameFromObject(action.savedObj)) {
                messsage += ` "${crudRegistry.getNameFromObject(action.savedObj)}"`;
              }
              messsage += ` was successfully updated`;
              this.notificationService.success(messsage);
            }
            return of(crudRegistry.doneSaveAction({ obj: updateResult, refreshData: action.refreshData }));
          }
          return of(
            new SaveFinishedAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_FINISHED, action.crudRegistyName),
              action.crudRegistyName,
              desiredVersion,
              updateResult,
              action.guid,
              notifyUser,
              action.refreshData
            )
          );
        }
      )
    );
  });

  public stateSaveListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudSaveListFinishedAction<DataType, IDType>(action)),
      mergeMap((action: SaveListFinishedAction<DataType, IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [SaveListFinishedAction<DataType, IDType>, CrudManagementState, string]) => {
        let updateObjsObservablesList: Array<Observable<DataType | Error | PatchErrorImpl | undefined>> = [];
        const finishedSavingObjsList: Array<DataType> = [];
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        const guidToSavedStateMap: GuidToSavedStateMap<IDType> = new Map();
        for (const obj of action.savedObjs) {
          const guidVal: IDType = getGuidFromObject(obj);
          const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[guidVal.toString()];
          const lastAppliedState = crudManagementState[action.crudRegistyName].lastAppliedState[guidVal.toString()];
          const desiredVersion = desiredObjectState.version;
          guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: false });
          const lastAppliedVersion = lastAppliedState.version;
          if (desiredVersion === lastAppliedVersion || desiredVersion === -1) {
            finishedSavingObjsList.push(lastAppliedState.obj);
            guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: true });
            continue;
          }
          const sanitisedObject = getSanitisedObject(crudRegistry, desiredObjectState.obj, lastAppliedState.obj);
          const updateObjObservable$ = crudRegistry.crudInterface.update(guidVal, sanitisedObject, orgId).pipe(
            catchError((err: Error | PatchErrorImpl) => {
              return of(err);
            })
          );
          updateObjsObservablesList.push(updateObjObservable$);
        }
        if (updateObjsObservablesList.length === 0) {
          updateObjsObservablesList = [of(undefined)];
        }
        return forkJoin(updateObjsObservablesList).pipe(
          withLatestFrom(of(action), of(finishedSavingObjsList), of(guidToSavedStateMap), of(crudRegistry))
        );
      }),
      mergeMap(
        ([updateResult, action, finishedSavingObjsList, guidToSavedStateMap, crudRegistry]: [
          Array<DataType | Error | PatchErrorImpl | undefined>,
          SaveListFinishedAction<DataType, IDType>,
          Array<DataType>,
          GuidToSavedStateMap<IDType>,
          CrudRegistry<DataType, IDType>
        ]) => {
          if (updateResult.length === 1 && updateResult[0] === undefined) {
            // Nothing left to update and no errors
            if (finishedSavingObjsList.length === 0) {
              return of(
                new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
              );
            }
            if (action.notifyUser) {
              let messsage = `${pluralizeString(getFormattedCrudRegistyName(action.crudRegistyName))}`;
              messsage += ` were successfully updated`;
              this.notificationService.success(messsage);
            }
            return of(
              crudRegistry.doneSaveListAction({
                objs: finishedSavingObjsList,
                refreshData: action.refreshData,
              })
            );
          }
          const successfullUpdatesList: Array<DataType> = [];
          const errorsList: Array<Error | PatchErrorImpl> = [];
          for (const updatedObj of updateResult) {
            if (updatedObj instanceof HttpErrorResponse || updatedObj instanceof Error || updatedObj instanceof PatchErrorImpl) {
              errorsList.push(updatedObj);
            } else {
              const guidVal: IDType = getGuidFromObject(updatedObj);
              successfullUpdatesList.push(updatedObj);
              const desiredVersion = guidToSavedStateMap.get(guidVal).version;
              guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: true });
            }
          }
          return of(
            new SaveListHandlerAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.SAVE_LIST_HANDLER, action.crudRegistyName),
              action.crudRegistyName,
              guidToSavedStateMap,
              successfullUpdatesList,
              errorsList,
              action.notifyUser,
              action.refreshData
            )
          );
        }
      )
    );
  });

  public deleteHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteAction<DataType, IDType>(action) || isCrudRetryDeleteAction<IDType>(action)),
      mergeMap((action: DeleteAction<DataType, IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(([action, crudManagementState, orgId]: [DeleteAction<DataType, IDType>, CrudManagementState, string]) => {
        const desiredState = crudManagementState[action.crudRegistyName].desiredState[action.guid.toString()];
        const desiredVersion = desiredState.version;
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        if (desiredVersion !== -1) {
          return of(undefined).pipe(withLatestFrom(of(action), of(undefined)));
        }
        return crudRegistry.crudInterface.delete(action.guid, orgId).pipe(
          mergeMap((deleteResp) => {
            return of(deleteResp).pipe(withLatestFrom(of(action), of(desiredVersion)));
          }),
          catchError((err: Error | PatchErrorImpl) => {
            return of(err).pipe(withLatestFrom(of(action), of(desiredVersion)));
          })
        );
      }),
      mergeMap(
        ([deleteResult, action, version]: [
          void | undefined | Error | PatchErrorImpl,
          DeleteAction<DataType, IDType>,
          number | undefined
        ]) => {
          if (deleteResult === undefined || version === undefined) {
            return of(
              new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
            );
          }
          if (deleteResult instanceof HttpErrorResponse || deleteResult instanceof Error || deleteResult instanceof PatchErrorImpl) {
            const errorMessage = `Failed to delete ${action.crudRegistyName}.`;
            return of(
              new FailedDeleteAction<IDType>(
                getCrudActionTypeName(CrudActions.FAILED_DELETE, action.crudRegistyName),
                action.crudRegistyName,
                action.guid,
                deleteResult,
                errorMessage
              )
            );
          }
          return of(
            new DeleteFinishedAction<DataType, IDType>(
              getCrudActionTypeName(CrudActions.DELETE_FINISHED, action.crudRegistyName),
              action.crudRegistyName,
              version,
              action.obj,
              action.guid,
              true
            )
          );
        }
      )
    );
  });

  public deleteListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteListAction<DataType>(action) || isCrudRetryDeleteListAction<IDType>(action)),
      mergeMap((action: DeleteListAction<DataType> | RetryDeleteListAction<IDType>) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectCrudManagementState)), this.store.pipe(select(selectApiOrgId))))
      ),
      mergeMap(
        ([action, crudManagementState, orgId]: [
          DeleteListAction<DataType> | RetryDeleteListAction<IDType>,
          CrudManagementState,
          string
        ]) => {
          let deleteObjsObservablesList: Array<Observable<IDType | Error | PatchErrorImpl | undefined>> = [];
          const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
          const guidToSavedStateMap: GuidToSavedStateMap<IDType> = new Map();
          let objsList: Array<DataType> = [];
          if (action instanceof RetryDeleteListAction) {
            for (const id of action.ids) {
              const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[id.toString()];
              objsList.push(desiredObjectState.obj);
            }
          } else {
            objsList = action.objs;
          }
          for (const obj of objsList) {
            const guidVal: IDType = getGuidFromObject(obj);
            if (guidVal.toString() === getNewCrudStateObjGuid()) {
              // We do not want to delete objs that do not yet exist in the back-end
              continue;
            }
            const desiredObjectState: ObjectState<DataType> = crudManagementState[action.crudRegistyName].desiredState[guidVal.toString()];
            const desiredVersion = desiredObjectState.version;
            if (desiredVersion !== -1) {
              continue;
            }
            guidToSavedStateMap.set(guidVal, { version: desiredVersion, saved: false });
            const deleteObjObservable$ = crudRegistry.crudInterface.delete(guidVal, orgId).pipe(
              mergeMap((_) => {
                return of(guidVal);
              }),
              catchError((err: Error | PatchErrorImpl) => {
                return of(err);
              })
            );
            deleteObjsObservablesList.push(deleteObjObservable$);
          }
          if (deleteObjsObservablesList.length === 0) {
            deleteObjsObservablesList = [of(undefined)];
          }
          return forkJoin(deleteObjsObservablesList).pipe(withLatestFrom(of(action), of(guidToSavedStateMap)));
        }
      ),
      mergeMap(
        ([deleteResult, action, guidToSavedStateMap]: [
          Array<IDType | undefined | Error | PatchErrorImpl>,
          DeleteListAction<DataType> | RetryDeleteListAction<IDType>,
          GuidToSavedStateMap<IDType>
        ]) => {
          if (deleteResult.length === 1 && deleteResult[0] === undefined) {
            return of(
              new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName)
            );
          }
          const successfullDeletedIdsList: Array<IDType> = [];
          const failedDeleteIdsList: Array<IDType> = [];
          for (const item of deleteResult) {
            if (item instanceof HttpErrorResponse || item instanceof Error || item instanceof PatchErrorImpl) {
              continue;
            }
            successfullDeletedIdsList.push(item);
            const desiredVersion = guidToSavedStateMap.get(item).version;
            guidToSavedStateMap.set(item, { version: desiredVersion, saved: true });
          }
          for (let [key, value] of guidToSavedStateMap) {
            if (!value.saved) {
              failedDeleteIdsList.push(key);
            }
          }
          let notifyUser = true;
          if (action instanceof DeleteListAction) {
            notifyUser = action.notifyUser;
          }
          return of(
            new DeleteListHandlerAction<IDType>(
              getCrudActionTypeName(CrudActions.DELETE_LIST_HANDLER, action.crudRegistyName),
              action.crudRegistyName,
              guidToSavedStateMap,
              successfullDeletedIdsList,
              failedDeleteIdsList,
              notifyUser
            )
          );
        }
      )
    );
  });

  public deletedListHandlerDeleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteListHandlerAction<IDType>(action)),
      map((action: DeleteListHandlerAction<IDType>) => {
        if (action.successIds.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        return new DeleteListFinishedAction<IDType>(
          getCrudActionTypeName(CrudActions.DELETE_LIST_FINISHED, action.crudRegistyName),
          action.crudRegistyName,
          action.guidToSavedStateMap,
          action.successIds,
          action.notifyUser
        );
      })
    );
  });

  public deletedListHandlerHandleErrors$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteListHandlerAction<IDType>(action)),
      map((action: DeleteListHandlerAction<IDType>) => {
        if (action.failedIds.length === 0) {
          return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
        }
        const errorMessage = `Failed to delete ${pluralizeString(action.crudRegistyName)}.`;
        return new FailedDeleteListAction<IDType>(
          getCrudActionTypeName(CrudActions.FAILED_DELETE_LIST, action.crudRegistyName),
          action.crudRegistyName,
          action.failedIds,
          errorMessage
        );
      })
    );
  });

  public failedDeleteHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudFailedDeleteAction<IDType>(action)),
      map((action: FailedDeleteAction<IDType>) => {
        this.appErrorHandler.openCrudStateErrorMessageDialog<IDType>(
          action.error,
          action.errorMessage,
          action.crudRegistyName,
          action.guid,
          RetryDeleteAction,
          CrudActions.RETRY_DELETE,
          true, // notifyUser
          true // refreshData
        );
        return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
      })
    );
  });

  public failedDeleteListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudFailedDeleteListAction<IDType>(action)),
      map((action: FailedDeleteListAction<IDType>) => {
        this.appErrorHandler.openCrudStateMultipleErrorMessageDialog<DataType, IDType>(
          action.errorMessage,
          action.crudRegistyName,
          action.ids,
          RetryDeleteListAction,
          CrudActions.RETRY_DELETE_LIST,
          true, // notifyUser
          true // refreshData
        );
        return new MaintainStateAction(getCrudActionTypeName(CrudActions.MAINTAIN_STATE, action.crudRegistyName), action.crudRegistyName);
      })
    );
  });

  public stateDeleteHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteFinishedAction<DataType, IDType>(action)),
      map((action: DeleteFinishedAction<DataType, IDType>) => {
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        let messsage = `${getFormattedCrudRegistyName(action.crudRegistyName)}`;
        if (!!crudRegistry.getNameFromObject(action.obj)) {
          messsage += ` "${crudRegistry.getNameFromObject(action.obj)}"`;
        }
        messsage += ` was successfully deleted`;
        this.notificationService.success(messsage);
        return crudRegistry.doneDeleteAction({ id: getGuidFromObject(action.obj), obj: action.obj });
      })
    );
  });

  public stateDeleteListHandler$ = createEffect(() => {
    return this.actions$.pipe(
      filter((action) => isCrudDeleteListFinishedAction<IDType>(action)),
      map((action: DeleteListFinishedAction<IDType>) => {
        const crudRegistry = this.crudRegistryService.crudRegistry.get(action.crudRegistyName);
        if (action.notifyUser) {
          let messsage = `${pluralizeString(getFormattedCrudRegistyName(action.crudRegistyName))}`;
          messsage += ` were successfully deleted`;
          this.notificationService.success(messsage);
        }
        return crudRegistry.doneDeleteListAction({ ids: action.ids });
      })
    );
  });
}

import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject, Observable, combineLatest } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  Column,
  createSelectRowColumn,
  createInputColumn,
  createChipListColumn,
  createSelectColumn,
  createCheckBoxColumn,
  CheckBoxColumn,
  setColumnDefs,
  createExpandColumn,
  createIconColumn,
  createActionsColumn,
  ActionMenuOptions,
  ChiplistColumn,
} from '../table-layout/column-definitions';
import { cloneDeep } from 'lodash-es';
import { TableElement } from '../table-layout/table-element';
import { FilterManager } from '../filter/filter-manager';
import { updateTableElements, createEnumChecker } from '../utils';
import {
  ApplicationService,
  Connector,
  NetworkPortRange,
  ApplicationServiceAssignment,
  Application,
  Organisation,
  ConnectorSpec,
  LabelledObject,
  Resource,
} from '@agilicus/angular';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OptionalNetworkElement } from '../optional-types';
import {
  isValidQualifiedDomain,
  isValidHostnameOrIp4,
  isValidIp4,
  isValidPortRange,
  isValidFQDNSingleLabel,
  isValidPortRangeList,
} from '../validation-utils';
import { getDefaultNewRowProperties, getDefaultTableProperties, getDefaultNestedDataProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import { selectOrganisations } from '@app/core/organisations/organisations.selectors';
import { selectApiApplicationsList, selectApiApplicationsRefreshData } from '@app/core/api-applications/api-applications.selectors';
import {
  selectApplicationServicesList,
  selectApplicationServicesRefreshDataValue,
} from '@app/core/application-service-state/application-service.selectors';
import {
  deletingApplicationServices,
  initApplicationServices,
  refreshApplicationServices,
  savingApplicationService,
} from '@app/core/application-service-state/application-service.actions';
import { getFormattedLowercaseResourceName, getResourceTypeTooltip, getServiceProtocolTypeIcon } from '../resource-utils';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import { ActionApiApplicationsInitApplications } from '@app/core/api-applications/api-applications.actions';
import { OrganisationsState } from '@app/core/organisations/organisations.models';
import {
  getFilteredConnectorNamesList,
  isConnectorBeingRemovedFromResource,
  openRemoveConnectorFromResourceWarningDialog,
} from '../connector-utils';
import {
  getDisableHttp2CheckboxTooltip,
  getDynamicSourcePortOverrideTooltipText,
  getNetworkDescriptiveText,
  getNetworkExposeAsHostnameTooltipText,
  getNetworkHostnameTooltipText,
  getNetworkNameTooltipText,
  getNetworkOverrideIpTooltipText,
  getNetworkPortRangesFromString,
  getNetworkPortTooltipText,
  getNetworkSourcePortOverrideTooltipText,
  getNetworkSourceAddressOverrideTooltipText,
  getNetworkTlsVerifyTooltipText,
  getPortRangeLimit,
  setNetworkFromNetworkElement,
} from '@app/core/application-service-state/application-services-utils';
import { ResourceType } from '../resource-type.enum';
import { ButtonType } from '../button-type.enum';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog.component';
import { createDialogData, getDefaultDialogConfig } from '../dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import { TableLayoutComponent } from '../table-layout/table-layout.component';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { ObjectWithId } from '../object-with-id';
import { NetworkCsvDialogComponent, NetworkCsvDialogData } from '../network-csv-dialog/network-csv-dialog.component';
import { InputSize } from '../custom-chiplist-input/input-size.enum';

export interface NetworkElement extends ResourceTableElement, ApplicationService {
  learning_mode_enabled: boolean;
  expose_as_hostname: boolean;
  disable_http2?: boolean;
  ports?: string;
  source_port_override?: string;
  source_address_override?: string;
  dynamic_source_port_override?: boolean;
  override_ip?: string;
  connector_name: string;
  prevous_connector_name: string;
  ignore_port_range_check: boolean;
  ignore_resource_disconnect_check: boolean;
  backingObject: ApplicationService;
}

export interface ApplicationServiceAssignmentBindElement extends ApplicationServiceAssignment, TableElement {
  parentId: string | number;
}

@Component({
  selector: 'portal-network-overview',
  templateUrl: './network-overview.component.html',
  styleUrls: ['./network-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private appServicesCopy: Array<ApplicationService>;
  public columnDefs: Map<string, CheckBoxColumn<NetworkElement>> = new Map();
  public tableData: Array<NetworkElement> = [];
  public rowObjectName = 'NETWORK';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate(['/network-new'], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
    new TableScopedButton(
      'ADD MULTIPLE FROM CSV',
      ButtonColor.PRIMARY,
      'Create multiple networks via CSV upload in format "name", "hostname", "ports", "override_ip"',
      'Button that creates multiple networks via CSV upload',
      () => {
        this.openNetworkCsvDialog();
      }
    ),
  ];
  public fixedTable = false;
  public filterManager: FilterManager = new FilterManager();
  public hasAppsPermissions: boolean;
  public sortDataBy = 'index';
  public connectorIdToConnectorMap: Map<string, Connector> = new Map();
  public connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public pageDescriptiveText = getNetworkDescriptiveText();
  public productGuideLink = `https://www.agilicus.com/anyx-guide/services/`;
  private allOrganisations: Array<Organisation>;
  private orgIdToOrgNameMap: Map<string, string>;
  private orgNameToOrgIdMap: Map<string, string>;
  private applications: Array<Application>;
  private localAppicationServicesRefreshDataValue = 0;
  private localConnectorsRefreshDataValue = 0;
  private localApplicationsRefreshDataValue = 0;
  private resetTableDataWithApiData = false;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstanceWithLabels> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<NetworkElement>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private routerHelperService: RouterHelperService,
    private dialog: MatDialog,
    private router: Router,
    private policyResourceLinkService: PolicyResourceLinkService<NetworkElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initApplicationServices({ force: true, blankSlate: false }));
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    this.store.dispatch(new ActionApiApplicationsInitApplications(true, false, false));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    this.getAndSetAllData();
  }

  private getAndSetAllData(): void {
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const appServiceListState$ = this.store.pipe(select(selectApplicationServicesList));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const applicationsListState$ = this.store.pipe(select(selectApiApplicationsList));
    const orgsState$ = this.store.pipe(select(selectOrganisations));
    const refreshApplicationServicesDataState$ = this.store.pipe(select(selectApplicationServicesRefreshDataValue));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    const refreshApplicationDataState$ = this.store.pipe(select(selectApiApplicationsRefreshData));
    combineLatest([
      hasAppsPermissions$,
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      appServiceListState$,
      connectorListState$,
      applicationsListState$,
      orgsState$,
      refreshApplicationServicesDataState$,
      refreshConnectorDataState$,
      refreshApplicationDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasAppsPermissionsResp,
          getPermissionsAndResourceAndLabelDataResp,
          appServiceListStateResp,
          connectorListStateResp,
          applicationsListStateResp,
          orgsStateResp,
          refreshApplicationServicesDataStateResp,
          refreshConnectorDataStateResp,
          refreshApplicationDataStateResp,
        ]) => {
          if (this.resetTableDataWithApiData) {
            this.resetTableDataWithApiData = false;
            this.store.dispatch(refreshApplicationServices());
            return;
          }
          this.orgId = hasAppsPermissionsResp.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          if (
            !this.hasAppsPermissions ||
            !connectorListStateResp ||
            !appServiceListStateResp ||
            !applicationsListStateResp ||
            !this.isAllOrgStateDataDefined(orgsStateResp)
          ) {
            this.resetEmptyTable();
            return;
          }
          this.setConnectorMaps(connectorListStateResp);
          this.allOrganisations = orgsStateResp.all_organisations;
          this.orgIdToOrgNameMap = orgsStateResp.org_id_to_org_name_map;
          this.orgNameToOrgIdMap = orgsStateResp.org_name_to_org_id_map;
          this.setConnectorColumnGetAllowedValues(connectorListStateResp);
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);

          if (
            this.tableData.length === 0 ||
            this.localAppicationServicesRefreshDataValue !== refreshApplicationServicesDataStateResp ||
            this.localConnectorsRefreshDataValue !== refreshConnectorDataStateResp ||
            this.localApplicationsRefreshDataValue != refreshApplicationDataStateResp ||
            this.localPoliciesRefreshDataValue !== getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue
          ) {
            this.localAppicationServicesRefreshDataValue = refreshApplicationServicesDataStateResp;
            this.localConnectorsRefreshDataValue = refreshConnectorDataStateResp;
            this.localApplicationsRefreshDataValue = refreshApplicationDataStateResp;
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            this.appServicesCopy = cloneDeep(appServiceListStateResp);
            this.applications = applicationsListStateResp;
            if (
              this.localAppicationServicesRefreshDataValue !== 0 &&
              this.localConnectorsRefreshDataValue !== 0 &&
              this.localApplicationsRefreshDataValue !== 0 &&
              this.localPoliciesRefreshDataValue !== 0
            ) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable();
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Need to filter out connectors with more than one instance if the network type is a Share.
   */
  private setConnectorColumnGetAllowedValues(connectorList: Array<Connector>): void {
    const connectorColumn = this.columnDefs.get('connector_name');
    connectorColumn.getAllowedValues = (element: OptionalNetworkElement) => {
      if (!connectorList) {
        return [];
      }
      if (element.service_protocol_type === ApplicationService.ServiceProtocolTypeEnum.fileshare) {
        const filteredConnectorList: Array<Connector> = connectorList.filter((connector) => connector.status.instances.length <= 1);
        return getFilteredConnectorNamesList(filteredConnectorList, this.orgId, ConnectorSpec.ConnectorTypeEnum.agent, [
          ConnectorSpec.ConnectorTypeEnum.agent,
        ]);
      }
      return getFilteredConnectorNamesList(connectorList, this.orgId, ConnectorSpec.ConnectorTypeEnum.agent, [
        ConnectorSpec.ConnectorTypeEnum.agent,
      ]);
    };
  }

  private setConnectorMaps(connectors: Array<Connector>): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  private isAllOrgStateDataDefined(orgsStateResp: OrganisationsState): boolean {
    if (
      !orgsStateResp ||
      !orgsStateResp.all_organisations ||
      !orgsStateResp.org_id_to_org_name_map ||
      !orgsStateResp.org_name_to_org_id_map
    ) {
      return false;
    }
    return true;
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<NetworkElement> = [];
    for (let i = 0; i < this.appServicesCopy.length; i++) {
      data.push(this.createTableElement(this.appServicesCopy[i], i));
    }
    this.setNestedTableData(data);
    updateTableElements(this.tableData, data);
  }

  private setNestedTableData(data: Array<NetworkElement>): void {
    for (const element of data) {
      element.expandedData = {
        ...getDefaultNestedDataProperties(element),
        nestedRowObjectName: 'NETWORK ASSIGNMENT',
        nestedButtonsToShow: [ButtonType.ADD, ButtonType.DELETE],
        hideNestedFilter: true,
        makeEmptyNestedTableElement: (): ApplicationServiceAssignmentBindElement => {
          return {
            app_id: '',
            org_id: '',
            environment_name: '',
            expose_type: '',
            expose_as_hostnames: [],
            parentId: element.index,
            ...getDefaultNewRowProperties(),
          };
        },
      };
      this.initializeNestedColumnDefs(element.expandedData.nestedColumnDefs);
      const applicationColumn = element.expandedData.nestedColumnDefs.get('app_id');
      applicationColumn.allowedValues = ['', ...this.applications.map((app) => app.name)];
      const organisationColumn = element.expandedData.nestedColumnDefs.get('org_id');
      organisationColumn.allowedValues = ['', ...this.allOrganisations.map((org) => org.organisation)];
      if (!element.assignments) {
        element.assignments = [];
      }
      for (let i = 0; i < element.assignments.length; i++) {
        const clientConfig = element.assignments[i];
        const nestedElement = this.createApplicationServiceAssignmentElement(clientConfig, i, element);
        element.expandedData.nestedTableData.push(nestedElement);
      }
    }
  }

  private createApplicationServiceAssignmentElement(
    clientConfig: ApplicationServiceAssignment,
    index: number,
    element: NetworkElement
  ): ApplicationServiceAssignmentBindElement {
    const data: ApplicationServiceAssignmentBindElement = {
      app_id: clientConfig.app_id,
      org_id: clientConfig.org_id,
      environment_name: clientConfig.environment_name,
      expose_type: clientConfig.expose_type,
      expose_as_hostnames: clientConfig.expose_as_hostnames,
      parentId: element.index,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  /**
   * Nested Table Column
   */
  private getApplicationColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const applicationColumn = createSelectColumn('app_id');
    applicationColumn.displayName = 'Application';
    applicationColumn.requiredField = () => true;
    applicationColumn.getDisplayValue = (element: ApplicationServiceAssignmentBindElement) => {
      let selectedApplication = this.applications.find((i) => i.id === element.app_id);
      return selectedApplication?.name ? selectedApplication.name : '';
    };
    return applicationColumn;
  }

  /**
   * Nested Table Column
   */
  private getOrganisationColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const organisationColumn = createSelectColumn('org_id');
    organisationColumn.displayName = 'Organisation';
    organisationColumn.requiredField = () => true;
    organisationColumn.getDisplayValue = (element: ApplicationServiceAssignmentBindElement) => {
      return this.orgIdToOrgNameMap.get(element.org_id);
    };
    return organisationColumn;
  }

  /**
   * Nested Table Column
   */
  private getEnvironmentColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const environmentColumn = createSelectColumn('environment_name');
    environmentColumn.displayName = 'Instance';
    environmentColumn.disableField = (element: ApplicationServiceAssignmentBindElement): boolean => {
      return !element.app_id;
    };
    environmentColumn.requiredField = () => true;
    environmentColumn.getAllowedValues = (element: ApplicationServiceAssignmentBindElement): Array<any> => {
      if (!!element.app_id) {
        const targetApp = this.applications.find((app) => app.id === element.app_id);
        if (!!targetApp) {
          const environmentNameList = targetApp.environments.map((env) => env.name);
          return environmentNameList;
        }
      }
      return [];
    };
    environmentColumn.isValidEntry = (entry) => {
      return true;
    };
    return environmentColumn;
  }

  /**
   * Nested Table Column
   */
  private getExposeTypeColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const exposeTypeColumn = createSelectColumn('expose_type');
    exposeTypeColumn.displayName = 'Service Type';
    exposeTypeColumn.requiredField = () => true;
    exposeTypeColumn.getAllowedValues = (element: ApplicationServiceAssignmentBindElement): Array<any> => {
      exposeTypeColumn.allowedValues = ['path_prefix', 'hostname', 'application', 'not_exposed'];
      return exposeTypeColumn.allowedValues;
    };
    return exposeTypeColumn;
  }

  /**
   * Nested Table Column
   */
  private getHostnamesColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const hostnamesColumn = createChipListColumn('expose_as_hostnames');
    hostnamesColumn.displayName = 'Hostnames';
    hostnamesColumn.disableField = (element: ApplicationServiceAssignmentBindElement) => {
      if (element.expose_type === 'hostname') {
        return false;
      }
      return true;
    };
    hostnamesColumn.isReadOnly = (element: ApplicationServiceAssignmentBindElement) => {
      if (element.expose_type === 'hostname') {
        return false;
      }
      return true;
    };
    hostnamesColumn.requiredField = (element: ApplicationServiceAssignmentBindElement) => {
      if (element?.expose_type && element.expose_type === 'hostname') {
        return true;
      }
      return false;
    };
    hostnamesColumn.hasAutocomplete = false;
    hostnamesColumn.isFreeform = true;
    hostnamesColumn.isValidEntry = (hostname: string): boolean => {
      return isValidQualifiedDomain(hostname);
    };
    return hostnamesColumn;
  }

  /**
   * Nested Table Column
   */
  private getNestedActionsColumn(): Column<ApplicationServiceAssignmentBindElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<ApplicationServiceAssignmentBindElement>> = [
      {
        displayName: 'Configure Hostnames',
        icon: 'dns',
        tooltip: 'Click to access the "Hostnames" advanced configuration',
        columnName: 'expose_as_hostnames',
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeNestedColumnDefs(nestedColumnDefs: Map<string, Column<ApplicationServiceAssignmentBindElement>>): void {
    setColumnDefs(
      [
        createSelectRowColumn(),
        this.getApplicationColumn(),
        this.getOrganisationColumn(),
        this.getEnvironmentColumn(),
        this.getExposeTypeColumn(),
        this.getHostnamesColumn(),
        this.getNestedActionsColumn(),
      ],
      nestedColumnDefs
    );
  }

  private getPortRangeStringFromNetworkPortRange(value: Array<NetworkPortRange>): string {
    return value.map((port) => port.port).join(', ');
  }

  private createTableElement(item: ApplicationService, index: number): NetworkElement {
    const networkWithMetadata: ObjectWithId = {
      ...item,
      metadata: {
        id: item.id,
      },
    };
    const connectorName = this.connectorIdToConnectorMap.get(item.connector_id)?.spec.name;
    const data: NetworkElement = {
      hostname: '',
      ipv4_addresses: [],
      name: '',
      org_id: '',
      ports: this.getPortRangeStringFromNetworkPortRange(!!item.config?.ports ? item.config.ports : []),
      source_port_override: this.getPortRangeStringFromNetworkPortRange(
        !!item.config?.source_port_override ? item.config.source_port_override : []
      ),
      source_address_override: item.config?.source_address_override,
      dynamic_source_port_override: !!item.config?.dynamic_source_port_override,
      expose_as_hostname: !!item.protocol_config?.expose_config?.expose_as_hostname,
      override_ip: !!item.ipv4_addresses && item.ipv4_addresses.length !== 0 ? item.ipv4_addresses[0] : '',
      learning_mode_enabled: !!item?.status?.alternate_mode_status?.learning_mode,
      connector_name: connectorName ? connectorName : '',
      prevous_connector_name: connectorName ? connectorName : '',
      ignore_port_range_check: false,
      ignore_resource_disconnect_check: false,
      nestedFormColumnDefs: new Map(),
      backingObject: item,
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        networkWithMetadata,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    for (const key of Object.keys(item)) {
      data[key] = item[key];
    }
    setColumnDefs(
      [
        this.getTlsEnabledColumn(),
        this.getTlsVerifyColumn(),
        this.getExposeAsHostnameColumn(),
        this.getDisableHttp2Column(),
        this.getDynamicSourcePortOverrideColumn(),
        this.getSourcePortOverrideColumn(),
        this.getSourceAddressOverrideColumn(),
      ],
      data.nestedFormColumnDefs
    );
    return data;
  }

  /**
   * Parent Table Column
   */
  private getTypeColumn(): Column<NetworkElement> {
    const typeColumn = createIconColumn('type');
    /**
     * Determines the mat-icon name to be passed into the mat-icon
     * html tag for display in the table. The name is a string that
     * identifies the type of mat-icon.
     */
    typeColumn.getDisplayValue = (element: OptionalNetworkElement) => {
      return getServiceProtocolTypeIcon(element.service_protocol_type);
    };
    typeColumn.getTooltip = (element: OptionalNetworkElement) => {
      return getResourceTypeTooltip(element.service_protocol_type as ResourceType);
    };
    return typeColumn;
  }

  /**
   * Parent Table Column
   */
  private getNameColumn(): Column<NetworkElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.requiredField = () => true;
    nameColumn.isEditable = true;
    nameColumn.isCaseSensitive = true;
    nameColumn.getHeaderTooltip = () => {
      return getNetworkNameTooltipText();
    };
    nameColumn.isValidEntry = (serviceName: string, element: NetworkElement): boolean => {
      if (element.backingObject.name === serviceName) {
        // We do not want to prevent updates to existing networks
        // that do not meet the new requirements for naming.
        // So, if the value has not been changed, do not apply validation logic.
        return true;
      }
      return isValidFQDNSingleLabel(serviceName);
    };
    nameColumn.getFormattedValue = (serviceName: string, element: NetworkElement): string => {
      if (element.service_protocol_type === ApplicationService.ServiceProtocolTypeEnum.ip) {
        // Networks cannot have uppercase characters, but other resource types can.
        return getFormattedLowercaseResourceName(serviceName);
      }
      return serviceName.trim();
    };
    nameColumn.inputSize = InputSize.TEXT_INPUT_LARGE;
    return nameColumn;
  }

  /**
   * Parent Table Column
   */
  private getHostnameColumn(): Column<NetworkElement> {
    const hostnameColumn = createInputColumn('hostname');
    hostnameColumn.displayName = 'Hostname/IP';
    hostnameColumn.requiredField = () => true;
    hostnameColumn.isEditable = true;
    hostnameColumn.isValidEntry = (hostname: string): boolean => {
      return isValidHostnameOrIp4(hostname);
    };
    hostnameColumn.getHeaderTooltip = () => {
      return getNetworkHostnameTooltipText();
    };
    return hostnameColumn;
  }

  /**
   * Parent Table Column
   */
  private getPortRangesColumn(): Column<NetworkElement> {
    const portRangesColumn = createInputColumn('ports');
    portRangesColumn.displayName = 'Ports';
    portRangesColumn.isEditable = true;
    portRangesColumn.requiredField = () => true;
    portRangesColumn.isValidEntry = (value: string): boolean => {
      return isValidPortRangeList(value);
    };
    portRangesColumn.getHeaderTooltip = () => {
      return getNetworkPortTooltipText();
    };
    return portRangesColumn;
  }

  /**
   * Parent Table Column
   */
  private getIpColumn(): Column<NetworkElement> {
    const ipColumn = createInputColumn('override_ip');
    ipColumn.displayName = 'Override IP';
    ipColumn.isEditable = true;
    ipColumn.isValidEntry = (ip: string): boolean => {
      return isValidIp4(ip);
    };
    ipColumn.getHeaderTooltip = () => {
      return getNetworkOverrideIpTooltipText();
    };
    return ipColumn;
  }

  /**
   * Parent Table Column
   */
  private getConnectorColumn(): Column<NetworkElement> {
    const connectorColumn = createSelectColumn('connector_name');
    connectorColumn.displayName = 'Via Connector';
    connectorColumn.isEditable = true;
    connectorColumn.getDisplayValue = (element: OptionalNetworkElement) => {
      return this.connectorIdToConnectorMap.get(element.connector_id)?.spec?.name
        ? this.connectorIdToConnectorMap.get(element.connector_id).spec.name
        : '';
    };
    connectorColumn.getHeaderTooltip = () => {
      return 'This is the connector by which we reach the resource. If it does not exist you may create one using the "New" sub-menu under the "Connectors" tab on the left.';
    };
    return connectorColumn;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<NetworkElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getLearningModeEnabled(): Column<NetworkElement> {
    const learningModeEnabledColumn = createCheckBoxColumn('learning_mode_enabled');
    learningModeEnabledColumn.displayName = 'Diagnostic Mode';
    learningModeEnabledColumn.isEditable = false;
    learningModeEnabledColumn.getHeaderTooltip = () => {
      return `Indicates whether this launcher is in "Diagnostic" mode`;
    };
    return learningModeEnabledColumn;
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<NetworkElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<NetworkElement>> = [
      {
        displayName: 'Disable Diagnostic Mode',
        icon: 'toggle_off',
        tooltip: 'Click to disable Diagnostic Mode',
        onClick: (element: NetworkElement) => {
          element.alternate_mode_setting.learning_mode = undefined;
          this.saveItem(element, true);
        },
        isDisabled: (element: NetworkElement) => {
          return !element.status?.alternate_mode_status?.learning_mode;
        },
      },
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: NetworkElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.org_id,
            target_resource_id: element.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
      {
        displayName: 'Configure Policies',
        icon: 'vpn_key',
        tooltip: 'Click to access the "Policies" advanced configuration',
        columnName: this.policyResourceLinkService.getPoliciesColumnName(),
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  /**
   * Nested Form Column
   */
  private getTlsEnabledColumn(): Column<NetworkElement> {
    const tlsEnabledColumn = createCheckBoxColumn('tls_enabled');
    tlsEnabledColumn.displayName = 'Accessed via TLS';
    tlsEnabledColumn.isChecked = (element: NetworkElement): boolean => {
      return !!element.tls_enabled;
    };
    tlsEnabledColumn.setElementFromCheckbox = (element: NetworkElement, isBoxChecked: boolean): any => {
      element.tls_enabled = isBoxChecked;
    };
    return tlsEnabledColumn;
  }

  /**
   * Nested Form Column
   */
  private getTlsVerifyColumn(): Column<NetworkElement> {
    const tlsVerifyColumn = createCheckBoxColumn('tls_verify');
    tlsVerifyColumn.displayName = 'Verify TLS';
    tlsVerifyColumn.isChecked = (element: NetworkElement): boolean => {
      return !!element.tls_verify;
    };
    tlsVerifyColumn.setElementFromCheckbox = (element: NetworkElement, isBoxChecked: boolean): any => {
      element.tls_verify = isBoxChecked;
    };
    tlsVerifyColumn.getHeaderTooltip = () => {
      return getNetworkTlsVerifyTooltipText();
    };
    return tlsVerifyColumn;
  }

  /**
   * Nested Form Column
   */
  private getExposeAsHostnameColumn(): Column<NetworkElement> {
    const exposeAsHostnameColumn = createCheckBoxColumn('expose_as_hostname');
    exposeAsHostnameColumn.displayName = 'Expose as Hostname';
    exposeAsHostnameColumn.disableField = (element: NetworkElement): boolean => {
      return element.service_protocol_type !== ApplicationService.ServiceProtocolTypeEnum.ip;
    };
    exposeAsHostnameColumn.isChecked = (element: NetworkElement): boolean => {
      return !!element.protocol_config?.expose_config?.expose_as_hostname;
    };
    exposeAsHostnameColumn.setElementFromCheckbox = (element: NetworkElement, isBoxChecked: boolean): any => {
      this.setExposeAsHostnameValueFromCheckbox(element, isBoxChecked);
      // We need to reset the table data with the api data in order to get the updated status which includes the exposed_as_hostname value.
      this.resetTableDataWithApiData = true;
    };
    exposeAsHostnameColumn.getHeaderTooltip = (element: NetworkElement) => {
      if (!element.status?.routing_info?.exposed_as_hostname) {
        return getNetworkExposeAsHostnameTooltipText();
      }
      return `${getNetworkExposeAsHostnameTooltipText()} The endpoint is "${element.status.routing_info.exposed_as_hostname}"`;
    };
    exposeAsHostnameColumn.getTooltip = (element: NetworkElement) => {
      return !!element.status?.routing_info?.exposed_as_hostname ? element.status.routing_info.exposed_as_hostname : '';
    };
    return exposeAsHostnameColumn;
  }

  /**
   * Nested Form Column
   */
  private getDisableHttp2Column(): Column<NetworkElement> {
    const disableHttp2Column = createCheckBoxColumn('disable_http2');
    disableHttp2Column.displayName = 'My application does not support http2 (supports only http/1.1)';
    disableHttp2Column.isChecked = (element: NetworkElement): boolean => {
      return !!element.protocol_config?.http_config?.disable_http2;
    };
    disableHttp2Column.setElementFromCheckbox = (element: NetworkElement, isBoxChecked: boolean): any => {
      this.setDisableHttp2ValueFromCheckbox(element, isBoxChecked);
    };
    disableHttp2Column.getHeaderTooltip = () => {
      return getDisableHttp2CheckboxTooltip();
    };
    return disableHttp2Column;
  }

  /**
   * Nested Form Column
   */
  private getDynamicSourcePortOverrideColumn(): Column<NetworkElement> {
    const dynamicSourcePortOverrideColumn = createCheckBoxColumn('dynamic_source_port_override');
    dynamicSourcePortOverrideColumn.isChecked = (element: NetworkElement): boolean => {
      return !!element.config?.dynamic_source_port_override;
    };
    dynamicSourcePortOverrideColumn.setElementFromCheckbox = (element: NetworkElement, isBoxChecked: boolean): any => {
      this.setDynamicSourcePortOverrideValueFromCheckbox(element, isBoxChecked);
    };
    dynamicSourcePortOverrideColumn.getHeaderTooltip = () => {
      return getDynamicSourcePortOverrideTooltipText();
    };
    dynamicSourcePortOverrideColumn.startNewLine = () => true;
    return dynamicSourcePortOverrideColumn;
  }

  /**
   * Nested Form Column
   */
  private getSourcePortOverrideColumn(): Column<NetworkElement> {
    const sourcePortOverrideColumn = createInputColumn('source_port_override');
    sourcePortOverrideColumn.isEditable = true;
    sourcePortOverrideColumn.isValidEntry = (value: string): boolean => {
      const portRangesStringArray = value.split(',').map((str) => str.trim());
      for (const portRange of portRangesStringArray) {
        if (!isValidPortRange(portRange)) {
          return false;
        }
      }
      return true;
    };
    sourcePortOverrideColumn.getHeaderTooltip = () => {
      return getNetworkSourcePortOverrideTooltipText();
    };
    return sourcePortOverrideColumn;
  }

  /**
   * Nested Form Column
   */
  private getSourceAddressOverrideColumn(): Column<NetworkElement> {
    const sourceAddressOverrideColumn = createInputColumn('source_address_override');
    sourceAddressOverrideColumn.isEditable = true;
    sourceAddressOverrideColumn.isValidEntry = (value: string): boolean => {
      return isValidHostnameOrIp4(value);
    };
    sourceAddressOverrideColumn.getHeaderTooltip = () => {
      return getNetworkSourceAddressOverrideTooltipText();
    };
    return sourceAddressOverrideColumn;
  }

  private setExposeAsHostnameValueFromCheckbox(appServiceElement: NetworkElement, isBoxChecked: boolean) {
    appServiceElement.expose_as_hostname = isBoxChecked;
    if (!appServiceElement.protocol_config) {
      appServiceElement.protocol_config = {
        expose_config: {
          expose_as_hostname: isBoxChecked,
        },
      };
      return;
    }
    if (!appServiceElement.protocol_config.expose_config) {
      appServiceElement.protocol_config.expose_config = {
        expose_as_hostname: isBoxChecked,
      };
      return;
    }
    appServiceElement.protocol_config.expose_config.expose_as_hostname = isBoxChecked;
  }

  private setDisableHttp2ValueFromCheckbox(appServiceElement: NetworkElement, isBoxChecked: boolean) {
    appServiceElement.disable_http2 = isBoxChecked;
    if (!appServiceElement.protocol_config) {
      appServiceElement.protocol_config = {
        http_config: {
          disable_http2: isBoxChecked,
        },
      };
      return;
    }
    if (!appServiceElement.protocol_config.http_config) {
      appServiceElement.protocol_config.http_config = {
        disable_http2: isBoxChecked,
      };
      return;
    }
    appServiceElement.protocol_config.http_config.disable_http2 = isBoxChecked;
  }

  private setDynamicSourcePortOverrideValueFromCheckbox(appServiceElement: NetworkElement, isBoxChecked: boolean) {
    appServiceElement.dynamic_source_port_override = isBoxChecked;
    if (!appServiceElement.config) {
      appServiceElement.config = {
        dynamic_source_port_override: isBoxChecked,
      };
      return;
    }
    appServiceElement.config.dynamic_source_port_override = isBoxChecked;
  }

  private initializeColumnDefs(): void {
    if (this.shouldGateAccess()) {
      // Do not include policies column:
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getTypeColumn(),
          this.getNameColumn(),
          this.getHostnameColumn(),
          this.getPortRangesColumn(),
          this.getIpColumn(),
          this.getConnectorColumn(),
          this.getLearningModeEnabled(),
          this.getActionsColumn(),
          createExpandColumn<NetworkElement>(),
        ],
        this.columnDefs
      );
    } else {
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getTypeColumn(),
          this.getNameColumn(),
          this.getHostnameColumn(),
          this.getPortRangesColumn(),
          this.getIpColumn(),
          this.getConnectorColumn(),
          this.getPoliciesColumn(),
          this.getLearningModeEnabled(),
          this.getActionsColumn(),
          createExpandColumn<NetworkElement>(),
        ],
        this.columnDefs
      );
    }
  }

  private openPortRangeWarningDialog(updatedAppServiceElement: NetworkElement): void {
    const messagePrefix = `Large Port Range`;
    const message = `Warning: Having more than ${getPortRangeLimit()} ports in a port range can make the interceptor less reliable. 
    If you experience a problem try using smaller port ranges.\n<br><br>
    Would you like to continue saving with the large port range?`;
    const dialogData: ConfirmationDialogData = {
      ...createDialogData(messagePrefix, message),
      icon: 'warning',
      buttonText: { confirm: 'Yes', cancel: 'No' },
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        updatedAppServiceElement.ignore_port_range_check = true;
        this.updateEvent(updatedAppServiceElement);
      } else {
        // Mark the row as dirty so that the user will be warned of the issue again
        // if they do not change the port range value on subsequent save attempts.
        updatedAppServiceElement.dirty = true;
        updatedAppServiceElement.ignore_port_range_check = false;
      }
    });
  }

  /**
   * Receives an NetworkElement from the table then updates and saves
   * the current application.
   */
  public updateEvent(updatedAppService: NetworkElement | ApplicationServiceAssignmentBindElement): void {
    const updatedElementAsAgentConnectorLocalBind = updatedAppService as ApplicationServiceAssignmentBindElement;
    if (updatedElementAsAgentConnectorLocalBind.parentId !== undefined && updatedElementAsAgentConnectorLocalBind.parentId !== null) {
      const parentElement = this.getParentElementFromParentId(updatedElementAsAgentConnectorLocalBind);
      const updatedServiceAssignments = this.getServiceAssignmentsFromNestedTableData(parentElement.expandedData.nestedTableData);
      parentElement.assignments = updatedServiceAssignments;
      this.saveItem(parentElement);
      return;
    }
    const updatedAppServiceAsNetworkElement = updatedAppService as NetworkElement;
    if (this.areOneOrMorePortRangesTooLarge(updatedAppServiceAsNetworkElement)) {
      this.openPortRangeWarningDialog(updatedAppServiceAsNetworkElement);
      return;
    }
    if (isConnectorBeingRemovedFromResource(updatedAppServiceAsNetworkElement)) {
      openRemoveConnectorFromResourceWarningDialog(updatedAppServiceAsNetworkElement, this.dialog, this.updateEvent.bind(this));
      return;
    }
    this.saveItem(updatedAppServiceAsNetworkElement);
  }

  /**
   * Having more than 10 ports in a port range can make the interceptor less reliable
   */
  private areOneOrMorePortRangesTooLarge(updatedAppServiceElement: NetworkElement): boolean {
    if (updatedAppServiceElement.ignore_port_range_check) {
      return false;
    }
    const portRangeValues = getNetworkPortRangesFromString(updatedAppServiceElement.ports);
    for (const portRange of portRangeValues) {
      const valueAsString = portRange.port;
      const valueAsNumberArray = valueAsString.split('-').map((portStr) => parseInt(portStr, 10));
      if (valueAsNumberArray.length === 2) {
        const range = valueAsNumberArray[1] - valueAsNumberArray[0];
        if (range + 1 > getPortRangeLimit()) {
          return true;
        }
      }
    }
    return false;
  }

  private getServiceAssignmentsFromNestedTableData(
    nestedTableData: Array<ApplicationServiceAssignmentBindElement>
  ): Array<ApplicationServiceAssignment> {
    const updatedServiceAssignments: Array<ApplicationServiceAssignment> = [];
    for (const element of nestedTableData) {
      updatedServiceAssignments.push(this.getClientConfigFromApplicationServiceAssignmentElement(element));
    }
    return updatedServiceAssignments;
  }

  private getClientConfigFromApplicationServiceAssignmentElement(
    applicationServiceAssignmentElement: ApplicationServiceAssignmentBindElement
  ): ApplicationServiceAssignment {
    const result: ApplicationServiceAssignment = {
      app_id: applicationServiceAssignmentElement.app_id,
      org_id: applicationServiceAssignmentElement.org_id,
      environment_name: applicationServiceAssignmentElement.environment_name,
      expose_type: applicationServiceAssignmentElement.expose_type,
      expose_as_hostnames: [],
    };
    if (applicationServiceAssignmentElement.expose_type === 'hostname') {
      result.expose_as_hostnames = [...applicationServiceAssignmentElement.expose_as_hostnames];
    }
    return result;
  }

  private getParentElementFromParentId(
    ApplicationServiceAssignmentBindElement: ApplicationServiceAssignmentBindElement
  ): NetworkElement | undefined {
    return this.tableData[ApplicationServiceAssignmentBindElement.parentId];
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }): void {
    if (!this.isValidSelectionColumn(params)) {
      return;
    }
    this.handleProtocolColumnUpdate(params);
    this.handleConnectorColumnUpdate(params);
    this.handleExposeTypeColumnUpdate(params);
    this.handleApplicationColumnUpdate(params);
    this.handleOrganisationColumnUpdate(params);
    this.handleEnvironmentColumnUpdate(params);
  }

  private isValidSelectionColumn(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }): boolean {
    if (
      params.column.name !== 'protocol' &&
      params.column.name !== 'connector_name' &&
      params.column.name !== 'expose_type' &&
      params.column.name !== 'app_id' &&
      params.column.name !== 'org_id' &&
      params.column.name !== 'environment_name'
    ) {
      return false;
    }
    return true;
  }

  private handleProtocolColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const isProtocolEnum = createEnumChecker(ApplicationService.ProtocolEnum) || null;
    const networkElement = params.element as NetworkElement;
    if (params.column.name === 'protocol' && isProtocolEnum(params.value)) {
      networkElement.protocol = params.value;
    }
  }

  private handleConnectorColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const networkElement = params.element as NetworkElement;
    if (params.column.name === 'connector_name') {
      const elementCopy = cloneDeep(networkElement);
      networkElement.prevous_connector_name = elementCopy.connector_name;
      networkElement.connector_name = params.value;
      const connectorId = this.connectorNameToConnectorMap.get(params.value)?.metadata.id;
      networkElement.connector_id = !!connectorId ? connectorId : '';
    }
  }

  private handleExposeTypeColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const applicationServiceAssignmentBindElement = params.element as ApplicationServiceAssignmentBindElement;
    if (params.column.name === 'expose_type') {
      applicationServiceAssignmentBindElement.expose_type = params.value;
      if (applicationServiceAssignmentBindElement.expose_type !== 'hostname') {
        applicationServiceAssignmentBindElement.expose_as_hostnames = [];
      }
    }
  }

  private handleApplicationColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const applicationServiceAssignmentBindElement = params.element as ApplicationServiceAssignmentBindElement;
    if (params.column.name === 'app_id') {
      applicationServiceAssignmentBindElement.app_id = this.applications.find((app) => app.name === params.value).id;
      // We need to clear the environment field when the app name is changed.
      applicationServiceAssignmentBindElement.environment_name = '';
    }
  }

  private handleOrganisationColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const applicationServiceAssignmentBindElement = params.element as ApplicationServiceAssignmentBindElement;
    if (params.column.name === 'org_id') {
      applicationServiceAssignmentBindElement.org_id = this.orgNameToOrgIdMap.get(params.value);
    }
  }

  private handleEnvironmentColumnUpdate(params: {
    value: ApplicationService.ProtocolEnum | string;
    column: Column<NetworkElement | ApplicationServiceAssignmentBindElement>;
    element: NetworkElement | ApplicationServiceAssignmentBindElement;
  }) {
    const applicationServiceAssignmentBindElement = params.element as ApplicationServiceAssignmentBindElement;
    if (params.column.name === 'environment_name') {
      applicationServiceAssignmentBindElement.environment_name = params.value;
    }
  }

  private saveItem(updatedTableElement: NetworkElement, refreshData = false): void {
    updatedTableElement.ignore_port_range_check = false;
    updatedTableElement.ignore_resource_disconnect_check = false;
    setNetworkFromNetworkElement(updatedTableElement);
    this.policyResourceLinkService
      .submitPoliciesResult$(updatedTableElement, this.orgId)
      .pipe(take(1))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(updatedTableElement, resourceAndLabelsAndPolicyResponse);
        // Need to make a copy of the object or it will be converted to readonly.
        this.store.dispatch(
          savingApplicationService({
            obj: cloneDeep(updatedTableElement),
            trigger_update_side_effects: false,
            notifyUser: true,
            refreshData: !!refreshData ? refreshData : this.resetTableDataWithApiData,
          })
        );
        this.changeDetector.detectChanges();
      });
  }

  private deleteFromNestedTable(applicationServiceAssignmentBindElement: ApplicationServiceAssignmentBindElement): void {
    const parentElement = this.getParentElementFromParentId(applicationServiceAssignmentBindElement);
    parentElement.expandedData.nestedTableData = parentElement.expandedData.nestedTableData.filter((element) => !element.isChecked);
    const updatedServiceAssignments = this.getServiceAssignmentsFromNestedTableData(parentElement.expandedData.nestedTableData);
    parentElement.assignments = updatedServiceAssignments;
    this.saveItem(parentElement);
  }

  private deleteFromParentTable(appServicesToDelete: Array<NetworkElement>): void {
    // Need to make a copy of the appServiceToDelete or it will be
    // converted to readonly.
    this.store.dispatch(
      deletingApplicationServices({ objs: cloneDeep(appServicesToDelete), trigger_update_side_effects: false, notifyUser: true })
    );
  }

  public deleteSelected(elementsToDelete: Array<NetworkElement | ApplicationServiceAssignmentBindElement>): void {
    const elementAsApplicationServiceAssignmentBindElement = elementsToDelete[0] as ApplicationServiceAssignmentBindElement;
    if (
      elementAsApplicationServiceAssignmentBindElement.parentId !== undefined &&
      elementAsApplicationServiceAssignmentBindElement.parentId !== null
    ) {
      this.deleteFromNestedTable(elementAsApplicationServiceAssignmentBindElement);
      return;
    }
    const elementsAsNetworkElements = elementsToDelete as Array<NetworkElement>;
    this.deleteFromParentTable(elementsAsNetworkElements);
  }

  public openNetworkCsvDialog(): void {
    const dialogData: NetworkCsvDialogData = {
      orgId: this.orgId,
    };
    const dialogRef = this.dialog.open(
      NetworkCsvDialogComponent,
      getDefaultDialogConfig({
        data: dialogData,
      })
    );
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.unsubscribe$.next();
        this.store.dispatch(initApplicationServices({ force: true, blankSlate: false }));
        this.getAndSetAllData();
        return;
      }
    });
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
    this.tableLayoutComp.triggerChangeDetectionFromParentComponent();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this), ['protocol']);
  }
}

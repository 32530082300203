export enum StepperType {
  application = 'application',
  share = 'share',
  desktop = 'desktop',
  ssh = 'ssh',
  network = 'network',
  launcher = 'launcher',
  organisation = 'organisation',
  apiKey = 'apiKey',
}

import { Organisation, User, UserMetadata } from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core';
import { GettingStartedTask } from '@app/core/user/preferences/getting-started-tasks.enum';
import { GettingStartedData } from '@app/core/user/preferences/getting-started-data';
import { getTaskCompletedStatus } from '@app/core/user/preferences/user-preference-utils';
import { ActionUserUpdateGettingStartedData } from '@app/core/user/user.actions';
import { selectApiOrgId, selectGettingStartedData, selectUser, selectCurrentOrg } from '@app/core/user/user.selectors';
import { scrollToTop } from '@app/shared/components/utils';
import { select, Store } from '@ngrx/store';
import { cloneDeep } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ConfirmationDialogComponent } from '@app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { createDialogData } from '@app/shared/components/dialog-utils';
import { MatDialog } from '@angular/material/dialog';
import { EventsService } from '@app/core/services/events.service';

export interface CombinedUserAndOrgData {
  orgId: string;
  currentUser: User;
  userMetaData: Array<UserMetadata>;
}

export interface ListItemData {
  text: string;
  task: GettingStartedTask;
  link: string;
}

@Component({
  selector: 'portal-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GettingStartedComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  private currentOrg: Organisation;
  private shownWelcome: boolean = false;
  private gettingStartedDataCopy: GettingStartedData;
  public currentUser: User;
  public zeroTrustLink = `https://www.agilicus.com/zero-trust/`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/`;
  public productGuideLargeText = true;
  private dialogOpen = false;
  public listItemData: Array<ListItemData> = [
    {
      text: `Create a network connection to each site containing resources (Web Applications, Shares, Desktops, etc)`,
      task: GettingStartedTask.completed_connect_to_sites,
      link: 'connect-to-sites-guide',
    },
    {
      text: `Configure the sources of user authentication and identity`,
      task: GettingStartedTask.completed_identify_users,
      link: 'identify-users-guide',
    },
    {
      text: `Configure the name and access for each resource`,
      task: GettingStartedTask.completed_expose_resource,
      link: 'expose-resource-guide',
    },
    {
      text: `Assign Users, Groups, Permissions for access to these resources`,
      task: GettingStartedTask.completed_assign_permissions,
      link: 'assign-permissions-guide',
    },
    {
      text: `Diagnose user access issues, demonstrate user/resource access audit`,
      task: GettingStartedTask.completed_audit_user,
      link: 'audit-user-guide',
    },
    {
      text: `Understand billing, setup a payment method`,
      task: GettingStartedTask.completed_setup_payment,
      link: 'setup-payment-guide',
    },
  ];
  public overrideAllShowDemoChecks = true;

  public getTaskCompletedStatus = getTaskCompletedStatus;

  constructor(
    private store: Store<AppState>,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    public welcomeDialog: MatDialog,
    private eventsService: EventsService
  ) {}

  private showWelcomeDialog(currentOrgResp: Organisation): void {
    if (currentOrgResp && currentOrgResp.created && !this.dialogOpen) {
      if (this.shownWelcome === false) {
        this.shownWelcome = true;
        const age = new Date().getTime() - new Date(currentOrgResp.created).getTime();
        if (age < 600 * 1000) {
          this.openWelcomeDialog();
        }
      }
    }
  }

  public ngOnInit(): void {
    // Scroll to top of page when component is loaded.
    this.scrollToTop();
    const orgId$ = this.store.pipe(select(selectApiOrgId));
    const currentOrg$ = this.store.pipe(select(selectCurrentOrg));
    const gettingStartedData$ = this.store.pipe(select(selectGettingStartedData));
    const currentUser$ = this.store.pipe(select(selectUser));
    combineLatest([orgId$, gettingStartedData$, currentUser$, currentOrg$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([orgIdResp, gettingStartedDataResp, currentUserResp, currentOrgResp]) => {
        this.orgId = orgIdResp;
        this.gettingStartedDataCopy = cloneDeep(gettingStartedDataResp);
        this.currentUser = currentUserResp;
        this.currentOrg = currentOrgResp;
        this.changeDetector.detectChanges();
        this.showWelcomeDialog(currentOrgResp);
      });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public dismissGettingStarted(): void {
    this.gettingStartedDataCopy.hide_getting_started = true;
    this.store.dispatch(new ActionUserUpdateGettingStartedData(this.gettingStartedDataCopy));
    this.routeToCommonTasksScreen();
  }

  private routeToCommonTasksScreen(): void {
    this.router.navigate(['/'], {
      queryParams: { org_id: this.orgId },
    });
  }

  public routeToGuideScreen(guideLink: string): void {
    this.router.navigate([`/${guideLink}`], {
      queryParams: { org_id: this.orgId },
    });
  }

  public openWelcomeDialog(): void {
    this.eventsService.SendEvent({
      event_id: 'welcome-dialog',
      event_type: 'admin-nag',
      category: 'getting-started',
      sub_category: 'welcome',
      org_id: this.currentOrg.id,
    });
    this.dialogOpen = true;
    const messagePrefix = `Welcome To Agilicus AnyX`;
    let message = `

     <br>
     <p>Please <b>bookmark</b> this page (https://${window.location.hostname}), it is your personal administrative portal.
     <p><br>On the left menu you will see a 'Getting Started' which gives some initial configuration suggestions, you may return to it at anytime.
     <p><br>If you have any questions, please use either the chat-icon in the lower-right, or email support@agilicus.com.
    `;
    const dialogData = createDialogData(messagePrefix, message);
    dialogData.icon = 'emoji_people';
    const dialogRef = this.welcomeDialog.open(ConfirmationDialogComponent, {
      data: { ...dialogData, buttonText: { confirm: 'OK', cancel: '' } },
    });
  }

  /**
   * Scrolls to the top of the page.
   */
  public scrollToTop(): void {
    scrollToTop();
  }

  private findFirstIncompleteTask(): ListItemData | undefined {
    for (const item of this.listItemData) {
      if (!getTaskCompletedStatus(item.task, this.gettingStartedDataCopy)) {
        return item;
      }
    }
    return undefined;
  }

  public isFirstIncompleteTask(item: ListItemData | undefined): boolean {
    if (!item) {
      return false;
    }
    const firstIncompleteTask = this.findFirstIncompleteTask();
    return !!firstIncompleteTask ? item.task === firstIncompleteTask.task : false;
  }
}

export enum ColumnTypes {
  INPUT = 'input',
  INPUT_LINK = 'input_link',
  AUTOINPUT = 'autoinput',
  SELECT = 'select',
  CHIPLIST = 'chip',
  CHECK = 'check',
  ICON = 'icon',
  ACTIONS = 'actions',
  SELECT_ROW = 'select_row',
  FILE = 'file',
  READONLY = 'readonly',
  EXPAND = 'expand',
  DATE_PICKER = 'date_picker',
  /**
   * Acts as a "placeholder" for a row of adjacent hidden columns. It is a visual indicator that
   * there are columns hidden and allows the user to click on the column header to uncollapse them.
   */
  COLLAPSED = 'collapsed',
}

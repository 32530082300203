export enum InputSize {
  STANDARD = 'STANDARD',
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
  DATE = 'DATE',
  ACTIONS = 'ACTIONS',
  TEXT_INPUT = 'TEXT_INPUT',
  TEXT_INPUT_LARGE = 'TEXT_INPUT_LARGE',
  SELECT_INPUT = 'SELECT_INPUT',
  SELECT_ROW = 'SELECT_ROW',
}

<div class="dialog-container">
  <mat-dialog-content>
    <mat-card class="inbox-card">
      <mat-card-header>
        <div
          *ngIf="inboxItem.status.message.icon"
          [style]="getIconUrlStyle()"
          mat-card-avatar
          class="inbox-header-image"
        >
        </div>
        <mat-card-title>{{inboxItem.status.message.title}}</mat-card-title>
        <mat-card-subtitle>{{inboxItem.status.message.sub_header}}</mat-card-subtitle>
      </mat-card-header>
      <hr>
      <mat-card-content>
        <p [innerHTML]="inboxItem.status.message.text"></p>
    
        <span *ngIf="inboxItem?.status?.message?.uri">
          <portal-product-guide-link
            *ngIf="shouldAddDeepLink(inboxItem.status.message.uri)"
            class="inbox-message-url"
            [productGuideLink]="inboxItem.status.message.uri"
            [productGuideLinkText]="getMessageUriText(inboxItem)">
          </portal-product-guide-link>
    
          <a
            *ngIf="!shouldAddDeepLink(inboxItem.status.message.uri)"
            class="inbox-message-url"
            [href]="inboxItem.status.message.uri"
            (click)="openMessageUri($event)"
          >
            {{ getMessageUriText(inboxItem) }}
          </a>
        </span>
    
      </mat-card-content>
      <img
        *ngIf="inboxItem?.status?.message?.image"
        mat-card-image src="{{ inboxItem.status.message.image }}"
      >
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <div *ngIf="showCustomActionButtons(inboxItem)">
      <button
        *ngFor="let action of inboxItem.status.message.actions"
        type="button"
        mat-raised-button
        color="primary"
        (click)="fetchActionUri(action)"
        mat-button
        class="custom-button"
      >
        {{ getFormattedButtonText(action.title) }}
      </button>
    </div>
    
    <button
      mat-raised-button
      [color]="getCloseButtonColor()"
      (click)="onCloseClick()"
    >
      CLOSE
    </button>
  </mat-dialog-actions>
</div>


import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { AppState } from '@app/core';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { select, Store } from '@ngrx/store';
import { Connector, ConnectorSpec, SSHResource, LabelledObject, Resource } from '@agilicus/angular';
import { combineLatest, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ButtonType } from '../button-type.enum';
import { FilterManager } from '../filter/filter-manager';
import {
  ActionMenuOptions,
  ChiplistColumn,
  Column,
  createActionsColumn,
  createInputColumn,
  createResourceIconColumn,
  createSelectColumn,
  createSelectRowColumn,
  IconColumn,
  SelectColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { isValidHostnameOrIp4, isValidResourceName, isValidPort } from '../validation-utils';
import {
  isConnectorBeingRemovedFromResource,
  openRemoveConnectorFromResourceWarningDialog,
  setConnectorColumnAllowedValues,
} from '../connector-utils';
import { getDefaultTableProperties } from '../table-layout-utils';
import { canNavigateFromTable } from '../../../core/auth/auth-guard-utils';
import {
  deletingSSHResources,
  initSSHResources,
  resetIconStatusSSHResources,
  savingIconSSHResources,
  savingSSHResource,
} from '@app/core/ssh-state/ssh.actions';
import { initConnectors } from '@app/core/connector-state/connector.actions';
import {
  selectSSHResourceEntity,
  selectSSHResourceIconStatus,
  selectSSHResourceList,
  selectSSHResourceRefreshDataValue,
} from '@app/core/ssh-state/ssh.selectors';
import { selectConnectorList, selectConnectorRefreshDataValue } from '@app/core/connector-state/connector.selectors';
import { getIconURIFromResource, updateTableElements } from '../utils';
import { cloneDeep } from 'lodash-es';
import { ButtonColor, TableButton, TableScopedButton } from '../buttons/table-button/table-button.component';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { InputData } from '../custom-chiplist-input/input-data';
import { ResourceLogoDialogComponent, ResourceLogoDialogData } from '../resource-logo-dialog/resource-logo-dialog.component';
import { ResourceType } from '../resource-type.enum';
import { getDefaultDialogConfig, getDefaultLogoDialogConfig } from '../dialog-utils';
import { RouterHelperService } from '@app/core/router-helper/router-helper.service';
import { OptionalSSHResourceElement } from '../optional-types';
import { AuditRoute } from '../audit-subsystem/audit-subsystem.component';
import { SshCredentialsDialogComponent, SshCredentialsDialogData } from '../ssh-credentials-dialog/ssh-credentials-dialog.component';
import { initPolicyTemplateInstances } from '@app/core/policy-template-instance-state/policy-template-instance.actions';
import {
  PolicyTemplateInstanceWithLabels,
  ResourceTableElement,
} from '@app/core/api/policy-template-instance/policy-template-instance-utils';
import { PolicyResourceLinkService } from '@app/core/policy-resource-link-service/policy-resource-link.service';
import { FeatureGateService } from '@app/core/services/feature-gate.service';
import { getTrimmedResourceName } from '../resource-utils';
import { TableLayoutComponent } from '../table-layout/table-layout.component';

export interface SSHResourceElement extends ResourceTableElement {
  name: string;
  address: string;
  port: string;
  connector_name: string;
  prevous_connector_name: string;
  username?: string | null;
  backingObject: SSHResource;
}

export interface CombinedPermissionsAndSSHResourceData {
  permission: OrgQualifiedPermission;
  sshResources: Array<SSHResource>;
  connectors: Array<Connector>;
}

@Component({
  selector: 'portal-ssh-overview',
  templateUrl: './ssh-overview.component.html',
  styleUrls: ['./ssh-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SshOverviewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasAppsPermissions: boolean;
  private orgId: string;
  public tableData: Array<SSHResourceElement> = [];
  public columnDefs: Map<string, Column<SSHResourceElement>> = new Map();
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'SSH';
  public buttonsToShow: Array<ButtonType> = [ButtonType.DELETE];
  public customButtons: Array<TableButton> = [
    new TableScopedButton(
      `ADD ${this.rowObjectName}`,
      ButtonColor.PRIMARY,
      `Add a new ${this.rowObjectName.toLowerCase()}`,
      `Button that adds a new ${this.rowObjectName.toLowerCase()}`,
      () => {
        this.router.navigate([`/${this.rowObjectName.toLowerCase()}-new`], {
          queryParams: { org_id: this.orgId },
        });
      }
    ),
  ];
  public doNotPluralizeRowObjectName = true;
  public warnOnNOperations = 1;
  private connectorIdToConnectorMap: Map<string, Connector> = new Map();
  private connectorNameToConnectorMap: Map<string, Connector> = new Map();
  public pageDescriptiveText = `SSH (Secure Shell) is a remote command-line interface for system management.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/zero-trust-ssh-access/`;
  private localSSHRefreshDataValue = 0;
  private localConnectorsRefreshDataValue = 0;
  // For policy column:
  private policyTemplateInstanceList: Array<PolicyTemplateInstanceWithLabels> = [];
  // For policy column:
  private resources: Array<Resource> = [];
  // For policy column:
  private labelledObjects: Array<LabelledObject> = [];
  // For policy column:
  private policyNameAndTypeStringToPolicyMap: Map<string, PolicyTemplateInstanceWithLabels> = new Map();
  // For policy column:
  private localPoliciesRefreshDataValue = 0;

  @ViewChild('tableLayoutComp') tableLayoutComp: TableLayoutComponent<SSHResourceElement>;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    public dialog: MatDialog,
    private routerHelperService: RouterHelperService,
    private policyResourceLinkService: PolicyResourceLinkService<SSHResourceElement>,
    private featureGateService: FeatureGateService
  ) {}

  public shouldGateAccess(): boolean {
    return !this.featureGateService.shouldEnablePolicyTemplates();
  }

  public ngOnInit(): void {
    this.store.dispatch(initSSHResources({ force: true, blankSlate: false }));
    this.store.dispatch(initConnectors({ force: true, blankSlate: false }));
    // For policy column:
    this.store.dispatch(initPolicyTemplateInstances({ force: true, blankSlate: false }));
    this.initializeColumnDefs();
    const hasAppsPermissions$ = this.store.pipe(select(selectCanAdminApps));
    const sshResourceListState$ = this.store.pipe(select(selectSSHResourceList));
    const connectorListState$ = this.store.pipe(select(selectConnectorList));
    const refreshSSHDataState$ = this.store.pipe(select(selectSSHResourceRefreshDataValue));
    const refreshConnectorDataState$ = this.store.pipe(select(selectConnectorRefreshDataValue));
    combineLatest([
      hasAppsPermissions$,
      this.policyResourceLinkService.getPolicyAndResourceAndLabelDataWithPermissions$(),
      sshResourceListState$,
      connectorListState$,
      refreshSSHDataState$,
      refreshConnectorDataState$,
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        ([
          hasAppsPermissionsResp,
          getPermissionsAndResourceAndLabelDataResp,
          sshResourceListStateResp,
          connectorListStateResp,
          refreshSSHDataStateResp,
          refreshConnectorDataStateResp,
        ]) => {
          this.orgId = hasAppsPermissionsResp.orgId;
          this.hasAppsPermissions = hasAppsPermissionsResp.hasPermission;
          // For policy column:
          this.policyTemplateInstanceList = getPermissionsAndResourceAndLabelDataResp.policyTemplateInstanceList;
          // For policy column:
          this.resources = getPermissionsAndResourceAndLabelDataResp.resourcesList;
          // For policy column:
          this.labelledObjects = getPermissionsAndResourceAndLabelDataResp.labelledObjects;
          if (!this.hasAppsPermissions || !connectorListStateResp || !sshResourceListStateResp || sshResourceListStateResp.length === 0) {
            this.resetEmptyTable();
            return;
          }
          this.setConnectorIdToNameMap(connectorListStateResp);
          setConnectorColumnAllowedValues(
            this.columnDefs.get('connector_name'),
            connectorListStateResp,
            this.orgId,
            ConnectorSpec.ConnectorTypeEnum.agent,
            [ConnectorSpec.ConnectorTypeEnum.agent]
          );
          // For policy column:
          this.policyResourceLinkService.setPolicyNameAndTypeStringMap(
            this.policyNameAndTypeStringToPolicyMap,
            this.policyTemplateInstanceList
          );
          // For policy column:
          this.policyResourceLinkService.setResourceTablePoliciesColumnAllowedValuesList(this.columnDefs, this.policyTemplateInstanceList);
          if (
            this.tableData.length === 0 ||
            this.localSSHRefreshDataValue !== refreshSSHDataStateResp ||
            this.localConnectorsRefreshDataValue !== refreshConnectorDataStateResp ||
            this.localPoliciesRefreshDataValue !== getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue
          ) {
            this.localSSHRefreshDataValue = refreshSSHDataStateResp;
            this.localConnectorsRefreshDataValue = refreshConnectorDataStateResp;
            this.localPoliciesRefreshDataValue = getPermissionsAndResourceAndLabelDataResp.refreshPolicyTemplateInstanceDataStateValue;
            if (
              this.localSSHRefreshDataValue !== 0 &&
              this.localConnectorsRefreshDataValue !== 0 &&
              this.localPoliciesRefreshDataValue !== 0
            ) {
              // Only render the table data once all fresh data is retrieved from the ngrx state.
              // Once each state is updated the local refresh values will have incremented by at least 1.
              this.updateTable(sshResourceListStateResp);
            }
          }
          this.changeDetector.detectChanges();
        }
      );
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setConnectorIdToNameMap(connectors: Array<Connector>): void {
    this.connectorIdToConnectorMap.clear();
    this.connectorNameToConnectorMap.clear();
    for (const connector of connectors) {
      this.connectorIdToConnectorMap.set(connector.metadata.id, connector);
      this.connectorNameToConnectorMap.set(connector.spec.name, connector);
    }
  }

  /**
   * Parent table column
   */
  private getIconColumn(): IconColumn<SSHResourceElement> {
    return createResourceIconColumn<InputData, SSHResourceElement>(this.getIconURIFromElement.bind(this), this.openIconDialog.bind(this));
  }

  private getIconURIFromElement(element: SSHResourceElement): string {
    return getIconURIFromResource(element.backingObject);
  }

  public openIconDialog(element: SSHResourceElement): void {
    const dialogData: ResourceLogoDialogData<SSHResource> = {
      resourceType: ResourceType.ssh,
      saveAction: savingIconSSHResources,
      resetStatusAction: resetIconStatusSSHResources,
      getResourceStateSelector: selectSSHResourceEntity({ id: element.backingObject.metadata.id }),
      getIconStatusStateSelector: selectSSHResourceIconStatus,
    };
    const dialogRef = this.dialog.open(
      ResourceLogoDialogComponent,
      getDefaultLogoDialogConfig({
        data: dialogData,
      })
    );
  }

  /**
   * Parent table column
   */
  private getNameColumn(): Column<SSHResourceElement> {
    const nameColumn = createInputColumn('name');
    nameColumn.isEditable = true;
    nameColumn.requiredField = () => true;
    nameColumn.isValidEntry = (name: string): boolean => {
      return isValidResourceName(name);
    };
    nameColumn.getFormattedValue = (name: string, element: SSHResourceElement): string => {
      return getTrimmedResourceName(name);
    };
    return nameColumn;
  }

  /**
   * Parent table column
   */
  private getAddressColumn(): Column<SSHResourceElement> {
    const addressColumn = createInputColumn('address');
    addressColumn.isEditable = true;
    addressColumn.requiredField = () => true;
    addressColumn.isValidEntry = (address: string): boolean => {
      return isValidHostnameOrIp4(address);
    };
    return addressColumn;
  }

  /**
   * Parent table column
   */
  private getPortColumn(): Column<SSHResourceElement> {
    const portColumn = createInputColumn('port');
    portColumn.isEditable = true;
    portColumn.isValidEntry = (port: string): boolean => {
      return isValidPort(port);
    };
    return portColumn;
  }

  /**
   * Parent table column
   */
  private getConnectorNameColumn(): SelectColumn<SSHResourceElement> {
    const connectorNameColumn = createSelectColumn('connector_name');
    connectorNameColumn.displayName = 'Connector';
    connectorNameColumn.isEditable = true;
    return connectorNameColumn;
  }

  /**
   * Parent Table Column
   */
  private getPoliciesColumn(): ChiplistColumn<SSHResourceElement> {
    return this.policyResourceLinkService.getResourceTablePoliciesColumn(this.policyNameAndTypeStringToPolicyMap);
  }

  /**
   * Parent Table Column
   */
  private getActionsColumn(): Column<SSHResourceElement> {
    const actionsColumn = createActionsColumn('actions');
    const menuOptions: Array<ActionMenuOptions<SSHResourceElement>> = [
      {
        displayName: 'Search in Audits',
        icon: 'search',
        tooltip: 'Click to filter audits',
        onClick: (element: OptionalSSHResourceElement) => {
          const auditRouteData: AuditRoute = {
            org_id: element.backingObject.spec.org_id,
            target_resource_id: element.backingObject.metadata.id,
            target_resource_name: element.name,
          };
          this.routerHelperService.redirect('audit-subsystem/', auditRouteData);
        },
      },
      {
        displayName: 'Manage Credentials',
        icon: 'lock',
        tooltip: 'Click to manage credentials for this SSH',
        onClick: (element: OptionalSSHResourceElement) => {
          const dialogData: SshCredentialsDialogData = {
            ssh: element.backingObject,
          };
          const dialogRef = this.dialog.open(
            SshCredentialsDialogComponent,
            getDefaultDialogConfig({
              data: dialogData,
              maxWidth: '950px',
              minHeight: '500px',
            })
          );
        },
      },
      {
        displayName: 'Configure Policies',
        icon: 'vpn_key',
        tooltip: 'Click to access the "Policies" advanced configuration',
        columnName: this.policyResourceLinkService.getPoliciesColumnName(),
      },
    ];
    actionsColumn.allowedValues = menuOptions;
    return actionsColumn;
  }

  private initializeColumnDefs(): void {
    if (this.shouldGateAccess()) {
      // Do not include policies column:
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getAddressColumn(),
          this.getPortColumn(),
          this.getConnectorNameColumn(),
          this.getActionsColumn(),
        ],
        this.columnDefs
      );
    } else {
      setColumnDefs(
        [
          createSelectRowColumn(),
          this.getIconColumn(),
          this.getNameColumn(),
          this.getAddressColumn(),
          this.getPortColumn(),
          this.getConnectorNameColumn(),
          this.getPoliciesColumn(),
          this.getActionsColumn(),
        ],
        this.columnDefs
      );
    }
  }

  private updateTable(sshResources: Array<SSHResource>): void {
    this.buildData(sshResources);
    this.replaceTableWithCopy();
  }

  private buildData(sshResources: Array<SSHResource>): void {
    const data: Array<SSHResourceElement> = [];
    for (let i = 0; i < sshResources.length; i++) {
      const sshResource = sshResources[i];
      data.push(this.createTableElement(sshResource, i));
    }
    updateTableElements(this.tableData, data);
  }

  private createTableElement(item: SSHResource, index: number): SSHResourceElement {
    const connectorName = this.connectorIdToConnectorMap.get(item.spec.connector_id)?.spec.name;
    const data: SSHResourceElement = {
      name: item.spec.name,
      address: item.spec.address,
      port: item.spec.config.ports[0].port,
      connector_name: connectorName ? connectorName : '',
      prevous_connector_name: connectorName ? connectorName : '',
      username: item.spec.username,
      backingObject: cloneDeep(item),
      ...getDefaultTableProperties(index),
      ...this.policyResourceLinkService.getResourceElementWithPolicies(
        item,
        this.resources,
        this.labelledObjects,
        this.policyTemplateInstanceList
      ),
    };
    return data;
  }

  public deleteSelected(itemElementsToDelete: Array<SSHResourceElement>): void {
    const itemsToDelete: Array<SSHResource> = this.getItemsFromTableElements(itemElementsToDelete);
    // Need to make a copy of the sshResourcesToDelete or it will be
    // converted to readonly.
    this.store.dispatch(deletingSSHResources({ objs: cloneDeep(itemsToDelete), trigger_update_side_effects: false, notifyUser: true }));
  }

  public showNoPermissionsText(): boolean {
    return this.hasAppsPermissions !== undefined && !this.hasAppsPermissions;
  }

  public getItemsFromTableElements(tableElements: Array<SSHResourceElement>): Array<SSHResource> {
    return tableElements.map((item) => item.backingObject);
  }

  private getItemFromTableElement(tableElement: SSHResourceElement): SSHResource {
    const result: SSHResource = tableElement.backingObject;
    result.spec.name = tableElement.name;
    result.spec.address = tableElement.address;
    result.spec.config.ports[0].port = tableElement.port;
    result.spec.username = tableElement.username;
    return result;
  }

  /**
   * Receives a SSHResourceElement from the table then updates and saves
   * the SSH resource.
   */
  public updateEvent(updatedSSHResourceElement: SSHResourceElement): void {
    if (isConnectorBeingRemovedFromResource(updatedSSHResourceElement)) {
      openRemoveConnectorFromResourceWarningDialog(updatedSSHResourceElement, this.dialog, this.saveItem.bind(this));
      return;
    }
    this.saveItem(updatedSSHResourceElement as SSHResourceElement);
  }

  private saveItem(updatedTableElement: SSHResourceElement): void {
    const updatedItem = this.getItemFromTableElement(updatedTableElement);
    this.policyResourceLinkService
      .submitPoliciesResult$(updatedTableElement, this.orgId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resourceAndLabelsAndPolicyResponse) => {
        this.policyResourceLinkService.onSubmitPoliciesFinish(updatedTableElement, resourceAndLabelsAndPolicyResponse);
        // Need to make a copy of the object or it will be converted to readonly.
        this.store.dispatch(savingSSHResource({ obj: cloneDeep(updatedItem), trigger_update_side_effects: false, notifyUser: true }));
        this.changeDetector.detectChanges();
      });
  }

  /**
   * Triggered when a user selects a new option from the dropdown menu
   * in the table. The data is sent from the table-layout to this component.
   */
  public updateSelection(params: { value: string; column: Column<SSHResourceElement>; element: SSHResourceElement }): void {
    if (params.column.name === 'connector_name') {
      const elementCopy = cloneDeep(params.element);
      params.element.prevous_connector_name = elementCopy.connector_name;
      params.element.connector_name = params.value;
      const targetConnector = this.connectorNameToConnectorMap.get(params.value);
      if (!!targetConnector) {
        params.element.backingObject.spec.connector_id = targetConnector.metadata.id;
      } else {
        params.element.backingObject.spec.connector_id = '';
      }
    }
  }

  /**
   * Resets the data to display an empty table.
   */
  private resetEmptyTable(): void {
    this.tableData = [];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public canDeactivate(): Observable<boolean> | boolean {
    return canNavigateFromTable(this.tableData, this.columnDefs, this.updateEvent.bind(this));
  }
}

<div
  class="tab-component-outer-container"
  portalSwipeable
  [appTabState]="uiState?.tabsState"
  [tabGroupId]="tabGroupId"
  [tabsLength]="tabsLength"
>
  <div
    class="top-header top"
    [ngClass]="{
      'no-tabs-top-header': !isHostedApplicationsEnabled()
    }"
  >
    <mat-icon
      class="back-arrow"
      (click)="returnToApp()"
      matTooltip="Return to Define Application"
    >
      arrow_back
    </mat-icon>
    <span class="title-prefix">Configure Instance:</span>
  
    <span
      *ngIf="!!currentApplicationCopy?.environments"
      class="title-prefix title-app-name"
      matTooltip="The name of the currently selected application"
    >
      {{ currentApplicationCopy.name }}
    </span>
    
    <div
      *ngIf="!!currentApplicationCopy?.environments && !!instanceSelectorForm && isHostedApplicationsEnabled()"
      class="main-selector-container"
    >
      <form
        [formGroup]="instanceSelectorForm"
        spellcheck="false"
        autocomplete="off"
      >
        <mat-form-field class="select-instance-form-field">
          <mat-select
            #select
            formControlName="instance_selection"
            (selectionChange)="selectCurrentInstance($event.value)"
            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select)"
          >
            <mat-option
              *ngFor="let option of getInstancesVersionTagList()"
              [value]="option"
            >
              {{ option }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-icon
          class="help-icon select-instance-help-icon"
          [matTooltip]="getInstanceSelectorTooltipText()"
        >
          help_outline
        </mat-icon>
      </form>
    </div>
  </div>

  <!-- This empty div is so the background of the tab header will appear to span the full screen width -->
  <div class="background-span" *ngIf="!isSmallScreen && isHostedApplicationsEnabled()"></div>

  <div
    class="main-content"
    [ngClass]="{
      'no-tabs-main-content': !isHostedApplicationsEnabled()
    }"
  >
    <div *ngIf="currentEnvironment !== undefined" class="content-container">
      <div class="tab-group-container row content">
        <mat-tab-group
          [headerPosition]="getTabHeaderPositionFromScreenSizeFunc()"
          mat-stretch-tabs
          animationDuration="0ms"
          class="tab-group-overrides"
          [ngClass]="{
            'fixed-tabs-top': !isSmallScreen,
            'invisible-tabs': !isHostedApplicationsEnabled()
          }"
          [selectedIndex]="localTabIndex"
          portalTabState [appTabState]="uiState.tabsState" [tabGroupId]="tabGroupId"
        >
          <!-- Common Tab -->
          <mat-tab>
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                settings_applications
              </mat-icon>
              <span>Common</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card appearance="outlined" class="tab-card-container">
                <div class="tab-content-container">
                  <portal-page-info
                    pageDescriptiveText="Configure the common properties of this instance."
                    productGuideLink="https://www.agilicus.com/anyx-guide/product-guide-applications/#h-instance-details"
                    pageInfoWidth="full"
                  >
                  </portal-page-info>
                  <div *ngIf="!!envForm && !!allOrganisations" class="env-update-form-container">
                    <form
                      class="env-update-form" 
                      [formGroup]="envForm"
                      spellcheck="false"
                      autocomplete="off"
                    >
                      <span *ngIf="isHostedApplicationsEnabled()">
                        <mat-form-field>
                          <input 
                            matInput 
                            type="text" 
                            placeholder="Version Tag" 
                            formControlName="version_tag"
                            required 
                            (blur)="onFormBlur(envForm, 'version_tag')"
                            (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                          >
                          <mat-error>Version Tag is required</mat-error>
                        </mat-form-field>
              
                        <mat-form-field>
                          <mat-label>Owner</mat-label>
                          <mat-select 
                            #selectOwner
                            formControlName="maintenance_org_name"
                            (selectionChange)="modifyApplicationOnFormSelectionChange('maintenance_org_name')"
                            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectOwner)"
                            required 
                          >
                            <mat-option value=""></mat-option>
                            <mat-option 
                              *ngFor="let org of allOrganisations"
                              value="{{ org.organisation }}"
                            >
                              {{ org.organisation }}
                            </mat-option>
                          </mat-select>
                          <mat-error>Owner is required</mat-error>
                        </mat-form-field>
              
                        <mat-form-field class="chiplist-form-field">
                          <mat-label>Organisations</mat-label>
                          <mat-chip-grid #assignmentsChipList>
                            <mat-chip-row
                              *ngFor="let assignment of currentAssignments"
                              [selectable]="filterChipOptions.selectable"
                              [removable]="filterChipOptions.removable"
                              (removed)="removeAssignmentChip(getOrgNameFromId(assignment.org_id))"
                            >
                              {{ getOrgNameFromId(assignment.org_id) }}
                              <mat-icon matChipRemove *ngIf="isEnvironmentEditable()">cancel</mat-icon>
                            </mat-chip-row>
                            <input
                              #input
                              formControlName="organisations"
                              [matChipInputFor]="assignmentsChipList"
                              [matAutocomplete]="auto"
                              [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                              [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
                              (matChipInputTokenEnd)="addAssignmentChipOnInputEvent($event)"
                              matTooltip="Separate multiple entries by a semicolon"
                              #trigger="matAutocompleteTrigger"
                              (keyup.enter)="keyTabManager.keyTabChipList($event.target.value, $event.target.id, trigger, false)"
                            />
                          </mat-chip-grid>
                          <mat-autocomplete
                            #auto="matAutocomplete"
                            (optionSelected)="
                            addChipOnAutoSelect($event.option.value); input.value = ''
                            "
                          >
                            <span *ngFor="let option of filteredOrgOptions$ | async">
                              <mat-option
                                [value]="option"
                                *ngIf="!isOptionAlreadySelected(option)"
                              >
                                {{ option }}
                              </mat-option>
                            </span>
                          </mat-autocomplete>
                        </mat-form-field>
              
                        <mat-form-field>
                          <mat-label>Bundle Name</mat-label>
                          <mat-select 
                            #selectBundle
                            formControlName="serverless_image"
                            (selectionChange)="modifyApplicationOnFormSelectionChange('serverless_image')"
                            (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, selectBundle)"
                          >
                            <mat-option value=""></mat-option>
                            <mat-option 
                              *ngFor="let appBundle of appBundles"
                              value="{{ appBundle.label }}"
                            >
                              {{ appBundle.label }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </span>
            
                      <mat-form-field class="chiplist-form-field">
                        <mat-label>Fully-qualified domain names</mat-label>
                        <mat-chip-grid #fqdnAliasChipList>
                          <mat-chip-row
                            *ngFor="let fqdnAlias of getCurrentEnvFqdnAliases()"
                            [selectable]="filterChipOptions.selectable"
                            [removable]="filterChipOptions.removable"
                            (removed)="removeFqdnAliasChip(fqdnAlias)"
                          >
                            {{ fqdnAlias }}
                            <mat-icon matChipRemove *ngIf="isEnvironmentEditable()">cancel</mat-icon>
                          </mat-chip-row>
                          <input
                            #input
                            matInput
                            formControlName="domain_aliases"
                            [matChipInputFor]="fqdnAliasChipList"
                            [matChipInputSeparatorKeyCodes]="filterChipOptions.separatorKeysCodes"
                            [matChipInputAddOnBlur]="filterChipOptions.addOnBlur"
                            (matChipInputTokenEnd)="addFqdnAliasOnInputEvent($event)"
                            matTooltip="Separate multiple entries by a semicolon"
                            (keyup.enter)="keyTabManager.keyTabChipList($event.target.value, $event.target.id, undefined, false)"
                          />
                        </mat-chip-grid>
                      </mat-form-field>

                      <div *ngIf="isUsingAgent() && isLuaWafEnabled() && isHostedApplicationsEnabled()">
                        <portal-proxy-location-selection
                          [formCtrl]="envForm.get('proxy_location')"
                          label="Data Path Processing:"
                          (handleProxyLocationOptionChange)="onProxyLocationOptionChange($event)"
                        >
                        </portal-proxy-location-selection>
                      </div>
                    </form>
                  </div>
            
                  <portal-application-environment-status></portal-application-environment-status>
                </div>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- File Tab -->
          <mat-tab *ngIf="isHostedApplicationsEnabled()">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                insert_drive_file
              </mat-icon>
              <span>File</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card appearance="outlined" class="tab-card-container">
                <portal-application-environment-files
                  [fixedTable]="fixedData"
                >
                </portal-application-environment-files>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- Environment Tab -->
          <mat-tab *ngIf="isHostedApplicationsEnabled()">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                widgets
              </mat-icon>
              <span>Environment</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card appearance="outlined" class="tab-card-container">
                <portal-application-environment-variables
                  [fixedTable]="fixedData"
                >
                </portal-application-environment-variables>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- External Mounts Tab -->
          <mat-tab *ngIf="isHostedApplicationsEnabled()">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                storage
              </mat-icon>
              <span>External Mounts</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card appearance="outlined" class="tab-card-container">
                <portal-application-environment-external-mounts
                  [fixedTable]="fixedData"
                >
                </portal-application-environment-external-mounts>
              </mat-card>
            </ng-template>
          </mat-tab>

          <!-- Temporary Directories Tab -->
          <mat-tab *ngIf="isHostedApplicationsEnabled()">
            <ng-template mat-tab-label>
              <mat-icon class="tab-icon" *ngIf="!isSmallScreen">
                folder
              </mat-icon>
              <span>Temporary Directories</span>
            </ng-template>
            <ng-template matTabContent>
              <mat-card appearance="outlined" class="tab-card-container">
                <portal-application-environment-temporary-directories
                  [fixedTable]="fixedData"
                >
                </portal-application-environment-temporary-directories>
              </mat-card>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
import { NavMenuItem } from './submenu.component';
import { getUserTypeIcon } from '../utils';
import { getResourceTypeIcon } from '../resource-utils';
import { ResourceType } from '../resource-type.enum';
import { User } from '@agilicus/angular';
import { PermissionsDefinition } from '@app/core/user/permissions/permissions-definition.enum';

export function getNewsSideNavMenu(): NavMenuItem {
  return {
    name: 'News',
    id: 'news',
    routerLink: 'news',
    disabled: false,
    requiredPermissions: [],
  };
}

export function getMenuItemId(defaultId: string, subString: string): string {
  return `${defaultId}-${subString}`;
}

export function getGettingStartedSideNavMenu(): NavMenuItem {
  const defaultId = 'getting-started';
  return {
    id: defaultId,
    name: 'Getting Started',
    disabled: false,
    requiredPermissions: [],
    items: [
      {
        id: getMenuItemId(defaultId, 'overview'),
        name: 'Overview',
        icon: 'description',
        routerLink: 'getting-started',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'connect-to-sites-guide'),
        name: 'Connect To Sites',
        icon: 'device_hub',
        routerLink: 'connect-to-sites-guide',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'identify-users-guide'),
        name: 'Identify Users',
        icon: 'perm_identity',
        routerLink: 'identify-users-guide',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'expose-resource-guide'),
        name: 'Expose Resource',
        icon: 'grain',
        routerLink: 'expose-resource-guide',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'assign-permissions-guide'),
        name: 'Assign Permissions',
        icon: 'security',
        routerLink: 'assign-permissions-guide',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'audit-user-guide'),
        name: 'Audit User',
        icon: 'offline_pin',
        routerLink: 'audit-user-guide',
        disabled: false,
        requiredPermissions: [],
      },
      {
        id: getMenuItemId(defaultId, 'setup-payment-guide'),
        name: 'Setup Payment',
        icon: 'payment',
        routerLink: 'setup-payment-guide',
        disabled: false,
        requiredPermissions: [],
      },
    ],
  };
}

export function getLabelOverviewSideNavMenu(): NavMenuItem {
  const defaultId = 'label';
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'labels'),
    name: 'Labels',
    icon: 'label',
    routerLink: 'labels',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasLabelAdminPermissions, PermissionsDefinition.hasResourceAdminPermissions],
  };
  return overviewMenuItem;
}

export function getSupportRequestsOverviewSideNavMenu(): NavMenuItem {
  const defaultId = 'support-request';
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'support-requests'),
    name: 'Support Requests',
    icon: 'help_center',
    routerLink: 'support-requests',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  return overviewMenuItem;
}

export function getOrganisationSideNavMenu(): NavMenuItem {
  const defaultId = 'organisation';
  const subOrgNewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'sub-org-new'),
    name: 'New',
    icon: 'business',
    routerLink: 'sub-org-new',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'org-admin'),
    name: 'Overview',
    icon: 'description',
    routerLink: 'org-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const billingMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'billing'),
    name: 'Billing',
    icon: 'receipt',
    routerLink: 'billing',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersReadOrAdminPermissions],
  };
  const subOrgMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'sub-org-admin'),
    name: 'Sub-Organisations Overview',
    icon: 'business',
    routerLink: 'sub-org-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const authAuditMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'authentication-audit'),
    name: 'Authentication Audit',
    icon: 'work_outline',
    routerLink: 'authentication-audit',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasAuditPermissions],
  };
  const auditSubsystemMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'audit-subsystem'),
    name: 'Audit Subsystem',
    icon: 'book',
    routerLink: 'audit-subsystem',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasAuditPermissions],
  };
  const auditDestinationsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'audit-destinations'),
    name: 'Audit Destinations',
    icon: 'folder_shared',
    routerLink: 'audit-destinations',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasAuditDestinationAdminPermissions],
  };
  const labelOverviewSideNavMenu = getLabelOverviewSideNavMenu();

  const menuItemsList = [
    subOrgNewMenuItem,
    overviewMenuItem,
    billingMenuItem,
    subOrgMenuItem,
    authAuditMenuItem,
    auditSubsystemMenuItem,
    auditDestinationsMenuItem,
    labelOverviewSideNavMenu,
    getSupportRequestsOverviewSideNavMenu(),
  ];

  return {
    id: defaultId,
    name: 'Organisation',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getAuthenticationSideNavMenu(): NavMenuItem {
  const defaultId = 'authentication';
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'authentication-overview'),
    name: 'Overview',
    icon: 'description',
    routerLink: 'authentication-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions],
  };
  const themingMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'theming'),
    name: 'Theming',
    icon: 'developer_board',
    routerLink: 'theming',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions],
  };
  const sharedIdentityMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'shared-identity'),
    name: 'Shared Identity',
    icon: 'folder_shared',
    routerLink: 'shared-identity',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions],
  };
  const customIdentityMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'custom-identity'),
    name: 'Custom Identity',
    icon: 'folder_special',
    routerLink: 'custom-identity',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions],
  };
  const onsiteIdentityMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'onsite-identity'),
    name: 'Onsite Identity',
    icon: 'cloud',
    routerLink: 'onsite-identity',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions, PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const applicationIdentityMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-identity'),
    name: 'Application Identity',
    icon: 'apps',
    routerLink: 'application-identity',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions, PermissionsDefinition.hasApplicationReadOrAdminPermissions],
  };
  const authenticationPolicyMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'auth-issuer-policy'),
    name: 'Authentication Policy',
    icon: 'vpn_key',
    routerLink: 'auth-issuer-policy',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions],
  };
  const menuItemsList = [
    overviewMenuItem,
    themingMenuItem,
    sharedIdentityMenuItem,
    customIdentityMenuItem,
    onsiteIdentityMenuItem,
    applicationIdentityMenuItem,
    authenticationPolicyMenuItem,
  ];

  return {
    id: defaultId,
    name: 'Authentication',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getAccessSideNavMenu(): NavMenuItem {
  const defaultId = 'access';
  const usersMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'user-admin'),
    name: 'Users',
    icon: getUserTypeIcon(User.TypeEnum.user),
    routerLink: 'user-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const groupsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'group-admin'),
    name: 'Groups',
    icon: getUserTypeIcon(User.TypeEnum.group),
    routerLink: 'group-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const resourceGroupsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'resource-group-admin'),
    name: 'Resource Groups',
    icon: 'supervised_user_circle',
    routerLink: 'resource-group-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasResourceAdminPermissions],
  };
  const serviceAccountsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'service-account-admin'),
    name: 'Service Accounts',
    icon: getUserTypeIcon(User.TypeEnum.service_account),
    routerLink: 'service-account-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions],
  };
  const applicationPermissionsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-permissions-admin'),
    name: 'Application Permissions',
    icon: 'vpn_key',
    routerLink: 'application-permissions-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions, PermissionsDefinition.hasApplicationReadOrAdminPermissions],
  };
  const resourcePermissionsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'resource-permissions-admin'),
    name: 'Resource Permissions',
    icon: 'verified_user',
    routerLink: 'resource-permissions-admin',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasUsersAdminPermissions, PermissionsDefinition.hasResourceReadOrAdminPermissions],
  };
  const resourceRequestsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'resource-access-requests'),
    name: 'Resource Requests',
    icon: 'assignment_late',
    routerLink: 'resource-access-requests',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasResourceReadOrAdminPermissions,
      PermissionsDefinition.hasApplicationReadOrAdminPermissions,
      PermissionsDefinition.requestFlowEnabled,
    ],
  };
  const auditsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'user-audits'),
    name: 'Audits',
    icon: 'work_outline',
    routerLink: 'user-audits',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasUsersReadOrAdminPermissions,
      PermissionsDefinition.hasDiagnosticsReadOrAdminPermissions,
      PermissionsDefinition.hasResourceReadOrAdminPermissions,
      PermissionsDefinition.hasApplicationReadOrAdminPermissions,
    ],
  };
  const policiesMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'policies'),
    name: 'Policies',
    icon: 'policy',
    routerLink: 'policies',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasRulesAdminPermissions],
  };
  const apiKeysSideNavMenu = getApiKeysSideNavMenu();
  const menuItemsList = [
    usersMenuItem,
    groupsMenuItem,
    resourceGroupsMenuItem,
    serviceAccountsMenuItem,
    applicationPermissionsMenuItem,
    resourcePermissionsMenuItem,
    resourceRequestsMenuItem,
    auditsMenuItem,
    policiesMenuItem,
    apiKeysSideNavMenu,
  ];

  return {
    id: defaultId,
    name: 'Access',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getApplicationsSideNavMenu(): NavMenuItem {
  const defaultId = 'applications';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-new'),
    name: 'New',
    routerLink: 'application-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-overview'),
    name: 'Overview',
    routerLink: 'application-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const defineMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-define'),
    name: 'Define',
    routerLink: 'application-define',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const diagnoseMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-diagnose'),
    name: 'Diagnose',
    routerLink: 'application-diagnose',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationReadOrAdminPermissions,
      PermissionsDefinition.hasDiagnosticsReadOrAdminPermissions,
    ],
  };
  const authClientsMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'application-authentication-clients'),
    name: 'Authentication Clients',
    routerLink: 'application-authentication-clients',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasIssuersAdminPermissions, PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem, defineMenuItem, diagnoseMenuItem, authClientsMenuItem];

  return {
    id: defaultId,
    name: 'Applications',
    icon: getResourceTypeIcon(ResourceType.application),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getConnectorsSideNavMenu(): NavMenuItem {
  const defaultId = 'connectors';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'connector-new'),
    name: 'New',
    routerLink: 'connector-new',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'connector-overview'),
    name: 'Overview',
    routerLink: 'connector-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const forwardingMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'forwarding-services'),
    name: 'Forwarding',
    routerLink: 'forwarding-services',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem, forwardingMenuItem];

  return {
    id: defaultId,
    name: 'Connectors',
    icon: getResourceTypeIcon(ResourceType.service_forwarder),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getDesktopsSideNavMenu(): NavMenuItem {
  const defaultId = 'desktops';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'desktop-new'),
    name: 'New',
    routerLink: 'desktop-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'desktop-overview'),
    name: 'Overview',
    routerLink: 'desktop-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'Desktops',
    icon: getResourceTypeIcon(ResourceType.desktop),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getSshSideNavMenu(): NavMenuItem {
  const defaultId = 'ssh';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'ssh-new'),
    name: 'New',
    routerLink: 'ssh-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'ssh-overview'),
    name: 'Overview',
    routerLink: 'ssh-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'SSH',
    icon: getResourceTypeIcon(ResourceType.ssh),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getLaunchersSideNavMenu(): NavMenuItem {
  // TODO: check if correct permissions:
  const defaultId = 'launchers';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'launcher-new'),
    name: 'New',
    routerLink: 'launcher-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'launcher-overview'),
    name: 'Overview',
    routerLink: 'launcher-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationReadOrAdminPermissions, PermissionsDefinition.hasResourceAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'Launchers',
    icon: getResourceTypeIcon(ResourceType.launcher),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getNetworksSideNavMenu(): NavMenuItem {
  const defaultId = 'networks';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'network-new'),
    name: 'New',
    routerLink: 'network-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'network-overview'),
    name: 'Overview',
    routerLink: 'network-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'Networks',
    icon: getResourceTypeIcon(ResourceType.application_service),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getSharesSideNavMenu(): NavMenuItem {
  const defaultId = 'shares';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'shares-new'),
    name: 'New',
    routerLink: 'shares-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'shares-overview'),
    name: 'Overview',
    routerLink: 'shares-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasApplicationAdminPermissions],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'Shares',
    icon: getResourceTypeIcon(ResourceType.fileshare),
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getApiKeysSideNavMenu(): NavMenuItem {
  const defaultId = 'api-keys';
  const newMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'api-key-new'),
    name: 'New',
    routerLink: 'api-key-new',
    disabled: true,
    requiredPermissions: [
      PermissionsDefinition.hasApplicationAdminPermissions,
      PermissionsDefinition.hasUsersAdminPermissions,
      PermissionsDefinition.hasIssuersAdminPermissions,
    ],
  };
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'api-key-overview'),
    name: 'Overview',
    routerLink: 'api-key-overview',
    disabled: true,
    requiredPermissions: [],
  };
  const menuItemsList = [newMenuItem, overviewMenuItem];

  return {
    id: defaultId,
    name: 'API Keys',
    icon: 'vpn_key',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getResourceOverviewSideNavMenu(): NavMenuItem {
  const defaultId = 'resource';
  const overviewMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'resource-overview'),
    name: 'Overview',
    icon: 'description',
    routerLink: 'resource-overview',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasLabelAdminPermissions, PermissionsDefinition.hasResourceAdminPermissions],
  };
  return overviewMenuItem;
}

export function getMetricsSideNavMenu(): NavMenuItem {
  const defaultId = 'metrics';
  const topUsersMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'top-users'),
    name: 'Top Users',
    icon: 'vertical_align_top',
    routerLink: 'metrics-top-users',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasMetricsReadonlyPermissions, PermissionsDefinition.hasApplicationReadOrAdminPermissions],
  };
  const activeUsersMenuItem: NavMenuItem = {
    id: getMenuItemId(defaultId, 'active-users'),
    name: 'Active Users',
    icon: 'person',
    routerLink: 'metrics-active-users',
    disabled: true,
    requiredPermissions: [PermissionsDefinition.hasMetricsReadonlyPermissions, PermissionsDefinition.hasApplicationReadOrAdminPermissions],
  };
  const menuItemsList = [topUsersMenuItem, activeUsersMenuItem];

  return {
    id: defaultId,
    name: 'Metrics',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getResourcesSideNavMenu(): NavMenuItem {
  const defaultId = 'resources';
  const resourceOverviewSideNavMenu = getResourceOverviewSideNavMenu();
  const applicationsSideNavMenu = getApplicationsSideNavMenu();
  const connectorsSideNavMenu = getConnectorsSideNavMenu();
  const desktopsSideNavMenu = getDesktopsSideNavMenu();
  const sshSideNavMenu = getSshSideNavMenu();
  const launchersSideNavMenu = getLaunchersSideNavMenu();
  const networksSideNavMenu = getNetworksSideNavMenu();
  const sharesSideNavMenu = getSharesSideNavMenu();
  const menuItemsList = [
    resourceOverviewSideNavMenu,
    applicationsSideNavMenu,
    connectorsSideNavMenu,
    desktopsSideNavMenu,
    launchersSideNavMenu,
    networksSideNavMenu,
    sharesSideNavMenu,
    sshSideNavMenu,
  ];

  return {
    id: defaultId,
    name: 'Resources',
    disabled: isParentMenuDisabled(menuItemsList),
    requiredPermissions: undefined,
    items: menuItemsList,
  };
}

export function getNavMenuItemsList(
  organisationSideNavMenu: NavMenuItem,
  authenticationSideNavMenu: NavMenuItem,
  accessSideNavMenu: NavMenuItem,
  resourcesSideNavMenu: NavMenuItem,
  metricsSideNavMenu: NavMenuItem
): Array<NavMenuItem> {
  return [
    getNewsSideNavMenu(),
    getGettingStartedSideNavMenu(),
    organisationSideNavMenu,
    authenticationSideNavMenu,
    accessSideNavMenu,
    resourcesSideNavMenu,
    metricsSideNavMenu,
  ];
}

export function getAllParentSideNavMenuItems(): Array<NavMenuItem> {
  const organisationSideNavMenu = getOrganisationSideNavMenu();
  const authenticationSideNavMenu = getAuthenticationSideNavMenu();
  const accessSideNavMenu = getAccessSideNavMenu();
  const resourcesSideNavMenu = getResourcesSideNavMenu();
  const metricsSideNavMenu = getMetricsSideNavMenu();
  return getNavMenuItemsList(
    organisationSideNavMenu,
    authenticationSideNavMenu,
    accessSideNavMenu,
    resourcesSideNavMenu,
    metricsSideNavMenu
  );
}

export function getAllParentAndNestedSideNavMenuItemsFromParentMenuItems(parentSideNavMenuItems: Array<NavMenuItem>): Array<NavMenuItem> {
  const allMenuItemsList = [...parentSideNavMenuItems];
  for (const parentMenuItem of parentSideNavMenuItems) {
    if (!parentMenuItem.items) {
      continue;
    }
    allMenuItemsList.push(...parentMenuItem.items);
  }
  return allMenuItemsList;
}

export function getAllParentAndNestedSideNavMenuItems(): Array<NavMenuItem> {
  const parentSideNavMenuItems = getAllParentSideNavMenuItems();
  if (!parentSideNavMenuItems) {
    return [];
  }
  return getAllParentAndNestedSideNavMenuItemsFromParentMenuItems(parentSideNavMenuItems);
}

export function getSideNavMenu(): Array<NavMenuItem> {
  return getAllParentSideNavMenuItems();
}

export function isParentMenuDisabled(menuItemsList: Array<NavMenuItem>): boolean {
  if (!menuItemsList || menuItemsList.length === 0) {
    return true;
  }
  for (const item of menuItemsList) {
    if (!item.disabled) {
      return false;
    }
  }
  return true;
}

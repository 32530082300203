import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, Renderer2 } from '@angular/core';
import { AuditsService, ListAuthRecordsRequestParams, ListUsersRequestParams, UsersService, ListUsersResponse } from '@agilicus/angular';
import { Observable, combineLatest } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CustomValidatorsService } from '@app/core/services/custom-validators.service';
import { takeUntil } from 'rxjs/operators';
import { fillOptionalDataFromForm, updateTableElements } from '@app/shared/components/utils';
import {
  TimeIntervalOption,
  getDefaultTimeIntervalOptions,
  setStartAndEndDatesForFilter,
  getStartDateMaxSetter,
  getEndDateMinSetter,
} from '@app/shared/components/date-utils';
import { FilterManager } from '../filter/filter-manager';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core';
import { Subject } from 'rxjs';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { selectCanReadAudits } from '@app/core/user/permissions/audits.selectors';
import { createCombinedPermissionsSelector, OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanReadUsers } from '@app/core/user/permissions/users.selectors';
import { Column, createExpandColumn, createReadonlyColumn, ReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { downloadDataToCsv } from '../file-utils';
import { Papa } from 'ngx-papaparse';
import { isANumber } from '../validation-utils';
import {
  AuthAuditElement,
  AuthAuditsPlusEmail,
  getAuditElements,
  getAuthAuditApplicationNameColumn,
  getAuthAuditClientIdColumn,
  getAuthAuditEventNameColumn,
  getAuthAuditResultColumn,
  getAuthAuditSourceIpColumn,
  getAuthAuditsPlusEmailList,
  getAuthAuditTimeColumn,
  getAuthAuditTokenIdColumn,
  getAuthAuditTraceIdColumn,
  getAuthAuditUpstreamIdpColumn,
  getAuthAuditUserAgentColumn,
} from '../audit-utils';
import { InputData } from '../custom-chiplist-input/input-data';
import { cloneDeep } from 'lodash-es';
import { getDefaultReadonlyTableSettings } from '../table-layout-utils';

@Component({
  selector: 'portal-auth-audit',
  templateUrl: './auth-audit.component.html',
  styleUrls: ['./auth-audit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthAuditComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private orgId: string;
  public columnDefs: Map<string, Column<AuthAuditElement>> = new Map();
  public tableData: Array<AuthAuditElement> = [];
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = true;
  private users$: Observable<ListUsersResponse>;
  public timeIntervalOptions: Array<TimeIntervalOption> = getDefaultTimeIntervalOptions();
  public maxDate: Date = new Date();
  public authAuditForm: UntypedFormGroup;
  public downloadButtonDescription = 'AUDITS';
  private permissions$: Observable<OrgQualifiedPermission>;
  public hasPermissions: boolean;
  private authAuditsPlusEmailList: Array<AuthAuditsPlusEmail> = [];
  public pageDescriptiveText = `Global organisation-wide authentication audits. Who has signed in, from where and when.`;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/authentication-audit/`;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public getStartDateMaxSetter = getStartDateMaxSetter;
  public getEndDateMinSetter = getEndDateMinSetter;
  public getDefaultReadonlyTableSettings = getDefaultReadonlyTableSettings;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customValidatorsService: CustomValidatorsService,
    private changeDetector: ChangeDetectorRef,
    private store: Store<AppState>,
    private auditsService: AuditsService,
    private usersService: UsersService,
    private papa: Papa,
    private renderer: Renderer2
  ) {}

  public ngOnInit(): void {
    this.initializeFormGroup();
    this.initializeColumnDefs();
    this.permissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanReadAudits, selectCanReadUsers)));
    this.permissions$.pipe(takeUntil(this.unsubscribe$)).subscribe((permissions) => {
      this.orgId = permissions.orgId;
      this.hasPermissions = permissions.hasPermission;
      if (!this.orgId || !this.hasPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.getAuthAudits();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public getAuthAudits(): void {
    const authAuditFilterData = this.getAuthAuditFilterData();
    const audits$ = this.auditsService.listAuthRecords(authAuditFilterData);
    const userParams: ListUsersRequestParams = {
      org_id: this.orgId,
      type: ['user'],
    };
    this.users$ = this.usersService.listUsers(userParams);
    combineLatest([audits$, this.users$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([auditsResp, usersResp]) => {
        this.authAuditsPlusEmailList = getAuthAuditsPlusEmailList(auditsResp, usersResp);
        this.updateTable();
      });
  }

  /**
   * Parent & Nested Table Column
   */
  private getEmailColumn(): ReadonlyColumn<InputData> {
    const emailColumn = createReadonlyColumn('email');
    return emailColumn;
  }

  /**
   * Parent Table Columns
   */
  private initializeColumnDefs(): void {
    setColumnDefs(
      [
        getAuthAuditTimeColumn(),
        this.getEmailColumn(),
        getAuthAuditEventNameColumn(),
        getAuthAuditResultColumn(),
        getAuthAuditClientIdColumn(),
        getAuthAuditSourceIpColumn(),
        createExpandColumn(),
      ],
      this.columnDefs
    );
  }

  /**
   * Nested Table Columns
   */
  private initializeNestedAuthAuditColumnDefs(nestedColumnDefs: Map<string, Column<TableElement>>): void {
    setColumnDefs(
      [
        getAuthAuditTimeColumn(),
        this.getEmailColumn(),
        getAuthAuditEventNameColumn(),
        getAuthAuditResultColumn(),
        getAuthAuditClientIdColumn(),
        getAuthAuditSourceIpColumn(),
        getAuthAuditTokenIdColumn(),
        getAuthAuditTraceIdColumn(),
        getAuthAuditUpstreamIdpColumn(),
        getAuthAuditUserAgentColumn(),
        getAuthAuditApplicationNameColumn(),
      ],
      nestedColumnDefs
    );
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const data: Array<AuthAuditElement> = getAuditElements(
      this.authAuditsPlusEmailList,
      this.initializeNestedAuthAuditColumnDefs.bind(this)
    );
    updateTableElements(this.tableData, data);
  }

  private initializeFormGroup(): void {
    this.authAuditForm = this.formBuilder.group({
      dtFrom: null,
      dtTo: null,
      quick_select_time: '60',
      limit: ['', [this.customValidatorsService.customValidator(isANumber)]],
    });
  }

  public getAuthAuditFilterData(): ListAuthRecordsRequestParams {
    const authAuditFilterData: ListAuthRecordsRequestParams = {
      org_id: this.orgId,
    };
    fillOptionalDataFromForm(authAuditFilterData, this.authAuditForm, ['limit']);

    const targetDates = setStartAndEndDatesForFilter(this.authAuditForm);
    if (targetDates.startDate !== null) {
      authAuditFilterData.dt_from = targetDates.startDate.toISOString();
    }
    if (targetDates.endDate !== null) {
      authAuditFilterData.dt_to = targetDates.endDate.toISOString();
    }
    return authAuditFilterData;
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public downloadAuthAuditData(): void {
    const copyOfAuthAuditsPlusEmailList = cloneDeep(this.authAuditsPlusEmailList);
    const formattedCopyOfAuthAuditsPlusEmailList: Array<AuthAuditsPlusEmail> = [];
    for (const authAuditPlusEmail of copyOfAuthAuditsPlusEmailList) {
      delete authAuditPlusEmail._builtin_original;
      formattedCopyOfAuthAuditsPlusEmailList.push({
        // Make email first column in csv:
        email: authAuditPlusEmail.email,
        ...authAuditPlusEmail,
      });
    }
    downloadDataToCsv(formattedCopyOfAuthAuditsPlusEmailList, this.papa, this.renderer, 'auth-audit-data');
  }
}

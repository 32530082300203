<portal-page-info
  pageTitle="User Audit"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="user-diagnose-container">
  <div *ngIf="hasPermissions !== undefined && hasPermissions === false">
    <portal-no-permissions></portal-no-permissions>
  </div>

  <div *ngIf="hasPermissions" class="search-user-container">
    <form 
      class="user-search-form" 
      [formGroup]="userSearchForm" 
      spellcheck="false"
      autocomplete="off"
    >
      <mat-form-field
        class="user-email-input" 
        appearance="outline"
        matTooltip="Search for a user by entering their email address"
      >
        <mat-label>User Email</mat-label>
        <input
          matInput
          type="text"
          formControlName="user_email"
          [matAutocomplete]="auto"
          (keyup.enter)="getUserInfoOnAction()"
        />
        <mat-icon matPrefix class="search-user-icon-container">person_search</mat-icon>
        <mat-autocomplete 
          #auto="matAutocomplete"
        >
          <mat-option
            *ngFor="let option of filteredUserOptions$ | async"
            [value]="option"
          >
            {{ option }}
          </mat-option>
        </mat-autocomplete>
        <mat-error>A valid user email is required</mat-error>
      </mat-form-field>
    </form>

    <button
      mat-raised-button
      color="primary"
      class="search-user-button"
      (click)="getUserInfoOnAction()"
    >
      SEARCH
    </button>
  </div>

  <div *ngIf="isSearching" class="searching-text-container">
    <div class="three-dot-container">
      <h2 class="searching-text">Searching</h2> <span class="dot-elastic"></span>
    </div>
  </div>

  <div *ngIf="(componentContext$ | async) as ctx">
    <div *ngIf="ctx.state as state">
      <div *ngIf="!!state.error_message" class="error-message-text">
        <h2>{{ state.error_message }}</h2>
      </div>
      <div *ngIf="state.user">
        <div class="user-title">
          <h2>{{ getUserNameAndEmailString(state.user) }}</h2>
        </div>

        <mat-accordion class="expansion-container" multi>
          <mat-expansion-panel
            [expanded]="getPanelState(userAuditPanel.APP_ACCESS_OVERVIEW)"
            (opened)="onPanelOpen(userAuditPanel.APP_ACCESS_OVERVIEW)"
            (closed)="onPanelClose(userAuditPanel.APP_ACCESS_OVERVIEW)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Application Access Overview
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              *ngIf="getPanelState(userAuditPanel.APP_ACCESS_OVERVIEW)"
              class="table-panel-container"
            >
              <div *ngIf="state.application_access === undefined">
                <h5>Application Access data is unavailable. Please try again.</h5>
              </div>

              <div *ngIf="!!state.application_access">
                <portal-table-layout
                  [tableData]="state.application_access"
                  [columnDefs]="appAccessTableColumnDefs"
                  [hideRow]="hideAppRowFunc"
                  [filterManager]="appAccessFilterManager"
                  [filterMenuOptions]="appAccessFilterMenuOptions"
                  [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
                  [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
                  [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
                  [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
                >
                </portal-table-layout>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel
            [expanded]="getPanelState(userAuditPanel.RESOURCE_ACCESS_OVERVIEW)"
            (opened)="onPanelOpen(userAuditPanel.RESOURCE_ACCESS_OVERVIEW)"
            (closed)="onPanelClose(userAuditPanel.RESOURCE_ACCESS_OVERVIEW)"
          >
            <mat-expansion-panel-header>
              <mat-panel-title>
                Resource Access Overview
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div
              *ngIf="getPanelState(userAuditPanel.RESOURCE_ACCESS_OVERVIEW)"
              class="table-panel-container"
            >
              <div *ngIf="state.resource_access === undefined">
                <h5>Resource Access data is unavailable. Please try again.</h5>
              </div>

              <div *ngIf="!!state.resource_access">
                <portal-table-layout
                  [tableData]="state.resource_access"
                  [columnDefs]="resourceAccessTableColumnDefs"
                  [hideRow]="hideResourceRowFunc"
                  [filterManager]="resourceAccessFilterManager"
                  [filterMenuOptions]="resourceAccessFilterMenuOptions"
                  [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
                  [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
                  [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
                  [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
                >
                </portal-table-layout>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="auth-preference-table">
          <h2 class="section-title">Authentication Preferences</h2>

          <div class="button-container">
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="show-logs-button"
              (click)="resetMfaPreferences(state.user)"
              matTooltip="Click to reset the user's multi-factor authentication preferences"
              [disabled]="!state.user"
            >
              RESET MFA PREFERENCES
            </button>
          </div>

          <div *ngIf="state.mfa_challenge_methods === undefined">
            <h5>Authentication Preferences data is unavailable. Please try again.</h5>
          </div>

          <div *ngIf="!!state.mfa_challenge_methods">
            <portal-table-layout
              [tableData]="state.mfa_challenge_methods"
              [columnDefs]="mfaTableColumnDefs"
              [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
              [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
              [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
              [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
              [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
            >
            </portal-table-layout>
          </div>
        </div>

        <div class="mfa-enroll-date-container">
          <h3
            class="mfa-enroll-date"
          >
            Multi-factor authentication enrollment deadline: <span [ngClass]="{ 'expired-mfa-enroll': isUserMfaEnrollmentExpired(state.userMfaEnrollmentMetadata?.spec?.data) }">{{ getMfaEnrollmentDisplayValue(state.userMfaEnrollmentMetadata) }}</span>
        </h3>

          <div class="button-container">
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="reset-mfa-enroll-deadline-button"
              (click)="resetMfaEnrollmentDeadline(state)"
              matTooltip="Click to reset the user's multi-factor authentication enrollment deadline to the organisation's default"
              [disabled]="!state.current_org || !state.userMfaEnrollmentMetadata || !state.user"
            >
              RESET
            </button>
          </div>
        </div>

        <div class="user-diagnose-form-container">
          <form
            class="user-diagnose-form"
            [formGroup]="userAuditFilterForm"
            spellcheck="false"
            autocomplete="off"
          >
            <div class="set-log-time">
              <mat-form-field class="user-diagnose-filter-field">
                <mat-label>Select a time frame:</mat-label>
                <mat-select 
                  #select1
                  formControlName="quick_select_time"
                  (keyup.enter)="keyTabManager.keyTabSelect($event.target.id, select1)"
                >
                  <mat-option value="custom">Custom</mat-option>
                  <mat-option
                    *ngFor="let time of timeIntervalOptions"
                    value="{{ time.interval }}"
                    >{{ time.displayedInterval }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
      
              <mat-form-field
                class="user-diagnose-filter-field"
                *ngIf="userAuditFilterForm.value.quick_select_time === 'custom'"
              >
                <mat-label>Choose start date</mat-label>
                <input
                  matInput
                  [max]="maxDate"
                  [owlDateTime]="dt1"
                  [owlDateTimeTrigger]="dt1"
                  formControlName="dtFrom"
                  [owlDateTimeFilter]="getStartDateMaxSetter(userAuditFilterForm)"
                  (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                />
                <owl-date-time #dt1></owl-date-time>
                <mat-error>Enter valid date and time</mat-error>
              </mat-form-field>
      
              <p
                class="user-diagnose-filter-field diagnose-filter-field-p"
                *ngIf="userAuditFilterForm.value.quick_select_time === 'custom'"
              >
                to
              </p>
      
              <mat-form-field
                class="user-diagnose-filter-field"
                *ngIf="userAuditFilterForm.value.quick_select_time === 'custom'"
              >
                <mat-label>Choose end date</mat-label>
                <input
                  matInput
                  [owlDateTime]="dt2"
                  [owlDateTimeTrigger]="dt2"
                  formControlName="dtTo"
                  [owlDateTimeFilter]="getEndDateMinSetter(userAuditFilterForm)"
                  (keyup.enter)="keyTabManager.keyTab($event.target.id)"
                />
                <owl-date-time #dt2></owl-date-time>
                <mat-error>Enter valid date and time</mat-error>
              </mat-form-field>
            </div>
          </form>

          <div class="button-container">
            <button
              type="button"
              mat-raised-button
              color="primary"
              class="show-logs-button"
              [disabled]="!userAuditFilterForm.valid || (!state.audits?.audits_formatted && !state.logs)"
              (click)="getUserInfoOnAction()"
              matTooltip="Click to update the information in the audit table"
            >
              UPDATE RESULTS <fa-icon [icon]="['fas', 'search']"></fa-icon>
            </button>
          </div>
        </div>

        <div class="session-audit-table">
          <h2 class="section-title">Sessions Audit</h2>
          <div *ngIf="state.audits?.audits_formatted === undefined">
            <h5>Audit data is unavailable. Please try again.</h5>
          </div>

          <div *ngIf="!!state.audits?.audits_formatted">
            <div class="button-container">
              <portal-download-button
                class="download-button-container"
                buttonDescription="LOGINS"
                tooltipText="Click to download the audit information"
                (downloadFile)="downloadUserAuditData(state.audits?.raw_audit_data)"
              >
              </portal-download-button>
            </div>
  
            <portal-table-layout
              [tableData]="state.audits?.audits_formatted"
              [columnDefs]="auditTableColumnDefs"
              hasNestedTable="true"
              [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
              [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
              [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
              [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
              [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
            >
            </portal-table-layout>
          </div>
        </div>

        <div class="logs-table">
          <h2 class="section-title">Logs</h2>

          <div *ngIf="state.logs === undefined">
            <h5>Logs data is unavailable. Please try again.</h5>
          </div>

          <div *ngIf="!!state.logs">
            <portal-table-layout
              [tableData]="state.logs"
              [columnDefs]="logTableColumnDefs"
              [fixedTable]="getDefaultReadonlyTableSettings().fixedTable"
              [hideFilter]="getDefaultReadonlyTableSettings().hideFilter"
              [fullWidthTable]="getDefaultReadonlyTableSettings().fullWidthTable"
              [showTableControls]="getDefaultReadonlyTableSettings().showTableControls"
              [showPageInfo]="getDefaultReadonlyTableSettings().showPageInfo"
            >
            </portal-table-layout>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { TableElement } from '../table-layout/table-element';
import {
  AutoInputColumn,
  Column,
  createAutoInputColumn,
  createInputColumn,
  createSelectRowColumn,
  setColumnDefs,
} from '../table-layout/column-definitions';
import { FilterManager } from '../filter/filter-manager';
import { DesktopResource } from '@agilicus/angular';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';
import { getFilteredValues } from '../custom-chiplist-input/custom-chiplist-input.utils';
import { Observable } from 'rxjs';
import { getRdpDesktopExtraConfigOptions } from '../resource-utils';
import { updateTableElements } from '../utils';

export interface DesktopExtraConfigElement extends TableElement {
  name: string;
  type: string;
  value: string;
  nameFormControl: UntypedFormControl;
}

@Component({
  selector: 'portal-desktop-extra-config',
  templateUrl: './desktop-extra-config.component.html',
  styleUrl: './desktop-extra-config.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopExtraConfigComponent implements OnInit, OnDestroy {
  @Input() public desktopCopy: DesktopResource;
  public tableData: Array<DesktopExtraConfigElement> = [];
  public columnDefs: Map<string, Column<DesktopExtraConfigElement>> = new Map();
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);
  public filterManager: FilterManager = new FilterManager();
  public fixedTable = false;
  public rowObjectName = 'CONFIGURATION OVERRIDE';
  public tableHasBeenEdited = false;

  constructor(private changeDetector: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.updateTable();
    this.changeDetector.detectChanges();
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const dataForTable: Array<DesktopExtraConfigElement> = [];
    const extraConfigList = !!this.desktopCopy.spec.extra_configs ? this.desktopCopy.spec.extra_configs : [];
    for (let i = 0; i < extraConfigList.length; i++) {
      const item = extraConfigList[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(extraConfig: string, index: number): DesktopExtraConfigElement {
    const indexofFirstColon = extraConfig.indexOf(':');
    const indexofSecondColon = extraConfig.substring(indexofFirstColon + 1).indexOf(':') + indexofFirstColon + 1;
    const name = extraConfig.substring(0, indexofFirstColon);
    const type = extraConfig.substring(indexofFirstColon + 1, indexofSecondColon);
    const value = extraConfig.substring(indexofSecondColon + 1);
    const data: DesktopExtraConfigElement = {
      ...getDefaultTableProperties(index),
      name: !!name ? name : '',
      type: !!type ? type : '',
      value: !!value ? value : '',
      nameFormControl: new UntypedFormControl(),
    };
    data.nameFormControl.setValue(!!name ? name : '');
    return data;
  }

  /**
   * Parent table column
   */
  private getNameColumn(): Column<DesktopExtraConfigElement> {
    const column = createAutoInputColumn('nameFormControl');
    column.displayName = 'Name';
    column.getDisplayValue = (element: DesktopExtraConfigElement | string) => {
      if (typeof element === 'string') {
        return element as string;
      }
      const elementAsDesktopExtraConfigElement = element as DesktopExtraConfigElement;
      return !!elementAsDesktopExtraConfigElement.name ? elementAsDesktopExtraConfigElement.name : '';
    };
    column.isEditable = true;
    column.requiredField = () => true;
    column.isValidEntry = (value: string) => {
      return value.indexOf(':') === -1;
    };
    column.allowedValues = getRdpDesktopExtraConfigOptions();
    column.getFilteredValues = (
      element: DesktopExtraConfigElement,
      column: AutoInputColumn<DesktopExtraConfigElement>
    ): Observable<Array<string>> => {
      return getFilteredValues(element.nameFormControl, column);
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getTypeColumn(): Column<DesktopExtraConfigElement> {
    const column = createInputColumn('type');
    column.isEditable = true;
    column.requiredField = () => true;
    column.isValidEntry = (value: string) => {
      return value.indexOf(':') === -1;
    };
    return column;
  }

  /**
   * Parent table column
   */
  private getValueColumn(): Column<DesktopExtraConfigElement> {
    const column = createInputColumn('value');
    column.isEditable = true;
    column.requiredField = () => true;
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getNameColumn(), this.getTypeColumn(), this.getValueColumn()], this.columnDefs);
  }

  public makeEmptyTableElement(): DesktopExtraConfigElement {
    return {
      name: '',
      type: '',
      value: '',
      nameFormControl: new UntypedFormControl(),
      ...getDefaultNewRowProperties(),
    };
  }

  private getExtraConfigStringFromDesktopExtraConfigElement(element: DesktopExtraConfigElement): string {
    return `${element.name}:${element.type}:${element.value}`;
  }

  /**
   * Receives an element from the table then updates and saves
   * the data.
   */
  public updateEvent(): void {
    this.tableHasBeenEdited = true;
    const extraConfigList = [];
    for (const element of this.tableData) {
      extraConfigList.push(this.getExtraConfigStringFromDesktopExtraConfigElement(element));
    }
    this.desktopCopy.spec.extra_configs = extraConfigList;
  }

  public updateAutoInput(params: {
    optionValue: string;
    column: AutoInputColumn<DesktopExtraConfigElement>;
    element: DesktopExtraConfigElement;
  }): void {
    if (params.column.name !== 'nameFormControl') {
      return;
    }
    if (params.optionValue === params.element.name) {
      // The value has not been changed.
      return;
    }
    params.element.name = params.optionValue;
    params.element.dirty = true;
  }

  public deleteSelected(): void {
    this.tableHasBeenEdited = true;
    const extraConfigList = [];
    for (const element of this.tableData) {
      if (!element.isChecked) {
        extraConfigList.push(this.getExtraConfigStringFromDesktopExtraConfigElement(element));
      }
    }
    this.desktopCopy.spec.extra_configs = extraConfigList;
    this.tableData = [...this.tableData.filter((element) => !element.isChecked)];
    this.changeDetector.detectChanges();
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public getExtraConfigFormatText(): string {
    return '"<name>:<type>:<value>"';
  }
}

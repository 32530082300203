<h2>Configure {{ chiplistInput?.displayName }} <span *ngIf="!!getElementIdentifier()">for "{{ getRowIdentifierText() }}"</span></h2>
<p class="descriptive-text">
  The table on the right represents the {{ chiplistInput?.displayName }} that have already been assigned. 
  The table on the left represents the available {{ chiplistInput?.displayName }} that can be assigned. 
  Select options from either table to move them to the opposite table.
</p>

<div class="undo-button-container">
  <button
    mat-raised-button
    color="primary"
    class="undo-button"
    (click)="onUndoClick()"
    [disabled]="disableUndoButton()"
    matTooltip="Click to undo all changes and restore to original values"
    [matTooltipDisabled]="disableUndoButton()"
  >
    <mat-icon>undo</mat-icon> UNDO
  </button>
</div>

<div mat-dialog-content>
  <div
    class="swap-selected-table-options"
    [matTooltip]="getDisabledSwapButtonTootltipText()"
    [matTooltipDisabled]="!disableSwapButton()"
  >
    <button
      mat-raised-button
      color="primary"
      (click)="onSwapClick()"
      [disabled]="disableSwapButton()"
      matTooltip="Click to move the selected options from one table to the other"
      [matTooltipDisabled]="disableSwapButton()"
    >
      <mat-icon>swap_horiz</mat-icon>
    </button>
  </div>

  <!-- Left Table -->
  <div
    class="left-table-container"
    [ngClass]="{ 'move-left-table-down': !!chiplistInput.isFreeform }"
  >
    <portal-table-layout
      #leftTableLayoutComp
      [tableData]="leftTableData"
      [columnDefs]="leftTableColumnDefs"
      [filterManager]="leftTableFilterManager"
      [paramSpecificFilterManager]="leftTableParamSpecificFilterManager"
      [fixedTable]="isFixedTable()"
      [showTableControls]="showTableControls"
      [buttonsToShow]="buttonsToShow"
      [customButtons]="customButtons"
      [alternateRowColor]="true"
      [largeHeaders]="largeHeaders"
      [selectOnRowClick]="selectOnRowClick()"
      [paginatorConfig]="leftTablePaginatorConfig"
      [filterMenuOptions]="leftTableFilterMenuOptions"
      (updateSelection)="updateSelection($event)"
    >
    </portal-table-layout>
  </div>

  <!-- Right Table -->
  <div
    class="right-table-container"
    [ngClass]="{ 'right-table-container-free-form': !!chiplistInput.isFreeform }"
  >
    <div
      *ngIf="!!chiplistInput.isFreeform"
      class="add-to-available-options-container"
    >
      <form 
        class="free-form-chiplist-input" 
        [formGroup]="freeFormChiplistForm" 
        spellcheck="false"
        autocomplete="off"
      >  
        <mat-form-field>
          <mat-label>Enter value here to add to the table below</mat-label>
          <input
            matInput 
            type="text"
            formControlName="option_value"
            (keyup.enter)="addFreeFormValueToTable()"
          >
        </mat-form-field>
      </form>

      <button
        mat-raised-button
        color="primary"
        (click)="addFreeFormValueToTable()"
        [disabled]="disableAddButton()"
      >
        ADD
      </button>
    </div>

    <portal-table-layout
      #rightTableLayoutComp
      [tableData]="rightTableData"
      [columnDefs]="rightTableColumnDefs"
      [filterManager]="rightTableFilterManager"
      [fixedTable]="isFixedTable()"
      [showTableControls]="showTableControls"
      [buttonsToShow]="buttonsToShow"
      [customButtons]="customButtons"
      [alternateRowColor]="true"
      [largeHeaders]="largeHeaders"
      [selectOnRowClick]="selectOnRowClick()"
      [paginatorConfig]="rightTablePaginatorConfig"
    >
    </portal-table-layout>
  </div>

  <mat-dialog-actions align="end" class="clear">
    <button
      mat-raised-button
      color="primary"
      mat-dialog-close
    >
      CLOSE
    </button>
  </mat-dialog-actions>
</div>
import { Component, Inject, OnInit, ElementRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { FilterChipOptions } from '../filter-chip-options';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject, Observable, combineLatest } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { ApiApplicationsState } from '@app/core/api-applications/api-applications.models';
import { selectApiApplications } from '@app/core/api-applications/api-applications.selectors';
import { AppState } from '@app/core';
import {
  Application,
  GroupsService,
  Group,
  ListGroupsResponse,
  Issuer,
  OIDCUpstreamIdentityProvider,
  ManagedUpstreamIdentityProvider,
  LocalAuthUpstreamIdentityProvider,
  ApplicationUpstreamIdentityProvider,
} from '@agilicus/angular';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { map, startWith, takeUntil } from 'rxjs/operators';
import { ConfirmationDialogData } from '../confirmation-dialog/confirmation-dialog.component';
import { cloneDeep } from 'lodash-es';
import { selectCurrentIssuer } from '@app/core/issuer-state/issuer.selectors';

export interface DefaultPresetValues {
  disallow_shared_sessions_by_default: boolean;
  multi_factor_window: string;
  single_sign_on_time: string;
  strict_mfa?: boolean;
}

@Component({
  selector: 'portal-auth-preset-dialog',
  templateUrl: './auth-preset-dialog.component.html',
  styleUrls: ['./auth-preset-dialog.component.scss'],
})
export class AuthPresetDialogComponent implements OnInit {
  public messagePrefix = '';
  public confirmButtonText = 'Apply';
  public cancelButtonText = 'Cancel';
  public orgId: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private defaultValues: DefaultPresetValues;

  public presetValuesGroup: UntypedFormGroup;

  private appState$: Observable<ApiApplicationsState>;
  public applications: Array<Application>;
  public allApplications = [];
  public appCtrl = new UntypedFormControl();
  public filteredApplications: Observable<string[]>;
  public selectedApplications: string[] = [];

  public groups: Array<Group>;
  public allGroups = [];
  public groupCtrl = new UntypedFormControl();
  public filteredGroups: Observable<string[]>;
  public selectedGroups: string[] = [];
  public issuerCopy: Issuer;
  public upstreams: Array<
    ManagedUpstreamIdentityProvider | OIDCUpstreamIdentityProvider | ApplicationUpstreamIdentityProvider | LocalAuthUpstreamIdentityProvider
  >;
  public allUpstreams = [];
  public upstreamCtrl = new UntypedFormControl();
  public filteredUpstreams: Observable<string[]>;
  public selectedUpstreams: string[] = [];

  @ViewChild('appInput') public appInput: ElementRef<HTMLInputElement>;
  @ViewChild('groupInput') public groupInput: ElementRef<HTMLInputElement>;
  @ViewChild('upstreamInput') public upstreamInput: ElementRef<HTMLInputElement>;

  // For setting enter key to change input focus.
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public filterChipOptions: FilterChipOptions = {
    visible: true,
    selectable: true,
    removable: true,
    addOnBlur: true,
    separatorKeysCodes: [ENTER, COMMA],
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<AuthPresetDialogComponent>,
    private store: Store<AppState>,
    private groupsService: GroupsService,
    private formBuilder: UntypedFormBuilder
  ) {
    if (data) {
      this.messagePrefix = data.messagePrefix || this.messagePrefix;
      this.orgId = data?.orgId;
      this.defaultValues = data?.defaultValues;
    }
  }

  public ngOnInit(): void {
    this.initializeForm();
    this.appState$ = this.store.pipe(select(selectApiApplications));
    const groups$ = this.groupsService.listGroups({ org_id: this.orgId });
    const currentIssuer$ = this.store.pipe(select(selectCurrentIssuer));
    combineLatest([this.appState$, groups$, currentIssuer$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([appStateResp, groupResp, currentIssuerResp]: [ApiApplicationsState, ListGroupsResponse, Issuer]) => {
        this.applications = appStateResp?.applications;
        this.groups = groupResp?.groups;
        this.issuerCopy = cloneDeep(currentIssuerResp);
        this.upstreams = this.issuerCopy.managed_upstreams;
        this.upstreams = this.upstreams.concat(
          this.issuerCopy.oidc_upstreams,
          this.issuerCopy.local_auth_upstreams,
          this.issuerCopy.application_upstreams
        );
        this.filterValues();
      });
  }

  private initializeForm(): void {
    this.presetValuesGroup = this.formBuilder.group({
      multiFactorRequiredTime: new UntypedFormControl(),
      sessionsValidTime: new UntypedFormControl(),
      sharedSessionsBetweenApps: new UntypedFormControl(),
      noMfaFromProviders: this.formBuilder.group({ checked: new UntypedFormControl(), values: new UntypedFormControl([]) }),
      requireMfaFromApps: this.formBuilder.group({ checked: new UntypedFormControl(), values: new UntypedFormControl([]) }),
      requireMfaFromGroups: this.formBuilder.group({ checked: new UntypedFormControl(), values: new UntypedFormControl([]) }),
    });
    if (this.defaultValues) {
      this.assignDefaultValues();
    }

    setTimeout(() => {
      // Enable/disable respective chip-list drop-down based on the checkbox.
      this.enableOrDisableChipListDropDown();
    }, 1000);
  }

  private assignDefaultValues(): void {
    if (this.defaultValues.multi_factor_window) {
      this.presetValuesGroup.patchValue({
        multiFactorRequiredTime: this.defaultValues.multi_factor_window,
      });
    }
    if (this.defaultValues.single_sign_on_time) {
      this.presetValuesGroup.patchValue({
        sessionsValidTime: this.defaultValues.single_sign_on_time,
      });
    }
    if (!this.defaultValues.disallow_shared_sessions_by_default) {
      this.presetValuesGroup.patchValue({
        sharedSessionsBetweenApps: !this.defaultValues.disallow_shared_sessions_by_default,
      });
    }
  }

  private filterValues(): void {
    this.allApplications.push(...this.applications.map((app) => app.name));
    this.allGroups.push(...this.groups.map((group) => group.id));
    this.allUpstreams.push(...this.upstreams.map((upstream) => upstream.name));

    this.filterApplications();
    this.filterGroups();
    this.filterUpstreams();
  }

  private enableOrDisableChipListDropDown(): void {
    const controllers = [
      { checkBoxController: 'noMfaFromProviders', valueController: 'upstreamCtrl' },
      { checkBoxController: 'requireMfaFromApps', valueController: 'appCtrl' },
      { checkBoxController: 'requireMfaFromGroups', valueController: 'groupCtrl' },
    ];

    for (const controller of controllers) {
      const statusControl = this.presetValuesGroup.get(controller.checkBoxController).get('checked');
      statusControl.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe((newStatus) => {
        if (newStatus) {
          this[controller.valueController].enable();
        } else {
          this[controller.valueController].disable();
        }
      });
      statusControl.updateValueAndValidity();
    }
  }

  private filterApplications(): void {
    this.filteredApplications = this.appCtrl.valueChanges.pipe(
      startWith(null as string),
      map((app: string | null) => (app ? this.filterContainsCaseInsensitive(app, this.allApplications) : this.allApplications.slice()))
    );
  }

  private filterGroups(): void {
    this.filteredGroups = this.groupCtrl.valueChanges.pipe(
      startWith(null as string),
      map((group: string | null) => (group ? this.filterContainsCaseInsensitive(group, this.allGroups) : this.allGroups.slice()))
    );
  }

  private filterUpstreams(): void {
    this.filteredUpstreams = this.upstreamCtrl.valueChanges.pipe(
      startWith(null as string),
      map((upstream: string | null) =>
        upstream ? this.filterContainsCaseInsensitive(upstream, this.allUpstreams) : this.allUpstreams.slice()
      )
    );
  }

  public getGroupNameFromId(id: string): string {
    return this.groups.find((i) => i.id === id).first_name;
  }

  public addChipList(event: MatChipInputEvent, selectedValues: string[]): void {
    const value = (event.value || '').trim();

    // Add our value
    if (value) {
      selectedValues.push(value);
    }

    this.clearFormControllerAndBlurInputField();
  }

  public removeChipList(app: string, selectedValues: string[]): void {
    const index = selectedValues.indexOf(app);

    // Remove value
    if (index >= 0) {
      selectedValues.splice(index, 1);
    }
  }

  public selectedChipList(event: MatAutocompleteSelectedEvent, selectedValues: string[]): void {
    selectedValues.push(event.option.value);
    this.clearFormControllerAndBlurInputField();
  }

  public isOptionAlreadySelected(option: string, selectedValues: string[]): boolean {
    for (const assignment of selectedValues) {
      if (assignment === option) {
        return true;
      }
    }
    return false;
  }

  private clearFormControllerAndBlurInputField(): void {
    if (this.appInput?.nativeElement) {
      this.appInput.nativeElement.blur();
      this.appCtrl.setValue(null);
    }

    if (this.groupInput?.nativeElement) {
      this.groupInput.nativeElement.blur();
      this.groupCtrl.setValue(null);
    }

    if (this.upstreamInput?.nativeElement) {
      this.upstreamInput.nativeElement.blur();
      this.upstreamCtrl.setValue(null);
    }
  }

  private filterContainsCaseInsensitive(value: string, arr: string[]): string[] {
    const filterValue = value.toLowerCase();
    return arr.filter((app) => app.toLowerCase().includes(filterValue));
  }

  public onConfirmClick(): void {
    this.presetValuesGroup.value.noMfaFromProviders.values = this.selectedUpstreams;
    this.presetValuesGroup.value.requireMfaFromApps.values = this.selectedApplications;
    this.presetValuesGroup.value.requireMfaFromGroups.values = this.selectedGroups;
    this.dialogRef.close(this.presetValuesGroup.value);
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { CommonComponentsModule } from './common-components.module';
import { ApplyAppModelComponent } from './components/apply-app-model/apply-app-model.component';

@NgModule({
  imports: [BrowserModule, CommonModule, CommonComponentsModule, MatTooltipModule, MatButtonModule],
  declarations: [ApplyAppModelComponent],
  exports: [ApplyAppModelComponent],
})
export class NgrxStepperModule {}

import { createSelector } from '@ngrx/store';
import { selectApiApplicationsState } from '../core.state';
import { makeEnvConfigVarGuid } from './api-applications-utils';
import { ApiApplicationsState } from './api-applications.models';

export const selectApiApplications = createSelector(selectApiApplicationsState, (state: ApiApplicationsState) => state);

export const selectApiApplicationsList = createSelector(selectApiApplicationsState, (state: ApiApplicationsState) => state.applications);

export const selectApiCurrentApplication = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_application
);

export const selectApiCurrentEnvironment = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_environment
);

export const selectApiCurrentApplicationBundlesList = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_application_bundles_list
);

export const selectApiApplicationsEnvironmentConfigVarList = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_environment_config_var_list
);

export const selectApiApplicationsApplicationModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.application_model
);

export const selectApiApplicationsCurrentApplicationContentSecurityPolicy = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_application_content_security_policy
);

export const selectApiApplicationsCurrentRolesList = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_roles_list
);

export const selectApiApplicationsCurrentRulesList = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_rules_list
);

export const selectApiApplicationsCurrentRule = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_rule
);

export const selectApiApplicationsCurrentRoleToRuleEntriesList = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.current_role_to_rule_entries_list
);

export const selectApiApplicationsRefreshData = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.refresh_data
);

export const selectApiApplicationsSavingRule = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.saving_rule
);

export const selectApiApplicationsRuleId = createSelector(selectApiApplicationsState, (state: ApiApplicationsState) => state?.rule_id);

export const selectApiApplicationsEnvConfigVarId = createSelector(selectApiApplicationsState, (state: ApiApplicationsState) => {
  if (!!state.current_application && !!state.current_environment && !!state?.current_environment_config_var_list) {
    return makeEnvConfigVarGuid(state.current_application.id, state.current_environment.name);
  }
  return undefined;
});

export const selectApiApplicationsShouldPopulateValue = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state.should_populate
);

export const selectApiApplicationsDesktopModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.desktop_model
);

export const selectApiApplicationsDesktopModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.desktop_model_status
);

export const selectApiApplicationsFileShareModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.file_share_model
);

export const selectApiApplicationsFileShareModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.file_share_model_status
);

export const selectApiApplicationsSshModel = createSelector(selectApiApplicationsState, (state: ApiApplicationsState) => state?.ssh_model);

export const selectApiApplicationsSshModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.ssh_model_status
);

export const selectApiApplicationsApiKeyModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.api_key_model
);

export const selectApiApplicationsApiKeyModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.api_key_model_status
);

export const selectApiApplicationsNetworkModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.network_model
);

export const selectApiApplicationsNetworkModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.network_model_status
);

export const selectApiApplicationsLauncherModel = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.launcher_model
);

export const selectApiApplicationsLauncherModelStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.launcher_model_status
);

export const selectApiApplicationsDemoStatus = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.demo_status
);

export const selectApiApplicationsGetAllCurrentAppConfig = createSelector(
  selectApiApplicationsState,
  (state: ApiApplicationsState) => state?.get_all_current_app_config_from_api
);

import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSelectModule } from '@angular/material/select';
import { CommonComponentsModule } from '@app/shared/common-components.module';
import { SharedModule } from '@app/shared/shared.module';
import { TableModule } from '@app/shared/table.module';
import { AuthIssuerPolicyRuleConditionsComponent } from '../auth-issuer-policy-rule-conditions/auth-issuer-policy-rule-conditions.component';
import { AuthIssuerPolicyRuleComponent } from '../auth-issuer-policy-rule/auth-issuer-policy-rule.component';
import { AuthIssuerPolicyComponent } from './auth-issuer-policy.component';

@NgModule({
  imports: [
    CommonComponentsModule,
    CommonModule,
    ReactiveFormsModule,
    MatOptionModule,
    MatCheckboxModule,
    DragDropModule,
    MatExpansionModule,
    MatSelectModule,
    SharedModule,
    TableModule,
  ],
  declarations: [AuthIssuerPolicyComponent, AuthIssuerPolicyRuleComponent, AuthIssuerPolicyRuleConditionsComponent],
  exports: [AuthIssuerPolicyComponent],
})
export class AuthIssuerPolicyModule {}

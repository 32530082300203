import {
  ListApiKeysRequestParams,
  ListAPIKeysResponse,
  ListUsersResponse,
  Resource,
  ResourcesService,
  ResourceTypeEnum,
  ServiceAccount,
  TokensService,
  User,
  UsersService,
} from '@agilicus/angular';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { selectCanAdminOrReadUsers } from '@app/core/user/permissions/users.selectors';
import { select, Store } from '@ngrx/store';
import { combineLatest, forkJoin, Observable, of, Subject } from 'rxjs';
import { concatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { selectCanAdminOrReadTokens } from '@app/core/user/permissions/tokens.selectors';
import { MatStepper } from '@angular/material/stepper';
import { delayStepperAdvanceOnSuccessfulApply, handleStateOnFirstStepSelection } from '../application-template-utils';
import { DefaultStepperState } from '../default-stepper-state';
import { ApplicationModelStatus } from '@app/core/api-applications/api-applications.models';
import {
  ActionApiApplicationsResetApiKeyModel,
  ActionApiApplicationsSubmittingApiKeyModel,
} from '@app/core/api-applications/api-applications.actions';
import { getEmptyStringIfUnset, modifyDataOnFormBlur, updateTableElements } from '../utils';
import { APIKeyModel } from '@app/core/models/api-key/api-key-model';
import {
  selectApiApplicationsApiKeyModel,
  selectApiApplicationsApiKeyModelStatus,
} from '@app/core/api-applications/api-applications.selectors';
import { cloneDeep } from 'lodash-es';
import { getResourceNameAndTypeString, getTrimmedResourceName } from '../resource-utils';
import { getServiceAccounts } from '@app/core/user/user.utils';
import { getResources } from '@app/core/api/resources/resources-api-utils';
import { KeyTabManager } from '../key-tab-manager/key-tab-manager';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { StepperType } from '../stepper-type.enum';
import { AuthService } from '@app/core/services/auth-service.service';
import { convertDateToReadableFormat } from '../date-utils';
import { ButtonType } from '../button-type.enum';
import { TableElement } from '../table-layout/table-element';
import { ChiplistColumn, Column, createChipListColumn, createSelectRowColumn, setColumnDefs } from '../table-layout/column-definitions';
import { getDefaultNewRowProperties, getDefaultTableProperties } from '../table-layout-utils';

export interface CombinedPermissionsAndData {
  permission: OrgQualifiedPermission;
  apiKeys: ListAPIKeysResponse;
  users: ListUsersResponse;
}

export interface ScopeElement extends TableElement {
  scope: string[];
}

@Component({
  selector: 'portal-api-key-new',
  templateUrl: './api-key-new.component.html',
  styleUrl: './api-key-new.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApiKeyNewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public hasUsersPermissions: boolean;
  public hasTokensPermissions: boolean;
  private orgId: string;
  private currentUser: User;
  public usersList: Array<User> = [];
  private userIdToUserMap: Map<string, User> = new Map();
  private userEmailToUserMap: Map<string, User> = new Map();
  public startOptionFormGroup: UntypedFormGroup;
  public apiKeyStepperState: DefaultStepperState = {
    selectedStartOption: undefined,
  };
  private apiKeyModelStatus: ApplicationModelStatus;
  public apiKeyNameFormGroup: UntypedFormGroup;
  public accountTypeFormGroup: UntypedFormGroup;
  public currentApiKeyModel: APIKeyModel;
  public rowObjectName = 'SCOPE';
  public fixedTable = false;
  public buttonsToShow: Array<ButtonType> = [ButtonType.ADD, ButtonType.DELETE];
  public tableData: Array<ScopeElement> = [];
  public columnDefs: Map<string, Column<ScopeElement>> = new Map();
  public makeEmptyTableElementFunc = this.makeEmptyTableElement.bind(this);

  // TODO: add text below:
  public pageDescriptiveText = ``;
  public productGuideLink = `https://www.agilicus.com/anyx-guide/api-key-management/`;

  public completedApiKeyText = `Your API key creation is complete. Please review it below. Make any corrections needed above.`;

  public combinedPermissionsAndData$: Observable<CombinedPermissionsAndData>;
  public serviceAccounts: Array<ServiceAccount>;
  public resources: Array<Resource>;
  public isServiceAccountSelected = false;
  public resourceDropdownOptions: Array<{ label: string; value: string }> = [];
  public resourceTypesList = Object.values(ResourceTypeEnum);
  public keyTabManager: KeyTabManager = new KeyTabManager();

  public appModelSubmissionProgressBarController: ProgressBarController = new ProgressBarController();

  public scopesFormGroup!: UntypedFormGroup;
  public availableResources: Array<{ id: string; name: string }> = [];
  public isSpecificResourceSelected = false;
  public isResourceTypeSelected = false;

  public expiryFormGroup: UntypedFormGroup;
  public minDate: Date;

  public scopesList: string[] = [];
  public displayedColumns: string[] = ['scope', 'actions'];
  public stepperType = StepperType.apiKey;

  public allTransformedResources: { label: string; value: string; type: string }[] = [];
  public filteredResourcesByType: { label: string; value: string }[] = [];

  public apiKeyInfo: { username: string; password: string } | null = null;

  @ViewChild('apiKeysStepper') public apiKeysStepper: MatStepper;

  constructor(
    private store: Store<AppState>,
    private changeDetector: ChangeDetectorRef,
    private usersService: UsersService,
    private tokensService: TokensService,
    private formBuilder: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.minDate = new Date();
    this.minDate.setHours(0, 0, 0, 0);
    this.initializeFormGroups();
    if (this.combinedPermissionsAndData$ === undefined) {
      // we are fetching data for the first time
      this.getPermissionsAndData();
    }
  }

  private getCombinedPermissions$(): Observable<OrgQualifiedPermission> {
    const hasUsersPermissions$ = this.store.pipe(select(selectCanAdminOrReadUsers));
    const hasTokensPermissions$ = this.store.pipe(select(selectCanAdminOrReadTokens));
    const apiKeyModelState$ = this.store.pipe(select(selectApiApplicationsApiKeyModel));
    const apiKeyModelStatusState$ = this.store.pipe(select(selectApiApplicationsApiKeyModelStatus));
    const currentUser$ = this.authService.auth().user$();

    return combineLatest([hasUsersPermissions$, hasTokensPermissions$, apiKeyModelState$, apiKeyModelStatusState$, currentUser$]).pipe(
      switchMap(
        ([hasUsersPermissionsResp, hasTokensPermissionsResp, apiKeyModelStateResp, apiKeyModelStatusStateResp, currentUserResp]) => {
          this.hasUsersPermissions = hasUsersPermissionsResp.hasPermission;
          this.hasTokensPermissions = hasTokensPermissionsResp.hasPermission;
          this.apiKeyModelStatus = apiKeyModelStatusStateResp;

          this.currentUser = currentUserResp;

          if (!this.currentApiKeyModel || this.apiKeyModelStatus.complete) {
            this.currentApiKeyModel = cloneDeep(apiKeyModelStateResp);
          }

          const orgId = hasUsersPermissionsResp.orgId;

          return forkJoin({
            serviceAccounts: getServiceAccounts(this.usersService, orgId),
            resources: getResources(this.resourcesService, orgId),
          }).pipe(
            map(({ serviceAccounts, resources }) => {
              const combinedPermissions: OrgQualifiedPermission = {
                orgId: orgId,
                hasPermission: hasUsersPermissionsResp.hasPermission && hasTokensPermissionsResp.hasPermission,
              };

              if (!this.apiKeyModelStatus.saving) {
                this.initializeFormGroups();
              }
              delayStepperAdvanceOnSuccessfulApply(this.apiKeysStepper, this.apiKeyModelStatus);

              this.serviceAccounts = serviceAccounts;
              this.resources = resources;
              this.transformResourcesForDropdown(resources);
              this.updateTable([]);

              return combinedPermissions;
            })
          );
        }
      )
    );
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndData> {
    return this.getCombinedPermissions$().pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp: OrgQualifiedPermission) => {
        this.orgId = hasPermissionsResp?.orgId;
        let apiKeys$: Observable<ListAPIKeysResponse>;
        let users$: Observable<ListUsersResponse>;
        if (!!this.orgId) {
          const listApiKeysRequestParams: ListApiKeysRequestParams = {
            org_id: this.orgId,
          };
          apiKeys$ = this.tokensService.listApiKeys(listApiKeysRequestParams);
          users$ = this.usersService.listUsers({
            org_id: this.orgId,
            type: ['user'],
          });
        }
        return combineLatest([of(hasPermissionsResp), apiKeys$, users$]);
      }),
      map(([hasPermissionsResp, apiKeysResp, usersResp]: [OrgQualifiedPermission, ListAPIKeysResponse, ListUsersResponse]) => {
        const combinedPermissionsAndData: CombinedPermissionsAndData = {
          permission: hasPermissionsResp,
          apiKeys: apiKeysResp,
          users: usersResp,
        };
        return combinedPermissionsAndData;
      })
    );
  }

  private initializeColumnDefs(): void {
    setColumnDefs([createSelectRowColumn(), this.getScopeColumn()], this.columnDefs);
  }

  private getScopeColumn(): ChiplistColumn<ScopeElement> {
    const scopeColumn = createChipListColumn('scope');
    scopeColumn.isEditable = true;
    scopeColumn.disableField = (row: ScopeElement) => {
      return !(row.isNew && row.scope.length < 1);
    };
    scopeColumn.requiredField = () => true;
    scopeColumn.isRemovable = false;
    scopeColumn.hasMultiAutocomplete = false;
    scopeColumn.isValidEntry = (item: string): boolean => {
      if (item === undefined || item === null) {
        return false;
      }
      return true;
    };
    scopeColumn.getDisplayValue = (scope: string) => {
      return this.processScopeString(scope);
    };
    scopeColumn.isFreeform = true;
    return scopeColumn;
  }

  private updateTable(data: string[]): void {
    this.buildData(data);
    this.replaceTableWithCopy();
  }

  private buildData(data: string[]): void {
    const dataForTable: Array<ScopeElement> = [];
    for (let i = 0; i < data.length; i++) {
      const item = data[i];
      dataForTable.push(this.createTableElement(item, i));
    }
    updateTableElements(this.tableData, dataForTable);
  }

  private createTableElement(item: string, index: number): ScopeElement {
    const data: ScopeElement = {
      scope: [item],
      ...getDefaultTableProperties(index),
    };

    return data;
  }

  public makeEmptyTableElement(): ScopeElement {
    return {
      scope: [],
      ...getDefaultNewRowProperties(),
    };
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }

  public updateEvent(updatedElement: ScopeElement): void {
    this.saveScope(updatedElement);
  }

  private saveScope(tableElement: ScopeElement): void {
    this.createNewScope(tableElement);
  }

  private createNewScope(newTableElement: ScopeElement): void {
    const newScope = this.getItemFromTableElement(newTableElement);

    if (!this.scopes.value.includes(newScope)) {
      this.scopes.push(new FormControl(newScope));
    }

    this.currentApiKeyModel.scopes = this.scopes.value;
    this.updateTable(this.scopes.value);
  }

  private getItemFromTableElement(tableElement: ScopeElement): string {
    return tableElement.scope[0];
  }

  public deleteSelected(itemsToDelete: Array<ScopeElement>): void {
    for (const item of itemsToDelete) {
      if (item.index === -1) {
        continue;
      }
      this.deleteScope(item);
    }
  }

  private deleteScope(item: ScopeElement): void {
    const newScope = this.getItemFromTableElement(item);

    const scopeIndex = this.scopes.value.indexOf(newScope);

    if (scopeIndex !== -1) {
      this.scopes.removeAt(scopeIndex);
    }

    this.currentApiKeyModel.scopes = this.scopes.value;

    this.updateTable(this.scopes.value);
  }

  private transformResourcesForDropdown(resources: Resource[]): void {
    this.allTransformedResources = [];

    resources.forEach((resource: Resource) => {
      const label = getResourceNameAndTypeString(resource.spec.name, resource.spec.resource_type);
      this.allTransformedResources.push({
        label: label,
        value: resource.metadata.id,
        type: resource.spec.resource_type,
      });
    });

    this.filteredResourcesByType = [];
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setUserMaps(): void {
    this.userIdToUserMap.clear();
    this.userEmailToUserMap.clear();
    for (const user of this.usersList) {
      this.userIdToUserMap.set(user.id, user);
      this.userEmailToUserMap.set(user.email, user);
    }
  }

  public getPermissionsAndData(): void {
    this.combinedPermissionsAndData$ = this.getCombinedPermissionsAndData$();
    this.combinedPermissionsAndData$.pipe(takeUntil(this.unsubscribe$)).subscribe((combinedPermissionsAndDataResp) => {
      if (!combinedPermissionsAndDataResp?.apiKeys || !combinedPermissionsAndDataResp?.users) {
        return;
      }
      this.usersList = combinedPermissionsAndDataResp.users.users;
      this.setUserMaps();
    });
  }

  public hasAllPermissions(): boolean {
    return this.hasUsersPermissions && this.hasTokensPermissions;
  }

  public showNoPermissionsText(): boolean {
    return this.hasUsersPermissions !== undefined && this.hasTokensPermissions !== undefined && !this.hasAllPermissions();
  }

  private initializeFormGroups(): void {
    this.initializeAllFormGroups();
    this.changeDetector.detectChanges();
  }

  private initializeAllFormGroups(): void {
    const apiKeyService = this.getModel();
    this.apiKeyNameFormGroup = this.formBuilder.group({
      name: [getEmptyStringIfUnset(apiKeyService?.name)],
    });

    this.accountTypeFormGroup = this.formBuilder.group({
      accountType: ['self'],
      selectedServiceAccount: [getEmptyStringIfUnset(this.currentApiKeyModel?.serviceAccount)],
    });

    this.scopesFormGroup = this.formBuilder.group({
      apiAccess: [false],
      resourceAccess: [false],
      selectedResourceType: [{ value: '', disabled: true }, Validators.required],
      selectedResource: [{ value: '', disabled: true }, Validators.required],
      selectedPermissionScope: [{ value: '', disabled: true }, Validators.required],
      scopes: this.formBuilder.array(apiKeyService?.scopes || [], Validators.required),
    });

    this.expiryFormGroup = this.formBuilder.group({
      expiryDate: [apiKeyService?.expiryDate || null, [Validators.required, Validators.min(this.minDate.getTime())]],
    });
  }

  public onResourceAccessChange(): void {
    const resourceAccess = this.scopesFormGroup.get('resourceAccess')?.value;

    if (resourceAccess) {
      this.scopesFormGroup.get('selectedResourceType')?.enable();
    } else {
      this.scopesFormGroup.get('selectedResourceType')?.disable();
    }

    this.changeDetector.detectChanges();
  }

  public onResourceTypeChange(): void {
    const selectedType = this.scopesFormGroup.get('selectedResourceType')?.value;

    if (selectedType) {
      this.filteredResourcesByType = this.allTransformedResources.filter((r) => r.type === selectedType);

      this.scopesFormGroup.get('selectedResource')?.enable();
      this.scopesFormGroup.get('selectedPermissionScope')?.enable();
    } else {
      this.filteredResourcesByType = [];
      this.scopesFormGroup.get('selectedResource')?.disable();
      this.scopesFormGroup.get('selectedPermissionScope')?.disable();
    }
  }

  public onApiAccessChange(isChecked: boolean): void {
    const apiScope = 'urn:agilicus:api:*';

    if (isChecked) {
      if (!this.scopes.value.includes(apiScope)) {
        this.scopes.push(new FormControl(apiScope));
      }
    } else {
      const index = this.scopes.value.indexOf(apiScope);
      if (index !== -1) {
        this.scopes.removeAt(index);
      }
    }

    this.currentApiKeyModel.scopes = this.scopes.value;
    this.updateTable(this.scopes.value);
  }

  public onExpiryDateNextClick(): void {
    if (this.expiryFormGroup.valid) {
      this.currentApiKeyModel.expiryDate = this.expiryFormGroup.get('expiryDate')?.value;
    }
  }

  public getReadableExpiryDate(): string {
    return convertDateToReadableFormat(this.currentApiKeyModel.expiryDate);
  }

  private getResourceNameFromId(resourceId: string): string {
    if (!this.resources || !Array.isArray(this.resources)) {
      return resourceId;
    }

    const resource = this.resources.find((r) => r.metadata.id === resourceId);
    return resource ? resource.spec.name : resourceId;
  }

  public processScopeString(scope: string): string {
    const parts = scope.split(':');

    if (parts.length === 5) {
      const resourceId = parts[3];
      const resourceName = this.getResourceNameFromId(resourceId);

      parts[3] = resourceName;
      return parts.join(':');
    }

    return scope;
  }

  get scopes(): UntypedFormArray {
    return this.scopesFormGroup.get('scopes') as UntypedFormArray;
  }

  public onResourceSelectionChange(): void {
    const selectedResource = this.scopesFormGroup.get('selectedResource')?.value;
    const permissionScopeControl = this.scopesFormGroup.get('selectedPermissionScope');

    if (selectedResource === '*') {
      permissionScopeControl?.disable();
    } else {
      permissionScopeControl?.enable();
    }

    permissionScopeControl?.updateValueAndValidity();
  }

  public isScopeValid(): boolean {
    const type = this.scopesFormGroup.get('selectedResourceType')?.value;
    const scope = this.scopesFormGroup.get('selectedResource')?.value;
    const permission = this.scopesFormGroup.get('selectedPermissionScope')?.value;

    if (!type || !scope) return false;

    if (scope !== '*' && !permission) return false;

    return true;
  }

  public addScope(): void {
    const type = this.scopesFormGroup.get('selectedResourceType')?.value;
    const scope = this.scopesFormGroup.get('selectedResource')?.value;
    const permission = this.scopesFormGroup.get('selectedPermissionScope')?.value;

    if (!type || !scope) return;

    const fullScope = scope === '*' ? `urn:agilicus:${type}:${scope}` : `urn:agilicus:${type}:${scope}:${permission}`;

    if (!this.scopes.value.includes(fullScope)) {
      this.scopes.push(new FormControl(fullScope));
    }

    this.currentApiKeyModel.scopes = this.scopes.value;
    this.updateTable(this.scopes.value);
  }

  public isStepperComplete(): boolean {
    return this.apiKeyModelStatus.complete;
  }

  public onStepperSelectionChange(selectedIndex: number): void {
    handleStateOnFirstStepSelection(
      selectedIndex,
      this.apiKeyModelStatus,
      this.apiKeyStepperState,
      this.resetModel.bind(this),
      this.initializeStartOptionFormGroup.bind(this)
    );
  }

  private initializeStartOptionFormGroup(): void {
    this.startOptionFormGroup = this.formBuilder.group({
      selectedStartOption: [this.apiKeyStepperState.selectedStartOption, Validators.required],
    });
  }

  public onAccountTypeChange(): void {
    const accountType = this.accountTypeFormGroup.get('accountType')?.value;
    const selectedServiceAccountControl = this.accountTypeFormGroup.get('selectedServiceAccount');

    if (accountType === 'serviceAccount') {
      this.isServiceAccountSelected = true;
      selectedServiceAccountControl?.setValidators([Validators.required]);
    } else {
      this.isServiceAccountSelected = false;
      this.accountTypeFormGroup.get('selectedServiceAccount')?.setValue('');
      selectedServiceAccountControl?.clearValidators();
      selectedServiceAccountControl?.setValue('');
    }

    selectedServiceAccountControl?.updateValueAndValidity();
  }

  private resetModel(): void {
    this.store.dispatch(new ActionApiApplicationsResetApiKeyModel());
  }

  private getModel(): APIKeyModel | undefined {
    return this.currentApiKeyModel;
  }

  public onFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    modifyDataOnFormBlur(form, formField, this.modifyStepperDataOnFormBlur.bind(this), obj);
  }

  private modifyStepperDataOnFormBlur<T extends object>(form: UntypedFormGroup, formField: string, obj: T): void {
    let formValue = form.get(formField).value;
    if (formField === 'name') {
      formValue = getTrimmedResourceName(formValue);
    }
    obj[formField] = formValue;
  }

  public updateSelectedServiceAccount(form: UntypedFormGroup, value: string): void {
    if (form.controls.selectedServiceAccount.invalid) {
      return;
    }

    this.updateApplicationKeyModel(value);
  }

  public onCheckboxChange(param: string, isBoxChecked: boolean): void {
    this.currentApiKeyModel[param] = isBoxChecked;
  }

  public updateApplicationKeyModel(serviceAccount: string): void {
    this.currentApiKeyModel.serviceAccount = serviceAccount;
  }

  public submitApiKeyModel(apiKeyModel: APIKeyModel): void {
    if (apiKeyModel.serviceAccount) {
      apiKeyModel.id = apiKeyModel.serviceAccount;
    } else {
      apiKeyModel.id = this.currentUser.id;
    }

    this.store.dispatch(new ActionApiApplicationsSubmittingApiKeyModel(apiKeyModel));
  }
}

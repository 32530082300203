<portal-page-info
  pageTitle="Resource Access Requests"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasPermissions !== undefined && !hasPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div *ngIf="hasPermissions" class="resource-access-requests-container">
  <portal-table-layout
    #tableLayoutComp
    [tableData]="tableData"
    [columnDefs]="parentColumnDefs"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    [filterManager]="parentFilterManager"
    [keyTabManager]="keyTabManager"
    hasNestedTable="true"
    [buttonsToShow]="buttonsToShow"
    [customButtons]="customButtons"
    (updateSelection)="updateSelection($event)"
    (approveSelected)="acceptBulkUsersRequests($event)"
    (rejectSelected)="rejectAllUserRequests($event)"
  >
  </portal-table-layout>
</div>
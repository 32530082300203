import { GetUsageMetricsRequestParams, OrganisationsService, UsageMetric, UsageMetrics } from '@agilicus/angular';
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core';
import { BillingProductFull } from '@app/core/models/billing/billing-product-full';
import { BillingProductLabel } from '@app/core/models/billing/billing-product-label.enum';
import { BillingProductType } from '@app/core/models/billing/billing-product-type.enum';
import { BillingSubscriptionFull } from '@app/core/models/billing/billing-subscription-full';
import { MetricType } from '@app/core/models/billing/metric-type.enum';
import { select, Store } from '@ngrx/store';
import { Observable, Subject, concatMap, of, combineLatest, map } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { capitalizeFirstLetter, createEnumChecker, updateTableElements } from '../utils';
import { selectCanAdminApps } from '@app/core/user/permissions/app.selectors';
import { selectCanAdminUsers } from '@app/core/user/permissions/users.selectors';
import { createCombinedPermissionsSelector } from '@app/core/user/permissions/permissions.selectors';
import { OrgQualifiedPermission } from '@app/core/user/permissions/permissions.selectors';
import { BillingAccountFull } from '@app/core/models/billing/billing-account-full';
import { Column, createReadonlyColumn, setColumnDefs } from '../table-layout/column-definitions';
import { TableElement } from '../table-layout/table-element';
import { getDefaultReadonlyTableSettings, getDefaultTableProperties } from '../table-layout-utils';
import { FilterManager } from '../filter/filter-manager';

export interface UsageMetricElement extends TableElement {
  type: string;
  active: string;
  provisioned: string;
  label: BillingProductLabel;
}

export interface CombinedPermissionsAndMetricsData {
  permission: OrgQualifiedPermission;
  usageMetrics: UsageMetrics;
}

@Component({
  selector: 'portal-usage-metrics',
  templateUrl: './usage-metrics.component.html',
  styleUrls: ['./usage-metrics.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsageMetricsComponent implements OnInit, OnDestroy {
  @Input() public billingAccountInfo: BillingAccountFull;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public usageMetrics: UsageMetrics;
  public displayedColumns: string[] = ['type', 'active', 'provisioned'];
  private productLabelToIdMap: Map<string, string> = new Map();
  public hasPermissions: boolean;
  public orgId: string;
  public columnDefs: Map<string, Column<UsageMetricElement>> = new Map();
  public tableData: Array<UsageMetricElement> = [];
  public filterManager: FilterManager = new FilterManager();

  // This is required in order to reference the enum in the html template.
  public billingProductType = BillingProductType;

  public getDefaultReadonlyTableSettings = getDefaultReadonlyTableSettings;

  constructor(
    private store: Store<AppState>,
    private organisationsService: OrganisationsService,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this.initializeColumnDefs();
    this.getPermissionsAndSetData();
  }

  private getCombinedPermissionsAndData$(): Observable<CombinedPermissionsAndMetricsData> {
    const hasPermissions$ = this.store.pipe(select(createCombinedPermissionsSelector(selectCanAdminUsers, selectCanAdminApps)));
    return hasPermissions$.pipe(
      takeUntil(this.unsubscribe$),
      concatMap((hasPermissionsResp) => {
        let usageMetrics$: Observable<UsageMetrics> | undefined = of(undefined);
        if (!!hasPermissionsResp?.orgId && !!hasPermissionsResp?.hasPermission) {
          const usageMetricsRequestParams: GetUsageMetricsRequestParams = {
            org_id: hasPermissionsResp.orgId,
          };
          usageMetrics$ = this.organisationsService.getUsageMetrics(usageMetricsRequestParams);
        }
        return combineLatest([of(hasPermissionsResp), usageMetrics$]);
      }),
      map(([hasPermissionsResp, usageMetricsResp]: [OrgQualifiedPermission, UsageMetrics]) => {
        const combinedPermissionsAndMetricsData: CombinedPermissionsAndMetricsData = {
          permission: hasPermissionsResp,
          usageMetrics: usageMetricsResp,
        };
        return combinedPermissionsAndMetricsData;
      })
    );
  }

  private getPermissionsAndSetData(): void {
    this.getCombinedPermissionsAndData$().subscribe((combinedPermissionsAndDataResp) => {
      this.orgId = combinedPermissionsAndDataResp?.permission?.orgId;
      this.hasPermissions = combinedPermissionsAndDataResp?.permission?.hasPermission;
      this.usageMetrics = combinedPermissionsAndDataResp.usageMetrics;
      if (!this.hasPermissions) {
        // Need this in order for the "No Permissions" text to be displayed when the page first loads.
        this.changeDetector.detectChanges();
        return;
      }
      this.setupData();
    });
  }

  public ngOnDestroy(): void {
    this.changeDetector.detach();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private setupData(): void {
    this.setProductLabelToIdMap();
    this.updateTable();
    this.changeDetector.detectChanges();
  }

  private updateTable(): void {
    this.buildData();
    this.replaceTableWithCopy();
  }

  private buildData(): void {
    const inputMetrics = !!this.usageMetrics ? this.usageMetrics.metrics : new Array<UsageMetric>();
    const data: Array<UsageMetricElement> = [];
    for (let i = 0; i < inputMetrics.length; i++) {
      data.push(this.createUsageMetricsElement(inputMetrics[i], i));
    }
    updateTableElements(this.tableData, data);
  }

  private createUsageMetricsElement(metric: UsageMetric, index: number): UsageMetricElement {
    const productLabel = this.getProductLabelByType(metric.type);
    const data: UsageMetricElement = {
      active: `${metric.active.peak} @ ${this.getProductPriceDisplayValue(
        this.getProductPriceInDollarsFromLabel(productLabel, BillingProductType.active)
      )}`,
      provisioned: `${metric.provisioned.peak} @ ${this.getProductPriceDisplayValue(
        this.getProductPriceInDollarsFromLabel(productLabel, BillingProductType.provisioned)
      )}`,
      type: this.displayUsageMetricType(metric.type),
      label: productLabel,
      ...getDefaultTableProperties(index),
    };
    return data;
  }

  public getProductPriceTooltip(): string {
    if (!this.billingAccountInfo) {
      return 'Pricing information is not yet available for this product type';
    }
    return '';
  }

  private getProductPriceDisplayValue(productPriceInDollars: string): string {
    if (productPriceInDollars === undefined) {
      return 'N/A';
    }
    return `$${productPriceInDollars}`;
  }

  private displayUsageMetricType(metricType: string): string {
    const typeTranslation: Map<string, string> = new Map<string, string>([
      ['application_service', 'Network Resources'],
      ['application', 'Application Resources'],
      ['fileshare', 'Share Resources'],
      ['service_forwarder', 'Service Forwarder Resources'],
      ['desktop', 'Desktop Resources'],
      ['user', 'Users'],
    ]);
    if (typeTranslation.has(metricType)) {
      return typeTranslation.get(metricType);
    }
    return capitalizeFirstLetter(metricType);
  }

  public setProductLabelToIdMap(): void {
    this.productLabelToIdMap.clear();
    if (!this.billingAccountInfo?.status?.products) {
      return;
    }
    for (const product of this.billingAccountInfo.status.products) {
      if (!product) {
        // A value of null is currently possible
        continue;
      }
      const productFull = product as BillingProductFull;
      if (!productFull.metadata.metric) {
        continue;
      }
      this.productLabelToIdMap.set(productFull.metadata.metric, productFull.id);
    }
  }

  private getProductLabelByType(type: string): BillingProductLabel {
    const isMetricTypeEnum = createEnumChecker(MetricType);
    if (!isMetricTypeEnum(type)) {
      return undefined;
    }
    switch (type) {
      case MetricType.application: {
        return BillingProductLabel.application_resources;
      }
      case MetricType.application_service: {
        return BillingProductLabel.application_service_resources;
      }
      case MetricType.fileshare: {
        return BillingProductLabel.fileshare_resources;
      }
      case MetricType.desktop: {
        return BillingProductLabel.desktop_resources;
      }
      case MetricType.user: {
        return BillingProductLabel.users;
      }
      default: {
        return undefined;
      }
    }
  }

  public getProductPriceInDollarsFromLabel(label: BillingProductLabel | undefined, type: BillingProductType): string | undefined {
    if (
      !this.billingAccountInfo ||
      !this.billingAccountInfo.status?.subscriptions ||
      this.billingAccountInfo.status.subscriptions.length === 0
    ) {
      return undefined;
    }
    const productId = this.productLabelToIdMap.get(`${type}_${label}`);
    for (const subscription of this.billingAccountInfo.status.subscriptions) {
      const subscriptionFull = subscription as BillingSubscriptionFull;
      for (const item of subscriptionFull.stripe_subscription.items.data) {
        if (item.price.product === productId) {
          const currency = item.price.currency;
          return `${item.price.unit_amount / 100} ${currency.toUpperCase()}`;
        }
      }
    }
    return '0';
  }

  private getTypeColumn(): Column<UsageMetricElement> {
    const column = createReadonlyColumn('type');
    return column;
  }

  private getActiveColumn(): Column<UsageMetricElement> {
    const column = createReadonlyColumn('active');
    return column;
  }

  private getProvisionedColumn(): Column<UsageMetricElement> {
    const column = createReadonlyColumn('provisioned');
    return column;
  }

  private initializeColumnDefs(): void {
    setColumnDefs([this.getTypeColumn(), this.getActiveColumn(), this.getProvisionedColumn()], this.columnDefs);
  }

  private replaceTableWithCopy(): void {
    const tableDataCopy = [...this.tableData];
    this.tableData = tableDataCopy;
    this.changeDetector.detectChanges();
  }
}

<portal-page-info
  pageTitle="Expose Resource"
  [pageDescriptiveText]="pageDescriptiveText"
  [pageSubDescriptiveList]="pageSubDescriptiveList"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div class="main-content-container">
  <mat-slide-toggle
    class="mark-complete-slider"
    [checked]="isTaskComplete"
    (change)="onCompleteToggle($event.checked)"
  >
    {{ getTaskCompleteToggleText(targetTask, gettingStartedDataCopy) }}
  </mat-slide-toggle>

  <form
    [formGroup]="exposeResourceFormGroup"
    spellcheck="false"
    autocomplete="off"
  >
    <div class="parent-radio-group">
      <mat-radio-group
        formControlName="resourceType"
        aria-labelledby="resource type option radio buttons"
        class="radio-group"
      >
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="resourceTypeEnum.application"
        >
          I want to expose a website accessed by a browser
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="resourceTypeEnum.application_service"
        >
          I want to expose my network resource like an SQL database
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="resourceTypeEnum.launcher"
        >
          I want to expose my legacy application which uses a desktop client
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="resourceTypeEnum.desktop"
        >
          I want to expose my desktop for remote access
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          [value]="resourceTypeEnum.fileshare"
        >
          I want to expose files
        </mat-radio-button>
        <mat-radio-button
          class="radio-button full-line-radio-button"
          value="other"
        >
          I want to expose something else
        </mat-radio-button>
      </mat-radio-group>
    </div>
  </form>

  <div class="recommendations-container">
    <div *ngIf="needToExposeApplication()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a new Application."
        imageSourcePath="assets/img/expose-resource-application.png"
        (imageClick)="onImageClick('application-new')"
      >
      </portal-getting-started-recommendations>
    </div>

    <!-- TODO: change image screenshot for Networks -->
    <div *ngIf="needToExposeApplicationService()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a Network resource."
        imageSourcePath="assets/img/expose-resource-network.png"
        (imageClick)="onImageClick('network-overview')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="needToExposeLauncher()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a Launcher."
        imageSourcePath="assets/img/expose-resource-launcher.png"
        (imageClick)="onImageClick('launcher-overview')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="needToExposeDesktop()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a Desktop resource."
        imageSourcePath="assets/img/expose-resource-desktop.png"
        (imageClick)="onImageClick('desktop-new')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="needToExposeShare()">
      <portal-getting-started-recommendations
        recommendationCustomText="We recommend that you create a Share."
        imageSourcePath="assets/img/expose-resource-share.png"
        (imageClick)="onImageClick('shares-new')"
      >
      </portal-getting-started-recommendations>
    </div>

    <div *ngIf="needToExposeOther()">
      <p>
        For further assistance, please use the Chat icon in the lower-right, or email info&#64;agilicus.com
      </p>
    </div>
  </div>
</div>
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  Application,
  ApplicationService,
  ApplicationServicesService,
  ApplicationsService,
  CatalogueEntry,
  CataloguesService,
  Connector,
  ConnectorsService,
  EnvironmentConfigVarList,
  Issuer,
  IssuerClient,
  IssuersService,
  Organisation,
  RoleToRuleEntry,
  RoleV2,
  RuleV2,
  User,
  UsersService,
  FileShareService,
  DesktopResource,
  SSHResource,
  CreateLauncherRequestParams,
  LaunchersService,
  Launcher,
  AuthenticationDocument,
  GroupsService,
  PermissionsService,
  ResourcePermissionSpec,
  TokensService,
  AgentConnector,
  ObjectCredential,
  CredentialsService,
  RuleConfig,
  PolicyTemplateInstance,
  PolicyTemplatesService,
  SimpleResourcePolicyTemplate,
  ResourcesService,
  Resource,
  APIKey,
} from '@agilicus/angular';
import { map, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { NotificationService, AppState } from '../../core';
import { EnvironmentConfigService } from '../services/environment-config/environment-config.service';
import { of, Observable, forkJoin } from 'rxjs';
import {
  ApiApplicationsActionTypes,
  ActionApiApplicationsAppSaveFinished,
  ActionApiApplicationsSubmittingApplicationModel,
  ActionApiApplicationsSuccessfulApplicationModelSubmission,
  ActionApiApplicationsFailedApplicationModelSubmission,
  ActionApiApplicationsUpdateApplicationModel,
  ActionApiApplicationsSubmittingFileShareServiceModel,
  ActionApiApplicationsSuccessfulFileShareServiceModelSubmission,
  ActionApiApplicationsFailedFileShareServiceModelSubmission,
  ActionApiApplicationsSubmittingDesktopModel,
  ActionApiApplicationsFailedDesktopModelSubmission,
  ActionApiApplicationsSuccessfulDesktopModelSubmission,
  ActionApiApplicationsSubmittingSSHModel,
  ActionApiApplicationsFailedSSHModelSubmission,
  ActionApiApplicationsSuccessfulSSHModelSubmission,
  ActionApiApplicationsSubmittingNetworkModel,
  ActionApiApplicationsFailedNetworkModelSubmission,
  ActionApiApplicationsSuccessfulNetworkModelSubmission,
  ActionApiApplicationsSubmittingLauncherModel,
  ActionApiApplicationsFailedLauncherModelSubmission,
  ActionApiApplicationsSuccessfulLauncherModelSubmission,
  ActionApiApplicationsMaintainState,
  ActionApiApplicationsCreateDemo,
  ActionApiApplicationsSuccessfulDemoCreation,
  ActionApiApplicationsFailedDemoCreation,
  ActionApiApplicationsDeleteDemo,
  ActionApiApplicationsSuccessfulDemoDelete,
  ActionApiApplicationsFailedDemoDelete,
  ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation,
  DemoDeleteResources,
  ActionApiApplicationsSubmittingApiKeyModel,
  ActionApiApplicationsSuccessfulApiKeyModelSubmission,
  ActionApiApplicationsFailedApiKeyModelSubmission,
} from '../api-applications/api-applications.actions';
import { Store, select } from '@ngrx/store';
import { selectApiOrgId, selectIssuer, selectIssuerId, selectUser } from '../user/user.selectors';
import { ApplicationModel } from '../models/application/application-model';
import { selectCurrentOrganisation } from '../organisations/organisations.selectors';
import {
  addCommonPathPrefixRuleToPolicyTemplate,
  createNewApplication,
  createNewRole,
  createNewRoletoRuleEntry,
  createNewRule,
  createOrUpdateEnvConfigVarsList,
  deleteApplicationByName$,
  getConnectorByName,
  updateExistingApplication,
} from '../api-applications/api-applications-utils';
import {
  createNewIssuerClient,
  getIssuerClientFromApplicationModel,
  updateExistingIssuerClient,
} from '../issuer-clients/issuer-clients-utils';
import {
  setApplicationConfigFromModel,
  ALL_ACCESS_RULE_COMMENT,
  findRuleWithComment,
  getAllAccessRule,
  getApplicationFromModel,
  getDefaultRole,
  getGroupAndUpdateRoles,
  getOidcProxyRuntimeCatalogueEntry,
  getRoleToRuleEntry,
  needToConfigureConnector,
  needToCreateDefaultRole,
  needToCreateIssuerClient,
  needToCreateUpstreamService,
  needToUpdateGroup,
  ruleFromSpec,
  needToCreateSshCredential,
  getAllAccessPolicyRule,
  needToSetCommonPathPrefixRule,
} from '../models/application/application-model-api-utils';
import { getConnectorEnvConfigVars } from '../models/application/application-model-api-agent-utils';
import { getProxyPolicyRules, getProxyRules } from '../models/application/application-model-api-proxy-utils';
import {
  createNewApplicationService,
  createNewDesktopResource,
  createNewSSHResource,
  createFileShareService,
  getDesktopResourceFromModel,
  getSSHResourceFromModel,
  getFileShareServiceFromModel,
  getSelfHostedApplicationServiceFromApplicationModel,
  updateExistingApplicationService,
  updateExistingDesktopResource,
  updateExistingSSHResource,
  updateExistingFileShareService,
  getNetworkFromModel,
  getApplicationService,
  deleteExistingApplicationServiceByName$,
  deleteExistingDesktopResourceByName$,
  deleteExistingFileShareServiceByName$,
} from '../application-service-state/application-services-utils';
import { cloneDeep } from 'lodash-es';
import { FileShareModel } from '../models/file-share/file-share-model';
import { DesktopModel } from '../models/desktop/desktop-model';
import { SSHModel } from '../models/ssh/ssh-model';
import { DynamicEnvironmentService } from '../services/dynamic-environment.init';
import { createBasicDefaultIssuer } from '../issuer-state/issuer.utils';
import { NetworkModel } from '../models/network/network-model';
import * as ApplicationServiceActions from '../application-service-state/application-service.actions';
import * as FileShareServiceActions from '../file-share-state/file-share-service.actions';
import * as DesktopResourceActions from '../desktop-state/desktop.actions';
import * as SSHResourceActions from '../ssh-state/ssh.actions';
import * as LauncherActions from '../launcher-state/launcher.actions';
import * as ApiKeyActions from '../api-key-state/api-key.actions';
import { LauncherModel } from '../models/launcher/launcher-model';
import { getLauncherResourceFromModel } from '../launcher-state/launcher-utils';
import { createNewLauncher, updateExistingLauncher } from '../api/launchers/launchers-api-utils';
import {
  assignDemoResourcePermissions$,
  createAndSetupDemoGroup$,
  createDemoConnectorWithAuthenticationDocument$,
  deleteDemoIssuerClientsByApplicationName$,
  getDemoAgentConnectorName,
  getDemoApplicationModel,
  getDemoApplicationName,
  getDemoDesktopModel,
  getDemoDesktopName,
  getDemoEnvConfigVarsList,
  getDemoGroupName,
  getDemoShareModel,
  getDemoShareName,
  getDemoUpstreamServiceName,
} from '../api/demo-api-utils';
import { ApiDataCreation, createNewApiDataHandleErrors, getMoreThanOneResultErrorMessage } from '../api/api-utils';
import { selectApiApplicationsDemoStatus } from '../api-applications/api-applications.selectors';
import { ApplicationModelStatus } from '../api-applications/api-applications.models';
import { deleteGroupByName$ } from '../api/group-api-utils';
import { deleteExistingAgentConnectorByName$, getConnectorById } from '../api/connectors/connectors-api-utils';
import * as AgentConnectorActions from '../agent-connector-state/agent-connector.actions';
import * as ConnectorActions from '../connector-state/connector.actions';
import { createNewSshCredential$ } from '../api/credentials-api-utils';
import { getDefaultNewCredential } from '../credentials-utils';
import { createNewPolicyTemplateInstance$ } from '../api/policy-template-instance/policy-template-instance-api-utils';
import { PolicyTemplateType } from '../api/policy-template-instance/policy-template-type';
import { getResourceAndReconcileDefaultPolicy$ } from '../api/resources/resources-api-utils';
import { getNewApplicationEmptyPolicy } from '../api/policy-template-instance/policy-template-instance-utils';
import { APIKeyModel } from '../models/api-key/api-key-model';
import { createNewApiKey, getApiKeyFromModel, updateExistingApiKey } from '../api-key-state/api-key-utils';

@Injectable()
export class ApplicationModelEffects {
  constructor(
    private actions$: Actions,
    private applicationsService: ApplicationsService,
    private notificationService: NotificationService,
    private store: Store<AppState>,
    private environmentConfigService: EnvironmentConfigService,
    private usersService: UsersService,
    private issuersService: IssuersService,
    private applicationServicesService: ApplicationServicesService,
    private cataloguesService: CataloguesService,
    private connectorsService: ConnectorsService,
    private launchersService: LaunchersService,
    private groupService: GroupsService,
    private permissionsService: PermissionsService,
    private tokensService: TokensService,
    public env: DynamicEnvironmentService,
    private credentialsService: CredentialsService,
    private policyTemplatesService: PolicyTemplatesService,
    private resourcesService: ResourcesService
  ) {}

  public createDemo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.CREATE_DEMO),
      concatMap((action: ActionApiApplicationsCreateDemo) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation))));
      }),
      concatMap(([action, currentOrg]: [ActionApiApplicationsCreateDemo, Organisation]) => {
        return createDemoConnectorWithAuthenticationDocument$(this.connectorsService, this.tokensService, currentOrg).pipe(
          concatMap((resp) => {
            const agentConnectorResp: AgentConnector = resp[0];
            const authDocResp: AuthenticationDocument = resp[1];
            return of(new ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation(agentConnectorResp, authDocResp));
          }),
          catchError((error) => {
            this.notificationService.error('Failed to create demo environment!');
            return of(new ActionApiApplicationsFailedDemoCreation());
          })
        );
      })
    )
  );

  public submitApplicationOnSuccessfulDemoConnectorAndAuthDocCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CONNECTOR_AND_AUTH_DOC_CREATION),
      map((action: ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation) => {
        return new ActionApiApplicationsSubmittingApplicationModel(getDemoApplicationModel(), action.authDoc);
      })
    )
  );

  public addToStateOnSuccessfulDemoAgentConnectorSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CONNECTOR_AND_AUTH_DOC_CREATION),
      map((action: ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation) => {
        return AgentConnectorActions.upsertAgentConnector({
          obj: action.agentConnector,
          refreshData: true,
        });
      })
    )
  );

  public addToConnectorStateOnSuccessfulDemoAgentConnectorSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DEMO_CONNECTOR_AND_AUTH_DOC_CREATION),
      concatMap((action: ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation))));
      }),
      concatMap(([action, currentOrg]: [ActionApiApplicationsSuccessfulDemoConnectorAndAuthDocCreation, Organisation]) => {
        return getConnectorById(this.connectorsService, action.agentConnector.metadata.id, currentOrg.id).pipe(
          map((connectorResp) => {
            return ConnectorActions.upsertConnector({
              obj: connectorResp,
              refreshData: true,
            });
          })
        );
      })
    )
  );

  public deleteDemo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.DELETE_DEMO),
      concatMap((action: ActionApiApplicationsDeleteDemo) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation))));
      }),
      concatMap(([action, currentOrg]: [ActionApiApplicationsDeleteDemo, Organisation]) => {
        return this.deleteAllDemoResources$(currentOrg.id);
      }),
      map((deletedResources) => {
        this.notificationService.success(`Demo environment was successfully deleted!`);
        return new ActionApiApplicationsSuccessfulDemoDelete(deletedResources);
      }),
      catchError((err) => {
        console.log('err');
        console.log(err);
        if (err.error.error_message === getMoreThanOneResultErrorMessage()) {
          this.notificationService.error(`Unable to delete all demo resources. Duplicate resource found.`);
        } else {
          this.notificationService.error(`Failed to delete the demo environment. Please try again.`);
        }
        return of(new ActionApiApplicationsFailedDemoDelete());
      })
    )
  );

  public removeFromStateOnSuccessfulDemoAgentConnectorDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DEMO_DELETE),
      map((action: ActionApiApplicationsSuccessfulDemoDelete) => {
        const deletedAgentConnector: AgentConnector = action.deletedResources.agentConnector;
        return AgentConnectorActions.deleteAgentConnector({
          id: deletedAgentConnector.metadata.id,
          obj: deletedAgentConnector,
          refreshData: true,
        });
      })
    )
  );

  public removeFromStateOnSuccessfulDemoConnectorDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DEMO_DELETE),
      map((action: ActionApiApplicationsSuccessfulDemoDelete) => {
        const deletedAgentConnector: AgentConnector = action.deletedResources.agentConnector;
        return ConnectorActions.deleteConnector({
          id: deletedAgentConnector.metadata.id,
          obj: deletedAgentConnector as any,
          refreshData: true,
        });
      })
    )
  );

  public submittingAppModel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_APPLICATION_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingApplicationModel) => {
        return of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectApiOrgId)),
            this.store.select(selectUser),
            this.store.select(selectIssuer),
            this.store.select(selectIssuerId),
            this.store.select(selectCurrentOrganisation)
          )
        );
      }),
      concatMap(
        ([action, orgId, currentUser, issuer, issuerId, currentOrg]: [
          ActionApiApplicationsSubmittingApplicationModel,
          string,
          User,
          string,
          string,
          Organisation
        ]) => {
          const currentIssuer = createBasicDefaultIssuer({ id: issuerId, issuer: issuer });
          const appModelCopy = cloneDeep(action.appModel);
          const applicationModelSubmitAll$ = this.applicationModelSubmitAll(
            appModelCopy,
            orgId,
            currentUser,
            currentIssuer,
            currentOrg,
            action.authDoc,
            action.usePolicyRules
          );
          return applicationModelSubmitAll$.pipe(
            concatMap((appModelCreateResp: Application) => {
              if (!action.appModel.is_demo) {
                this.notificationService.success('New application "' + appModelCreateResp.name + '" was successfully created');
              }
              return of(new ActionApiApplicationsSuccessfulApplicationModelSubmission(appModelCopy));
            }),
            catchError((error) => {
              this.notificationService.error('Failed to create new application "' + appModelCopy.name + '". Please try again.');
              return of(new ActionApiApplicationsFailedApplicationModelSubmission());
            })
          );
        }
      )
    );
  });

  public updateDemoStatusOnFailedApplicationModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.FAILED_APPLICATION_MODEL_SUBMISSION),
      concatMap((action: ActionApiApplicationsFailedApplicationModelSubmission) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiApplicationsDemoStatus))));
      }),
      map(([action, demoStatus]: [ActionApiApplicationsFailedApplicationModelSubmission, ApplicationModelStatus]) => {
        if (demoStatus.saving) {
          return new ActionApiApplicationsFailedDemoCreation();
        }
        return new ActionApiApplicationsMaintainState();
      })
    )
  );

  public onDemoApplicationCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_APPLICATION_MODEL_SUBMISSION),
      concatMap((action: ActionApiApplicationsSuccessfulApplicationModelSubmission) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation)), this.store.pipe(select(selectUser))));
      }),
      concatMap(([action, currentOrg, user]: [ActionApiApplicationsSuccessfulApplicationModelSubmission, Organisation, User]) => {
        if (!!action.model.is_demo) {
          return createAndSetupDemoGroup$(this.groupService, action.model, user, currentOrg).pipe(
            map((_) => {
              return new ActionApiApplicationsSubmittingDesktopModel(getDemoDesktopModel());
            }),
            catchError((_) => {
              this.notificationService.error(`Failed to configure new group "${getDemoGroupName()}"`);
              return of(new ActionApiApplicationsFailedDemoCreation());
            })
          );
        }
        return of(new ActionApiApplicationsMaintainState());
      })
    )
  );

  public onDemoDesktopCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DESKTOP_MODEL_SUBMISSION),
      concatMap((action: ActionApiApplicationsSuccessfulDesktopModelSubmission) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation))));
      }),
      concatMap(([action, currentOrg]: [ActionApiApplicationsSuccessfulDesktopModelSubmission, Organisation]) => {
        if (!!action.model.is_demo) {
          return assignDemoResourcePermissions$(
            this.groupService,
            this.permissionsService,
            currentOrg,
            action.desktopResource.metadata.id,
            ResourcePermissionSpec.ResourceTypeEnum.desktop
          ).pipe(
            map((resp) => {
              if (!resp) {
                this.notificationService.error(`Failed to assign permissions for "${action.desktopResource.spec.name}"`);
                return new ActionApiApplicationsFailedDemoCreation();
              }
              return new ActionApiApplicationsSubmittingFileShareServiceModel(getDemoShareModel());
            }),
            catchError((_) => {
              return of(new ActionApiApplicationsFailedDemoCreation());
            })
          );
        }
        return of(new ActionApiApplicationsMaintainState());
      })
    )
  );

  public onDemoShareCreation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_FILE_SHARE_SERVICE_MODEL_SUBMISSION),
      concatMap((action: ActionApiApplicationsSuccessfulFileShareServiceModelSubmission) => {
        return of(action).pipe(withLatestFrom(this.store.pipe(select(selectCurrentOrganisation))));
      }),
      concatMap(([action, currentOrg]: [ActionApiApplicationsSuccessfulFileShareServiceModelSubmission, Organisation]) => {
        if (!!action.model.is_demo) {
          return assignDemoResourcePermissions$(
            this.groupService,
            this.permissionsService,
            currentOrg,
            action.share.metadata.id,
            ResourcePermissionSpec.ResourceTypeEnum.fileshare
          ).pipe(
            map((resp) => {
              if (!resp) {
                this.notificationService.error(`Failed to assign permissions for "${action.share.spec.name}"`);
                return new ActionApiApplicationsFailedDemoCreation();
              }
              this.notificationService.success(`Demo environment was successfully created!`);
              return new ActionApiApplicationsSuccessfulDemoCreation();
            }),
            catchError((_) => {
              return of(new ActionApiApplicationsFailedDemoCreation());
            })
          );
        }
        return of(new ActionApiApplicationsMaintainState());
      })
    )
  );

  public submittingFileShareServiceModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_FILE_SHARE_SERVICE_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingFileShareServiceModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId)), this.store.select(selectCurrentOrganisation)))
      ),
      concatMap(([action, orgId, org]: [ActionApiApplicationsSubmittingFileShareServiceModel, string, Organisation]) => {
        const getConnector$ = getConnectorByName(this.connectorsService, action.model.connector_name, orgId);
        return forkJoin([of(action), of(orgId), of(org), getConnector$]).pipe(
          catchError((error) => {
            return of([action, orgId, org, undefined]);
          })
        );
      }),
      concatMap(
        ([action, orgId, org, connector]: [ActionApiApplicationsSubmittingFileShareServiceModel, string, Organisation, Connector]) => {
          return forkJoin([of(action), this.submitFileShareServiceModel(action.model, org, orgId, connector?.metadata?.id)]).pipe(
            catchError((error) => {
              return of([action, undefined]);
            })
          );
        }
      ),
      map(([action, share]: [ActionApiApplicationsSubmittingFileShareServiceModel, FileShareService | undefined]) => {
        if (!share) {
          this.notificationService.error(`Failed to create new share "${action.model.name}". Please try again.`);
          return new ActionApiApplicationsFailedFileShareServiceModelSubmission();
        }
        return new ActionApiApplicationsSuccessfulFileShareServiceModelSubmission(share, action.model);
      })
    )
  );

  public addToStateOnSuccessfulFileShareServiceModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_FILE_SHARE_SERVICE_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulFileShareServiceModelSubmission) => {
        return FileShareServiceActions.upsertFileShareService({ obj: action.share, refreshData: true });
      })
    )
  );

  public submittingDesktopModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_DESKTOP_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingDesktopModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSubmittingDesktopModel, string]) => {
        const getConnector$ = getConnectorByName(this.connectorsService, action.desktop_model.connector_name, orgId);
        return forkJoin([of(action), of(orgId), getConnector$]).pipe(
          catchError((error) => {
            return of([action, orgId, undefined]);
          })
        );
      }),
      concatMap(([action, orgId, connector]: [ActionApiApplicationsSubmittingDesktopModel, string, Connector]) => {
        return forkJoin([of(action), this.submitDesktopModel(action.desktop_model, orgId, connector?.metadata?.id)]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      map(([action, desktopResource]: [ActionApiApplicationsSubmittingDesktopModel, DesktopResource | undefined]) => {
        if (!desktopResource) {
          this.notificationService.error(`Failed to create new desktop "${action.desktop_model.name}". Please try again.`);
          return new ActionApiApplicationsFailedDesktopModelSubmission();
        }
        return new ActionApiApplicationsSuccessfulDesktopModelSubmission(desktopResource, action.desktop_model);
      })
    )
  );

  public addToStateOnSuccessfulDesktopModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_DESKTOP_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulDesktopModelSubmission) => {
        return DesktopResourceActions.upsertDesktopResource({ obj: action.desktopResource, refreshData: true });
      })
    )
  );

  public submittingSSHModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_SSH_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingSSHModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSubmittingSSHModel, string]) => {
        const getConnector$ = getConnectorByName(this.connectorsService, action.ssh_model.connector_name, orgId);
        return forkJoin([of(action), of(orgId), getConnector$]).pipe(
          catchError((error) => {
            return of([action, orgId, undefined]);
          })
        );
      }),
      concatMap(([action, orgId, connector]: [ActionApiApplicationsSubmittingSSHModel, string, Connector]) => {
        return forkJoin([of(action), this.submitSSHModel(action.ssh_model, orgId, connector?.metadata?.id)]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      concatMap(([action, sshResource]: [ActionApiApplicationsSubmittingSSHModel, SSHResource | undefined]) => {
        let createSshCredential$: Observable<ObjectCredential | undefined> = of(undefined);
        if (!!sshResource && needToCreateSshCredential(action.ssh_model)) {
          const newCredential = getDefaultNewCredential(sshResource, {
            private_key: action.ssh_model.secrets.private_key,
            private_key_passphrase: action.ssh_model.secrets.private_key_passphrase,
            password: action.ssh_model.secrets.password,
            username: action.ssh_model.secrets.username,
          });
          createSshCredential$ = createNewSshCredential$(this.credentialsService, newCredential);
        }
        return forkJoin([of(action), of(sshResource), createSshCredential$]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      map(
        ([action, sshResource, sshCredential]: [
          ActionApiApplicationsSubmittingSSHModel,
          SSHResource | undefined,
          ObjectCredential | undefined
        ]) => {
          if (!sshResource) {
            this.notificationService.error(`Failed to create new ssh "${action.ssh_model.name}". Please try again.`);
            return new ActionApiApplicationsFailedSSHModelSubmission();
          }
          return new ActionApiApplicationsSuccessfulSSHModelSubmission(sshResource);
        }
      )
    )
  );

  public addToStateOnSuccessfulSSHModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_SSH_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulSSHModelSubmission) => {
        return SSHResourceActions.upsertSSHResource({ obj: action.SSHResource, refreshData: true });
      })
    )
  );

  /**
   * Since the sshs also appear in the network overtable, we need to also update the
   * Application Service state with the ssh
   */
  public addToApplicationServiceStateOnSuccessfulSSHModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_SSH_MODEL_SUBMISSION),
      concatMap((action: ActionApiApplicationsSuccessfulSSHModelSubmission) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSuccessfulSSHModelSubmission, string]) => {
        return getApplicationService(this.applicationServicesService, action.SSHResource.metadata.id, orgId);
      }),
      concatMap((appService: ApplicationService) => {
        return of(ApplicationServiceActions.upsertApplicationService({ obj: appService, refreshData: true }));
      })
    )
  );

  public submittingNetworkModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_NETWORK_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingNetworkModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSubmittingNetworkModel, string]) => {
        const getConnector$ = getConnectorByName(this.connectorsService, action.model.connector_name, orgId);
        return forkJoin([of(action), of(orgId), getConnector$]).pipe(
          catchError((error) => {
            return of([action, orgId, undefined]);
          })
        );
      }),
      concatMap(([action, orgId, connector]: [ActionApiApplicationsSubmittingNetworkModel, string, Connector]) => {
        return forkJoin([of(action), this.submitNetworkModel(action.model, orgId, connector?.metadata?.id)]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      map(([action, network]: [ActionApiApplicationsSubmittingNetworkModel, ApplicationService | undefined]) => {
        if (!network) {
          this.notificationService.error(`Failed to create new network "${action.model.name}". Please try again.`);
          return new ActionApiApplicationsFailedNetworkModelSubmission();
        }
        return new ActionApiApplicationsSuccessfulNetworkModelSubmission(network);
      })
    )
  );

  public addToStateOnSuccessfulNetworkModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_NETWORK_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulNetworkModelSubmission) => {
        return ApplicationServiceActions.upsertApplicationService({ obj: action.object, refreshData: true });
      })
    )
  );

  public submittingLauncherModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_LAUNCHER_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingLauncherModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSubmittingLauncherModel, string]) => {
        return forkJoin([of(action), this.submitLauncherModel(action.model, orgId)]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      map(([action, launcher]: [ActionApiApplicationsSubmittingLauncherModel, Launcher | undefined]) => {
        if (!launcher) {
          this.notificationService.error(`Failed to create new launcher "${action.model.name}". Please try again.`);
          return new ActionApiApplicationsFailedLauncherModelSubmission();
        }
        return new ActionApiApplicationsSuccessfulLauncherModelSubmission(launcher);
      })
    )
  );

  public addToStateOnSuccessfulLauncherModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_LAUNCHER_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulLauncherModelSubmission) => {
        return LauncherActions.upsertLauncher({ obj: action.object, refreshData: true });
      })
    )
  );

  public submittingApiKeyModel$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUBMITTING_APIKEY_MODEL),
      concatMap((action: ActionApiApplicationsSubmittingApiKeyModel) =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
      ),
      concatMap(([action, orgId]: [ActionApiApplicationsSubmittingApiKeyModel, string]) => {
        return forkJoin([of(action), this.submitApiKeyModel(action.api_key_model, orgId)]).pipe(
          catchError((error) => {
            return of([action, undefined]);
          })
        );
      }),
      map(([action, apiKey]: [ActionApiApplicationsSubmittingApiKeyModel, APIKey | undefined]) => {
        if (!apiKey) {
          this.notificationService.error(`Failed to create new API key "${action.api_key_model.name}". Please try again.`);
          return new ActionApiApplicationsFailedApiKeyModelSubmission();
        }
        return new ActionApiApplicationsSuccessfulApiKeyModelSubmission(apiKey);
      })
    )
  );

  public addToStateOnSuccessfulApiKeyModelSubmission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ApiApplicationsActionTypes.SUCCESSFUL_APIKEY_MODEL_SUBMISSION),
      map((action: ActionApiApplicationsSuccessfulApiKeyModelSubmission) => {
        return ApiKeyActions.upsertAPIKey({ obj: action.APIKey, refreshData: true });
      })
    )
  );

  // createWrappedEffect Alt trial:
  /*
  private createWrappedEffect<T, S, R extends Action>(
    objs: Observable<any>,
    // objs: Actions<any>,
    actionType: string,
    handler: (action: R, error: any) => Observable<S>,
    pipeline: (action: R) => OperatorFunction<R, T>
  ): Observable<T | S> {
    return objs.pipe(
      ofType(actionType),
      mergeMap((action) => {
        return of(action).pipe(
          pipeline(action),
          catchError((error) => {
            return handler(action, error);
          })
        );
      })
    );
  }
  */

  // createWrappedEffect trial:
  /*
  private createWrappedEffect<T, S>(
    // objs: Observable<Action>,
    objs: Actions<Action>,
    actionType: string,
    handler: (action: Action, error: any) => Observable<S>,
    pipeline: (action: Action) => OperatorFunction<Action, T>
  ): Observable<T | S> {
    return objs.pipe(
      ofType(actionType),
      mergeMap((action) => {
        return of(action).pipe(
          pipeline(action),
          catchError((error) => {
            return handler(action, error);
          })
        );
      })
    );
  }
  */

  // submittingDesktopModel$ trial with createWrappedEffect:
  /*
  public submittingDesktopModel$ = createEffect(() =>
    this.createWrappedEffect<
      ActionApiApplicationsSuccessfulDesktopSubmission,
      ActionApiApplicationsFailedDesktopModelSubmission,
      // ActionApiApplicationsSubmittingDesktopModel
    >(
      this.actions$,
      ApiApplicationsActionTypes.SUBMITTING_DESKTOP_MODEL,
      (action, error) => {
        // this.notificationService.error(`Failed to create new desktop "${action.desktop_model.name}". Please try again.`);
        return of(new ActionApiApplicationsFailedDesktopModelSubmission());
      },
      pipe(
        concatMap((action: ActionApiApplicationsSubmittingDesktopModel) =>
          of(action).pipe(withLatestFrom(this.store.pipe(select(selectApiOrgId))))
        ),
        concatMap(([action, orgId]: [ActionApiApplicationsSubmittingDesktopModel, string]) => {
          const getConnector$ = getConnectorByName(this.connectorsService, action.desktop_model.connector_name, orgId);
          return forkJoin([of(action), of(orgId), getConnector$]);
        }),
        concatMap(([action, orgId, connector]: [ActionApiApplicationsSubmittingDesktopModel, string, Connector]) => {
          return this.submitDesktopModel(action.desktop_model, orgId, connector?.metadata?.id);
        }),
        map((desktopResource: DesktopResource) => {
          return new ActionApiApplicationsSuccessfulDesktopSubmission(desktopResource);
        })
      )
    )
  );
  */

  // create new launcher
  public postItem(itemToCreate: Launcher): Observable<Launcher> {
    const createRequestParams: CreateLauncherRequestParams = {
      Launcher: itemToCreate,
    };
    return this.launchersService.createLauncher(createRequestParams);
  }

  private applicationModelSubmitAll(
    appModel: ApplicationModel,
    orgId: string,
    currentUser: User,
    currentIssuer: Issuer,
    currentOrg: Organisation,
    authDoc: AuthenticationDocument,
    usePolicyRules: boolean
  ): Observable<Application> {
    const oidcProxyRuntimeCatalogueEntry$ = getOidcProxyRuntimeCatalogueEntry(this.cataloguesService);
    return forkJoin([oidcProxyRuntimeCatalogueEntry$]).pipe(
      concatMap(([oidcProxyRuntimeCatalogueEntryResp]: [CatalogueEntry]) => {
        const createIssuerClient$ = this.submitApplicationModelIssuerClient(appModel, orgId, currentIssuer, currentOrg);
        return forkJoin([of(oidcProxyRuntimeCatalogueEntryResp), createIssuerClient$]);
      }),
      concatMap(([oidcProxyRuntimeCatalogueEntryResp, createIssuerClientResp]: [CatalogueEntry, IssuerClient | undefined]) => {
        const newApplication = getApplicationFromModel(appModel, orgId, currentUser.email, oidcProxyRuntimeCatalogueEntryResp);
        // Add the application config if applicable.
        setApplicationConfigFromModel(newApplication, appModel, currentOrg, currentIssuer, createIssuerClientResp);
        const createNewApp$ = this.submitApplicationModelCreateApplication(newApplication, orgId);
        const getConnector$ = this.submitApplicationModelGetConnector(appModel, orgId);
        return forkJoin([of(createIssuerClientResp), createNewApp$, getConnector$]);
      }),
      concatMap(([createIssuerClientResp, postAppResp, getConnectorResp]: [IssuerClient | undefined, Application, Connector]) => {
        if (!postAppResp) {
          return of(new ActionApiApplicationsFailedApplicationModelSubmission());
        }
        this.store.dispatch(new ActionApiApplicationsAppSaveFinished(postAppResp, false));
        this.addAppIdToAppModel(appModel, postAppResp);
        const reconcileResourceDefaultPolicy$ = getResourceAndReconcileDefaultPolicy$(this.resourcesService, postAppResp.id, orgId);
        return forkJoin([of(postAppResp), of(createIssuerClientResp), of(getConnectorResp), reconcileResourceDefaultPolicy$]);
      }),
      concatMap(
        ([postAppResp, createIssuerClientResp, getConnectorResp, reconcileResourceDefaultPolicyResp]: [
          Application,
          IssuerClient | undefined,
          Connector,
          Resource
        ]) => {
          const createDefaultRole$ = this.submitApplicationModelDefaultRole(appModel, orgId, postAppResp, currentOrg);
          let createUpstreamServices$: Observable<Array<ApplicationService> | undefined> = of(undefined);
          if (!appModel.is_demo) {
            // Only create the upstream services (networks) if not a demo:
            createUpstreamServices$ = this.submitApplicationModelUpstreamService(
              appModel,
              orgId,
              postAppResp,
              getConnectorResp?.metadata?.id
            );
          }
          return forkJoin([of(postAppResp), of(createIssuerClientResp), createDefaultRole$, of(getConnectorResp), createUpstreamServices$]);
        }
      ),
      concatMap(
        ([postAppResp, createIssuerClientResp, createDefaultRoleResp, getConnectorResp, _]: [
          Application,
          IssuerClient | undefined,
          RoleV2,
          Connector,
          Array<ApplicationService>
        ]) => {
          const createConnectorEnvVars$ = this.submitApplicationModelCreateConnectorEnvVars(
            appModel,
            getConnectorResp,
            postAppResp,
            currentOrg,
            authDoc
          );
          let createRulesOrPolicyTemplateInstance$: Array<Observable<RuleV2 | PolicyTemplateInstance | undefined>> = [of(undefined)];
          if (usePolicyRules) {
            const ruleConfigsToCreate: Array<RuleConfig> = [
              ...this.modelToAppPolicyRules(appModel),
              ...this.modelToUserDefinedPolicyRules(appModel),
              ...this.modelToProxyPolicyRules(appModel, postAppResp, orgId),
            ];
            createRulesOrPolicyTemplateInstance$ = this.submitApplicationModelPolicyRules(appModel, ruleConfigsToCreate, postAppResp);
          } else {
            const ruleV2sToCreate: Array<RuleV2> = [
              ...this.modelToAppRules(appModel, postAppResp, orgId),
              ...this.modelToUserDefinedRules(appModel, postAppResp, orgId),
              ...this.modelToProxyRules(appModel, postAppResp, orgId),
            ];
            createRulesOrPolicyTemplateInstance$ = this.submitApplicationModelRules(ruleV2sToCreate);
          }
          return forkJoin([
            of(postAppResp),
            of(createIssuerClientResp),
            of(createDefaultRoleResp),
            of(getConnectorResp),
            createConnectorEnvVars$,
            forkJoin(createRulesOrPolicyTemplateInstance$),
          ]);
        }
      ),
      concatMap(
        ([
          postAppResp,
          createIssuerClientResp,
          createDefaultRoleResp,
          getConnectorResp,
          createConnectorEnvVarsResp,
          createRulesOrPolicyTemplateInstanceResp,
        ]: [Application, IssuerClient | undefined, RoleV2, Connector, EnvironmentConfigVarList, Array<RuleV2>]) => {
          let createRoleToRuleEntries$: Array<Observable<RoleToRuleEntry | undefined>> = [of(undefined)];
          if (!usePolicyRules) {
            const roleToRuleEntries = this.assignRulesToRolesForModel(
              appModel,
              postAppResp,
              orgId,
              createRulesOrPolicyTemplateInstanceResp,
              createDefaultRoleResp
            );
            const createDefaultRoleToRuleEntries$ = this.submitApplicationModelCreateRoleToRuleEntries(postAppResp, roleToRuleEntries);
            createRoleToRuleEntries$ = [...createDefaultRoleToRuleEntries$];
          }
          const newLauncher$ = this.createNewLauncher(appModel, orgId);
          return forkJoin([
            of(postAppResp),
            of(createIssuerClientResp),
            of(getConnectorResp),
            forkJoin(createRoleToRuleEntries$),
            newLauncher$,
          ]);
        }
      ),
      concatMap(([postAppResp, _, __, ___, ____]: [Application, IssuerClient | undefined, Connector, Array<RoleToRuleEntry>, Launcher]) => {
        return of(postAppResp);
      })
    );
  }

  private createNewLauncher(appModel: ApplicationModel, orgId: string): Observable<Launcher> {
    if (appModel.authentication.launch_desktop === true || String(appModel.authentication.launch_desktop) === 'true') {
      const LauncherSpec = {
        spec: {
          name: appModel.name,
          org_id: orgId,
          applications: [appModel.app_id],
        },
      };
      return this.postItem(LauncherSpec);
    } else {
      return of({});
    }
  }

  private addAppIdToAppModel(appModel: ApplicationModel, newApplication: Application): void {
    appModel.app_id = newApplication.id;
    this.store.dispatch(new ActionApiApplicationsUpdateApplicationModel(appModel));
  }

  private submitApplicationModelCreateApplication(newApplication: Application, orgId: string): Observable<Application | undefined> {
    let createNewApp$: Observable<Application | undefined> = of(undefined);
    const createNewAppInitial$ = createNewApplication(this.applicationsService, newApplication, orgId);
    const appModelCreationData: ApiDataCreation<ApplicationsService, Application> = {
      service: this.applicationsService,
      newData: newApplication,
      updateExistingFunc: updateExistingApplication,
    };
    createNewApp$ = createNewApiDataHandleErrors(createNewAppInitial$, appModelCreationData);
    return createNewApp$;
  }

  private submitApplicationModelIssuerClient(
    appModel: ApplicationModel,
    orgId: string,
    currentIssuer: Issuer,
    currentOrg: Organisation
  ): Observable<IssuerClient | undefined> {
    let createIssuerClient$: Observable<IssuerClient | undefined> = of(undefined);
    if (needToCreateIssuerClient(appModel)) {
      const newIssuerClient = getIssuerClientFromApplicationModel(appModel, orgId, currentIssuer, currentOrg);
      const createIssuerClientInitial$ = createNewIssuerClient(this.issuersService, newIssuerClient);
      const appModelCreationData: ApiDataCreation<IssuersService, IssuerClient> = {
        service: this.issuersService,
        newData: newIssuerClient,
        updateExistingFunc: updateExistingIssuerClient,
      };
      createIssuerClient$ = createNewApiDataHandleErrors(createIssuerClientInitial$, appModelCreationData);
    }
    return createIssuerClient$;
  }

  private submitApplicationModelDefaultRole(
    appModel: ApplicationModel,
    orgId: string,
    newApp: Application,
    currentOrg: Organisation
  ): Observable<RoleV2 | undefined> {
    let createDefaultRole$: Observable<RoleV2 | undefined> = of(undefined);
    if (needToCreateDefaultRole(appModel)) {
      const newDefaultRole = getDefaultRole(newApp, orgId);
      const createDefaultRoleInitial$ = createNewRole(this.applicationsService, newDefaultRole, newApp);
      createDefaultRole$ = createNewApiDataHandleErrors(createDefaultRoleInitial$).pipe(
        concatMap((postRoleResp) => {
          // Make this role the default role for the app.
          const newAppCopy: Application = { ...newApp };
          newAppCopy.default_role_id = postRoleResp.metadata.id;
          return updateExistingApplication(this.applicationsService, newAppCopy).pipe(
            concatMap((putAppResp) => {
              this.store.dispatch(new ActionApiApplicationsAppSaveFinished(putAppResp, false));
              if (needToUpdateGroup(appModel)) {
                return getGroupAndUpdateRoles(postRoleResp, currentOrg, appModel, this.usersService, this.notificationService).pipe(
                  concatMap((_) => {
                    return of(postRoleResp);
                  })
                );
              }
              return of(postRoleResp);
            })
          );
        })
      );
    }
    return createDefaultRole$;
  }

  private submitApplicationModelUpstreamService(
    appModel: ApplicationModel,
    orgId: string,
    newApp: Application,
    connectorId: string
  ): Observable<Array<ApplicationService> | undefined> {
    const upstreamServicesObservablesArray = [];
    if (needToCreateUpstreamService(appModel)) {
      appModel.hosting.on_prem.upstream_services.forEach((upstreamService, index) => {
        const newUpstreamService = getSelfHostedApplicationServiceFromApplicationModel(
          appModel,
          orgId,
          newApp,
          connectorId,
          upstreamService,
          index
        );
        if (!!newUpstreamService) {
          const createUpstreamServiceInitial$ = createNewApplicationService(this.applicationServicesService, newUpstreamService);
          const appModelCreationData: ApiDataCreation<ApplicationServicesService, ApplicationService> = {
            service: this.applicationServicesService,
            newData: newUpstreamService,
            updateExistingFunc: updateExistingApplicationService,
          };
          upstreamServicesObservablesArray.push(createNewApiDataHandleErrors(createUpstreamServiceInitial$, appModelCreationData));
        }
      });
    }
    if (upstreamServicesObservablesArray.length === 0) {
      return of(undefined);
    }
    return forkJoin(upstreamServicesObservablesArray);
  }

  private submitFileShareServiceModel(
    fileShareModel: FileShareModel,
    org: Organisation,
    orgId: string,
    connectorId: string
  ): Observable<FileShareService | undefined> {
    const newFileShareService = getFileShareServiceFromModel(fileShareModel, orgId, connectorId);
    if (!newFileShareService) {
      return of(undefined);
    }

    newFileShareService.spec.transport_base_domain = org.subdomain;
    const createdShare$ = createFileShareService(this.applicationServicesService, newFileShareService);
    const creationData: ApiDataCreation<ApplicationServicesService, FileShareService> = {
      service: this.applicationServicesService,
      newData: newFileShareService,
      updateExistingFunc: updateExistingFileShareService,
    };
    return createNewApiDataHandleErrors(createdShare$, creationData);
  }

  private submitApplicationModelGetConnector(appModel: ApplicationModel, orgId: string): Observable<Connector | undefined> {
    let getConnector$: Observable<Connector | undefined> = of(undefined);
    if (needToConfigureConnector(appModel)) {
      getConnector$ = getConnectorByName(this.connectorsService, appModel.hosting.on_prem.connector_name, orgId);
    }
    return getConnector$;
  }

  private submitApplicationModelRules(rules: Array<RuleV2>): Array<Observable<RuleV2 | undefined>> {
    if (rules.length === 0) {
      return [of(undefined)];
    }
    return rules.map((rule) => {
      const createRuleInitial$ = createNewRule(this.applicationsService, rule);
      return createNewApiDataHandleErrors(createRuleInitial$);
    });
  }

  private submitApplicationModelPolicyRules(
    appModel: ApplicationModel,
    rules: Array<RuleConfig>,
    application: Application
  ): Array<Observable<PolicyTemplateInstance | undefined>> {
    if (rules.length === 0) {
      return [of(undefined)];
    }
    const simpleResourcePolicyTemplate: SimpleResourcePolicyTemplate = {
      template_type: PolicyTemplateType.simple_resource,
      policy_structure: rules.map((rule) => {
        return {
          name: rule.name,
          root_node: {
            children: [],
            priority: rule.priority,
            rule_name: rule.name,
          },
        };
      }),
      rules: [...rules],
    };
    if (!!needToSetCommonPathPrefixRule(appModel)) {
      addCommonPathPrefixRuleToPolicyTemplate(
        appModel.authorization.application_model_routing.common_path_prefix,
        simpleResourcePolicyTemplate
      );
    }
    const newPolicyTemplateInstance = getNewApplicationEmptyPolicy(application);
    newPolicyTemplateInstance.spec.template = simpleResourcePolicyTemplate;
    const createPolicyTemplateInstanceInitial$ = createNewPolicyTemplateInstance$(this.policyTemplatesService, newPolicyTemplateInstance);
    return [createNewApiDataHandleErrors(createPolicyTemplateInstanceInitial$)];
  }

  private modelToAppRules(appModel: ApplicationModel, app: Application, orgId: string): Array<RuleV2> {
    const rules: Array<RuleV2> = [];
    if (needToCreateDefaultRole(appModel)) {
      rules.push(getAllAccessRule(app, orgId));
    }

    return rules;
  }

  private modelToAppPolicyRules(appModel: ApplicationModel): Array<RuleConfig> {
    const rules: Array<RuleConfig> = [];
    if (needToCreateDefaultRole(appModel)) {
      rules.push(getAllAccessPolicyRule());
    }

    return rules;
  }

  private modelToUserDefinedRules(appModel: ApplicationModel, app: Application, orgId: string): Array<RuleV2> {
    const rules = appModel.authorization?.rules;
    if (!rules) {
      return [];
    }
    const result: Array<RuleV2> = rules.map((rule) => {
      return ruleFromSpec(rule.spec, app, orgId);
    });

    return result;
  }

  private modelToUserDefinedPolicyRules(appModel: ApplicationModel): Array<RuleConfig> {
    const rules = appModel.authorization?.policy_rules;
    if (!rules) {
      return [];
    }
    return rules;
  }

  private modelToProxyRules(appModel: ApplicationModel, app: Application | undefined, orgId: string): Array<RuleV2> {
    return getProxyRules(appModel, app, orgId);
  }

  private modelToProxyPolicyRules(appModel: ApplicationModel, app: Application | undefined, orgId: string): Array<RuleConfig> {
    return getProxyPolicyRules(appModel, app, orgId);
  }

  private assignRulesToRolesForModel(
    appModel: ApplicationModel,
    app: Application,
    orgId: string,
    rules: Array<RuleV2>,
    createDefaultRoleResp: RoleV2
  ): Array<RoleToRuleEntry> {
    const results: RoleToRuleEntry[] = [];

    // The default role creates a rule with the all access rule comment. We need to assign that.
    // A much better solution here is to have an endpoint which accepts the roles and rules at the
    // same time and linkes them together.
    if (needToCreateDefaultRole(appModel)) {
      const allAccessRule = findRuleWithComment(rules, ALL_ACCESS_RULE_COMMENT);
      if (!!allAccessRule) {
        results.push(getRoleToRuleEntry(app, orgId, createDefaultRoleResp, allAccessRule));
      }
    }

    return results;
  }
  private submitApplicationModelCreateRoleToRuleEntries(
    newApp: Application,
    entries: Array<RoleToRuleEntry>
  ): Array<Observable<RoleToRuleEntry | undefined>> {
    const results = entries.map((roleToRuleEntry) => {
      const createRoleToRuleEntryInitial$ = createNewRoletoRuleEntry(this.applicationsService, roleToRuleEntry, newApp);
      return createNewApiDataHandleErrors(createRoleToRuleEntryInitial$);
    });
    if (results.length === 0) {
      return [of(undefined)];
    }

    return results;
  }

  private submitApplicationModelCreateConnectorEnvVars(
    appModel: ApplicationModel,
    newConnector: Connector,
    newApp: Application,
    organisation: Organisation,
    authDoc: AuthenticationDocument
  ): Observable<EnvironmentConfigVarList> {
    let createConnectorEnvVars$: Observable<EnvironmentConfigVarList> = of(undefined);
    if (appModel.is_demo && !!authDoc) {
      const envConfigVars = getDemoEnvConfigVarsList(authDoc, organisation, newConnector.metadata.id);
      const createConnectorEnvVarsInitial$ = createOrUpdateEnvConfigVarsList(
        this.environmentConfigService,
        newApp.id,
        newApp.environments[0].name,
        organisation.id,
        envConfigVars
      );
      createConnectorEnvVars$ = createNewApiDataHandleErrors(createConnectorEnvVarsInitial$);
    } else if (!!newConnector && !!newApp) {
      const envConfigVars = getConnectorEnvConfigVars(appModel.hosting);
      const createConnectorEnvVarsInitial$ = createOrUpdateEnvConfigVarsList(
        this.environmentConfigService,
        newApp.id,
        newApp.environments[0].name,
        organisation.id,
        envConfigVars
      );
      createConnectorEnvVars$ = createNewApiDataHandleErrors(createConnectorEnvVarsInitial$);
    }
    return createConnectorEnvVars$;
  }

  private submitDesktopModel(desktopModel: DesktopModel, orgId: string, connectorId: string): Observable<DesktopResource | undefined> {
    const newDesktopResource = getDesktopResourceFromModel(desktopModel, orgId, connectorId);
    if (!newDesktopResource) {
      return of(undefined);
    }

    const createdDesktopResource$ = createNewDesktopResource(this.applicationServicesService, newDesktopResource);
    const creationData: ApiDataCreation<ApplicationServicesService, DesktopResource> = {
      service: this.applicationServicesService,
      newData: newDesktopResource,
      updateExistingFunc: updateExistingDesktopResource,
    };
    return createNewApiDataHandleErrors(createdDesktopResource$, creationData);
  }

  private submitSSHModel(sshModel: SSHModel, orgId: string, connectorId: string): Observable<SSHResource | undefined> {
    const newSSHResource = getSSHResourceFromModel(sshModel, orgId, connectorId);
    if (!newSSHResource) {
      return of(undefined);
    }

    const createdSSHResource$ = createNewSSHResource(this.applicationServicesService, newSSHResource);
    const creationData: ApiDataCreation<ApplicationServicesService, SSHResource> = {
      service: this.applicationServicesService,
      newData: newSSHResource,
      updateExistingFunc: updateExistingSSHResource,
    };
    return createNewApiDataHandleErrors(createdSSHResource$, creationData);
  }

  private submitNetworkModel(model: NetworkModel, orgId: string, connectorId: string): Observable<ApplicationService | undefined> {
    const newNetwork = getNetworkFromModel(model, orgId, connectorId);
    if (!newNetwork) {
      return of(undefined);
    }

    const createdNetwork$ = createNewApplicationService(this.applicationServicesService, newNetwork);
    const creationData: ApiDataCreation<ApplicationServicesService, ApplicationService> = {
      service: this.applicationServicesService,
      newData: newNetwork,
      updateExistingFunc: updateExistingApplicationService,
    };
    return createNewApiDataHandleErrors(createdNetwork$, creationData);
  }

  private submitLauncherModel(model: LauncherModel, orgId: string): Observable<Launcher | undefined> {
    const newLauncher = getLauncherResourceFromModel(model, orgId);
    if (!newLauncher) {
      return of(undefined);
    }

    const createdLauncher$ = createNewLauncher(this.launchersService, newLauncher);
    const creationData: ApiDataCreation<LaunchersService, Launcher> = {
      service: this.launchersService,
      newData: newLauncher,
      updateExistingFunc: updateExistingLauncher,
    };
    return createNewApiDataHandleErrors(createdLauncher$, creationData);
  }

  private submitApiKeyModel(model: APIKeyModel, orgId: string): Observable<APIKey | undefined> {
    const newApiKey = getApiKeyFromModel(model, orgId);
    if (!newApiKey) {
      return of(undefined);
    }

    const createdApiKey$ = createNewApiKey(this.tokensService, newApiKey);
    const creationData: ApiDataCreation<TokensService, APIKey> = {
      service: this.tokensService,
      newData: newApiKey,
      updateExistingFunc: updateExistingApiKey,
    };
    return createNewApiDataHandleErrors(createdApiKey$, creationData);
  }

  private deleteAllDemoResources$(orgId: string): Observable<DemoDeleteResources> {
    const deleteDemoApplication$ = deleteApplicationByName$(this.applicationsService, getDemoApplicationName(), orgId);
    return deleteDemoApplication$.pipe(
      concatMap((deletedApp) => {
        return forkJoin([of(deletedApp), deleteDemoIssuerClientsByApplicationName$(this.issuersService, getDemoApplicationName(), orgId)]);
      }),
      concatMap(([deletedApp, deletedIssuerClient]) => {
        return forkJoin([of(deletedApp), of(deletedIssuerClient), deleteGroupByName$(this.groupService, getDemoGroupName(), orgId)]);
      }),
      concatMap(([deletedApp, deletedIssuerClient, deletedGroup]) => {
        return forkJoin([
          of(deletedApp),
          of(deletedIssuerClient),
          of(deletedGroup),
          deleteExistingDesktopResourceByName$(this.applicationServicesService, getDemoDesktopName(), orgId),
        ]);
      }),
      concatMap(([deletedApp, deletedIssuerClient, deletedGroup, deletedDesktopResource]) => {
        return forkJoin([
          of(deletedApp),
          of(deletedIssuerClient),
          of(deletedGroup),
          of(deletedDesktopResource),
          deleteExistingFileShareServiceByName$(this.applicationServicesService, getDemoShareName(), orgId),
        ]);
      }),
      concatMap(([deletedApp, deletedIssuerClient, deletedGroup, deletedDesktopResource, deletedShare]) => {
        return forkJoin([
          of(deletedApp),
          of(deletedIssuerClient),
          of(deletedGroup),
          of(deletedDesktopResource),
          of(deletedShare),
          deleteExistingApplicationServiceByName$(this.applicationServicesService, getDemoUpstreamServiceName(), orgId),
        ]);
      }),
      concatMap(([deletedApp, deletedIssuerClient, deletedGroup, deletedDesktopResource, deletedShare, deletedAppService]) => {
        return forkJoin([
          of(deletedApp),
          of(deletedIssuerClient),
          of(deletedGroup),
          of(deletedDesktopResource),
          of(deletedShare),
          of(deletedAppService),
          deleteExistingAgentConnectorByName$(this.connectorsService, getDemoAgentConnectorName(), orgId),
        ]);
      }),
      map(
        ([
          deletedApp,
          deletedIssuerClient,
          deletedGroup,
          deletedDesktopResource,
          deletedShare,
          deletedAppService,
          deletedAgentConnector,
        ]) => {
          return {
            application: deletedApp,
            issuerClient: deletedIssuerClient,
            group: deletedGroup,
            desktop: deletedDesktopResource,
            share: deletedShare,
            network: deletedAppService,
            agentConnector: deletedAgentConnector,
          };
        }
      )
    );
  }
}

<portal-page-info
  [pageTitle]="componentTitle"
  [pageDescriptiveText]="pageDescriptiveText"
  [productGuideLink]="productGuideLink"
>
</portal-page-info>

<div *ngIf="hasGroupsPermissions !== undefined && !hasGroupsPermissions">
  <portal-no-permissions></portal-no-permissions>
</div>

<div 
  *ngIf="hasGroupsPermissions" 
  class="group-admin-container"
>
  <portal-groups-csv
    *ngIf="showGroupsCsv"
    (updateEvent)="reloadFirstPage()"
  >
  </portal-groups-csv> 

  <portal-table-layout
    #tableLayoutComp
    [columnDefs]="columnDefs"
    [filterManager]="filterManager"
    [paramSpecificFilterManager]="paramSpecificFilterManager"
    [filterMenuOptions]="filterMenuOptions"
    [makeEmptyTableElement]="makeEmptyTableElementFunc"
    [rowObjectName]="rowObjectName"
    [paginatorConfig]="paginatorConfig"
    [linkDataSource] = "linkDataSource"
    [useBackendFilter]="useBackendFilter"
    [showTableControls]="showTableControls"
    (updateEvent)="updateEvent($event)"
    (removeSelected)="removeSelected($event)"
    (refreshDataSource)="refreshDataSource()"
    (filterSearchDelay)="paramSpecificFilterManager.filterSearchDelay($event)"
    (filterBySearchParam)="filterBySearchParam()"
  >
  </portal-table-layout>
</div>

<div class="logo-dialog-container">
  <span *ngIf="!!resource && !!data.resourceType">
    <h2>{{ capitalizeFirstLetter(getFormattedResourceType(data.resourceType)) }} Icon</h2>
    <p>To {{ getIconActionText() }} icon for {{ getFormattedResourceType(data.resourceType) }} "{{ resource.spec.name }}", drop an image file in the area below or click to select a file to upload</p>
  </span>

  <span *ngIf="!!label">
    <h2>Label Icon</h2>
    <p>To {{ getIconActionText() }} icon for label "{{ label.spec.name }}", drop an image file in the area below or click to select a file to upload</p>
  </span>

  <div class="upload-area-container">
    <portal-upload-area
      defaultText="Icon"
      [iconUrl]="getResourceIconUrl()"
      [isUploading]="isUploading"
      tooltipText="Drop a file here or click to select a file to upload"
      (uploadFile)="uploadFile($event)"
      (handleFileDropError)="handleFileDropError()"
    >
    </portal-upload-area>
  </div>

  <portal-progress-bar
    [progressBarController]="progressBarController"
  >
  </portal-progress-bar>
</div>

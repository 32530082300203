import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, Output, Renderer2 } from '@angular/core';
import { Papa, UnparseConfig } from 'ngx-papaparse';
import { NotificationService } from '@app/core';
import { ProgressBarController } from '../progress-bar/progress-bar-controller';
import { ResourceGroupElement } from '../resource-group-admin/resource-group-admin.component';
import { getDefaultTableProperties } from '../table-layout-utils';
import { Resource, ResourceMember, ResourceTypeEnum } from '@agilicus/angular';
import { getResourceNameAndTypeString } from '../resource-utils';

@Component({
  selector: 'portal-resource-groups-csv',
  templateUrl: './resource-groups-csv.component.html',
  styleUrls: ['./resource-groups-csv.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResourceGroupsCsvComponent implements OnDestroy {
  @Input() public resourceGroups: Array<ResourceGroupElement> = [];
  @Input() public resourceIdToResourceMap: Map<string, Resource> = new Map();
  @Input() public resourceNameAndTypeToResourceMap: Map<string, Resource> = new Map();
  @Input() public orgId: string;
  @Output() public updateTableData = new EventEmitter<ResourceGroupElement[]>();
  @Input() public isUploading: boolean;
  @Input() public progressBarController: ProgressBarController;

  constructor(
    private papa: Papa,
    private notificationService: NotificationService,
    private renderer: Renderer2,
    private changeDetector: ChangeDetectorRef
  ) {}

  public ngOnDestroy(): void {
    this.changeDetector.detach();
  }

  public onUploadResourceGroups(event: Event): void {
    this.progressBarController = this.progressBarController.resetProgressBar();

    const inputElement = event.target as HTMLInputElement;
    const file = inputElement.files?.[0];

    if (!file) {
      this.notificationService.error('No file uploaded.');
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const csvData = reader.result as string;
      const parsedData = this.parseCSVData(csvData);

      if (!parsedData) {
        this.notificationService.error('Invalid CSV format. Please ensure the file contains "name" and "resource_members" headers.');
        inputElement.value = '';
        return;
      }

      const { validGroups, invalidRows, duplicatedGroups } = this.processUploadedResourceGroups(parsedData);

      if (invalidRows.length > 0 || duplicatedGroups.length > 0) {
        this.createUploadErrorNotification(invalidRows, duplicatedGroups);
        inputElement.value = '';
        return;
      }

      this.progressBarController = this.progressBarController.initializeProgressBar();
      this.changeDetector.detectChanges();

      this.updateTableData.emit(validGroups);

      inputElement.value = '';
    };

    reader.onerror = () => {
      this.notificationService.error('Failed to read uploaded file.');
      inputElement.value = '';
    };

    reader.readAsText(file);
  }

  private parseCSVData(csvData: string): Array<{ name: string; resource_members: string }> {
    try {
      const result = this.papa.parse(csvData, {
        header: true,
        skipEmptyLines: true,
      });

      if (result.errors?.length) {
        return null;
      }

      const requiredHeaders = ['name', 'resource_members'];
      const headers = result.meta.fields || [];
      const missingHeaders = requiredHeaders.filter((header) => !headers.includes(header));

      if (missingHeaders.length > 0) {
        return null;
      }

      return result.data as Array<{ name: string; resource_members: string }>;
    } catch {
      return null;
    }
  }

  private processUploadedResourceGroups(uploadedData: Array<{ name: string; resource_members: string }>): {
    validGroups: Array<ResourceGroupElement>;
    invalidRows: Array<number>;
    duplicatedGroups: Array<number>;
  } {
    const processedGroups: Array<ResourceGroupElement> = [];
    const invalidRows: Array<number> = [];
    const duplicatedGroups: Array<number> = [];

    const groupNameSet = new Set<string>();

    uploadedData.forEach((groupData, index) => {
      if (!groupData.name) {
        if (!invalidRows.includes(index + 1)) invalidRows.push(index + 1);
        return;
      }

      if (groupNameSet.has(groupData.name.trim().toLowerCase())) {
        if (!duplicatedGroups.includes(index + 1)) duplicatedGroups.push(index + 1);
        return;
      }

      groupNameSet.add(groupData.name.trim().toLowerCase());

      const resourceMembers: ResourceMember[] = groupData.resource_members
        .split(';')
        .map((memberString) => {
          const match = memberString.match(/(.*)\((.*)\)/);
          if (!match) {
            if (!invalidRows.includes(index + 1)) invalidRows.push(index + 1);
            return null;
          }

          const [_, name, type] = match.map((s) => s.trim());
          const convertedType = type === 'network' ? 'application_service' : type;

          const resource = Array.from(this.resourceIdToResourceMap.values()).find(
            (res) => res.spec.name === name && res.spec.resource_type === convertedType
          );

          if (!resource) {
            if (!invalidRows.includes(index + 1)) invalidRows.push(index + 1);
            return null;
          }

          return {
            id: resource.metadata.id,
            name,
            type: convertedType,
          };
        })
        .filter((member) => member !== null) as ResourceMember[];

      if (resourceMembers.length === 0) {
        if (!invalidRows.includes(index + 1)) invalidRows.push(index + 1);
        return;
      }

      const existingGroup = processedGroups.find((group) => group.name === groupData.name.trim());

      if (existingGroup) {
        existingGroup.resource_members = [...existingGroup.resource_members, ...resourceMembers].filter(
          (member, index, self) => self.findIndex((m) => m.id === member.id) === index
        );
      } else {
        const matchedResource = Array.from(this.resourceIdToResourceMap.values()).find((res) => res.spec.name === groupData.name.trim());

        const metadataId = matchedResource ? matchedResource.metadata.id : null;

        processedGroups.push({
          name: groupData.name.trim(),
          resource_members: resourceMembers,
          backingResourceGroup: {
            spec: {
              name: groupData.name.trim(),
              resource_type: ResourceTypeEnum.group,
              resource_members: resourceMembers,
              org_id: this.orgId,
            },
            metadata: metadataId ? { id: metadataId } : undefined,
          },
          ...getDefaultTableProperties(processedGroups.length),
        });
      }
    });

    return { validGroups: processedGroups, invalidRows, duplicatedGroups };
  }

  private createUploadErrorNotification(invalidRows: Array<number>, duplicatedGroups: Array<number>): void {
    let message = '';

    // Adjust row numbers to account for the header row
    const adjustedInvalidRows = invalidRows.map((row) => row + 1);
    const adjustedDuplicatedGroups = duplicatedGroups.map((row) => row + 1);

    if (adjustedInvalidRows.length > 0) {
      message += `The following CSV rows are invalid: ${adjustedInvalidRows.join(', ')}. `;
    }
    if (adjustedDuplicatedGroups.length > 0) {
      message += `The following CSV rows contain duplicated groups: ${adjustedDuplicatedGroups.join(', ')}. `;
    }
    this.notificationService.error(message);
  }

  public downloadResourceGroups(): void {
    const csvData = this.resourceGroups.map((group) => ({
      name: group.name,
      resource_members: group.resource_members
        .map((member) => {
          const targetResource = this.resourceIdToResourceMap.get(member.id);
          if (targetResource) {
            return getResourceNameAndTypeString(targetResource.spec.name, targetResource.spec.resource_type);
          }
          return member.id;
        })
        .join(';'),
    }));

    const options: UnparseConfig = {
      quotes: true,
      header: true,
      newline: '\n',
    };

    const csvContent = this.papa.unparse(csvData, options);
    const link = this.renderer.createElement('a');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    link.href = window.URL.createObjectURL(blob);
    link.download = 'resource_groups.csv';
    link.click();
  }
}
